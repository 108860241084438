import {
  Box, Flex, Heading, OrderedList,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { GuestStatus, GuestSummary, Seat } from "sharedTypes"
import Icon from "components/elements/Icon"
import SeatPlaceholderButton from "components/Buttons/SeatPlaceholderButton"
import Item from "./Item"
import VacantSeatItem from "./VacantSeatItem"
import GuestItem from "./GuestItem"
import BlockedSeatItem from "./BlockedSeatItem"

export enum ItemTypes {
  LIST_ITEM = "listItem",
}

export enum Placeholders {
  Seats = "seats_placeholder",
  Waitlists = "waitlists_placeholder",
}

type Props = {
  seats: Seat[];
  waitlist: GuestSummary[];
  onUpdateLists: (lists) => void;
  onDeleteWaitlistedGuest: (guestId: string) => void;
  onMoveGuestToOpenSeat?: (guest: GuestSummary) => void;
  onResetSeat: (number: number) => void;
  onAssignGuest: (number: number) => void;
  onMoveToWaitlist: (number: number) => void;
  onAddPlaceholder: (number: number) => void;
  onEditPlaceholder: (number: number) => void;
  canEditSeating: boolean;
  editing: boolean;
}

const Lists = ({
  seats: initialSeats,
  waitlist: initialWaitlist,
  onUpdateLists,
  onDeleteWaitlistedGuest,
  onMoveGuestToOpenSeat,
  onResetSeat,
  onAssignGuest,
  onMoveToWaitlist,
  onAddPlaceholder,
  onEditPlaceholder,
  canEditSeating,
  editing,
}: Props) => {
  type SeatWithId = Seat & { dragId: string }
  type GuestWithId = GuestSummary & { dragId: string }

  const [lists, setLists] = useState<{
    seats: SeatWithId[];
    waitlist: GuestWithId[];
  }>()

  useEffect(() => {
    setLists({
      seats: initialSeats.map((seat, index) => ({ ...seat, dragId: `seat_${index}` })),
      waitlist: initialWaitlist.map((waitlistItem, index) => ({ ...waitlistItem, dragId: `waitlistItem_${index}` })),
    })
  }, [initialSeats, initialWaitlist])

  if (!lists) {
    return null
  }

  const { seats, waitlist } = lists
  const createGuestFromSeat = ({ dragId, guest }: SeatWithId): GuestWithId => ({
    dragId, ...guest!,
  }) // todo: handle if there's no guest (can't drop blocked or vacant seat to waitlist)
  const createSeatFromGuest = ({ dragId, ...guest }: GuestWithId): SeatWithId => ({
    dragId, guestId: guest.id, guest, reserved: false, description: null, number: -1,
  })

  type DragAndDropItem = {
    WaitlistSlot?: GuestWithId;
    WaitlistSlotIndex?: number;
    Seat?: SeatWithId;
    SeatIndex?: number;
  }

  const onMove = (fromId: string, toId: string) => {
    setLists((prevLists) => {
      if (!prevLists) {
        return prevLists
      }

      const updatedLists = { seats: [...prevLists?.seats], waitlist: [...prevLists?.waitlist] }
      const { seats: prevSeats, waitlist: prevWaitlist } = updatedLists

      const from: DragAndDropItem = {}
      const to: DragAndDropItem = {}

      from.WaitlistSlot = prevWaitlist.find(({ dragId }) => dragId === fromId)
      if (from.WaitlistSlot) {
        from.WaitlistSlotIndex = prevWaitlist.indexOf(from.WaitlistSlot)
      } else {
        from.Seat = prevSeats.find(({ dragId }) => dragId === fromId)
        if (from.Seat) {
          from.SeatIndex = prevSeats.indexOf(from.Seat)
        }
      }

      if (!(from.Seat || from.WaitlistSlot)) {
        return prevLists
      }

      if (toId === Placeholders.Seats) {
        to.SeatIndex = 0
      } else if (toId === Placeholders.Waitlists) {
        to.WaitlistSlotIndex = 0
      } else {
        to.Seat = seats.find(({ dragId }) => dragId === toId)
        if (to.Seat) {
          to.SeatIndex = prevSeats.indexOf(to.Seat)
        } else {
          to.WaitlistSlot = prevWaitlist.find(({ dragId }) => dragId === toId)
          if (to.WaitlistSlot) {
            to.WaitlistSlotIndex = prevWaitlist.indexOf(to.WaitlistSlot)
          }
        }
      }

      if (!(to.SeatIndex !== undefined || to.WaitlistSlotIndex !== undefined)) {
        return prevLists
      }

      if (from.SeatIndex !== undefined) {
        if (to.WaitlistSlotIndex !== undefined) {
          prevSeats[from.SeatIndex] = { // todo: make it nicer by extracting it to a function
            dragId: "vacant_1",
            guest: null,
            guestId: null,
            number: -1,
            reserved: false,
            description: null,
          }
        } else {
          prevSeats.splice(from.SeatIndex, 1)
        }
      } else if (from.WaitlistSlotIndex !== undefined) {
        prevWaitlist.splice(from.WaitlistSlotIndex, 1)
      }

      if (to.SeatIndex !== undefined) {
        if (from.WaitlistSlotIndex !== undefined) {
          if (to.Seat!.guest || to.Seat!.reserved) {
            return prevLists // todo: this should be improved
          }

          prevSeats[to.SeatIndex] = {
            ...createSeatFromGuest(from.WaitlistSlot!),
            dragId: prevSeats[to.SeatIndex].dragId,
          }
        } else {
          prevSeats.splice(
            to.SeatIndex,
            0,
            from.Seat!,
          )
        }
      } else if (to.WaitlistSlotIndex !== undefined) {
        prevWaitlist.splice(
          to.WaitlistSlotIndex,
          0,
          from.WaitlistSlot || createGuestFromSeat(from.Seat!),
        )
      }

      return updatedLists
    })
  }

  const onDrop = () => {
    onUpdateLists(lists)
  }

  return (
    <>
      <Box w={2 / 3} mx="auto" color="gray.600">
        <Heading as="h2" fontSize="md" borderBottomWidth={1} py={4}>
          <Flex align="center"><Icon icon="invite-seat" size={6} mr={4} /> Seats</Flex>
        </Heading>
        <OrderedList styleType="none" m={0}>
          {seats.map((seat) => {
            const {
              guest, reserved, number, dragId, description,
            } = seat

            const content = (previewRef) => {
              if (guest) {
                const { checkedIn, status, name } = guest

                return (
                  <GuestItem
                    guest={guest}
                    seatNumber={number}
                    onRemoveGuestFromSeat={() => onResetSeat(number)}
                    onMoveGuestToWaitlist={onMoveToWaitlist}
                    canEditSeating={canEditSeating}
                  >
                    <Box ref={previewRef}>
                      <Icon
                        icon="dot"
                        size={4}
                        mr={2}
                        color={`${statusColor(checkedIn, status)}.300`}
                      /> {name}
                    </Box>
                  </GuestItem>
                )
              }

              if (reserved) {
                return (
                  <BlockedSeatItem
                    seat={seat}
                    onRemovePlaceholder={() => onResetSeat(number)}
                    onEditPlaceholder={onEditPlaceholder}
                    canEditSeating={canEditSeating}
                  >
                    <Box ref={previewRef}><SeatPlaceholderButton label={description || "Blocked"} color="gray.500" /></Box>
                  </BlockedSeatItem>
                )
              }

              return (
                <VacantSeatItem
                  seat={seat}
                  onAssignGuest={onAssignGuest}
                  onAddPlaceholder={onAddPlaceholder}
                  canEditSeating={canEditSeating}
                >
                  <Box ref={previewRef}><Icon icon="dot" mr={2} /> Vacant</Box>
                </VacantSeatItem>
              )
            }

            return (
              <Item
                key={dragId}
                dragId={dragId}
                number={number}
                onMove={onMove}
                onDrop={onDrop}
                onDelete={() => onResetSeat(number)}
                editing={editing}
              >
                {content}
              </Item>
            )
          })}
          {editing && (
            <Item
              dragId={Placeholders.Seats}
              onMove={onMove}
              onDrop={onDrop}
              editing={editing}
              show={!seats.length}
              draggable={false}
            >
              Drag a guest here to add them to the table
            </Item>
          )}
        </OrderedList>
      </Box>
      {(waitlist.length || editing) && (
        <Box w={2 / 3} mx="auto" color="gray.600">
          <Heading as="h2" fontSize="md" borderBottomWidth={1} py={4}>
            <Flex align="center"><Icon icon="past-events" size={6} mr={4} /> Waitlist</Flex>
          </Heading>
          <OrderedList styleType="none" m={0}>
            {waitlist.map((guest, index) => {
              const {
                dragId, name, id, checkedIn, status,
              } = guest

              return (
                <Item
                  key={dragId}
                  dragId={dragId}
                  number={index + 1}
                  onMove={onMove}
                  onDrop={onDrop}
                  onDelete={() => onDeleteWaitlistedGuest(id)}
                  editing={editing}
                >
                  {(previewRef) => (
                    <GuestItem
                      guest={guest}
                      onRemoveGuestFromWaitlist={onDeleteWaitlistedGuest}
                      onMoveGuestToOpenSeat={onMoveGuestToOpenSeat}
                      canEditSeating={canEditSeating}
                    >
                      <Box ref={previewRef}>
                        <Icon
                          icon="dot"
                          size={4}
                          mr={2}
                          color={`${statusColor(checkedIn, status)}.300`}
                        /> {name}
                      </Box>
                    </GuestItem>
                  )}
                </Item>
              )
            })}
            {editing && (
              <Item
                dragId={Placeholders.Waitlists}
                onMove={onMove}
                onDrop={onDrop}
                editing={editing}
                show={!waitlist.length}
                draggable={false}
              >
                Drag a guest here to add them to the waitlist
              </Item>
            )}
          </OrderedList>
        </Box>
      )}
    </>
  )
}

const statusColor = (checkedIn, status) => {
  if (checkedIn) {
    return "green"
  } if (status === GuestStatus.Confirmed) {
    return "blue"
  } if (status === GuestStatus.Declined) {
    return "red"
  }

  return "gray"
}

export default Lists
