import React, { Fragment, ReactNode } from "react"
import Modal from "components/dialogs/Modal"
import SubmitButton from "components/Buttons/SubmitButton"
import CancelButton from "components/Buttons/CancelButton"
import DangerButton from "components/Buttons/DangerButton"
import Button from "components/Buttons/Button"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { ButtonProps } from "@chakra-ui/react"
import ConfirmDelete, { Props as ConfirmDeleteProps } from "../Confirm/ConfirmDelete"

type Props = {
  title: ReactNode | string
  initialValues?: object
  onClose: (responseData?) => void
  onSubmit: (values) => any | Promise<any>
  onDanger?: ((values?) => void) | null
  onBack?: ((values?) => void)
  submitText?: string
  submitColorScheme?: ButtonProps["colorScheme"]
  children: ReactNode | ((props) => ReactNode)
  validationSchema?: any
  validate?: (values) => any
  noPadding?: boolean
  hideActions?: boolean
  icon?: string
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "full"
  h?: string
  dangerProps?: Omit<ConfirmDeleteProps, "children">
  closeOnEsc?: boolean
  loading?: boolean
  hideCancel?: boolean
  submitIsDisabled?: boolean
}

const ModalForm = ({
  initialValues = {},
  title,
  onClose,
  onSubmit,
  onDanger,
  onBack,
  submitText = "Save",
  submitColorScheme = "button.primary",
  children,
  validationSchema,
  validate,
  noPadding = false,
  hideActions = false,
  icon,
  size = "2xl",
  h,
  dangerProps,
  closeOnEsc = true,
  loading,
  hideCancel,
  submitIsDisabled,
}: Props) => {
  const {
    control, handleSubmit, watch, formState: { isValid, isSubmitting, errors }, setValue, reset,
  } = useForm({
    defaultValues: initialValues,
    resolver: validationSchema ? yupResolver(validationSchema) : validate,
    mode: "onChange",
  })

  let resolvedChildren = children
  if (typeof children === "function") {
    resolvedChildren = children({
      control, watch, setValue, isValid, errors, handleSubmit, reset,
    })
  }

  const defaultActions = [
    !hideCancel && <CancelButton onClick={onClose} />,
    <SubmitButton
      colorScheme={submitColorScheme}
      disabled={!isValid || submitIsDisabled}
      submitting={isSubmitting || loading}
      text={submitText}
    />]

  if (onDanger || onBack) {
    const values = watch()

    if (onDanger) {
      const DangerWrapper = dangerProps !== undefined ? ConfirmDelete : Fragment
      defaultActions.unshift(
        <DangerWrapper {...dangerProps}>
          <DangerButton onClick={() => onDanger(values)} />
        </DangerWrapper>,
      )
    } else if (onBack) {
      defaultActions.unshift(<Button variant="outline" colorScheme="button.light" onClick={() => onBack(values)}>Back</Button>)
    }
  }

  return (
    <Modal
      show
      onSubmit={handleSubmit(onSubmit)}
      icon={icon}
      hide={onClose}
      title={title}
      actions={hideActions ? [] : defaultActions}
      noPadding={noPadding}
      size={size}
      h={h}
      closeOnEsc={closeOnEsc}
    >
      {resolvedChildren as ReactNode}
    </Modal>
  )
}

export default ModalForm
