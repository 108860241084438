import React, { ReactNode } from "react"
import { Flex } from "@chakra-ui/react"
import Icon from "components/elements/Icon"

type Props = {
  children: ReactNode
  icon?: string
  isSeparate?: boolean
}

const FormHeader = ({ children, icon, isSeparate = true }: Props) => (
  <Flex
    borderWidth={1}
    borderBottomWidth={0}
    borderTopRadius={isSeparate ? "md" : "unset"}
    borderTopWidth={isSeparate ? 1 : 0}
    px={6}
    py={4}
    backgroundColor="gray.50"
  >
    {icon && <Icon icon={icon} size={7} mr={4} />}
    {children}
  </Flex>
)

export default FormHeader
