import React, { useState } from "react"
import { AgendaRow, AgendaHeading as DioboxAgendaHeading } from "sharedTypes"
import { AGENDA_ROW_TYPES } from "utilities/enums"
import EditableSection from "components/layout/EditableSection"
import { Heading } from "@chakra-ui/react"
import AgendaRowForm from "../Form"

const AgendaHeading = (agendaHeading: DioboxAgendaHeading) => {
  const [edit, setEdit] = useState(false)

  const {
    title,
  } = agendaHeading

  return (
    <EditableSection onEdit={() => setEdit(true)}>
      <Heading size="lg" fontWeight="normal">{title}</Heading>
      {edit && (
        <AgendaRowForm
          agendaRow={agendaHeading as AgendaRow}
          agendaRowType={AGENDA_ROW_TYPES.heading}
          onHide={() => { setEdit(false) }}
        />
      )}
    </EditableSection>
  )
}

export default AgendaHeading
