import { Flex, Text } from "@chakra-ui/react"
import React from "react"

const HorizontalStat = ({ label, value }) => (
  <Flex align="center">
    <Text
      as="span"
      fontWeight="600"
      fontSize="2xl"
      mr={2}
    >{value}
    </Text>
    <Text as="span">{label}</Text>
  </Flex>
)

export default HorizontalStat
