import React from "react"
import Modal from "components/dialogs/Modal"
import UpgradeAlert from "components/elements/UpgradeAlert"
import Button from "components/Buttons/Button"
import {
  Box, Heading, Spacer, Text,
} from "@chakra-ui/react"
import pluralize from "pluralize"
import { asNumber } from "utilities/strings"

type Props = {
  numberOfRecords: number
  maxNumberOfRecords: number | null
  remainingSpots: number | null
  onClickBack: () => void
  onClose: () => void
}

const Upgrade = ({
  numberOfRecords, maxNumberOfRecords, remainingSpots, onClickBack, onClose,
}: Props) => (
  <Modal
    icon="import"
    size="3xl"
    title="Import Guests"
    actions={[
      <Button variant="outline" colorScheme="button.light" onClick={onClickBack}>Back</Button>,
      <Spacer />,
      <Button variant="outline" colorScheme="button.light" onClick={onClose}>Cancel</Button>,
    ]}
    show
    hide={onClose}
    closeOnEsc={false}
  >
    <Box mx={10} my={20}>
      <Heading
        fontWeight="normal"
        size="lg"
        textAlign="center"
        mb={1}
      >Guest Limit Reached
      </Heading>
      <Text mb={5} align="center" lineHeight={1.3}>
        Your import file contains {asNumber(numberOfRecords)} records, which would exceed your
        guest limit of {asNumber(maxNumberOfRecords!)}.
        {!!remainingSpots && <Box>You have {asNumber(remainingSpots)} remaining {pluralize("guest", remainingSpots)} in this event.</Box>}
      </Text>
      <UpgradeAlert text="Upgrade to increase your import limit" />
    </Box>
  </Modal>
)

export default Upgrade
