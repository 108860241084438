import React from "react"
import { FormControl, FormLabel, Switch } from "@chakra-ui/react"
import { Control, FieldValues, useController } from "react-hook-form"

type Props<T extends FieldValues> = {
  name: string
  control: Control<T, object>
  label?: string
  disabled?: boolean
  mb?: string|number
  w?: string
  fontSize?: string
  fontWeight?: string
  size?: string
  onChange?: (event) => void
}

const SwitchField = <T extends FieldValues, >({
  name, control, label, disabled, mb = 4, w, fontSize, fontWeight = "bold", size, onChange = () => {},
}: Props<T>) => {
  const { field } = useController<any>({ name, control })

  return (
    <FormControl
      mb={mb}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      isDisabled={disabled}
      w={w}
    >
      {label && <FormLabel fontSize={fontSize} fontWeight={fontWeight} mb={0}>{label}</FormLabel>}
      <Switch
        size={size}
        isChecked={Boolean(field.value)}
        isDisabled={disabled}
        onChange={(event) => {
          onChange(event)
          field.onChange(event.target.checked)
        }}
      />
    </FormControl>
  )
}

export default SwitchField
