import Icon from "components/elements/Icon"
import NavLink from "components/Navigation/NavLink"
import React from "react"
import { Text, Box } from "@chakra-ui/react"

type Props = {
  title: string;
  icon?: string;
  path?: string;
  onClick?: (event: React.MouseEvent) => void;
}

const SubNavItem = ({
  icon,
  title,
  path,
  onClick,
  ...props
}: Props) => {
  if (path) {
    return (
      <NavLink
        display="block"
        borderLeftWidth={5}
        borderColor="gray.900"
        px={8}
        lineHeight="taller"
        activeProps={{
          bg: "black",
          borderColor: "blue.500",
          fontWeight: "bold",
          color: "white",
        }}
        to={path}
        {...props}
      >
        {icon && <><Icon icon={icon} mx={1} mb={1} verticalAlign="middle" /> {title}</>}
        {!icon && <Text as="span" ml={7}>{title}</Text>}
      </NavLink>
    )
  }

  return (
    <Box
      cursor="pointer"
      role="link"
      borderLeftWidth={5}
      borderColor="gray.900"
      lineHeight="taller"
      px={8}
      onClick={onClick}
    >
      <Text as="span" ml={7}>{title}</Text>
    </Box>
  )
}

export default SubNavItem
