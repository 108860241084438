import React, { MouseEventHandler } from "react"
import { As, Button, CheckboxProps } from "@chakra-ui/react"
import Icon from "components/elements/Icon"

export const pillButtonActiveColorScheme = { borderColor: "blue.400", backgroundColor: "blue.50" }

type Props = {
  title: string
  icon: string
  active?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  _checked?: {
    borderColor: string
    backgroundColor: string
  }
  cursor?: CheckboxProps["cursor"]
  as?: As
}

const PillButton = ({
  title, icon, active, onClick, ...rest
}: Props) => {
  const activeProps = active ? pillButtonActiveColorScheme : {}

  return (
    <Button
      backgroundColor="gray.50"
      borderRadius="2xl"
      variant="outline"
      px={2.5}
      leftIcon={<Icon color="blue.400" icon={icon} size={4} />}
      {...activeProps}
      onClick={onClick}
      {...rest}
      _hover={{}}
      _active={{}}
    >{title}
    </Button>
  )
}

export default PillButton
