import React, { useState } from "react"
import {
  FormControl,
  FormLabel,
  Radio as ChakraRadio,
  RadioGroup,
  Stack,
  Box,
  Flex,
  SpaceProps,
  LayoutProps,
  Text,
} from "@chakra-ui/react"
import { Control, useController } from "react-hook-form"
import { Boolean } from "utilities/enums"
import PriceField from "components/ReactHookForm/PriceField"

type Props = {
  mb?: number;
  control: Control<any>;
  spacing?: number;
  enabledLabel: string;
  disabledLabel: string;
  w: LayoutProps["width"];
  rightText?: string;
}

const PriceWithRadio = ({
  mb = 4,
  control,
  spacing = 3,
  enabledLabel,
  disabledLabel,
  w,
  rightText,
}: Props) => {
  const { field: inputField } = useController({ name: "price", control })
  const { field: radioField } = useController({ name: "variablePrice", control })

  const isLessOrEqualZero = inputField?.value <= 0
  const [isEnabled, setIsEnabled] = useState(
    // If we have a price (we're in edit form mode) and the price is 0,
    // we should disable the price input
    isLessOrEqualZero ? Boolean.False : Boolean.True as string,
  )

  return (
    <FormControl mb={mb}>
      <Flex align="flex-start">
        <FormLabel fontWeight="bold" w={1 / 4}>Ticket price</FormLabel>
        <RadioGroup
          w="100%"
          value={isEnabled}
          onChange={(value) => {
            setIsEnabled(value)
            radioField.onChange(value !== Boolean.True)
          }}
        >
          <Stack
            align="center"
            spacing={spacing}
          >
            <Radio label={enabledLabel} value={Boolean.True} />
            <PriceField
              name="price"
              control={control}
              w={w}
              disabled={isEnabled === Boolean.False}
              rightText={rightText}
              precision={2}
            />
            <Radio label={disabledLabel} value={Boolean.False} />
          </Stack>
          {(isEnabled === Boolean.True && isLessOrEqualZero) && <Text color="red.400">You must provide a price</Text>}
        </RadioGroup>
      </Flex>
    </FormControl>
  )
}

type RadioProps = {
  px?: SpaceProps["padding"];
  horizontal?: boolean;
  label: string
  value: string
}

const Radio = ({
  px, horizontal, label, value,
}: RadioProps) => (
  <ChakraRadio
    w="100%"
    px={px}
    alignItems="flex-start"
    value={value}
  >
    <Box ml={1} mt={horizontal ? "-3px" : "-6px"}>{label}</Box>
  </ChakraRadio>
)

export default PriceWithRadio
