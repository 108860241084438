import Icon from "components/elements/Icon"
import React, { useState } from "react"
import pluralize from "pluralize"
import { Transaction } from "sharedTypes"
import { asDate } from "utilities/strings"
import TextField from "components/ReactHookForm/TextField"
import Select from "components/ReactHookForm/Select"
import TextArea from "components/ReactHookForm/TextArea"
import DatePickerField from "components/ReactHookForm/DatePickerField"
import ActivityForm from "../Form"

type Props = {
  activity?: Transaction;
}

const Accommodation = ({ activity }: Props) => {
  const [days, setDays] = useState(1)
  let initialValues
  if (activity) {
    initialValues = {
      transactionDate: asDate(activity.transactionDate, "yyyy-MM-dd"),
      duration: activity.data.duration,
      expensesPaidBy: activity.data.expensesPaidBy,
      staysIn: activity.data.staysIn,
      details: activity.details,
    }
  } else {
    initialValues = {
      transactionDate: asDate(new Date(), "yyyy-MM-dd"),
      duration: 1,
      expensesPaidBy: "Guest pays",
      staysIn: "Hotel",
      details: "",
    }
  }

  return (
    <ActivityForm
      title={<><Icon icon="accommodation" size={6} mr={2} /> Accommodation</>}
      fields={(control) => (
        <>
          <DatePickerField
            name="transactionDate"
            control={control}
            label="Date"
            placeholder="Select Date"
          />
          <TextField
            name="duration"
            control={control}
            label="Duration"
            type="number"
            onChange={(e) => setDays(e.target.value)}
            rightElement={pluralize("day", days)}
          />
          <Select
            name="staysIn"
            control={control}
            label="Stays in"
            options={[
              { label: "Hotel", value: "Hotel" },
              { label: "Apartment", value: "Apartment" },
              { label: "Home", value: "Home" },
              { label: "Other", value: "Other" },
            ]}
          />
          <Select
            name="expensesPaidBy"
            control={control}
            label="Expenses"
            options={[
              { label: "Guest pays", value: "Guest pays" },
              { label: "Event pays", value: "Event pays" },
            ]}
          />
          <TextArea
            name="details"
            control={control}
            label="Details"
            placeholder="Additional details (optional)"
          />
        </>
      )}
      initialValues={initialValues}
      apiPayload={({
        transactionDate, details, duration, expensesPaidBy, staysIn,
      }) => ({
        type: "AccommodationTransaction",
        transactionDate,
        details,
        data: {
          duration,
          expensesPaidBy,
          staysIn,
        },
      })}
      activity={activity}
    />
  )
}

export default Accommodation
