import React from "react"
import { Form } from "sharedTypes"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import EditorSection from "components/Builder/components/EditorSection"
import RegistrationSettingsSwitches from "pages/EventShow/Forms/SelectedForm/RegistrationFields/RegistrationSettingsSwitches"
import { Control } from "react-hook-form"
import formatRegistrationFields from "pages/EventShow/Forms/SelectedForm/RegistrationFields/formatRegistrationFields"

type Props = {
  form: Form
  control: Control<any, any>
  enableNameAndEmailToggle?: boolean
}

const RegistrationSettings = ({ form, control, enableNameAndEmailToggle }: Props) => {
  const fields = formatRegistrationFields(form, enableNameAndEmailToggle)

  return (
    <>
      <SidePanelHeader>Registration</SidePanelHeader>
      <EditorSection>
        <RegistrationSettingsSwitches fields={fields} control={control} />
      </EditorSection>
    </>
  )
}

export default RegistrationSettings
