import React from "react"
import { Text, Box } from "@chakra-ui/react"
import { Control, useController } from "react-hook-form"
import TextField from "./TextField"

type Props = {
  name: string
  control: Control<any>
  placeholder?: string
  disabled?: boolean
  showLabel?: boolean
  variant?: "outline" | "filled" | "flushed" | "unstyled";
}

const UrlField = ({
  name,
  control,
  placeholder,
  disabled,
  showLabel,
  variant = "outline",
}: Props) => {
  const urlValidatorRegex = /^(?:(?:(https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?:[\w-]+\.)+[a-z]{2,}|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:[/?#]\S*)?$/i

  const {
    field,
    field: { value: fieldValue },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    rules: { pattern: urlValidatorRegex },
  })

  const handleBlur = () => {
    if (fieldValue && !fieldValue.startsWith("http://") && !fieldValue.startsWith("https://")) {
      field.onChange(`http://${fieldValue}`)
    }
  }

  return (
    <Box w="100%">
      {showLabel && (
        <Box mb={4}>
          <Text fontWeight="semibold">URL</Text>
        </Box>
      )}
      <TextField
        disabled={disabled}
        variant={variant}
        control={control}
        placeholder={placeholder}
        name={name}
        onBlur={handleBlur}
        mb={1}
      />
      {invalid && <Text textColor="red.500">Link is not valid</Text>}
    </Box>
  )
}

export default UrlField
