import React from "react"
import { BuilderBlock, BuilderBlockType } from "sharedTypes/builder"
import { FormQuestion } from "sharedTypes"
import { Control, useWatch } from "react-hook-form"
import { RichTextFieldType } from "components/ReactHookForm/RichTextField"
import SidePanelForm from "../components/SidePanelForm"
import QuestionFields from "./Fields/QuestionFields"
import ImageFields from "./Fields/ImageFields"
import HtmlFields from "./Fields/HtmlFields"
import DividerFields from "./Fields/DividerFields"
import QRCodeFields from "./Fields/QRCodeFields"
import TextFields from "./Fields/TextFields"
import HeadingFields from "./Fields/HeadingFields"
import SpacerFields from "./Fields/SpacerFields"
import ButtonFields from "./Fields/ButtonFields"

type Props = {
  selectedBlock: BuilderBlock
  onChange: (data: {}) => void
  onCancel: (data: {}) => void
  imageUploadUrl: string
  selectedBlockId: number
  onDeleteBlock?: () => void
  richTextFieldType?: RichTextFieldType
}

const EditorPanel = ({
  selectedBlock: { type, data },
  onChange,
  imageUploadUrl,
  onCancel,
  selectedBlockId,
  onDeleteBlock,
  richTextFieldType,
}: Props) => (
  <SidePanelForm
    onChange={onChange}
    onCancel={onCancel}
    initialValues={{ selectedBlockType: type, ...data }}
    selectedBlockId={selectedBlockId}
  >
    {({ control }) => (
      <Fields
        imageUploadUrl={imageUploadUrl}
        control={control}
        onDeleteBlock={onDeleteBlock}
        onChange={onChange}
        richTextFieldType={richTextFieldType}
      />
    )}
  </SidePanelForm>
)

type FieldsProps = {
  imageUploadUrl: string
  control: Control<any, any>
  onDeleteBlock?: () => void
  onChange: (updatedQuestion: FormQuestion) => void
  richTextFieldType?: RichTextFieldType
}

const Fields = ({
  imageUploadUrl, control, onDeleteBlock, onChange, richTextFieldType,
}: FieldsProps) => {
  const data = useWatch({ control })

  const { selectedBlockType: type } = data
  switch (type) {
    case BuilderBlockType.TEXT:
      return <TextFields control={control} type={richTextFieldType} />
    case BuilderBlockType.HEADING:
      return <HeadingFields control={control} />
    case BuilderBlockType.SPACER:
      return <SpacerFields control={control} />
    case BuilderBlockType.HTML:
      return <HtmlFields control={control} />
    case BuilderBlockType.DIVIDER:
      return <DividerFields control={control} />
    case BuilderBlockType.QR_CODE:
      return <QRCodeFields control={control} />
    case BuilderBlockType.IMAGE:
      return (
        <ImageFields imageUploadUrl={imageUploadUrl} control={control} />
      )
    case BuilderBlockType.BUTTON:
      return (
        <ButtonFields control={control} />
      )
    case BuilderBlockType.QUESTION:
      return (
        <QuestionFields
          data={data as FormQuestion}
          onDeleteBlock={onDeleteBlock}
          onChange={onChange}
        />
      )
    default:
      return <></>
  }
}

export default EditorPanel
