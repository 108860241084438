import React, { ReactNode } from "react"
import { ListItem, Link as ChakraLink } from "@chakra-ui/react"

interface FormTypeSelectListItemProps {
  children: ReactNode
  selected?: boolean
  onClick?: () => void
  disabled?: boolean
}

const FormTypeSelectListItem = ({
  selected, onClick, children, disabled,
}: FormTypeSelectListItemProps) => {
  const disabledProps = {
    bg: "gray.200",
    opacity: 0.2,
    onClick: () => {},
    cursor: "not-allowed !important",
  }

  const selectedProps = {
    bg: "ui.selectedItemBg",
  }

  return (
    <ListItem
      borderBottomWidth={1}
    >
      <ChakraLink
        onClick={onClick}
        {...selected && selectedProps}
        {...disabled && disabledProps}
        h={16}
        p={6}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        _hover={{ textDecoration: "none" }}
      >
        {children}
      </ChakraLink>
    </ListItem>
  )
}

export default FormTypeSelectListItem
