import React from "react"
import { Heading, Box } from "@chakra-ui/react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextField from "components/ReactHookForm/TextField"
import ToggleButtons from "components/ReactHookForm/ToggleButtons"
import SeatPlaceholderButton from "components/Buttons/SeatPlaceholderButton"

type Props = {
  onSubmit: (description) => void;
  onClose: () => void;
  description?: string | null;
}

const predefinedDescriptions = ["Placeholder", "Plus-One", "Blocked"]

const PlaceholderForm = ({ onClose, onSubmit, description }: Props) => {
  const initialValues = {
    description: "Placeholder",
    custom: "Custom",
  }

  if (description) {
    if (predefinedDescriptions.includes(description)) {
      initialValues.description = description
    } else {
      initialValues.description = "Custom"
      initialValues.custom = description
    }
  }

  return (
    <ModalForm
      onSubmit={
        (values) => {
          onSubmit(values.description === "Custom" ? values.custom : values.description)
          onClose()
        }
      }
      onClose={onClose}
      title="Placeholder Seat"
      initialValues={initialValues}
      size="md"
    >
      {({ control, watch, setValue }) => {
        const custom = watch("custom")

        return (
          <Box w="fit-content" m="auto" textAlign="center">
            <Heading size="md" mb={4}>Label</Heading>
            <ToggleButtons name="description" control={control} columns={1}>
              {[
                ...predefinedDescriptions.map((value) => ({
                  button: (selected) => <Label text={value} selected={selected} />,
                  value,
                })),
                {
                  button: (selected) => <Label text={custom} selected={selected} />,
                  value: "Custom",
                },
              ]}
            </ToggleButtons>
            <TextField name="custom" control={control} onFocus={() => setValue("description", "Custom")} />
          </Box>
        )
      }}
    </ModalForm>
  )
}

const Label = ({ text, selected }: {text: string, selected: boolean}) => <Box mt={4} textAlign="center"><SeatPlaceholderButton label={text} color={`gray.${selected ? "500" : "300"}`} /></Box>

export default PlaceholderForm
