import React from "react"
import * as Yup from "yup"
import {
  Box, Heading, ListItem, OrderedList, Text,
} from "@chakra-ui/react"
import { useCurrentEvent } from "queries/events"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import RadioButtons from "components/ReactHookForm/RadioButtons"
import Select from "components/ReactHookForm/Select"

enum ImportMethods {
  Create = "create",
  Update = "update",
}

type Props = {
  values: { updateExistingPeople: boolean, guestList: string }
  numberOfRecords: number;
  onSaveImport: (updateExistingPeople: boolean, guestList: string) => void;
  onClickBack: (updateExistingPeople: boolean, guestList: string) => void;
  onClose: () => void;
}

const ImportOptions = ({
  values: { updateExistingPeople, guestList },
  numberOfRecords,
  onSaveImport,
  onClickBack,
  onClose,
}: Props) => {
  const { data: currentEvent } = useCurrentEvent()

  const guestLists = currentEvent.eventGroups.map(
    ({ id, title }) => ({ label: title, value: id.toString() }),
  )
  guestLists.unshift({ label: "All Guests", value: "all" })

  const ImportOptionsSchema = Yup.object().shape({
    importMethod: Yup.string().required(),
    guestList: Yup.string().required(),
  })

  const createLabel = (
    <Box>
      <Text color="gray.700" fontSize="xl" fontWeight="semibold">Create new people</Text>
      <Text color="gray.600" size="sm">Always creates a new guest for every row in your import
        file.
      </Text>
    </Box>
  )

  const updateLabel = (
    <Box>
      <Text color="gray.700" fontSize="xl" fontWeight="semibold">Update existing people</Text>
      <Text color="gray.600" size="sm">Uses the guest email address in your import file to
        prevent creating duplicates, providing
        these benefits:
      </Text>
      <OrderedList pl={4} color="gray.600" size="xs" styleType="decimal">
        <ListItem>
          If a guest exists in this event, Diobox will merge your data into existing guests.
        </ListItem>
        <ListItem>
          If a guest exists in another event, Diobox will merge your data and add that guest to
          this event, which helps building guest history across events.
        </ListItem>
      </OrderedList>
    </Box>
  )

  return (
    <ModalForm
      icon="import"
      size="3xl"
      initialValues={{
        importMethod: updateExistingPeople ? ImportMethods.Update : ImportMethods.Create,
        guestList,
      }}
      validationSchema={ImportOptionsSchema}
      onSubmit={(form) => {
        onSaveImport(form.importMethod === ImportMethods.Update, form.guestList)
      }}
      submitText="Import"
      onBack={
        ({ importMethod, guestList: newGuestList }) => {
          onClickBack(importMethod === ImportMethods.Update, newGuestList)
        }
      }
      onClose={onClose}
      closeOnEsc={false}
      title="Import Guests"
    >
      {({ control }) => (
        <>
          <Heading mb={4} size="md">Your file contains {numberOfRecords} records.</Heading>
          <Text mb={6} fontSize="lg">How do you want to handle duplicates in your file?</Text>
          <RadioButtons
            name="importMethod"
            control={control}
            options={[
              { label: createLabel, value: ImportMethods.Create },
              { label: updateLabel, value: ImportMethods.Update },
            ]}
            spacing={6}
          />
          <Select
            vertical
            name="guestList"
            control={control}
            label="Which guest list do you want to import to?"
            options={guestLists}
          />
        </>
      )}
    </ModalForm>
  )
}

export default ImportOptions
