import React, { ReactNode, useState } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import ErrorView from "./ErrorView"

type Props = {
  children: ReactNode
}

const StripeProvider = ({ children }: Props) => {
  const [error, setError] = useState<boolean>(false)

  const publishableKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY
  if (!publishableKey) {
    setError(true)

    throw Error("Stripe publishable key is not defined.")
  }
  const stripePromise = loadStripe(publishableKey).catch(() => {
    setError(true)

    return null
  })

  if (error) {
    return <ErrorView />
  }

  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  )
}

export default StripeProvider
