import React, { useState } from "react"
import Icon from "components/elements/Icon"
import Modal from "components/dialogs/Modal"
import _ from "lodash"
import {
  EventGroup, GuestFilter, GuestFilterType, ModalName, Segment, AccountUser, EventCounts,
} from "sharedTypes"
import { Box, Flex, Text } from "@chakra-ui/react"
import useModal from "services/useModal"
import Filter from "./FilterGroup/Filter"
import FilterGroup from "./FilterGroup"
import CheckInAlert from "../../SelectedGuest/Show/CheckInAlert"

type Props = {
  counts: EventCounts;
  eventGroups: EventGroup[];
  segmentsEnabled: boolean;
  segments: Segment[];
  selected: {segmentId?: string; groupId?: string | number;};
  onChange: (filter) => void;
  canEditGuests: boolean;
  teamMembers: AccountUser[];
}

const Filters = ({
  counts, eventGroups, segmentsEnabled, segments, onChange, selected, canEditGuests, teamMembers,
}: Props) => {
  const showModal = useModal()
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [editCheckInAlert, setEditCheckInAlert] = useState<EventGroup | null>(null)

  const selectFilter = (filter) => {
    onChange(filter)
    setShowFilterModal(false)
  }

  const filterProperties = (filter) => (
    {
      ...filter,
      key: filter.groupId || filter.segmentId,
      onSelect: () => selectFilter(filter),
      selected: isSelected(filter, selected),
      onEditCheckinAlert: canEditGuests ? () => setEditCheckInAlert(filter.eventGroup) : () => {
      },
    })

  const filters = allFilters(
    eventGroups, counts, segmentsEnabled ? segments : [],
  )

  const selectedFilter = findSelectedFilter(filters, selected)

  const generateFilters = (type: GuestFilterType) => filters
    .filter((filter) => filter.type === type)
    .map((filter) => filterProperties(filter))

  return (
    <>
      <Flex as="button" align="center" type="button" onClick={() => setShowFilterModal(true)}>
        <Icon icon={selectedFilter.icon || "dot"} size={5} mr={2} color={selectedFilter.iconColor} />
        <span>{selectedFilter.label} {
          selectedFilter.label !== "All Guests"
          && <Text as="span" fontSize="md">({selectedFilter.count})</Text>
        }
        </span>
        <Icon icon="down-arrow" ml={2} />
      </Flex>
      <Modal
        title="Lists"
        show={showFilterModal}
        hide={() => setShowFilterModal(false)}
        noPadding
      >
        <Box py={4}>
          <Filter {...generateFilters(GuestFilterType.All)[0]} />
          <FilterGroup
            label="Segments"
            filters={generateFilters(GuestFilterType.DefaultSegment)}
          />
          <FilterGroup
            label="Custom Segments"
            filters={generateFilters(GuestFilterType.CustomSegment)}
            editable
            onAdd={() => showModal(ModalName.SegmentForm)}
          />
          <FilterGroup
            editable={canEditGuests}
            label="Guest Lists"
            filters={generateFilters(GuestFilterType.EventGroup)}
            onAdd={() => showModal(ModalName.GuestListForm)}
          />
        </Box>
      </Modal>
      {
        editCheckInAlert
        && (
        <CheckInAlert
          teamMembers={teamMembers}
          guestList={editCheckInAlert}
          onClose={() => setEditCheckInAlert(null)}
        />
        )
      }
    </>
  )
}

const defaultFilters = (counts) => ([
  {
    type: GuestFilterType.All,
    groupId: "",
    icon: "guests",
    iconColor: "blue.500",
    label: "All Guests",
    count: counts.guests,
  },
  {
    type: GuestFilterType.DefaultSegment,
    groupId: "confirmed",
    iconColor: "blue.300",
    label: "Confirmed",
    count: counts.confirmedGuests,
  },
  {
    type: GuestFilterType.DefaultSegment,
    groupId: "unconfirmed",
    label: "Unconfirmed",
    iconColor: "gray.400",
    count: counts.unconfirmedGuests,
  },
  {
    type: GuestFilterType.DefaultSegment,
    groupId: "waitlisted",
    label: "Waitlisted",
    iconColor: "yellow.300",
    count: counts.waitlistedGuests,
  },
  {
    type: GuestFilterType.DefaultSegment,
    groupId: "declined",
    iconColor: "red.300",
    label: "Declined",
    count: counts.declinedGuests,
  },
  {
    type: GuestFilterType.DefaultSegment,
    groupId: "bounced",
    iconColor: "purple.300",
    label: "Bounced",
    count: counts.bouncedGuests,
  },
  {
    type: GuestFilterType.DefaultSegment,
    groupId: "seated",
    iconColor: "gray.600",
    label: "With Seats",
    count: counts.seatedGuests,
  },
  {
    type: GuestFilterType.DefaultSegment,
    groupId: "unseated",
    iconColor: "gray.200",
    label: "Without Seats",
    count: counts.unseatedGuests,
  },
  {
    type: GuestFilterType.DefaultSegment,
    groupId: "arrived",
    iconColor: "green.500",
    label: "Checked In",
    count: counts.arrivedGuests,
  },
  {
    type: GuestFilterType.DefaultSegment,
    groupId: "unarrived",
    iconColor: "orange.300",
    label: "Not Checked In",
    count: counts.unarrivedGuests,
  },
])

export const allFilters = (
  eventGroups: EventGroup[], counts, segments: Segment[],
) => {
  const eventGroupFilters = _.sortBy(
    eventGroups,
    "icon",
  ).map((g) => ({
    type: GuestFilterType.EventGroup,
    groupId: String(g.id),
    icon: g.icon ? `event-group-${g.icon}` : "tag",
    label: g.title,
    count: counts.eventGroups.find((eg) => eg.id === g.id)?.size || 0,
    eventGroup: g,
  }))

  const segmentFilters = segments.map((s) => ({
    type: GuestFilterType.CustomSegment,
    segmentId: s.id,
    icon: "dot",
    iconColor: `${s.color}.500`,
    label: s.name,
    count: counts.segments.find((segment) => s.id === segment.id)?.size || 0,
  }))

  const filters: GuestFilter[] = [
    ...defaultFilters(counts),
    ...eventGroupFilters,
    ...segmentFilters,
  ]

  return filters
}

const isSelected = (selected, current) => (
  selected.groupId === current.groupId
  && selected.segmentId === current.segmentId
)
export const findSelectedFilter = (filters, selected) => (
  filters.find((filter) => isSelected(selected, filter))
) || filters[0]

export const selectedFilter = (eventGroups, counts, segments, segmentId, groupId) => (
  findSelectedFilter(allFilters(eventGroups, counts, segments), { segmentId, groupId })
)

export const selectedFilterCount = (eventGroups, counts, segments, segmentId, groupId) => (
  selectedFilter(eventGroups, counts, segments, segmentId, groupId).count
)

export default Filters
