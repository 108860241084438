import Icon from "components/elements/Icon"
import React from "react"
import {
  Box, Text,
} from "@chakra-ui/react"

const FormResponses = ({ items }) => {
  const getAnswer = (answer) => {
    if (typeof answer === "object") {
      return Object.values(answer).map((text) => text).join(", ")
    }

    return answer
  }

  return (
    <Box py={2}>
      {items.map(
        ({ question, answer }, index) => (
          <div key={index}>
            <Icon icon="more" mr={2} color="gray.300" />
            {question}
            {answer && <> <Text as="span" color="blue.400">{getAnswer(answer)}</Text></>}
          </div>
        ),
      )}
    </Box>
  )
}

export default FormResponses
