import React from "react"
import {
  Box, Table, Tbody, Td, Tr,
} from "@chakra-ui/react"
import Panel from "components/elements/Panel"
import _ from "lodash"
import { FormQuestion } from "sharedTypes"
import StatusBar from "components/elements/StatusBar"
import { CHART_COLORS } from "utilities/constants"

const NO_RESPONSE_LABEL = "No Response"

type Props = {
  question: FormQuestion
}

const responseSummary = ({ responses }: FormQuestion) => Object.entries(
  Object.entries(responses).reduce((acc, [rawResponse, value]) => {
    const responseSet = JSON.parse(rawResponse)

    if (responseSet.length === 0) {
      acc[NO_RESPONSE_LABEL] = value
    }

    responseSet.forEach((response) => {
      if (acc[response]) {
        acc[response] += value
      } else {
        acc[response] = value
      }
    })

    return acc
  }, {} as Record<string, number>),
)
  .map(([name, value]) => ({ name, value }))
  .sort((a, b) => b.value - a.value)

const ResponseChart = ({ question, question: { responses, id } }: Props) => {
  if (!responses) {
    return null
  }

  const hasResponses = Object.keys(responses).length
  const data = hasResponses
    ? responseSummary(question)
    : question.choices.map((choice) => ({ name: choice, value: 0 }))

  return (
    <Box mb={8}>
      <Panel>
        <Table variant="unstyled" w="full">
          <Tbody>
            {data.map(({ name, value }, index) => {
              const ratio = value / _.sum(Object.values(responses)) || 0

              return (
                <Tr key={id + name + index} fontWeight="semibold">
                  <Td textAlign="right" p={0}>{name}</Td>
                  <Td w="60%" py={5}>
                    <StatusBar
                      h={7}
                      sections={[
                        { color: CHART_COLORS[index % CHART_COLORS.length], size: ratio * 100 },
                        { color: "gray.100", size: (1 - ratio) * 100 },
                      ]}
                    />
                  </Td>
                  <Td whiteSpace="nowrap" fontSize="sm" p={0}>{value} ({Math.round(ratio * 100)}%)</Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Panel>
    </Box>
  )
}

export default ResponseChart
