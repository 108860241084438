import React from "react"
import {
  Box, Divider, ListItem, Text, UnorderedList,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"

type Props = {
  title: string;
  icon: string;
  descriptionItems: string[];
  onSelect: () => void;
  selected: boolean;
  enabled?: boolean;
  centeredDescription?: boolean;
}

const ExportOption = ({
  title,
  icon,
  descriptionItems,
  onSelect,
  selected,
  enabled = true,
  centeredDescription = false,
}: Props) => {
  const descriptionList = descriptionItems
    .map((item, index) => <ListItem key={index} mb={2}>{item}</ListItem>)

  return (
    <Box
      borderWidth={1}
      borderColor={selected ? "blue.400" : "gray.400"}
      bg={selected ? "blue.50" : "gray.50"}
      p={5}
      borderRadius={5}
      onClick={onSelect}
      cursor="pointer"
      opacity={enabled ? 1 : 0.4}
      pointerEvents={enabled ? "all" : "none"}
    >
      <Box textAlign="center">
        <Icon icon={icon} size={12} />
        <Text fontSize="xl" textAlign="center">
          {title}
        </Text>
      </Box>
      <Divider my={4} />
      <UnorderedList {...centeredDescription ? { listStyleType: "none", textAlign: "center" } : {}}>
        {descriptionList}
      </UnorderedList>
    </Box>
  )
}

export default ExportOption
