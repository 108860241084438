import React from "react"
import { Flex, Text } from "@chakra-ui/react"
import { DioboxEvent } from "sharedTypes"
import Avatar from "./Avatar"

type Props = {
  event: DioboxEvent
}

const EventWithAvatar = ({ event: { title, website } }: Props) => (
  <Flex align="center">
    <Avatar
      width="50px"
      height="50px"
      src={website?.coverImageUrl}
      name={title}
      borderRadius="none"
    />
    <Text fontSize="md" ml={2} maxWidth={400} noOfLines={1}>{title}</Text>
  </Flex>
)

export default EventWithAvatar
