import React, { ComponentType, ReactNode, useState } from "react"
import {
  Box, Flex, Text,
} from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import { DioboxEvent, FormsTerminologyModalProps, Translations } from "sharedTypes"

type Props = {
  children: ReactNode
  title: string
  event: DioboxEvent
  Modal: ComponentType<FormsTerminologyModalProps>
  onSubmit: (values: any) => Promise<void>
  defaultTranslations: Translations
}

const BoxWithEditModal = ({
  children, title, event, Modal, onSubmit, defaultTranslations,
}: Props) => {
  const [edit, setEdit] = useState(false)

  const handleSubmit = async (values) => {
    await onSubmit(values)
    setEdit(false)
  }

  return (
    <Box borderBottomWidth={1} p={8}>
      <Flex justifyContent="space-between" mb={4}>
        <Text fontSize="xl" fontWeight="bold">{title}</Text>
        <Button variant="outline" onClick={() => setEdit(true)}>Edit</Button>
      </Flex>
      {children}
      {edit && (
        <Modal
          event={event}
          title={title}
          onClose={() => setEdit(false)}
          onSubmit={handleSubmit}
          defaultTranslations={defaultTranslations}
        />
      )}
    </Box>
  )
}

export default BoxWithEditModal
