import {
  Text, Link as ChakraLink,
} from "@chakra-ui/react"
import React from "react"
import Button from "components/Buttons/Button"
import { useIntercom } from "react-use-intercom"
import { Account, ModalName } from "sharedTypes"
import Link from "components/elements/Link"
import AccountError from "components/layout/AccountError"
import useModal from "services/useModal"
import { createBillingPortalSession } from "api/accounts"

type Props = {
  currentAccount: Account
  onClickSupportLogin?: () => void
}

const ExpiredSubscription = ({ currentAccount, onClickSupportLogin }: Props) => {
  const { show: showIntercom } = useIntercom()
  const showModal = useModal()
  const openBillingPortal = () => createBillingPortalSession()
    .then((response) => {
      window.open(response.data.url, "_blank")
    })

  return (
    <AccountError
      title="Your subscription is canceled"
      footer={(
        <ChakraLink onClick={() => showModal(ModalName.SwitchAccount)}>
          Switch Account
        </ChakraLink>
        )}
      onClickSupportLogin={onClickSupportLogin}
    >
      <Text fontSize="lg" fontWeight="semibold" mb={6}>{currentAccount.name}</Text>
      <Text mb={6} fontSize="lg">
        Diobox is an all-in-one platform which helps you manage,
        simplify, and scale all your event programs.
      </Text>
      <Text fontSize="lg" mb={8}>
        Your subscription comes with features for guest lists, seating, email invites,
        and registration. It also includes an iOS app for check-in, fast QR Code scanning and
        badge printing.
      </Text>
      <Button
        onClick={() => showModal(ModalName.Upgrade)}
        size="md"
      >Reactivate Subscription
      </Button>
      <Text fontSize="sm" mt={6}>
        Access your <Link onClick={openBillingPortal}>Billing Portal</Link>, a Stripe dashboard,
        which manages Diobox subscriptions, providing you access to your invoices and credit cards.
        <br />
        If you have any questions about your subscription,
        {" "}<Link onClick={showIntercom}>message us</Link>.
      </Text>
    </AccountError>
  )
}

export default ExpiredSubscription
