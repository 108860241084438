import React from "react"
import { Center, Text } from "@chakra-ui/react"

const SelectPrompt = ({ text }) => (
  <Center h="full">
    <Text fontSize="lg" color="gray.500">{text}</Text>
  </Center>
)

export default SelectPrompt
