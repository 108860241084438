import * as Yup from "yup"
import React, { useState } from "react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextField from "components/ReactHookForm/TextField"
import { useQueryClient } from "react-query"
import { deleteCheckInLocation, postCheckInLocation, putCheckInLocation } from "../api/events"
import {
  addCheckInLocation,
  removeCheckInLocation,
  updateCheckInLocation,
} from "../context/actions"
import { CheckInLocation } from "../sharedTypes"

type Props = {
  eventId: string
  onSave: (location: CheckInLocation) => void
  onHide: () => void
  editLocation?: CheckInLocation
}

const EventActivityForm = ({
  eventId, editLocation, onSave, onHide,
}: Props) => {
  const [location, setLocation] = useState(editLocation)

  const resetState = () => {
    onHide()
    setLocation({} as CheckInLocation)
  }

  const submitFn = (values) => (location?.id
    ? putCheckInLocation(eventId, location?.id, values) : postCheckInLocation(eventId, values))

  const LocationSchema = Yup.object().shape({ name: Yup.string().required() })

  const queryClient = useQueryClient()

  const onSubmit = (values) => submitFn(values).then(({ data }) => {
    if (location?.id) {
      updateCheckInLocation(queryClient, eventId, data)
    } else {
      addCheckInLocation(queryClient, eventId, data)
    }
    onSave?.(data)
    resetState()
  })

  const onDelete = () => {
    if (!location?.id) return

    deleteCheckInLocation(eventId, location.id).then(() => {
      removeCheckInLocation(queryClient, eventId, location.id.toString())
      resetState()
    })
  }

  return (
    <ModalForm
      title={editLocation?.id ? "Edit Event Activity" : "Add Event Activity"}
      initialValues={{ name: editLocation?.name }}
      onSubmit={onSubmit}
      onClose={onHide}
      onDanger={editLocation?.id ? onDelete : null}
      validationSchema={LocationSchema}
      size="md"
    >
      {({ control }) => (
        <>
          <TextField
            name="name"
            control={control}
            placeholder="Name"
          />
        </>
      )}
    </ModalForm>
  )
}

export default EventActivityForm
