import { Flex } from "@chakra-ui/react"
import React, { ReactNode } from "react"

interface Props {
  type: "header" | "footer"
  children: ReactNode
  color?: string
}

const FullScreenModalNav = ({ type, children, color }: Props) => (
  <Flex
    justify="space-between"
    align="center"
    px={5}
    h={type === "header" ? "44px" : "64px"}
    bg={type === "header" ? "black" : "gray.800"}
    color={color || "white"}
    top={type === "header" ? 0 : "initial"}
    bottom={type === "footer" ? 0 : "initial"}
    zIndex={10}
    w="100%"
    fontSize="lg"
  >
    {children}
  </Flex>
)

export default FullScreenModalNav
