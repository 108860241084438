import React from "react"
import { FixedBlockType, Form } from "sharedTypes"
import { BlockInsertPosition } from "components/Builder/BuildingBlock"
import { Box } from "@chakra-ui/react"
import { DraggableItem } from "sharedTypes/builder"
import TopFixedBlockContent from "./TopFixedBlockContent"

type Props = {
  form: Form
  rsvpConfirm: string
  rsvpDecline: string
  portalPrimaryColor: string
  editable?: boolean
  selectedFixedBlockType?: FixedBlockType
  onDropBlock?: (
    item: DraggableItem,
    blockId: number,
    position: BlockInsertPosition
  ) => void
  onSelectFixedBlockType?: (type: FixedBlockType) => void
}

const TopFixedBlock = ({
  form,
  rsvpConfirm,
  rsvpDecline,
  portalPrimaryColor,
  editable,
  selectedFixedBlockType,
  onDropBlock,
  onSelectFixedBlockType,
}: Props) => (
  <Box borderBottomWidth={1}>
    <TopFixedBlockContent
      form={form}
      rsvpConfirm={rsvpConfirm}
      rsvpDecline={rsvpDecline}
      portalPrimaryColor={portalPrimaryColor}
      editable={editable}
      selectedFixedBlockType={selectedFixedBlockType}
      onDropBlock={onDropBlock}
      onSelectFixedBlockType={onSelectFixedBlockType}
    />
  </Box>
)

export default TopFixedBlock
