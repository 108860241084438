import React from "react"
import NoContent from "components/elements/NoContent"
import { ModalName } from "sharedTypes"
import useModal from "services/useModal"

type Props = {
  guestLimit: number
  guestsCount: number
}

const LimitReached = ({ guestLimit, guestsCount }: Props) => {
  const showModal = useModal()

  const callsToActions = [
    {
      text: "Upgrade",
      action: () => showModal(ModalName.Upgrade),
      isButton: true,
    },
  ]

  return (
    <NoContent
      title="Guest Limit Reached"
      icon="lock"
      descriptionItems={[`Your event has a guest limit of ${guestLimit}, but this event includes ${guestsCount} guests. Please update your subscription to view all guests.`]}
      callsToActions={callsToActions}
    />
  )
}

export default LimitReached
