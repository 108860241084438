import { Stack, StackDivider, Text } from "@chakra-ui/react"
import _ from "lodash"
import React from "react"
import { GuestSummary } from "sharedTypes"
import GroupCheckInItem from "./GroupCheckInItem"

type Props = {
  group: GuestSummary[]
  remainingFromGroup: number
  checkIn: (id: string) => void
  checkInAll: () => void
}

const GroupCheckInPopover = ({
  group, remainingFromGroup, checkIn, checkInAll,
}: Props) => {
  if (group.length <= 1) {
    return <Text my={4} textAlign="center">Guest does not belong to a group</Text>
  }

  return (
    <Stack spacing={0}>
      {_.sortBy(group, ["personInfo.firstName", "personInfo.lastName"]).map(({ id, personInfo: { firstName, lastName }, checkedIn }) => (
        <GroupCheckInItem
          label={`${firstName} ${lastName}`}
          checkedIn={Boolean(checkedIn)}
          onCheckIn={() => checkIn(id)}
        />
      ))}
      {remainingFromGroup && (
      <>
        <StackDivider borderColor="gray.300" borderTopWidth={1} />
        <GroupCheckInItem
          label={`Check In ${remainingFromGroup === group.length ? `All ${remainingFromGroup} Guests` : `${remainingFromGroup} remaining`}`}
          checkedIn={false}
          onCheckIn={() => checkInAll()}
          bulk
        />
      </>
      )}
    </Stack>
  )
}

export default GroupCheckInPopover
