import React from "react"
import { Box, SpaceProps, Text } from "@chakra-ui/react"

type Props = {
    title: string,
    subtitle?: string,
    mb?: SpaceProps["marginBottom"];
}

const TitleWithSubtitle = ({ title, subtitle, mb = 6 }: Props) => (
  <Box mb={mb}>
    <Text fontSize="3xl" fontWeight="semibold">{title}</Text>
    {subtitle && (
    <Box mt={1}>
      <Text fontSize="lg">{subtitle}</Text>
    </Box>
    )}
  </Box>
)

export default TitleWithSubtitle
