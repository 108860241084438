import React from "react"
import TagGuestList from "./TagGuestList"
import ChangeStatus from "./ChangeStatus"
import SendEmail from "./SendEmail/SendEmail"
import SendSmartReminder from "./SendSmartReminder"
import RemoveFromEvent from "./RemoveFromEvent"

type Props = {
  action: string|null;
  onClose: () => void;
  selectedCount: number;
}

const Action = ({ action, onClose, selectedCount }: Props) => {
  switch (action) {
    case "tag-guest-list":
      return <TagGuestList onClose={onClose} selectedCount={selectedCount} />
    case "change-status":
      return <ChangeStatus onClose={onClose} selectedCount={selectedCount} />
    case "send-email":
      return <SendEmail onClose={onClose} selectedCount={selectedCount} />
    case "send-smart-reminder":
      return <SendSmartReminder onClose={onClose} selectedCount={selectedCount} />
    case "remove-from-event":
      return <RemoveFromEvent onClose={onClose} />
    default:
      return null
  }
}

export default Action
