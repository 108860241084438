import React, { ReactNode, useEffect } from "react"
import Icon from "components/elements/Icon"
import { useForm } from "react-hook-form"
import AutoSave from "./AutoSave"

type Props<T> = {
  onChange: (values: T) => void
  initialValues: any
  children: (control) => ReactNode
  onCancel?: (data: {}) => void
  selectedBlockId?: number
}

const SidePanelForm = <T, >({
  onChange, onCancel, initialValues, children, selectedBlockId,
}: Props<T>) => {
  const { control, reset } = useForm({
    defaultValues: initialValues,
    mode: "onChange",
  })

  useEffect(() => {
    reset(initialValues)
  }, [selectedBlockId])

  return (
    <form>
      <AutoSave control={control} onChange={onChange} />
      {onCancel && (
      <Icon
        icon="prev-arrow"
        onClick={onCancel}
        position="absolute"
        cursor="pointer"
        size={5}
        m={6}
      />
      )}
      {children({ control })}
    </form>
  )
}

export default SidePanelForm
