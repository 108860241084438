type Props = {
  clicked: number
  opened: string
  softBounced: string
  hardBounced: string
  bounced: string
}

export const getColorByInvitationStatus = ({
  clicked, opened, softBounced, hardBounced, bounced,
}: Props) => {
  if (clicked) {
    return "emailStatus.clicked"
  }
  if (opened) {
    return "emailStatus.opened"
  }
  if (softBounced) {
    return "emailStatus.softBounced"
  }
  if (hardBounced) {
    return "emailStatus.hardBounced"
  }
  if (bounced) {
    return "emailStatus.bounced"
  }

  return "emailStatus.unopened"
}

export default null
