import React from "react"
import { Box } from "@chakra-ui/react"
import Link from "components/elements/Link"
import { ButtonBlock } from "sharedTypes/builder"

const Button = ({ block: { data } }: { block: ButtonBlock }) => (
  <Box
    textAlign="center"
    bgColor={data.blockColor}
    pt={`${data.paddingTop * 4}px`}
    pb={`${data.paddingBottom * 4}px`}
  >
    <Link
      bgColor={data.buttonColor}
      color={data.textColor}
      fontFamily={data.font}
      fontWeight="bold"
      display="inline-block"
      py={3}
      px={8}
      textDecoration="none"
    >{data.text}
    </Link>
  </Box>
)

export default Button
