import React, { useEffect, useState } from "react"
import { Box, Flex } from "@chakra-ui/react"
import Modal from "components/dialogs/Modal"
import { DioboxEvent, TextMessage } from "sharedTypes"
import { useGuests } from "queries/guests"
import Spinner from "components/elements/Spinner"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { SENDER_PHONE_NUMBER } from "utilities/constants"
import { asDate } from "utilities/strings"
import useMergeTags from "services/useMergeTags"
import { sendTextMessageTest } from "api/texts"
import { errorToast, successToast } from "utilities/toasts"
import { PhoneNumberUtil } from "google-libphonenumber"
import Phone from "./Phone/Phone"
import GuestSelector from "./GuestSelector"
import SendTestMessage from "./SendTestMessage"

type Props = {
  onHide: () => void
  event: DioboxEvent
  textMessage: TextMessage
  enabled: boolean
}

export const FIRST_X_NUMBER_OF_GUESTS_TO_SHOW = 10

const phoneUtil = PhoneNumberUtil.getInstance()

const validatePhoneNumber = (phoneNumber: string) => {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber)

    return phoneUtil.isValidNumber(number)
  } catch (e) {
    return false
  }
}

const PreviewTextMessageSchema = Yup.object().shape({
  to: Yup.string()
    .test("is-valid-phone", "Phone number is not valid", (value) => validatePhoneNumber(value!))
    .required(),
  body: Yup.string().min(1).required(),
})

const PreviewTextMessage = ({
  onHide, event: { title, startDate, id: eventId },
  textMessage: { body: bodyTemplate },
  enabled,
}: Props) => {
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(0)
  const { guests, isLoading } = useGuests(eventId)
  const {
    control, formState: { isSubmitting, isValid }, handleSubmit, resetField, setValue, watch,
  } = useForm({
    defaultValues: { to: "", body: "" },
    resolver: yupResolver(PreviewTextMessageSchema),
    mode: "onChange",
  })
  const firstXGuests = guests.length > 0 ? guests.slice(0, FIRST_X_NUMBER_OF_GUESTS_TO_SHOW) : []
  const selectedGuest = firstXGuests.length > 0 ? firstXGuests[selectedGuestIndex] : null

  const { replaceMergeTags } = useMergeTags({ eventId, guestExternalId: selectedGuest?.externalId })

  const date = asDate(startDate)!

  const onSubmit = (values) => sendTextMessageTest(eventId, values)
    .then(() => {
      successToast({ title: `Sent message to: ${values.to}` })
      resetField("to")
    }).catch(() => {
      errorToast({ title: "Message failed to be delivered due to the recipient being unavailable or unreachable." })
    })

  useEffect(() => {
    const textBody = replaceMergeTags(bodyTemplate, title, date, selectedGuest)
    setValue("body", textBody)
  }, [selectedGuest])

  const populatedTextBody = watch("body") ?? ""

  return (
    <Modal size="5xl" title="Preview" show noPadding hide={onHide}>
      {isLoading ? <Spinner /> : (
        <Flex>
          <Flex w="60%" borderRightWidth={1} justifyContent="center" pt={14}>
            <Phone phoneNumber={SENDER_PHONE_NUMBER} text={populatedTextBody} />
          </Flex>
          <Box flex={1} pt={8}>
            {firstXGuests.length > 0 && (
            <GuestSelector
              selectedGuest={firstXGuests[selectedGuestIndex]}
              selectedGuestIndex={selectedGuestIndex}
              setSelectedGuestIndex={setSelectedGuestIndex}
              guestCount={firstXGuests.length}
            />
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <SendTestMessage
                control={control}
                isSubmitting={isSubmitting}
                disabled={!(isValid && enabled)}
              />
            </form>
          </Box>
        </Flex>
      )}

    </Modal>
  )
}

export default PreviewTextMessage
