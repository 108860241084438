import React, { Fragment, useRef, useState } from "react"
import {
  Textarea, Box, Flex, Button, Text,
} from "@chakra-ui/react"
import { Control, useController } from "react-hook-form"
import { MERGE_TAGS } from "utilities/enums"
import { useForms } from "queries/forms"
import { getFormsGroupedByType } from "utilities/forms"
import { DioboxEvent } from "sharedTypes"
import useAutosizeTextArea from "utilities/useAutosizeTextArea"
import Icon from "./elements/Icon"
import Menu, { MenuItem } from "./dialogs/Menu"

type Props = {
  name: string
  control: Control
  characterLimit?: number
  event: DioboxEvent
}

const TextMessageEditor = ({
  name, control, characterLimit, event,
}: Props) => {
  const { field } = useController({ name, control })
  const [charCount, setCharCount] = useState(field.value.length)
  const textareaRef = useRef<HTMLTextAreaElement|null>(null)
  const { data } = useForms(event.id)
  const forms = data?.data ?? []
  const groupedForms = getFormsGroupedByType(forms, event)
  useAutosizeTextArea(textareaRef, field.value)

  const insertAtCursor = (text: string) => {
    const textarea = textareaRef.current
    if (!textarea) return

    const start = textarea.selectionStart
    const end = textarea.selectionEnd
    const newText = textarea.value.substring(0, start) + text + textarea.value.substring(end)

    field.onChange(newText)
    setCharCount(newText.length)
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value

    field.onChange(newText)
    setCharCount(newText.length)
  }

  const mergeTagsOptions = MERGE_TAGS.map(
    ({ id, label }) => <MenuItem onClick={() => insertAtCursor(`{{${id}}}`)} key={id}>{label}</MenuItem>,
  )

  const formLinkOptions = groupedForms.map(
    ({ label, options }) => <Fragment key={label}><Box px={8} py={3}><Text color="gray.500">{label}</Text></Box>{options.map(({ label: optionLabel, value }) => <MenuItem onClick={() => insertAtCursor(`{{FORM_${value}}}`)} key={value}><Box ml={6}>{optionLabel}</Box></MenuItem>)}</Fragment>,
  )

  const availableCharacterLength = characterLimit ? characterLimit - charCount : null

  return (
    <>
      <Box borderWidth={1} borderRadius="xl" overflow="hidden" mt={4}>
        <Flex px={1}>
          <Menu
            button={(<Button variant="ghost" rightIcon={<Icon icon="down-arrow" fontSize="sm" />} fontSize="sm">Insert Merge Tags</Button>)}
            menuItems={mergeTagsOptions}
          />
          <Menu
            placement="auto"
            button={(<Button variant="ghost" rightIcon={<Icon icon="down-arrow" fontSize="sm" />} fontSize="sm">Insert Form Links</Button>)}
            menuItems={formLinkOptions}
            maxH={600}
          />
        </Flex>
        <Textarea
          {...field}
          ref={textareaRef}
          bg="white"
          borderTopWidth={1}
          borderLeftWidth={0}
          borderRightWidth={0}
          borderBottomWidth={0}
          borderRadius={0}
          onChange={handleChange}
        />
      </Box>
      {characterLimit && availableCharacterLength !== null && (
      <Flex justifyContent="space-between" mt={2}>
        <Text color="gray.500">Character limit</Text>
        <Text color={availableCharacterLength >= 0 ? "gray.500" : "red.500"}>{availableCharacterLength}</Text>
      </Flex>
      )}
    </>
  )
}

export default TextMessageEditor
