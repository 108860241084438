import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { FormType } from "sharedTypes"
import { formIconAndType } from "utilities/forms"

type Props = {
  type: FormType
}

const FilterOptionLabel = ({ type }: Props) => (
  <Flex alignItems="center">
    <Box mr={2}>
      <Icon icon={formIconAndType(type).icon} />
    </Box>
    <Text fontWeight="semibold">{type}</Text>
  </Flex>
)

export default FilterOptionLabel
