import React from "react"
import { ListItem, OrderedList, Text } from "@chakra-ui/react"
import { embedUrl } from "utilities/externalLinks"
import CodeBlock from "./elements/CodeBlock"
import Code from "./elements/Code"

export enum EmbedInstructionsType {
  Event = "event",
  Form = "ticket"
}

type Props = {
  type: EmbedInstructionsType
  externalId: string
}

const EmbedInstructions = ({ type, externalId }: Props) => (
  <OrderedList>
    <ListItem mb={5}>
      <Text mb={2}>Add the following code inside your  <Code>{"<head>"}</Code> tag:</Text>
      <CodeBlock copiable>
        {`<script type="text/javascript" src="${embedUrl}" async defer></script>`}
      </CodeBlock>
    </ListItem>
    <ListItem>
      <Text mb={2}>
        Place this code where you want the registration button to appear on the
        same web page. You can customize the button text by
        changing <b>&quot;Registration&quot;</b> to your preferred label:
      </Text>
      <CodeBlock copiable>
        {`<dio-button ${type}="${externalId}">Registration</dio-button>`}
      </CodeBlock>
    </ListItem>
  </OrderedList>
)

export default EmbedInstructions
