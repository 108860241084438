import React, { ChangeEvent, MouseEvent, useRef } from "react"
import {
  Checkbox,
  Stack, Spacer, Text, ListItem, Flex, Box, As,
} from "@chakra-ui/react"
import { random } from "lodash"
import {
  Table, ItemTypes, GuestSummary,
} from "sharedTypes"
import GuestAvatar from "components/elements/GuestAvatar"
import { useParams, Link } from "react-router-dom"
import { useEventGuestPaths } from "utilities/routes"

import Icon from "components/elements/Icon"
import {
  ConnectDragPreview, ConnectDragSource, DragSourceMonitor, useDrag,
} from "react-dnd"
import { updateGuest, updateTables } from "context/actions"
import * as guestsApi from "api/guests"
import { useQueryClient } from "react-query"
import { useInViewport } from "ahooks"
import Pill from "components/elements/Pill"
import { updateTablesFromGuest } from "../../SelectedGuest/Show/Seating"

type Props = {
  guest: GuestSummary;
  onSelectionChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick: (event: MouseEvent) => void;
  tables: Table[];
  order: string;
  filtered: boolean;
  selected?: boolean;
  multipleSelect?: boolean;
  multipleSelectEnabled?: boolean;
  showTables?: boolean;
  draggingEnabled?: boolean;
  onClickGuest?: ((guest: GuestSummary) => void) | boolean;
  plusOne?: true;
  disabled?: boolean;
}

const Item = (props: Props) => {
  const {
    guest,
    guest: {
      id,
      seatingAssignment,
    },
    tables,
    showTables,
    draggingEnabled,
    selected,
    multipleSelectEnabled,
    plusOne,
  } = props

  const queryClient = useQueryClient()
  const { eventId, guestId } = useParams<{ eventId: string, guestId: string }>()

  const ref = useRef(null)
  const inViewport = useInViewport(ref)

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: ItemTypes.GUEST,
    canDrag: draggingEnabled,
    item: { type: ItemTypes.GUEST },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (
      item,
      monitor: DragSourceMonitor<{ type: typeof ItemTypes }, {table: Table, seatNumber: number}>,
    ) => {
      if (!monitor.didDrop()) {
        return
      }

      const { table, seatNumber } = monitor.getDropResult()!

      assignSeat(guest, table, seatNumber)
    },
  })

  const assignSeat = (guestToAssign: GuestSummary, table: Table, seatNumber: number) => {
    guestsApi.postGuestSeat(eventId, guestToAssign, table, seatNumber)
      .then(({ data }) => {
        updateGuest(queryClient, data)
        const updatedTables = updateTablesFromGuest(data, tables)
        updateTables(queryClient, eventId, updatedTables)
      })
  }

  let bg = "none"
  if (showTables && seatingAssignment) {
    bg = "gray.200"
  } else if (selected || (!multipleSelectEnabled && id === guestId)) {
    bg = "blue.50"
  }

  return (
    <ListItem
      display="flex"
      position="relative"
      borderBottomWidth={1}
      opacity={showTables && isDragging ? 0.5 : 1}
      bg={bg}
      ref={ref}
    >
      {!inViewport
        ? <GuestItemSkeleton plusOne={plusOne} />
        : (
          <GuestItem
            {...props}
            drag={drag}
            dragPreview={dragPreview}
            eventId={eventId}
            plusOne={plusOne}
          />
        )}
    </ListItem>
  )
}

const GuestItemSkeleton = ({ plusOne }: {plusOne?: true}) => (
  <Flex align="center" pl={plusOne ? 16 : 4} pr={4}>
    <Box w={42} h={42} borderRadius="full" bg="gray.100" />
    <Box w={random(100, 180)} h={5} ml={3} borderRadius="lg" bg="gray.100" />
  </Flex>
)

type GuestItemProps = Props & {
  drag: ConnectDragSource,
  dragPreview: ConnectDragPreview,
  eventId: string,
}

const GuestItem = ({
  guest,
  guest: {
    personInfo: { firstName, lastName, photoUrl },
    id, status, checkedIn, groupSize, seatingAssignment, guestId,
  },
  onSelectionChange,
  onClick,
  order,
  selected,
  multipleSelect,
  multipleSelectEnabled,
  showTables,
  draggingEnabled,
  onClickGuest,
  drag,
  dragPreview,
  eventId,
  plusOne,
  disabled,
}: GuestItemProps) => {
  type ListItemPersonProps = {
    as: As | undefined,
    to: string,
    align: "center",
    w: "100%",
    ref?: any,
    cursor?: string,
    onClick?: (event: MouseEvent) => void,
    opacity?: string,
  }

  const { eventGuestPath } = useEventGuestPaths()

  const listItemPersonProps: ListItemPersonProps = {
    as: Link,
    to: eventGuestPath(eventId!, id),
    align: "center",
    w: "100%",
    onClick,
  }

  if (draggingEnabled) {
    listItemPersonProps.as = Box
    listItemPersonProps.ref = drag
    listItemPersonProps.cursor = "move"
  }

  if (onClickGuest !== false) {
    listItemPersonProps.as = Box
    if (typeof onClickGuest === "function") {
      listItemPersonProps.onClick = () => onClickGuest(guest)
      listItemPersonProps.cursor = "pointer"
    }
  }

  if (disabled) {
    listItemPersonProps.opacity = "0.3"
    listItemPersonProps.onClick = undefined
  }

  return (
    <>
      <Flex {...listItemPersonProps} pl={plusOne ? 20 : 8} pr={showTables ? 12 : 4} py={3}>
        <Flex align="center" mr={3} ref={dragPreview}>
          <Box mt={1}>
            <GuestAvatar
              firstName={firstName}
              lastName={lastName}
              photoUrl={photoUrl}
              status={status}
              checkedIn={!!checkedIn}
            />
          </Box>
          <Flex align="center" p={3} wrap="wrap">
            <Box mr={3}>
              <Text
                as="span"
                fontWeight="bold"
              >{order === "first_name_asc" ? firstName : lastName}
              </Text>
              {order === "first_name_asc" || !lastName ? " " : ", "}
              {order === "first_name_asc" ? lastName : firstName}
            </Box>
            {(groupSize > 1) && <GroupLabel groupSize={groupSize} childGuest={!!guestId} />}
          </Flex>
        </Flex>
      </Flex>
      {multipleSelect && (
        <MultiSelectCheckbox
          enabled={multipleSelectEnabled || selected}
          checked={!!selected || selected}
          onChange={onSelectionChange}
          plusOne={plusOne}
        />
      )}
      {(showTables && seatingAssignment) && (
        <>
          <Spacer /><AssignedTable {...seatingAssignment} />
        </>
      )}
    </>
  )
}

const MultiSelectCheckbox = ({
  enabled, checked, onChange, plusOne,
}) => (
  <Flex position="absolute" align="center" h="full">
    <Flex
      align="center"
      justify="center"
      position="relative"
      left={plusOne ? 20 : 8}
      top="1px"
      ml="-1px"
      w={10}
      h={10}
      boxSizing="content-box"
      borderWidth={1}
      borderRadius="full"
      borderColor="gray.100"
      bg="gray.100"
      opacity={enabled ? 1 : 0}
      _hover={{ opacity: 1 }}
      transitionProperty="opacity"
      transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
      transitionDuration="200ms"
    >
      <Checkbox
        p={2.5}
        isChecked={!!checked}
        onChange={onChange}
      />
    </Flex>

  </Flex>
)

const AssignedTable = ({ seatingTableTitle, seat, shape }) => (
  <Stack
    borderLeftWidth={1}
    position="absolute"
    right={0}
    px={2}
    py={2}
    w={16}
    textAlign="center"
    h="100%"
    spacing={0}
  >
    <Icon icon={`table-${shape}`} size={8} m="auto" />
    <Text fontSize="xs" lineHeight={1} noOfLines={1}>{seatingTableTitle}</Text>
    <Text fontSize="xs" lineHeight={1} noOfLines={1}>Seat {seat}</Text>
  </Stack>
)

const GroupLabel = ({ groupSize, childGuest }) => (
  <Pill
    color={childGuest ? "gray.100" : "teal.300"}
    borderWidth={1}
    textColor={childGuest ? "teal.300" : undefined}
    borderColor="teal.300"
    label={`Group of ${groupSize}`}
    width="auto"
    px={2}
  />
)

export default Item
