import { Box, Text } from "@chakra-ui/react"
import React from "react"
import { useIntercom } from "react-use-intercom"
import Link from "./elements/Link"
import FullScreenInfoView from "./FullScreenInfoView"

type Props = {
  clearError?: () => void;
}

const ErrorView = ({ clearError }: Props) => {
  const { show: showIntercom } = useIntercom()

  return (
    <FullScreenInfoView
      heading="Something went wrong"
      description="We’ve been notified and will work to quickly resolve."
      footer={(
        <Box textAlign="center">
          <><Link onClick={clearError} to="/">Go back</Link> and try again.</>
          <Text>If the error persists, please{" "}
            <Link onClick={showIntercom}>contact us</Link>.
          </Text>
        </Box>
      )}
    />
  )
}

export default ErrorView
