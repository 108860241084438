import React, { ReactElement } from "react"
import { Box, FormControl, FormLabel } from "@chakra-ui/react"

type Props = {
  title: string;
  children: ReactElement;
}

const Fieldset = ({ title, children }: Props) => (
  <FormControl as="fieldset" mb={6}>
    <FormLabel
      as="legend"
      w="full"
      fontSize="lg"
      fontWeight="semibold"
    >
      {title}
    </FormLabel>
    <Box background="white" borderTopWidth={1} borderBottomWidth={1} paddingRight={6}>
      {children}
    </Box>
  </FormControl>
)

export default Fieldset
