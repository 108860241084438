import React, { useState } from "react"
import SearchField from "components/inputs/SearchField"
import { Guest, Seat, Table } from "sharedTypes"
import Icon from "components/elements/Icon"
import { Box, Flex, Text } from "@chakra-ui/react"
import DropdownItem from "../DropdownItem"

type Props = {
  tables: Table[];
  guest: Guest;
  onRemoveSeat: () => void;
  onSetStanding: () => void;
  onSelectTable: (table) => void;
}

const TableSelect = ({
  tables, guest, onRemoveSeat, onSetStanding, onSelectTable,
}: Props) => {
  const [searchString, setSearchString] = useState("")
  const filteredTables = searchString
    ? tables.filter(({ name }) => name.search(new RegExp(searchString, "i")) !== -1)
    : tables

  const numberOfSeats = (seats: Seat[], assigned: boolean|null = null) => {
    if (assigned === null) {
      return seats.length
    }

    return seats.filter(({ guest: g }) => !!g === assigned).length
  }

  const isNoSeatSelected = !(guest.seatingAssignment || guest.waitlistTable || guest.standing)
  const isStandingSelected = guest.standing

  return (
    <>
      <DropdownItem component="div">
        <SearchField
          value={searchString}
          onChange={setSearchString}
          placeholder="Search Table Names"
          loading={false}
        />
      </DropdownItem>
      <DropdownItem
        type="button"
        onClick={onRemoveSeat}
        selected={isNoSeatSelected}
        checked={isNoSeatSelected}
      >No Seat
      </DropdownItem>
      <DropdownItem
        type="button"
        onClick={onSetStanding}
        selected={isStandingSelected}
        checked={isStandingSelected}
      >Standing
      </DropdownItem>
      {filteredTables.map((table) => {
        const {
          id, name, shape, seats,
        } = table
        const isSelected = table.id === guest.seatingAssignment?.seatingTableId
          || table.id === guest.waitlistTable?.id
        const assignedSeatCount = numberOfSeats(seats, true)
        const vacantSeatCount = seats.length - assignedSeatCount

        return (
          <DropdownItem
            key={id}
            type="button"
            onClick={() => onSelectTable(table)}
            selected={isSelected}
          >
            <Flex align="center">
              <Flex align="center" my={-2} overflow="hidden" flexGrow={1}>
                <Icon icon={`table-${shape}`} size={8} mr={2} />
                <Box lineHeight={6} w={42} flexGrow={1}>
                  <Text fontSize="lg" w="full" noOfLines={1} title={name}>{name}</Text>
                  <Text fontSize="sm">
                    {assignedSeatCount} {"Assigned "}
                    <Text as="span" color={vacantSeatCount ? "green.500" : "red.500"}>
                      ({vacantSeatCount} Vacant)
                    </Text>
                  </Text>
                </Box>
              </Flex>
              <Icon icon="next-arrow" />
            </Flex>
          </DropdownItem>
        )
      })}
    </>
  )
}

export default TableSelect
