import React from "react"
import { useParams } from "react-router-dom"
import { Guest, Transaction } from "sharedTypes"
import Note from "./Note"
import Package from "./Package"
import TravelPlan from "./TravelPlan"
import Rating from "./Rating"
import Accommodation from "./Accommodation"

interface Props {
  guest: Guest
}

const ActivityModal = ({ guest }: Props) => {
  const {
    activityType: type,
    activityId: id,
  } = useParams<{ activityType: string, activityId: string }>()

  enum ActivityTypes {
    NoteTransaction="note",
    PackageTransaction= "package",
    TravelPlansTransaction= "travel-plan",
    AccommodationTransaction= "accommodation",
    RatingTransaction= "rating",
  }

  let activityType
  let activityProps = {}

  if (id) {
    const activity = guest.personInfo.transactions.find(
      (transaction) => transaction.id.toString() === id,
    ) as Transaction

    activityType = ActivityTypes[activity.type]
    activityProps = { activity }
  } else {
    activityType = type
  }

  switch (activityType) {
    case ActivityTypes.NoteTransaction:
      return <Note {...activityProps} />
    case ActivityTypes.PackageTransaction:
      return <Package {...activityProps} />
    case ActivityTypes.TravelPlansTransaction:
      return <TravelPlan {...activityProps} />
    case ActivityTypes.AccommodationTransaction:
      return <Accommodation {...activityProps} />
    case ActivityTypes.RatingTransaction:
      return <Rating {...activityProps} />
    default:
      return null
  }
}

export default ActivityModal
