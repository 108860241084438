import React from "react"
import Helmet from "react-helmet"
import { useRouteMatch } from "react-router-dom"
import { accountPath, eventsPath } from "utilities/routes"
import { useCurrentEvent } from "queries/events"

const PageTitle = () => {
  const accountRouteMatch = useRouteMatch(accountPath())
  const eventsRouteMatch = useRouteMatch(eventsPath())
  const { data: currentEvent } = useCurrentEvent()

  if (!currentEvent) {
    return null
  }

  return (
    <Helmet
      defaultTitle="Diobox"
      titleTemplate="%s | Diobox"
    >
      {(() => {
        if (currentEvent.title) {
          return <title>{currentEvent.title}</title>
        }

        if (accountRouteMatch?.url) {
          return <title>Account</title>
        }

        if (eventsRouteMatch?.url) {
          return <title>Events</title>
        }

        return null
      })()}
    </Helmet>
  )
}

export default PageTitle
