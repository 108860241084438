import React from "react"
import * as Yup from "yup"
import { postInvitationSampleEmail } from "api/emails"
import { successToast } from "utilities/toasts"
import { Text } from "@chakra-ui/react"
import useCurrentAccount from "services/useCurrentAccount"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import EmailField from "components/ReactHookForm/EmailField"

type Props = {
  eventId: string
  emailId: number
  emailAddress: string
  onHide: () => void
}

const SendTestEmail = ({
  eventId, emailId, emailAddress, onHide,
}: Props) => {
  const currentAccount = useCurrentAccount()
  const freePlan = !currentAccount.subscription

  const onSubmit = (values) => postInvitationSampleEmail(eventId, emailId, values).then(() => {
    successToast({ title: `Sent test email to ${values.emailAddress}` })
    onHide()
  })

  const TestEmailSchema = Yup.object().shape({
    emailAddress: Yup.string().email().required(),
  })

  return (
    <ModalForm
      title="Test Email"
      onClose={onHide}
      initialValues={{ emailAddress }}
      validationSchema={TestEmailSchema}
      onSubmit={onSubmit}
      submitText="Send Test Email"
      size="lg"
    >
      {({ control }) => (
        <>
          <EmailField name="emailAddress" control={control} placeholder="Email" label="Send a test email to:" vertical readonly={freePlan} />
          {freePlan && (
          <Text color="blue.500">
            You can only send emails to yourself (in the free plan). After upgrading to a paid
            subscription plan, you’ll be able to send test emails externally.
          </Text>
          )}
        </>
      )}
    </ModalForm>
  )
}

export default SendTestEmail
