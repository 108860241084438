import {
  Box, Flex, Heading, Text,
} from "@chakra-ui/react"
import { asMoney, asNumber, asPercent } from "utilities/strings"
import React from "react"
import StatusBar from "components/elements/StatusBar"

export type Props = {
  title: string
  number: number
  color?: string
  ratio?: number
  currency?: string
  subBlock?: boolean
}

const StatisticsBlock = ({
  title, number, color = "gray.800", ratio, currency, subBlock,
}: Props) => (
  <Box pl={subBlock ? 8 : 0}>
    <Flex justify="space-between" align="center">
      <Heading fontSize="md" fontWeight="semibold">{title}</Heading>
      <Text fontSize={!ratio ? "2xl" : "md"} fontWeight="semibold">{currency ? asMoney(number, currency) : asNumber(number)} {ratio ? `(${asPercent(ratio)})` : ""}</Text>
    </Flex>
    {typeof ratio === "number" && (
    <Box mt={1} mb={3.5}>
      <StatusBar sections={[{ color, size: ratio * 100 }, { color: "gray.100", size: (1 - ratio) * 100 }]} />
    </Box>
    )}
  </Box>
)

export default StatisticsBlock
