import useCurrentAccount from "services/useCurrentAccount"
import React, { useState } from "react"
import Spinner from "components/elements/Spinner"
import { ModalName, SubscriptionStatuses, User } from "sharedTypes"
import { useLocation } from "react-router-dom"
import { eventsFuturePath, eventsPastPath } from "utilities/routes"
import {
  Box, Text, Flex, Heading, HStack, Image,
} from "@chakra-ui/react"
import { useChannel } from "@harelpls/use-pusher"
import { PusherEvent } from "utilities/enums"
import Menu, { MenuItem } from "components/dialogs/Menu"
import ConfirmEmailAlert from "components/ConfirmEmailAlert"
import Button from "components/Buttons/Button"
import Icon from "components/elements/Icon"
import Link from "components/elements/Link"
import dioboxPlatform from "assets/images/diobox-platform.svg"
import { useEvents } from "queries/events"
import useModal from "services/useModal"
import UpgradeAlert from "components/elements/UpgradeAlert"
import AlertBoxWrapper from "components/elements/AlertBox/AlertBoxWrapper"
import NewEvent from "./NewEvent"
import TableView from "./TableView"
import GridView from "./GridView"
import PastDueAlert from "../../components/elements/PastDueAlert"

type Props = {
  onClickResolve: () => void
  currentUser: User
}

const EventIndex = ({ onClickResolve, currentUser }: Props) => {
  enum Views {
    EventGrid,
    EventTable,
  }

  const currentAccount = useCurrentAccount()
  const [view, setView] = useState(Views.EventGrid)
  const [cloneId, setCloneId] = useState(null)
  const { pathname } = useLocation()
  const { admin, canCreateEvents } = useCurrentAccount()

  const createEventAccess = admin || canCreateEvents

  const channel = useChannel(`account_${currentAccount.id}`)
  if (channel && channel.global_callbacks.length === 0) {
    channel.bind_global((event, data) => {
      switch (event) {
        case PusherEvent.EVENT_CLONED:
          setCloneId(data.event_id)
          break
        default:
          break
      }
    })
  }

  const dateFilter = () => {
    switch (pathname) {
      case eventsFuturePath():
        return "future"
      case eventsPastPath():
        return "past"
      default:
        return null
    }
  }

  const title = () => {
    switch (dateFilter()) {
      case "future":
        return "Upcoming"
      case "past":
        return "Past"
      default:
        return "All"
    }
  }

  const { isLoading, data: events } = useEvents(currentAccount.id, dateFilter(), cloneId)
  const showModal = useModal()
  const accountIsPastDue = currentAccount?.subscription?.status === SubscriptionStatuses.PastDue

  if (isLoading) {
    return <Spinner />
  }

  if (events === undefined) {
    return null
  }

  if (events.length === 0) {
    return (
      <NoEvents
        canCreateEvent={createEventAccess}
        currentUser={currentUser}
        switchAccount={() => showModal(ModalName.SwitchAccount)}
      />
    )
  }
  const atEventLimit = !currentAccount.subscription

  return (
    <Flex direction="column" h="100vh">
      <Flex
        px={10}
        py={4}
        justify="space-between"
        align="center"
        borderBottomWidth={1}
      >
        <Heading fontSize="2xl" fontWeight="600" verticalAlign="middle">{title()} Events{" "}
          <Text
            as="span"
            fontSize="md"
          >
            ({events?.length})
          </Text>
        </Heading>
        <HStack spacing={5}>
          <Menu
            buttonText="View"
            menuItems={[
              <MenuItem
                onClick={() => setView(Views.EventGrid)}
                selected={view === Views.EventGrid}
                px={10}
              >View as Icons
              </MenuItem>,
              <MenuItem
                onClick={() => setView(Views.EventTable)}
                selected={view === Views.EventTable}
                px={10}
              >View as List
              </MenuItem>,
            ]}
          />
          {createEventAccess && !atEventLimit && (
            <NewEvent />
          )}
        </HStack>
      </Flex>
      <Box h={1} flexGrow={1} overflowY="scroll">
        <AlertBoxWrapper>
          {accountIsPastDue && (
          <PastDueAlert onClickResolve={onClickResolve} />
          )}
          {!currentUser.confirmed && (
          <ConfirmEmailAlert email={currentUser.email} />
          )}
          {atEventLimit && (
          <UpgradeAlert text="Your account is on the Free Plan and is limited to 1 event with 25 guests." />
          )}
        </AlertBoxWrapper>
        {view === Views.EventGrid && <GridView events={events} />}
        {view === Views.EventTable && <TableView events={events} />}
      </Box>
    </Flex>
  )
}

const NoEvents = (
  { canCreateEvent, currentUser, switchAccount }: {
    canCreateEvent: boolean, currentUser: User, switchAccount: () => void },
) => {
  const features = [
    { text: "Guest Lists", icon: "payment-lists" },
    { text: "Invitations", icon: "newdash-invites" },
    { text: "RSVP Forms", icon: "private-rsvp" },
    { text: "QR Check-In", icon: "qr-checkin" },
    { text: "Ticketing", icon: "ticketing-stroke" },
    { text: "Website", icon: "globe" },
    { text: "Seating", icon: "tables-stroke" },
  ]

  return (
    <Flex direction="column" h="100vh" align="center" justify="space-between">
      <AlertBoxWrapper>
        {!currentUser.confirmed && <ConfirmEmailAlert email={currentUser.email} />}
      </AlertBoxWrapper>
      <Flex align="center" direction="column">
        <Image src={dioboxPlatform} mb={5} w={521} h={222} />
        <Heading
          fontSize="2xl"
          fontWeight={600}
          mb={3}
        >Welcome to Diobox!
        </Heading>
        <Text w="350px" align="center" mb={8} fontSize="lg">
          Your all-in-one platform to seamlessly and efficiently manage the entire event lifecycle.
        </Text>
        <HStack mb={8} spacing={12}>
          {features.map((feature) => (
            <Box key={feature.text} color="gray.500" fontWeight={600} textAlign="center">
              <Icon icon={feature.icon} size={8} />
              <Text>{feature.text}</Text>
            </Box>
          ))}
        </HStack>
        {canCreateEvent && (
        <NewEvent
          button={<Button leftIcon="plus" size="sm">Create First Event</Button>}
        />
        )}
      </Flex>
      <Box pb={6}>
        <Text fontSize="sm" color="gray.700">
          Expecting to see an event that was shared with you?
          Try <Link onClick={switchAccount}>Switching Your Account</Link>.
        </Text>
      </Box>
    </Flex>
  )
}

export default EventIndex
