import { post, put, del } from "../services/api"
import { currentAccountId } from "../services/authentication"
import { Table } from "../sharedTypes"

export const putTable = (eventId: string, table: Partial<Table>) => put<Table>(
  `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/tables/${table.externalId}.json`,
  table,
)

export const postTable = (eventId: string, table: Partial<Table>) => post<Table>(
  `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/tables.json`,
  table,
)

export const deleteTable = (eventId: string, table: Table) => del<void>(
  `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/tables/${table.externalId}.json`,
)
