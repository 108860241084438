import React, { ReactNode } from "react"
import { Box } from "@chakra-ui/react"
import Divider from "components/Builder/Blocks/Divider"
import Spacer from "components/Builder/Blocks/Spacer"
import Image from "components/Builder/Blocks/Image"
import Text from "components/Builder/Blocks/Text"
import { BuilderBlock, BuilderBlockType } from "sharedTypes/builder"

type Props = {
  blocks: BuilderBlock[]
  questionRenderFunction: (block: BuilderBlock) => ReactNode
}

const FormBlocks = ({ blocks, questionRenderFunction }: Props) => (
  <>
    {blocks.map((block, i) => {
      switch (block.type) {
        case BuilderBlockType.QUESTION:
          return (
            <Box key={i} py={2}>
              {questionRenderFunction(block)}
            </Box>
          )
        case BuilderBlockType.DIVIDER:
          return <Divider key={i} block={block} />
        case BuilderBlockType.SPACER:
          return <Spacer key={i} block={block} />
        case BuilderBlockType.IMAGE:
          return <Image key={i} block={block} />
        case BuilderBlockType.TEXT:
          return <Box mx={-5}><Text key={i} block={block} /></Box>
        default:
          return <></>
      }
    })}
  </>
)

export default FormBlocks
