import React from "react"
import {
  eventSeatingPath,
} from "utilities/routes"
import { Box } from "@chakra-ui/react"
import SeatingTable from "components/elements/SeatingTable"
import PageHeader from "components/layout/PageHeader"
import Button from "components/Buttons/Button"
import Section from "components/layout/Section"
import { GuestSummary, Seat, Table as DioboxTable } from "sharedTypes"
import BackLink from "components/Navigation/BackLink"
import Lists from "./Lists"
import TableMenu from "../TableMenu"
import withTableState from "../withTableState"

type Props = {
  editing: boolean;
  setEditing: (boolean) => void;
  table: DioboxTable;
  seats: Seat[];
  waitlistedGuests: GuestSummary[];
  resetSeat: (number: number) => void;
  assignGuest: (number: number) => void;
  moveToWaitlist: (number: number) => void;
  addPlaceholder: (number: number) => void;
  editPlaceholder: (number: number) => void;
  removeGuestFromWaitlist: (guestId: string) => void;
  moveGuestToOpenSeat?: (guest: GuestSummary) => void;
  handleCancelEditing: () => void;
  handleUpdateLists: () => void;
  save: () => void;
  eventId: string;
  canEditSeating: boolean;
}

const Table = ({
  editing,
  setEditing,
  table,
  seats,
  waitlistedGuests,
  resetSeat,
  assignGuest,
  moveToWaitlist,
  addPlaceholder,
  editPlaceholder,
  removeGuestFromWaitlist,
  moveGuestToOpenSeat,
  handleCancelEditing,
  handleUpdateLists,
  save,
  eventId,
  canEditSeating,
}: Props) => {
  const {
    name,
    shape,
    topSize,
    bottomSize,
    rightSize,
    leftSize,
  } = table

  const actionButtons = editing
    ? [
      <Button size="sm" variant="outline" onClick={handleCancelEditing}>Cancel</Button>,
      <Button size="sm" onClick={save}>Save</Button>,
    ]
    : [
      <Button size="sm" variant="outline" onClick={() => setEditing(true)}>Edit Seats</Button>,
      <TableMenu
        table={table}
        buttonText="Table Options"
      />,
    ]
  if (!canEditSeating) {
    actionButtons.shift()
  }

  return (
    <Box mx={24} my={10}>
      <BackLink to={eventSeatingPath(eventId)}>Return to Table List</BackLink>
      <Box mb={20}>
        <PageHeader
          actionButtons={actionButtons}
        >{name}
        </PageHeader>
        <Section>
          <SeatingTable
            id={`page${table.id}`}
            shape={shape}
            topSeats={topSize}
            bottomSeats={bottomSize}
            rightSeats={rightSize}
            leftSeats={leftSize}
            seatsData={seats}
            editing={editing}
            onResetSeat={resetSeat}
            onAssignGuest={assignGuest}
            onMoveToWaitlist={moveToWaitlist}
            onAddPlaceholder={addPlaceholder}
          />
        </Section>
        <Lists
          seats={seats}
          waitlist={waitlistedGuests}
          onUpdateLists={handleUpdateLists}
          onDeleteWaitlistedGuest={removeGuestFromWaitlist}
          onMoveGuestToOpenSeat={moveGuestToOpenSeat}
          onResetSeat={resetSeat}
          onAssignGuest={assignGuest}
          onMoveToWaitlist={moveToWaitlist}
          onAddPlaceholder={addPlaceholder}
          onEditPlaceholder={editPlaceholder}
          canEditSeating={canEditSeating}
          editing={canEditSeating && editing}
        />
      </Box>
    </Box>
  )
}

export default withTableState(Table)
