import React from "react"
import Rating from "react-star-ratings"
import { Box } from "@chakra-ui/react"

type Props = {
  value: number
}

const ScorecardRating = ({ value }: Props) => (
  <Box textAlign="center">
    <Rating
      rating={value}
      numberOfStars={5}
      starRatedColor="#4a4a4a"
      starHoverColor="#4a4a4a"
      starDimension="1em"
      starSpacing="0em"
      isSelectable={false}
    />
  </Box>
)

export default ScorecardRating
