import React, { ReactNode } from "react"
import {
  Box, Divider, Text,
} from "@chakra-ui/react"

type Props = {
  children: ReactNode
  title: string
  first?: boolean
  last?: boolean
}

const EditModalSegment = ({
  children, title, first, last,
}: Props) => (
  <Box mt={first ? 0 : 12}>
    <Box mb={8}>
      <Text fontSize="xl" fontWeight="bold">{title}</Text>
    </Box>
    {children}
    {!last && <Divider />}
  </Box>
)

export default EditModalSegment
