import React from "react"
import { QuestionTypes } from "sharedTypes"
import {
  Checkbox, Input, Radio, Select, Stack, Textarea, Text,
} from "@chakra-ui/react"
import ButtonPreview from "./ButtonPreview"

type Props = {
  type: QuestionTypes
  choices: string[]
}

export const QuestionContent = ({ type, choices }: Props) => {
  let content

  switch (type) {
    case QuestionTypes.SingleAnswer:
      content = <Stack>{choices.map((choice, index) => <Radio key={index + choice} display="block" bg="white">{choice}</Radio>)}</Stack>
      break
    case QuestionTypes.MultipleAnswer:
      content = <Stack>{choices.map((choice, index) => <Checkbox key={index + choice} display="block">{choice}</Checkbox>)}</Stack>
      break
    case QuestionTypes.DropdownMenu:
      content = <Select display="block" w="full" borderWidth={1} bg="white">{["", ...choices].map((choice, index) => <option key={index + choice}>{choice}</option>)}</Select>
      break
    case QuestionTypes.SingleLineText:
      content = <Input display="block" w="full" borderWidth={1} type="text" bg="white" />
      break
    case QuestionTypes.ParagraphText:
      content = <Textarea display="block" w="full" borderWidth={1} bg="white" />
      break
    case QuestionTypes.FileUpload:
      content = (
        <Stack spacing={3} mt={2}>
          <ButtonPreview color="gray.50" size={40} textColor="gray.700" borderColor="gray.300" borderWidth={1}>Select File</ButtonPreview>
          <Text color="gray.600" fontSize="sm">
            Supported formats: PDF, PNG, & JPG files up to 5 MB
          </Text>
        </Stack>
      )
      break
    default:
      content = null
      break
  }

  return <>{content}</>
}

export default QuestionContent
