import {
  Box,
  Popover as ChakraPopover,
  PopoverArrow, PopoverBody, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Text,
  Portal,
  LayoutProps,
} from "@chakra-ui/react"
import React, { ReactNode } from "react"
import SimpleBar from "simplebar-react"

interface PopoverProps {
  trigger: ReactNode
  body: ReactNode
  header?: ReactNode | string
  footer?: ReactNode
  placement?: "top" | "right" | "bottom" | "left" | "auto" | "auto-start" | "auto-end" | "top-start" | "top-end" | "bottom-start" | "bottom-end" | "right-start" | "right-end" | "left-start" | "left-end"
  isDisabled?: boolean
  onOpen?: () => void
  onClose?: () => void
  arrow?: boolean
  gutter?: number
  zIndex?: number
  isOpen?: boolean
  triggerEvent?: "click" | "hover"
  contentWidth?: "auto" | "xs" | number
  contentHeight?: LayoutProps["maxHeight"]
  closeOnClick?: boolean
  closeOnBlur?: boolean
  variant?: "responsive"
}

const Popover = ({
  trigger,
  body,
  header,
  footer,
  placement,
  isDisabled,
  zIndex,
  onOpen,
  onClose,
  arrow = true,
  gutter = 8,
  isOpen,
  triggerEvent = "click",
  contentWidth = "xs",
  contentHeight,
  closeOnClick,
  closeOnBlur,
  variant,
}: PopoverProps) => (
  <ChakraPopover
    gutter={gutter}
    closeOnBlur={closeOnBlur}
    closeOnEsc
    arrowSize={15}
    placement={placement}
    onOpen={onOpen}
    onClose={onClose}
    isOpen={isOpen}
    trigger={triggerEvent}
    variant={variant}
  >
    {({ onClose: closePopover }) => (
      <>
        {!isDisabled && (
        <PopoverTrigger>
          <Box
            zIndex={zIndex}
            cursor="pointer"
            as="span"
            tabIndex={0}
            role="button"
          >
            {trigger}
          </Box>
        </PopoverTrigger>
        )}
        {isDisabled
            && <Box as="span" pointerEvents="none">{trigger}</Box>}
        <Portal>
          <Box sx={{
            "[data-popper-placement='bottom'] .chakra-popover__arrow": {
              background: header ? "gray.50!important" : "",
            },
          }}
          >
            <PopoverContent
              w={contentWidth}
              boxShadow="md"
              {...closeOnClick ? { onClick: closePopover } : {}}
              borderRadius="md"
            >
              {arrow && <PopoverArrow />}
              {header && (
              <PopoverHeader
                px={5}
                py={3}
                borderTopRadius="md"
                bgColor="gray.50"
              ><Text fontSize="xl" textAlign="center" color="black">{header}</Text>
              </PopoverHeader>
              )}
              <PopoverBody
                p={0}
                as={contentHeight ? SimpleBar : "div"}
                maxH={contentHeight}
                overflow="auto"
              >
                {body}
              </PopoverBody>
              {footer && (
              <PopoverFooter>
                {footer}
              </PopoverFooter>
              )}
            </PopoverContent>
          </Box>
        </Portal>
      </>
    )}
  </ChakraPopover>
)

export default Popover
