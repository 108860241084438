import React from "react"
import { Box } from "@chakra-ui/react"
import LabelRedefinition from "modals/FormForm/LabelRedefinition"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import { DioboxEvent, Translations } from "sharedTypes"
import TitleWithSubtitle from "../TitleWithSubtitle"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
}

const GuestConfirmationStatusesSection = ({ event, updateEvent, defaultTranslations }: Props) => {
  const onSubmit = (values) => updateEvent(values)

  return (
    <Box>
      <TitleWithSubtitle
        title="Guest Confirmation Statuses"
        subtitle="These fields indicate the guest's status on their confirmation page and correspond with a color."
        mb={5}
      />
      <ContainedForm values={event} onSubmit={onSubmit} name="Guest Confirmation Statuses">
        {({ control }) => (
          <Box maxWidth={400}>
            <LabelRedefinition
              defaultTranslation={defaultTranslations.confirmed}
              name="translations.confirmed"
              control={control}
            />
            <LabelRedefinition
              defaultTranslation={defaultTranslations.unconfirmed}
              name="translations.unconfirmed"
              control={control}
            />
            <LabelRedefinition
              defaultTranslation={defaultTranslations.waitlisted}
              name="translations.waitlisted"
              control={control}
            />
            <LabelRedefinition
              defaultTranslation={defaultTranslations.declined}
              name="translations.declined"
              control={control}
            />
          </Box>
        )}
      </ContainedForm>
    </Box>
  )
}

export default GuestConfirmationStatusesSection
