import {
  Text, Link as ChakraLink, Box,
} from "@chakra-ui/react"
import React from "react"
import { useIntercom } from "react-use-intercom"
import { Account, ModalName } from "sharedTypes"
import Link from "components/elements/Link"
import AccountError from "components/layout/AccountError"
import useModal from "services/useModal"

type Props = {
  currentAccount: Account
}

const AccountDisabled = ({ currentAccount }: Props) => {
  const { show: showIntercom } = useIntercom()
  const showModal = useModal()

  return (
    <AccountError
      title="Your account is disabled"
      footer={(
        <>
          <ChakraLink onClick={() => showModal(ModalName.SwitchAccount)}>
            Switch Account
          </ChakraLink>
          <Box mx={5}>•</Box>
          <ChakraLink onClick={() => showModal(ModalName.SwitchAccount, { newAccount: true })}>
            Create New Account
          </ChakraLink>
        </>
)}
    >
      <Text fontSize="lg" fontWeight="semibold" mb={6}>{currentAccount.name}</Text>
      <Text fontSize="sm">
        If you have any questions about your account,{" "}
        <Link onClick={showIntercom}>message us</Link>.
      </Text>
    </AccountError>
  )
}

export default AccountDisabled
