import React from "react"
import Icon from "components/elements/Icon"
import { Flex, Text } from "@chakra-ui/react"

type Props = {
  icon: string;
  text: string;
  onClick: () => void;
  color?: string;
}

const ActionButton = ({
  icon, text, onClick, color = "blue.400",
}: Props) => (
  <Flex
    as="button"
    direction="column"
    ml={4}
    w={24}
    justify="start"
    align="center"
    color={color}
    onClick={onClick}
    type="button"
  >
    <Icon icon={icon} size={5} mb={3} />
    <Text as="span" lineHeight="nono">{text}</Text>
  </Flex>
)

export default ActionButton
