import React from "react"
import {
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  Stack,
  Text,
} from "@chakra-ui/react"
import { ImportGuestsHeader } from "sharedTypes"
import Icon from "components/elements/Icon"
import HeaderDropdown from "modals/ImportGuests/ManageFields/Table/HeaderDropdown"

type Props = {
  headerConfiguration: (ImportGuestsHeader | undefined)[];
  headers: string[];
  rows: (string | undefined)[][];
  onChangeHeaderConfiguration: (number, header: ImportGuestsHeader) => void;
  customFields?: string[];
}

const ImportGuestsTable = ({
  headerConfiguration,
  headers,
  rows,
  onChangeHeaderConfiguration,
  customFields = [],
}: Props) => (
  <Table variant="simple" size="sm">
    <Thead>
      <Tr>
        {headerConfiguration.map((header, index) => (
          <Th
            key={index}
            role="columnheader"
            borderWidth={2}
            verticalAlign="top"
          >
            <Stack direction="column" align="center" justify="center" spacing={4} py={4} minW={40} w="full">
              <Icon
                icon={header ? "checkmark" : "times"}
                color={header ? "green.600" : "red.600"}
                size={6}
              />
              <HeaderDropdown
                columnIndex={index}
                selected={header}
                onChange={onChangeHeaderConfiguration}
                customField={customFields.find((v) => v === headers[index])}
              />
            </Stack>
          </Th>
        ))}
      </Tr>
    </Thead>
    <Tbody>
      {rows!.map((row, i) => (
        <Tr key={i}>
          {row!.map((field, j) => (
            <Td
              key={j}
              borderWidth={2}
              background={headerConfiguration[j] ? "green.100" : "red.100"}
            >
              <Text w={40} noOfLines={1}>{field}</Text>
            </Td>
          ))}
        </Tr>
      ))}
    </Tbody>
  </Table>
)

export default ImportGuestsTable
