import React from "react"
import { useChannel } from "@harelpls/use-pusher"
import {
  addEmail,
  addEventGroup,
  addGuestTransaction,
  addTable,
  removeEmail,
  removeEventGroup,
  removeGuestTransaction,
  removeTable,
  updateEmail,
  updateEventGroup,
  updateGuest,
  updateGuestTransaction,
  updateTable,
  updateWebsiteViewsCount, refetchGuests,
} from "context/actions"
import {
  EventGroup, Guest, Email, Table, Transaction,
} from "sharedTypes"
import { getGuest } from "api/guests"
import { camelizeKeys } from "humps"
import _ from "lodash"
import { PusherEvent } from "utilities/enums"
import { errorToast, infoToast } from "utilities/toasts"
import { useQueryClient } from "react-query"
import { eventCacheKey, useCurrentEvent } from "queries/events"
import Spinner from "./elements/Spinner"

const PusherWrapper = ({ children }) => {
  const { data: currentEvent, isLoading } = useCurrentEvent()
  const queryClient = useQueryClient()

  const channel = useChannel(`event_${currentEvent?.id}`)

  if (isLoading) {
    return <Spinner />
  }

  if (!currentEvent?.id) {
    return null
  }

  if (channel && channel.global_callbacks.length === 0) {
    channel.bind_global((event, data) => {
      const camelizedData: {[x: string]: any} = camelizeKeys(data)
      handleMessage(event, camelizedData)
    })
  }

  const handleMessage = (
    event, camelizedData: { [p: string]: any },
  ) => {
    switch (event) {
      case PusherEvent.EVENT_GROUP_CREATED:
        addEventGroup(queryClient, currentEvent.id, camelizedData as EventGroup)
        break
      case PusherEvent.EVENT_GROUP_DELETED:
        removeEventGroup(queryClient, currentEvent.id, camelizedData.id)
        break
      case PusherEvent.EVENT_GROUP_UPDATED:
        updateEventGroup(queryClient, currentEvent.id, camelizedData as EventGroup)
        break
      case PusherEvent.EVENT_UPDATED:
        debouncedRefreshEvent()
        break
      case PusherEvent.GUEST_CREATED:
      case PusherEvent.GUEST_DELETED:
      case PusherEvent.GUESTS_DELETED:
        debouncedRefreshGuests(queryClient, currentEvent.id)
        break
      case PusherEvent.GUEST_UPDATED:
        if (camelizedData.guestId) {
          getGuest(currentEvent.id, camelizedData.guestId)
            .then(({ data }) => updateGuest(queryClient, data as Guest))
        } else {
          updateGuest(queryClient, camelizedData as Guest)
        }
        break
      case PusherEvent.IMPORT_COMPLETED:
        infoToast({ title: "Import complete", duration: null })
        break
      case PusherEvent.IMPORT_FAILED:
        errorToast({ title: "Import failed" })
        break
      case PusherEvent.INVITATION_CREATED:
        addEmail(queryClient, currentEvent.id, camelizedData as Email)
        break
      case PusherEvent.INVITATION_UPDATED:
        updateEmail(queryClient, currentEvent.id, camelizedData as Email)
        break
      case PusherEvent.INVITATION_DELETED:
        removeEmail(queryClient, currentEvent.id, camelizedData.id)
        break
      case PusherEvent.TABLE_CREATED:
        addTable(queryClient, currentEvent.id, camelizedData as Table)
        break
      case PusherEvent.TABLE_UPDATED:
        updateTable(queryClient, currentEvent.id, camelizedData as Table)
        break
      case PusherEvent.TABLE_DELETED:
        removeTable(queryClient, currentEvent.id, camelizedData.id)
        break
      case PusherEvent.TRANSACTION_CREATED:
        addGuestTransaction(queryClient, camelizedData as Transaction)
        break
      case PusherEvent.TRANSACTION_DELETED:
        removeGuestTransaction(queryClient, camelizedData as Transaction)
        break
      case PusherEvent.TRANSACTION_UPDATED:
        updateGuestTransaction(queryClient, camelizedData as Transaction)
        break
      case PusherEvent.WEBSITE_VIEWS_COUNT_UPDATED:
        updateWebsiteViewsCount(queryClient, currentEvent.id, camelizedData.viewsCount)
        break
      default:
        break
    }
  }

  const refreshEvent = () => {
    queryClient.invalidateQueries(eventCacheKey(currentEvent.id))
  }
  const debouncedRefreshEvent = _.debounce(refreshEvent, 250)

  const debouncedRefreshGuests = _.debounce(refetchGuests, 250)

  return children
}

export default PusherWrapper
