import {
  put, post, del, get,
} from "services/api"
import { currentAccountId } from "services/authentication"
import {
  AgendaRow, APIError, Speaker, Website,
} from "sharedTypes"
import { PutWebsite } from "sharedTypes/api"

export const putWebsite = (eventId: string, website: PutWebsite) => put<Website>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/website.json`, website)

export const validateWebsiteUrlPath = (urlPath: string) => get<{valid: boolean, error: APIError}>(`${import.meta.env.VITE_API_BASE}/api/v3/websites/validate`, { urlPath })

export const postSpeaker = (
  eventId: string,
  speaker: Speaker,
) => post<Speaker>(
  `${import.meta.env.VITE_API_BASE}/api/v3`
  + `/accounts/${currentAccountId()}`
  + `/events/${eventId}/website/speakers.json`,
  speaker,
)

export const putSpeaker = (
  eventId: string,
  speakerId: number,
  speaker: Speaker,
) => put<Speaker>(
  `${import.meta.env.VITE_API_BASE}/api/v3`
  + `/accounts/${currentAccountId()}`
  + `/events/${eventId}`
  + `/website/speakers/${speakerId}.json`,
  speaker,
)

export const deleteSpeaker = (
  eventId: string,
  speakerId: number,
) => del<void>(
  `${import.meta.env.VITE_API_BASE}/api/v3`
  + `/accounts/${currentAccountId()}`
  + `/events/${eventId}`
  + `/website/speakers/${speakerId}.json`,
)

export const postSpeakersOrder = (
  eventId: string,
  order: number[],
) => post<void>(
  `${import.meta.env.VITE_API_BASE}/api/v3`
  + `/accounts/${currentAccountId()}`
  + `/events/${eventId}/website/speakers/sort.json`,
  { speakers: order },
)

export const postAgendaRow = (
  eventId: string,
  agendaRow: AgendaRow,
) => post<AgendaRow>(
  `${import.meta.env.VITE_API_BASE}/api/v3`
  + `/accounts/${currentAccountId()}`
  + `/events/${eventId}/website/agenda_rows.json`,
  agendaRow,
)

export const putAgendaRow = (
  eventId: string,
  agendaRowId: number,
  agendaRow: AgendaRow,
) => put<AgendaRow>(
  `${import.meta.env.VITE_API_BASE}/api/v3`
  + `/accounts/${currentAccountId()}`
  + `/events/${eventId}`
  + `/website/agenda_rows/${agendaRowId}.json`,
  agendaRow,
)

export const deleteAgendaRow = (
  eventId: string,
  agendaRowId: number,
) => del<void>(
  `${import.meta.env.VITE_API_BASE}/api/v3`
  + `/accounts/${currentAccountId()}`
  + `/events/${eventId}`
  + `/website/agenda_rows/${agendaRowId}.json`,
)
