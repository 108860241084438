import React from "react"
import { Flex, Box, Text } from "@chakra-ui/react"
import { DioboxEvent, Form, FormType } from "sharedTypes"
import Icon from "components/elements/Icon"
import { formIconAndType, formStatusAndColor, getFormExternalUrl } from "utilities/forms"
import { asMoney } from "utilities/strings"
import Menu, { MenuItem } from "components/dialogs/Menu"
import { eventSubmissionsPath, eventEditFormPath } from "utilities/routes"
import { Link } from "react-router-dom"

type Props = {
  form: Form
  event: DioboxEvent
  currency: string
  showFormSubmissionsLink?: boolean
  flip?: boolean
}

const FormIntegrationListItem = ({
  form, event: { id: eventId }, event, currency, showFormSubmissionsLink = true,
}:Props) => {
  const {
    type, name, price, description, externalId: formId,
  } = form

  const { icon } = formIconAndType(type)
  const { color } = formStatusAndColor(form, event)

  return (
    <Box>
      <Flex justifyContent="space-between">
        <Flex alignItems="center">
          <Icon icon="dot" size={3} mr={3} color={color} />
          <Icon icon={icon} size={7} mr={3} />
          <Text fontWeight="semibold">{name}</Text>
          {type === FormType.Ticket && <Text fontWeight="semibold" ml={1}>{asMoney(price, currency)}</Text>}
        </Flex>
        <Box>
          <Menu
            noPadding
            buttonText="Form"
            placement="bottom-end"
            menuItems={[
              <MenuItem as="a" href={getFormExternalUrl(formId, type === FormType.RSVP)} target="_blank" {...(MenuItem as any).props}>
                Preview Form
              </MenuItem>,
              <MenuItem as={Link} to={eventEditFormPath(eventId, formId)}>
                Edit Form
              </MenuItem>,
              showFormSubmissionsLink && (
                <MenuItem as={Link} to={`${eventSubmissionsPath(eventId)}?formId=${formId}`}>
                  View Form Submissions
                </MenuItem>
              ),
            ]}
          />
        </Box>
      </Flex>
      <Flex pl={16}>
        <Text textColor="gray.500">{description}</Text>
      </Flex>
    </Box>
  )
}

export default FormIntegrationListItem
