import React, { ReactNode } from "react"
import { HStack } from "@chakra-ui/react"

type Props = {
  children: ReactNode
}

const HeaderTabs = ({ children }: Props) => <HStack gap={2.5} pt={6} pb={3} bg="white" zIndex={100}>{children}</HStack>

export default HeaderTabs
