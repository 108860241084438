import React from "react"
import GuestAvatar from "components/elements/GuestAvatar"
import { Seat as DioboxSeat, Table } from "sharedTypes"
import Icon from "components/elements/Icon"
import { firstName, lastName } from "utilities/strings"
import { Box } from "@chakra-ui/react"
import VacantSeat from "./VacantSeat"
import BlockedSeatItem from "../../Table/Lists/BlockedSeatItem"
import GuestItem from "../../Table/Lists/GuestItem"

type Props = {
  seat: DioboxSeat;
  table: Table;
  onResetSeat: () => void;
  onAssignGuest: () => void;
  onMoveToWaitlist: () => void;
  onAddPlaceholder: () => void;
  onEditPlaceholder: () => void;
  canEditSeating: boolean;
  isVisible?: boolean;
}

const Seat = ({
  table,
  seat,
  onResetSeat,
  onAssignGuest,
  onMoveToWaitlist,
  onAddPlaceholder,
  onEditPlaceholder,
  canEditSeating,
  isVisible = true,
}: Props) => {
  const {
    guest, reserved,
  } = seat
  if (!isVisible) {
    return <Box w={10} h={10} borderRadius="full" bg="gray.100" />
  }

  if (reserved) {
    return (
      <BlockedSeatItem
        seat={seat}
        onEditPlaceholder={onEditPlaceholder}
        onRemovePlaceholder={onResetSeat}
        canEditSeating={canEditSeating}
      >
        <Icon icon="seat-blocked" size={10} alt="Blocked" />
      </BlockedSeatItem>
    )
  }

  if (!guest) {
    return (
      <VacantSeat
        table={table}
        seat={seat}
        onAddPlaceholder={onAddPlaceholder}
        onAssignGuest={onAssignGuest}
        canEditSeating={canEditSeating}
      />
    )
  }

  const {
    name, status, checkedIn, photoUrl,
  } = guest

  return (
    <GuestItem
      guest={guest}
      seatNumber={seat.number}
      onMoveGuestToWaitlist={onMoveToWaitlist}
      onRemoveGuestFromSeat={onResetSeat}
      canEditSeating={canEditSeating}
    >
      <GuestAvatar
        firstName={firstName(name)}
        lastName={lastName(name)}
        status={status}
        checkedIn={!!checkedIn}
        photoUrl={photoUrl}
      />
    </GuestItem>
  )
}

export default Seat
