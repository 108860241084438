import React, { useState } from "react"
import EventResourcesView from "components/layout/EventResourcesView"
import { Box, Text } from "@chakra-ui/react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import * as Yup from "yup"
import { DioboxEvent, MessageTemplateGroup, FormType } from "sharedTypes"
import FormTypeSelectListItem from "components/layout/FormTypeSelectListItem"
import { useMutation, useQueryClient } from "react-query"
import { postInvitation } from "api/emails"
import { addEmail } from "context/actions"
import { useHistory } from "react-router"
import { eventEmailSetupPath } from "utilities/routes"
import { useForms } from "queries/forms"
import Spinner from "components/elements/Spinner"
import { useAccountConfiguration } from "queries/users"
import SelectedGroup from "components/MessageTemplates/SelectedGroup"
import useFeatureFlag from "services/featureFlags"

type Props = {
  event: DioboxEvent
  onClose: () => void
}

const NewEmail = ({
  event, onClose,
}: Props) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const {
    isLoading: accountConfigurationIsLoading,
    accountConfiguration,
  } = useAccountConfiguration()
  const { id: eventId } = event
  const { isLoading, data: forms } = useForms(eventId)
  const create = useMutation(
    ({ templateId, formId }: {templateId: string, formId: string}) => postInvitation(
      eventId,
      { invitation: { templateId, formId } },
    ).then(({ data }) => data), {
      onSuccess: (data) => {
        addEmail(queryClient, eventId, data)
        history.push(eventEmailSetupPath(eventId, data.id))
        onClose()
      },
    },
  )
  const { surveyForms: surveyFormsEnabled } = useFeatureFlag()

  const { emailTemplates } = accountConfiguration
  let { groups } = emailTemplates
  if (!surveyFormsEnabled) {
    groups = groups.filter((group) => group.name !== "Surveys & Data Collection")
  }

  const [selectedGroup, setSelectedGroup] = useState<MessageTemplateGroup>(groups[0])

  const validationSchema = Yup.object().shape({
    templateId: Yup.string(),
    formType: Yup.string().nullable(),
    formId: Yup.string().when(
      "formType", {
        is: undefined,
        then: (schema) => schema.nullable(),
        otherwise: (schema) => schema.required(),
      },
    ),
  })

  return (
    <ModalForm
      title="New Email"
      size="4xl"
      h="80%"
      noPadding
      onClose={onClose}
      onSubmit={(values) => create.mutateAsync(values)}
      submitText="Create"
      initialValues={{ templateId: null, formId: null }}
      validationSchema={validationSchema}
    >
      {({ watch, control, reset }) => {
        if (isLoading || accountConfigurationIsLoading) {
          return <Spinner heightFull />
        }

        if (!forms) {
          return null
        }

        const templateId = watch("templateId")

        const handleSelectTemplate = (selectedTemplateId: string, formType?: FormType) => {
          // After we changed the email type, we need to reset the selected form type too
          const formIdToSelect = formType ? forms.data.filter((form) => form.type === formType).map((form) => form.externalId)[0] : ""
          // We need the formType value stored in the form values to use for validation
          reset({ templateId: selectedTemplateId, formId: formIdToSelect, formType })
        }

        return (
          <EventResourcesView
            listWidth="sm"
            showBorder={false}
            listItems={groups.map(
              (group) => (
                <FormTypeSelectListItem
                  selected={group?.name === selectedGroup?.name}
                  onClick={() => { setSelectedGroup(group) }}
                >
                  <Box>
                    <Text fontSize="sm" fontWeight="semibold" noOfLines={1}>{group?.name}</Text>
                  </Box>
                </FormTypeSelectListItem>
              ),
            )}
            selectedItem={(
              <SelectedGroup
                onSelectTemplate={handleSelectTemplate}
                group={selectedGroup}
                selectedTemplateId={templateId}
                control={control}
                event={event}
                forms={forms.data}
              />
              )}
          />
        )
      }}
    </ModalForm>
  )
}

export default NewEmail
