import React from "react"
import { Guest } from "sharedTypes"
import { asMoney } from "utilities/strings"
import { Grid } from "@chakra-ui/react"
import ScorecardRating from "./Rating"
import ScorecardItem from "./ScorecardItem"

type Props = {
  guest: Guest
  currency: string
}

const Scorecard = ({
  currency,
  guest: {
    personInfo: {
      totalEvents, totalConfirmed, totalAttended, noShows, averageRating, totalSpent,
    },
  },
}: Props) => (
  <Grid gap={4} templateColumns="repeat(3, 1fr)">
    <ScorecardItem name="Total Events" value={totalEvents} />
    <ScorecardItem name="Confirmed" value={totalConfirmed} color="blue.500" />
    <ScorecardItem name="Attended" value={totalAttended} color="green.500" />
    <ScorecardItem name="No Show" value={noShows} color="red.500" />
    <ScorecardItem name="Avg. Rating" value={<ScorecardRating value={averageRating} />} />
    <ScorecardItem name="Total Spend" value={asMoney(totalSpent, currency)} />
  </Grid>
)

export default Scorecard
