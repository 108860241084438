import React from "react"
import { FixedBlockType, Form, FormType } from "sharedTypes"
import { DraggableItem } from "sharedTypes/builder"
import { BlockInsertPosition } from "components/Builder/BuildingBlock"
import FixedBlock from "../FixedBlock"
import GuestNameBlock from "../GuestNameBlock/GuestNameBlock"
import RegistrationFieldsBlock from "../RegistrationFields/RegistrationFields"

type Props = {
  form: Form
  rsvpConfirm: string
  rsvpDecline: string
  portalPrimaryColor: string
  editable?: boolean
  selectedFixedBlockType?: FixedBlockType
  onDropBlock?: (
    item: DraggableItem,
    blockId: number,
    position: BlockInsertPosition
  ) => void
  onSelectFixedBlockType?: (type: FixedBlockType) => void
}

const TopFixedBlockContent = ({
  form: { type },
  form,
  rsvpConfirm,
  rsvpDecline,
  portalPrimaryColor,
  editable,
  selectedFixedBlockType,
  onDropBlock,
  onSelectFixedBlockType,
}: Props) => {
  switch (type) {
    case FormType.RSVP:
    case FormType.Survey: {
      return (
        <FixedBlock
          editable={editable}
          selected={selectedFixedBlockType === FixedBlockType.GuestName}
          onSelect={() => onSelectFixedBlockType?.(FixedBlockType.GuestName)}
          onDropInto={onDropBlock}
          blockInsertPosition={BlockInsertPosition.Bottom}
        >
          <GuestNameBlock
            positiveAnswer={rsvpConfirm}
            negativeAnswer={rsvpDecline}
            form={form}
            checkboxColor={portalPrimaryColor}
            showRSVPRadioButtons={type === FormType.RSVP}
          />
        </FixedBlock>
      )
    }
    default: {
      return (
        <FixedBlock
          editable={editable}
          selected={selectedFixedBlockType === FixedBlockType.Registration}
          onSelect={() => onSelectFixedBlockType?.(FixedBlockType.Registration)}
          onDropInto={onDropBlock}
          blockInsertPosition={BlockInsertPosition.Bottom}
        >
          <RegistrationFieldsBlock form={form} />
        </FixedBlock>
      )
    }
  }
}

export default TopFixedBlockContent
