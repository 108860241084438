import _ from "lodash"
import { postAccount } from "api/accounts"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { eventsPath } from "utilities/routes"
import { Account, User } from "sharedTypes"
import { storeCurrentAccountId } from "services/authentication"
import Modal from "components/dialogs/Modal"
import {
  Text, List, ListItem, Flex, Box, Heading,
} from "@chakra-ui/react"
import * as Yup from "yup"
import { setCurrentUser } from "context/actions"
import PlanText from "components/elements/PlanText"
import { useQueryClient } from "react-query"
import AccountLogo from "components/elements/AccountLogo"
import Icon from "components/elements/Icon"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextField from "components/ReactHookForm/TextField"

type Props = {
  accounts: Account[]
  hide: () => void
  currentUser: User
  newAccount?: boolean
}

const AccountChooser = ({
  accounts, hide, currentUser, newAccount = false,
}: Props) => {
  const history = useHistory()
  const [addAccount, setAddAccount] = useState(newAccount)

  const switchAccount = (account) => {
    storeCurrentAccountId(account.id)
    history.push(eventsPath())
    hide()
  }

  const onHide = () => {
    setAddAccount(false)
    hide()
  }

  return !addAccount ? (
    <AccountList
      accounts={accounts}
      switchAccount={switchAccount}
      addAccount={() => setAddAccount(true)}
      onHide={onHide}
    />
  ) : <AccountForm hide={onHide} switchAccount={switchAccount} currentUser={currentUser} />
}

const AccountList = ({
  accounts, switchAccount, addAccount, onHide,
}) => {
  const [ownAccounts, othersAccounts] = _.partition(accounts, { owner: true })

  return (
    <Modal
      show
      title="Switch Account"
      hide={onHide}
      noPadding
      size="xl"
    >
      {ownAccounts.length > 0 && (
      <AccountListSection
        title="Account Owner"
        accounts={ownAccounts}
        switchAccount={switchAccount}
      />
      )}
      {othersAccounts.length > 0
      && (
      <AccountListSection
        title="Team Member"
        accounts={othersAccounts}
        switchAccount={switchAccount}
      />
      )}
      <Flex
        align="center"
        py={5}
        px={10}
        role="button"
        onClick={addAccount}
        fontSize="lg"
        fontWeight="bold"
        color="blue.500"
      ><Icon icon="plus" mr={2} /> Add Account
      </Flex>
    </Modal>
  )
}

type AccountFormProps = {
  hide: () => void
  switchAccount: (account: Account) => void
  currentUser: User
}

const AccountForm = ({ hide, switchAccount, currentUser }: AccountFormProps) => {
  const queryClient = useQueryClient()

  const onSubmit = (values) => postAccount(values).then((response) => {
    let { accounts } = currentUser
    accounts = [response.data, ...accounts]
    setCurrentUser(queryClient, { ...currentUser, ...{ accounts } })
    switchAccount(response.data)
  })

  const AccountSchema = Yup.object().shape({
    name: Yup.string().required(),
  })

  return (
    <ModalForm
      title="New Account"
      onClose={hide}
      initialValues={{ name: "" }}
      validationSchema={AccountSchema}
      onSubmit={onSubmit}
    >
      {({ control }) => (
        <>
          <Text mb={4}>Create a new account for a new set of events and contacts database.</Text>
          <TextField
            name="name"
            control={control}
            placeholder="Name"
          />
        </>
      )}
    </ModalForm>
  )
}

const AccountListSection = ({
  title, accounts, switchAccount,
}) => (
  <Box pt={8}>
    <Heading fontSize="lg" fontWeight="normal" mx={6}>{title}</Heading>
    <List borderBottomWidth={1} px={6} pt={4} pb={5}>
      {_.sortBy(accounts, "name").map((account) => (
        <ListItem
          cursor="pointer"
          py={3}
          key={account.id}
          onClick={() => switchAccount(account)}
        >
          <Flex align="center">
            <AccountLogo logoUrl={account.logoUrl} accountName={account.name} showBorder />
            <Text fontSize="lg" fontWeight="bold" ml={4} mr="auto">{account.name}</Text>
            <PlanText
              w="140px"
              py={0.5}
              text={`${account.subscription?.type || "Free"} Plan`}
            />
          </Flex>
        </ListItem>
      ))}
    </List>
  </Box>
)

export default AccountChooser
