import React from "react"
import {
  Box, Flex, Stack, Text,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import TimestampLine from "./TimestampLine"

type Props = {
  createdTime: string
  updatedAt: string
  timeZone: string
  confirmedTime?: string
}

const Timestamps = ({
  createdTime, updatedAt, timeZone, confirmedTime,
}: Props) => (
  <Box py={6} px={5}>
    <Flex mb={2} alignItems="center">
      <Icon icon="date-time-filled" mr={3} color="gray.300" />
      <Text color="gray.500">Timestamps</Text>
    </Flex>
    <Stack pl={7} spacing={1.5}>
      <TimestampLine label="Created" dateTime={createdTime} timeZone={timeZone} />
      <TimestampLine label="Last Modified" dateTime={updatedAt} timeZone={timeZone} />
      {confirmedTime && (
        <TimestampLine label="Last Confirmed" dateTime={confirmedTime} timeZone={timeZone} />
      )}
    </Stack>
  </Box>
)

export default Timestamps
