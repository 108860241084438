import React from "react"
import { Box, Text as ChakraText } from "@chakra-ui/react"
import TextActivityPopup from "components/dialogs/TextActivityPopup"
import Icon from "components/elements/Icon"
import { DioboxEvent, TextActivityItem } from "sharedTypes"
import Body from "./Body"

type Props = {
  item: TextActivityItem
  event: DioboxEvent
}

const Text = ({ item: { displayType, data: { body, status } }, item, event }: Props) => (
  <>
    <Box mb={2}>
      <TextActivityPopup
        trigger={(
          <>
            <Icon
              size={6}
              icon="text-sms"
              mr={2}
              color={`textStatus.${status}`}
            />
            <ChakraText as="span" color="gray.900">{displayType}</ChakraText>
          </>
        )}
        header="Text Message"
        item={item}
        event={event}
      />
    </Box>
    <Body text={body} />
  </>
)

export default Text
