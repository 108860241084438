import React from "react"
import {
  Box, Flex, HStack, Text,
} from "@chakra-ui/react"
import { asMoney } from "utilities/strings"
import { SubmissionLineItem } from "sharedTypes"
import Icon from "components/elements/Icon"
import { getFormSubmissionExternalUrl, submissionIsPaid } from "utilities/forms"
import Link from "components/elements/Link"
import SubmissionCard from "pages/EventShow/Submissions/FormSubmissions/FormSubmissionsDetailView/SubmissionCard"

type Props = {
  index: number
  lineItem: SubmissionLineItem
}

const LineItemCard = ({ index, lineItem }: Props) => (
  <SubmissionCard
    header={(
      <Flex
        justify="space-between"
        fontSize="xl"
        fontWeight="semibold"
        w="100%"
      >
        <HStack fontWeight="semibold">
          <Icon icon={`form-${lineItem.form.type.toLowerCase()}`} size={6} />
          <Text>{lineItem.name}</Text>
          {submissionIsPaid(lineItem.price) && (
            <Text>{asMoney(lineItem.price, lineItem.currency)}</Text>
          )}
        </HStack>
        <Text color="gray.500">#{index}</Text>
      </Flex>
      )}
  >
    <Flex justify="space-between">
      {lineItem.guest && (
      <Box>
        <Text fontSize="xl">{lineItem.guest.firstName} {lineItem.guest.lastName}</Text>
        <Text>{lineItem.guest.email}</Text>
      </Box>
      )}
      <Link
        color="gray.500"
        href={getFormSubmissionExternalUrl(lineItem.externalId)}
        isExternal
      >
        <Icon icon="link-external" size={5} />
      </Link>
    </Flex>
  </SubmissionCard>
)

export default LineItemCard
