import React from "react"
import {
  Box,
  FormControl, FormLabel,
} from "@chakra-ui/react"
import "react-quill/dist/quill.snow.css"
import ReactQuill, { Quill } from "react-quill"
import getPlaceholderModule from "quill-placeholder-module"
import { MERGE_TAGS } from "utilities/enums"
import { Control, FieldValues, useController } from "react-hook-form"

// @ts-ignore
Quill.register("modules/placeholder", getPlaceholderModule(Quill, {
  className: "ql-placeholder-content",
}))

const AlignStyle = Quill.import("attributors/style/align")
Quill.register(AlignStyle, true)

/* When you paste a formatted text (e.g. a bold one) the quill will automatically set the
   style: color to the color of the original text, and we cannot override it from it's parent
   componet as we do in normal cases so we should filter the colors this way. */
const ColorAttributor = Quill.import("attributors/style/color")
ColorAttributor.whitelist = []
Quill.register(ColorAttributor)

export enum RichTextFieldType {
  EMAIL_BLOCK_TEXT = "emailBlockText",
  SIMPLE = "simple",
  WEBSITE = "website",
  QUESTION = "question",
  MINIMAL = "minimal",
  FORM_BLOCK_TEXT = "formBlockText",
}

type Props<T extends FieldValues> = {
  name: string;
  control: Control<T, object>;
  label?: string;
  vertical?: boolean;
  type?: RichTextFieldType;
  placeholder?: string;
  className?: string
}

const RichTextField = <T extends FieldValues, >({
  name, control, label, vertical, type, placeholder, className,
}: Props<T>) => {
  const { field } = useController<any>({ control, name })

  return (
    <FormControl mb={4} display={vertical ? "initial" : "flex"}>
      {label && <FormLabel fontWeight="bold" w={vertical ? "auto" : 1 / 4}>{label}</FormLabel>}
      <Box w="100%" bg="white" className="rich-text-editor-container">
        <CustomToolbar type={type} />
        <ReactQuill
          theme="snow"
          value={field.value}
          modules={modules(type)}
          onChange={(content, delta, source) => {
            if (source !== "user") {
              return
            }

            field.onChange(content)
          }}
          placeholder={placeholder}
          className={className}
          bounds=".rich-text-editor-container"
        />
      </Box>
    </FormControl>
  )
}

const modules = (type) => {
  switch (type) {
    case RichTextFieldType.EMAIL_BLOCK_TEXT:
      return {
        toolbar: "#toolbar",
        placeholder: {
          delimiters: ["{{", "}}"],
          placeholders: MERGE_TAGS.map(({ id }) => ({ id, label: id })),
        },
      }
    case RichTextFieldType.FORM_BLOCK_TEXT:
      return {
        toolbar: "#toolbar",
      }
    case RichTextFieldType.SIMPLE:
      return {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link"],
          ["clean"],
        ],
      }
    case RichTextFieldType.WEBSITE:
      return {
        toolbar: [
          [{ size: [] }],
          [{ align: [] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link"],
          ["clean"],
        ],
      }
    case RichTextFieldType.QUESTION:
      return {
        toolbar: [
          ["bold", "italic", "strike"],
          ["link"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ align: [] }],
        ],
      }
    case RichTextFieldType.MINIMAL:
      return {
        toolbar: [
          ["bold", "italic", "strike", "link"],
        ],
      }
    default:
      return {
        toolbar: [
          [{ size: [] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link"],
          ["clean"],
        ],
      }
  }
}

const CustomToolbar = ({ type }) => {
  const commonTools = (
    <>
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <select className="ql-align">
          <option value="" />
          <option value="center" />
          <option value="right" />
          <option value="justify" />
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-link" />
      </span>
      <span className="ql-formats">
        <button className="ql-clean" />
      </span>
    </>
  )

  switch (type) {
    case RichTextFieldType.EMAIL_BLOCK_TEXT:
      return (
        <div id="toolbar">
          {commonTools}
          <span className="ql-formats">
            <select className="ql-placeholder">
              {MERGE_TAGS.map(({ id, label }) => <option key={id} value={id}>{label}</option>)}
            </select>
          </span>
        </div>
      )
    case RichTextFieldType.FORM_BLOCK_TEXT:
      return (
        <div id="toolbar">
          {commonTools}
        </div>
      )
    default:
      return <></>
  }
}

export default RichTextField
