import { setCurrentUser, updateCurrentAccount } from "context/actions"
import { clearSession } from "services/authentication"
import React from "react"
import { Account as DioboxAccount, User } from "sharedTypes"
import * as api from "api/accounts"
import { Box, Text } from "@chakra-ui/react"
import { useQueryClient } from "react-query"
import ConfirmDeleteWithCheckboxes from "components/dialogs/Confirm/ConfirmDeleteWithCheckboxes"
import { errorToast } from "utilities/toasts"
import Link from "components/elements/Link"
import Settings from "./Settings/Settings"
import ContactSettings from "./Settings/ContactSettings"
import AccountExport from "./Settings/AccountExport"
import useFeatureFlag from "../../services/featureFlags"

interface Props {
  currentUser: User
  account: DioboxAccount
}

const Account = ({ currentUser: { email: userEmail }, account }: Props) => {
  const queryClient = useQueryClient()
  const { accountExport: accountExportEnabled } = useFeatureFlag()

  const updateAccount = (params) => {
    const data = { ...account, ...params }

    return api.putAccount(data).then((response) => {
      updateCurrentAccount(queryClient, response.data)
    })
  }

  const deleteAccount = (params) => api.checkPassword(params).then((response) => {
    if (response.data.passed) {
      return api.deleteAccount().then(() => {
        clearSession()
        setCurrentUser(queryClient, {} as User)
      })
    }

    return Promise.reject(response.data)
  })

  const handleSubmit = async (values) => {
    try {
      await deleteAccount(values)
    } catch (error) {
      errorToast({ title: "Password is incorrect" })
    }
  }

  return (
    <Box px={32} py={8}>
      <Box pb={10}>
        <Text fontSize="4xl" mb={8} fontWeight="semibold">Account Settings</Text>
        <Settings
          id={account.id}
          name={account.name}
          industry={account.industry}
          logoUrl={account.logoUrl}
          updateAccount={updateAccount}
        />
      </Box>
      {accountExportEnabled && (
        <Box pb={10}>
          <Text fontSize="4xl" mb={8} fontWeight="semibold">Account Export</Text>
          <AccountExport userEmail={userEmail} />
        </Box>
      )}
      <Box pb={10}>
        <Text fontSize="4xl" mb={8} fontWeight="semibold">Contact Settings</Text>
        <ContactSettings />
      </Box>
      {!account.subscription
      && (
      <Box py={8}>
        <ConfirmDeleteWithCheckboxes
          onSubmit={handleSubmit}
          checkboxTexts={[
            "All events will be deleted",
            "All guests will be deleted",
            "Event websites will be removed",
            "This cannot be undone",
          ]}
          passwordRequired
          type="Account"
          name={account.name}
        >
          {({ setShowModal }) => (
            <>
              <Text w={1 / 4} mr={3} fontWeight="bold">Delete Account</Text>
              <Box w="100%">
                To permanently delete your account, all events, and all guests, {" "}
                <Link onClick={() => setShowModal(true)}>click here</Link>.
              </Box>
            </>
          )}
        </ConfirmDeleteWithCheckboxes>
      </Box>
      )}
    </Box>
  )
}

export default Account
