import React, { ReactNode } from "react"
import Select from "components/ReactHookForm/Select"
import TextField from "components/ReactHookForm/TextField"
import TextArea from "components/ReactHookForm/TextArea"
import DatePickerField from "components/ReactHookForm/DatePickerField"
import { Control } from "react-hook-form"
import { Stack } from "@chakra-ui/react"
import { Boolean } from "utilities/enums"

type Props = {
  control: Control<object, any>;
  showVisibilityInput?: boolean
  showQuantityInput?: boolean
  children?: ReactNode;
  }

const BasicInputs = ({
  control, showVisibilityInput, showQuantityInput, children,
}: Props) => (
  <Stack spacing={6}>
    <TextField
      name="name"
      control={control}
      label="Name"
      variant="outline"
    />
    <TextArea
      name="description"
      control={control}
      label="Description"
      helperText="The form name and description will appear on the Guest Portal and receipt."
      placeholder="Description (optional)"
    />
    {showVisibilityInput && (
      <Select
        name="private"
        control={control}
        label="Visibility"
        options={visibilityOptions}
        helperText="Make this “Private” to hide it from the Guest Portal listing."
        fullWidth
      />
    )}
    <DatePickerField
      showTimeSelect
      name="submissionsEndAt"
      control={control}
      label="Closing Time"
      variant="outline"
      helperText="The form will close on this date.
              You can clear this date if you don't want the form to close, even after your event ends."
      showDeleteButton
    />
    {showQuantityInput && (
      <TextField
        name="quantity"
        control={control}
        label="Quantity"
        variant="outline"
        helperText="The form will close after the quantity is reached."
        placeholder="Unlimited"
        type="number"
        showDeleteButton
      />
    )}
    {children}
  </Stack>
)

const visibilityOptions = [{ label: "Public", value: Boolean.False }, { label: "Private", value: Boolean.True }]

export default BasicInputs
