import React, { ReactNode } from "react"
import { Box, Flex } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import ShrinkWrap from "components/ShrinkWrap"

type Props = {
  isChecked?: boolean
  children?: ReactNode
  isDisabled?: boolean
  color?: string
}

const RSVPCheckbox = ({
  isChecked, children, isDisabled, color = "blue.400",
}: Props) => (
  <Flex cursor={isDisabled ? "not-allowed" : "pointer"} zIndex={0}>
    <Box
      position="relative"
      w={5}
      h={5}
      mr={1}
      flexShrink={0}
    >
      <Icon
        icon="rsvp-checkbox"
        position="absolute"
        width="100%"
        height="100%"
        zIndex="1"
        color="gray.400"
      />
      <Icon
        icon="rsvp-checkmark"
        position="absolute"
        width="100%"
        height="100%"
        zIndex="2"
        color={isDisabled ? "gray.400" : color}
        opacity={isChecked ? 1 : 0}
        transition="opacity 0.2s"
      />
    </Box>
    <Box color={isDisabled ? "gray.400" : "inherit"}>
      <ShrinkWrap>{children}</ShrinkWrap>
    </Box>
  </Flex>
)

export default RSVPCheckbox
