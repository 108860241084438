import React, { ReactNode } from "react"
import {
  Flex, Box, Text, LayoutProps,
} from "@chakra-ui/react"
import Section from "./Section"
import Button from "../Buttons/Button"

type Props = {
  children?: any
  title?: string | ReactNode
  onEdit?: (() => void) | null
  columns?: boolean
  showBorder?: boolean
  titleWidth?: LayoutProps["width"]
}

const EditableSection = ({
  children, title, onEdit, columns, showBorder, titleWidth = "16.6%",
}: Props) => {
  const showBorderProps = showBorder ? {
    backgroundColor: "white", borderRadius: "lg", borderWidth: 1, padding: 6,
  } : {}

  return (
    <Section borderTopWidth={0} py={0}>
      <Box {...columns ? { display: "flex", justifyContent: "space-between" } : {}} {...showBorderProps}>
        {title && (
        <Text
          mb={4}
          {...!children && !columns ? { fontSize: "3xl", fontWeight: "light" } : {}}
          {...children && !columns ? { fontSize: "xl", fontWeight: "semibold" } : {}}
          {...columns ? {
            width: titleWidth, mr: 4, flexShrink: 0, leading: "tall",
          } : {}}
        >{title}
        </Text>
        )}
        <Flex justify="space-between" flexGrow={1}>
          <Box w="full" fontSize="xl">{children}</Box>
          {onEdit
          && (
          <Box ml={8}>
            <Button variant="outline" size="sm" onClick={onEdit}>Edit</Button>
          </Box>
          )}
        </Flex>
      </Box>
    </Section>
  )
}

export default EditableSection
