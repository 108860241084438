import React from "react"
import {
  Link, Image, Box, Stack,
} from "@chakra-ui/react"

type Props = {
  type: string
  filename: string
  src: string
}

const FilePreview = ({ type, filename, src }: Props) => (
  <Stack>
    {type.startsWith("image") && (
      <Box>
        <Image alt={filename} src={src} w="auto" maxH={250} />
      </Box>
    )}
    <Link isExternal href={src}>
      {filename}
    </Link>
  </Stack>
)

export default FilePreview
