import React from "react"
import { Website } from "sharedTypes"
import { Box, Text } from "@chakra-ui/react"
import * as Yup from "yup"
import { DEFAULT_BUTTON_COLOR } from "components/elements/ButtonPreview"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextField from "components/ReactHookForm/TextField"
import ColorSelect from "components/ReactHookForm/ColorSelect/ColorSelect"
import SwitchField from "components/ReactHookForm/SwitchField"

type Props = {
  website: Website;
  updateWebsite: (websiteAttributes: Partial<Website>) => Promise<any>;
  onClose: () => void;
}

const WebsiteButtonForm = ({ website, updateWebsite, onClose }: Props) => {
  const validationSchema = Yup.object().shape({
    actionEnabled: Yup.boolean(),
    actionText: Yup.string(),
    rsvpButtonColor: Yup.string().matches(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i),
  })

  const onSubmit = ({ actionEnabled, actionText, rsvpButtonColor }) => updateWebsite(
    { actionEnabled, actionText, rsvpButtonColor },
  ).then(() => {
    onClose()
  })

  return (
    <ModalForm
      title="Button Settings"
      validationSchema={validationSchema}
      initialValues={{
        actionEnabled: website.actionEnabled,
        actionText: website.actionText,
        rsvpButtonColor: website.rsvpButtonColor || DEFAULT_BUTTON_COLOR,
      }}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      {({ control, watch }) => {
        const actionEnabled = watch("actionEnabled")

        return (
          <>
            <SwitchField name="actionEnabled" control={control} label="Show Button" />
            <Box opacity={actionEnabled ? 1 : 0.5}>
              <Text mb={6}>
                This button adds ticket ordering to your website.<br />
                When enabled, guests can click it to see all available public tickets
                and to complete their registration.
              </Text>
              <TextField
                name="actionText"
                control={control}
                label="Button Text"
                disabled={!actionEnabled}
              />
              <ColorSelect
                name="rsvpButtonColor"
                control={control}
                label="Button Color"
                disabled={!actionEnabled}
              />
            </Box>
          </>
        )
      }}
    </ModalForm>
  )
}

export default WebsiteButtonForm
