import React, { useState } from "react"
import pluralize from "pluralize"
import { Flex, HStack, Text } from "@chakra-ui/react"
import Link from "components/elements/Link"
import { useKeyPress } from "ahooks"
import useGuestsStore from "services/useGuestsStore"
import { shallow } from "zustand/shallow"
import { EventCounts, EventGroup, Segment } from "sharedTypes"
import ActionButton from "./ActionButton"
import Action from "./Action"
import { selectedFilterCount } from "../ScrollView/Filters"

type Props = {
  eventGroups: EventGroup[]
  segments: Segment[]
  counts: EventCounts
}

const MultipleSelectActions = ({ eventGroups, segments, counts }: Props) => {
  const [action, setAction] = useState<string|null>(null)
  const [selectedGuestIds, selectAll, deselectAll,
    allSelected, groupId, segmentId] = useGuestsStore(
    (s) => [s.selectedGuestIds, s.selectAll, s.deselectAll,
      s.allSelected, s.groupId, s.segmentId],
    shallow,
  )

  let count
  const handleCancel = () => {
    deselectAll()
  }

  const handleSelectAll = () => {
    selectAll()
  }

  if (allSelected) {
    count = selectedFilterCount(eventGroups, counts, segments, segmentId, groupId)
  } else {
    count = selectedGuestIds.length
  }

  useKeyPress("esc", () => handleCancel())

  return (
    <>
      <Flex direction="column" justify="center" align="center" h="full">
        <Text fontSize="2xl">{count} {pluralize("guest", count)} selected</Text>
        <HStack fontSize="lg" textDecoration="underline" spacing={4}>
          {!allSelected && <Link onClick={handleSelectAll}>select all</Link>}
          <Link onClick={handleCancel}>cancel</Link>
        </HStack>
        <Flex mt={16}>
          <ActionButton
            icon="circle"
            text="Change Status"
            onClick={() => setAction("change-status")}
          />
          <ActionButton
            icon="tag"
            text="Tag Guest List"
            onClick={() => setAction("tag-guest-list")}
          />
          <ActionButton
            icon="email-only-large"
            text="Send Email"
            onClick={() => setAction("send-email")}
          />
          <ActionButton
            icon="smart-reminder"
            text="Send Smart Reminder"
            onClick={() => setAction("send-smart-reminder")}
          />
          <ActionButton
            icon="times"
            text="Remove From Event"
            onClick={() => setAction("remove-from-event")}
            color="red.700"
          />
        </Flex>
      </Flex>
      <Action
        action={action}
        onClose={() => setAction(null)}
        selectedCount={count}
      />
    </>
  )
}

export default MultipleSelectActions
