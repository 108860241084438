import { Text } from "@chakra-ui/react"
import React from "react"
import Button from "components/Buttons/Button"
import { ModalName, User } from "sharedTypes"
import Link from "components/elements/Link"
import AccountError from "components/layout/AccountError"
import useModal from "services/useModal"

type Props = {
  currentUser: User
}

const NoAccount = ({ currentUser }: Props) => {
  const showModal = useModal()

  return (
    <AccountError title="There are no accounts associated with your email address">
      <Text
        fontSize="lg"
        fontWeight="semibold"
        mt={6}
      >{currentUser.firstName} {currentUser.lastName}
      </Text>
      <Text fontSize="lg" mb={6}>{currentUser.email}</Text>
      <Text mb={6} fontSize="lg">
        This can happen if an account owner stopped sharing an event with you.
        Contact the person who originally invited you to Diobox.
      </Text>
      <Text mb={6} fontSize="lg">
        Diobox is an all-in-one platform which helps you manage,
        simplify, and scale all your event programs.
      </Text>
      <Button
        onClick={() => showModal(ModalName.SwitchAccount, { newAccount: true })}
        size="md"
      >
        Create Your Own Account
      </Button>
      <Text fontSize="lg" mt={8}>
        Your account comes with features for guest lists, seating, email invites, and
        registration. It also includes an iOS app for check-in, fast QR Code scanning and
        badge printing. Learn more: <Link href="https://d.io/">d.io</Link>.
      </Text>
    </AccountError>
  )
}

export default NoAccount
