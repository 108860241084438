import React from "react"
import NoContent from "components/elements/NoContent"

type Props = {
  onClickAdd: () => void
}

const NoAgenda = ({ onClickAdd }: Props) => {
  const descriptionItems = [
    "Build a custom event program guide",
    "Include agenda start and end times",
    "Create multiple sessions (or days) by grouping event programs together",
    "Assign multiple speakers to each program",
  ]

  const callsToActions = [
    {
      icon: "plus-big",
      text: "Add First Program",
      action: onClickAdd,
    },
  ]

  return (
    <NoContent
      title="Event Agenda"
      descriptionItems={descriptionItems}
      icon="agenda"
      callsToActions={callsToActions}
    />
  )
}

export default NoAgenda
