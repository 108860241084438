import React from "react"
import { DioboxEvent } from "sharedTypes"
import { Box, Heading, Text } from "@chakra-ui/react"
import { Control, FieldValues } from "react-hook-form"
import UpgradeAlert from "components/elements/UpgradeAlert"
import useCurrentAccount from "services/useCurrentAccount"
import { useCurrentEvent } from "queries/events"
import AutoSubmitForm from "components/ReactHookForm/AutoSubmitForm"
import { Boolean } from "utilities/enums"
import BuilderLayout from "components/Builder/BuilderLayout"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import { CCEmailsFormFields } from "pages/EventShow/Settings/EmailNotifications"
import RecipientCheckboxes from "components/RecipientCheckboxes"

type Props<T extends FieldValues> = {
  event: DioboxEvent
  control: Control<T, object>
  updateEvent: (params: {}) => Promise<void>
}

const Recipients = <T extends FieldValues, >({
  event, control, updateEvent,
}: Props<T>) => (
  <BuilderLayout sidePanel={<SidePanel updateEvent={updateEvent} />}>
    <Box w={550} m="auto" p={8}>
      <Text fontSize="lg" fontWeight="bold" mb={8}>Lists</Text>
      <RecipientCheckboxes
        event={event}
        control={control}
      />
    </Box>
  </BuilderLayout>
  )

type SidePanelProps = {
  updateEvent: (params: {}) => Promise<void>;
}

const SidePanel = ({ updateEvent }: SidePanelProps) => {
  const { subscription } = useCurrentAccount()
  const isCorporate = subscription?.type === "Corporate"
  const { data: { ccEmailsEnabled } } = useCurrentEvent()

  return (
    <>
      <SidePanelHeader>Sending Options</SidePanelHeader>
      <Box py={10} px={6}>
        <Heading fontSize="lg" fontWeight="normal" mb={3}>CC Email Addresses on the Guest Profile</Heading>
        <Text mb={4}>When a guest has more than 1 email address:</Text>
        <AutoSubmitForm
          defaultValues={{ ccEmailsEnabled: ccEmailsEnabled ? Boolean.True : Boolean.False }}
          onSubmit={
            ({
              ccEmailsEnabled: enabled,
            }) => updateEvent({ ccEmailsEnabled: enabled === Boolean.True })
          }
        >
          {({ control }) => (
            <>
              <CCEmailsFormFields control={control} isCorporate={isCorporate} />
              {!isCorporate && <UpgradeAlert text="This Feature requires a Corporate Subscription." />}
            </>
          )}
        </AutoSubmitForm>
      </Box>
    </>
  )
}

export default Recipients
