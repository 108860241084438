import React from "react"
import Icon from "components/elements/Icon"
import { Flex } from "@chakra-ui/react"

type Props = {
  text: string;
  color?: string;
}

const InvitationStatus = ({ text, color }: Props) => {
  if (!text) {
    return null
  }

  return (
    <Flex align="center" color={color}>
      <Icon icon="dot" size={3} mr={1} /> {text}
    </Flex>
  )
}

export default InvitationStatus
