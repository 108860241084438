import React from "react"
import EditorSection from "components/Builder/components/EditorSection"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import ColorSelect from "components/ReactHookForm/ColorSelect/ColorSelect"
import NumberField from "components/ReactHookForm/NumberField"
import { Control } from "react-hook-form"

type Props = {
  control: Control
}

const SpacerFields = ({ control }: Props) => (
  <>
    <SidePanelHeader>Spacer Block</SidePanelHeader>
    <EditorSection>
      <NumberField label="Size" name="size" control={control} min={1} />
    </EditorSection>
    <EditorSection>
      <ColorSelect label="Block Color" name="blockColor" control={control} colorScheme="light" />
    </EditorSection>
  </>
)

export default SpacerFields
