import React from "react"
import { Box, Stack, Text } from "@chakra-ui/react"
import { DioboxEvent, Form } from "sharedTypes"
import pluralize from "pluralize"
import FormIntegrationListItem from "./FormIntegrationListItem"

type Props = {
  event: DioboxEvent
  forms: Form[]
  deletedFormsCount: number
  showFormSubmissionsLink?: boolean
}

const FormIntegrations = ({
  event: { currency }, event, forms, deletedFormsCount, showFormSubmissionsLink,
}:Props) => {
  const formCount = forms.length

  if (deletedFormsCount > 0) {
    return (
      <Text color="red.400" fontWeight="semibold">The form connected to this email is no longer available or has been deleted.
        Connect the submit button in this email to a new form before sending it out.
      </Text>
    )
  }

  return (
    <>
      <Box mb={6}>
        <Text fontSize="lg">This email connects to {formCount} {pluralize("forms", formCount)}:</Text>
      </Box>
      <Stack spacing={7}>
        {forms.map((form) => (
          <FormIntegrationListItem
            key={form.externalId}
            form={form}
            event={event}
            currency={currency}
            showFormSubmissionsLink={showFormSubmissionsLink}
          />
        ))}
      </Stack>
    </>
  )
}

export default FormIntegrations
