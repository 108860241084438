import useCurrentAccount from "services/useCurrentAccount"
import { SubscriptionType } from "sharedTypes"

const useCurrentPlanType = () => {
  const { subscription } = useCurrentAccount()

  const isBasic = subscription?.type === SubscriptionType.Basic
  const isStandard = subscription?.type === SubscriptionType.Standard
  const isProfessional = subscription?.type === SubscriptionType.Professional
  const isCorporate = subscription?.type === SubscriptionType.Corporate

  return {
    isBasic, isStandard, isProfessional, isCorporate,
  }
}

export default useCurrentPlanType
