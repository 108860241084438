import React from "react"
import Button from "components/Buttons/Button"
import PageHeader from "components/layout/PageHeader"
import CancelButton from "components/Buttons/CancelButton"

type Props = {
  sortingEnabled: boolean;
  onEnableSorting: () => void;
  onCancelSorting: () => void;
  onSaveSorting: () => void;
  onAddSpeaker: () => void;
}

const Header = ({
  sortingEnabled,
  onEnableSorting,
  onCancelSorting,
  onSaveSorting,
  onAddSpeaker,
}: Props) => {
  const actions = sortingEnabled ? [
    <CancelButton size="sm" onClick={onCancelSorting} />,
    <Button size="sm" onClick={onSaveSorting}>Save</Button>,
  ] : [
    <Button variant="outline" size="sm" onClick={onEnableSorting}>Edit Order</Button>,
    <Button leftIcon="plus" size="sm" onClick={onAddSpeaker}>Speaker</Button>,
  ]

  return (
    <PageHeader actionButtons={actions} />
  )
}

export default Header
