import React from "react"
import { FormType } from "sharedTypes"
import { eventSubmissionsPath } from "utilities/routes"
import { Link } from "react-router-dom"
import SubmissionsLabel from "./SubmissionsLabel"

type Props = {
  submissionLineItemsCount: number;
  type: FormType
  eventId: string
  formId: string
  quantity?: number;
}

const Submissions = ({
  submissionLineItemsCount, type, eventId, formId, quantity,
}: Props) => {
  if (submissionLineItemsCount && submissionLineItemsCount > 0) {
    return (
      <Link style={{ display: "flex" }} to={{ pathname: eventSubmissionsPath(eventId), search: `?formId=${formId}` }}>
        <SubmissionsLabel
          type={type}
          quantity={quantity}
          submissionLineItemsCount={submissionLineItemsCount}
        />
      </Link>
    )
  }

  return (
    <SubmissionsLabel
      type={type}
      quantity={quantity}
      submissionLineItemsCount={submissionLineItemsCount}
    />
  )
}

export default Submissions
