import React from "react"
import {
  Route, Switch, useRouteMatch,
} from "react-router-dom"
import { DioboxEvent, ModalName, User } from "sharedTypes"
import { sortAgendaRows, sortSpeakers } from "utilities/objects"
import { Box, Flex } from "@chakra-ui/react"
import SecondaryNav from "components/layout/SecondaryNav"
import CenteredContent from "components/layout/CenteredContent"
import UpgradeAlert from "components/elements/UpgradeAlert"
import EventPage, { EventPageHeader } from "components/layout/EventPage"
import pluralize from "pluralize"
import HorizontalStat from "components/elements/HorizontalStat"
import { asNumber } from "utilities/strings"
import useModal from "services/useModal"
import ConfirmEmailAlert from "components/ConfirmEmailAlert"
import AlertBoxWrapper from "components/elements/AlertBox/AlertBoxWrapper"
import Setup from "./Setup"
import HeroSection from "./HeroSection"
import Speakers from "./Speakers"
import Agenda from "./Agenda"
import NoWebsite from "./NoWebsite"
import PageContent from "./PageContent/PageContent"
import Map from "./Map/Map"
import PublishedUpdater from "./PublishedUpdater/PublishedUpdater"

type Props = {
  event: DioboxEvent
  updateWebsite: (params: {}) => Promise<void>
  currentUser: User
}

const Website = ({ event, updateWebsite, currentUser }: Props) => {
  const { path } = useRouteMatch()
  const showModal = useModal()

  const {
    website,
    website: { viewsCount, speakers, agendaRows },
    id,
  } = event

  if (!website.urlPath) {
    return (
      <>
        {!currentUser.confirmed && (
          <AlertBoxWrapper absolute>
            <ConfirmEmailAlert email={currentUser.email} />
          </AlertBoxWrapper>
        )}
        <NoWebsite enableWebsite={() => showModal(ModalName.WebPageURL)} />
      </>
    )
  }

  const requiresUpgrade = (
    <Box my={8}>
      <UpgradeAlert text="This feature requires a Basic Subscription." />
    </Box>
  )

  return (
    <EventPage
      header={(
        <EventPageHeader
          icon="website"
          title="Web Page"
          items={(
            <>
              <HorizontalStat
                label={pluralize("View", viewsCount)}
                value={asNumber(viewsCount)}
              />
              <PublishedUpdater
                website={website}
                updateWebsite={updateWebsite}
              />
            </>
          )}
        />
      )}
      body={(
        <Flex
          flexGrow={1}
          position="relative"
          h="100%"
        >
          <SecondaryNav
            routeMatch="/events/:id/website"
            items={[
              {
                icon: "guest-search-settings", text: "Setup", color: "blue.400",
              },
              {
                icon: "site-homepage", text: "Hero Section", color: "blue.400",
              },
              {
                icon: "page-outline", text: "Page Content", color: "blue.400",
              },
              {
                icon: "speaker-outline", text: "Speakers", color: "blue.400",
              },
              {
                icon: "agenda", text: "Agenda", color: "blue.400",
              },
              {
                icon: "map-outline", text: "Map", color: "blue.400",
              },
            ]}
            w="sm"
          />
          <CenteredContent>
            <Switch>
              <Route path={`${path}/hero-section`}>
                <HeroSection
                  website={website}
                  updateWebsite={updateWebsite}
                />
              </Route>
              <Route path={`${path}/page-content`}>
                <PageContent
                  website={website}
                  updateWebsite={updateWebsite}
                />
              </Route>
              <Route path={`${path}/map`}>
                <Map
                  website={website}
                  updateWebsite={updateWebsite}
                  eventId={id}
                />
              </Route>
              <Route path={`${path}/speakers`}>
                {event.agendaEnabled ? (
                  <Speakers
                    eventId={event.id}
                    speakers={sortSpeakers(speakers)}
                  />
                ) : requiresUpgrade}
              </Route>
              <Route path={`${path}/agenda`}>
                {event.agendaEnabled ? (
                  <Agenda
                    rows={sortAgendaRows(agendaRows)}
                  />
                ) : requiresUpgrade}
              </Route>
              <Route path={`${path}`}>
                <Setup
                  website={website}
                  updateWebsite={updateWebsite}
                  event={event}
                />
              </Route>
            </Switch>
          </CenteredContent>
        </Flex>
      )}
    />
  )
}

export default Website
