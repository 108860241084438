import React, { ReactNode } from "react"
import {
  Flex, Box, Heading, TypographyProps,
} from "@chakra-ui/react"

interface Props {
  children?: string;
  actionButtons?: ReactNode[];
  headingSize?: TypographyProps["fontSize"];
}

const SectionHeader = ({ children, actionButtons, headingSize = "2xl" }: Props) => (
  <Flex justify="space-between" align="center">
    <Heading fontSize={headingSize}>{children}</Heading>
    {actionButtons
    && <Box ml={8}>{actionButtons.map((button, index) => <span key={index}>{button}</span>)}</Box>}
  </Flex>
)

export default SectionHeader
