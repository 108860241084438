import { Website } from "sharedTypes"

const useWebsiteUrl = ({ urlPath, published, previewToken }: Website) => {
  const baseUrl = import.meta.env.VITE_WEBSITE_HOST

  let text = `${baseUrl}/${urlPath}`
  if (!published) {
    text += `/${previewToken}`
  }

  return text
}

export default useWebsiteUrl
