import React from "react"
import { Flex, Image as ChakraImage } from "@chakra-ui/react"
import { ImageBlock } from "sharedTypes/builder"
import Icon from "components/elements/Icon"

type Props = {
  block: ImageBlock
  hasPadding?: boolean
}

const Image = ({ block: { data }, hasPadding = false }: Props) => {
  if (!data.source) {
    return (
      <Placeholder />
    )
  }

  return (
    <Flex
      justify={data.align}
      bgColor={data.blockColor}
      pt={`${data.paddingTop * 4}px`}
      pb={`${data.paddingBottom * 4}px`}
      px={hasPadding ? 6 : 0}
    >
      <ChakraImage
        src={data.source}
        alt={data.alt}
        fallback={<Placeholder />}
        w={`${data.scale}%`}
      />
    </Flex>
  )
}

const Placeholder = () => (
  <Flex
    justifyContent="center"
    alignItems="center"
    h={36}
    backgroundColor="gray.100"
    borderTopWidth={1}
    borderBottomWidth={1}
  >
    <Icon icon="email-block-image" size={12} color="gray.400" />
  </Flex>
)

export default Image
