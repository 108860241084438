import React, { ReactElement } from "react"
import Confirm from "components/dialogs/Confirm/Confirm"
import * as api from "api/guests"
import { useQueryClient } from "react-query"
import { Guest } from "sharedTypes"
import { updateGuest } from "context/actions"

type Props = {
  guest: Guest
  children: ReactElement
}

const Subscribe = ({ guest, children }: Props) => {
  const queryClient = useQueryClient()

  const { personInfo: { firstName, unsubscribed } } = guest

  const setSubscription = (subscribed: boolean = true) => {
    api.putGuest(guest.eventId, guest.id, {
      personInfo: {
        unsubscribed: !subscribed,
      },
    }).then(({ data }) => {
      updateGuest(queryClient, data)
    })
  }

  return (
    <Confirm
      confirmationTitle={`${unsubscribed ? "Resubscribe" : "Unsubscribe"} ${firstName}?`}
      confirmationText={`This person will ${unsubscribed ? "receive" : "stop receiving"} event emails and invitations.`}
      confirmButtonLabel={unsubscribed ? "Resubscribe" : "Unsubscribe"}
      onClickConfirm={() => setSubscription(unsubscribed)}
    >
      {children}
    </Confirm>
  )
}

export default Subscribe
