import React from "react"
import { postInvitationExport } from "api/emails"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import { Text } from "@chakra-ui/react"

const ExportInvitationList = ({
  eventId, emailId, emailAddress, onHide,
}) => {
  const exportCSV = () => postInvitationExport(eventId, emailId).then(() => onHide())

  return (
    <ModalForm
      onClose={onHide}
      title="Export Email Report"
      onSubmit={exportCSV}
      submitText="Export"
    >
      <Text>
        A CSV file containing the recipients of this email and their engagement data will be
        generated and sent to: <Text as="span" fontWeight="semibold">{emailAddress}</Text>
      </Text>
    </ModalForm>
  )
}

export default ExportInvitationList
