import React from "react"
import { DioboxEvent, Website } from "sharedTypes"
import { Stack } from "@chakra-ui/react"
import { successToast } from "utilities/toasts"
import { DEFAULT_BUTTON_COLOR } from "components/elements/ButtonPreview"
import URL from "./URL"
import Button from "./Button"
import ActionV2 from "./ActionV2"

type Props = {
  website: Website
  updateWebsite: (params: {}) => Promise<void>
  event: DioboxEvent
}

const Setup = ({ website, updateWebsite, event }: Props) => {
  const {
    actionBehavior,
    rsvpButtonColor,
    actionText,
  } = website

  const updateWebsiteWithToastFeedback = (params) => updateWebsite(params)
    .then(() => successToast({ title: "Website updated" }))

  return (
    <Stack spacing={10}>
      <URL website={website} />
      <ActionV2
        behavior={actionBehavior}
        updateWebsite={updateWebsiteWithToastFeedback}
        event={event}
      />
      <Button
        color={rsvpButtonColor || DEFAULT_BUTTON_COLOR}
        text={actionText}
        updateWebsite={updateWebsiteWithToastFeedback}
      />
    </Stack>
  )
}

export default Setup
