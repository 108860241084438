import React, { ReactNode } from "react"
import {
  Box, Divider, Flex, Text,
} from "@chakra-ui/react"
import SwitchField from "components/ReactHookForm/SwitchField"
import { Control } from "react-hook-form"

type Props = {
  title: string,
  children: ReactNode,
  control: Control<any, any>
  switchName?: string
  showSeparator?: boolean
  disabled?: boolean
}

const ConfigBlock = ({
  title, children, control, switchName, showSeparator = false, disabled,
}: Props) => (
  <>
    <Box px={6}>
      <Flex justifyContent="space-between" pt={4} minH={12}>
        <Text fontWeight="semibold" fontSize="lg">{title}</Text>
        {switchName && <Box><SwitchField name={switchName} disabled={disabled} control={control} size="lg" /></Box>}
      </Flex>
      <Box>{children}</Box>
    </Box>
    {showSeparator && <Divider />}
  </>
)
export default ConfigBlock
