import React, { useState } from "react"
import EditableSection from "components/layout/EditableSection"
import Button from "components/Buttons/Button"
import { Box, HStack, Text } from "@chakra-ui/react"
import { asDateWithTZ } from "utilities/strings"
import { useCurrentEvent } from "queries/events"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import SwitchField from "components/ReactHookForm/SwitchField"
import TextField from "components/ReactHookForm/TextField"

type Props = {
  dateEnabled: boolean;
  dateHeader: string;
  dateLine1: string;
  dateLine2: string;
  updateWebsite: (params: {}) => Promise<void>;
}

const Date = ({
  dateEnabled, dateHeader, dateLine1, dateLine2, updateWebsite,
}: Props) => {
  const [edit, setEdit] = useState(false)

  return (
    <EditableSection
      title="Date Block"
      onEdit={() => { setEdit(true) }}
      columns
    >
      <Box color={!dateEnabled ? "gray.200" : ""}>
        <Text fontSize="2xl" fontWeight="semibold">{ dateHeader }</Text>
        <Text>{ dateLine1 }</Text>
        <Text>{ dateLine2 }</Text>
      </Box>
      {edit && (
      <Edit {...{
        setEdit,
        dateEnabled,
        dateHeader,
        dateLine1,
        dateLine2,
        updateWebsite,
      }}
      />
      )}
    </EditableSection>
  )
}

const Edit = ({
  setEdit, dateEnabled, dateHeader, dateLine1, dateLine2, updateWebsite,
}) => {
  const { data: { startDate, timezone } } = useCurrentEvent()

  const onSubmit = (values) => updateWebsite(values).then(() => {
    setEdit(false)
  })

  return (
    <ModalForm
      initialValues={{
        dateEnabled, dateHeader, dateLine1, dateLine2,
      }}
      onSubmit={onSubmit}
      title="Date Block"
      onClose={() => {
        setEdit(false)
      }}
    >
      {({ control, watch, setValue }) => {
        const enabled = watch("dateEnabled")

        const setDefaultValues = () => {
          setValue("dateHeader", asDateWithTZ(startDate, timezone, "EEEE, MMMM d, yyyy"))
          setValue("dateLine1", asDateWithTZ(startDate, timezone, "h:mm a"))
          setValue("dateLine2", "")
        }

        return (
          <>
            <SwitchField
              name="dateEnabled"
              control={control}
              label="Show Section"
            />
            <TextField
              name="dateHeader"
              control={control}
              label="Header"
              disabled={!enabled}
            />
            <TextField
              name="dateLine1"
              control={control}
              label="Line 1"
              disabled={!enabled}
            />
            <TextField
              name="dateLine2"
              control={control}
              label="Line 2"
              disabled={!enabled}
            />
            <HStack mt={6}>
              <Box w={1 / 4} />
              <Box w="100%">
                <Button
                  variant="outline"
                  onClick={setDefaultValues}
                  isDisabled={!enabled}
                >
                  Default Event Values
                </Button>
              </Box>
            </HStack>
          </>
        )
      }}
    </ModalForm>

  )
}

export default Date
