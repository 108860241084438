import React from "react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import * as Yup from "yup"
import {
  DioboxEvent, FormType, TextMessage, TextMessageType,
} from "sharedTypes"
import { useForms } from "queries/forms"
import Spinner from "components/elements/Spinner"
import TemplateOption from "components/MessageTemplates/TemplateOption"
import { postTextMessage } from "api/texts"
import { refetchTextMessages } from "context/actions"
import { successToast } from "utilities/toasts"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import { eventTextMessagePath } from "utilities/routes"
import { asDate, replaceMergeTagsInText } from "utilities/strings"
import { DateFormat } from "utilities/constants"

const templates = [
  {
    textMessageType: TextMessageType.Invitation,
    features: ["Send personalized invitations with links to private RSVP pages for recipients to confirm their attendance."],
    formType: FormType.RSVP,
    icon: "form-rsvp",
    id: "0",
    name: "Invite with RSVP Forms",
    templateBody: "Hi {{FULL_NAME}},\nYou are cordially invited to the {{EVENT}} on {{DATE}}. Please RSVP to confirm your attendance as space is limited:\n{{FORM_}}",
  },
  {
    textMessageType: TextMessageType.Invitation,
    features: ["Send invitations with links to blank registration forms allowing invitees and others to register for the event for free."],
    formType: FormType.Registration,
    icon: "form-registration",
    id: "1",
    name: "Invite with Registration Forms",
    templateBody: "Hi {{FULL_NAME}},\nPlease register for the {{EVENT}} on {{DATE}}. Feel free to share with others who may be interested:\n{{FORM_}}",
  },
  {
    textMessageType: TextMessageType.Invitation,
    features: ["Send invitations containing links to ticketing forms for recipients to securely purchase event tickets."],
    formType: FormType.Ticket,
    icon: "form-ticket",
    id: "2",
    name: "Invite with Ticket Payment Forms",
    templateBody: "Hi {{FULL_NAME}},\nSecure your spot for the {{EVENT}} on {{DATE}} by getting your ticket:\n{{FORM_}}\nLooking forward to your participation.",
  },
  {
    textMessageType: TextMessageType.Confirmation,
    features: ["Send confirmation messages with links directing recipients to their confirmation pages and QR codes. Ensure the guests' statuses are marked as “Confirmed” before sending."],
    icon: "form-terms",
    id: "3",
    name: "Send Confirmation Links",
    templateBody: "Hi {{FULL_NAME}},\nYour attendance for the {{EVENT}} on {{DATE}} has been confirmed. Here's your confirmation and QR code:\n{{CONFIRMATION_URL}}",
  },
  {
    textMessageType: TextMessageType.General,
    features: ["Send general updates to recipients to keep them informed about changes or important information related to the event."],
    icon: "text-sms",
    id: "4",
    name: "Send General Updates",
    templateBody: "Save the date for the {{EVENT}} on {{DATE}}! More details to follow. Looking forward to seeing you soon.",
  },
]

type Props = {
  event: DioboxEvent
  onClose: () => void
}

const NewTextMessage = ({ event, onClose }: Props) => {
  const {
    id: eventId, counts: { texts: textCount }, title, startDate,
  } = event
  const { isLoading, data: forms } = useForms(eventId)
  const queryClient = useQueryClient()
  const history = useHistory()

  const validationSchema = Yup.object().shape({
    templateId: Yup.string(),
    formType: Yup.string().nullable(),
    formId: Yup.string().when(
      "formType", {
        is: undefined,
        then: (schema) => schema.nullable(),
        otherwise: (schema) => schema.required(),
      },
    ),
  })

  const handleSubmit = ({ templateBody, formId, textMessageType }) => {
    const textMessageData = {
      body: replaceMergeTagsInText(templateBody, [
        { mergeTag: "{{FORM_}}", value: `{{FORM_${formId}}}` },
        { mergeTag: "{{EVENT}}", value: title },
        { mergeTag: "{{DATE}}", value: asDate(startDate, DateFormat.DAY_OF_WEEK_MONTH_DAY)! },
      ]),
      name: `${textMessageType} ${textCount + 1}`,
    } as TextMessage

    postTextMessage(eventId, textMessageData)
      .then(async ({ data: { externalId } }) => {
        await refetchTextMessages(queryClient, eventId)
        history.push(eventTextMessagePath(eventId, externalId))
        successToast({ title: "New text message has been created" })
        onClose()
      })
  }

  return (
    <ModalForm
      title="New Text Message"
      h="80%"
      onClose={onClose}
      onSubmit={handleSubmit}
      submitText="Create"
      initialValues={{ templateId: null, formId: null }}
      validationSchema={validationSchema}
    >
      {({ watch, control, reset }) => {
        if (isLoading) {
          return <Spinner heightFull />
        }

        if (!forms) {
          return null
        }

        const templateId = watch("templateId")

        const handleSelectTemplate = (
          selectedTemplateId: string,
          textMessageType: TextMessageType,
          templateBody: string,
          formType?: FormType,
        ) => {
          const formIdToSelect = formType ? forms.data.filter((form) => form.type === formType).map((form) => form.externalId)[0] : ""
          reset({
            templateId: selectedTemplateId,
            formId: formIdToSelect,
            textMessageType,
            templateBody,
            formType,
          })
        }

        return templates.map(({
          id, features, icon, name: templateName, formType, textMessageType, templateBody,
        }) => (
          <TemplateOption
            key={id}
            onClick={() => handleSelectTemplate(id, textMessageType, templateBody, formType)}
            selected={templateId === id}
            icon={icon}
            description={templateName}
            features={features}
            control={control}
            formType={formType}
            event={event}
            forms={forms.data}
          />
        ))
      }}
    </ModalForm>
  )
}

export default NewTextMessage
