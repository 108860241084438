import React, { ReactNode } from "react"
import {
  Text,
  Modal as ChakraModal,
  ModalContent, ModalHeader, ModalBody, ModalFooter, ModalOverlay, ModalCloseButton, Box, HStack,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"

type Props = {
  actions?: ReactNode[];
  children: ReactNode;
  closeOnEsc?: boolean
  footer?: ReactNode
  h?: string;
  header?: ReactNode
  hide: () => void;
  icon?: string;
  noPadding?: boolean;
  show: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "full";
  title?: string | ReactNode;
  onSubmit?: () => Promise<any>;
}

const Modal = ({
  actions = [],
  children,
  closeOnEsc = true,
  footer,
  h,
  header,
  hide,
  icon,
  noPadding = false,
  show,
  size = "2xl",
  title,
  onSubmit,
}: Props) => {
  let content = (
    <>
      {!header && (
      <ModalHeader
        role="heading"
        borderBottomWidth={1}
        bgColor="gray.50"
        borderTopRadius="0.375rem"
        display="flex"
        justifyContent="space-between"
      >
        <HStack>
          {icon && <Icon icon={icon} size={8} />}
          <Text size="lg">{title}</Text>
        </HStack>
        <ModalCloseButton position="initial" _focus={{ boxShadow: "none" }} />
      </ModalHeader>
      )}
      {header}
      <ModalBody p={noPadding ? 0 : 8}>
        {children}
      </ModalBody>
      { actions && actions.length > 0 && !footer && (
      <ModalFooter borderTopWidth="1px">
        {actions.map(
          (action, index) => (
            <Box
              key={index}
              mr={actions.length === 3 && index === 0 ? "auto" : ""}
              ml={actions.length !== 3 || index !== 0 ? 3 : ""}
            >{action}
            </Box>
          ),
        )}
      </ModalFooter>
      )}
      {footer}
    </>
  )

  if (onSubmit) {
    content = <Box as="form" onSubmit={onSubmit} display="contents">{content}</Box>
  }

  return (
    <ChakraModal
      onClose={hide}
      isOpen={show}
      isCentered
      size={size}
      scrollBehavior="inside"
      trapFocus={import.meta.env.NODE_ENV !== "test"}
      closeOnEsc={closeOnEsc}
      closeOnOverlayClick={false}
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent
        role="dialog"
        maxHeight={size === "full" ? "initial" : "calc(100vh - 7.5rem)"}
        h={size === "full" ? "100vh" : h}
      >
        {content}
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal
