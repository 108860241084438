import {
  Avatar, Flex, Td, Tr,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import React from "react"
import { EmailReportRecipient } from "sharedTypes"
import { getColorByInvitationStatus } from "utilities/invitations"
import { asDate } from "utilities/strings"

type Props = {
  recipient: EmailReportRecipient
}

const EmailReportRow = ({
  recipient: {
    id,
    guest: { firstName, lastName },
    data: {
      sentTo, opened, softBounced, hardBounced, bounced,
    },
    transactionDate,
    clicked,
  },
}: Props) => {
  const guestName = `${firstName} ${lastName}`
  const color = getColorByInvitationStatus({
    clicked, opened, softBounced, hardBounced, bounced,
  })

  return (
    <Tr key={id}>
      <Td><Flex alignItems="center" gap={2}><Avatar size="sm" bg={color} name={guestName} />{guestName}</Flex></Td>
      <Td>{sentTo}</Td>
      <Td>{asDate(transactionDate)}</Td>
      <Td>{opened || 0}</Td>
      <Td>{clicked}</Td>
      <Td>{softBounced && <Icon icon="checkmark" />}</Td>
      <Td>{hardBounced && <Icon icon="checkmark" />}</Td>
      <Td>{bounced && <Icon icon="checkmark" />}</Td>
    </Tr>
  )
}

export default EmailReportRow
