import React from "react"
import { Flex, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import Link from "components/elements/Link"

type Props = {
  url: string
  title: string
}

const HelpCenterLink = ({ url, title }: Props) => (
  <Flex p={6} justifyContent="center">
    <Link isExternal href={url}>
      <Flex alignItems="center" gap={2}>
        <Icon size={4.5} icon="link" /><Text>{title}</Text>
      </Flex>
    </Link>
  </Flex>
)

export default HelpCenterLink
