import {
  Box, Divider, Flex, Stack, Text,
} from "@chakra-ui/react"
import React, { ReactNode } from "react"

const RoundedList = ({ children }) => (
  <Stack direction="column" borderRadius="xl" bgColor="white" borderWidth={1} divider={<Divider />} py={3}>
    { children }
  </Stack>
)

interface RoundedListItemProps {
  text1: string | ReactNode
  text2?: string | ReactNode
  action?: ReactNode
}

export const RoundedListItem = ({ text1, text2, action }: RoundedListItemProps) => (
  <Flex px={6} py={3} justify="space-between" align="center">
    <Box>
      <Text fontSize="lg" fontWeight="bold" mb={1}>{text1}</Text>
      <Text>{text2}</Text>
    </Box>
    {action}
  </Flex>
)

export default RoundedList
