import React, { ReactNode } from "react"
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react"
import ErrorView from "components/ErrorView"

type Props = {
  children: ReactNode
}

const ErrorBoundary = ({ children }: Props) => (
  <SentryErrorBoundary fallback={<ErrorView />}>{children}</SentryErrorBoundary>
)

export default ErrorBoundary
