import React from "react"
import { Box, Stack, Text } from "@chakra-ui/react"
import LabelRedefinition from "modals/FormForm/LabelRedefinition"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import { DioboxEvent, Translations } from "sharedTypes"
import TitleWithSubtitle from "../TitleWithSubtitle"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
}

const FormButtonsSection = ({ event, updateEvent, defaultTranslations }: Props) => {
  const onSubmit = (values) => updateEvent(values)

  return (
    <Box>
      <TitleWithSubtitle
        title="Form Buttons & Labels"
        subtitle="These buttons and labels appear across various sections of the registration forms and the Guest Portal."
        mb={5}
      />
      <ContainedForm values={event} onSubmit={onSubmit} name="Form Buttons">
        {({ control }) => (
          <Stack spacing={12} maxWidth={400}>
            <Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.continue}
                name="translations.continue"
                control={control}
              />
              <Text>
                This button appears when multiple forms or tickets are available
                for the guest to complete.
              </Text>
            </Box>
            <Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.save}
                name="translations.save"
                control={control}
              />
              <Text>
                This button appears throughout the Guest Portal wherever the guest can save
                a form or their profile.
              </Text>
            </Box>
            <Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.edit}
                name="translations.edit"
                control={control}
              />
              <Text>
                This option appears if guests can edit their registration pages.
              </Text>
            </Box>
            <Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.submitted}
                name="translations.submitted"
                control={control}
              />
              <Text>
                The label showing the original form submission date.
              </Text>
            </Box>
            <Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.edited}
                name="translations.edited"
                control={control}
              />
              <Text>
                If a guest updates their registartion, this label indicates
                the last time it was edited.
              </Text>
            </Box>
          </Stack>
        )}
      </ContainedForm>
    </Box>
  )
}

export default FormButtonsSection
