import React from "react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import { Box, Text } from "@chakra-ui/react"
import * as Yup from "yup"
import EditModalSegment from "pages/EventShow/GuestPortal/Terminology/FormSpecificMessagingSection/EditModalSegment"
import TranslationField from "pages/EventShow/GuestPortal/Terminology/TranslationField"
import { FormsTerminologyModalProps } from "sharedTypes"

const TicketFormsModal = ({
  title, event, onClose, onSubmit, defaultTranslations,
}: FormsTerminologyModalProps) => {
  const TicketFormsModalSchema = Yup.object().shape({})

  return (
    <ModalForm
      title={`Messaging: ${title}`}
      onClose={onClose}
      initialValues={event}
      validationSchema={TicketFormsModalSchema}
      onSubmit={onSubmit}
    >
      {({ control }) => (
        <>
          <EditModalSegment title="Credit Card Fields" first>
            <Box mb={6}>
              <Text>The credit card fields are automatically localized by Stripe for users based
                on their location and regional settings.
              </Text>
            </Box>
          </EditModalSegment>
          <EditModalSegment title="Form Button">
            <Box mb={6}>
              <Text>The button will include the Purchase label followed by amount and currency.
                For example: Pay $150.00.
              </Text>
            </Box>
            <TranslationField
              defaultTranslation={defaultTranslations.ticketSubmitButton}
              fieldName="translations.ticketSubmitButton"
              editable
              control={control}
            />
          </EditModalSegment>
          <EditModalSegment title="Payment Header">
            <TranslationField
              defaultTranslation={defaultTranslations.payment}
              fieldName="translations.payment"
              editable
              control={control}
            />
          </EditModalSegment>
          <EditModalSegment title="Form Messages" last>
            <TranslationField
              label="Success Message"
              defaultTranslation={defaultTranslations.ticketSuccessMessage}
              fieldName="translations.ticketSuccessMessage"
              editable
              control={control}
            />
            <TranslationField
              label="Form Edited Message"
              defaultTranslation={defaultTranslations.ticketEditMessage}
              fieldName="translations.ticketEditMessage"
              editable
              control={control}
            />
            <TranslationField
              label="Closing Message"
              defaultTranslation={defaultTranslations.ticketClosingMessage}
              fieldName="translations.ticketClosingMessage"
              editable
              control={control}
            />
          </EditModalSegment>
        </>
      )}
    </ModalForm>
  )
}

export default TicketFormsModal
