import React from "react"
import { Transaction } from "sharedTypes"
import TextArea from "components/ReactHookForm/TextArea"
import DatePickerField from "components/ReactHookForm/DatePickerField"
import ActivityForm from "../Form"

type Props = {
  activity?: Transaction;
}

const Note = ({ activity }: Props) => {
  let initialValues

  if (activity) {
    initialValues = {
      transactionDate: activity.transactionDate,
      details: activity.details,
    }
  } else {
    initialValues = {
      transactionDate: Date(),
      details: "",
    }
  }

  return (
    <ActivityForm
      title="Note"
      fields={(control) => (
        <>
          <DatePickerField
            name="transactionDate"
            control={control}
            placeholder="Select Date"
            utc
          />
          <TextArea
            name="details"
            control={control}
            placeholder="Write an internal note"
            h={60}
          />
        </>
      )}
      initialValues={initialValues}
      apiPayload={({ transactionDate, type, details }) => ({
        type: "NoteTransaction",
        transactionDate,
        details,
        data: { type },
      })}
      activity={activity}
    />
  )
}

export default Note
