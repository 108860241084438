import { useInfiniteQuery, useQuery } from "react-query"
import { getGuest, getGuests } from "api/guests"
import { Guest, GuestSummary } from "sharedTypes"

export const guestCacheKey = (guestId?: string) => (guestId ? ["guest", guestId] : "guest")
export const guestsCacheKey = (
  eventId: string,
  searchString?: string,
  groupId?: string | number,
  order?: string,
  segmentId?: string,
) => ["guests", eventId, searchString, groupId, order, segmentId].filter(Boolean)

export const useGuest = (
  eventId: string,
  guestId: string,
):Guest => useQuery(
  guestCacheKey(guestId),
  () => getGuest(eventId, guestId).then(({ data }) => data),
  {
    initialData: {} as Guest,
  },
).data as Guest

export const useGuests = (
  eventId,
  searchString?,
  order?,
  groupId?,
  segmentId?,
) => {
  const { data, fetchNextPage, isLoading } = useInfiniteQuery(
    guestsCacheKey(eventId, searchString, groupId, order, segmentId),
    ({ pageParam = 1 }) => getGuests(
      eventId, pageParam, searchString, groupId, order, segmentId,
    ),
    {
      getNextPageParam: (
        lastPage,
        pages,
      ) => (lastPage.data.length === 100 ? pages.length + 1 : undefined),
    },
  )

  const guests: GuestSummary[] = data?.pages.reduce(
    (prev, curr) => ([...prev, ...curr.data]),
    [] as GuestSummary[],
  ) || []

  return { guests, fetchNextPage, isLoading }
}
