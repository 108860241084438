import React, { ReactNode, useRef } from "react"
import useCurrentAccount from "services/useCurrentAccount"
import { signOut, storeSocketId } from "services/authentication"
import { useIntercom } from "react-use-intercom"
import { Flex, Box, Spacer } from "@chakra-ui/react"
import { usePusher } from "@harelpls/use-pusher"
import { useToggle } from "ahooks"
import { useCurrentEvent } from "queries/events"
import { useQueryClient } from "react-query"
import { Route, Switch } from "react-router-dom"
import { eventPath } from "utilities/routes"
import { UUID } from "utilities/strings"
import SimpleBar from "simplebar-react"
import { User } from "sharedTypes"
import RootMenu from "./RootNav"
import EventMenu from "./EventNav"
import Dropdown from "./Dropdown"
import Icon from "../elements/Icon"
import ScrollToTop from "./ScrollToTop"

type Props = {
  children: ReactNode
  currentUser: User
}

const Navigation = ({ children, currentUser }: Props) => {
  const currentAccount = useCurrentAccount()
  const { shutdown: shutdownIntercom } = useIntercom()
  const [compact, { toggle: toggleCompact }] = useToggle(false)
  const { data: currentEvent } = useCurrentEvent()
  const queryClient = useQueryClient()
  const ref = useRef(null)

  const { client } = usePusher()
  if (client?.connection?.socket_id) {
    storeSocketId(client.connection.socket_id)
  }

  const sidebarWidth = compact && currentEvent.id ? 75 : 72

  return (
    <Flex align="stretch" h="100vh">
      <Box as={SimpleBar} h="100vh" flexShrink={0} w={sidebarWidth}>
        <Flex
          direction="column"
          py={3}
          bg="gray.900"
          color="gray.200"
          zIndex={10}
          minH="100vh"
        >
          <Dropdown
            currentUser={currentUser}
            currentAccount={currentAccount}
            signOut={() => signOut(queryClient, shutdownIntercom)}
            compact={compact}
          />
          <Switch>
            <Route path={eventPath(`:eventId${UUID}`)}>
              {currentEvent?.id && <EventMenu event={currentEvent} compact={compact} />}
            </Route>
            <Route>
              <RootMenu />
            </Route>
          </Switch>
          <Spacer />
          {currentEvent?.id && (
            <Flex justify={compact ? "center" : "flex-end"} w={sidebarWidth}>
              <Box p={5} role="button" onClick={() => toggleCompact()} color="gray.600">
                <Icon icon={compact ? "next-arrow" : "prev-arrow"} />
              </Box>
            </Flex>
          )}
        </Flex>
      </Box>
      <Box as={SimpleBar} flex={1} maxH="100vh" scrollableNodeProps={{ ref }}>
        {children}
      </Box>
      <ScrollToTop containerRef={ref} />
    </Flex>
  )
}

export default Navigation
