import { Text } from "@chakra-ui/react"
import React from "react"
import * as Yup from "yup"
import * as api from "api/websites"
import { updateWebsite } from "context/actions"
import { useQueryClient } from "react-query"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import ImageUpload from "components/ReactHookForm/ImageUpload"

interface Props {
  eventId: string
  coverImageUrl: string
  onHide: () => void;
}

const EventImage = ({ eventId, coverImageUrl, onHide }: Props) => {
  const queryClient = useQueryClient()

  const onSubmit = (values) => api.putWebsite(eventId, values)
    .then(({ data }) => {
      updateWebsite(queryClient, eventId, data)
      onHide()
    })

  const EventImageSchema = Yup.object().shape({
    coverImage: Yup.mixed().test("hasChanged", "Please upload or remove your image.", (value: any) => {
      if (coverImageUrl) {
        return typeof value !== "undefined"
      }

      return value?.name
    }),
  })

  return (
    <ModalForm
      title="Event Image"
      initialValues={{}}
      validationSchema={EventImageSchema}
      onSubmit={onSubmit}
      onClose={onHide}
    >
      {({ control }) => (
        <>
          <Text fontSize="lg" mb={3}>This will be the primary image on your event website.</Text>
          <ImageUpload
            alt="cover image"
            name="coverImage"
            control={control}
            imageUrl={coverImageUrl ? `${coverImageUrl}?w=320` : ""}
            imageWidth={320}
          />
        </>
      )}
    </ModalForm>
  )
}

export default EventImage
