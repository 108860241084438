import { AccountUser } from "sharedTypes"
import UserWithAvatar from "components/elements/UserWithAvatar"
import React from "react"

type Props = {
  teamMember: AccountUser
  isCurrent: boolean
}

const TeamMemberWithAvatar = ({ teamMember, isCurrent }: Props) => {
  const { user } = teamMember

  const name = user ? `${user.firstName} ${user.lastName}` : undefined
  const email = user?.email || teamMember.email
  const unconfirmed = user && !user.confirmed
  const photoUrl = user?.photoUrl

  return (
    <UserWithAvatar
      name={name}
      email={email}
      invited={!user}
      unconfirmed={unconfirmed}
      current={isCurrent}
      photoUrl={photoUrl}
      w="full"
    />
  )
}

export default TeamMemberWithAvatar
