import React from "react"
import { GuestStatus } from "sharedTypes"
import { Box, Flex, Image } from "@chakra-ui/react"

type Props = {
  status?: GuestStatus;
  firstName: string;
  lastName: string;
  photoUrl?: string;
  checkedIn?: boolean;
  size?: number;
}

const GuestAvatar = ({
  status = GuestStatus.Unconfirmed,
  firstName,
  lastName,
  photoUrl,
  checkedIn = false,
  size = 10,
}: Props) => {
  const initials = [firstName.charAt(0), lastName?.charAt(0)]
  const hasPhoto = photoUrl && !photoUrl.match("data.diobox.com")

  const bgColor = () => {
    if (checkedIn) {
      return "status.checkedIn"
    }

    switch (status) {
      case GuestStatus.Confirmed:
        return "status.confirmed"
      case GuestStatus.Declined:
        return "status.declined"
      case GuestStatus.Waitlisted:
        return "status.waitlisted"
      default:
        return "status.unconfirmed"
    }
  }

  return (
    <Box w={size} h={size}>
      <Flex
        align="center"
        justify="center"
        color="white"
        height="full"
        borderRadius="full"
        overflow="hidden"
        bgColor={bgColor()}
      >
        {hasPhoto && (
          <Image
            w="90%"
            borderRadius="full"
            bg="white"
            src={photoUrl}
            alt="avatar"
          />
        )}
        {!hasPhoto && <Box fontSize={size >= 32 ? "5xl" : ""}>{initials}</Box>}
      </Flex>
    </Box>
  )
}

export default GuestAvatar
