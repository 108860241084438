import React from "react"
import debounce from "debounce-promise"
import { updateWebsite } from "context/actions"
import * as api from "api/websites"
import { HStack, Text } from "@chakra-ui/react"
import { eventWebsitePath } from "utilities/routes"
import { useHistory } from "react-router-dom"
import { useQueryClient } from "react-query"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextField from "components/ReactHookForm/TextField"

const testWebsiteUrl = async (urlPath: string) => {
  const errors: {urlPath?: string} = {}

  if (urlPath.length < 3) {
    return "Please enter at least 3 characters"
  }

  await api.validateWebsiteUrlPath(urlPath)
    .then((response) => {
      if (!response.data.valid) {
        errors.urlPath = response.data.error.userMessage
      }
    }).catch(() => {
      errors.urlPath = "Something went wrong."
    })

  return errors.urlPath
}

const debouncedTestWebsiteUrl = debounce(testWebsiteUrl, 250)

type Props = {
  eventId: string
  urlPath: string
  onHide: () => void
}

const WebPageURL = ({ eventId, urlPath, onHide }: Props) => {
  const queryClient = useQueryClient()
  const history = useHistory()

  const onSubmit = (values) => {
    const resetAndHide = () => {
      onHide()
    }

    return api.putWebsite(eventId, values)
      .then(({ data }) => {
        updateWebsite(queryClient, eventId, data)
        history.push(eventWebsitePath(eventId))
        resetAndHide()
      })
      .catch(() => {
        // API service automatically alerts the user, so code can continue to run,
        // but we don't want to close the modal
      })
  }

  const initialValues = {
    urlPath: urlPath || "",
  }

  return (
    <ModalForm
      title="Page URL"
      onClose={onHide}
      initialValues={initialValues}
      onSubmit={onSubmit}
      size="lg"
    >
      {({ control, errors }) => (
        <>
          <Text fontSize="lg" mb={5}>Set your vanity URL, a short link to remember and share:</Text>
          <HStack align="baseline">
            <Text fontWeight="semibold">{import.meta.env.VITE_WEBSITE_HOST}/</Text>
            <TextField
              name="urlPath"
              control={control}
              error={!!errors.urlPath?.message}
              helperText={errors.urlPath?.message}
              validate={debouncedTestWebsiteUrl}
            />
          </HStack>
        </>
      )}
    </ModalForm>
  )
}

export default WebPageURL
