import { useForms } from "queries/forms"
import { FormType, GuestStatus, GuestSummary } from "sharedTypes"
import { getFormExternalUrl } from "utilities/forms"
import { replaceMergeTagsInText } from "utilities/strings"

type Props = {
  eventId: string
  guestExternalId?: string
}

const useMergeTags = ({ eventId, guestExternalId }: Props) => {
  const { data: forms } = useForms(eventId)

  const getLinkByFormId = (formId: string) => {
    const form = forms?.data.find(({ externalId }) => externalId === formId)

    if (!form) {
      return undefined
    }

    const { externalId, type } = form
    const isRsvp = type === FormType.RSVP

    const formUrl = getFormExternalUrl(externalId, isRsvp)

    return (guestExternalId && isRsvp) ? formUrl.replace("preview", guestExternalId) : formUrl
  }

  const replaceMergeTags = (
    original: string,
    eventTitle: string,
    eventDate: string,
    selectedGuest: GuestSummary | null,
  ) => {
    const mergeTagReplacements = [
      { mergeTag: "{{EVENT}}", value: eventTitle },
      { mergeTag: "{{DATE}}", value: eventDate },
    ]
    const bodyWithEventAndDate = replaceMergeTagsInText(original, mergeTagReplacements)

    const formMergeTagReplacements = extractFormIds(bodyWithEventAndDate).map(
      (formMergeTag) => ({ mergeTag: formMergeTag.mergeTag, value: getLinkByFormId(formMergeTag.id) ?? "" }),
    )
    const bodyWithMergeTags = replaceMergeTagsInText(bodyWithEventAndDate, formMergeTagReplacements)

    if (!selectedGuest) {
      return bodyWithMergeTags
    }

    const generateGuestMergeTagReplacements = () => {
      const guestName = `${selectedGuest.personInfo.firstName} ${selectedGuest.personInfo.lastName}`

      let confirmationStatus
      switch (selectedGuest.status) {
        case GuestStatus.Confirmed: {
          confirmationStatus = "Confirmed"
          break
        }
        case GuestStatus.Declined: {
          confirmationStatus = "Declined"
          break
        }
        case GuestStatus.Unconfirmed: {
          confirmationStatus = "Unconfirmed"
          break
        }
        case GuestStatus.Waitlisted: {
          confirmationStatus = "Waitlisted"
          break
        }
        default: {
          break
        }
      }

      const seatingInfo = selectedGuest.seatingAssignment ? `${selectedGuest.seatingAssignment.seatingTableTitle} - ${selectedGuest.seatingAssignment.seat}` : ""
      const tableName = selectedGuest.seatingAssignment ? selectedGuest.seatingAssignment.seatingTableTitle : ""

      return [
        { mergeTag: "{{FULL_NAME}}", value: guestName },
        { mergeTag: "{{CONFIRMATION_STATUS}}", value: confirmationStatus },
        { mergeTag: "{{SEATING_INFO}}", value: seatingInfo },
        { mergeTag: "{{TITLE}}", value: selectedGuest.personInfo.companyTitle ?? "" },
        { mergeTag: "{{COMPANY}}", value: selectedGuest.personInfo.companyName ?? "" },
        { mergeTag: "{{EMAIL_ADDRESS}}", value: selectedGuest.personInfo.email ?? "" },
        { mergeTag: "{{LAST_NAME}}", value: selectedGuest.personInfo.lastName ?? "" },
        { mergeTag: "{{FIRST_NAME}}", value: selectedGuest.personInfo.firstName ?? "" },
        { mergeTag: "{{TABLE_NAME}}", value: tableName },
      ]
    }

    return replaceMergeTagsInText(bodyWithMergeTags, generateGuestMergeTagReplacements())
  }

  const getFormsFromTextBody = (body: string) => {
    const extractedFormIds = extractFormIds(body)

    if (forms) {
      return forms.data.filter(
        ({ externalId }) => extractedFormIds.map(({ id }) => id).includes(externalId),
      )
    }

    return []
  }

  const extractFormIds = (text: string) => {
    const regex = /\{\{FORM_[a-zA-Z0-9]+\}\}/g

    const matches = text.match(regex)

    return matches?.map((match) => {
      const id = match.slice(2, -2).split("_")[1]

      return { mergeTag: match, id }
    }) || []
  }

  return { replaceMergeTags, getFormsFromTextBody }
}

export default useMergeTags
