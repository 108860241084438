import React from "react"
import Link from "components/elements/Link"
import { LinkProps } from "@chakra-ui/react"
import Icon from "./elements/Icon"

export type Props = {
  children: string;
  url: string;
} & LinkProps

const DocsLink = ({ children, url, ...props }: Props) => <Link href={url} isExternal {...props}><Icon icon="book" size={5} mr={2} ml="-2px" mt="-2px" />{children}</Link>

export default DocsLink
