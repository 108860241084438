import React, { ReactNode } from "react"
import Icon from "components/elements/Icon"
import { Box } from "@chakra-ui/react"
import NavLink from "../NavLink"

type Props = {
  icon: string;
  title: string;
  path: string;
  compact?: boolean;
  showSubMenu?: boolean;
  type?: "account" | "event";
  onClick?: (event: React.MouseEvent) => void;
  indicator?: ReactNode;
  children?: ReactNode | ReactNode[];
}

const NavItem = ({
  icon,
  title,
  path,
  compact,
  showSubMenu,
  children,
  type = "account",
  indicator,
  ...props
}: Props) => {
  const submenu = children && (
    <Box
      my={1}
      {...showSubMenu ? { display: "block" } : { display: "none" }}
    >
      {children}
    </Box>
  )

  return (
    <Box position="relative" pb={type === "account" ? 10 : 2}>
      <NavLink
        activeProps={{
          bg: "black", borderColor: "blue.500", fontWeight: "bold", color: "white",
        }}
        display="block"
        borderLeftWidth={5}
        borderColor="gray.900"
        lineHeight="taller"
        letterSpacing=".05em"
        {...type === "account" ? { px: compact ? 5 : 8 } : {
          px: 5, pt: 2, pb: 1, fontSize: "sm",
        }}
        to={path}
        exact={!!children}
        {...props}
      >
        <Box>
          <Icon icon={icon} size={5} mr={type === "account" ? 1 : 4} mb={1} verticalAlign="middle" /> {!compact && title}
        </Box>
      </NavLink>
      {submenu}
      {!compact && indicator}
    </Box>
  )
}

export default NavItem
