import React from "react"
import { Box, Text } from "@chakra-ui/react"

const SubmissionPreference = ({
  label,
  lines,
}: {
  label: string
  lines: (string | null)[]
}) => (
  <Box>
    <Text textTransform="uppercase" fontSize="xs" mb={0} color="gray.600">
      {label}
    </Text>
    {lines.filter(Boolean).map((t, i) => (
      <Text key={i}>{t}</Text>
    ))}
  </Box>
)

export default SubmissionPreference
