import React from "react"
import Icon from "components/elements/Icon"
import { Flex, Text } from "@chakra-ui/react"

type Props = {
  icon: React.ReactNode;
  title: string;
  disabled: boolean;
}

const DropdownToggle = ({ icon, title, disabled }: Props) => (
  <Flex align="center" justify="space-between" p={4} borderWidth={1} borderRadius="base">
    <Flex align="center" color="gray.700">
      {icon}
      <Text fontSize="lg" fontWeight="light" noOfLines={1}>{title}</Text>
    </Flex>
    {!disabled && <Icon icon="down-arrow" size={3} mr={-2} color="gray.300" />}
  </Flex>
)

export default DropdownToggle
