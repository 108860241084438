import React from "react"
import { Text, VStack } from "@chakra-ui/react"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import { postExportAccount } from "api/accounts"
import { successToast } from "utilities/toasts"
import { useMutation } from "react-query"

type Props = {
  userEmail: string;
}

const AccountExport = ({ userEmail }: Props) => {
  const { mutateAsync } = useMutation(
    postExportAccount,
    {
      onSuccess: (() => {
        successToast({ title: "Exporting in progress. The time to process depends on the number of guests." })
      }),
    },
  )

  return (
    <ContainedForm
      values={{}}
      onSubmit={mutateAsync}
      submitButtonColorScheme="button.primary"
      submitButtonText="Export"
      confirmationTitle="Account Export"
      confirmButtonLabel="Export"
      confirmationText={(
        <VStack gap={4}>
          <Text>Export all guests across all your events. This option will include all guest data
            stored on Diobox.
          </Text>
          <Text>The time to process the export depends on the total number of guests
            in your account. When ready, a CSV export file will be emailed
            to: <Text as="strong" fontWeight="semibold">{userEmail}</Text>
          </Text>
        </VStack>
)}
      showCancel={false}
      disableSubmitWhenNotDirty={false}
    >
      <VStack spacing={4} py={4} align="baseline">
        <Text>
          Export all guests across all your events.
          This option will include all guest data stored on Diobox.
        </Text>
      </VStack>
    </ContainedForm>
  )
}

export default AccountExport
