import React from "react"
import { Box, Stack, Text } from "@chakra-ui/react"
import LabelRedefinition from "modals/FormForm/LabelRedefinition"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import { DioboxEvent, Translations } from "sharedTypes"
import TitleWithSubtitle from "../../TitleWithSubtitle"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
}

const OtherPhrasesSection = ({ event, updateEvent, defaultTranslations }: Props) => {
  const onSubmit = (values) => updateEvent(values)

  return (
    <Box>
      <TitleWithSubtitle
        title="Other Phrases"
        subtitle="Customize the other terms or phrases in the Guest Portal."
        mb={5}
      />
      <ContainedForm values={event} onSubmit={onSubmit} name="Other Phrases">
        {({ control }) => (
          <Stack spacing={12}>
            <Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.guest}
                name="translations.guest"
                control={control}
              />
              <Text>
                This header separates each guest’s registration by number, e.g.,
                Guest 1, Guest 2, etc., when multiple guests are included.
              </Text>
            </Box>
            <Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.eventActivity}
                name="translations.eventActivity"
                control={control}
              />
              <Text>
                The header that appears on the Guest Portal if a
                guest has any form submissions.
              </Text>
            </Box>
            <Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.qrCodeForCheckIn}
                name="translations.qrCodeForCheckIn"
                control={control}
              />
              <Text>
                The help text that appears below the QR codes on the
                confirmation pages and email notifications.
              </Text>
            </Box>
            <Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.buttonNotWorking}
                name="translations.buttonNotWorking"
                control={control}
              />
              <Text>
                This help text appears at the bottom of the email ensuring the button link
                works across all email clients, supports screen readers, and provides transparency
                for security.
              </Text>
            </Box>
            <Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.receipt}
                name="translations.receipt"
                control={control}
              />
              <Text>
                This label appears in guest emails, followed by a link to the form they submitted.
              </Text>
            </Box>
            <Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.editProfileHeader}
                name="translations.editProfileHeader"
                control={control}
              />
              <Text>
                The help text that appears on top of the Guest Profile Edit page.
              </Text>
            </Box>
            <Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.editProfile}
                name="translations.editProfile"
                control={control}
              />
              <Text>
                The header and the menu item that allows a guest to edit their profile.
              </Text>
            </Box>
            <Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.emailNotifications}
                name="translations.emailNotifications"
                control={control}
              />
              <Text>
                The header and the menu item that allows a guest to
                change their email subscription settings.
              </Text>
            </Box>
            <Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.uploadPhoto}
                name="translations.uploadPhoto"
                control={control}
              />
              <Text>
                The header and the button that allows a guest to upload
                their own photo.
              </Text>
            </Box>
          </Stack>
        )}
      </ContainedForm>
    </Box>
  )
}

export default OtherPhrasesSection
