import React from "react"
import GuestAvatar from "components/elements/GuestAvatar"
import { Guest } from "sharedTypes"
import { Flex, Box, Text } from "@chakra-ui/react"
import EmailProperty from "../../../MultipleSelectActions/Action/EmailProperty"

type Props = {
  guest: Guest
}

const GuestCard = ({
  guest: {
    personInfo, personInfo: { firstName, lastName, email }, status, checkedIn,
  },
}: Props) => (
  <EmailProperty name="To">
    <Flex align="center">
      <Box mr={4}>
        <GuestAvatar {...personInfo} status={status} checkedIn={!!checkedIn} />
      </Box>
      <Box lineHeight={1.2}>
        {firstName} {lastName}<br />
        <Text>
          ({email})
        </Text>
      </Box>
    </Flex>
  </EmailProperty>
)

export default GuestCard
