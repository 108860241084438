import React from "react"
import { Box, Text } from "@chakra-ui/react"

type Props = {
  sections: {size: number, color: string}[]
  h?: number
}

const StatusBar = ({ sections, h = 3.5 }: Props) => {
  const total = sections.reduce((acc, object) => acc + object.size, 0)
  const nonZeroSections = sections.filter(({ size }) => !!size)

  const sectionsList = nonZeroSections.map(({ size, color }, index) => (
    <Text
      as="span"
      bgColor={color}
      key={index}
      style={{ width: `${(size / total) * 100}%` }}
      display="inline-block"
      h="full"
      boxSizing="content-box"
      borderInlineEnd={1}
      borderColor={size > 0 && index !== nonZeroSections.length - 1 ? "gray.300" : "none"}
      lineHeight="none"
    />
  ))

  return (
    <>
      <Box
        w="full"
        h={h}
        boxSizing="content-box"
        borderColor="gray.400"
        borderRadius={4}
        lineHeight="none"
        overflow="hidden"
        bg="gray.200"
      >
        {sectionsList}
      </Box>
    </>
  )
}

export default StatusBar
