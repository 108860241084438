import React from "react"
import * as Yup from "yup"
import { Flex, Text } from "@chakra-ui/react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextField from "components/ReactHookForm/TextField"

interface Props {
  onHide: () => void
  title: string
  subtitle: string
  onSubmit: (values: {})=> void
  fieldName: string
  initialValues: {}
}

const EditFieldModal = ({
  onHide, title, subtitle, onSubmit, fieldName, initialValues,
}: Props) => {
  const handleSubmit = async (values: {}) => {
    await onSubmit(values)
    onHide()
  }

  return (
    <ModalForm
      size="lg"
      title={title}
      onClose={onHide}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({ [fieldName]: Yup.string().required() })}
    >
      { ({ control }) => (
        <Flex flexDirection="column" gap={4} mb={24}>
          <Text>{subtitle}</Text>
          <Flex w="80%">
            <TextField name={fieldName} control={control} variant="outline" />
          </Flex>
        </Flex>
      )}
    </ModalForm>
  )
}

export default EditFieldModal
