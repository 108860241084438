import React from "react"
import EditorSection from "components/Builder/components/EditorSection"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import { Text } from "@chakra-ui/react"
import PaddingInputs from "components/Builder/components/PaddingInputs"
import ColorSelect from "components/ReactHookForm/ColorSelect/ColorSelect"
import { Control } from "react-hook-form"

type Props = {
  control: Control
}

const QRCodeFields = ({ control }: Props) => (
  <>
    <SidePanelHeader>QR Code Block</SidePanelHeader>
    <EditorSection>
      <Text mb={4}>
        This block will generate a unique QR Code for each guest
        that can be scanned by the Diobox iOS app to track check-in.
      </Text>
    </EditorSection>
    <EditorSection>
      <PaddingInputs control={control} />
    </EditorSection>
    <EditorSection>
      <ColorSelect label="Block Color" name="blockColor" control={control} colorScheme="light" />
    </EditorSection>
  </>
)

export default QRCodeFields
