import React, { ReactNode } from "react"
import { VStack, Heading, Text } from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import { BuilderType } from "sharedTypes/builder"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import EditorSection from "components/Builder/components/EditorSection"
import AddContentSection from "components/Builder/AddContentSection"
import { EMAIL_BUILDER_EMAIL_INVITATION_ARTICLE_LINK } from "utilities/externalLinks"
import HelpCenterLink from "./EditorPanel/HelpCenterLink"

type Props = {
  onClickSettings: () => void
}

const EmailBuilderPanel = ({ onClickSettings }: Props) => (
  <>
    <SidePanelHeader>Email Builder</SidePanelHeader>
    <AddContentSection builderType={BuilderType.Email} />
    <Section>
      <Heading size="md" fontWeight="normal">Email Settings</Heading>
      <Text>Edit general colors, add upload attachment, and change unsubscribe.</Text>
      <Button colorScheme="button.primary" variant="outline" onClick={onClickSettings}>Edit Settings</Button>
    </Section>
    <HelpCenterLink url={EMAIL_BUILDER_EMAIL_INVITATION_ARTICLE_LINK} title="Help Center: Emails & Invitations" />
  </>
)

const Section = ({ children }: {children: ReactNode[]}) => (
  <EditorSection>
    <VStack pt={6} pb={8} spacing={6} maxW={72} m="auto" textAlign="center">
      {children}
    </VStack>
  </EditorSection>
)

export default EmailBuilderPanel
