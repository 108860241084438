import Select from "components/ReactHookForm/Select"
import React from "react"

const HeadingSizeSelect = ({ control }) => (
  <Select
    name="size"
    control={control}
    label="Heading Size"
    options={[
      { label: "Title", value: 1 },
      { label: "Heading 1", value: 2 },
      { label: "Heading 2", value: 3 },
      { label: "Heading 3", value: 4 },
    ]}
  />
)

export default HeadingSizeSelect
