import React from "react"
import { Box, Button as ChakraButton, ButtonProps } from "@chakra-ui/react"
import Icon from "components/elements/Icon"

type Props = {
  children: React.ReactNode;
  leftIcon?: string
  rightIcon?: string
  href?: string
  target?: string
  to?: string
} & Omit<ButtonProps, "leftIcon" | "rightIcon" | "disabled">

const Button = ({
  children,
  colorScheme = "button.primary",
  type = "button",
  variant = "solid",
  size = "md",
  leftIcon,
  rightIcon,
  ...rest
}: Props) => (
  <ChakraButton
    type={type}
    colorScheme={colorScheme}
    size={size}
    variant={variant}
    bgColor={variant === "outline" ? "white" : ""}
    {...leftIcon ? { leftIcon: <Icon icon={leftIcon} /> } : {}}
    {...rest}
  >
    <Box lineHeight="short">{children}</Box>
    {rightIcon && <Icon icon={rightIcon} size={3} ml={2} />}
  </ChakraButton>
)

export default Button
