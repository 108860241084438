import { extendTheme } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"
import chakraColors from "./chakraColors"
import cardTheme from "./cardTheme"

const noFocusOutline = { baseStyle: { _focus: { outline: "none", boxShadow: "none" } } }

export default extendTheme({
  colors: chakraColors,
  fonts: {
    body: "proxima-nova",
  },
  components: {
    Checkbox: { baseStyle: { control: { bg: "white" } } },
    Switch: { baseStyle: { track: { bg: "gray.600", _checked: { bg: "blue.500" } } } },
    Radio: {
      baseStyle: {},
      variants: {
        readonly: {
          parts: ["label", "control"],
          label: {
            _disabled: {
              opacity: 1,
            },
          },
          control: {
            _disabled: {
              bg: "gray.200",
              borderColor: "gray.300",
            },
          },
        },
      },
    },
    Button: noFocusOutline,
    Link: noFocusOutline,
    Popover: {
      variants: {
        responsive: {
          content: { width: "unset" },
        },
      },
    },
    Card: cardTheme,
  },
  styles: {
    global: (props) => ({
      body: {
        color: mode("gray.900", "whiteAlpha.900")(props),
      },
      "*, *::before, &::after": {
        borderColor: mode("gray.300", "whiteAlpha.300")(props),
      },
    }),
  },
})
