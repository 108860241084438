import React from "react"
import * as api from "api/emails"
import {
  addEmail,
  removeEmail,
  updateEmail as updateEmailAction,
} from "context/actions"
import EmailWizard from "pages/EventShow/Emails/EmailWizard"
import EmailReport from "pages/EventShow/Emails/EmailReport"
import { useHistory, useLocation, useParams } from "react-router-dom"
import Spinner from "components/elements/Spinner"
import SentEmail from "pages/EventShow/Emails/SentEmail"
import DraftEmail from "pages/EventShow/Emails/DraftEmail"
import { eventEmailPath, eventEmailsPath } from "utilities/routes"
import { useMutation, useQueryClient } from "react-query"
import { useCurrentEvent } from "queries/events"
import { useEmail } from "queries/emails"
import { AccountUser, User } from "sharedTypes"
import useFormIntegrations from "services/useFormIntegrations"

type Props = {
  updateEvent: (params: {}) => Promise<void>
  currentUser: User
  accountUsers: AccountUser[]
}

const SelectedEmail = ({ updateEvent, currentUser, accountUsers }: Props) => {
  const {
    emailId: emailIdString,
    action,
  } = useParams<{ eventId: string, emailId: string, action: string }>()
  const emailId = Number(emailIdString)
  const { isLoading: eventIsLoading, data: currentEvent } = useCurrentEvent()
  const history = useHistory()
  const location = useLocation()
  const queryClient = useQueryClient()
  const {
    isLoading: currentEmailIsLoading,
    data: currentEmail,
  } = useEmail(currentEvent.id, emailId)
  const {
    data: linkedForms,
    isLoading: formIntegrationsIsLoading,
    deletedFormsCount,
  } = useFormIntegrations(
    { eventId: currentEvent.id, email: currentEmail },
  )

  const updateEmail = (params) => api.putInvitation(currentEvent.id, emailId, {
    ...params,
    ...params.emailAttachment ? { "invitation[emailAttachment]": params.emailAttachment } : {},
    ...params.showEmailAttachment === false ? { emailAttachment: null } : {},
  })
    .then(({ data }) => {
      updateEmailAction(queryClient, currentEvent.id, data)
    })

  const duplicateEmail = useMutation(
    () => api.duplicateInvitation(currentEvent.id, emailId).then(({ data }) => data), {
      onSuccess: (data) => {
        addEmail(queryClient, currentEvent.id, data)
        history.push(eventEmailPath(currentEvent.id, data.id))
      },
    },
  )

  const deleteEmail = useMutation(
    () => api.deleteInvitation(currentEvent.id, emailId), {
      onSuccess: () => {
        removeEmail(queryClient, currentEvent.id, emailId)
        history.push(eventEmailsPath(currentEvent.id))
      },
    },
  )

  const sendEmail = () => api.postInvitationSend(currentEvent.id, emailId).then(({ data }) => {
    updateEmailAction(queryClient, currentEvent.id, data)
  })

  if (currentEmailIsLoading || eventIsLoading || !currentEmail || formIntegrationsIsLoading) {
    return <Spinner heightFull />
  }

  if (!linkedForms) {
    return null
  }

  if (["setup", "recipients", "email", "review"].includes(action)) {
    return (
      <EmailWizard
        event={currentEvent}
        email={currentEmail}
        action={action}
        updateEmail={updateEmail}
        sendEmail={sendEmail}
        updateEvent={updateEvent}
        currentUser={currentUser}
        accountUsers={accountUsers}
        linkedForms={linkedForms}
        deletedFormsCount={deletedFormsCount}
      />
    )
  }

  if (location.pathname.match(/report$/)) {
    return (
      <EmailReport email={currentEmail} />
    )
  }

  if (currentEmail.sentOn) {
    return (
      <SentEmail
        email={currentEmail}
        duplicateEmail={() => duplicateEmail.mutate()}
        deleteEmail={() => deleteEmail.mutate()}
        linkedForms={linkedForms}
        deletedFormsCount={deletedFormsCount}
        event={currentEvent}
      />
    )
  }

  return (
    <DraftEmail
      event={currentEvent}
      email={currentEmail}
      duplicateEmail={() => duplicateEmail.mutate()}
      deleteEmail={() => deleteEmail.mutate()}
      linkedForms={linkedForms}
      deletedFormsCount={deletedFormsCount}
    />
  )
}

export default SelectedEmail
