import React from "react"
import { Box, Text, Link } from "@chakra-ui/react"
import { Email } from "sharedTypes"
import BlockContent from "./Builder/BlockContent"

type Props = {
  email: Email
}

const ViewEmailContent = ({
  email: {
    showUnsubscribe, backgroundColor, borderColor, emailData: { blocks },
  },
}: Props) => (
  <Box bgColor={backgroundColor} py={10}>
    <Box borderWidth={1} borderColor={borderColor} w={600} mx="auto" mb={2}>
      {blocks.map((block, index) => <BlockContent key={index} block={block} />)}
    </Box>
    {showUnsubscribe && (
      <Text align="center" mt={8} color="gray.600" fontSize="sm">
        Don&apos;t want to receive invitations from us? <Link textDecoration="underline" href="#">Unsubscribe</Link>.
      </Text>
    )}
  </Box>
)

export default ViewEmailContent
