import React from "react"
import SectionHeader from "pages/EventShow/Settings/SectionHeader"
import {
  Box, Flex, Image, Stack, Text,
} from "@chakra-ui/react"
import Link from "components/elements/Link"
import { iOSAppURL } from "utilities/routes"
import download from "assets/images/download-from-app-store.png"
import badgePrintingPreview from "assets/images/badge-printing-ios-app-preview.png"
import UpgradeAlert from "components/elements/UpgradeAlert"
import { BADGE_PRINTING_ARTICLE_URL, DIOBOX_APP_LINK } from "utilities/externalLinks"

type Props = {
  enabled: boolean
}

const BadgePrinting = ({ enabled }: Props) => (
  <>
    <SectionHeader>Badge Printing</SectionHeader>
    <Stack mt={6} spacing={10} align="center">
      <Box borderRadius="xl" bgColor="white" borderWidth={1} p={6}>
        <Flex mb={8}>
          <Box>
            <Text mb={4}>
              Manage the complete badge production process from start to finish on the{" "}
              <Link isExternal href={DIOBOX_APP_LINK}>Diobox iOS App.</Link><br />
              You can build badges using branded templates and multi-purpose QR codes for networking
              opportunities and for your sponsors/exhibitors. This proprietary QR code system can be
              used by both the event organizer to track the guest and by any other party to
              facilitate the exchange of virtual business cards.
            </Text>
            <Text mb={4}>
              To learn more, see:{" "}
              <Link isExternal href={BADGE_PRINTING_ARTICLE_URL}>
                Badge Printing Help Articles
              </Link>
            </Text>
          </Box>
        </Flex>
        <Image src={badgePrintingPreview} />
      </Box>
      <Link w={130} display="block" href={iOSAppURL} isExternal>
        <Image src={download} />
      </Link>
      {!enabled && <Box w="full"><UpgradeAlert text="Feature requires a Professional Subscription or above." /></Box>}
    </Stack>
  </>
)

export default BadgePrinting
