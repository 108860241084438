import React from "react"
import Icon from "components/elements/Icon"
import { EmailTransaction } from "sharedTypes"
import {
  Box, Flex, Stack,
} from "@chakra-ui/react"
import Popover from "components/dialogs/Popover"
import {
  ActivityPopoverContent, ActivityPopoverDeleteTransaction, ActivityPopoverRow,
  ActivityPopoverSection,
} from "components/dialogs/ActivityPopover"
import { asDateWithTZ } from "utilities/strings"
import { eventEmailPath } from "utilities/routes"
import Link from "components/elements/Link"
import { useCurrentEvent } from "queries/events"
import { getColorByInvitationStatus } from "utilities/invitations"
import Status from "./Status"

type Props = {
  item: EmailTransaction
}

const InvitationItem = ({
  item,
  item: {
    data: {
      table: {
        sentTo, sentFromName, sentFromEmail, sentSubject, opened, clicked,
        bounced, softBounced, hardBounced,
      },
    },
    transactionDate, userId, title, displayType,
    eventId, emailId,
  },
}: Props) => {
  const getStatuses = () => {
    const statuses: React.ReactNode[] = []
    if (softBounced) {
      statuses.push(<Status key="softBounced" text="Soft Bounced" color="emailStatus.softBounced" />)
    } else if (hardBounced) {
      statuses.push(<Status key="hardBounced" text="Hard Bounced" color="emailStatus.hardBounced" />)
    } else if (bounced) {
      statuses.push(<Status key="bounced" text="Bounced" color="emailStatus.bounced" />)
    } else if (opened) {
      statuses.push(<Status key="opened" text={`Opened x${opened}`} color="emailStatus.opened" />)
    } else if (clicked > 0) {
      statuses.push(<Status key="opened" text="Opened" color="emailStatus.opened" />)
    } else {
      statuses.push(<Status key="unopened" text="Unopened" color="emailStatus.unopened" />)
    }

    if (clicked) {
      statuses.push(<Status key="clicked" text={`Clicked x${clicked}`} color="emailStatus.clicked" />)
    }

    return statuses
  }

  const iconColor = getColorByInvitationStatus({
    clicked, opened, softBounced, hardBounced, bounced,
  })
  const { data: { timezone, users } } = useCurrentEvent()
  const sender = users.find(({ user }) => user?.id === userId)?.user
  const icon = <Icon icon="email-plain" color={iconColor} size={6} mr={2} />

  return (
    <Flex justify="space-between">
      <Box>
        <Popover
          trigger={(
            <Box color="gray.900">
              {icon} {displayType}
            </Box>
          )}
          header={displayType}
          placement="auto"
          body={(
            <ActivityPopoverContent>
              {emailId !== null && (
                <ActivityPopoverSection>
                  <ActivityPopoverRow
                    label="Email Name"
                    value={<Link to={eventEmailPath(eventId, emailId)}>{title}</Link>}
                  />
                </ActivityPopoverSection>
              )}
              <ActivityPopoverSection>
                <ActivityPopoverRow
                  label="Date/Time"
                  value={asDateWithTZ(transactionDate, timezone)}
                />
                {sender && (
                <ActivityPopoverRow
                  label="Sent by"
                  value={`${sender.firstName} ${sender.lastName}`}
                />
                )}
              </ActivityPopoverSection>
              <ActivityPopoverSection>
                <ActivityPopoverRow label="To" value={sentTo} />
                <ActivityPopoverRow label="From" value={<>{sentFromName}<br />({sentFromEmail})</>} />
                <ActivityPopoverRow label="Subject" value={sentSubject} />
              </ActivityPopoverSection>
              <ActivityPopoverSection>
                <ActivityPopoverRow
                  label="Engagement"
                  value={<Stack spacing={3}>{getStatuses()}</Stack>}
                />
              </ActivityPopoverSection>
              <ActivityPopoverDeleteTransaction item={item} />
            </ActivityPopoverContent>
          )}
        />
      </Box>
    </Flex>
  )
}

export default InvitationItem
