import React from "react"
import { Box } from "@chakra-ui/react"
import {
  DioboxEvent, Form, FormStatus, FormType,
} from "sharedTypes"
import { formStatusAndColor } from "utilities/forms"
import Submissions from "./Submissions/Submissions"
import Link from "./Link"
import Visibility from "./Visibility"
import StatusRow from "./StatusRow"
import Status from "./Status"

type Props = {
  form: Form
  event: DioboxEvent
}

const StatusBox = ({ form, event }: Props) => {
  const {
    quantity,
    submissionLineItemsCount,
    submissionsEndAt,
    private: isPrivate,
    type,
    externalId,
  } = form
  const { id: eventId } = event

  const { status, color } = formStatusAndColor(form, event)

  const generateCountsTitle = () => {
    switch (type) {
      case FormType.Registration:
        return "Registrations"
      case FormType.Ticket:
        return "Ticket Orders"
      case FormType.RSVP:
        return "RSVPs"
      case FormType.Waitlist:
      case FormType.Survey:
        return "Submissions"
      default:
        return ""
    }
  }

  return (
    <Box
      borderWidth={1}
      borderRadius="md"
      w="100%"
      px={6}
      pb={6}
      backgroundColor="white"
    >
      <StatusRow title="Status">
        <Status
          submissionsEndAt={submissionsEndAt}
          status={status}
          formType={type}
          color={color}
          event={event}
        />
      </StatusRow>
      {status !== FormStatus.GatewayNotConnected && (
        <StatusRow title="Visibility">
          <Visibility isPrivate={isPrivate} status={status} type={type} />
        </StatusRow>
      )}
      <StatusRow title="Direct Link">
        <Link type={type} externalId={externalId} />
      </StatusRow>
      <StatusRow title={generateCountsTitle()}>
        <Submissions
          quantity={quantity}
          submissionLineItemsCount={submissionLineItemsCount}
          type={type}
          eventId={eventId}
          formId={externalId}
        />
      </StatusRow>
    </Box>
  )
}

export default StatusBox
