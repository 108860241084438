import React from "react"
import { AgendaItem, Speaker } from "sharedTypes"
import * as Yup from "yup"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextField from "components/ReactHookForm/TextField"
import DatePickerField from "components/ReactHookForm/DatePickerField"
import TextAreaField from "components/ReactHookForm/TextArea"
import Checkboxes from "components/ReactHookForm/Checkboxes"
import { updateEndDate } from "utilities/objects"
import SpeakerListItem from "../SpeakerListItem"

type Props = {
  agendaItem?: AgendaItem;
  onHide: () => void;
  onSubmit: (values: any) => Promise<any>;
  onDelete: (agendaRowId) => void;
  eventSpeakers: Speaker[];
}

const AgendaItemForm = ({
  agendaItem,
  onHide,
  onSubmit,
  onDelete,
  eventSpeakers,
}: Props) => {
  const AgendaItemSchema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string(),
    speakerIds: Yup.array().of(Yup.number()),
    startsAt: Yup.date().required(),
    endsAt: Yup.date(),
  })

  const initialValues = agendaItem ? { ...agendaItem } : {
    title: "",
    description: "",
    speakerIds: [],
    startsAt: "",
    endsAt: "",
  }

  return (
    <ModalForm
      title={`${agendaItem ? "Edit" : "Add"} Agenda Item`}
      onClose={onHide}
      initialValues={initialValues}
      validationSchema={AgendaItemSchema}
      onSubmit={onSubmit}
      onDanger={agendaItem ? () => onDelete(agendaItem.id) : null}
    >
      {({ control, watch, setValue }) => {
        const [startsAt, endsAt] = watch(["startsAt", "endsAt"])

        const handleStartDateChange = () => {
          updateEndDate({
            startDate: startsAt, endDate: endsAt, defaultDiffInHours: 1, setValue, endDateName: "endsAt",
          })
        }

        return (
          <>
            <DatePickerField
              showTimeSelect
              name="startsAt"
              control={control}
              label="Start time"
              utc
              intervalStartDate={startsAt}
              intervalEndDate={endsAt}
              onCalendarClose={handleStartDateChange}
            />
            <DatePickerField
              showTimeSelect
              name="endsAt"
              control={control}
              label="End time"
              utc
              minDate={new Date(startsAt)}
              intervalStartDate={startsAt}
              intervalEndDate={endsAt}
            />
            <TextField name="title" control={control} label="Title" />
            <TextAreaField name="description" control={control} label="Description" h={40} />
            <Checkboxes
              label="Speakers"
              name="speakerIds"
              control={control}
              items={eventSpeakers}
              itemComponent={SpeakerListItem}
            />
          </>
        )
      }}
    </ModalForm>
  )
}

export default AgendaItemForm
