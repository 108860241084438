import React from "react"
import AuthenticationWrapper from "pages/Unauthenticated/AuthenticationWrapper"
import { VStack, Box, Text } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import { eventsPath, upgradePath, useQuery } from "utilities/routes"
import SubmitButton from "components/Buttons/SubmitButton"
import Select from "components/ReactHookForm/Select"
import {
  FEATURE_OPTIONS,
  INDUSTRY_OPTIONS,
  SOURCE_OPTIONS,
  TEAM_SIZE_OPTIONS,
  YEARLY_EVENTS_OPTIONS,
} from "utilities/enums"
import Checkboxes from "components/ReactHookForm/Checkboxes"
import * as Yup from "yup"
import { putAccount } from "api/accounts"
import { useIntercom } from "react-use-intercom"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"

const CompleteRegistration = () => {
  const history = useHistory()
  const { update: updateIntercom } = useIntercom()
  const query = useQuery()
  const plan = query.get("plan")

  const onSubmit = (params) => putAccount(params).then(() => {
    history.push(plan ? upgradePath(plan) : eventsPath())
    updateIntercom({ hideDefaultLauncher: false })
  })
  const CompleteRegistrationSchema = Yup.object().shape({
    industry: Yup.string().required(),
    expectedYearlyEvents: Yup.string().required(),
    expectedTeamSize: Yup.string().required(),
    expectedFeatures: Yup.array().of(Yup.string()).min(1),
    referralSource: Yup.string().required(),
  })

  const defaultValues = {
    industry: "",
    expectedYearlyEvents: "",
    expectedTeamSize: "",
    expectedFeatures: [],
    referralSource: "",
  }

  const { control, handleSubmit, formState: { isValid, isSubmitting } } = useForm({
    defaultValues,
    resolver: yupResolver(CompleteRegistrationSchema),
    mode: "onChange",
  })

  const form = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={6}>
        <Select
          label="What is your industry?"
          name="industry"
          control={control}
          options={INDUSTRY_OPTIONS.map((option) => ({ label: option, value: option }))}
          includeBlank
          vertical
        />
        <Select
          label="How many events do you organize each year?"
          name="expectedYearlyEvents"
          control={control}
          options={YEARLY_EVENTS_OPTIONS.map((option) => ({ label: option, value: option }))}
          includeBlank
          vertical
        />
        <Select
          label="How many people (including yourself) help manage your events?"
          name="expectedTeamSize"
          control={control}
          options={TEAM_SIZE_OPTIONS.map((option) => ({ label: option, value: option }))}
          includeBlank
          vertical
        />
        <Checkboxes
          name="expectedFeatures"
          control={control}
          label="Which features are you looking to use?"
          items={FEATURE_OPTIONS.map((option) => ({ id: option, value: option }))}
          itemComponent={({ value }) => value}
          vertical
        />
        <Select
          label="How did you hear about Diobox?"
          name="referralSource"
          control={control}
          options={SOURCE_OPTIONS.map((option) => ({ label: option, value: option }))}
          includeBlank
          vertical
        />
        <Box pt={10}>
          <SubmitButton
            text="Get Started"
            disabled={!isValid}
            submitting={isSubmitting}
          />
        </Box>
      </VStack>
    </form>
  )

  return (
    <AuthenticationWrapper
      pageTitle="Complete Your Registration"
      body={form}
      header={null}
      description={<Text fontSize="2xl" align="center">A few more questions to optimize your account</Text>}
    />
  )
}

export default CompleteRegistration
