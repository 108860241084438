import React from "react"
import { updateFormTranslations } from "context/actions"
import RegistrationFormsModal from "modals/EditMessaging/RegistrationFormsModal"
import RsvpFormsModal from "modals/EditMessaging/RsvpFormsModal"
import TicketFormsModal from "modals/EditMessaging/TicketFormsModal"
import WaitlistFormsModal from "modals/EditMessaging/WaitlistFormsModal"
import { useQueryClient } from "react-query"
import {
  DioboxEvent, Form, FormType, Translations,
} from "sharedTypes"
import SurveyFormsModal from "./SurveyFormsModal"

type Props = {
  formType: FormType
  onClose: () => void
  event: DioboxEvent
  defaultTranslations: Translations
  updateEvent: (params: {}) => Promise<void>
  form: Form
}
const EditMessaging = ({
  formType, onClose, event, defaultTranslations, updateEvent, form,
}: Props) => {
  const queryClient = useQueryClient()

  const handleSubmit = async (values) => {
    await updateEvent(values)
    onClose()

    updateFormTranslations(queryClient, form, values.translations)
  }

  const ModalComponent = () => {
    switch (formType) {
      case FormType.Ticket:
        return TicketFormsModal
      case FormType.RSVP:
        return RsvpFormsModal
      case FormType.Registration:
        return RegistrationFormsModal
      case FormType.Waitlist:
        return WaitlistFormsModal
      case FormType.Survey:
        return SurveyFormsModal
      default:
        return TicketFormsModal
    }
  }

  const Modal = ModalComponent()

  return (
    <Modal
      onClose={onClose}
      event={event}
      title={`${formType} Forms`}
      onSubmit={handleSubmit}
      defaultTranslations={defaultTranslations}
    />
  )
}

export default EditMessaging
