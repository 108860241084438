import React from "react"
import * as Yup from "yup"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import DatePickerField from "components/ReactHookForm/DatePickerField"
import {
  Text,
  Box,
  InputGroup,
  Input,
} from "@chakra-ui/react"
import { asDateWithTZ } from "utilities/strings"
import { DioboxEvent, Email } from "sharedTypes"
import { useQueryClient } from "react-query"
import { putInvitation } from "api/emails"
import { updateEmail } from "context/actions"
import { successToast } from "utilities/toasts"
import timeZones from "utilities/timeZones"
import UpgradeAlert from "components/elements/UpgradeAlert"
import useCurrentAccount from "services/useCurrentAccount"
import _ from "lodash"
import AlertBox, { AlertBoxType } from "components/elements/AlertBox/AlertBox"
import { useHistory } from "react-router-dom"
import { eventEmailRecipientsPath } from "utilities/routes"

type Props = {
  event: DioboxEvent
  email: Email
  onHide: () => void
  onSave?: () => void
}

const ScheduleSendEmail = ({
  event, email: { id: emailId, sendTo }, onHide, onSave,
}: Props) => {
  const { id: eventId, timezone } = event
  const queryClient = useQueryClient()
  const { subscription } = useCurrentAccount()
  const history = useHistory()
  const isCorporate = subscription?.type === "Corporate"
  const isAtLeastOneRecipientGroupSelected = !_.isEmpty(sendTo)

  const handleSubmit = ({ sendScheduledAt }) => putInvitation(eventId, emailId, {
    sendScheduledAt,
  })
    .then(({ data }) => {
      updateEmail(queryClient, eventId, data)
      successToast({
        title: `Email is scheduled to be sent on ${asDateWithTZ(sendScheduledAt, timezone)}`,
        status: "success",
      })
      onHide()
      onSave?.()
    })

  const handleAlertBoxButtonClick = () => {
    onHide()
    history.push(eventEmailRecipientsPath(eventId, emailId))
  }

  const validationSchema = Yup.object().shape({
    sendScheduledAt: Yup.date().required(),
  })

  return (
    <ModalForm
      title="Schedule Send"
      onClose={onHide}
      initialValues={{ sendScheduledAt: new Date() }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      submitText="Schedule Send"
      size="lg"
      h="fit-content"
      submitIsDisabled={!isCorporate || !isAtLeastOneRecipientGroupSelected}
    >
      {({ control }) => (
        <Box>
          {!isCorporate && <UpgradeAlert text="Email Scheduler requires a Corporate Plan" mb={6} />}
          {isCorporate && !isAtLeastOneRecipientGroupSelected && (
            <AlertBox
              type={AlertBoxType.Verification}
              text="Cannot Schedule Email"
              buttonText="Select Recipients"
              onClick={handleAlertBoxButtonClick}
              mb={6}
            />
          )}
          <Box h={350}>
            <DatePickerField
              open
              shouldCloseOnSelect={false}
              showTimeSelect
              name="sendScheduledAt"
              placeholder="Send scheduled at"
              control={control}
              minDate={new Date()}
              customInput={<CustomInput timezone={timezone} />}
              calendarOffset={20}
            />
          </Box>
        </Box>
      )}
    </ModalForm>
  )
}

const CustomInput = (props) => {
  const { timezone } = props

  return (
    <>
      <InputGroup justifyContent="center">
        <Input
          type="text"
          w="80"
          textAlign="center"
          bg="white"
          variant="flushed"
          color="gray.700"
          fontSize="xl"
          {...props}
        />
      </InputGroup>
      <TimeZone timeZone={timezone} />
    </>
  )
}

const TimeZone = ({ timeZone }) => (
  <Text color="gray.600" mt={2} align="center">
    {timeZones.find((tz) => tz.timeZoneId === timeZone)?.label}
  </Text>
)

export default ScheduleSendEmail
