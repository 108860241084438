import React from "react"
import { Image, Text } from "@chakra-ui/react"
import { ModalName } from "sharedTypes"
import useModal from "services/useModal"
import SectionV2 from "components/layout/SectionV2"

type Props = {
  coverImageUrl: string;
}

const EventImage = ({ coverImageUrl }: Props) => {
  const showModal = useModal()

  const content = coverImageUrl
    ? (
      <Image
        w="100%"
        borderRadius="lg"
        src={coverImageUrl}
        alt="website"
        mt={6}
        h={72}
        objectFit="cover"
      />
    )
    : <Text>No Event Image</Text>

  return (
    <SectionV2
      title="Hero Image"
      subtitle="Upload a high-resolution photo or illustration that represents your event.
      Keep in mind that depending onthe selected template and the user&apos;s device, this
      image may be cropped and displayed in different sizes."
      onEdit={() => { showModal(ModalName.EventImage) }}
    >
      {content}
    </SectionV2>
  )
}

export default EventImage
