import React from "react"
import { Button } from "@chakra-ui/react"

type Props = {
  color: string
  onSelect: (newColor: string) => void
  selected?: boolean
}

const ColorCircle = ({ color, onSelect, selected }: Props) => (
  <Button
    p={0}
    w={7}
    h={7}
    minWidth="none"
    borderRadius="full"
    bgColor={`${color}`}
    value={color}
    onClick={(e) => onSelect(e.currentTarget.value)}
    borderColor="gray.400"
    borderWidth={1}
    boxShadow={selected ? "outline" : "none"}
    _hover={{
      bg: "auto",
      opacity: "0.5",
    }}
    _focus={{}}
  />
)

export default ColorCircle
