import React from "react"
import { Address } from "sharedTypes"

type Props = {
  value: Address;
}

const AddressField = ({
  value: {
    line1, line2, city, state, zip, country,
  },
}: Props) => (
  <>
    {line1 && <>{ line1 }<br /></>}
    {line2 && <>{ line2 }<br /></>}
    {(city || state || zip) && <>{city && <>{city},</>} {state} {zip}<br /></>}
    {country && <>{ country } <br /></>}
  </>
)

export default AddressField
