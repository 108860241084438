import React from "react"
import { Text } from "@chakra-ui/react"
import Linkify from "react-linkify"

type Props = {
  text: string
}

const Body = ({ text }: Props) => (
  <Linkify>
    <Text whiteSpace="pre-wrap">{text}</Text>
  </Linkify>
)

export default Body
