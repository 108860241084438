import React from "react"
import { Flex, Text, Button } from "@chakra-ui/react"
import Icon from "./elements/Icon"

  type Props = {
    currentPage: number;
    pageCount: number;
    onChangePage: (currentPage: number) => void;
    showPageInfo?: boolean;
  }

const PaginationButtons = ({
  currentPage, pageCount, onChangePage, showPageInfo = false,
}: Props) => (
  <Flex alignItems="center">
    <Button
      variant="ghost"
      size="sm"
      mr={2}
      onClick={() => onChangePage(currentPage > 0 ? (currentPage - 1) : currentPage)}
      isDisabled={currentPage === 1}
    >
      <Icon icon="prev-arrow" />
    </Button>
    {showPageInfo && <Text>Page {currentPage} of {pageCount}</Text>}
    <Button
      variant="ghost"
      size="sm"
      ml={2}
      pr={0}
      onClick={() => onChangePage(currentPage < pageCount ? (currentPage + 1) : currentPage)}
      isDisabled={currentPage === pageCount}
    >
      <Icon icon="next-arrow" />
    </Button>
  </Flex>
)

export default PaginationButtons
