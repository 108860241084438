import { useInfiniteQuery, useQuery } from "react-query"
import { getEventQuestion, getEventQuestionResponses, getEventQuestions } from "api/forms"
import { FormQuestion } from "../sharedTypes"

export const eventQuestionsCacheKey = (eventId: string) => ["eventQuestions", eventId]
export const eventQuestionCacheKey = (questionId: string, page?: number) => ["eventQuestion", questionId, page]

export const useEventQuestions = (eventId: string) => {
  const {
    data, fetchNextPage, isLoading,
  } = useInfiniteQuery(
    eventQuestionsCacheKey(eventId),
    ({ pageParam = 1 }) => getEventQuestions(eventId, pageParam),
    {
      getNextPageParam: (
        lastPage,
      ) => (lastPage.data.meta.nextPage ?? undefined),
    },
  )

  const questions: FormQuestion[] = data?.pages.reduce(
    (prev, curr) => ([...prev, ...curr.data.data]),
      [] as FormQuestion[],
  ) || []

  return {
    questions, fetchNextPage, isLoading,
  }
}

export const useEventQuestion = (eventId: string, questionId: string) => useQuery(
  eventQuestionCacheKey(questionId),
  () => getEventQuestion(eventId, questionId).then(({ data }) => data),
  { enabled: Boolean(questionId) },
)

export const useEventQuestionResponsesPaginated = (
  eventId: string,
  questionId: string,
  page: number,
) => useQuery(
  eventQuestionCacheKey(questionId, page),
  () => getEventQuestionResponses(eventId, questionId, page).then(({ data }) => data),
  { keepPreviousData: true },
)
