import React from "react"
import {
  eventPath,
} from "utilities/routes"
import Avatar from "components/elements/Avatar"
import { DioboxEvent } from "sharedTypes"
import { Box, Heading } from "@chakra-ui/react"
import NavLink from "../NavLink"
import MenuItems from "./MenuItems"

interface Props {
  event: DioboxEvent
  compact: boolean
}

const EventSidebar = ({ event, compact }: Props) => (
  <Box pt={4} borderTopWidth={1}>
    <HeaderItem event={event} compact={compact} />
    <MenuItems event={event} compact={compact} />
  </Box>
)

type HeaderItemProps = {
  event: DioboxEvent
  compact: boolean
}

const HeaderItem = ({ event, compact }: HeaderItemProps) => (
  <NavLink
    to={eventPath(event.id)}
    exact
    borderLeftWidth="5px"
    borderColor="gray.900"
    activeProps={{ borderColor: "blue.500", bg: "black" }}
    display="flex"
    alignItems="center"
    px={3}
    py={4}
    mb={2}
  >
    <Avatar
      borderRadius="base"
      width="40px"
      height="40px"
      src={event.website?.coverImageUrl}
      name={event.title}
      alignSelf="flex-start"
    />
    {!compact && (
    <Heading
      as="h1"
      fontSize="md"
      color="white"
      ml={2}
    >
      {event.title}
    </Heading>
    )}
  </NavLink>
)

export default EventSidebar
