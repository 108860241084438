import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { Control } from "react-hook-form"
import SubmitButton from "components/Buttons/SubmitButton"
import { SENDER_PHONE_NUMBER } from "utilities/constants"
import PhoneNumberField from "components/inputs/PhoneNumberField"

type Props = {
  control: Control<{
    body: string;
    to: string;
  }>
  isSubmitting: boolean
  disabled: boolean
}

const SendTestMessage = ({
  control, isSubmitting, disabled,
}: Props) => (
  <Box py={8} borderBottomWidth={1} px={6}>
    <Text mb={2}>Send a Test Message</Text>
    <PhoneNumberField control={control} name="to" placeholder={SENDER_PHONE_NUMBER} />
    <Flex justifyContent="flex-end">
      <SubmitButton submitting={isSubmitting} disabled={disabled} text="Send Message" size="sm" />
    </Flex>
  </Box>
)

export default SendTestMessage
