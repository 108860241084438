import React from "react"
import {
  Box,
  FormControl, FormLabel, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack,
} from "@chakra-ui/react"
import { Control, useController } from "react-hook-form"

type Props = {
  name: string
  control: Control
  disabled?: boolean
}

const Scale = ({
  name, control, disabled,
}: Props) => {
  const { field: { value: fieldValue }, field } = useController<any>({ name, control })

  return (
    <FormControl display="flex" isDisabled={disabled} py={4}>
      <FormLabel fontWeight="bold" w="25%">Scale</FormLabel>
      <Box w="100%" pr={2}>
        <Slider
          {...field}
          onChange={(value: number) => field.onChange(value)}
          maxW={56}
        >
          <SliderMark
            value={fieldValue}
            textAlign="center"
            bg="blue.500"
            color="white"
            mt="-10"
            ml="-5"
            w="12"
          >
            {fieldValue}%
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </Box>
    </FormControl>
  )
}

export default Scale
