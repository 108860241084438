import React from "react"
import useMaintenanceStatus from "../services/useMaintenanceStatus"
import MaintenanceView from "./MaintenanceView"

const MaintenanceProvider = ({ children }) => {
  const ongoingMaintenance = useMaintenanceStatus(({ maintenanceStatus }) => maintenanceStatus)

  if (ongoingMaintenance) {
    return <MaintenanceView />
  }

  return children
}

export default MaintenanceProvider
