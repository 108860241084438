import React from "react"
import useModal from "services/useModal"
import { DioboxEvent, ModalName } from "sharedTypes"
import { useIntercom } from "react-use-intercom"
import pluralize from "pluralize"
import { Box, Flex } from "@chakra-ui/react"
import EventPage, { EventPageHeader } from "components/layout/EventPage"
import HorizontalStat from "components/elements/HorizontalStat"
import Button from "components/Buttons/Button"
import EventResourcesView from "components/layout/EventResourcesView"
import EventResourcesViewListItem from "components/EventResourcesViewListItem"
import SelectPrompt from "components/SelectPrompt"
import { useParams } from "react-router-dom"
import { eventTextMessagePath } from "utilities/routes"
import { usePaginatedTextMessages } from "queries/texts"
import Spinner from "components/elements/Spinner"
import useCurrentPlanType from "utilities/useCurrentPlanType"
import PendingTextingVerificationAlert from "./PendingTextingVerificationAlert"
import NoTexts from "./NoTexts"
import SelectedTextMessage from "./SelectedTextMessage/SelectedTextMessage"

type Props = {
  enabled: boolean
  event: DioboxEvent
}

const Texts = ({
  enabled,
  event: { counts: { texts: textCount } },
  event,
}: Props) => {
  const { show: showIntercom } = useIntercom()
  const { eventId, textMessageId } = useParams<{ eventId: string, textMessageId: string }>()
  const showModal = useModal()
  const { isLoading, fetchNextPage, texts } = usePaginatedTextMessages(eventId)
  const { isCorporate } = useCurrentPlanType()

  if (isLoading) {
    return <Spinner />
  }

  if (textCount === 0) {
    return <NoTexts enabled={enabled} />
  }

  return (
    <EventPage
      header={(
        <EventPageHeader
          icon="texts-nav"
          title="Texts"
          items={(
            <>
              <HorizontalStat label={pluralize("Campaign", textCount)} value={textCount} />
              <Flex>
                <Button leftIcon="plus" onClick={() => { showModal(ModalName.NewTextMessage) }} size="sm">
                  Text Message
                </Button>
              </Flex>
            </>
            )}
        />
        )}
      body={(
        <EventResourcesView
          onScrollEnd={fetchNextPage}
          listHeader={
              !enabled ? (
                <Box p={4}>
                  <PendingTextingVerificationAlert contactUs={showIntercom} />
                </Box>
              ) : null
            }
          listItems={texts.map(({ externalId, name, sentAt }) => (
            <EventResourcesViewListItem
              id={externalId}
              selectedListItemId={textMessageId}
              path={eventTextMessagePath(eventId, externalId)}
              icon="text-sms"
              title={name}
              sentOn={sentAt}
            />
          ))}
          showBorder={false}
          bgColor={textMessageId ? "gray.50" : "white"}
          selectedItem={textMessageId ? (
            <SelectedTextMessage
              textMessageId={textMessageId}
              event={event}
              enabled={enabled}
              isCorporate={isCorporate}
            />
          ) : <SelectPrompt text="Select a Text message" />}
        />
        )}
    />
  )
}

export default Texts
