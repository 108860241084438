import React, { useState } from "react"
import { Box, Flex } from "@chakra-ui/react"
import EventPage, { EventPageHeader } from "components/layout/EventPage"
import HorizontalStat from "components/elements/HorizontalStat"
import Button from "components/Buttons/Button"
import useModal from "services/useModal"
import pluralize from "pluralize"
import {
  AccountConfiguration,
  DioboxEvent,
  EventCounts,
  FormSortOption,
  ModalName,
} from "sharedTypes"
import { useParams, useRouteMatch } from "react-router-dom"
import { errorToast } from "utilities/toasts"
import { MAX_FORMS_PER_EVENT } from "utilities/constants"
import MenuWithOptionGroups, { MenuWithOptionGroupType } from "components/MenuWithOptionGroups"
import EventForms from "./EventForms"

interface Props {
  event: DioboxEvent
  counts: EventCounts
  updateEvent: (params: {}) => Promise<void>
  accountConfiguration: AccountConfiguration
}

const Forms = ({
  event,
  counts,
  updateEvent,
  accountConfiguration,
}: Props) => {
  const showModal = useModal()
  const { path } = useRouteMatch()
  const { translations: defaultTranslations } = accountConfiguration
  const { forms: formsCount } = counts
  const { formId } = useParams<{ formId: string}>()
  const [formSort, setFormSort] = useState(FormSortOption.CreatedAtAsc)

  const handleClick = () => {
    if (formsCount >= MAX_FORMS_PER_EVENT) {
      errorToast({ title: `You can create up to ${MAX_FORMS_PER_EVENT} Forms per event` })

      return
    }

    showModal(ModalName.FormForm)
  }

  const viewButtonGroups = [{
    groupTitle: "Sort By",
    type: MenuWithOptionGroupType.Radio,
    defaultValue: formSort,
    onChange: (sortOption) => setFormSort(sortOption),
    options: [
      { title: "Date Created (Ascending)", value: FormSortOption.CreatedAtAsc },
      { title: "Date Created (Descending)", value: FormSortOption.CreatedAtDesc },
      { title: "Form Name", value: FormSortOption.Name },
      { title: "Form Type", value: FormSortOption.Type },
      { title: "Form Visibility", value: FormSortOption.Visibility },
    ],
  }]

  return (
    <EventPage
      header={(
        <EventPageHeader
          icon="forms"
          title="Forms"
          items={(
            <>
              <HorizontalStat label={pluralize("Form", formsCount)} value={formsCount} />
              <Flex>
                <MenuWithOptionGroups title="View" optionGroups={viewButtonGroups} />
                <Box ml={4}>
                  <Button leftIcon="plus" onClick={handleClick} size="sm">
                    Form
                  </Button>
                </Box>
              </Flex>
            </>
          )}
        />
      )}
      body={(
        <EventForms
          event={event}
          counts={counts}
          formId={formId}
          path={path}
          updateEvent={updateEvent}
          defaultTranslations={defaultTranslations}
          sort={formSort}
        />
      )}
    />
  )
}

export default Forms
