import React from "react"
import {
  Route, Switch, useHistory, useLocation, useParams,
} from "react-router-dom"
import {
  eventSubmissionsPath, eventDataCollectionQuestionPath, eventSubmissionPath,
  eventQuestionsPath,
} from "utilities/routes"
import {
  DioboxEvent, FormQuestion, ModalName, SubmissionsSubpath, TabButton,
} from "sharedTypes"
import EventPage, { EventPageHeader } from "components/layout/EventPage"
import HorizontalStat from "components/elements/HorizontalStat"
import { asMoney, asNumber } from "utilities/strings"
import pluralize from "pluralize"
import Button from "components/Buttons/Button"
import useModal from "services/useModal"
import HeaderTabs from "components/elements/HeaderTabs"
import NavigationTabButton from "components/Navigation/HeaderTabs/NavigationTabButton"
import QuestionsAndResponses from "./QuestionsAndResponses/QuestionsAndResponses"
import FormSubmissions from "./FormSubmissions/FormSubmissions"

interface Props {
  event: DioboxEvent
}

const Submissions = ({
  event:
  {
    id: eventId,
    counts: {
      questions, responses, netSales, formSubmissionLineItems: {
        registration = 0, rsvp = 0, ticket = 0, waitlist = 0,
      },
    },
    currency,
  },
  event,
}: Props) => {
  const location = useLocation()
  const showModal = useModal()
  const history = useHistory()
  const { subpath } = useParams<{ subpath: string }>()

  const tabs = [
    { id: 0, title: "Form Submissions & Ticket Orders", iconName: "orders-outline" },
    {
      id: 1, title: "Questions & Responses", subpath: SubmissionsSubpath.Questions, iconName: "question-outline",
    },
  ]

  // If there's no active tab, default to the first tab
  const activeTab = tabs.find((tab) => tab.subpath === subpath) ?? tabs[0]
  const handleOnSuccess = ({ id }: FormQuestion) => {
    history.push(eventDataCollectionQuestionPath(eventId, id))
  }

  const handleClickAddQuestion = () => {
    showModal(ModalName.QuestionForm, { onSuccess: handleOnSuccess })
  }

  const responsesIsAcitve = activeTab.id === 1

  const statbar = (responsesIsAcitve && Boolean(questions))
    ? (
      <>
        <HorizontalStat label={pluralize("Question", questions)} value={asNumber(questions)} />
        <HorizontalStat label="Responses" value={asNumber(responses)} />
        <Button
          leftIcon="plus"
          onClick={handleClickAddQuestion}
          size="sm"
        >
          Question
        </Button>
      </>
    )
    : (
      <>
        {registration > 0 && <HorizontalStat label="Registrations" value={asNumber(registration)} />}
        {rsvp > 0 && <HorizontalStat label="RSVPs" value={asNumber(rsvp)} />}
        {waitlist > 0 && <HorizontalStat label="Waitlist" value={asNumber(waitlist)} />}
        {ticket > 0 && <HorizontalStat label="Tickets" value={asNumber(ticket)} />}
        {netSales > 0 && <HorizontalStat label="Net sales" value={asMoney(netSales, currency)} />}
      </>
    )

  return (
    <EventPage
      header={(
        <EventPageHeader
          icon="data-collection"
          title="Submissions"
          subtitle={(
            <HeaderNav
              eventId={eventId}
              activeTab={activeTab}
              tabs={tabs}
            />
          )}
          items={statbar}
        />
      )}
      body={(
        <Switch location={location}>
          <Route
            path={`${eventQuestionsPath(":eventId")}/:questionId?`}
          >
            <QuestionsAndResponses event={event} />
          </Route>
          <Route path={`${eventSubmissionPath(":eventId")}/:submissionId?`}>
            <FormSubmissions event={event} />
          </Route>
        </Switch>
      )}
    />
  )
}

type HeaderNavProps = {
  eventId: string
  tabs: TabButton<SubmissionsSubpath>[]
  activeTab: TabButton<SubmissionsSubpath>
}

const HeaderNav = ({ eventId, tabs, activeTab }: HeaderNavProps) => (
  <HeaderTabs>
    {tabs.map(({
      id, title, iconName, subpath,
    }) => (
      <NavigationTabButton
        title={title}
        iconName={iconName}
        active={activeTab?.id === id}
        path={eventSubmissionsPath(eventId, subpath)}
      />
    ))}

  </HeaderTabs>
)

export default Submissions
