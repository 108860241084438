import React from "react"
import {
  accountPath, accountPlanPath,
  accountTeamPath, eventsFuturePath, eventsPastPath, eventsPath,
} from "utilities/routes"
import { useLocation } from "react-router-dom"
import useCurrentAccount from "services/useCurrentAccount"
import NavItem from "../NavItem"
import SubNavItem from "../NavItem/SubNavItem"

const RootMenu = () => {
  const { pathname } = useLocation()
  const { admin } = useCurrentAccount()

  return (
    <>
      <NavItem
        icon="events"
        title="Events"
        path={eventsPath()}
        showSubMenu={[eventsPath(), eventsFuturePath(), eventsPastPath()].includes(pathname)}
      >
        <SubNavItem
          icon="upcoming-events"
          title="Upcoming Events"
          path={eventsFuturePath()}
        />
        <SubNavItem
          icon="past-events"
          title="Past Events"
          path={eventsPastPath()}
        />
      </NavItem>
      {(admin) && (
      <NavItem
        icon="settings"
        title="Account"
        path={accountPath()}
        showSubMenu={pathname.indexOf("/account") === 0}
      >
        <SubNavItem
          icon=""
          title="Plan &amp; Billing"
          path={accountPlanPath()}
        />
        <SubNavItem
          icon=""
          title="Team Members"
          path={accountTeamPath()}
        />
      </NavItem>
      )}
    </>
  )
}

export default RootMenu
