import React, { useCallback } from "react"
import { debounce } from "lodash"
import { Box, Flex } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { matchPath, useHistory } from "react-router-dom"
import { useCurrentEvent } from "queries/events"
import { useEventGuestPaths } from "utilities/routes"
import Search from "../Search"
import Menu from "../../Menu"

type Props = {
  loading: boolean;
  searchString: string;
  onSearch: (query: string) => void;
  order: string;
  onSetOrder: (order: string) => void;
  allSelected?: boolean;
  toggleSelectAll?: () => void;
  showMenu?: boolean;
  clearSelectedGuestOnSearch?: boolean;
}

const ListHeader = ({
  loading,
  searchString,
  onSearch,
  order,
  onSetOrder,
  allSelected,
  toggleSelectAll,
  showMenu = true,
  clearSelectedGuestOnSearch = true,
}: Props) => {
  const { data: currentEvent } = useCurrentEvent()
  const history = useHistory()
  const { eventGuestsPath } = useEventGuestPaths()

  const searchGuests = (query) => {
    onSearch(query)
    if (
      clearSelectedGuestOnSearch
      && matchPath(history.location.pathname, "/events/:eventId/guests/:guestId")
    ) {
      history.push(eventGuestsPath(currentEvent.id))
    }
  }

  const debouncedSearchGuests = useCallback(debounce(searchGuests, 400), [])

  return (
    <Flex
      justify="space-between"
      align="center"
      borderBottomWidth={1}
      pos="relative"
      py={2}
      w="full"
    >
      <Search
        onSearch={debouncedSearchGuests}
        value={searchString}
        loading={loading}
        padded={!showMenu}
      />
      {showMenu && (
        <Menu
          order={order}
          onSetOrder={onSetOrder}
          allSelected={allSelected}
          toggleSelectAll={toggleSelectAll}
          button={(
            <Box borderLeftWidth={1} pl={3} pr={2} mr={3}>
              <Icon icon="dots-hamburger" size={5} color="gray.500" />
            </Box>
            )}
        />
      )}
    </Flex>
  )
}

export default ListHeader
