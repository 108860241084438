import { Box, Flex } from "@chakra-ui/react"
import React, { ReactNode } from "react"

type Props = {
  children: ReactNode
  showBorder?: boolean
}

const PageContent = ({ children, showBorder }: Props) => {
  const borderProps = showBorder ? { background: "white", borderWidth: 1, borderRadius: "md" } : {}

  return (
    <Flex
      p={6}
      minHeight="full"
      minWidth={460}
      maxWidth={750}
      margin="auto"
    >
      <Flex
        p={showBorder ? 6 : 0}
        w="full"
        justifyContent="center"
        alignContent="center"
        {...borderProps}
      >
        <Box w="full">
          {children}
        </Box>
      </Flex>
    </Flex>
  )
}

export default PageContent
