import React from "react"
import { refetchEventQuestions, updateEventQuestion } from "context/actions"
import { useQueryClient } from "react-query"
import { FormQuestion, QuestionTypes } from "sharedTypes"
import { useEventQuestion } from "queries/questions"
import { deleteEventQuestion, postEventQuestion, putEventQuestion } from "api/forms"
import QuestionModalForm from "./QuestionModalForm"

interface Props {
  eventId: string
  questionId: string
  onClose: () => void
  onSuccess?: (updatedQuestion?: FormQuestion) => void
}

const QuestionModal = ({
  eventId, questionId, onClose, onSuccess,
}: Props) => {
  const queryClient = useQueryClient()
  const { isLoading, data: question } = useEventQuestion(eventId, questionId)

  // Wait for the initial values to load
  if (isLoading) {
    return null
  }

  const initialValues = {
    title: question?.title ?? "",
    type: question?.type ?? QuestionTypes.SingleAnswer,
    choices: question?.choices ?? [""],
    required: question?.required ?? false,
  }

  const onSubmit = async (values) => {
    if (question) {
      await putEventQuestion(eventId, question.id, values).then(({ data: updatedQuestion }) => {
        onSuccess?.(updatedQuestion)
        updateEventQuestion(queryClient, question.id, values)
      })
    } else {
      await postEventQuestion(eventId, values)
        .then(({ data: newQuestion }) => {
          onSuccess?.(newQuestion)
        })
    }

    return refetchEventQuestions(queryClient, eventId).then(() => { onClose() })
  }

  const handleDelete = async () => {
    if (!question) {
      return
    }

    await deleteEventQuestion(eventId, question.id).then((async () => {
      await refetchEventQuestions(queryClient, eventId)
      onClose()
    }))
  }

  return (
    <QuestionModalForm
      title="Question Editor"
      initialValues={initialValues}
      onSave={onSubmit}
      onCancel={onClose}
      termsEnabled
      richTextEditor
      uploadEnabled
      textBlockEnabled={false}
      onDelete={question ? handleDelete : undefined}
    />
  )
}

export default QuestionModal
