import React from "react"
import Checkbox from "components/ReactHookForm/Checkbox"
import SwitchField from "components/ReactHookForm/SwitchField"
import { Box, Flex, Text } from "@chakra-ui/react"
import { Control, FieldValues, useWatch } from "react-hook-form"

type Props<T extends FieldValues> = {
  name: string
  control: Control<T, any>
  label: string
  disabled?: boolean
  requiredLocked?: boolean
}

const Field = <T extends FieldValues, >({
  name, control, label, disabled, requiredLocked,
}: Props<T>) => {
  const enabled = useWatch<any>({ control, name: `${name}Enabled` })

  return (
    <Flex
      align="center"
      justify="space-between"
      ml={6}
      py={3}
      borderBottomWidth={1}
      _last={{ borderBottomWidth: 0 }}
      color={!enabled ? "gray.400" : "auto"}
    >
      <Text as="span" fontSize="lg">{label}</Text>
      <Flex align="center">
        <Checkbox
          name={name ? `${name}Required` : ""}
          control={control}
          label="Required"
          disabled={disabled || !enabled || requiredLocked}
          mb={-2}
        />
        <Box ml={4}><SwitchField
          name={name ? `${name}Enabled` : ""}
          control={control}
          mb={0}
          disabled={disabled}
        />
        </Box>
      </Flex>
    </Flex>
  )
}

export default Field
