import React, { useRef } from "react"
import { Box, ChakraProvider, theme } from "@chakra-ui/react"
import MainRouter from "components/MainRouter/MainRouter"
import { IntercomProvider } from "react-use-intercom"
import SessionHydrater from "components/SessionHydrater"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import chakraTheme from "theme/chakraTheme"
import StripeProvider from "./components/StripeProvider"
import MaintenanceProvider from "./components/MaintenanceProvider"

const App = () => {
  const toastContainerRef = useRef<HTMLDivElement|null>(null)
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60,
        retry: false,
      },
    },
  })

  return (
    <ChakraProvider
      theme={chakraTheme}
      portalZIndex={theme.zIndices.popover}
      toastOptions={{
        portalProps: {
          containerRef: toastContainerRef,
        },
      }}
    >
      <IntercomProvider appId={import.meta.env.VITE_INTERCOM_APP_ID!}>
        <StripeProvider>
          <QueryClientProvider client={queryClient}>
            <MaintenanceProvider>
              <DndProvider backend={HTML5Backend}>
                <SessionHydrater>
                  <MainRouter />
                </SessionHydrater>
              </DndProvider>
            </MaintenanceProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </StripeProvider>
      </IntercomProvider>
      <Box ref={toastContainerRef} />
    </ChakraProvider>
  )
}

export default App
