import React from "react"
import { Flex, Text } from "@chakra-ui/react"
import URL from "components/elements/Link"
import { FormType } from "sharedTypes"
import { getFormExternalUrl } from "utilities/forms"

type Props = {
  type: FormType
  externalId: string;
}

const Link = ({ type, externalId }: Props) => {
  const isRSVPOrSurvey = [FormType.RSVP, FormType.Survey].includes(type)
  const link = getFormExternalUrl(externalId, isRSVPOrSurvey)

  return (
    <Flex flexDirection="column" justifyContent="flex-end">
      <URL isExternal href={link}><Text lineHeight={3}>{link}</Text></URL>
      {isRSVPOrSurvey && <Text position="relative" bottom={-3} fontSize="xs" color="gray.600" lineHeight={0}>Actual Form link will be generated uniquely for each guest.</Text>}
    </Flex>
  )
}

export default Link
