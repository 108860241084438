import React from "react"
import { Speaker } from "sharedTypes"
import _ from "lodash"
import * as Yup from "yup"
import { addSpeaker, removeSpeaker, updateSpeaker } from "context/actions"
import * as api from "api/websites"
import { useCurrentEvent } from "queries/events"
import { useQueryClient } from "react-query"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import ImageUpload from "components/ReactHookForm/ImageUpload"
import TextField from "components/ReactHookForm/TextField"
import TextArea from "components/ReactHookForm/TextArea"
import { successToast } from "utilities/toasts"
import extendFormValuesWithImages from "utilities/extendFormValuesWithImages"

type Props = {
  speaker?: Speaker;
  onHide: () => void;
}

const showUpdatedToast = () => successToast({ title: "Speakers updated" })

const SpeakerForm = ({
  speaker, onHide,
}: Props) => {
  const queryClient = useQueryClient()
  const { data: currentEvent } = useCurrentEvent()

  const create = (data) => api.postSpeaker(currentEvent.id, data)
    .then((response) => {
      addSpeaker(queryClient, currentEvent.id, response.data)
    })
    .then(showUpdatedToast)

  const deleteSpeaker = (speakerId) => api.deleteSpeaker(currentEvent.id, speakerId)
    .then(() => {
      removeSpeaker(queryClient, currentEvent.id, speakerId)
    })
    .then(showUpdatedToast)

  const update = (data) => api.putSpeaker(currentEvent.id, data.id, data)
    .then((response) => {
      updateSpeaker(queryClient, currentEvent.id, response.data)
    })
    .then(showUpdatedToast)

  const onSubmit = (values) => {
    const resetAndHide = () => {
      onHide()
    }

    if (speaker) {
      return update(values).then(resetAndHide)
    }
    const lastSpeaker = _.maxBy(currentEvent.website.speakers, "position")
    const position = ((lastSpeaker as any)?.position || 0) + 1

    const data = {
      ...values,
      position,
    }

    return create(data).then(resetAndHide)
  }

  const SpeakerSchema = Yup.object().shape({
    company: Yup.string(),
    description: Yup.string(),
    link: Yup.string(),
    name: Yup.string().required(),
    photo: Yup.mixed(),
    title: Yup.string(),
  })

  let initialValues
  if (speaker) {
    initialValues = extendFormValuesWithImages(speaker, ["photo"])
  } else {
    initialValues = {
      photo: "",
      name: "",
      company: "",
      title: "",
      description: "",
      link: "",
    }
  }

  return (
    <ModalForm
      title={`${speaker ? "Edit" : "Add"} Speaker`}
      onClose={onHide}
      initialValues={initialValues}
      validationSchema={SpeakerSchema}
      onSubmit={onSubmit}
      onDanger={speaker ? () => deleteSpeaker(speaker.id) : null}
    >
      {({ control }) => (
        <>
          <ImageUpload
            name="photo"
            control={control}
            label="Photo"
            imageUrl={speaker?.photoUrl}
            alt="Profile Picture"
            imageWidth={200}
            imageHeight={200}
            imageFit="cover"
          />
          <TextField name="name" control={control} label="Name" />
          <TextField name="title" control={control} label="Title" />
          <TextField name="company" control={control} label="Company" />
          <TextArea name="description" control={control} label="Description" />
          <TextField name="link" control={control} label="Link" />
        </>
      )}
    </ModalForm>
  )
}

export default SpeakerForm
