import React, { ReactNode } from "react"
import { Box, Flex } from "@chakra-ui/react"

type Props = {
  children: ReactNode
  header?: ReactNode
  noPadding?: boolean
}

const SubmissionCard = ({ children, header, noPadding = false }: Props) => (
  <Box
    borderWidth={1}
    borderRadius="lg"
    borderColor="gray.300"
    overflow="hidden"
  >
    {header && (
    <Flex
      bgColor="gray.50"
      borderBottomWidth={1}
      borderColor="gray.300"
      p={5}
    >
      {header}
    </Flex>
    )}
    <Box p={noPadding ? 0 : 5} bgColor="white">
      {children}
    </Box>
  </Box>
)

export default SubmissionCard
