import React from "react"
import { Box, Flex, FlexProps } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { Link } from "react-router-dom"
import { MenuItem } from "components/dialogs/Menu"

type Props = {
  icon: string;
  title: string;
  link?: string;
  href?: string;
  onClick?: () => void;
  hasSubmenu?: boolean;
} & FlexProps

const GuestMenuItem = ({
  icon, title, link, onClick, hasSubmenu, href, ...rest
}: Props) => {
  const menuItemProps = {
    as: link ? Link : A,
    to: link,
    onClick,
    href,
    target: href ? "_blank" : null,
    rel: href ? "noopener noreferrer" : undefined,
    ...rest,
  }

  return (
    <MenuItem p={0} as={Box}>
      <Flex
        px={5}
        py={2.5}
        color="blue.500"
        justify="space-between"
        align="center"
        w="full"
        {...menuItemProps}
      >
        <Box>
          <Icon icon={icon} size={5} mr={2} />
          {title}
        </Box>
        {hasSubmenu && <Icon icon="next-arrow" size={3} />}
      </Flex>
    </MenuItem>
  )
}

const A = ({ children, ...props }) => <a {...props}>{children}</a>

export default GuestMenuItem
