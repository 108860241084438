import React, { ReactNode } from "react"
import { Box, Stack, StackDivider } from "@chakra-ui/react"
import { useDrag } from "react-dnd"
import Icon from "components/elements/Icon"
import ConfirmDelete from "components/dialogs/Confirm/ConfirmDelete"
import { BuilderBlockType, DraggableItem, DraggableItemTypes } from "sharedTypes/builder"
import { getDeleteBlockTitle } from "utilities/objects"
import HoverArea from "./HoverArea"

export enum BlockInsertPosition {
  Top = "top",
  Bottom = "bottom",
}

type Props = {
  dragId: number
  onMove: (fromDragId: number, toDragId: number, position: BlockInsertPosition) => void
  onSelect: () => void
  selected: boolean
  onDelete: () => void
  children: ReactNode
  type: BuilderBlockType
  onDropInto?: (item: DraggableItem, blockId: number, position: BlockInsertPosition) => void
  onDuplicate?: () => void
}

const BuildingBlock = ({
  dragId,
  onMove,
  onSelect,
  selected,
  onDelete,
  children,
  type,
  onDropInto,
  onDuplicate,
}: Props) => {
  const [, drag, preview] = useDrag({
    type: DraggableItemTypes.REORDER,
    item: { dragId },
  })

  const handleClick = (e) => {
    if (
      e.target.dataset.selectBlockOnClick
    ) {
      onSelect()
    }
  }

  return (
    <Box
      ref={drag}
      onClick={handleClick}
      cursor="pointer"
      outline={selected ? "2px solid #3182CE" : undefined}
      position="relative"
      zIndex={selected ? 2 : undefined}
      _hover={{ outline: `2px solid ${selected ? "#3182CE" : "#C4C4C4"}`, zIndex: 3 }}
    >
      <HoverArea
        position={BlockInsertPosition.Top}
        onMove={onMove}
        onDropInto={onDropInto}
        dragId={dragId}
      />
      <HoverArea
        position={BlockInsertPosition.Bottom}
        onMove={onMove}
        onDropInto={onDropInto}
        dragId={dragId}
      />
      <Box ref={preview}>
        {children}
        <BlockControls
          show={selected}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
          type={type}
        />
      </Box>
    </Box>
  )
}

type BlockControlProps = {
  show: boolean
  onDuplicate?: () => void
  onDelete: () => void
  type: BuilderBlockType
}

const BlockControls = ({
  show, onDuplicate, onDelete, type,
}: BlockControlProps) => {
  if (!show) {
    return null
  }

  return (
    <Box
      position="absolute"
      right={-14}
      top={0}
      w={12}
      mr={1}
      bgColor="white"
      borderWidth={1}
      fontSize="xl"
      cursor="auto"
    >
      <Stack
        spacing={0}
        align="center"
        divider={<StackDivider w="50%" alignSelf="center" />}
      >
        <Icon icon="menu" m={4} cursor="move" />
        {onDuplicate && <Box p={3} onClick={onDuplicate} cursor="pointer"><Icon icon="copy" size={5} /></Box>}
        <ConfirmDelete
          type={getDeleteBlockTitle(type)}
          onClickConfirm={onDelete}
          description={type === BuilderBlockType.QUESTION ? "Deleting it will also remove all associated responses from guests. This action cannot be undone." : ""}
        >
          <Box p={3} cursor="pointer"><Icon icon="cross" color="red.500" size={5} /></Box>
        </ConfirmDelete>
      </Stack>
    </Box>
  )
}

export default BuildingBlock
