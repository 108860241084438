import React from "react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import { Text } from "@chakra-ui/react"
import { getTextReportExport } from "api/texts"

type Props = {
  eventId: string
  textMessageId: string
  emailAddress: string
  onHide: () => void
}

const ExportTextReport = ({
  eventId, textMessageId, emailAddress, onHide,
}: Props) => {
  const handleSubmit = async () => {
    await getTextReportExport(eventId, textMessageId)

    onHide()
  }

  return (
    <ModalForm
      onClose={onHide}
      title="Export Text Report"
      onSubmit={handleSubmit}
      submitText="Export"
    >
      <Text>
        A CSV file containing the recipients of this SMS and their deliverability data will be
        generated and sent to: <Text as="span" fontWeight="semibold">{emailAddress}</Text>
      </Text>
    </ModalForm>
  )
}

export default ExportTextReport
