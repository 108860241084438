import { useQuery } from "react-query"
import { getInvitation, getInvitations } from "api/emails"

export const emailsCacheKey = (eventId: string) => ["emails", eventId]
export const emailCacheKey = (emailId: number) => ["email", emailId.toString()]

export const useEmails = (
  eventId: string, options: {} = {},
) => useQuery(
  emailsCacheKey(eventId),
  () => getInvitations(eventId).then(({ data }) => data),
  options,
)

export const useEmail = (
  eventId: string, emailId: number,
) => useQuery(
  emailCacheKey(emailId), () => getInvitation(eventId, emailId).then(({ data }) => data),
)
