import React from "react"
import { Flex, Text } from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import StatusIndicator from "./StatusIndicator"

type Props = {
  published: boolean
  urlPath: string
  onOpen: () => void
}
const PreviewMode = ({ published, urlPath, onOpen }: Props) => (
  <>
    <Text fontWeight="semibold">Status</Text>
    <Flex justifyContent="space-between" align="baseline">
      <StatusIndicator
        published={published}
        urlPath={urlPath}
        dotSize={3}
        fontSize="medium"
        fontWeight="normal"
      />
      <Button onClick={onOpen} variant="ghost">Edit</Button>
    </Flex>
  </>
)

export default PreviewMode
