import React, { useEffect, useState } from "react"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import { ThemeColor } from "sharedTypes"
import { Radio, RadioGroup, Stack } from "@chakra-ui/react"

const THEME_OPTIONS = [
  { label: "Default", value: ThemeColor.Default },
  { label: "Neutral", value: ThemeColor.Neutral },
  { label: "Lapis", value: ThemeColor.Lapis },
  { label: "Flix", value: ThemeColor.Flix },
  { label: "Onyx", value: ThemeColor.Onyx },
  { label: "Custom", value: ThemeColor.Custom },
]

type Props = {
    watch: UseFormWatch<any>
    setValue: UseFormSetValue<any>
}

const ThemeSelector = ({ watch, setValue }: Props) => {
  const [selectedTheme, setSelectedTheme] = useState(THEME_OPTIONS[0].label)

  const themes = [
    {
      name: ThemeColor.Default,
      colors: [
        { key: "portalPrimaryColor", value: "#4A90E2" },
        { key: "portalTopNavBackgroundColor", value: "#F9F9F9" },
        { key: "portalTopNavTextColor", value: "#000000" },
        { key: "portalBackgroundColor", value: "#F9F9F9" },
      ],
    },
    {
      name: ThemeColor.Neutral,
      colors: [
        { key: "portalPrimaryColor", value: "#4D85BA" },
        { key: "portalTopNavBackgroundColor", value: "#F0ECE9" },
        { key: "portalTopNavTextColor", value: "#695845" },
        { key: "portalBackgroundColor", value: "#F6F4F2" },
      ],
    },
    {
      name: ThemeColor.Lapis,
      colors: [
        { key: "portalPrimaryColor", value: "#1163C4" },
        { key: "portalTopNavBackgroundColor", value: "#1163C4" },
        { key: "portalTopNavTextColor", value: "#FFFFFF" },
        { key: "portalBackgroundColor", value: "#F9F9F9" },
      ],
    },
    {
      name: ThemeColor.Flix,
      colors: [
        { key: "portalPrimaryColor", value: "#F20000" },
        { key: "portalTopNavBackgroundColor", value: "#00162C" },
        { key: "portalTopNavTextColor", value: "#FFFFFF" },
        { key: "portalBackgroundColor", value: "#F9F9F9" },
      ],
    },
    {
      name: ThemeColor.Onyx,
      colors: [
        { key: "portalPrimaryColor", value: "#3182CE" },
        { key: "portalTopNavBackgroundColor", value: "#141A16" },
        { key: "portalTopNavTextColor", value: "#FFFFFF" },
        { key: "portalBackgroundColor", value: "#141A16" },
      ],
    },
  ]

  useEffect(() => {
    const subscription = watch((data) => {
      const matchingTheme = themes.find(
        (theme) => theme.colors.every(
          (color) => data[color.key].toLowerCase() === color.value.toLowerCase(),
        ),
      )

      if (matchingTheme) {
        setSelectedTheme(matchingTheme.name)
      } else {
        setSelectedTheme(ThemeColor.Custom)
      }

      return () => subscription.unsubscribe()
    })
  }, [watch])

  useEffect(() => {
    const selectedColors = themes.find(
      ({ name: themeName }) => themeName === selectedTheme,
    )?.colors

    selectedColors?.forEach(({ key, value }) => setValue(key, value, { shouldDirty: true }))
  }, [selectedTheme])

  return (
    <RadioGroup onChange={setSelectedTheme} value={selectedTheme}>
      <Stack>
        {THEME_OPTIONS.map(({ label, value }) => <Radio w="fit-content" value={value}>{label}</Radio>)}
      </Stack>
    </RadioGroup>
  )
}

export default ThemeSelector
