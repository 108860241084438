import React, { useState } from "react"
import EditableSection from "components/layout/EditableSection"
import Button from "components/Buttons/Button"
import { Box, HStack, Text } from "@chakra-ui/react"
import { useCurrentEvent } from "queries/events"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextField from "components/ReactHookForm/TextField"
import SwitchField from "components/ReactHookForm/SwitchField"

type Props = {
  locationEnabled: boolean;
  locationVenue: string;
  locationLine1: string;
  locationLine2: string;
  locationLine3: string;
  updateWebsite: (params: {}) => Promise<void>;
}

const Location = ({
  locationEnabled, locationVenue, locationLine1, locationLine2, locationLine3, updateWebsite,
}: Props) => {
  const [edit, setEdit] = useState(false)

  return (
    <Box py={6}>
      <EditableSection
        title="Location Block"
        onEdit={() => { setEdit(true) }}
        columns
      >

        <Box color={!locationEnabled ? "gray.200" : ""}>
          <Text fontSize="2xl" fontWeight="semibold">{ locationVenue }</Text>
          <Text>{ locationLine1 }</Text>
          <Text>{ locationLine2 }</Text>
          <Text>{ locationLine3 }</Text>
        </Box>
        { edit && (
        <Edit {...{
          setEdit,
          locationEnabled,
          locationVenue,
          locationLine1,
          locationLine2,
          locationLine3,
          updateWebsite,
        }}
        />
        ) }
      </EditableSection>
    </Box>
  )
}

const Edit = ({
  setEdit,
  locationEnabled,
  locationVenue,
  locationLine1,
  locationLine2,
  locationLine3,
  updateWebsite,
}) => {
  const { data: currentEvent } = useCurrentEvent()

  const onSubmit = (values) => updateWebsite(values).then(() => {
    setEdit(false)
  })

  return (
    <ModalForm
      initialValues={{
        locationEnabled, locationVenue, locationLine1, locationLine2, locationLine3,
      }}
      onSubmit={onSubmit}
      title="Location Block"
      onClose={() => {
        setEdit(false)
      }}
    >
      { ({ control, watch, setValue }) => {
        const enabled = watch("locationEnabled")

        const setDefaultValues = () => {
          setValue("locationVenue", currentEvent.venue)
          setValue("locationLine1", currentEvent.addressLine1)
          setValue("locationLine2", currentEvent.addressLine2)
          setValue("locationLine3", `${currentEvent.city}, ${currentEvent.state} ${currentEvent.zipcode}`)
        }

        return (
          <>
            <SwitchField
              name="locationEnabled"
              control={control}
              label="Enabled"
            />
            <TextField
              name="locationVenue"
              control={control}
              label="Venue"
              disabled={!enabled}
            />
            <TextField
              name="locationLine1"
              control={control}
              label="Address Line 1"
              disabled={!enabled}
            />
            <TextField
              name="locationLine2"
              control={control}
              label="Address Line 2"
              disabled={!enabled}
            />
            <TextField
              name="locationLine3"
              control={control}
              label="Address Line 3"
              disabled={!enabled}
            />
            <HStack mt={6}>
              <Box w={1 / 4} />
              <Box w="100%">
                <Button
                  variant="outline"
                  onClick={setDefaultValues}
                  isDisabled={!enabled}
                >
                  Default Event Values
                </Button>
              </Box>
            </HStack>
          </>
        )
      }}
    </ModalForm>
  )
}

export default Location
