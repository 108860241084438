import React, { useEffect, useState } from "react"
import SearchField from "components/ReactHookForm/SearchField"

type Props = {
  onSearch: (searchString: string) => void;
  loading: boolean;
  value: string;
  padded?: boolean;
}

const Search = ({
  onSearch, loading, value: initialValue, padded,
}: Props) => {
  const [searchValue, setSearchValue] = useState(initialValue)

  useEffect(() => {
    setSearchValue(initialValue)
  }, [initialValue])

  const search = (value) => {
    setSearchValue(value)
    onSearch(value)
  }

  return (
    <SearchField
      onChange={(searchString) => search(searchString)}
      value={searchValue}
      loading={loading}
      variant="unstyled"
      padded={padded}
    />
  )
}

export default Search
