import React, { ReactNode, useState } from "react"
import * as Yup from "yup"
import { Flex, Spacer, Text } from "@chakra-ui/react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import Checkbox from "components/ReactHookForm/Checkbox"
import PasswordField from "components/ReactHookForm/PasswordField"
import { StringSchema, BooleanSchema } from "yup"
import _ from "lodash"
import { whatToDelete } from "./ConfirmDelete"

type Props = {
  children: ReactNode | ((props) => ReactNode)
  checkboxTexts: string[]
  onSubmit: (values) => any | Promise<any>;
  type?: string
  name?: string
  what?: string
  from?: string
  passwordRequired?: boolean
  warningText?: string
}

const ConfirmDeleteWithCheckboxes = ({
  children,
  checkboxTexts,
  onSubmit,
  type,
  name,
  what,
  from,
  passwordRequired,
  warningText,
}: Props) => {
  const [showModal, setShowModal] = useState(false)

  const confirmationText = <>Are you sure you want to delete {whatToDelete(type, name, what)}{from ? ` from ${from}` : ""}?</>

  let resolvedChildren = children
  if (typeof children === "function") {
    resolvedChildren = children({
      setShowModal,
    })
  }

  return (
    <Flex>
      {resolvedChildren as ReactNode}
      {showModal && (
      <DeleteModal
        hide={() => setShowModal(false)}
        type={type}
        passwordRequired={passwordRequired}
        checkboxTexts={checkboxTexts}
        confirmationText={confirmationText}
        warningText={warningText}
        onSubmit={onSubmit}
      />
      )}
    </Flex>
  )
}

type DeleteModalProps = {
  hide: () => void
  checkboxTexts: string[]
  confirmationText: ReactNode
  onSubmit: (values) => any | Promise<any>;
  type?: string
  passwordRequired?: boolean
  warningText?: string
}

const DeleteModal = ({
  hide,
  checkboxTexts,
  confirmationText,
  onSubmit,
  type,
  passwordRequired,
  warningText,
}: DeleteModalProps) => {
  const validationMap = new Map<string, BooleanSchema<boolean | undefined> |
  StringSchema<string | undefined>>()
  const initialValues = {}
  checkboxTexts.forEach((value) => {
    const formattedKey = _.camelCase(value)
    validationMap.set(formattedKey, Yup.boolean().required().oneOf([true]))
    initialValues[formattedKey] = false
  })
  if (passwordRequired) {
    const passwordKey = "password"
    validationMap.set(passwordKey, Yup.string().label(passwordKey).required())
    initialValues[passwordKey] = ""
  }
  const validationObject = Object.fromEntries(validationMap)
  const DeleteEntitySchema = Yup.object().shape(validationObject)

  return (
    <ModalForm
      title={`Delete ${type}`}
      onClose={hide}
      initialValues={initialValues}
      validationSchema={DeleteEntitySchema}
      onSubmit={onSubmit}
      submitText={`Delete ${type}`}
      submitColorScheme="button.danger"
      size="xl"
    >
      {({ control }) => (
        <>
          <Text fontSize="2xl" color="red.600" mb={5}>{confirmationText}</Text>
          <Text fontSize="xl" mb={6}>Read and check all boxes below:</Text>
          {checkboxTexts.map((item) => {
            const formattedKey = _.camelCase(item)

            return (
              <Checkbox
                key={formattedKey}
                name={formattedKey}
                control={control}
                label={item}
              />
            )
          })}
          {passwordRequired && (
          <>
            <Spacer py={2} />
            <PasswordField name="password" control={control} placeholder="Enter password" />
          </>
          )}
          {warningText && <Text fontWeight="bold">{warningText}</Text>}
        </>
      )}
    </ModalForm>
  )
}

export default ConfirmDeleteWithCheckboxes
