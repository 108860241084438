import React, { useCallback, useEffect } from "react"
import {
  Box, Flex, Stack, Text,
} from "@chakra-ui/react"
import { DioboxEvent, ModalName, TextMessage } from "sharedTypes"
import TextMessageEditor from "components/TextMessageEditor"
import { Control, UseFormWatch } from "react-hook-form"
import useModal from "services/useModal"
import _ from "lodash"
import PillButton from "components/Buttons/PillButton"
import { SENDER_PHONE_NUMBER } from "utilities/constants"
import EditableTextProperty from "../../components/EditableTextProperty"
import SelectedRecipients from "./SelectedRecipients"
import { TEXT_MESSAGE_BODY_CHARACTER_LIMIT } from "../SelectedTextMessage"

type Props = {
  control: Control
  selectedTextMessage: TextMessage
  handleSave: (textMessage: TextMessage) => void
  event: DioboxEvent
  watch: UseFormWatch<any>
  showSavedLabel: boolean
}

const FormContent = ({
  control, selectedTextMessage, handleSave, event, watch, showSavedLabel,
}: Props) => {
  const showModal = useModal()
  const debouncedSave = useCallback(_.debounce(handleSave, 2000), [handleSave])

  useEffect(() => {
    const subscription = watch((formData, { type }) => {
      if (type === "change") {
        debouncedSave(formData)
      }
    })

    return () => subscription.unsubscribe()
  }, [watch])

  const hasSelectedRecipients = selectedTextMessage?.recipients.length > 0

  return (
    <Stack spacing={8} my={3} ml={1.5}>
      <Flex alignItems="center">
        <Text fontWeight="bold" w="25%">Type</Text>
        <PillButton icon="text-sms" title="SMS" active cursor="auto" />
      </Flex>
      <Flex>
        <Text fontWeight="bold" w="25%">Sender Phone</Text>
        <Text>{SENDER_PHONE_NUMBER}</Text>
      </Flex>
      <EditableTextProperty
        label="Recipients"
        value={hasSelectedRecipients ? (
          <SelectedRecipients
            recipients={selectedTextMessage?.recipients}
            guestCount={selectedTextMessage?.guestCount}
            event={event}
          />
        ) : null}
        buttonText="Edit Recipients"
        onClick={() => showModal(
          ModalName.EditTextRecipients,
          { onSave: handleSave, textMessage: selectedTextMessage },
        )}
        emptyValueErrorMessage="Not selected"
      />
      <Box>
        <Flex justifyContent="space-between">
          <Text fontWeight="bold">Text Message</Text>
          {showSavedLabel && <Text color="gray.400">Saved</Text>}
        </Flex>
        <TextMessageEditor
          name="body"
          control={control}
          characterLimit={TEXT_MESSAGE_BODY_CHARACTER_LIMIT}
          event={event}
        />
      </Box>
    </Stack>
  )
}

export default FormContent
