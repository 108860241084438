import React, { useState, ReactNode } from "react"
import ReactCopyToClipboard from "react-copy-to-clipboard"
import Button from "components/Buttons/Button"

type Props = {
  children: ReactNode | ReactNode[];
  text: string;
  [x: string]: any;
}

const CopyToClipboard = ({ children, text, ...props }: Props) => {
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }

  const copy = children
  const done = "Copied!"

  return (
    <ReactCopyToClipboard text={text} onCopy={() => handleCopy()}>
      <Button {...props}>
        {copied ? done : copy}
      </Button>
    </ReactCopyToClipboard>
  )
}

export default CopyToClipboard
