import React from "react"
import { AgendaHeading } from "sharedTypes"
import * as Yup from "yup"
import { Text } from "@chakra-ui/react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextField from "components/ReactHookForm/TextField"
import DatePickerField from "components/ReactHookForm/DatePickerField"
import TextAreaField from "components/ReactHookForm/TextArea"

type Props = {
  agendaHeading?: AgendaHeading;
  onHide: () => void;
  onSubmit: (values: any) => Promise<any>;
  onDelete: (agendaRowId) => void;
}

const AgendaHeadingForm = ({
  agendaHeading,
  onHide,
  onSubmit,
  onDelete,
}: Props) => {
  const AgendaItemSchema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string(),
    startsAt: Yup.date(),
  })

  const initialValues = agendaHeading ? { ...agendaHeading } : {
    title: "",
    description: "",
    startsAt: "",
  }

  return (
    <ModalForm
      title={`${agendaHeading ? "Edit" : "Add"} Agenda Heading`}
      onClose={onHide}
      initialValues={initialValues}
      validationSchema={AgendaItemSchema}
      onSubmit={onSubmit}
      onDanger={agendaHeading ? () => onDelete(agendaHeading.id) : null}
    >
      {({ control }) => (
        <>
          <Text mb={2} fontSize="lg">
            An agenda heading is for grouping items together.
            It&apos;s typically used only if your event spans across several
            days to separate each day.
          </Text>
          <Text mb={5} fontSize="lg">
            The start time is only used to determine the position of the
            heading in agenda and is not displayed on the page.
          </Text>
          <DatePickerField showTimeSelect name="startsAt" control={control} label="Start time" utc />
          <TextField name="title" control={control} label="Title" />
          <TextAreaField name="description" control={control} label="Description" h={40} />
        </>
      )}
    </ModalForm>
  )
}

export default AgendaHeadingForm
