import React from "react"
import { Box, Stack, Text } from "@chakra-ui/react"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import TextField from "components/ReactHookForm/TextField"
import RichTextField, { RichTextFieldType } from "components/ReactHookForm/RichTextField"
import LabelRedefinition from "modals/FormForm/LabelRedefinition"
import { DioboxEvent, Translations } from "sharedTypes"
import ImageUpload from "components/ReactHookForm/ImageUpload"
import ImageUploadComponent from "components/ImageUploadComponent"
import extendFormValuesWithImages from "utilities/extendFormValuesWithImages"
import Scale from "components/ReactHookForm/Scale"
import TitleWithSubtitle from "../TitleWithSubtitle"
import ConfigBlock from "./ConfigBlock"
import UrlWithRegenerateLink from "../General/UrlWithRegenerateLink"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
  onChangeEventPreview: (event: Partial<DioboxEvent>) => void
}

const EventBranding = ({
  event: {
    portalShowEventImage,
    portalShowMap,
    portalEventImageUrl,
    portalEventImageScale,
    emailNotificationsEventName,
    portalDateTimeLocation,
    translations,
    portalMapUrl,
  },
  event,
  updateEvent,
  defaultTranslations,
  onChangeEventPreview,
}: Props) => {
  const values = {
    portalShowEventImage,
    portalShowMap,
    portalEventImageUrl,
    portalEventImageScale,
    emailNotificationsEventName,
    portalDateTimeLocation,
    translations,
    portalMapUrl,
  }

  return (
    <>
      <TitleWithSubtitle
        title="Event Branding"
        subtitle="The event branding is a section containing details about your event, which appears on top of all registration forms and confirmation pages."
      />
      <ContainedForm
        values={extendFormValuesWithImages(values, ["portalEventImage"])}
        onSubmit={updateEvent}
        noPadding
        name="Event Header"
        onChange={onChangeEventPreview}
      >
        {({ control, watch }) => {
          const eventImageEnabled = watch("portalShowEventImage")
          const mapEnabled = watch("portalShowMap")
          const formEventImageUrlValue = watch("portalEventImageUrl")

          return (
            <Stack py={4} spacing={4}>
              <ConfigBlock
                title="Event Image"
                switchName="portalShowEventImage"
                control={control}
                showSeparator
              >
                <Text>Upload an event branded image or logo.</Text>
                <Box mt={4} mb={4}>
                  <ImageUpload
                    name="portalEventImage"
                    control={control}
                    imageWidth="auto"
                    imageHeight={12}
                    imageUrl={portalEventImageUrl ? `${portalEventImageUrl}?w=320` : ""}
                    Component={UploadComponent}
                    disabled={!eventImageEnabled}
                  />
                </Box>
                {formEventImageUrlValue && (
                  <Box mt={6} maxW={96}>
                    <Scale control={control} name="portalEventImageScale" disabled={!eventImageEnabled} />
                  </Box>
                )}
              </ConfigBlock>
              <ConfigBlock title="Event Title" control={control} showSeparator>
                <Box mb={4}>
                  <Text>
                    Override the event name for a custom title in your
                    event header. This field is also used in the email
                    confirmations that are sent to guests.
                  </Text>
                </Box>
                <TextField variant="outline" name="emailNotificationsEventName" control={control} required />
              </ConfigBlock>
              <ConfigBlock title="Date, Time and Location" control={control} showSeparator>
                <Box mb={4}>
                  <Text>
                    Personalize (or translate) the wording of your event
                    date and location. Changing this will not affect the
                    actual event attributes.
                  </Text>
                </Box>
                <RichTextField
                  control={control}
                  name="portalDateTimeLocation"
                  type={RichTextFieldType.MINIMAL}
                  className="date-time-and-location-editor"
                />
              </ConfigBlock>
              <ConfigBlock title="Map" control={control} switchName="portalShowMap">
                <LabelRedefinition
                  defaultTranslation={defaultTranslations.viewMap}
                  name="translations.viewMap"
                  control={control}
                  disabled={!mapEnabled}
                />
                <Box mb={3} mt={2}>
                  <Text fontWeight="semibold" fontSize="lg">URL</Text>
                </Box>
                <UrlWithRegenerateLink control={control} event={event} mapEnabled={mapEnabled} />
              </ConfigBlock>
            </Stack>
          )
        }}
      </ContainedForm>
    </>
  )
}

const UploadComponent = (props) => <ImageUploadComponent {...props} uploadButtonText="Upload Event Image" removeButtonText="Remove" />

export default EventBranding
