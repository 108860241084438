import React from "react"
import { Flex, Button, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { inverseStyles } from ".."

type Props = {
  icon: string
  title: string
  text?: string
}

const SideToggle = ({
  icon, title, text,
}: Props) => (
  <Flex direction="column" align="center" h={20} pos="relative" top={4}>
    <Button
      w={12}
      h={12}
      borderRadius="full"
      fontSize="md"
      type="button"
      title={title}
      _hover={{ bg: "auto" }}
      {...inverseStyles}
    ><Icon icon={icon} size={6} />
    </Button>
    {text && <Text color="green.500">{text}</Text>}
  </Flex>
)

export default SideToggle
