import React from "react"
import Link from "components/elements/Link"
import useWebsiteUrl from "utilities/useWebsiteUrl"
import { Website } from "sharedTypes"

type Props = {
  website: Website
}

const WebsiteLink = ({ website }: Props) => {
  const url = useWebsiteUrl(website)

  return (
    <Link
      display="block"
      isExternal
      href={url}
    >
      {url}
    </Link>
  )
}

export default WebsiteLink
