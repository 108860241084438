import { groupBy, sum } from "lodash"
import React from "react"
import { Submission } from "sharedTypes"
import {
  Box, Flex, HStack, Stack, Text,
} from "@chakra-ui/react"
import { asDate, asMoney } from "utilities/strings"
import { submissionIsPaid } from "utilities/forms"
import SubmissionCard from "pages/EventShow/Submissions/FormSubmissions/FormSubmissionsDetailView/SubmissionCard"
import SubmissionPreference from "./SubmissionPreference"

type Props = {
  submission: Submission
}

const SubmissionSummaryCard = ({ submission }: Props) => {
  const groupedLineItems = groupBy(submission.lineItems, "form.id")
  const isSubmissionPaid = submissionIsPaid(submission.price)

  return (
    <SubmissionCard>
      <Stack spacing={5}>
        <Box>
          <Text fontSize="2xl">{submission.submitterName}</Text>
          <Text>{submission.submitterEmail}</Text>
        </Box>
        <HStack
          spacing={8}
          borderBottomWidth={isSubmissionPaid ? 1 : 0}
          borderTopWidth={1}
          pt={8}
          pb={isSubmissionPaid ? 8 : 0}
        >
          <SubmissionPreference label="Date" lines={[asDate(submission.createdAt)]} />
          {isSubmissionPaid && (
            <SubmissionPreference label="Payment" lines={[submission.paymentMethod]} />
          )}
        </HStack>
        {isSubmissionPaid && (
        <>
          {Object.keys(groupedLineItems).map((key, i) => {
            const total = sum(
              groupedLineItems[key].map((li) => parseFloat(li.price)),
            )
            const formattedTotal = total > 0
              ? asMoney(total, submission.currency)
              : null

            return (
              <Box key={i} borderBottomWidth={1} pb={5}>
                <Flex justify="space-between">
                  <Text>
                    {groupedLineItems[key].length} x{" "}
                    {groupedLineItems[key][0].form.name}
                  </Text>
                  <Text>
                    {formattedTotal}
                  </Text>
                </Flex>
              </Box>
            )
          })}
          <Text textAlign="right" fontWeight="bold">
            <Text as="span" mr={5} textTransform="uppercase">
              Total
            </Text>{" "}
            {asMoney(submission.price, submission.currency)}
          </Text>
        </>
        )}
      </Stack>
    </SubmissionCard>
  )
}

export default SubmissionSummaryCard
