import React from "react"
import { Flex, Text } from "@chakra-ui/react"
import { asDateWithTZ } from "utilities/strings"

type Props = {
  label: string
  dateTime: string
  timeZone: string
}

const TimestampLine = ({ label, dateTime, timeZone }: Props) => (
  <Flex>
    <Text w={40}>{label}</Text>
    <Text color="gray.500">{asDateWithTZ(dateTime, timeZone, "MMMM d, yyyy 'at' h:mm a")}</Text>
  </Flex>
)

export default TimestampLine
