import React, { useEffect, useState } from "react"
import { Redirect } from "react-router-dom"
import { eventsPath, loginPath, useQuery } from "utilities/routes"
import { confirmEmail } from "api/sessions"
import Spinner from "components/elements/Spinner"
import { setCurrentUser } from "context/actions"
import { successToast } from "utilities/toasts"
import { useQueryClient } from "react-query"
import { User } from "sharedTypes"

type Props = {
  currentUser: User
}

const ConfirmEmail = ({ currentUser }: Props) => {
  const [loading, setLoading] = useState(true)
  const queryClient = useQueryClient()
  const query = useQuery()
  const confirmationToken = query.get("confirmation_token") || ""

  useEffect(() => {
    confirmEmail(confirmationToken)
      .then(() => {
        setCurrentUser(queryClient, { ...currentUser, confirmed: true })
        successToast({ title: "Thanks for confirming your email!" })
        setLoading(false)
      }).catch(() => setLoading(false))
  }, [])

  if (currentUser.id) {
    return currentUser.confirmed ? <Redirect to={eventsPath()} /> : <Spinner />
  }

  return loading ? <Spinner /> : <Redirect to={loginPath()} />
}

export default ConfirmEmail
