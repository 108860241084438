import {
  Box, Heading, HStack, Table, Tbody, Td, Thead, Tr,
} from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { eventPath } from "utilities/routes"
import CoverImage from "pages/EventsIndex/CoverImage"
import { asDate, asMoney, asNumber } from "utilities/strings"
import WebsiteLink from "components/elements/WebsiteLink"
import pluralize from "pluralize"
import React from "react"

const TableView = ({ events }) => (
  <Box py={3} px={6}>
    <Table>
      <Thead>
        <Tr>
          <Td>Event</Td>
          <Td>Date</Td>
          <Td>Guests</Td>
          <Td>Website</Td>
          <Td>Net Sales</Td>
        </Tr>
      </Thead>
      <Tbody>
        { events.map((dioboxEvent) => {
          const {
            id,
            title,
            startDate,
            guestCount,
            netSales,
            currency,
            website,
            website: { urlPath, viewsCount, coverImageUrl },
          } = dioboxEvent

          return (
            <Tr key={id}>
              <Td>
                <Link to={eventPath(id)}>
                  <HStack spacing={3}>
                    <CoverImage
                      url={coverImageUrl}
                      startDate={startDate}
                      size={20}
                    />
                    <Heading
                      fontSize="md"
                      maxWidth={250}
                    >{title}
                    </Heading>
                  </HStack>
                </Link>
              </Td>
              <Td>{asDate(startDate, "PPP")}</Td>
              <Td>
                { asNumber(guestCount) } Total
              </Td>
              <Td>
                {urlPath
                && (
                  <>
                    <WebsiteLink website={website} />
                    { asNumber(viewsCount) }
                    {" "}
                    {pluralize("views", viewsCount)}
                  </>
                )}
                {!urlPath && "-"}
              </Td>
              <Td>{netSales ? asMoney(netSales, currency) : "-"}</Td>
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  </Box>
)

export default TableView
