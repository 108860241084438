import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { eventEditTablePath, eventTablePath } from "utilities/routes"
import {
  Guest as DioboxGuest, GuestSummary, ModalName, Seat,
} from "sharedTypes"
import { updateGuest, updateTable } from "context/actions"
import { guestSummary } from "utilities/objects"
import * as api from "api/tables"
import _ from "lodash"
import { errorToast } from "utilities/toasts"
import * as guestsApi from "api/guests"
import { deleteGuestSeat, postGuestTableWaitlist } from "api/guests"
import { useCurrentEvent } from "queries/events"
import { useQueryClient } from "react-query"
import usePermissions from "services/permissions"
import useModal from "services/useModal"
import PlaceholderForm from "./Table/PlaceholderForm"

export enum SeatSelectTypes {
  Guest = "guest",
  Placeholder = "placeholder",
}

const withTableState = (WrappedTable) => (props) => {
  const { data: { id: eventId } } = useCurrentEvent()
  const queryClient = useQueryClient()
  const { canEditSeating } = usePermissions()
  const showModal = useModal()
  const { table, table: { id: tableId }, autoSave } = props
  const { action } = useParams<{action?: "edit"}>()
  const history = useHistory()
  const editing = action === "edit"
  const setEditing = (editingEnabled: boolean) => {
    if (autoSave) {
      return
    }

    history.push(
      editingEnabled
        ? eventEditTablePath(eventId, parseInt(tableId, 10))
        : eventTablePath(eventId, parseInt(tableId, 10)),
    )
  }

  const {
    seats: initialSeats,
    waitlistedGuests: initialWaitlistedGuests,
  } = table
  const [seats, setSeats] = useState(initialSeats)
  const [waitlistedGuests, setWaitlistedGuests] = useState(initialWaitlistedGuests)

  useEffect(() => {
    if (autoSave && (seats !== initialSeats || waitlistedGuests !== initialWaitlistedGuests)) {
      save()
    }
  }, [seats, waitlistedGuests])

  const [
    selectedSeat,
    setSelectedSeat,
  ] = useState<{ seatNumber: number; select: SeatSelectTypes; } | null>(null)

  useEffect(() => setSeats(initialSeats), [initialSeats])
  useEffect(() => setWaitlistedGuests(initialWaitlistedGuests), [initialWaitlistedGuests])

  useEffect(() => {
    if (selectedSeat && selectedSeat.select === SeatSelectTypes.Guest) {
      showModal(
        ModalName.SelectGuest,
        {
          onSelect: handleClickGuest,
          onClose: () => setSelectedSeat(null),
        },
      )
    }
  }, [selectedSeat])

  const setSeat = (seatNumber, values) => {
    setSeats((prevState) => prevState.map((seat) => {
      if (seat.number === seatNumber) {
        return { ...seat, ...values }
      }

      return seat
    }))
  }

  const resetSeat = (seatNumber) => {
    const newSeat = createSeat({ number: seatNumber })
    const { guestId, reserved } = seats.find(({ number }) => number === seatNumber)

    if (autoSave && !reserved) {
      if (!guestId) {
        return
      }

      deleteGuestSeat(eventId, guestId).then(({ data }) => {
        updateGuest(queryClient, data)

        const newTable = {
          ...table,
          seats: seats.map(
            (seat) => (seat.guestId === guestId ? newSeat : seat),
          ),
        }

        updateTable(queryClient, eventId, newTable)
      })
    } else {
      setEditing(true)
      setSeat(seatNumber, newSeat)
    }
  }

  const handleClickGuest = (guest) => {
    if (!selectedSeat) {
      return
    }

    const newSeat = createSeat({ number: selectedSeat.seatNumber, guest })

    if (autoSave) {
      guestsApi.postGuestSeat(eventId, guest, table, selectedSeat.seatNumber)
        .then(({ data }) => {
          updateGuest(queryClient, data)
          const newTable = {
            ...table,
            seats: seats.map(
              (seat: Seat) => (seat.number === selectedSeat.seatNumber ? newSeat : seat),
            ),
          }

          updateTable(queryClient, eventId, newTable)
        })
    } else {
      setSeat(selectedSeat.seatNumber, newSeat)
    }

    setSelectedSeat(null)
  }

  const assignGuest = (seatNumber: number) => {
    setEditing(true)
    setSelectedSeat({ seatNumber, select: SeatSelectTypes.Guest })
  }

  const createSeat = (
    {
      number,
      guest = null,
      reserved = false,
      description = null,
    }: {
      number: number,
      guest?: DioboxGuest | null
      reserved?: boolean,
      description?: string | null
    },
    prevSeat?: Seat,
  ) => ({
    ...prevSeat,
    number,
    reserved,
    description,
    ...guest ? {
      guestId: guest.id,
      guest: guestSummary(guest),
    } : {
      guestId: null,
      guest: null,
    },
  })

  const moveToWaitlist = (seatNumber) => {
    const { guest } = seats.find(({ number }) => number === seatNumber) as Seat
    if (!guest) {
      return
    }

    if (autoSave) {
      postGuestTableWaitlist(eventId, guest.id, table).then(({ data }) => {
        updateGuest(queryClient, data)
        const newTable = {
          ...table,
          seats: seats.map(
            (seat) => (seat.guestId === guest.id ? createSeat({ number: seatNumber }) : seat),
          ),
          waitlistedGuests: [...waitlistedGuests, guest],
          waitlistedGuestIds: [...waitlistedGuests.map(({ id }) => id), guest.id],
        }

        updateTable(queryClient, eventId, newTable)
      })
    } else {
      setEditing(true)

      setWaitlistedGuests((prevState) => [...prevState, guest])
      resetSeat(seatNumber)
    }
  }

  const addPlaceholder = (seatNumber) => {
    setEditing(true)
    setSelectedSeat({ seatNumber, select: SeatSelectTypes.Placeholder })
  }

  const editPlaceholder = (seatNumber) => {
    setEditing(true)
    setSelectedSeat({ seatNumber, select: SeatSelectTypes.Placeholder })
  }

  const savePlaceholder = (description) => {
    if (!selectedSeat) {
      return
    }

    setSeat(selectedSeat.seatNumber, { reserved: true, description })
  }

  const removeGuestFromWaitlist = (guestId: string) => {
    setEditing(true)
    setWaitlistedGuests((prevGuests) => prevGuests.filter(({ id }) => id !== guestId))
  }

  const openSeat = seats.find(({ guest: g }) => !g)
  const moveGuestToOpenSeat = openSeat ? (guest: GuestSummary) => {
    if (!openSeat) {
      return
    }

    removeGuestFromWaitlist(guest.id)
    setSeat(openSeat.number, { number: openSeat.number, guest, guestId: guest.id })
  } : undefined

  const handleCancelEditing = () => {
    setEditing(false)
    setSeats(initialSeats)
    setWaitlistedGuests(initialWaitlistedGuests)
  }

  const handleUpdateLists = ({ seats: updatedSeats, waitlist: updatedWaitlist }) => {
    setSeats(updatedSeats.map((seat, index) => ({ ...seat, number: index + 1 })))
    setWaitlistedGuests(updatedWaitlist)
  }

  const save = () => {
    api.putTable(eventId, {
      ...table,
      seats,
      waitlistedGuests,
      waitlistedGuestIds: _.map(waitlistedGuests, "id"),
    })
      .then(({ data }) => {
        updateTable(queryClient, eventId, data)
        setEditing(false)
      })
      .catch(() => {
        errorToast({ title: "Failed updating table." })
      })
  }

  const additionalProps = {
    eventId,
    editing,
    setEditing,
    table,
    seats,
    waitlistedGuests,
    resetSeat,
    assignGuest,
    moveToWaitlist,
    addPlaceholder,
    editPlaceholder,
    savePlaceholder,
    removeGuestFromWaitlist,
    moveGuestToOpenSeat,
    handleCancelEditing,
    handleUpdateLists,
    save,
    canEditSeating,
  }

  return (
    <>
      <WrappedTable
        {...props}
        {...additionalProps}
      />
      {selectedSeat?.select === SeatSelectTypes.Placeholder && (
        <PlaceholderForm
          onSubmit={savePlaceholder}
          onClose={() => setSelectedSeat(null)}
          description={seats.find(({ number }) => number === selectedSeat.seatNumber)?.description}
        />
      )}
    </>
  )
}

export default withTableState
