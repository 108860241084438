import React, { useState } from "react"
import { normalizeBools } from "utilities/objects"
import EditableSection from "components/layout/EditableSection"
import { Box, HStack } from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import Link from "components/elements/Link"
import Icon from "components/elements/Icon"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import SwitchField from "components/ReactHookForm/SwitchField"
import { useFieldArray } from "react-hook-form"
import UrlField from "components/ReactHookForm/UrlField"

type Props = {
  links: string[];
  linksEnabled: boolean;
  updateWebsite: (params: {}) => Promise<void>;
}

const Links = ({ links, linksEnabled, updateWebsite }: Props) => {
  const [edit, setEdit] = useState(false)

  return (
    <EditableSection
      title="Links Block"
      onEdit={() => { setEdit(true) }}
      columns
    >
      <Box pointerEvents={!linksEnabled ? "none" : undefined}>
        { links.map((link, index) => (
          <Link
            key={index}
            href={link.includes("http") ? link : `http://${link}`}
            isExternal
            display="block"
            wordBreak="break-all"
            color={!linksEnabled ? "gray.200" : "blue.400"}
          >
            { link }
          </Link>
        )) }
      </Box>
      { edit && (
      <Edit {...{
        setEdit, links, linksEnabled, updateWebsite,
      }}
      />
      )}
    </EditableSection>
  )
}

const Edit = ({
  setEdit, links, linksEnabled, updateWebsite,
}) => {
  const onSubmit = (values) => {
    updateWebsite(normalizeBools(values)).then(() => {
      setEdit(false)
    })
  }

  return (
    <ModalForm
      initialValues={{ links, linksEnabled }}
      onSubmit={onSubmit}
      title="Links Block"
      size="xl"
      onClose={() => {
        setEdit(false)
      }}
    >
      { ({ control, watch }) => {
        const enabled = watch("linksEnabled")

        return (
          <>
            <SwitchField
              name="linksEnabled"
              control={control}
              label="Enabled"
            />
            <List name="links" control={control} enabled={enabled} />
          </>
        )
      }}
    </ModalForm>
  )
}

const List = ({ name, control, enabled }) => {
  const {
    fields, append, remove,
  } = useFieldArray<any, any, any>({ name, control })

  return (
    <>
      <Box mb={2}>
        {fields.map((link, index) => (
          <HStack
            key={index}
            spacing="15px"
          >
            <UrlField
              name={`links.${index}`}
              control={control}
              disabled={!enabled}
              variant="flushed"
            />
            <button
              onClick={(e) => {
                e.preventDefault()
                remove(index)
              }}
            >
              <Icon icon="cross" size={5} color="red.500" />
            </button>
          </HStack>
        ))}
      </Box>
      <Button
        onClick={() => append("")}
        isDisabled={!enabled}
        size="sm"
        leftIcon="plus"
      >
        Link
      </Button>
    </>
  )
}

export default Links
