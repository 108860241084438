import React from "react"
import {
  AccountUser, DioboxEvent, EventUser, User,
} from "sharedTypes"
import PermissionsForm, { InitialValuesProps } from "modals/PermissionsForm"
import { EventUserRole } from "services/permissions"
import { addAccountUser, updateAccountUser } from "context/actions"
import { postAccountUser, putAccountUser } from "api/accounts"
import { useQueryClient } from "react-query"
import { accountUsersCacheKey } from "queries/accountUsers"
import { currentAccountId } from "services/authentication"
import { deleteEventUser, postEventUser, putEventUser } from "../api/events"

type Props = {
  onClose: () => void
  currentUser: User
  currentEvent?: DioboxEvent
  accountUser?: AccountUser
}

const AccountUserForm = ({
  onClose, currentUser, currentEvent, accountUser,
}: Props) => {
  const queryClient = useQueryClient()

  const getEventUser = (eventId: string): (EventUser | undefined) => (
    accountUser?.events.find(({ event }) => event.id === eventId)
  )

  const handleEventUserRoleChange = async ({ eventId, email, role }) => {
    const eventUser = getEventUser(eventId)

    if (!eventId) { return null }
    if (eventUser?.role === role) { return null }
    if (!eventUser && role === EventUserRole.NoAccess) { return null }

    if (!eventUser) {
      return postEventUser(eventId, { email, role })
    }

    if (role === EventUserRole.NoAccess) {
      return deleteEventUser(eventId, eventUser.id)
    }

    return putEventUser(eventId, eventUser.id, { role })
  }

  const onSubmit = async (values) => {
    const { admin, canCreateEvents } = values
    const createdEventUser = await handleEventUserRoleChange(values)

    if (accountUser) {
      const { data: updatedAccountUser } = await putAccountUser({
        ...accountUser,
        admin,
        canCreateEvents,
      })

      await updateAccountUser(queryClient, updatedAccountUser)
    } else if (!createdEventUser) {
      const { data: newAccountUser } = await postAccountUser(values)
      await addAccountUser(queryClient, newAccountUser)
    } else {
      await queryClient.invalidateQueries(accountUsersCacheKey(currentAccountId()))
    }
    onClose()
  }

  const initialValues: InitialValuesProps = {
    admin: false,
    canCreateEvents: false,
  }

  if (currentEvent) {
    initialValues.eventId = currentEvent.id
    const eventUser = getEventUser(currentEvent.id)
    initialValues.role = eventUser?.role ?? EventUserRole.NoAccess
  }

  if (accountUser) {
    initialValues.email = accountUser.email ?? accountUser.user.email
    initialValues.admin = accountUser.admin
    initialValues.canCreateEvents = accountUser.canCreateEvents
  }

  return (
    <PermissionsForm
      user={accountUser?.user}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onClose={onClose}
      currentUser={currentUser}
    />
  )
}

export default AccountUserForm
