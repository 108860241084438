import React from "react"
import Icon from "components/elements/Icon"
import { asNumber } from "utilities/strings"
import { Flex, Text } from "@chakra-ui/react"

type Props = {
  label: string;
  count: number;
  onSelect: () => void;
  icon?: string;
  iconColor?: string;
  selected?: boolean;
}

const Filter = ({
  label, count, onSelect, icon = "dot", iconColor, selected,
}: Props) => (
  <Flex align="center" position="relative" w="full">
    <Flex
      role="button"
      onClick={onSelect}
      position="relative"
      justify="space-between"
      align="center"
      w="full"
      px={8}
      py={2}
      fontSize="xl"
      lineHeight="short"
      color="gray.600"
      bg={selected ? "blue.50" : ""}
    >
      <Flex align="center">
        {selected && <Icon icon="checkmark" pos="absolute" left={0} mx={2} color="blue.400" />}
        <Icon icon={icon} size={icon === "dot" ? 3 : 4} mr={2} color={iconColor} />
        <span>{label}</span>
      </Flex>
      <Flex align="center" justify="end">
        <Text as="span" align="right">{asNumber(count)}</Text>
      </Flex>
    </Flex>
  </Flex>
)

export default Filter
