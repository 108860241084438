import React from "react"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import EditorSection from "components/Builder/components/EditorSection"
import TextField from "components/ReactHookForm/TextField"
import { Heading, SimpleGrid } from "@chakra-ui/react"
import { MERGE_TAGS } from "utilities/enums"
import Button from "components/Buttons/Button"
import FontSelect from "components/Builder/components/FontSelect"
import HeadingSizeSelect from "components/Builder/components/HeadingSizeSelect"
import AlignmentSelect from "components/Builder/components/AlignmentSelect"
import PaddingInputs from "components/Builder/components/PaddingInputs"
import ColorSelect from "components/ReactHookForm/ColorSelect/ColorSelect"
import { Control } from "react-hook-form"
import withMergeTags from "../withMergeTags"

type Props = {
  name: string
  control: Control
  insertTag: (id: string) => void
  handleSelectionChange: () => void
}

const HeadingFields = withMergeTags(({
  name, control, insertTag, handleSelectionChange,
}: Props) => (
  <>
    <SidePanelHeader>Heading Block</SidePanelHeader>
    <EditorSection>
      <TextField name={name} control={control} variant="outline" onBlur={handleSelectionChange} />
      <Heading size="xs" mb={2} fontStyle="normal">Merge Fields</Heading>
      <SimpleGrid columns={2} spacing={4} my={2}>
        {MERGE_TAGS.map(({ id, label }) => (
          <Button key={id} size="xs" variant="outline" onClick={() => insertTag(id)}>{label}</Button>
        ))}
      </SimpleGrid>
    </EditorSection>
    <EditorSection>
      <FontSelect control={control} />
      <HeadingSizeSelect control={control} />
      <AlignmentSelect control={control} />
    </EditorSection>
    <EditorSection>
      <PaddingInputs control={control} />
    </EditorSection>
    <EditorSection>
      <ColorSelect label="Text Color" name="textColor" control={control} />
    </EditorSection>
    <EditorSection>
      <ColorSelect label="Block Color" name="blockColor" control={control} colorScheme="light" />
    </EditorSection>
  </>
), "text")

export default HeadingFields
