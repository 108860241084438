import React from "react"
import { Flex, Text } from "@chakra-ui/react"

type Props = {
  text: string
}

const FormListItemTypeHeader = ({ text }: Props) => (
  <Flex
    h={10}
    px={6}
    backgroundColor="gray.100"
    borderBottomWidth={1}
    alignItems="center"
  >
    <Text textTransform="uppercase" fontWeight="semibold">{text}</Text>
  </Flex>
)

export default FormListItemTypeHeader
