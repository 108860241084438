import React, { ReactNode } from "react"
import { Flex, Text } from "@chakra-ui/react"

type Props = {
  title: string,
  children: ReactNode
}

const StatusRow = ({
  title, children,
}: Props) => (
  <Flex my={2} minH={10}>
    <Flex minW={36} paddingTop={9}>
      <Text fontWeight="bold" lineHeight={3}>{title}</Text>
    </Flex>
    <Flex>{children}</Flex>
  </Flex>
)

export default StatusRow
