import React from "react"
import { Text } from "@chakra-ui/react"
import { ChakraProps } from "@chakra-ui/system"

type Props = {
  text: string
  onClick?: () => void
} & ChakraProps

const PlanText = ({
  text, onClick, ...props
}: Props) => (
  <Text
    textAlign="center"
    color="blue.500"
    fontSize="sm"
    fontWeight="semibold"
    borderWidth={1}
    borderColor="blue.500"
    px={3}
    borderRadius="base"
    as="button"
    onClick={onClick}
    {...props}
  >{text}
  </Text>
)

export default PlanText
