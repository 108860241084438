import { Flex, Text } from "@chakra-ui/react"
import React, { ReactNode } from "react"

type Props = {
  name: string;
  children: ReactNode;
}

const EmailProperty = ({ name, children }: Props) => (
  <Flex mb={8} align="top" lineHeight={1.2}>
    <Text fontWeight="bold" w={1 / 4} mr={2}>{name}</Text>
    <Text w="100%">{children}</Text>
  </Flex>
)

export default EmailProperty
