import React, { ReactNode, useEffect, useRef } from "react"
import {
  Box, Flex, Heading, HStack, Text,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import Popover from "components/dialogs/Popover"
import SimpleBar from "simplebar-react"

interface Props {
  header: ReactNode
  body: ReactNode
}

const EventPage = ({ header, body }: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const scrollToSection = () => {
      const hash = window.location.hash.substring(1) // Get the hash without the #
      if (hash) {
        const section = document.getElementById(hash)
        if (section && ref.current) {
          ref.current.scrollTop = section.offsetTop
        }
      }
    }

    scrollToSection()

    window.addEventListener("hashchange", scrollToSection)

    return () => {
      window.removeEventListener("hashchange", scrollToSection)
    }
  }, [])

  return (
    <Flex
      pt={8}
      direction="column"
      h="100%"
    >
      <Box px={8}>{header}</Box>
      <Box
        as={SimpleBar}
        h={1}
        flex={1}
        borderTopWidth={1}
        scrollableNodeProps={{ ref }}
      >
        {body}
      </Box>
    </Flex>
  )
}

interface EventPageHeaderProps {
  icon?: string
  title: ReactNode|string
  helpText?: ReactNode|string
  subtitle?: ReactNode
  items?: ReactNode
}

export const EventPageHeader = ({
  icon, title, helpText, subtitle, items,
}: EventPageHeaderProps) => (
  <Box mb={!subtitle ? 8 : 4} zIndex={2}>
    <Flex justify="space-between" align="center">
      <Flex align="center">
        {icon && <Icon icon={icon} size={5} mr={2} color="blue.500" />}
        <Heading fontSize="2xl" fontWeight="600" verticalAlign="middle" mr={helpText ? 3 : 0}>{title}</Heading>
        {helpText && (
        <Popover
          trigger={<Icon icon="growl-info" color="gray.500" />}
          triggerEvent="hover"
          body={<Text p={2}>{helpText}</Text>}
        />
        )}
      </Flex>
      <HStack spacing={10}>
        {items}
      </HStack>
    </Flex>
    {subtitle}
  </Box>
)

export default EventPage
