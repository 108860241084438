import React, { useState } from "react"
import { AgendaRow, AgendaItem as DioboxAgendaItem, Speaker } from "sharedTypes"
import { AGENDA_ROW_TYPES } from "utilities/enums"
import EditableSection from "components/layout/EditableSection"
import { asDateWithTZ } from "utilities/strings"
import {
  Box, Heading, ListItem, Text, UnorderedList,
} from "@chakra-ui/react"
import { useCurrentEvent } from "queries/events"
import SpeakerListItem from "./SpeakerListItem"
import AgendaRowForm from "../Form"

const AgendaItem = (agendaItem: DioboxAgendaItem) => {
  const [edit, setEdit] = useState(false)
  const { data: { website } } = useCurrentEvent()

  const {
    title,
    description,
    startsAt,
    endsAt,
    speakerIds,
  } = agendaItem

  let speakersList
  if (speakerIds.length) {
    const eventSpeakers = website.speakers

    const speakers = speakerIds
      .map(
        (speakerId) => eventSpeakers.find(
          ({ id }) => id === speakerId,
        ) as Speaker,
      )
      .filter(Boolean)

    speakersList = (
      <UnorderedList mt={4} ml={0} styleType="none">
        {speakers.map((speaker: Speaker) => (
          <ListItem key={speaker.id} mb={5}><SpeakerListItem {...speaker} /></ListItem>
        ))}
      </UnorderedList>
    )
  }

  return (
    <EditableSection onEdit={() => setEdit(true)}>
      <Box>
        <Box as="time" display="block" color="gray.500">
          <Text fontSize="md">
            {asDateWithTZ(startsAt, "UTC", "h:mm a")} - {asDateWithTZ(endsAt, "UTC", "h:mm a")}
          </Text>
        </Box>
        <Heading size="sm" fontWeight="normal">{title}</Heading>
        <Text mt={4} fontSize="sm" fontWeight="light" whiteSpace="pre-wrap">{description}</Text>
        {speakersList}
      </Box>
      {edit && (
        <AgendaRowForm
          agendaRow={agendaItem as AgendaRow}
          agendaRowType={AGENDA_ROW_TYPES.item}
          speakers={website.speakers}
          onHide={() => { setEdit(false) }}
        />
      )}
    </EditableSection>
  )
}

export default AgendaItem
