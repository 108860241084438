import React, { useEffect } from "react"
import {
  Text,
  InputGroup,
  LayoutProps,
  Center,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react"
import { Control, useController } from "react-hook-form"

  type Props = {
    name: string;
    control: Control<any>;
    disabled?: boolean;
    w: LayoutProps["width"];
    rightText?: string;
    precision?: number;
  }

const PriceField = ({
  name,
  control,
  disabled,
  w,
  rightText,
  precision,
}: Props) => {
  const { field } = useController({ name, control })

  useEffect(() => {
    if (field.value) {
      field.onChange(Number(field.value).toFixed(precision))
    }
  }, [])

  return (
    <InputGroup>
      <NumberInput precision={precision} {...field}>
        <NumberInputField w={w} disabled={disabled} />
      </NumberInput>
      {rightText && <Center><Text ml={2}>{rightText}</Text></Center>}
    </InputGroup>
  )
}

export default PriceField
