import React, { ReactNode, useRef } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { useInViewport } from "ahooks"
import { Control, useWatch } from "react-hook-form"
import useModal from "services/useModal"
import Checkbox from "components/ReactHookForm/Checkbox"
import { ModalName } from "sharedTypes"
import _ from "lodash"
import Icon from "./elements/Icon"

const RecipientCheckboxes = ({
  event: {
    counts: {
      arrivedGuests,
      confirmedGuests,
      declinedGuests,
      guests,
      unarrivedGuests,
      unconfirmedGuests,
      eventGroups: eventGroupsCounts,
      segments: segmentCounts,
      waitlistedGuests,
    },
    eventGroups,
    segmentsEnabled,
    segments: allSegments,
    tables,
  },
  control,
  submitOnChange = true,
}) => {
  const disabled = useWatch({ control, name: "sendTo.groupAll" })
  const showModal = useModal()
  const segments = segmentsEnabled ? allSegments : []

  return (
    <Box w="100%">
      <Box mb={10}>
        <CheckboxRow
          name="sendTo.groupAll"
          label="All Guests"
          count={guests}
          disabled={false}
          icon={<Icon icon="guests" color="blue.500" size={4} mx={1} />}
          control={control}
          submitOnChange={submitOnChange}
        />
      </Box>

      <Box mb={10}>
        <RecipientTitle>Segments</RecipientTitle>
        <CheckboxRow
          name="sendTo.groupConfirmed"
          label="Confirmed"
          disabled={disabled}
          count={confirmedGuests}
          icon={<Icon icon="dot" color="blue.300" size={3} mx={1} />}
          control={control}
          submitOnChange={submitOnChange}
        />
        <CheckboxRow
          name="sendTo.groupUnconfirmed"
          label="Unconfirmed"
          disabled={disabled}
          count={unconfirmedGuests}
          icon={<Icon icon="dot" color="gray.300" size={3} mx={1} />}
          control={control}
          submitOnChange={submitOnChange}
        />
        <CheckboxRow
          name="sendTo.groupWaitlisted"
          label="Waitlisted"
          disabled={disabled}
          count={waitlistedGuests}
          icon={<Icon icon="dot" color="yellow.300" size={3} mx={1} />}
          control={control}
          submitOnChange={submitOnChange}
        />
        <CheckboxRow
          name="sendTo.groupDeclined"
          label="Declined"
          disabled={disabled}
          count={declinedGuests}
          icon={<Icon icon="dot" color="red.300" size={3} mx={1} />}
          control={control}
          submitOnChange={submitOnChange}
        />
        <CheckboxRow
          name="sendTo.groupCheckedIn"
          label="Checked In"
          disabled={disabled}
          count={arrivedGuests}
          icon={<Icon icon="dot" color="green.500" size={3} mx={1} />}
          control={control}
          submitOnChange={submitOnChange}
        />
        <CheckboxRow
          name="sendTo.groupUnarrived"
          label="Not Checked In"
          disabled={disabled}
          count={unarrivedGuests}
          icon={<Icon icon="dot" color="orange.300" size={3} mx={1} />}
          control={control}
          submitOnChange={submitOnChange}
        />
      </Box>

      <Box mb={10}>
        <Flex justify="space-between">
          <RecipientTitle>Custom Segments</RecipientTitle>
          <Icon icon="plus" role="button" color="blue.500" onClick={() => showModal(ModalName.SegmentForm)} />
        </Flex>
        {!segments.length && <>Create a filter defined by custom rules</>}
        {_.sortBy(segments, "icon").map((segment) => (
          <CheckboxRow
            key={segment.id}
            name={`sendTo.segment_${segment.id}`}
            label={segment.name}
            disabled={disabled}
            count={segmentCounts.find((s) => s.id === segment.id)?.size || 0}
            icon={<Icon icon="dot" color={`${segment.color}.500`} size={3} mx={1} />}
            onClickEdit={
                () => showModal(ModalName.SegmentForm, { segmentId: segment.id })
              }
            control={control}
            submitOnChange={submitOnChange}
          />
        ))}
      </Box>

      <Box mb={10}>
        <RecipientTitle>Guest Lists</RecipientTitle>
        {_.sortBy(eventGroups, "icon").map((group) => (
          <CheckboxRow
            key={group.id}
            name={`sendTo.group${group.id}`}
            label={group.title}
            disabled={disabled}
            count={eventGroupsCounts.find((eg) => eg.id === group.id)?.size || 0}
            icon={<Icon icon={group.icon ? `event-group-${group.icon}` : "tag"} color="gray.600" mx={1} />}
            control={control}
            submitOnChange={submitOnChange}
          />
        ))}
      </Box>
      {tables.length > 0 && (
      <Box mb={10}>
        <RecipientTitle>Seating</RecipientTitle>
        {_.sortBy(tables, (table) => table.name.toLowerCase()).map((table) => (
          <CheckboxRow
            key={table.id}
            name={`sendTo.table${table.id}`}
            label={table.name}
            disabled={disabled}
            count={table.seats.length}
            icon={<Icon icon="tables-stroke" color="gray.600" mx={1} />}
            control={control}
            submitOnChange={submitOnChange}
          />
        ))}
      </Box>
      )}
    </Box>
  )
}

type CheckboxrowProps = {
  name: string,
  label: string,
  disabled: boolean,
  count: number,
  icon: ReactNode,
  onClickEdit?: () => void,
  control: Control<any>,
  submitOnChange: boolean,
}

const CheckboxRow = ({
  name, label, disabled, count, icon, onClickEdit, control, submitOnChange,
}: CheckboxrowProps) => {
  const containerRef = useRef(null)
  const inViewport = useInViewport(containerRef)

  return (
    <Flex justify="space-between" align="center" ref={containerRef} mb={3}>
      {inViewport
        && (
          <>
            <Checkbox
              name={name}
              control={control}
              label={<>{icon} {label}</>}
              submitOnChange={submitOnChange}
              disabled={disabled}
              mb={0}
            />
            <Box>{count}</Box>
            {onClickEdit && (<Icon icon="website-edit" role="button" color="blue.500" onClick={onClickEdit} ml={2} />)}
          </>
        )}
      {!inViewport && <Box>&nbsp;</Box>}
    </Flex>
  )
}

const RecipientTitle = ({ children }) => <Text casing="uppercase" color="gray.600" mb={3} fontSize="sm">{children}</Text>

export default RecipientCheckboxes
