import React, { useState } from "react"
import { User } from "sharedTypes"
import { putCurrentUser, putUpdatePassword } from "api/accounts"
import * as Yup from "yup"
import _ from "lodash"
import { Box, Flex } from "@chakra-ui/react"
import Link from "components/elements/Link"
import { setCurrentUser } from "context/actions"
import { useQueryClient } from "react-query"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import ImageUpload from "components/ReactHookForm/ImageUpload"
import PasswordField from "components/ReactHookForm/PasswordField"
import EmailField from "components/ReactHookForm/EmailField"
import TextField from "components/ReactHookForm/TextField"
import { PutCurrentUser, PutUpdatePassword } from "sharedTypes/api"
import { successToast } from "utilities/toasts"
import extendFormValuesWithImages from "utilities/extendFormValuesWithImages"
import { storeAuthToken } from "services/authentication"

interface EditProfileProps {
  user: User
  onClose: () => void
}

const EditProfile = ({ user, onClose }: EditProfileProps) => {
  const queryClient = useQueryClient()
  const [changePassword, setChangePassword] = useState(false)

  const onSubmit = async (values) => {
    if (changePassword) {
      try {
        const { data: { authenticationToken } } = await putUpdatePassword(_.pick(values, ["password", "newPassword", "confirmPassword"]) as PutUpdatePassword)
        storeAuthToken(authenticationToken)
        successToast({ title: "Password changed" })
      } catch (e) {
        return
      }
    } else {
      const { email: oldEmailAddress } = user
      const { email: currentEmailAddress } = values
      const isEmailChanged = oldEmailAddress !== currentEmailAddress

      const { data } = await putCurrentUser(_.pick(values, ["email", "firstName", "lastName", "photo"]) as PutCurrentUser)
      if (isEmailChanged) {
        successToast({ title: `Please check ${currentEmailAddress} inbox and verify your email.` })
      }
      setCurrentUser(queryClient, data)
    }

    onClose()
  }

  const UserSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email().required(),
  })

  const PasswordSchema = Yup.object().shape({
    password: Yup.string().required().min(6),
    newPassword: Yup.string().required().min(6),
    confirmPassword: Yup.string().required().oneOf([Yup.ref("newPassword")], "Passwords must match"),
  })

  if (changePassword) {
    return (
      <ModalForm
        title="Change Password"
        initialValues={{ password: "", newPassword: "", confirmPassword: "" }}
        onSubmit={onSubmit}
        onClose={onClose}
        validationSchema={PasswordSchema}
      >
        {({ control }) => (
          <>
            <PasswordField label="Current password" name="password" control={control} />
            <PasswordField label="New password" name="newPassword" control={control} />
            <PasswordField label="Confirm password" name="confirmPassword" control={control} />
          </>
        )}
      </ModalForm>
    )
  }

  const initialValues = extendFormValuesWithImages(_.pick(user, ["firstName", "lastName", "email"]), ["photo"])

  return (
    <ModalForm
      title="Edit Profile"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onClose={onClose}
      validationSchema={UserSchema}
    >
      {({ control }) => (
        <>
          <ImageUpload label="Profile photo" name="photo" control={control} imageUrl={user.photoUrl.includes("data.diobox.com") ? "" : user.photoUrl} />
          <TextField label="First name" name="firstName" control={control} />
          <TextField label="Last name" name="lastName" control={control} />
          <EmailField label="Email" name="email" control={control} />
          <Flex mt={5}>
            <Box w={1 / 4} mr={3} />
            <Link w="100%" onClick={() => setChangePassword(true)}>Change Password</Link>
          </Flex>
        </>
      )}
    </ModalForm>
  )
}

export default EditProfile
