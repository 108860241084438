import Avatar from "components/elements/Avatar"
import React from "react"
import { Speaker } from "sharedTypes"
import { Box, Flex, Text } from "@chakra-ui/react"

const SpeakerListItem = ({
  id,
  photoUrl,
  name,
  title,
}: Speaker) => (
  <Flex key={id}>
    <Avatar mr={3} src={photoUrl} name={name} />
    <Box>
      <Text fontWeight="medium">{name}</Text>
      <Text fontWeight="light" color="gray.500">{title}</Text>
    </Box>
  </Flex>
)

export default SpeakerListItem
