import { Link as ChakraLink } from "@chakra-ui/react"
import React, { ComponentProps } from "react"
import { Link, useRouteMatch } from "react-router-dom"
import { ChakraProps } from "@chakra-ui/system"

type Props = {
  activeProps?: object
  exact?: boolean
}
& ComponentProps<Link>
& ChakraProps

const NavLink = ({
  to, activeProps, exact, children, ...rest
}: Props) => {
  const routeMatch = useRouteMatch({ path: to, exact })

  return (
    <ChakraLink
      as={Link}
      to={to}
      _hover={{}}
      _focus={{}}
      {...rest}
      {...routeMatch ? activeProps : {}}
    >
      {children}
    </ChakraLink>
  )
}

export default NavLink
