import React from "react"
import GuestAvatar from "components/elements/GuestAvatar"
import { DioboxEvent, Guest } from "sharedTypes"
import { Link } from "react-router-dom"
import Icon from "components/elements/Icon"
import {
  Box, Flex, Heading, Text,
} from "@chakra-ui/react"
import { useEventGuestPaths } from "utilities/routes"
import GuestMenu from "../Menu"
import Subscribe from "../Subscribe"

type Props = {
  guest: Guest
  event: DioboxEvent
  displayMenu?: boolean
}

const Header = ({ guest, event, displayMenu }: Props) => {
  const { eventGuestPath } = useEventGuestPaths()
  const {
    id,
    eventId,
    personInfo: {
      firstName,
      lastName,
      photoUrl,
      companyName,
      companyTitle,
      unsubscribed,
    },
    notifications,
  } = guest

  const resubscribe = () => {
    if (!unsubscribed) { return null }

    const icon = <Icon icon="guest-unsubscribed" size={5} ml={2} cursor={displayMenu ? "pointer" : ""} />

    return displayMenu ? <Subscribe guest={guest}>{icon}</Subscribe> : icon
  }

  const checkInAlert = () => {
    if (notifications.length === 0) { return null }

    const icon = <Icon icon="checkin-alert" size={5} ml={2} color="blue.500" />

    return displayMenu ? <Link to={`${eventGuestPath(eventId, id)}/check-in-alert`}>{icon}</Link> : icon
  }

  return (
    <>
      <Flex mb={4} pos="relative" zIndex="1" align="top" justify="center">
        <GuestAvatar
          firstName={firstName}
          lastName={lastName}
          photoUrl={photoUrl}
          status={guest.status}
          checkedIn={guest.checkedIn > 0}
          size={32}
        />
        {(displayMenu) && (
        <Box pos="absolute" right={0}>
          <GuestMenu guest={guest} event={event} />
        </Box>
        )}
      </Flex>
      <Box textAlign="center" mb={6}>
        <Flex align="center" justify="center">
          <Heading fontSize="2xl">{firstName} {lastName}</Heading>
          {resubscribe()}
          {checkInAlert()}
        </Flex>
        { companyName && <Text>{ companyName }</Text> }
        { companyTitle && <Text>{ companyTitle }</Text> }
      </Box>
    </>
  )
}

export default Header
