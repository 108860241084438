import React, { ReactNode, ReactElement } from "react"
import Button from "components/Buttons/Button"
import CancelButton from "components/Buttons/CancelButton"
import Modal from "components/dialogs/Modal"
import { ButtonProps, Text } from "@chakra-ui/react"

type Props = {
  children: ReactElement;
  confirmationTitle?: string;
  confirmationText?: ReactNode | string;
  confirmButtonLabel?: string;
  confirmButtonColorScheme?: ButtonProps["colorScheme"]
  onClickConfirm?: () => void;
  skipIf?: boolean;
  stopChildClickPropagation?: boolean;
}

const Confirm = ({
  children,
  confirmationTitle = "Delete",
  confirmationText = "Are you sure?",
  confirmButtonLabel = "OK",
  confirmButtonColorScheme = "button.danger",
  onClickConfirm = () => {},
  skipIf,
  stopChildClickPropagation,
}: Props) => {
  const [open, setOpen] = React.useState(false)

  const {
    props: {
      onClick: onClickChildren = () => {},
    },
  } = children

  const onClick = () => {
    onClickConfirm()
    onClickChildren()
  }

  const handleClickOpen = (e) => {
    if (skipIf) {
      onClickConfirm()
    } else {
      setOpen(true)
    }
    if (stopChildClickPropagation) {
      e.stopPropagation()
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = () => {
    onClick()
    handleClose()
  }

  return (
    <>
      <children.type {...children.props} onClick={handleClickOpen} />
      <Modal
        size="lg"
        show={open}
        hide={handleClose}
        title={confirmationTitle}
        actions={[
          <CancelButton onClick={handleClose} />,
          <Button
            onClick={handleConfirm}
            colorScheme={confirmButtonColorScheme}
          >{confirmButtonLabel}
          </Button>,
        ]}
      >
        <Text size="xl">{confirmationText}</Text>
      </Modal>
    </>
  )
}

export default Confirm
