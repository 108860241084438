import { Box, Flex, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import _ from "lodash"
import { formIconAndType } from "utilities/forms"
import pluralize from "pluralize"
import { useCurrentEvent } from "queries/events"
import React from "react"
import { useHistory, useParams } from "react-router"
import { FormType, SubmissionLineItem } from "sharedTypes"
import { eventDataCollectionFormSubmissionPath } from "utilities/routes"
import { asDate, asMoney } from "utilities/strings"
import chakraColors from "theme/chakraColors"

type Props = {
  submitterName: string
  submitterEmail: string
  lineItems: SubmissionLineItem[]
  price: string
  createdAt: string
  externalId: string
}

const FormSubmissionListItem = ({
  submitterName, submitterEmail, lineItems, price, createdAt, externalId,
}: Props) => {
  const history = useHistory()
  const { data: { id: eventId, currency } } = useCurrentEvent()
  const { submissionId } = useParams<{ submissionId: string }>()

  const handleClick = () => {
    history.push(
      eventDataCollectionFormSubmissionPath(eventId, externalId),
    )
  }

  let listItemNameAndIcon = <Flex />
  const showPrice = Boolean(lineItems.find(
    ({ form: { type: lineItemFormType } }) => lineItemFormType === FormType.Ticket,
  ))

  if (lineItems.length !== 0) {
    const { icon, type } = formIconAndType(lineItems[0].form.type)

    const lineItemsCount = lineItems.length
    const formTypesWithCount = countByFormType(lineItems)

    listItemNameAndIcon = lineItemsCount > 1
      ? (
        <Flex>
          <Icon icon="multi-registration" size={5} mr={2} />
          <Text fontSize="smaller" fontWeight="bold">
            {formTypesWithCount.map(
              ({ type: typeName, count }) => `${count} ${pluralize(typeName, count)}`,
            ).join(", ")}
          </Text>
        </Flex>
      )
      : (
        <Flex>
          <Icon icon={icon} size={5} mr={2} />
          <Text fontSize="smaller">{type}</Text>
        </Flex>
      )
  }

  return (
    <Box
      borderBottomWidth={1}
      p={4}
      onClick={handleClick}
      cursor="pointer"
      backgroundColor={submissionId === externalId ? chakraColors.ui.selectedItemBg : ""}
    >
      <Flex justifyContent="space-between">
        {listItemNameAndIcon}
        <Flex>
          {showPrice && <Text mr={6}>{asMoney(price, currency)}</Text>}
          <Text>{asDate(createdAt, "M/d/y")}</Text>
        </Flex>
      </Flex>
      <Text>{submitterName}</Text>
      <Text fontSize="smaller">{submitterEmail}</Text>
    </Box>
  )
}

const countByFormType = (lineItems: SubmissionLineItem[]) => {
  const formTypeCounts = _.countBy(lineItems, "form.type")
  const result = Object.entries(formTypeCounts).map(([type, count]) => ({ type, count }))

  return result
}

export default FormSubmissionListItem
