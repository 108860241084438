import React from "react"
import { components, InputProps, SingleValue } from "react-select"
import Creatable from "react-select/creatable"
import { InputComponent } from "react-select/dist/declarations/src/animated/Input"
import { Control, useController } from "react-hook-form"

type Props = {
  name: string;
  control: Control<any>;
  options: string[];
  color?: string;
}

const Autocomplete = ({
  name,
  control,
  options,
  color = "#000000",
}: Props) => {
  const optionItems = options.map((option) => ({ label: option, value: option }))

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color,
    }),
  }

  const { field } = useController({ name, control })

  const selectedItem = { label: field.value, value: field.value }

  const Input = (({ ...props }: InputProps) => <components.Input {...props} autoComplete="off" />) as InputComponent

  return (
    <Creatable
      styles={customStyles}
      options={optionItems}
      defaultValue={selectedItem}
      onChange={({ value }: SingleValue<any>) => field.onChange(value)}
      components={{ Input }}
      menuShouldScrollIntoView={false}
    />
  )
}

export default Autocomplete
