import React from "react"
import { Text, Box, Flex } from "@chakra-ui/react"
import EditorSection from "components/Builder/components/EditorSection"
import Button from "components/Buttons/Button"
import {
  Form, FormType, ModalName, Translations,
} from "sharedTypes"
import useModal from "services/useModal"

type Props = {
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
  formType: FormType
  form: Form
}

const MessagingSection = ({
  updateEvent, defaultTranslations, formType, form,
}: Props) => {
  const showModal = useModal()

  return (
    <EditorSection>
      <Box mb={4}>
        <Text fontSize="lg" fontWeight="bold">Messaging</Text>
        <Text mb={4}>Customize or translate Diobox terms that appear on any {formType} form.</Text>
        <Flex my={2} justifyContent="center" alignItems="center">
          <Button
            variant="outline"
            size="sm"
            onClick={() => showModal(ModalName.EditMessaging, {
              formType, defaultTranslations, updateEvent, form,
            })}
          >Customize Messaging
          </Button>
        </Flex>
      </Box>
    </EditorSection>
  )
}

export default MessagingSection
