import React from "react"
import * as Sentry from "@sentry/react"
import { createRoot } from "react-dom/client"
import "./index.css"
import WebFont from "webfontloader"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import "sharedTypes"
import ErrorBoundary from "./components/ErrorBoundary"
import "simplebar-react/dist/simplebar.min.css"

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENVIRONMENT,
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
})

WebFont.load({
  typekit: {
    id: "plk3vvo",
  },
})

const container = document.getElementById("root")!
const root = createRoot(container)

root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </ErrorBoundary>,
)
