import React from "react"
import {
  AgendaRow, AgendaHeading, AgendaItem, Speaker,
} from "sharedTypes"
import { AGENDA_ROW_TYPES } from "utilities/enums"
import * as api from "api/websites"
import { addAgendaRow, removeAgendaRow, updateAgendaRow } from "context/actions"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { successToast } from "utilities/toasts"
import AgendaItemForm from "../AgendaItem/Form"
import AgendaHeadingForm from "../AgendaHeading/Form"

type Props = {
  agendaRow?: AgendaRow;
  agendaRowType: string;
  speakers?: Speaker[]
  onHide: () => void;
}

const showUpdatedToast = () => successToast({ title: "Agenda updated" })

const AgendaRowForm = ({
  agendaRow,
  agendaRowType,
  speakers = [],
  onHide,
}: Props) => {
  const { eventId } = useParams<{ eventId: string }>()
  const queryClient = useQueryClient()

  const create = (data) => api.postAgendaRow(eventId, data)
    .then((response) => {
      addAgendaRow(queryClient, eventId, response.data)
    })
    .then(showUpdatedToast)

  const deleteAgendaRow = (speakerId) => api.deleteAgendaRow(eventId, speakerId)
    .then(() => {
      removeAgendaRow(queryClient, eventId, speakerId)
    })
    .then(showUpdatedToast)

  const update = (data) => api.putAgendaRow(eventId, data.id, data)
    .then((response) => {
      updateAgendaRow(queryClient, eventId, response.data)
    })
    .then(showUpdatedToast)

  const onSubmit = (values) => {
    const data = {
      ...values,
      type: agendaRowType,
    }

    const resetAndHide = () => {
      onHide()
    }

    if (agendaRow) {
      return update(data).then(resetAndHide)
    }

    return create(data).then(resetAndHide)
  }

  switch (agendaRowType) {
    case AGENDA_ROW_TYPES.heading:
      return (
        <AgendaHeadingForm
          agendaHeading={agendaRow as AgendaHeading}
          onHide={onHide}
          onSubmit={onSubmit}
          onDelete={deleteAgendaRow}
        />
      )
    case AGENDA_ROW_TYPES.item:
      return (
        <AgendaItemForm
          agendaItem={agendaRow as AgendaItem}
          onHide={onHide}
          onSubmit={onSubmit}
          onDelete={deleteAgendaRow}
          eventSpeakers={speakers}
        />
      )
    default:
      return null
  }
}

export default AgendaRowForm
