import React from "react"
import { Box } from "@chakra-ui/react"
import Icon from "components/elements/Icon"

type Props = {
  title: string;
  icon: string;
  selected?: boolean;
}

const ToggleButton = ({
  title, icon, selected,
}: Props) => (
  <Box
    borderWidth={1}
    borderRadius="md"
    textAlign="center"
    py={4}
    bgColor={selected ? "blue.50" : ""}
    borderColor={selected ? "blue.400" : "gray.500"}
    color={selected ? "blue.400" : "gray.600"}
  >
    <Icon icon={icon} size={6} />
    <Box>{title}</Box>
  </Box>
)

export default ToggleButton
