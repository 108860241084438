import React from "react"
import Icon from "components/elements/Icon"
import { Guest, GuestStatus, ModalName } from "sharedTypes"
import PopoverMenuItem from "components/dialogs/PopoverMenuItem"
import Popover from "components/dialogs/Popover"
import { useDisclosure } from "@chakra-ui/react"
import useFeatureFlag from "services/featureFlags"
import useModal from "services/useModal"
import DropdownToggle from "../DropdownToggle"

type StatusOption = {
  key: number
  name: string
  color: string
}

type Props = {
  guest: Guest;
  updateGuest: (params: {}) => Promise<void>
  disabled: boolean
}

const StatusUpdater = ({
  updateGuest, guest, disabled,
}: Props) => {
  const { waitlistedStatus: waitlistedStatusEnabled } = useFeatureFlag()
  const disclosure = useDisclosure()
  const showModal = useModal()

  const statuses = [
    { key: GuestStatus.Unconfirmed, name: "Unconfirmed", color: "status.unconfirmed" },
    { key: GuestStatus.Confirmed, name: "Confirmed", color: "status.confirmed" },
    waitlistedStatusEnabled ? { key: GuestStatus.Waitlisted, name: "Waitlisted", color: "status.waitlisted" } : null,
    { key: GuestStatus.Declined, name: "Declined", color: "status.declined" },
  ].filter(Boolean) as StatusOption[]

  const status = statuses.find(({ key }) => key === guest.status) || statuses[0]

  const onChange = (guestStatus) => {
    showModal(ModalName.UpdateGuestStatus, { selectedStatusValue: guestStatus, guest, updateGuest })
  }

  return (
    <Popover
      isDisabled={disabled}
      header="Confirmation Status"
      arrow
      trigger={(
        <DropdownToggle
          icon={<Icon icon="dot" mr={2} color={status.color} />}
          title={status.name}
          disabled={disabled}
        />
      )}
      body={
         statuses.map(({ key, name, color }) => (
           <PopoverMenuItem
             key={key}
             value={key}
             icon="dot"
             iconColor={color}
             selected={guest.status === key}
             onChange={onChange}
           >
             {name}
           </PopoverMenuItem>
         ))
      }
      {...disclosure}
    />
  )
}

export default StatusUpdater
