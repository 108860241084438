import React, { ReactNode, RefObject } from "react"
import { Box, HStack } from "@chakra-ui/react"
import Button from "./Buttons/Button"

type Props = {
  imagePreview: ReactNode,
  fileInputRef: RefObject<HTMLInputElement>
  removed: boolean
  onRemove: () => void
  uploadButtonText?: string
  removeButtonText?: string
  disabled?: boolean
}

const ImageUploadComponent = ({
  imagePreview, fileInputRef, removed, onRemove, uploadButtonText = "Upload", removeButtonText = "Remove", disabled,
}: Props) => (
  <HStack>
    {imagePreview && !removed && <Box>{imagePreview}</Box>}
    <Button
      variant="outline"
      size="sm"
      onClick={() => fileInputRef.current!.click()}
      isDisabled={disabled}
    >{uploadButtonText}
    </Button>
    {imagePreview && !removed && (
      <Button
        variant="outline"
        size="sm"
        onClick={onRemove}
        isDisabled={disabled}
      >{removeButtonText}
      </Button>
    )}
  </HStack>
)

export default ImageUploadComponent
