import React from "react"
import { Box, Text } from "@chakra-ui/react"
import LabelRedefinition from "modals/FormForm/LabelRedefinition"
import { Control, useController } from "react-hook-form"

type Props = {
  label?: string
  defaultTranslation: string
  fieldName: string
  control: Control<any, any>
  editable?: boolean
}

const TranslationField = ({
  label, defaultTranslation, fieldName, control, editable,
}: Props) => {
  const { field } = useController<any>({ name: fieldName, control })

  const textToShow = field.value ?? defaultTranslation

  return (
    <Box mb={6}>
      <Box mb={2}>
        <Text fontSize="sm" fontWeight="semibold">{label}</Text>
      </Box>
      {editable ? (
        <LabelRedefinition
          defaultTranslation={defaultTranslation}
          name={fieldName}
          control={control}
        />
      ) : <Text>{textToShow}</Text>}

    </Box>
  )
}

export default TranslationField
