import React from "react"
import { Stack, Text } from "@chakra-ui/react"
import { postCustomDomain } from "api/events"
import * as Yup from "yup"
import { addCustomDomain } from "context/actions"
import useCurrentAccount from "services/useCurrentAccount"
import { useQueryClient } from "react-query"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import EmailField from "components/ReactHookForm/EmailField"
import { CustomDomain } from "sharedTypes"

const CustomDomainForm = ({ eventId, onClose }) => {
  const currentAccount = useCurrentAccount()
  const queryClient = useQueryClient()

  const onSubmit = (customDomain: CustomDomain) => postCustomDomain(
    eventId, customDomain,
  ).then(({ data }) => {
    addCustomDomain(queryClient, data)
    onClose()
  })

  const existingDomains = currentAccount.customDomains.map((d) => d.domain)
  const CustomDomainSchema = Yup.object().shape({
    email: Yup
      .string()
      .email()
      .required()
      .test((value) => {
        if (!value || !value.includes("@")) { return false }

        return !existingDomains.includes(value.split("@").slice(1).join())
      }),
  })

  return (
    <ModalForm
      title="Add Custom Domain"
      onSubmit={onSubmit}
      onClose={onClose}
      submitText="Start Verification Process"
      validationSchema={CustomDomainSchema}
      size="lg"
    >
      {({ control }) => (
        <Stack spacing={3}>
          <Text>Enter the email address for the domain you wish to verify:</Text>
          <EmailField name="email" control={control} placeholder="Email address" />
        </Stack>
      )}
    </ModalForm>
  )
}

export default CustomDomainForm
