import React from "react"
import Icon from "components/elements/Icon"
import Button from "components/Buttons/Button"
import CopyToClipboard from "components/elements/CopyToClipboard"
import WebsiteLink from "components/elements/WebsiteLink"
import { Box, Flex } from "@chakra-ui/react"
import { ModalName, Website } from "sharedTypes"
import useModal from "services/useModal"
import useWebsiteUrl from "utilities/useWebsiteUrl"
import Popover from "../../../dialogs/Popover"

interface Props {
  website: Website
}

const WebsiteStatusIndicator = ({ website }: Props) => {
  const showModal = useModal()

  const url = useWebsiteUrl(website)

  if (!website) {
    return null
  }

  const { urlPath, published } = website

  let iconColor = "gray.300"
  if (published) {
    iconColor = "green.300"
  } else if (urlPath) {
    iconColor = "orange.300"
  }

  return (
    <Box
      pos="absolute"
      zIndex={10}
      h={5}
      mr={6}
      mt={2}
      right={0}
      top={0}
      bottom={0}
    >
      {urlPath && (
      <Popover
        trigger={(
          <Icon
            color={iconColor}
            size={3.5}
            icon="dot"
            cursor={!urlPath ? "pointer" : ""}
            onClick={!urlPath ? () => showModal(ModalName.WebPageURL) : () => {}}
          />
        )}
        placement="right"
        triggerEvent="hover"
        body={(
          <Box px={14} py={6} textAlign="center">
            <Box mb={5} whiteSpace="nowrap">Website {published ? "Published" : "Draft"}:<br />
              <WebsiteLink website={website} />
            </Box>
            <Flex justify="center">
              <Box mr={3}>
                <CopyToClipboard
                  size="xs"
                  type="button"
                  variant="outline"
                  text={url}
                  leftIcon="copy"
                >
                  Copy
                </CopyToClipboard>
              </Box>
              <Button
                size="xs"
                variant="outline"
                onClick={() => window.open(url)}
                leftIcon="view"
              >
                View
              </Button>
            </Flex>
          </Box>
        )}
        contentWidth="auto"
      />
      )}
    </Box>
  )
}

export default WebsiteStatusIndicator
