import { useHistory } from "react-router-dom"
import {
  Flex, Heading, Image, Text,
} from "@chakra-ui/react"
import React from "react"
import upgradeCompleteArt from "../assets/images/upgrade-complete-art.png"
import Button from "../components/Buttons/Button"
import { eventsPath } from "../utilities/routes"

type Props = {
  onClickBack?: () => void
}

export const UpgradeSuccessful = ({ onClickBack }: Props) => {
  const history = useHistory()

  const handleClickBack = () => {
    history.push(eventsPath())
    if (onClickBack) { onClickBack() }
  }

  return (
    <Flex direction="column" align="center" justify="center" h="full" px={20} py={14}>
      <Image w={80} src={upgradeCompleteArt} />
      <Heading mt={10} mb={2} size="lg" fontWeight="semibold">Upgrade Complete!</Heading>
      <Text mb={12} fontSize="lg">Thank you! Your Diobox plan was successfully upgraded!</Text>
      <Button onClick={handleClickBack}>Back to Events</Button>
    </Flex>
  )
}

export default UpgradeSuccessful
