import { useEffect } from "react"
import { useDeepCompareEffect } from "ahooks"
import { Control, FieldValues, useWatch } from "react-hook-form"

type Props<T extends FieldValues> = {
  onChange: (params) => void
  control: Control<T, object>
}

const AutoSave = <T extends FieldValues, >({ onChange, control }: Props<T>) => {
  const { selectedBlockType, ...values } = useWatch<any>({ control })

  useDeepCompareEffect(() => {
    onChange({
      ...values,
      ...(values.size && { size: parseInt(values.size, 10) }),
      ...(values.scale && { scale: parseInt(values.scale, 10) }),
      ...(values.paddingTop && { paddingTop: parseInt(values.paddingTop, 10) }),
      ...(values.paddingBottom && { paddingBottom: parseInt(values.paddingBottom, 10) }),
      ...(values.lineHeight && { lineHeight: parseFloat(values.lineHeight) }),
    })
  }, [values])

  useEffect(() => {
    if (!values.rsvpLogo) {
      return
    }

    onChange({ "invitation[rsvpLogo]": values.rsvpLogo })
  }, [values.rsvpLogo])

  return null
}

export default AutoSave
