import { QuestionTypes } from "sharedTypes"

export const DEFAULT_SENDER_EMAIL = "events@diobox.com"
export const CURRENCY_OPTIONS = [
  { label: "AUD - Australian Dollar (A$)", value: "AUD", symbol: "$" },
  { label: "CAD - Canadian Dollar (C$)", value: "CAD", symbol: "$" },
  { label: "CHF - Swiss Franc (CHF)", value: "CHF", symbol: "CHF" },
  { label: "EUR - Euro (€)", value: "EUR", symbol: "€" },
  { label: "GBP - British Pound (£)", value: "GBP", symbol: "£" },
  { label: "HKD - Hong Kong Dollar (HK$)", value: "HKD", symbol: "$" },
  { label: "ILS - Israeli New Sheqel (₪)", value: "ILS", symbol: "₪" },
  { label: "INR - Indian rupee (₹)", value: "INR", symbol: "₹" },
  { label: "MXN - Mexican Peso (MEX$)", value: "MXN", symbol: "$" },
  { label: "NZD - New Zealand Dollar (NZ$)", value: "NZD", symbol: "$" },
  { label: "USD - United States Dollar (US$)", value: "USD", symbol: "$" },
  { label: "ZAR - South African Rand (R)", value: "ZAR", symbol: "R" },
]

export const INDUSTRY_OPTIONS = [
  "Art",
  "Corporate",
  "Education",
  "Entertainment",
  "Event Production",
  "Fashion",
  "Film & TV",
  "Finance",
  "Government",
  "Hospitality",
  "Media",
  "Museum",
  "Nightlife",
  "Nonprofit",
  "PR & Agency",
  "Real Estate",
  "Restaurant",
  "Retail",
  "Society",
  "Sports",
  "Technology",
  "Tourism",
  "Wedding",
  "Other",
]

export const YEARLY_EVENTS_OPTIONS = [
  "Single event",
  "2-4",
  "5-10",
  "11-24",
  "25+",
]

export const TEAM_SIZE_OPTIONS = [
  "Just me",
  "2-4",
  "5-9",
  "10+",
]

export const FEATURE_OPTIONS = [
  "Manage Guest Lists & Check-In",
  "Create Registration Pages",
  "Send Emails & Invitations",
  "Sell Tickets",
  "Print Name Badges",
  "Create Seating Charts",
]

export const SOURCE_OPTIONS = [
  "Search Engine",
  "Social Media",
  "App Store",
  "Blog or Publication",
  "Recommend by friend or colleague",
  "Invited to an event that used Diobox",
  "Other",
]

export const AGENDA_ROW_TYPES = {
  heading: "AgendaHeading",
  item: "AgendaItem",
}

export const IMPORT_GUEST_HEADERS = [
  { key: "first_name", value: "First Name" },
  { key: "last_name", value: "Last Name" },
  { key: "email", value: "Email" },
  { key: "phone", value: "Phone" },
  { key: "company_name", value: "Company" },
  { key: "company_title", value: "Title" },
  { key: "gender", value: "Gender" },
  { key: "line1", value: "Address Line 1" },
  { key: "line2", value: "Address Line 2" },
  { key: "city", value: "City" },
  { key: "state", value: "State" },
  { key: "zip", value: "Zip" },
  { key: "country", value: "Country" },
  { key: "notes", value: "Notes" },
  { key: "list", value: "Guest List" },
  { key: "status", value: "Status" },
  { key: "gender_unknown_guests", value: "Plus-Ones" },
  { key: "table", value: "Table Name" },
]

export enum PusherEvent {
  EVENT_CLONED = "event_cloned",
  EVENT_GROUP_CREATED = "event_group_created",
  EVENT_GROUP_DELETED = "event_group_deleted",
  EVENT_GROUP_UPDATED = "event_group_updated",
  EVENT_UPDATED = "event_updated",
  GUESTS_DELETED = "guests_deleted",
  GUESTS_UPDATED = "guests_updated",
  GUEST_CREATED = "guest_created",
  GUEST_UPDATED = "guest_updated",
  GUEST_DELETED = "guest_deleted",
  IMPORT_COMPLETED = "import_completed",
  IMPORT_FAILED = "import_failed",
  INVITATION_CREATED = "invitation_created",
  INVITATION_DELETED = "invitation_deleted",
  INVITATION_UPDATED = "invitation_updated",
  TABLE_CREATED = "table_created",
  TABLE_DELETED = "table_deleted",
  TABLE_UPDATED = "table_updated",
  TICKET_CREATED = "ticket_created",
  TICKET_DELETED = "ticket_deleted",
  TICKET_ORDER_CREATED = "ticket_order_created",
  TICKET_ORDER_UPDATED = "ticket_order_updated",
  TICKET_ORDER_DELETED = "ticket_order_deleted",
  TICKET_TYPE_CREATED = "ticket_type_created",
  TICKET_TYPE_DELETED = "ticket_type_deleted",
  TICKET_TYPE_UPDATED = "ticket_type_updated",
  TRANSACTION_CREATED = "transaction_created",
  TRANSACTION_DELETED = "transaction_deleted",
  TRANSACTION_UPDATED = "transaction_updated",
  WEBSITE_VIEWS_COUNT_UPDATED = "website_views_count_updated",
}

export const EMAIL_FONTS = [
  "Arial",
  "Verdana",
  "Helvetica",
  "Tahoma",
  "Trebuchet MS",
  "Times New Roman",
  "Georgia",
  "Garamond",
  "Courier New",
  "Brush Script MT",
]

export const EMAIL_DIVIDER_STYLES = [
  "solid",
  "dashed",
  "dotted",
]

export const EMAIL_ALIGNMENT_OPTIONS = [
  "left",
  "center",
  "right",
]

export const MERGE_TAGS = [
  { id: "FIRST_NAME", label: "First Name" },
  { id: "LAST_NAME", label: "Last Name" },
  { id: "FULL_NAME", label: "Full Name" },
  { id: "EMAIL_ADDRESS", label: "Email Address" },
  { id: "COMPANY", label: "Company" },
  { id: "TITLE", label: "Title" },
  { id: "TABLE_NAME", label: "Table Name" },
  { id: "SEATING_INFO", label: "Seating Info" },
  { id: "CONFIRMATION_STATUS", label: "Confirmation Status" },
  { id: "CONFIRMATION_URL", label: "Confirmation URL" },
]

export const COLOR_SCHEMES = {
  dark: ["#000000", "#163160", "#4B72D3", "#417505", "#D0021B", "#DE9215", "#4A4A4A"],
  light: ["#FFFFFF", "#EDEDED", "#FFFDE5", "#F6FCFF", "#FFF5F5", "#F7F6F7", "#FBF6F1"],
}

export const SIDEBAR_WIDTHS = {
  sm: "25%",
  md: "33.4%",
  lg: "40%",
  xl: "50%",
}

export const EMAIL_TYPES = {
  "email-only": "Standard Email",
  "email-rsvp": "Private Invitation",
}

export enum Boolean {
  True = "true",
  False = "false"
}

export const questionTypeIcons = {
  [QuestionTypes.MultipleAnswer]: "form-checkbox",
  [QuestionTypes.DropdownMenu]: "form-dropdown",
  [QuestionTypes.SingleAnswer]: "form-radiobutton",
  [QuestionTypes.Terms]: "form-terms",
  [QuestionTypes.SingleLineText]: "form-textfield",
  [QuestionTypes.ParagraphText]: "form-paragraph",
  [QuestionTypes.FileUpload]: "form-attachment",
  [QuestionTypes.TextBlock]: "text-block",
}
