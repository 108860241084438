import React from "react"
import { Box, Text } from "@chakra-ui/react"
import {
  DioboxEvent, MessageTemplateGroup, Form, FormType,
} from "sharedTypes"
import useCurrentAccount from "services/useCurrentAccount"
import requiredSubscription from "utilities/requiredSubscription"
import { Control } from "react-hook-form"
import UpgradeAlert from "components/elements/UpgradeAlert"
import TemplateOption from "components/MessageTemplates/TemplateOption"

type Props = {
  onSelectTemplate: (selectedTemplateId: string, formType?: FormType) => void
  control: Control<any>
  event: DioboxEvent
  forms: Form[]
  group?: MessageTemplateGroup
  selectedTemplateId?: string
}

const SelectedGroup = ({
  onSelectTemplate, control, event, group, forms, selectedTemplateId,
}: Props) => {
  const { subscription } = useCurrentAccount()

  if (!group) {
    return null
  }

  const {
    name, description, templates, requiredPlan,
  } = group

  const requiredSubscriptionName = requiredSubscription(subscription, requiredPlan)

  return (
    <>
      <Box pb={2}>
        <Text fontSize="2xl" mb={2}>{name}</Text>
        <Text mb={2}>{description}</Text>
        {requiredSubscriptionName && (
          <UpgradeAlert noBorder mb={3} text={`Requires ${requiredSubscriptionName} Subscription`} />
        )}
      </Box>
      {templates.map(({
        id, features, icon, name: templateName, formType,
      }) => (
        <TemplateOption
          key={id}
          onClick={() => onSelectTemplate(id, formType)}
          selected={selectedTemplateId === id}
          icon={icon}
          description={templateName}
          features={features}
          control={control}
          formType={formType}
          event={event}
          forms={forms}
        />
      ))}
    </>
  )
}

export default SelectedGroup
