import { Wrap, WrapItem } from "@chakra-ui/react"
import ListItem from "pages/EventsIndex/GridItem"
import React from "react"
import { DioboxEvent } from "../../sharedTypes"

type Props = {
  events: DioboxEvent[];
}

const GridView = ({ events }: Props) => (
  <Wrap spacing={12} px={10} py={7}>
    {events.map((event) => (
      <WrapItem w={48} key={event.id} cursor="pointer" textAlign="center">
        <ListItem
          eventId={event.id}
          title={event.title}
          imageUrl={event.website.coverImageUrl}
          startDate={event.startDate}
          guestCount={event.guestCount}
        />
      </WrapItem>
    ))}
  </Wrap>
)

export default GridView
