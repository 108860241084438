import React, { ReactNode } from "react"
import { Flex, Text, HStack } from "@chakra-ui/react"
import Section from "./Section"

type Props = {
  children?: string | ReactNode;
  actionButtons?: ReactNode[];
  fontSize?: string;
}

const PageHeader = ({ children, actionButtons, fontSize = "3xl" }: Props) => (
  <Section>
    <Flex align="center" justify="space-between" pb={4}>
      <Text fontSize={fontSize}>{children}</Text>
      {actionButtons
      && (
      <HStack ml={8} spacing={4}>
        {actionButtons.map((button, index) => <span key={index}>{button}</span>)}
      </HStack>
      )}
    </Flex>
  </Section>
)

export default PageHeader
