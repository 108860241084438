import React from "react"
import { Box, Text } from "@chakra-ui/react"
import RadioButtons, { RadioButtonsOption } from "components/ReactHookForm/RadioButtons"
import { ButtonBlockAction } from "sharedTypes"
import { Control, useController } from "react-hook-form"
import { useForms } from "queries/forms"
import Select from "components/ReactHookForm/Select"
import { getFormsGroupedByType } from "utilities/forms"
import UrlField from "components/ReactHookForm/UrlField"
import { useCurrentEvent } from "queries/events"

type Props = {
  type: "image" | "button"
  control: Control<any>
}

const ActionSelect = ({
  type, control,
}: Props) => {
  const { data: event } = useCurrentEvent()
  const { data } = useForms(event.id)
  const { field: { value: actionSelectValue } } = useController({ name: "action", control })
  const { field: { value: formIdValue } } = useController({ name: "formId", control })
  const forms = data?.data ?? []
  const groupedForms = getFormsGroupedByType(forms, event)

  const formSelectOption = {
    label: "Diobox Form",
    value: ButtonBlockAction.Form,
    additionalElement: actionSelectValue === ButtonBlockAction.Form && (
    <Select
      name="formId"
      control={control}
      includeBlank={!forms.find(({ externalId }) => externalId === formIdValue)}
      options={groupedForms}
    />
    ),
  }
  const otherLinkInputOption = {
    label: "Other Link",
    value: ButtonBlockAction.Other,
    additionalElement: actionSelectValue === ButtonBlockAction.Other && (
      <UrlField name="otherLink" control={control} placeholder="https://diobox.com" />
    ),
  }
  const eventPageOption = { label: "Diobox Event Page", value: ButtonBlockAction.Website }
  const noLinkOption = { label: "No Link", value: "" }

  const options: RadioButtonsOption[] = []

  if (type === "image") {
    options.push(noLinkOption)
  }

  options.push(
    formSelectOption,
    eventPageOption,
    otherLinkInputOption,
  )

  return (
    <>
      <RadioButtons
        label="Action"
        name="action"
        control={control}
        options={options}
      />
      <Box ml={24}>
        <Text>Your buttons can directly launch any of your Diobox Forms,
          link to your Diobox Event Page, or any other external link.
        </Text>
      </Box>
    </>
  )
}

export default ActionSelect
