import React from "react"
import Icon from "components/elements/Icon"
import { CheckInTransaction } from "sharedTypes"
import { Box, Text } from "@chakra-ui/react"
import Popover from "components/dialogs/Popover"
import { asDateWithTZ } from "utilities/strings"
import {
  ActivityPopoverContent,
  ActivityPopoverDeleteTransaction,
  ActivityPopoverRow,
  ActivityPopoverSection,
} from "components/dialogs/ActivityPopover"
import { useCurrentEvent } from "queries/events"

type Props = {
  item: CheckInTransaction;
}

const CheckIn = ({
  item, item: {
    userId, checkInLocationId, transactionDate,
  },
}: Props) => {
  const { data: currentEvent } = useCurrentEvent()

  const locationName = checkInLocationId
    ? currentEvent.checkInLocations.find(({ id }) => id === checkInLocationId)?.name : null
  const user = currentEvent.users.find((eventUser) => eventUser.user?.id === userId)?.user

  return (
    <Popover
      placement="auto"
      gutter={12}
      header="Guest Activity"
      trigger={(
        <Trigger
          locationName={locationName}
        />
      )}
      body={(
        <ActivityPopoverContent>
          <ActivityPopoverSection>
            <ActivityPopoverRow label="Date/Time" value={asDateWithTZ(transactionDate, currentEvent.timezone)} />
            {user && (
            <ActivityPopoverRow
              label="Admitted By"
              value={`${user.firstName} ${user.lastName}`}
            />
            )}
          </ActivityPopoverSection>
          <ActivityPopoverDeleteTransaction item={item} />
        </ActivityPopoverContent>
      )}
    />
  )
}

const Trigger = ({ locationName }) => (
  <Box display="inline-block">
    <Text mb={2} color="gray.900">
      <Icon
        size={6}
        icon="check-in"
        color="green.500"
        mr={2}
      /> {locationName ?? "Check-In"}
    </Text>
  </Box>
)

export default CheckIn
