import React, { ReactNode } from "react"
import { Box } from "@chakra-ui/react"

interface Props {
  status?: "error" | "success" | "warning" | "info"
  children: string | ReactNode
  mt?: string | number
  mb?: string | number
}

const Alert = ({
  status = "warning", mt, mb, children,
}: Props) => {
  const bgColor = {
    error: "red.100",
    success: "green.100",
    warning: "yellow.100",
    info: "gray.100",
  }

  return (
    <Box
      bgColor={bgColor[status]}
      px={4}
      py={3}
      mt={mt}
      mb={mb}
      borderWidth={1}
      borderRadius="base"
    >
      {children}
    </Box>
  )
}

export default Alert
