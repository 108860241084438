import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Account, ModalName, User } from "sharedTypes"
import {
  eventsPath, accountPath, accountPlanPath,
} from "utilities/routes"
import Icon from "components/elements/Icon"
import Avatar from "components/elements/Avatar"
import {
  Flex, Box, Heading, Text, Center, List, ListItem, Stack,
} from "@chakra-ui/react"
import EditProfile from "modals/EditProfile"
import { useIntercom } from "react-use-intercom"
import PlanText from "components/elements/PlanText"
import Popover from "components/dialogs/Popover"
import useModal from "services/useModal"
import AccountLogo from "components/elements/AccountLogo"
import { DIOBOX_HELP_LINK, DIOBOX_UPDATES_LINK } from "utilities/externalLinks"

type Props = {
  currentUser: User
  currentAccount: Account
  signOut: () => void
  compact: boolean
}

const NavigationDropdown = ({
  currentUser, currentAccount, signOut, compact,
}: Props) => {
  const showModal = useModal()
  const [showEditProfile, setShowEditProfile] = useState(false)
  const { show: showIntercom } = useIntercom()
  const { admin, logoUrl } = currentAccount
  const history = useHistory()

  const logo = (onClick) => (
    <AccountLogo logoUrl={logoUrl} accountName={currentAccount.name} onClick={onClick} />
  )

  const toggle = () => (
    <Flex m={4}>
      {logo(() => {})}
      <Box ml={2}>
        <Heading
          fontSize="md"
          color="white"
          wordBreak="break-word"
        >{!compact && currentAccount.name}
        </Heading>
        {!compact && <Text fontSize="sm" color="white">{currentUser.firstName} {currentUser.lastName}</Text>}
      </Box>
      {!compact && <Icon color="white" size={3} ml="auto" icon="down-arrow" />}
    </Flex>
  )

  const planType = currentAccount.subscription?.type || "Free"

  return (
    <Box mb={4}>
      <Popover
        trigger={toggle()}
        contentWidth="auto"
        gutter={16}
        closeOnClick
        body={(
          <Box w={275} pt={2}>
            <Box my={3} borderBottomWidth={1}>
              <Box px={5} mb={3} textAlign="center">
                <Center mb={2}>{logo(() => history.push(accountPath()))}</Center>
                <Heading
                  mb={1}
                  fontSize="md"
                  wordBreak="break-word"
                  cursor="pointer"
                  onClick={() => history.push(accountPath())}
                >{currentAccount.name}
                </Heading>
                <PlanText
                  text={`${planType} Plan`}
                  cursor="pointer"
                  onClick={planType !== "Corporate" ? () => showModal(ModalName.Upgrade) : () => { history.push(accountPlanPath()) }}
                />
              </Box>
              <List as={Stack} p={4} spacing={3}>
                <ListItem as={Link} to={eventsPath()}>
                  <MenuIcon name="events" color="blue" /> Events
                </ListItem>
                {admin && (
                <ListItem as={Link} to={accountPath()}>
                  <MenuIcon name="settings" color="gray" /> Account
                </ListItem>
                )}
              </List>
            </Box>
            <Box px={5} mb={3} textAlign="center" onClick={() => setShowEditProfile(true)} cursor="pointer">
              <Avatar
                w="40px"
                h="40px"
                name={[currentUser.firstName, currentUser.lastName].join(" ")}
                src={currentUser.photoUrl}
                mb={2}
              />
              <Heading fontSize="md" wordBreak="break-word">{currentUser.firstName} {currentUser.lastName}</Heading>
              <Text fontSize="sm" color="gray.700">{currentUser.email}</Text>
            </Box>
            <List as={Stack} p={4} spacing={3}>
              <ListItem as="button" textAlign="left" onClick={() => showModal(ModalName.SwitchAccount)}>
                <MenuIcon name="switch-account" /> Switch Account
              </ListItem>
              <ListItem as="a" href={DIOBOX_UPDATES_LINK} target="_blank" rel="noopener noreferrer">
                <MenuIcon name="checkin-alert" /> What&apos;s New
              </ListItem>
              <ListItem as="a" href={DIOBOX_HELP_LINK} target="_blank" rel="noopener noreferrer">
                <MenuIcon name="help-center" /> Help Center
              </ListItem>
              <ListItem as="button" textAlign="left" onClick={showIntercom}>
                <MenuIcon name="chat" /> Message Us
              </ListItem>
              <ListItem as="button" textAlign="left" onClick={signOut}>
                <MenuIcon name="sign-out" /> Sign Out
              </ListItem>
            </List>
          </Box>
        )}
      />
      {showEditProfile
      && (
      <EditProfile
        user={currentUser}
        onClose={() => setShowEditProfile(false)}
      />
      )}
    </Box>
  )
}

const MenuIcon = ({ name, color = "black" }) => <Icon icon={name} mr={2} mb={1} color={`${color}.500`} />

export default NavigationDropdown
