import React from "react"
import { Box } from "@chakra-ui/react"
import { SpacerBlock } from "sharedTypes/builder"

const Spacer = ({ block: { data } }: { block: SpacerBlock}) => (
  <Box
    h={`${data.size * 10}px`}
    bgColor={data.blockColor}
  />
)

export default Spacer
