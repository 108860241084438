import {
  Flex, Box, Text, Avatar,
} from "@chakra-ui/react"
import React from "react"
import { User } from "sharedTypes"

type Props = User

const UserListItem = ({
  id, firstName, lastName, photoUrl, email,
}: Props) => (
  <Flex key={id}>
    <Avatar
      mr={3}
      src={photoUrl}
      name={[firstName, lastName].join(" ")}
    />
    <Box>
      <Text fontSize="xl" fontWeight="semibold">{firstName} {lastName}</Text>
      <Text>{email}</Text>
    </Box>
  </Flex>
)

export default UserListItem
