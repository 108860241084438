import React from "react"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import { Control, useWatch } from "react-hook-form"
import EditorSection from "components/Builder/components/EditorSection"
import Upload from "components/Builder/components/Upload"
import ActionSelect from "components/Builder/components/ActionSelect"
import AlignmentSelect from "components/Builder/components/AlignmentSelect"
import PaddingInputs from "components/Builder/components/PaddingInputs"
import ColorSelect from "components/ReactHookForm/ColorSelect/ColorSelect"
import TextField from "components/ReactHookForm/TextField"
import Scale from "components/ReactHookForm/Scale"

type Props = {
  imageUploadUrl: string
  control: Control
}

const ImageFields = ({
  imageUploadUrl, control,
}: Props) => {
  const uploadedImageSource = useWatch({ control, name: "source" })
  const hasUploadedImage = Boolean(uploadedImageSource)

  return (
    <>
      <SidePanelHeader>Image Block</SidePanelHeader>
      <EditorSection>
        <Upload name="source" control={control} uploadUrl={imageUploadUrl} />
      </EditorSection>
      {hasUploadedImage && (
      <>
        <EditorSection>
          <ActionSelect
            type="image"
            control={control}
          />
        </EditorSection>
        <EditorSection>
          <AlignmentSelect control={control} />
        </EditorSection>
        <EditorSection>
          <PaddingInputs control={control} />
          <Scale
            name="scale"
            control={control}
          />
        </EditorSection>
        <EditorSection>
          <ColorSelect
            label="Block Color"
            name="blockColor"
            control={control}
            colorScheme="light"
          />
        </EditorSection>
        <EditorSection>
          <TextField
            name="alt"
            control={control}
            label="Alt text"
            variant="outline"
          />
        </EditorSection>
      </>
      )}
    </>
  )
}

export default ImageFields
