import React, { useState, MouseEvent } from "react"
import { useController } from "react-hook-form"

const withMergeTags = (FieldsComponent, name) => ({ control }) => {
  const { field } = useController({ name, control })
  const [selection, setSelection] = useState<{start: number, end: number}>({ start: 0, end: 0 })

  const handleSelectionChange = (event: MouseEvent<HTMLInputElement>) => {
    const {
      selectionStart,
      selectionEnd,
    } = event.target as HTMLInputElement

    setSelection({ start: selectionStart || 0, end: selectionEnd || 0 })
  }
  const insertTag = (tag) => {
    field.onChange(
      `${field.value.slice(0, selection.start)}{{${tag}}}${field.value.slice(selection.end)}`,
    )
  }

  return (
    <FieldsComponent {...{
      name, control, handleSelectionChange, insertTag,
    }}
    />
  )
}

export default withMergeTags
