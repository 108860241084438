import { findIndex } from "lodash"
import { Account } from "sharedTypes"
import { useCurrentUser } from "../queries/users"
import { currentAccountId } from "./authentication"

export default (): Account => {
  const currentUser = useCurrentUser()

  if (!currentUser?.accounts) {
    return {} as Account
  }

  let accountIndex = findIndex(currentUser.accounts, { id: currentAccountId() || "" })
  if (accountIndex === -1) {
    accountIndex = 0
  }

  return currentUser.accounts[accountIndex]
}
