import React from "react"
import {
  DioboxEvent, Form, Email, ModalName,
} from "sharedTypes"
import {
  Flex, Heading, Stack, Text,
} from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import { asNumber } from "utilities/strings"
import { eventEmailReportPath } from "utilities/routes"
import useModal from "services/useModal"
import CardWithTitle from "components/CardWithTitle"
import ViewEmailContent from "components/ViewEmailContent"
import { useQueryClient } from "react-query"
import { putInvitation } from "api/emails"
import { updateEmail } from "context/actions"
import StatRow from "components/StatRow"
import FormIntegrations from "./FormIntegrations/FormIntegrations"
import EmailHeader from "../EmailHeader"

type Props = {
  email: Email
  duplicateEmail: (email: Email) => void
  deleteEmail: (email: Email) => void
  linkedForms: Form[]
  deletedFormsCount: number
  event: DioboxEvent
}

const SentEmail = ({
  email,
  email: { from, sentFromEmail },
  duplicateEmail,
  deleteEmail,
  linkedForms,
  deletedFormsCount,
  event: { id: eventId, timezone },
  event,
}: Props) => {
  const showModal = useModal()
  const history = useHistory()
  const queryClient = useQueryClient()

  const onSubmit = (values) => {
    const { title: emailTitle } = values

    return putInvitation(eventId, email.id, {
      title: emailTitle,
    })
      .then(({ data }) => {
        updateEmail(queryClient, eventId, data)
      })
  }

  return (
    <Stack spacing={9}>
      <Flex justify="space-between">
        <EmailHeader
          email={email}
          fromEmail={`${from} <${sentFromEmail}>`}
          onEditName={() => showModal(ModalName.EditEmailName, { onSubmit, initialValues: email })}
          onViewReport={() => history.push(eventEmailReportPath(eventId, email.id))}
          onExportReport={() => showModal(ModalName.ExportInvitationList, { id: email.id })}
          onDuplicate={() => duplicateEmail(email)}
          onDelete={() => deleteEmail(email)}
          timezone={timezone}
        />
      </Flex>
      <EmailStats email={email} />
      {(linkedForms.length > 0 || deletedFormsCount > 0) && (
        <CardWithTitle title="Form Integration">
          <FormIntegrations
            event={event}
            forms={linkedForms}
            deletedFormsCount={deletedFormsCount}
          />
        </CardWithTitle>
      )}
      <CardWithTitle title="Email Preview" p={0}>
        <ViewEmailContent email={email} />
      </CardWithTitle>
    </Stack>
  )
}

const EmailStats = ({
  email: {
    sentSize,
    complained,
    clicks,
    opened,
    bounced,
    softBounced,
    hardBounced,
  },
}) => (
  <CardWithTitle title="Email Engagement">
    <Flex justify="space-between" mb={6}>
      <Heading fontSize="lg" fontWeight="normal">All Recipients</Heading>
      <Text fontSize="lg">{asNumber(sentSize)}</Text>
    </Flex>
    <StatRow label="Opened" count={opened} total={sentSize} color="emailStatus.opened" />
    <StatRow label="Clicked" count={clicks} total={sentSize} color="emailStatus.clicked" />
    <StatRow label="Unopened" count={sentSize - opened} total={sentSize} color="emailStatus.unopened" />
    {!!complained && <StatRow label="Complained" count={complained} total={sentSize} color="emailStatus.complained" />}
    <StatRow label="Soft Bounced" count={softBounced} total={sentSize} color="emailStatus.softBounced" />
    <StatRow label="Hard Bounced" count={hardBounced} total={sentSize} color="emailStatus.hardBounced" />
    {!!bounced && <StatRow label="Bounced" count={bounced} total={sentSize} color="emailStatus.bounced" />}
  </CardWithTitle>
)

export default SentEmail
