import React from "react"
import NoContent from "components/elements/NoContent"

const NoTables = ({ canEditSeating, onClick }) => {
  const callsToActions = [
    {
      icon: "plus-big",
      text: "Create First Table/Row",
      action: onClick,
    },
  ]

  return (
    <NoContent
      title="Tables & Rows"
      icon="tables-stroke"
      descriptionItems={["Create custom seating charts with real-time guest confirmations, waiting lists, and share them with your event stakeholders."]}
      callsToActions={canEditSeating ? callsToActions : []}
    />
  )
}

export default NoTables
