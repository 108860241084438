import Menu, { MenuHeader } from "components/dialogs/Menu"
import { Box, Flex } from "@chakra-ui/react"
import React, { ReactNode } from "react"

type Props = {
  label: ReactNode;
  title: string;
  description: ReactNode;
  menuItems: ReactNode[];
  canEditSeating: boolean;
  seatNumber?: number;
  labelColor?: string;
}

const SeatItem = ({
  label, title, description, menuItems, seatNumber, labelColor, canEditSeating,
}: Props) => (
  <Menu
    title={title}
    placement="bottom"
    noPadding
    button={(
      <Flex
        align="center"
        color={labelColor}
      >
        {label}
      </Flex>
      )}
    menuItems={[
      <MenuHeader noPadding>
        {seatNumber && <Box p={3}>Seat {seatNumber}</Box>}
        <Flex
          justify="center"
          align="center"
          p={8}
          borderTopWidth={1}
          borderBottomWidth={1}
          backgroundColor="white"
        >
          {description}
        </Flex>
      </MenuHeader>,
      ...menuItems,
    ]}
    disabled={!canEditSeating}
    isLazy
  />
)

export default SeatItem
