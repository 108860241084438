import React from "react"
import { Box } from "@chakra-ui/react"

interface Props {
  header?: any
  footer?: any
  children: any
  clickable?: boolean
  onClick?: () => void
  borderColor?: string
  bgColor?: string
  px?: number
}

const Panel = ({
  header, footer, children, onClick, clickable, borderColor, bgColor = "white", px = 6,
}: Props) => (
  <Box
    mb={5}
    borderWidth={1}
    borderRadius="md"
    borderColor={borderColor}
    bgColor={bgColor}
    cursor={clickable || !!onClick ? "pointer" : "initial"}
    _hover={{ bgColor: clickable || !!onClick ? "blue.50" : bgColor }}
    onClick={onClick}
  >
    { header && <Box borderBottomWidth={1} borderColor={borderColor} px={3} py={2}>{header}</Box>}
    <Box px={px} py={4}>
      { children }
    </Box>
    { footer && <Box borderTopWidth={1} borderColor={borderColor} px={6} py={4}>{footer}</Box>}
  </Box>
)

export default Panel
