import {
  Box, Flex, List, ListItem, VStack, Text,
} from "@chakra-ui/react"
import React, { ReactNode } from "react"
import Link from "components/elements/Link"
import Icon from "components/elements/Icon"
import Button from "../Buttons/Button"

type Props = {
  title: string
  descriptionItems?: (string | ReactNode)[]
  icon?: string
  callsToActions?: {
    text: string,
    icon?: string,
    action?: () => void,
    isButton?: boolean,
    link?: string
  }[]
  footer?: ReactNode
}

const NoContent = ({
  title,
  descriptionItems = [],
  icon,
  callsToActions = [],
  footer,
}: Props) => {
  const descriptionList = descriptionItems.map((descriptionItem, index) => (
    <ListItem mb={5} key={index}>{descriptionItem}</ListItem>
  ))

  const callToActionList = callsToActions.map(({
    icon: ctaIcon, text, action, isButton, link,
  }, index) => (
    <Link
      key={index}
      onClick={action}
      textDecoration="none"
      fontSize="lg"
      href={link}
      isExternal
    >
      <Text as={isButton ? Button : "span"}>{ctaIcon && <Icon icon={ctaIcon} mr={3} />}{text}</Text>
    </Link>
  ))

  return (
    <Flex
      direction="column"
      justify="center"
      minHeight="full"
      textAlign="center"
      align="center"
    >
      <Box w={1 / 3} mb={3}>
        {icon && <Icon icon={icon} size={20} />}
      </Box>
      <Text mb={3} fontSize="2xl" fontWeight="semibold">{title}</Text>
      <List mb={3} fontSize="lg" maxWidth={600}>
        {descriptionList}
      </List>
      <VStack spacing={3}>
        {callToActionList}
      </VStack>
      {footer && footer}
    </Flex>
  )
}

export default NoContent
