import { getInvitationEmailCount } from "api/emails"
import React, { ReactNode, useEffect } from "react"
import {
  DioboxEvent, Form, Email, ModalName,
} from "sharedTypes"
import pluralize from "pluralize"
import {
  Flex, Box, Text, ListItem, UnorderedList,
} from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import { hasValidCustomDomain } from "utilities/strings"
import { DEFAULT_SENDER_EMAIL } from "utilities/enums"
import Alert from "components/elements/Alert"
import Spinner from "components/elements/Spinner"
import useModal from "services/useModal"
import FormIntegrations from "../../SentEmail/FormIntegrations/FormIntegrations"

type Props = {
  event: DioboxEvent
  email: Email
  sendCount: number | null
  setSendCount: (count) => void
  emailDisabledReasons: ReactNode[]
  forms: Form[]
  deletedFormsCount: number
}

const Review = ({
  event, email, sendCount, setSendCount, emailDisabledReasons, forms, deletedFormsCount,
}: Props) => {
  const { id: eventId } = event

  useEffect(() => {
    setSendCount(null)
    getInvitationEmailCount(eventId, email.id).then(({ data }) => {
      setSendCount(data.emailCount)
    })
  }, [])

  if (sendCount === null) {
    return <Spinner />
  }

  return (
    <Flex justify="center" p={8}>
      <Box w={700}>
        {emailDisabledReasons.length > 0
        && (
        <Alert mb={6}>
          <Text fontSize="lg" mb={3} fontWeight="semibold">Cannot send email</Text>
          <UnorderedList>
            {emailDisabledReasons.map((r, i) => <ListItem key={i} mb={2}>{r}</ListItem>)}
          </UnorderedList>
        </Alert>
        )}
        <EmailSection
          event={event}
          email={email}
          sendCount={sendCount}
        />
        {(forms.length > 0 || deletedFormsCount > 0) && (
          <>
            <Flex justify="space-between" align="center" borderBottomWidth={1} mb={4} pb={3}>
              <Text fontSize="xl" fontWeight="bold">Form Integration</Text>
            </Flex>
            <Box ml={8}>
              <FormIntegrations
                event={event}
                forms={forms}
                deletedFormsCount={deletedFormsCount}
                showFormSubmissionsLink={false}
              />
            </Box>
          </>
        )}
      </Box>
    </Flex>
  )
}

const EmailSection = ({
  event, email, sendCount,
}) => {
  const showModal = useModal()

  const senderEmail = () => {
    if (hasValidCustomDomain(event)) {
      return `${email.fromMailbox || event.emailNotificationsSenderMailbox}@${event.customDomain.domain}`
    }

    return DEFAULT_SENDER_EMAIL
  }

  return (
    <Box mb={16}>
      <Flex justify="space-between" align="center" borderBottomWidth={2} pb={3} mb={3}>
        <Text fontSize="xl" fontWeight="bold">Email</Text>
        <Button
          onClick={() => showModal(ModalName.SendTestEmail, { id: email.id })}
          size="xs"
          variant="outline"
        >Send Test Email
        </Button>
      </Flex>
      <Row label="Subject Line" value={email.subject} />
      <Row label="Sender Name" value={email.from} />
      <Row label="Sender Email" value={senderEmail()} />
      <Row
        label="Recipients"
        value={<>{sendCount} {pluralize("guests", sendCount)} with valid {pluralize("emails", sendCount)}</>}
      />
    </Box>
  )
}

const Row = ({ label, value }) => (
  <Flex mb={6} fontSize="xl" pl={8}>
    <Text flexShrink={0} w={210} mr={3}>{label}</Text>
    <Text fontWeight="semibold">{value}</Text>
  </Flex>
)

export default Review
