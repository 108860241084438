import React, { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useHistory } from "react-router"
import {
  Form, FormType, ModalName, DioboxEvent, Translations,
} from "sharedTypes"
import * as api from "api/forms"
import { refetchForms } from "context/actions"
import { eventFormsPath } from "utilities/routes"
import { formCacheKey } from "queries/forms"
import Confirm from "components/dialogs/Confirm/Confirm"
import Menu, { MenuItem } from "components/dialogs/Menu"
import ConfirmDelete from "components/dialogs/Confirm/ConfirmDelete"
import { Divider, Flex } from "@chakra-ui/react"
import { MAX_FORMS_PER_EVENT } from "utilities/constants"
import { errorToast, successToast } from "utilities/toasts"
import Button from "components/Buttons/Button"
import useModal from "services/useModal"
import EmbedModal from "modals/EmbedModal"

type Props = {
  form: Form
  formTypeDisabled: boolean
  event: DioboxEvent
  defaultTranslations: Translations
  updateEvent: (params: {}) => Promise<void>
  formsCount: number
  onClickEditForm: () => void
}

const FormOptions = ({
  form,
  formTypeDisabled,
  event,
  defaultTranslations,
  updateEvent,
  formsCount,
  onClickEditForm,
}: Props) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const showModal = useModal()
  const [showEmbedModal, setShowEmbedModal] = useState(false)

  const { id: eventId } = event
  const {
    externalId: formId, submissionLineItemsCount, disabled: isFormDisabled, type,
  } = form
  const deleteForm = useMutation(
    () => api.deleteForm(eventId, formId), {
      onSuccess: () => {
        refetchForms(queryClient, eventId)
        history.push(eventFormsPath(eventId))
      },
    },
  )

  const setDisabledStatus = useMutation(
    (disabled: boolean) => api.patchForm(eventId, formId, { disabled }), {
      mutationKey: formCacheKey(formId),
      onSuccess: ({ data }) => {
        refetchForms(queryClient, eventId)
        queryClient.setQueryData(formCacheKey(formId), data)
      },
    },
  )

  const duplicateForm = useMutation(
    () => api.duplicateForm(eventId, formId), {
      onSuccess: () => {
        refetchForms(queryClient, eventId)
      },
    },
  )

  const handleDuplicateForm = () => {
    if (formsCount && formsCount >= MAX_FORMS_PER_EVENT) {
      errorToast({ title: `You can create up to ${MAX_FORMS_PER_EVENT} Forms per event` })

      return
    }

    duplicateForm.mutate()
    successToast({ title: "Form successfully duplicated" })
  }

  const setDisabledStatusMenuItem = isFormDisabled ? (
    <Confirm
      confirmationTitle="Enable Form"
      confirmationText="Are you sure you want to enable this form?"
      confirmButtonColorScheme="button.primary"
    >
      <MenuItem
        isDisabled={formTypeDisabled}
        onClick={() => setDisabledStatus.mutate(false)}
      >Enable
      </MenuItem>
    </Confirm>
  ) : (
    <Confirm
      confirmationTitle="Disable Form"
      confirmationText="Are you sure you want to disable this form?"
      confirmButtonColorScheme="button.primary"
    >
      <MenuItem onClick={() => setDisabledStatus.mutate(true)}>
        Disable
      </MenuItem>
    </Confirm>
  )

  const deleteMenuItem = (
    <ConfirmDelete type="Form">
      <MenuItem onClick={() => deleteForm.mutate()} color="red.700">Delete</MenuItem>
    </ConfirmDelete>
  )

  const embedButton = ![FormType.RSVP, FormType.Survey].includes(type) ? [
    <MenuItem
      onClick={() => { setShowEmbedModal(true) }}
    >Embed Form
    </MenuItem>,
    <Divider />] : []

  return (
    <Flex gap={3}>
      <Button
        size="sm"
        variant="outline"
        onClick={onClickEditForm}
      >
        Edit Form
      </Button>
      <Menu
        buttonText="More"
        noPadding
        placement="bottom-end"
        menuItems={[
          <MenuItem
            onClick={() => showModal(ModalName.FormForm, { form })}
          >Edit Attributes
          </MenuItem>,
          <MenuItem
            onClick={() => showModal(ModalName.EditMessaging, {
              formType: type, defaultTranslations, updateEvent, form,
            })}
          >Edit Messaging
          </MenuItem>,
          <Divider />,
          ...embedButton,
          <MenuItem onClick={handleDuplicateForm}>Duplicate Form</MenuItem>,
          <Divider />,
          setDisabledStatusMenuItem,
          <Divider />,
          !submissionLineItemsCount && deleteMenuItem,
        ]}
      />
      {showEmbedModal && (
        <EmbedModal
          hide={() => setShowEmbedModal(false)}
          externalId={form.externalId}
        />
      )}
    </Flex>
  )
}

export default FormOptions
