import React from "react"
import {
  Box, Grid, GridItem, Text,
} from "@chakra-ui/react"
import EditorSection from "components/Builder/components/EditorSection"
import { Control, useController } from "react-hook-form"
import SwitchField from "components/ReactHookForm/SwitchField"
import Select from "components/ReactHookForm/Select"

type Props = {
  control: Control<any>
}

const ManagePlusOnes = ({ control }: Props) => {
  const { field: { value: plusOnesEnabled } } = useController({ name: "plusOnesEnabled", control })

  return (
    <EditorSection>
      <Box mb={4}>
        <Grid
          templateColumns="repeat(5, 1fr)"
          rowGap={2}
          columnGap={3}
        >
          <GridItem colSpan={4}>
            <Text fontSize="lg" fontWeight="bold">Allow Guests to Manage Their Plus-Ones</Text>
          </GridItem>
          <GridItem colSpan={1} justifySelf="end">
            <SwitchField size="lg" control={control} name="plusOnesEnabled" />
          </GridItem>
          <GridItem colSpan={4}>
            <Text>If guests have existing plus-ones, they will be able to edit them. This is
              useful if you want to assign guest plus-ones individually (and potentially differently
              for each guest) before you sent out this invitation.
            </Text>
          </GridItem>
          <GridItem colSpan={1} />
          <GridItem colSpan={4} justifySelf="end">
            <Text>If guests don&apos;t have existing plus-ones, they will be able to register
              new plus-ones up to this limit.
            </Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Select
              control={control}
              name="maxPlusOnes"
              disabled={!plusOnesEnabled}
              options={[...Array(10)].map((_number, index) => {
                const count = index + 1

                return (
                  { label: count.toString(), value: count }
                )
              })}
            />
          </GridItem>
        </Grid>
      </Box>
    </EditorSection>
  )
}

export default ManagePlusOnes
