import React from "react"
import { Box, Text, Flex } from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import UrlField from "components/ReactHookForm/UrlField"
import { Control, useController } from "react-hook-form"
import { DioboxEvent } from "sharedTypes"
import CopyToClipboard from "components/elements/CopyToClipboard"

type Props = {
    control: Control<any>
    event: DioboxEvent
    mapEnabled: boolean
  }

const UrlWithRegenerateLink = ({ control, event, mapEnabled }: Props) => {
  const PORTAL_MAP_URL_KEY = "portalMapUrl"
  const { field } = useController({ name: PORTAL_MAP_URL_KEY, control })

  const handleClick = () => {
    const {
      venue, addressLine1, addressLine2, city, state, zipcode, country,
    } = event
    const addressString = [venue, addressLine1, addressLine2, city, state, zipcode, country].filter((c) => c).join(" ").replace(/ /g, "+")
    const url = `https://maps.google.com/?q=${addressString}`
    field.onChange(url)
  }

  return (
    <>
      <UrlField name={PORTAL_MAP_URL_KEY} control={control} disabled={!mapEnabled} />
      <Box mt={2} mb={5}>
        <Text>
          By default, the map link is generated using your event address and links to Google Maps.
        </Text>
      </Box>
      <Flex mb={8} alignItems="center">
        <Box mr={4}>
          <Button isDisabled={!mapEnabled} variant="outline" onClick={handleClick}>Regenerate Link</Button>
        </Box>
        <Box mr={2}>
          <CopyToClipboard text={field.value} disabled={!mapEnabled} variant="outline">Copy Link</CopyToClipboard>
        </Box>
      </Flex>
    </>
  )
}

export default UrlWithRegenerateLink
