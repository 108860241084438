import React from "react"
import { Heading as ChakraHeading } from "@chakra-ui/react"
import { HeadingBlock } from "sharedTypes/builder"

const Heading = ({ block: { data } }: { block: HeadingBlock }) => {
  const headingSizes = ["2.5em", "2em", "1.75em", "1.5em"]

  return (
    <ChakraHeading
      bgColor={data.blockColor}
      color={data.textColor}
      fontSize={headingSizes[data.size - 1]}
      fontFamily={data.font}
      textAlign={data.align}
      dangerouslySetInnerHTML={{ __html: data.text }}
      pt={`${data.paddingTop * 4}px`}
      pb={`${data.paddingBottom * 4}px`}
      px={5}
    />
  )
}

export default Heading
