import { cardAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    borderWidth: "1px",
    borderColor: "gray.300",
    boxShadow: "none",
    overflow: "hidden",
  },
  header: {
    px: 6,
    py: 4,
    borderBottomWidth: "1px",
    borderColor: "gray.300",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  body: {
    padding: 6,
  },
})

export default defineMultiStyleConfig({ baseStyle })
