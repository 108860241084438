import { useQuery } from "react-query"
import { getEventFormSubmission, getEventFormSubmissions } from "api/forms"
import { FormSubmissionSortOption } from "sharedTypes"

export const eventFormSubmissionsCacheKey = (eventId: string, page?: number, sort?: FormSubmissionSortOption, formIds?: string[], types?: string[]) => ["eventFormSubmissions", eventId, page, sort, formIds, types]
export const eventFormSubmissionCacheKey = (submissionId: string) => ["eventFormSubmission", submissionId]

export const useEventFormSubmissionsPaginated = (
  eventId: string,
  page: number,
  sort?: FormSubmissionSortOption,
  formIds?: string[],
  types?: string[],
) => {
  const query = useQuery(
    eventFormSubmissionsCacheKey(eventId, page, sort, formIds, types),
    () => getEventFormSubmissions(eventId, page, sort, formIds, types).then(({ data }) => data),
    { keepPreviousData: true },
  )

  return query
}

export const useEventFormSubmission = (eventId: string, submissionId: string) => useQuery(
  eventFormSubmissionCacheKey(submissionId),
  () => getEventFormSubmission(eventId, submissionId).then(({ data }) => data),
  { enabled: Boolean(submissionId) },
)
