import React from "react"
import {
  Code, FormControl, FormLabel,
} from "@chakra-ui/react"
import Editor from "react-simple-code-editor"

import hljs from "highlight.js"
import "highlight.js/styles/default.css"
import "assets/styles/highlight.css"
import { Control, useController } from "react-hook-form"

type Props = {
  name: string;
  control: Control<any>;
  label?: string;
  vertical?: boolean;
  fullHeight?: boolean;
  onBlur?: (event) => void;
}

const HTMLEditor = ({
  name, control, label, vertical, fullHeight, onBlur,
}: Props) => {
  const { field } = useController({ name, control })

  return (
    <FormControl
      mb={4}
      display={vertical ? "initial" : "flex"}
      {...fullHeight ? {
        height: "full",
        pb: 6,
      } : {}}
    >
      {label && <FormLabel fontWeight="bold" w={vertical ? "auto" : 1 / 4}>{label}</FormLabel>}
      <Code w="100%" h="100%" bg="white" borderWidth={2} fontSize="xs">
        <Editor
          value={field.value || ""}
          onValueChange={(code) => field.onChange(code)}
          highlight={(code) => hljs.highlightAuto(code).value}
          padding={10}
          style={{
            height: "100%",
          }}
          onBlur={onBlur}
        />
      </Code>
    </FormControl>
  )
}

export default HTMLEditor
