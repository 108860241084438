import React, { ReactNode } from "react"
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"

type Props = {
  children: ReactNode
  to?: string
  href?: string
  underlined?: boolean
} & LinkProps

const Link = ({
  children, color = "blue.500", textDecoration = "underline", underlined = false, to, href, ...props
}: Props) => {
  const history = useHistory()

  return (
    <ChakraLink
      color={color}
      textDecoration={underlined ? "underline" : "none"}
      onClick={to ? (e) => {
        history.push(to)
        e.preventDefault()
      } : undefined}
      href={to || href}
      _hover={{ textDecoration }}
      {...props}
    >{children}
    </ChakraLink>
  )
}

export default Link
