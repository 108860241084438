import { MutableRefObject, useEffect } from "react"

const useAutosizeTextArea = (
  textAreaRef: MutableRefObject<HTMLTextAreaElement | null>,
  value: string,
) => {
  useEffect(() => {
    const textArea = textAreaRef.current

    if (textArea) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textArea.style.height = "0px"
      const { scrollHeight } = textArea

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will produce an incorrect value.
      textArea.style.height = `${scrollHeight}px`
    }
  }, [textAreaRef, value])
}

export default useAutosizeTextArea
