import React from "react"
import Icon from "components/elements/Icon"
import { Flex, Text } from "@chakra-ui/react"
import { EventGroup, GuestFilterType, ModalName } from "sharedTypes"
import { asNumber } from "utilities/strings"
import useModal from "services/useModal"

type Props = {
  type: GuestFilterType;
  label: string;
  groupId: boolean;
  segmentId: string;
  icon?: string;
  iconColor?: string;
  onSelect: () => {};
  selected?: boolean;
  count: number;
  eventGroup?: EventGroup;
}

const EditableFilter = ({
  type, label, groupId, segmentId, icon, iconColor, onSelect, selected, count, eventGroup,
}: Props) => {
  const showModal = useModal()

  const handleEdit = () => {
    if (type === GuestFilterType.CustomSegment) {
      showModal(ModalName.SegmentForm, { segmentId })
    } else {
      showModal(ModalName.GuestListForm, { eventGroupId: groupId })
    }
  }

  return (
    <Flex
      justify="space-between"
      align="center"
      fontSize="xl"
      color="gray.600"
      w="full"
      px={8}
      py={2}
      bg={selected ? "blue.50" : "none"}
      position="relative"
    >
      <Flex
        align="center"
        justify="start"
        w="40%"
        flex={1}
        role="button"
        onClick={onSelect}
      >
        {selected
        && <Icon icon="checkmark" pos="absolute" left={0} mx={2} color="blue.400" />}
        <Icon
          icon={icon || "dot"}
          mr={2}
          color={`${iconColor}`}
          size={icon === "dot" ? 3 : 4}
        />
        <span>{label}</span>
      </Flex>
      {
        !!eventGroup?.notifications?.length
        && (
          <Icon
            size={6}
            icon="checkin-alert"
            zIndex={10}
            mr={2}
            color="blue.500"
            cursor="pointer"
            onClick={handleEdit}
          />
        )
      }
      <Flex align="center" justify="end" mr={3}>
        <Text as="span" align="right">{asNumber(count)}</Text>
      </Flex>
      <Icon
        icon="website-edit"
        color="blue.500"
        size={4}
        role="button"
        onClick={handleEdit}
      />
    </Flex>
  )
}

export default EditableFilter
