import { Form } from "sharedTypes"

export default (form: Form, enableNameAndEmailToggle?: boolean) => {
  const {
    profilePhoneEnabled,
    profilePhoneRequired,
    profileCompanyEnabled,
    profileCompanyRequired,
    profileAddressEnabled,
    profileAddressRequired,
    profileCityEnabled,
    profileCityRequired,
    profileCountryEnabled,
    profileCountryRequired,
    profilePostalCodeEnabled,
    profilePostalCodeRequired,
    profileStateEnabled,
    profileStateRequired,
    profileTitleEnabled,
    profileTitleRequired,
    profileNameEnabled,
    profileEmailEnabled,
    translations: {
      fullName,
      emailAddress,
      phone,
      company,
      title,
      addressLine1,
      city,
      state,
      postalCode,
      country,
    },
  } = form

  return {
    personal: [
      {
        name: fullName,
        enabled: profileNameEnabled,
        required: true,
        requiredLocked: true,
        fieldName: "profileName",
        readOnly: !enableNameAndEmailToggle,
      },
      {
        name: emailAddress,
        enabled: profileEmailEnabled,
        required: true,
        requiredLocked: true,
        fieldName: "profileEmail",
        readOnly: !enableNameAndEmailToggle,
      },
      {
        name: phone,
        enabled: profilePhoneEnabled,
        required: profilePhoneRequired,
        fieldName: "profilePhone",
      },
      {
        name: company,
        enabled: profileCompanyEnabled,
        required: profileCompanyRequired,
        fieldName: "profileCompany",
      },
      {
        name: title,
        enabled: profileTitleEnabled,
        required: profileTitleRequired,
        fieldName: "profileTitle",
      },
    ],
    address: [
      {
        name: addressLine1,
        enabled: profileAddressEnabled,
        required: profileAddressRequired,
        fieldName: "profileAddress",
      },
      {
        name: city,
        enabled: profileCityEnabled,
        required: profileCityRequired,
        fieldName: "profileCity",
      },
      {
        name: state,
        enabled: profileStateEnabled,
        required: profileStateRequired,
        select: true,
        fieldName: "profileState",
      },
      {
        name: postalCode,
        enabled: profilePostalCodeEnabled,
        required: profilePostalCodeRequired,
        fieldName: "profilePostalCode",
      },
      {
        name: country,
        enabled: profileCountryEnabled,
        required: profileCountryRequired,
        select: true,
        fieldName: "profileCountry",
      },
    ],
  }
}
