import { Box, Flex } from "@chakra-ui/react"
import React from "react"
import Icon from "components/elements/Icon"

type Props = {
  value: any;
  children: string;
  onChange: (value) => void;
  iconColor?: string;
  icon?: string;
  selected?: boolean;
}

const PopoverMenuItem = ({
  value, iconColor, children, onChange, icon, selected,
}: Props) => (
  <Box
    cursor="pointer"
    px={4}
    py={1}
    onClick={() => onChange(value)}
    role="menuitem"
  >
    <Flex justify="space-between" align="center" w="full" px={1} py={2}>
      <Flex align="center" fontSize="lg">
        {icon && <Icon icon={icon || "dot"} size={3} mr={4} color={iconColor} />} {children}
      </Flex>
      {selected && <Icon icon="checkmark" size={5} color="blue.500" ml={4} />}
    </Flex>
  </Box>
)

export default PopoverMenuItem
