import React from "react"
import {
  ActivityItem,
  CheckInTransaction,
  FormSubmissionLineItem,
  EmailTransaction,
  Transaction,
  TextActivityItem, DioboxEvent,
} from "sharedTypes"
import EmailItem from "./Invitation"
import GeneralItem from "./General"
import CheckIn from "./CheckIn"
import FormSubmissionItem from "./FormSubmission/FormSubmission"
import TextItem from "./Text/Text"

type Props = {
  item: ActivityItem
  event: DioboxEvent
}

const Item = ({ item, event }: Props) => {
  const { timezone } = event

  switch (item.type) {
    case "AttendanceTransaction":
      return <CheckIn item={item as CheckInTransaction} />
    case "EmailTransaction":
      return <EmailItem item={item as EmailTransaction} />
    case "FormSubmission":
      return <FormSubmissionItem item={item as FormSubmissionLineItem} timeZone={timezone} />
    case "TextTransaction":
      return <TextItem item={item as TextActivityItem} event={event} />
    default:
      return <GeneralItem item={item as Transaction} />
  }
}

export default Item
