import React from "react"
import FilePreview from "components/Portal/inputs/FilePreview"

type Props = {
  response: string | string[] | { file: string; url: string; type: string }
}
const FormattedAnswer = ({ response }: Props) => {
  if (Array.isArray(response)) {
    return <>{response.join(", ")}</>
  } if (typeof response === "object") {
    return (
      <FilePreview
        filename={response.file}
        src={response.url}
        type={response.type}
      />
    )
  }

  return <>{response}</>
}

export default FormattedAnswer
