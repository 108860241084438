import React, { useState } from "react"
import pluralize from "pluralize"
import * as guestsApi from "api/guests"
import * as emailsApi from "api/emails"
import {
  Email, Guest, ModalName,
} from "sharedTypes"
import Icon from "components/elements/Icon"
import Spinner from "components/elements/Spinner"
import { useHistory } from "react-router-dom"
import { popToast, ToastProps } from "utilities/toasts"
import { Box, Flex, Text } from "@chakra-ui/react"
import Link from "components/elements/Link"
import { useCurrentEvent } from "queries/events"
import { useEmails } from "queries/emails"
import useGuestsStore from "services/useGuestsStore"
import { shallow } from "zustand/shallow"
import useModal from "services/useModal"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import { useQueryClient } from "react-query"
import { guestCacheKey } from "queries/guests"
import GuestCard from "pages/EventShow/Guests/SelectedGuest/Show/GuestCard"
import { emailIcon, requiresUpgrade as formRequiresUpgrade } from "utilities/forms"
import useFormIntegrations from "services/useFormIntegrations"
import useCurrentAccount from "services/useCurrentAccount"
import { useEventGuestPaths } from "utilities/routes"
import EmailProperty from "../EmailProperty"
import EmailDetails from "./EmailDetails"

type Props = {
  onClose: () => void
  selectedCount?: number
  guest?: Guest
}

const SendEmail = ({ onClose, selectedCount, guest }: Props) => {
  const { isLoading: eventIsLoading, data: currentEvent } = useCurrentEvent()
  const showModal = useModal()
  const [selectedEmail, setSelectedEmail] = useState<Email>()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { eventGuestsPath } = useEventGuestPaths()

  const { isLoading: emailsIsLoading, data: emails = [] } = useEmails(currentEvent.id)

  const [selectedGuestIds, deselectAll, allSelected, groupId, segmentId] = useGuestsStore(
    (s) => [s.selectedGuestIds, s.deselectAll, s.allSelected, s.groupId, s.segmentId],
    shallow,
  )
  const { subscription } = useCurrentAccount()
  const {
    data: forms,
  } = useFormIntegrations(
    { eventId: currentEvent.id, email: selectedEmail },
  )
  const requiresUpgrade = formRequiresUpgrade(forms, subscription)

  let sendTo
  let feedbackMessage

  if (guest) {
    sendTo = <GuestCard guest={guest} />
    feedbackMessage = (guestCount): ToastProps => {
      if (!guestCount) {
        return { title: "Email was not sent because email address was not validated", status: "warning" }
      }

      return { title: `Successfully sent email to ${guest.personInfo.firstName} ${guest.personInfo.lastName}`, status: "success" }
    }
  } else {
    sendTo = <EmailProperty name="To">{selectedCount} selected {pluralize("guest", selectedCount)}</EmailProperty>
    feedbackMessage = (guestCount): ToastProps => {
      if (!guestCount) {
        return { title: "Emails were not sent because selected guests are unsubscribed.", status: "warning" }
      }

      return { title: `Successfully sent email to ${guestCount} ${pluralize("guest", guestCount)}`, status: "success" }
    }
  }

  const getEmail = (id) => {
    emailsApi.getInvitation(currentEvent.id, id).then(({ data }) => {
      setSelectedEmail(data)
    })
  }

  const onSubmit = () => guestsApi.postGuestsEmails(
    currentEvent.id,
      selectedEmail?.id!,
      allSelected ? undefined : (guest ? [guest.id] : selectedGuestIds),
      allSelected ? segmentId : undefined,
      allSelected ? groupId : undefined,
  ).then(({ data: { guestCount } }) => {
    if (!guest) {
      history.push(eventGuestsPath(currentEvent.id))
      deselectAll()
    } else {
      queryClient.removeQueries(guestCacheKey(guest.id))
    }

    popToast(feedbackMessage(guestCount))
    onClose()
  }).catch(() => {
    // API service automatically alerts the user, show must go on without closing the modal
  })

  let modalContent

  if (!emails.length) {
    if (emailsIsLoading || eventIsLoading) {
      modalContent = <Box py={8}><Spinner heightFull /></Box>
    } else {
      modalContent = (
        <Flex direction="column" justify="center" align="center" py={8} textAlign="center">
          <Icon icon="email-only-large" size={12} />
          <Text fontSize="2xl" my={2}>No Available Emails</Text>
          <Text mb={4} px={12}>
            After you send at least one email campaign,
            you will be able to resend it to additional guests here.
          </Text>
          <Link onClick={() => showModal(ModalName.NewEmail)}>Create New Email</Link>
        </Flex>
      )
    }
  }

  return (
    <ModalForm
      onSubmit={onSubmit}
      submitText="Send"
      noPadding
      onClose={onClose}
      title="Send Email"
      hideActions={!selectedEmail || requiresUpgrade}
    >
      {modalContent}
      {!selectedEmail && (
        emails.map((email) => {
          const { id, title, sentOn } = email

          return (
            <Flex py={3} px={4} gap={4} align="center" cursor="pointer" onClick={() => getEmail(id)} borderBottomWidth={1}>
              <Icon icon={emailIcon(email)} size={8} />
              <Box>
                <Text fontSize="lg">{title}</Text>
                <Text fontSize="sm">{sentOn}</Text>
              </Box>
              <Icon icon="next-arrow" size={5} my={3} ml="auto" />
            </Flex>
          )
        }))}
      <EmailDetails
        currentEvent={currentEvent}
        sendTo={sendTo}
        selectedEmail={selectedEmail}
        requiresUpgrade={requiresUpgrade}
      />
    </ModalForm>
  )
}

export default SendEmail
