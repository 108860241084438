import React from "react"
import { Stack } from "@chakra-ui/react"
import { useCurrentEvent } from "queries/events"
import { useEventFormSubmission } from "queries/formSubmissions"
import Spinner from "components/elements/Spinner"
import SelectPrompt from "components/SelectPrompt"
import { DioboxEvent, FormType } from "sharedTypes"
import NoContent from "components/elements/NoContent"
import FormSubmissionLineItemActivityPopup from "components/dialogs/FormSubmissionLineItemActivityPopup"
import { asMoney } from "utilities/strings"
import Button from "components/Buttons/Button"
import { Redirect } from "react-router-dom"
import { eventSubmissionsPath } from "utilities/routes"
import SubmissionSummaryCard from "./SubmissionSummaryCard"
import LineItemCard from "./LineItemCard"
import LineItemView from "./LineItemView/LineItemView"
import ReceiptButton from "./ReceiptButton"

type Props = {
  submissionId: string
  currency: string
  event: DioboxEvent
}

type APIError = {
  status: number
}

const FormSubmissionsDetailView = ({ submissionId, currency, event }: Props) => {
  const { isLoading: eventIsLoading, data: { id: eventId } } = useCurrentEvent()

  const {
    isLoading: eventFormSubmissionsIsLoading,
    data: submission,
    error,
  } = useEventFormSubmission(eventId, submissionId)

  if ((error as APIError)?.status === 404) {
    return <Redirect to={eventSubmissionsPath(event.id)} />
  }

  if (eventFormSubmissionsIsLoading || eventIsLoading) {
    return <Spinner />
  }

  if (!submission) {
    return <SelectPrompt text="Select a Form Submission" />
  }

  const {
    lineItems, externalId: formSubmissionExternalId, paymentMethod, paymentDetails, createdAt,
  } = submission

  if (!lineItems.length) {
    return <NoContent icon="data-collection" title="Form Submission" descriptionItems={["This Form Submission contains no line items"]} />
  }

  if (submission.lineItems.length === 1) {
    const { timezone } = event
    const {
      guest: { id: guestId },
      form: { type: formType },
      name,
      price,
      externalId: formSubmissionLineItemExternalId,
    } = lineItems[0]
    const isTicket = formType === FormType.Ticket
    const formSubmissionName = name + (price && formType === FormType.Ticket ? ` ${asMoney(price, currency)}` : "")
    const priceString = (price && isTicket ? `${asMoney(price, currency)}` : "")
    const header = `${formType} ${priceString}`

    return (
      <Stack alignItems="flex-end" gap={6}>
        <FormSubmissionLineItemActivityPopup
          trigger={(
            <Button
              rightIcon="down-arrow"
              variant="outline"
              size="sm"
            >Options
            </Button>
          )}
          guestId={guestId}
          formType={formType}
          formSubmissionName={formSubmissionName}
          isTicket={isTicket}
          externalId={formSubmissionLineItemExternalId}
          formSubmissionExternalId={formSubmissionExternalId}
          eventId={eventId}
          payment={paymentDetails ?? paymentMethod}
          header={header}
          createdAt={createdAt}
          timeZone={timezone}
        />
        <LineItemView lineItem={lineItems[0]} event={event} />
      </Stack>
    )
  }

  return (
    <Stack spacing={8}>
      <ReceiptButton formSubmissionExternalId={formSubmissionExternalId} />
      <SubmissionSummaryCard submission={submission} />
      {submission.lineItems.map((li, i) => (
        <LineItemCard key={i} index={i + 1} lineItem={li} />
      ))}
    </Stack>
  )
}

export default FormSubmissionsDetailView
