import React from "react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import { Text } from "@chakra-ui/react"

type Props = {
  onSubmit: () => void
  onClose: () => void
  isLoading?: boolean
  text: string
}

const ConfirmationModal = ({
  onSubmit, onClose, isLoading, text,
}:Props) => (
  <ModalForm
    title="Are you sure?"
    submitText="Send Now"
    size="lg"
    onSubmit={onSubmit}
    onClose={onClose}
    loading={isLoading}
  >
    <Text>{text}</Text>
  </ModalForm>
)

export default ConfirmationModal
