import React from "react"
import { ButtonProps } from "@chakra-ui/react"
import Button from "./Button"

type Props = {
  onClick: () => void
  children?: string
  size?: ButtonProps["size"]
  isLoading?: boolean
}

const DangerButton = ({
  onClick, children = "Delete", size = "md", isLoading,
}: Props) => (
  <Button
    variant="outline"
    colorScheme="button.danger"
    onClick={onClick}
    size={size}
    isLoading={isLoading}
  >
    { children }
  </Button>
)

export default DangerButton
