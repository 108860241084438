import { Box, Flex, Text } from "@chakra-ui/react"
import React, { ReactNode } from "react"
import { DioboxEvent } from "sharedTypes"
import SimpleBar from "simplebar-react"
import PortalPreview from "./PortalPreview"

type Props = {
  children: ReactNode
  eventPreview: DioboxEvent
}

const PageWithPreview = ({ children, eventPreview }: Props) => (
  <Flex mb={10} h="full" outline="1px solid red">
    <Box w="50%" borderRightWidth={1} h="full" as={SimpleBar}>
      <Box p={10}>
        {children}
      </Box>
    </Box>
    <Flex direction="column" w="50%" h="full" bg="gray.50">
      <Text w="full" p={3} borderBottomWidth={1} fontWeight="semibold">Preview</Text>
      <Box as={SimpleBar} h={1} flex={1} w="full">
        <Box maxW={640} m="auto" p={10}>
          <PortalPreview event={eventPreview} />
        </Box>
      </Box>
    </Flex>
  </Flex>
)

export default PageWithPreview
