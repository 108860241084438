import React, { ReactNode } from "react"
import { Box, Flex } from "@chakra-ui/react"
import SimpleBar from "simplebar-react"
import { DraggableItem } from "sharedTypes/builder"
import DropHereBox from "./DropHereBox"

type Props = {
  sidePanel: ReactNode
  handleDropIntoDropHereBox?: (item: DraggableItem) => void
  showDropHereBox?: boolean
  bg?: string
  children?: ReactNode
}

const BuilderLayout = ({
  sidePanel, handleDropIntoDropHereBox, showDropHereBox, children, bg,
}: Props) => (
  <Flex h="full" overflow="hidden" bg={bg}>
    <Box as={SimpleBar} h="full" flex={5}>
      {showDropHereBox ? <DropHereBox onDropInto={handleDropIntoDropHereBox} /> : children}
    </Box>
    <Box
      as={SimpleBar}
      flex={2}
      bgColor="gray.100"
      borderLeftWidth={2}
      minW="470px"
    >
      {sidePanel}
    </Box>
  </Flex>
)

export default BuilderLayout
