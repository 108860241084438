import React from "react"
import { Seat, Table, ItemTypes } from "sharedTypes"
import { useDrop } from "react-dnd"
import { Box, Flex } from "@chakra-ui/react"
import VacantSeatItem from "../../../Table/Lists/VacantSeatItem"

type Props = {
  table: Table;
  seat: Seat;
  onAssignGuest: () => void;
  onAddPlaceholder: () => void;
  canEditSeating: boolean;
}

const VacantSeat = ({
  table,
  seat,
  seat: { number: seatNumber },
  onAssignGuest,
  onAddPlaceholder,
  canEditSeating,
}: Props) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.GUEST,
    drop: () => ({ table, seatNumber }),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  })

  return (
    <Box ref={drop}>
      <VacantSeatItem
        seat={seat}
        onAddPlaceholder={onAddPlaceholder}
        onAssignGuest={onAssignGuest}
        canEditSeating={canEditSeating}
      >
        <Flex
          align="center"
          justify="center"
          borderRadius="full"
          bg={isOver ? "blue.100" : ""}
          w={10}
          h={10}
          borderWidth={1}
          opacity={isOver ? 0.5 : 1}
          borderColor="gray.500"
          textColor="gray.600"
          bgColor="gray.200"
        >
          {seatNumber}
        </Flex>
      </VacantSeatItem>
    </Box>
  )
}

export default VacantSeat
