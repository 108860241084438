import { Flex, Text, TypographyProps } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import React from "react"

type Props = {
  published: boolean
  urlPath: string
  dotSize?: number
  fontSize?: TypographyProps["fontSize"]
  fontWeight?: TypographyProps["fontWeight"]
}

const StatusIndicator = ({
  published, urlPath, dotSize = 3.5, fontSize = "xl", fontWeight = "bold",
}: Props) => (
  <Flex align="baseline">
    <Icon
      color={getStatusIndicatorColor(published, urlPath)}
      size={dotSize}
      icon="dot"
      mr={2}
    />
    <Text fontSize={fontSize} fontWeight={fontWeight}>{published ? "Published" : "Draft"}</Text>
  </Flex>
)

const getStatusIndicatorColor = (published: boolean, urlPath: string): string => {
  if (published) {
    return "green.300"
  }

  if (urlPath) {
    return "orange.300"
  }

  return "gray.300"
}

export default StatusIndicator
