import Select from "components/ReactHookForm/Select"
import { EMAIL_FONTS } from "utilities/enums"
import React from "react"

const FontSelect = ({ control }) => (
  <Select
    label="Font"
    name="font"
    control={control}
    options={EMAIL_FONTS.map((option) => ({ label: option, value: option }))}
  />
)

export default FontSelect
