import React from "react"
import { Select } from "@chakra-ui/react"
import { CustomImportGuestHeaderType, ImportGuestsHeader } from "sharedTypes"
import { IMPORT_GUEST_HEADERS } from "utilities/enums"
import { useForm } from "react-hook-form"
import { useDeepCompareEffect } from "ahooks"

type Props = {
  columnIndex: number;
  onChange: (number, values: ImportGuestsHeader) => void;
  customField?: string;
  selected?: ImportGuestsHeader;
}

const optionGroups = [
  {
    title: "Profile Fields",
    fields: [
      "first_name",
      "last_name",
      "email",
      "phone",
      "company_name",
      "company_title",
      "notes",
      "gender",
      "line1",
      "line2",
      "city",
      "state",
      "zip",
      "country",
    ],
  },
  {
    title: "Guest Fields",
    fields: [
      "status",
      "list",
      "gender_unknown_guests",
    ],
  },
  {
    title: "Table Fields",
    fields: [
      "table",
    ],
  },
]

const HeaderDropdown = ({
  columnIndex,
  onChange,
  customField,
  selected,
}: Props) => {
  const options = optionGroups.map(({ title, fields }, index) => (
    [
      <optgroup key={index} label={title}>
        {fields.map((field) => {
          const { key, value } = IMPORT_GUEST_HEADERS.find(
            (header) => field === header.key,
          ) as ImportGuestsHeader

          return <option key={key} value={key}>{value}</option>
        })}
      </optgroup>,
    ]
  ))

  if (customField) {
    options.unshift([
      <optgroup key="custom_field" label="Custom Field">
        <option value={customField}>{customField}</option>
      </optgroup>,
    ])
  }

  const {
    register, watch,
  } = useForm({
    defaultValues: selected,
    mode: "onChange",
  })

  const values: ImportGuestsHeader = watch()

  useDeepCompareEffect(() => {
    onChange(columnIndex, values)
  }, [values])

  const select = (
    <form>
      <Select
        {...register("key")}
        size="sm"
        minWidth={32}
        value={selected?.key || ""}
      >
        <option value="">Skip Column</option>
        {options}
      </Select>
      {selected?.custom && (
      <Select
        {...register("type")}
        size="sm"
        minWidth={32}
        mt={4}
      >
        <option value="">Select Type</option>
        <option value={CustomImportGuestHeaderType.TextField}>Text</option>
        <option value={CustomImportGuestHeaderType.EmailField}>Email</option>
        <option value={CustomImportGuestHeaderType.PhoneField}>Phone number</option>
      </Select>
      )}
    </form>
  )

  return select
}

export default HeaderDropdown
