import Icon from "components/elements/Icon"
import React from "react"
import { Text, Button } from "@chakra-ui/react"
import { defaultStyles, inverseStyles } from "../.."

type Props = {
  onCheckIn: () => void
  label: string
  checkedIn: boolean
  bulk?: boolean
}

const GroupCheckInItem = ({
  onCheckIn, label, checkedIn, bulk = false,
}: Props) => (
  <Button
    py={6}
    variant="ghost"
    textAlign="left"
    justifyContent="start"
    onClick={onCheckIn}
    disabled={!!checkedIn}
  >
    <Icon
      icon={bulk ? "checkin-multiple-filled" : "checkin-filled"}
      size={6}
      mr={2}
      borderRadius="full"
      borderColor="green.500"
      borderWidth={2}
      {...checkedIn ? inverseStyles : defaultStyles}
    />
    <Text isTruncated>{label}</Text>
  </Button>
)

export default GroupCheckInItem
