import { get } from "services/api"
import { currentAccountId } from "services/authentication"
import { PersonInfo } from "../sharedTypes"

export const getPeople = ({
  query,
  excludeEventId,
}: {
  query: string,
  excludeEventId: string
}) => get<PersonInfo>(
  `${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}`
    + `/people.json?search=${query}&exclude_event_id=${excludeEventId}&per_page=10`,
)

export default null
