import { post } from "services/api"
import { Input } from "@chakra-ui/react"
import React from "react"
import { Control, useController } from "react-hook-form"

type Props = {
  name: string;
  control: Control<any>;
  uploadUrl: string
}

const Upload = ({ name, control, uploadUrl }: Props) => {
  const { field } = useController({ name, control })

  const handleFileUpload = (event) => {
    const file = event.target.files[0]

    if (!file) {
      return
    }

    post<{link: string}>(uploadUrl, { file }).then(({ data: { link } }) => {
      field.onChange(link)
    })
  }

  return <Input type="file" onChange={handleFileUpload} mb={4} bg="white" />
}

export default Upload
