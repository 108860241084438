import React from "react"
import * as Yup from "yup"
import { addEventGroup, removeEventGroup, updateEventGroup } from "context/actions"
import { successToast } from "utilities/toasts"
import { Heading, Text, Box } from "@chakra-ui/react"
import { deleteEventGroup, postEventGroup, putEventGroup } from "api/events"
import { useQueryClient } from "react-query"
import {
  EventGroup, EventUser, AccountUser,
} from "sharedTypes"
import UpgradeAlert from "components/elements/UpgradeAlert"
import useCurrentAccount from "services/useCurrentAccount"
import TextField from "components/ReactHookForm/TextField"
import Checkboxes from "components/ReactHookForm/Checkboxes"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import { eventAndAccountAdmins } from "utilities/objects"
import UserListItem from "pages/EventShow/Guests/SelectedGuest/Show/CheckInAlert/UserListItem"

type Props = {
  eventId: string
  eventGroupId?: string;
  eventGroups: EventGroup[]
  users: EventUser[]
  teamMembers: AccountUser[]
  onHide: () => void;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required(),
})

const GuestListForm = ({
  eventId, eventGroupId, eventGroups, users, teamMembers, onHide,
}: Props) => {
  const queryClient = useQueryClient()
  const { subscription } = useCurrentAccount()

  let modalTitle
  let onSubmit
  let onDelete
  let initialValues

  const eventGroup = eventGroups.find(({ id }) => id.toString() === eventGroupId)

  if (eventGroup) {
    modalTitle = "Edit Guest List"
    initialValues = {
      title: eventGroup.title,
      selectedUsers: eventGroup.notifications?.map(({ userId }) => userId) || [],
    }
    onSubmit = ({ title, selectedUsers }) => putEventGroup(eventId, {
      id: eventGroup.id,
      title,
      notifications: selectedUsers.map((userId) => ({ userId, email: true })),
    }).then(({ data }) => {
      updateEventGroup(queryClient, eventId, data)
      successToast({ title: "Successfully updated Guest List" })
      onHide()
    })

    onDelete = () => {
      deleteEventGroup(eventId, eventGroup).then(() => {
        removeEventGroup(queryClient, eventId, eventGroup.id.toString())
        onHide()
      })
    }
  } else {
    modalTitle = "Add Guest List"
    initialValues = { title: "", selectedUsers: [] }
    onSubmit = ({ title, selectedUsers }) => postEventGroup(eventId, {
      title,
      notifications: selectedUsers.map((id) => ({ userId: id, email: true })),
    }).then(({ data }) => {
      addEventGroup(queryClient, eventId, data)
      successToast({ title: "Successfully created Guest List" })
      onHide()
    })
  }

  const allUsers = eventAndAccountAdmins(users, teamMembers)

  return (
    <ModalForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onDanger={onDelete}
      dangerProps={{ type: "Guest List", name: initialValues.title }}
      onClose={onHide}
      title={modalTitle}
    >
      {({ control }) => (
        <>
          <Box borderBottomWidth={1} py={4} mb={8}>
            <TextField label="Guest List Name" name="title" control={control} variant="outline" />
          </Box>
          <Heading fontSize="lg" mb={4} fontWeight="semibold">Check-In Alert</Heading>
          <Text mb={4}>Send an email alert when anyone on this Guest List checks-in:</Text>
          <Box mb={20}>
            {
            subscription
            && (
              <Checkboxes
                name="selectedUsers"
                control={control}
                items={allUsers.filter((u) => u.user?.confirmed).map(({ user }) => user)}
                itemComponent={UserListItem}
              />
            )
          }
            {
            !subscription
            && <UpgradeAlert text="This feature requires a Basic Subscription." />
          }
          </Box>
        </>
      )}
    </ModalForm>
  )
}

export default GuestListForm
