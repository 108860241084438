import Select from "components/ReactHookForm/Select"
import TextField from "components/ReactHookForm/TextField"
import React from "react"
import { INDUSTRY_OPTIONS } from "utilities/enums"
import { Flex, VStack, Text } from "@chakra-ui/react"
import ImageUpload from "components/ReactHookForm/ImageUpload"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import extendFormValuesWithImages from "utilities/extendFormValuesWithImages"

type Props = {
  id: string;
  name: string;
  industry: string;
  logoUrl: string;
  updateAccount: (params: {}) => Promise<any>;
}

const Settings = ({
  id, name, industry, logoUrl, updateAccount,
}: Props) => {
  const onSubmit = (values) => updateAccount(values)
  const values = extendFormValuesWithImages({ name, industry, logoUrl }, ["logo"])

  return (
    <ContainedForm
      values={values}
      onSubmit={onSubmit}
      name="Account"
    >
      {({ control }) => (
        <VStack spacing={8} align="baseline">
          <TextField
            name="name"
            control={control}
            label="Account Name"
            helperText="The account name is displayed in menus and headings. In most cases, it will be the name of your company or team."
          />
          <Flex w="full">
            <Text w={1 / 4} fontWeight="bold">Account ID</Text>
            <Text w="100%" pl={2}>{id}</Text>
          </Flex>
          <Select
            label="Industry"
            name="industry"
            control={control}
            options={INDUSTRY_OPTIONS.map((option) => ({ label: option, value: option }))}
            includeBlank
          />
          <ImageUpload
            name="logo"
            control={control}
            label="Logo"
            imageUrl={logoUrl ? `${logoUrl}?fit=crop&w=150&h=150` : ""}
            imageWidth={150}
            imageHeight={150}
            helperText="Account logo should be uploaded in a 1x1 ratio (square) at least 256x256. JPG or PNG only."
          />
        </VStack>
      )}
    </ContainedForm>
  )
}

export default Settings
