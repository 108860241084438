import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { useDrop } from "react-dnd"
import { DraggableItem, DraggableItemTypes } from "sharedTypes/builder"

type Props = {
  onDropInto?: (item: DraggableItem) => void
}

const DropHereBox = ({ onDropInto }: Props) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: DraggableItemTypes.ADD,
    drop: (item: DraggableItem) => onDropInto?.(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }))

  return (
    <Box
      ref={drop}
      mx="auto"
      mt={24}
      h="200px"
      w={600}
      borderWidth="2px"
      borderRadius="md"
      borderColor="gray.300"
      backgroundColor={isOver ? "gray.200" : "gray.100"}
      display="flex"
      alignItems="center"
      justifyContent="center"
      transition="background-color 0.2s"
    >
      <Text fontSize="lg" color="gray.500">
        Drop blocks here
      </Text>
    </Box>
  )
}

export default DropHereBox
