import React from "react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextField from "components/ReactHookForm/TextField"

type Props = {
  title: string;
  updateWebsite: (values) => Promise<void>;
  onClose: () => void;
}

const EditHeader = ({
  title, updateWebsite, onClose,
}: Props) => {
  const onSubmit = (values) => updateWebsite(values).then(() => {
    onClose()
  })

  return (
    <ModalForm
      initialValues={{ title }}
      onSubmit={onSubmit}
      title="Header"
      onClose={onClose}
    >
      {({ control }) => (
        <TextField key="title" name="title" control={control} label="Title" />
      )}
    </ModalForm>
  )
}

export default EditHeader
