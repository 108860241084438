import React, { ReactNode, useRef } from "react"
import SimpleBar from "simplebar-react"
import { Box, Flex } from "@chakra-ui/react"
import ScrollToTop from "../Navigation/ScrollToTop"

type Props = {
  children: ReactNode
}

const CenteredContent = ({ children }: Props) => {
  const ref = useRef(null)

  return (
    <>
      <Flex direction="column" flexGrow={1}>
        <Box
          scrollableNodeProps={{ ref }}
          position="relative"
          as={SimpleBar}
          px={10}
          py={8}
          h={1}
          flexGrow={1}
          bg="gray.50"
        >
          <Box pb={16} minWidth={460} maxWidth={750} margin="auto">
            {children}
          </Box>
        </Box>
      </Flex>
      <ScrollToTop containerRef={ref} />
    </>
  )
}

export default CenteredContent
