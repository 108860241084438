import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import { asNumber, asPercent } from "utilities/strings"
import Icon from "./elements/Icon"

type Props = {
  label: string
  count: number
  total: number
  color: string
}

const StatRow = ({
  label, count, total, color,
}:Props) => (
  <Flex mb={2} w="100%">
    <Box w="50%">
      <Icon icon="dot" size={3} mr={3} color={color} />
      {label}
    </Box>
    <Box w="35%" textAlign="right">({asPercent(count / total)})</Box>
    <Box w="15%" textAlign="right">{asNumber(count)}</Box>
  </Flex>
)

export default StatRow
