import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import Modal from "components/dialogs/Modal"
import Button from "components/Buttons/Button"
import { useEventQuestions } from "queries/questions"
import useModal from "services/useModal"
import {
  BuilderBlockWithBlockId, DioboxEvent, FormQuestion, ModalName,
} from "sharedTypes"
import SimpleBar from "simplebar-react"
import _ from "lodash"
import { BuilderBlock, BuilderBlockType } from "sharedTypes/builder"
import NoContent from "components/elements/NoContent"
import Spinner from "components/elements/Spinner"
import Icon from "components/elements/Icon"
import { questionTypeIcons } from "utilities/enums"
import { BlockInsertPosition } from "components/Builder/BuildingBlock"

type Props = {
  event: DioboxEvent
  onClose: () => void
  blocks: BuilderBlockWithBlockId[];
  addBlock: (
    formBlock: BuilderBlock,
    position: BlockInsertPosition,
    selectedQuestionId?: number,
  ) => void
  position: BlockInsertPosition
  selectedQuestionId?: number
}

const AddFormQuestion = ({
  event: { id: eventId, counts: { questions: questionCount } },
  onClose,
  blocks,
  addBlock,
  position,
  selectedQuestionId,
}: Props) => {
  const { questions: eventQuestions, fetchNextPage, isLoading } = useEventQuestions(eventId)
  const showModal = useModal()

  const handleAddQuestion = (eventQuestion: FormQuestion) => {
    addBlock({
      type: BuilderBlockType.QUESTION,
      data: eventQuestion,
    },
    position,
    selectedQuestionId)
    onClose()
  }

  const handleScroll = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10) {
      fetchNextPage()
    }
  }

  const handleAddedQuestionSuccessfully = (question: FormQuestion) => {
    handleAddQuestion(question)
    onClose()
  }

  const handleClickAddQuestion = () => showModal(
    ModalName.QuestionForm, { onSuccess: handleAddedQuestionSuccessfully },
  )

  const debouncedHandleScroll = _.debounce(handleScroll, 100)
  const spacer = 6

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Modal
      title="Add Question to Form"
      show
      size="xl"
      noPadding
      hide={onClose}
    >

      <Box
        p={spacer}
        as={SimpleBar}
        h="xl"
        flexGrow={1}
        scrollableNodeProps={{
          onScroll: (e) => {
            e.persist()
            debouncedHandleScroll(e)
          },
        }}
      >
        { questionCount === 0
          ? (
            <NoContent
              title="Create first custom question"
              callsToActions={[{
                icon: "plus-big", text: "Question", action: handleClickAddQuestion, isButton: true,
              }]}
            />
          ) : (
            <>
              <Flex justifyContent="space-between" alignItems="center">
                <Text>Select custom question</Text>
                <Button
                  leftIcon="plus"
                  onClick={handleClickAddQuestion}
                  size="sm"
                >Question
                </Button>
              </Flex>
              <Box py={spacer}>
                <Box borderRadius="md" borderWidth={1}>
                  {eventQuestions.map((eventQuestion, index) => {
                    const alreadyListed = Boolean(blocks.find(
                      ({ block }) => (
                        block.type === BuilderBlockType.QUESTION
                        && block.data.id === eventQuestion.id),
                    ))

                    return (
                      <Flex
                        key={eventQuestion.id}
                        minH={16}
                        p={4}
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottomWidth={(index === eventQuestions.length - 1) ? 0 : 1}
                      >
                        <Icon icon={questionTypeIcons[eventQuestion.type]} size={5} mr={2} />
                        <Box dangerouslySetInnerHTML={{ __html: eventQuestion.title }} w="86%" pr={2} />
                        <Button
                          variant="outline"
                          size="xs"
                          onClick={() => handleAddQuestion(eventQuestion)}
                          isDisabled={alreadyListed}
                          minWidth={14}
                        >{alreadyListed ? "Listed" : "Add"}
                        </Button>
                      </Flex>
                    )
                  })}
                </Box>
              </Box>
            </>
          )}
      </Box>

    </Modal>
  )
}

export default AddFormQuestion
