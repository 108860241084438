import React from "react"
import Pill from "components/elements/Pill"

type Props = {
  label: string
  color: string
}

const SeatPlaceholderButton = ({ label, color }: Props) => <Pill label={label} color={color} minW="36" width="fit-content" px={2} margin="auto" />

export default SeatPlaceholderButton
