import { create, StoreApi, UseBoundStore } from "zustand"

type Props = {
  maintenanceStatus: boolean,
  setMaintenanceStatus: (maintenanceStatus: boolean) => void
}

const useMaintenanceStatus: UseBoundStore<StoreApi<Props>> = create((set) => ({
  maintenanceStatus: false,
  setMaintenanceStatus: (maintenanceStatus: boolean) => set({ maintenanceStatus }),
}))

export default useMaintenanceStatus
