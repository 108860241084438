import React from "react"
import EventResourcesView from "components/layout/EventResourcesView"
import EventResourceListItem from "components/layout/EventResourceListItem"
import { eventDataCollectionQuestionPath } from "utilities/routes"
import { useParams } from "react-router-dom"
import SelectPrompt from "components/SelectPrompt"
import NoContent from "components/elements/NoContent"
import { useEventQuestions } from "queries/questions"
import { Box, Flex } from "@chakra-ui/react"
import Spinner from "components/elements/Spinner"
import useModal from "services/useModal"
import { DioboxEvent, ModalName } from "sharedTypes"
import Icon from "components/elements/Icon"
import { questionTypeIcons } from "utilities/enums"
import GuestResponses from "./GuestResponses"

type Props = {
  event: DioboxEvent
}

const QuestionsAndResponses = ({ event }: Props) => {
  const { questionId } = useParams<{ questionId: string }>()
  const { id: eventId, counts: { questions: questionCount } } = event
  const {
    questions,
    fetchNextPage,
    isLoading: eventQuestionsIsLoading,
  } = useEventQuestions(eventId)
  const showModal = useModal()

  const selectedQuestion = questions.find((question) => question.id.toString() === questionId)

  if (questionCount === 0) {
    const handleClickAddQuestion = () => showModal(ModalName.QuestionForm)

    return (
      <NoContent
        icon="segment"
        title="Questions & Responses"
        descriptionItems={["Create questions to ask your guests for additional information when they fill out registration forms or place ticket orders. You can use the same question in multiple forms to ensure consistent data collection."]}
        callsToActions={[{
          icon: "plus-big", text: "Create First Question", action: handleClickAddQuestion, isButton: true,
        }]}
      />
    )
  }

  if (eventQuestionsIsLoading) {
    return <Spinner />
  }

  return (
    <EventResourcesView
      showBorder={!!questionId}
      onScrollEnd={fetchNextPage}
      listItems={questions.map((question) => (
        <ListItem
          key={question.id}
          path={eventDataCollectionQuestionPath(eventId, question.id)}
          selected={question === selectedQuestion}
          question={question}
        />
      ))}
      selectedItem={selectedQuestion ? (
        <GuestResponses
          question={selectedQuestion}
          eventId={eventId}
        />
      ) : <SelectPrompt text="Select a Question" />}
    />
  )
}

const ListItem = ({ path, selected, question }) => (
  <EventResourceListItem path={path} selected={selected}>
    <Flex gap={4} align="center">
      <Icon icon={questionTypeIcons[question.type]} size={7} color="gray.500" /> <Box dangerouslySetInnerHTML={{ __html: question.title }} />
    </Flex>
  </EventResourceListItem>
)

export default QuestionsAndResponses
