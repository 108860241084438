import React from "react"
import TextField from "components/ReactHookForm/TextField"
import { Control } from "react-hook-form"

type Props = {
  name: string;
  control: Control<any>;
  placeholder: string;
}

const AttributeTextField = ({ name, control, placeholder }: Props) => (
  <TextField
    variant="flushed"
    name={name}
    control={control}
    placeholder={placeholder}
    mb={0}
  />
)

export default AttributeTextField
