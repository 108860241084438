import React from "react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import * as Yup from "yup"
import EditModalSegment from "pages/EventShow/GuestPortal/Terminology/FormSpecificMessagingSection/EditModalSegment"
import TranslationField from "pages/EventShow/GuestPortal/Terminology/TranslationField"
import { FormsTerminologyModalProps } from "sharedTypes"

const WaitlistFormsModal = ({
  title, event, onClose, onSubmit, defaultTranslations,
}: FormsTerminologyModalProps) => {
  const WaitlistFormsModalSchema = Yup.object().shape({})

  return (
    <ModalForm
      title={`Messaging: ${title}`}
      onClose={onClose}
      initialValues={event}
      validationSchema={WaitlistFormsModalSchema}
      onSubmit={onSubmit}
    >
      {({ control }) => (
        <>
          <EditModalSegment title="Form Button" first>
            <TranslationField
              label="Submit Button Label"
              defaultTranslation={defaultTranslations.waitlistSubmitButton}
              fieldName="translations.waitlistSubmitButton"
              editable
              control={control}
            />
          </EditModalSegment>
          <EditModalSegment title="Form Messages" last>
            <TranslationField
              label="Success Message"
              defaultTranslation={defaultTranslations.waitlistSuccessMessage}
              fieldName="translations.waitlistSuccessMessage"
              editable
              control={control}
            />
            <TranslationField
              label="Form Edited Message"
              defaultTranslation={defaultTranslations.waitlistEditMessage}
              fieldName="translations.waitlistEditMessage"
              editable
              control={control}
            />
            <TranslationField
              label="Closing Message"
              defaultTranslation={defaultTranslations.waitlistClosingMessage}
              fieldName="translations.waitlistClosingMessage"
              editable
              control={control}
            />
          </EditModalSegment>
        </>
      )}
    </ModalForm>
  )
}

export default WaitlistFormsModal
