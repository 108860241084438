import React from "react"
import Icon from "components/elements/Icon"
import { Box, HStack, Text } from "@chakra-ui/react"
import {
  EventGroup,
  Email,
  ModalName,
  Segment,
  SegmentRuleField,
} from "sharedTypes"
import Button from "components/Buttons/Button"
import { deleteSegment, postSegment, putSegment } from "api/guests"
import {
  addSegment, refetchGuests, removeSegment, updateSegment,
} from "context/actions"
import _ from "lodash"
import * as Yup from "yup"
import { useQueryClient } from "react-query"
import { useEmails } from "queries/emails"
import { shallow } from "zustand/shallow"
import useModal from "services/useModal"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import SelectMenu from "components/ReactHookForm/SelectMenu"
import TextField from "components/ReactHookForm/TextField"
import DatePickerField from "components/ReactHookForm/DatePickerField"
import {
  Control, useFieldArray, useWatch,
} from "react-hook-form"
import SimpleBar from "simplebar-react"
import UpgradeAlert from "components/elements/UpgradeAlert"
import useGuestsStore from "../services/useGuestsStore"

type Props = {
  eventId: string;
  segmentsEnabled: boolean;
  segmentId: string;
  segments: Segment[]
  eventGroups: EventGroup[]
  onHide: () => void;
}

const colors = [
  "gray",
  "red",
  "orange",
  "yellow",
  "green",
  "teal",
  "blue",
  "cyan",
  "purple",
  "pink",
]

const segmentSchema = Yup.object().shape({
  name: Yup.string().required(),
  color: Yup.string().required(),
  rules: Yup.array().of(Yup.object().shape({
    field: Yup.string().required(),
    operator: Yup.string().required(),
    value: Yup.mixed().required(),
  })).min(1),
})

const SegmentForm = ({
  eventId, segmentsEnabled, segmentId, segments, eventGroups, onHide,
}: Props) => {
  const queryClient = useQueryClient()
  const {
    filter, searchString, groupId, order,
  } = useGuestsStore((s) => s, shallow)
  const showModal = useModal()

  const activate = (segment: Segment) => {
    filter({ segmentId: segment.id })
    onHide()
  }

  let title = "Add Segment"
  let initialValues = {
    name: "",
    color: "gray",
    rules: [
      {
        field: "",
        operator: "",
        value: "",
      },
    ],
  }
  let onSubmit = (values) => postSegment(eventId, values).then(({ data }) => {
    addSegment(queryClient, eventId, data)
    activate(data)
  })

  let onDelete

  if (segmentId) {
    title = "Edit Segment"
    initialValues = segments.find(
      ({ id }) => segmentId === id,
    ) || initialValues
    onSubmit = (values) => putSegment(eventId, values).then(({ data }) => {
      updateSegment(queryClient, eventId, data)
      refetchGuests(queryClient, eventId, searchString, groupId, order, segmentId)
      activate(data)
    })

    onDelete = () => {
      deleteSegment(eventId, segmentId).then(() => {
        removeSegment(queryClient, eventId, segmentId)
        onHide()
      })
    }
  }

  if (!segmentsEnabled) {
    onSubmit = async () => showModal(ModalName.Upgrade)
  }

  const { data: emails = [] } = useEmails(eventId)
  const filteredEmails = (emails || []).filter((i) => i.sentOn)

  return (
    <ModalForm
      title={title}
      onClose={onHide}
      onSubmit={onSubmit}
      onDanger={onDelete}
      dangerProps={{ type: "Segment", name: initialValues.name }}
      initialValues={initialValues}
      validationSchema={!segmentsEnabled ? undefined : segmentSchema}
      validate={() => false}
      noPadding
    >
      {({ control, watch }) => {
        const rules = watch("rules")

        return (
          <Box as={SimpleBar} maxH={600} p={8}>
            <TextField label="Segment Name" name="name" control={control} />
            <SelectMenu
              w={40}
              name="color"
              control={control}
              label="Segment Tag"
              options={colors.map((color) => ({
                label: <><Icon icon="dot" color={`${color}.400`} /> {_.capitalize(color)}</>,
                value: color,
              }))}
            />
            <Text mt={8}>Guests matching <strong>all</strong> of the following rules</Text>
            <Rules
              name="rules"
              control={control}
              eventGroups={eventGroups}
              filteredEmails={filteredEmails}
              segmentsEnabled={segmentsEnabled}
              rules={rules}
            />
          </Box>
        )
      }}
    </ModalForm>
  )
}

const operators = (type) => {
  switch (type) {
    case SegmentRuleField.CheckInStatus:
    case SegmentRuleField.Confirmation:
    case SegmentRuleField.EmailCampaign:
    case SegmentRuleField.GuestList:
    case SegmentRuleField.Email:
    case SegmentRuleField.Seating:
      return [
        { label: "", value: "" },
        { label: "is", value: "eq" },
        { label: "is not", value: "ne" },
      ]
    case SegmentRuleField.CheckInDate:
      return [
        { label: "", value: "" },
        { label: "is", value: "eq" },
        { label: "is not", value: "ne" },
        { label: "before", value: "lt" },
        { label: "after", value: "gt" },
      ]
    default:
      return []
  }
}

const ValueSelect = (
  {
    field,
    name,
    control,
    guestLists,
    emails,
  }: {
    field: SegmentRuleField,
    name: string,
    control: Control<any>,
    guestLists: EventGroup[],
    emails: Email[]
  },
) => {
  switch (field) {
    case SegmentRuleField.GuestList:
      return (
        <SelectMenu
          name={name}
          control={control}
          maxListHeight={60}
          options={[
            { label: "", value: "" },
            ...(guestLists || []).map(
              ({ id, title, icon }) => (
                { label: <><Icon icon={icon || "tag"} /> {title}</>, value: id.toString() }
              ),
            ),
          ]}
        />
      )
    case SegmentRuleField.EmailCampaign:
      return (
        <SelectMenu
          name={name}
          control={control}
          maxListHeight={60}
          options={[
            { label: "", value: "" },
            ...(emails || []).map(
              ({ id, title }) => (
                { label: <><Icon icon="email-only-large" /> {title}</>, value: id.toString() }
              ),
            ),
          ]}
        />
      )
    case SegmentRuleField.CheckInDate:
      return <DatePickerField name={name} control={control} variant="outline" utc renderInPortal />
    case SegmentRuleField.CheckInStatus:
      return <SelectMenu name={name} control={control} options={[{ label: "", value: "" }, { label: "Checked In", value: "Arrived" }, { label: "Not Checked In", value: "Unarrived" }]} />
    case SegmentRuleField.Confirmation:
      return <SelectMenu name={name} control={control} options={[{ label: "", value: "" }, { label: "Confirmed", value: "Confirmed" }, { label: "Unconfirmed", value: "Unconfirmed" }, { label: "Declined", value: "Declined" }, { label: "Waitlisted", value: "Waitlisted" }]} />
    case SegmentRuleField.Seating:
      return <SelectMenu name={name} control={control} options={[{ label: "", value: "" }, { label: "Assigned", value: "Assigned" }, { label: "Standing", value: "Standing" }]} />
    case SegmentRuleField.Email:
      return <SelectMenu name={name} control={control} options={[{ label: "", value: "" }, { label: "Blank", value: "Blank" }, { label: "Invalid", value: "Invalid" }, { label: "Unsubscribed", value: "Unsubscribed" }, { label: "Hard Bounced", value: "Hard Bounced" }, { label: "Soft Bounced", value: "Soft Bounced" }]} />
    default:
      return <SelectMenu name={name} control={control} options={[]} />
  }
}

const Rule = ({
  index, remove, removable, eventGroups, emails, control,
}) => {
  const rule = useWatch({ name: `rules.${index}`, control })

  return (
    <>
      <HStack spacing={6} alignItems="center">
        <Box w="30%">
          <SelectMenu
            name={`rules.${index}.field`}
            control={control}
            options={[
              { label: "Select", value: "" },
              { label: SegmentRuleField.CheckInDate, value: SegmentRuleField.CheckInDate },
              { label: SegmentRuleField.CheckInStatus, value: SegmentRuleField.CheckInStatus },
              { label: SegmentRuleField.Confirmation, value: SegmentRuleField.Confirmation },
              { label: SegmentRuleField.Email, value: SegmentRuleField.Email },
              { label: SegmentRuleField.EmailCampaign, value: SegmentRuleField.EmailCampaign },
              { label: SegmentRuleField.GuestList, value: SegmentRuleField.GuestList },
              { label: SegmentRuleField.Seating, value: SegmentRuleField.Seating },
            ]}
          />
        </Box>
        <Box w="20%" {...!rule.field ? { opacity: 0.4, pointerEvents: "none" } : {}}>
          <SelectMenu
            name={`rules.${index}.operator`}
            control={control}
            options={operators(rule.field)}
          />
        </Box>
        <Box w="50%" {...!rule.field ? { opacity: 0.4, pointerEvents: "none" } : {}}>
          <ValueSelect
            field={rule.field}
            name={`rules.${index}.value`}
            control={control}
            guestLists={eventGroups}
            emails={emails}
          />
        </Box>
        {removable && (
          <Box pb={4}>
            <Icon
              icon="delete-solid"
              role="button"
              size={5}
              color="red.500"
              onClick={() => remove(index)}
            />
          </Box>
        )}
      </HStack>
    </>
  )
}

const Rules = ({
  name, control, eventGroups, filteredEmails, segmentsEnabled, rules,
}) => {
  const {
    fields, append, remove,
  } = useFieldArray<any, any, any>({ name, control })

  return (
    <Box my={10}>
      {fields.map(
        (condition, index) => (
          <>
            <Rule
              key={index}
              index={index}
              remove={remove}
              removable={rules.length > 1}
              eventGroups={eventGroups}
              emails={filteredEmails}
              control={control}
            />
            {index !== rules.length - 1 && <Text mb={4}>AND</Text>}
          </>
        ),
      )}
      <Button
        variant="link"
        leftIcon="plus"
        onClick={() => append({
          field: "",
          operator: "",
          value: "",
        })}
      >
        Add Condition
      </Button>
      {!segmentsEnabled && (
      <Box mt={10}><UpgradeAlert
        text="This feature requires a Corporate Subscription."
      />
      </Box>
      )}
    </Box>
  )
}

export default SegmentForm
