import React from "react"
import { Control, FieldValues } from "react-hook-form"
import TextField from "./TextField"

type Props<T extends FieldValues> = {
  name: string;
  control: Control<T, object>;
  label?: string;
  placeholder?: string;
  helperText?: string;
}

const PasswordField = <T extends FieldValues, >({
  name, control, label, placeholder, helperText,
}: Props<T>) => (
  <TextField
    name={name}
    control={control}
    label={label}
    placeholder={placeholder}
    type="password"
    autoComplete="current-password"
    helperText={helperText}
  />
  )

export default PasswordField
