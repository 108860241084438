import React from "react"
import { Text } from "@chakra-ui/react"
import AlertBox, { AlertBoxType } from "./AlertBox/AlertBox"

type Props = {
  onClickResolve: () => void
}

const PastDueAlert = ({ onClickResolve }: Props) => (
  <AlertBox
    type={AlertBoxType.Upgrade}
    text={<><Text as="strong" color="red.500">Your account is past due.</Text> <Text as="strong">It will be suspended and marked for deletion if payment is not processed promptly.</Text></>}
    buttonText="Resolve"
    onClick={onClickResolve}
  />
)

export default PastDueAlert
