import { Heading } from "@chakra-ui/react"
import React from "react"

type Props = {
  children: string
}

const SidePanelHeader = ({ children }: Props) => (
  <Heading
    bgColor="gray.200"
    borderBottomWidth={2}
    fontSize="lg"
    py={6}
    textAlign="center"
  >
    {children}
  </Heading>
)

export default SidePanelHeader
