import React, { useState } from "react"
import { useCurrentUser } from "queries/users"
import { isMobile, isTablet } from "react-device-detect"
import PageTitle from "../PageTitle"
import ErrorBoundary from "../ErrorBoundary"
import Unauthenticated from "./Unauthenticated"
import Authenticated from "./Authenticated"

const MainRouter = () => {
  const currentUser = useCurrentUser()
  const [forceDesktopView, setForceDesktopView] = useState(false)

  const handleClickShowWebappOnMobile = () => setForceDesktopView(true)

  const isPortableDevice = isMobile || isTablet
  const view = isPortableDevice && !forceDesktopView ? "mobile" : "desktop"

  return (
    <ErrorBoundary>
      <PageTitle />
      {!currentUser.id
        ? (
          <Unauthenticated
            currentUser={currentUser}
            view={view}
            onClickForceDesktopView={handleClickShowWebappOnMobile}
          />
        ) : (
          <Authenticated
            currentUser={currentUser}
            view={view}
            onClickForceDesktopView={handleClickShowWebappOnMobile}
          />
        )}
    </ErrorBoundary>
  )
}

export default MainRouter
