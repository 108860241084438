import {
  Box, Flex, FormControl, FormLabel, Input, Text,
} from "@chakra-ui/react"
import React from "react"
import ReactPlacesAutocomplete from "react-places-autocomplete"
import { Control, useController } from "react-hook-form"
import { omit } from "lodash"

interface Props {
  name: string
  control: Control<any>
  label?: string
  placeholder?: string
  onSelect: (results) => void
}

const PlacesAutocompleteField = ({
  name, control, label, placeholder, onSelect,
}: Props) => {
  const { field } = useController({ name, control })

  const geocode = (_, placeId) => {
    // @ts-ignore
    const service = new window.google.maps.places.PlacesService(document.createElement("div"))
    service.getDetails({ placeId }, (result) => onSelect(addressComponents(result)))
  }

  const addressComponents = ({ name: venueName, address_components: components }) => (
    {
      venue: venueName,
      addressLine1: addressComponent(components, "addressLine1"),
      city: addressComponent(components, "city"),
      state: addressComponent(components, "state"),
      zipcode: addressComponent(components, "zipcode"),
      country: addressComponent(components, "country"),
    }
  )

  const addressComponent = (components, componentName) => {
    const find = (type) => components.find((c) => c.types.includes(type))
    const componentValue = () => {
      switch (componentName) {
        case "addressLine1":
          return [find("street_number")?.long_name, find("route")?.long_name].filter(Boolean).join(" ")
        case "city":
          return (find("locality")?.long_name ?? find("sublocality")?.long_name)
        case "state":
          return find("administrative_area_level_1")?.short_name
        case "zipcode":
          return find("postal_code")?.short_name
        case "country":
          return find("country")?.long_name
        default:
          return ""
      }
    }

    return componentValue() ?? ""
  }

  return (
    <FormControl mb={4}>
      <ReactPlacesAutocomplete
        value={field.value}
        onChange={(value) => field.onChange(value)}
        onSelect={geocode}
      >
        {({
          getInputProps, suggestions, getSuggestionItemProps,
        }) => (
          <Flex align="center">
            {label && <FormLabel fontWeight="bold" w={1 / 4}>{label}</FormLabel>}
            <Box w="100%">
              <Input variant="flushed" {...getInputProps({ placeholder })} />
              {suggestions.length > 0 && (
              <Box borderWidth={1} mt={2} pos="absolute" zIndex={1} bg="white">
                {suggestions.map((suggestion) => {
                  const { placeId } = suggestion
                  const suggestionItemProps = omit(getSuggestionItemProps(suggestion), "key")

                  return (
                    <Box
                      key={placeId}
                      cursor="pointer"
                      _hover={{ bgColor: "gray.100" }}
                      {...suggestionItemProps}
                    >
                      <Text pt={1} px={2}>{suggestion.description}</Text>
                    </Box>
                  )
                })}
              </Box>
              )}
            </Box>
          </Flex>
        )}
      </ReactPlacesAutocomplete>
    </FormControl>
  )
}

export default PlacesAutocompleteField
