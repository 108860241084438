import { Button, Flex } from "@chakra-ui/react"
import React from "react"

type Props = {
  children: string
  color?: string
}

const FormSubmitButton = ({ children, color }: Props) => (
  <Flex justifyContent="center">
    <Button backgroundColor={color} color="white" minW={72}>{children}</Button>
  </Flex>
)

export default FormSubmitButton
