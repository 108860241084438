import { deleteSubscription, postSubscription } from "api/accounts"
import React, { useState } from "react"
import { Plan, Subscription } from "sharedTypes"
import * as Yup from "yup"
import {
  Box, Flex, Link, Text,
} from "@chakra-ui/react"
import Spinner from "components/elements/Spinner"
import { asMoney } from "utilities/strings"
import { updateCurrentAccount } from "context/actions"
import { usePlans } from "queries/plans"
import { useQueryClient } from "react-query"
import _ from "lodash"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import RadioButtons from "components/ReactHookForm/RadioButtons"
import Checkbox from "components/ReactHookForm/Checkbox"
import Select from "components/ReactHookForm/Select"
import TextArea from "components/ReactHookForm/TextArea"

type Props = {
  subscription: Subscription
}

const SubscriptionDowngrader = ({ subscription }: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(null as any)
  const queryClient = useQueryClient()
  const { type: currentType, amount: currentAmount, interval } = subscription

  const { isLoading, plans } = usePlans()
  if (isLoading) { return <Spinner /> }

  const filteredOptions = () => _.reverse(plans.filter((p) => (subscription.interval === "year" ? p.annualPrice * 100 < subscription.amount : p.monthlyPrice * 100 < subscription.amount)))

  const downgradeSubscription = (params) => (
    params.type === "Free"
      ? deleteSubscription(params)
      : postSubscription({
        ...params,
        stripePriceId: selectedPlan.stripePriceIds[subscription.interval],
      }))

  const onSubmit = (values) => {
    if (selectedPlan === null) {
      return setSelectedPlan(plans.find((plan) => plan.type === values.type))
    }

    return downgradeSubscription(values)
      .then((response) => {
        updateCurrentAccount(queryClient, response.data)
        setSelectedPlan(null)
        setShowModal(false)
      })
  }

  const SelectSchema = Yup.object().shape({
    type: Yup.string().oneOf(["Professional", "Standard", "Basic", "Free"]).required(),
  })
  const ConfirmSchema = Yup.object().shape({
    check1: Yup.mixed().test("", "", (value) => Boolean(value)),
    check2: Yup.mixed().test("", "", (value) => Boolean(value)),
    check3: Yup.mixed().test("", "", (value) => Boolean(value)),
    check4: Yup.mixed().test("", "", (value) => Boolean(selectedPlan.downgradeConfirmations.length < 4 || value)),
    check5: Yup.mixed().test("", "", (value) => Boolean(selectedPlan.downgradeConfirmations.length < 5 || value)),
    option: Yup.mixed().test("", "", (value) => Boolean(selectedPlan.type !== "Free" || value)),
  })

  const label = (option: Plan) => (
    <Flex justify="space-between">
      <Box>
        <Text fontSize="xl" fontWeight="semibold">{option.type === "Free" ? "Cancel Subscription" : `Downgrade to ${option.type} Plan`}</Text>
        <Text>{option.downgradeText}</Text>
      </Box>
      <Text>
        <Text
          fontSize="xl"
          fontWeight="semibold"
          as="span"
        >{asMoney(interval === "month" ? option.monthlyPrice : option.annualPrice, "USD", true)}
        </Text>/{interval}
      </Text>
    </Flex>
  )

  const planSummary = ({
    type, amount, current,
  }) => (
    <Box bg={current ? "gray.50" : "blue.50"} borderWidth={1} px={5} py={4} mb={5}>
      <Text casing="uppercase" fontSize="sm" color="gray.500" fontWeight="semibold">{ current ? "Current" : "New" }</Text>
      <Flex justify="space-between" fontSize="xl" fontWeight="semibold">
        {type === "Free" && <Text>Cancel Subscription</Text>}
        {type !== "Free" && <Text>{type} Plan {current && subscription.legacy && " (Legacy)"}</Text>}
        <Text>
          {asMoney(amount, "USD", true)}
          /
          {interval}
        </Text>
      </Flex>
    </Box>
  )

  const REASONS = [
    "Not using it enough (or at all)",
    "Only needed it for a limited time",
    "Found another product for our events",
    "Technical issues / product usability",
    "Too expensive",
    "Other",
  ]

  const formContents = (control) => {
    if (selectedPlan) {
      return (
        <>
          <Text fontSize="xl" color="red.600" mb={4}>
            {selectedPlan.type === "Free"
              ? "Are you sure you want to cancel your Diobox Subscription?" : "Are you sure you want to downgrade?"}
          </Text>
          {planSummary({
            type: currentType, amount: currentAmount / 100, current: true,
          })}
          {planSummary({
            type: selectedPlan.type,
            amount: interval === "month" ? selectedPlan.monthlyPrice : selectedPlan.annualPrice,
            current: false,
          })}
          <Text fontSize="xl" mb={4}>Read and check all boxes below:</Text>
          {selectedPlan.downgradeConfirmations.map((checkbox, index) => (<Checkbox key={index} name={`check${index + 1}`} control={control} label={checkbox} />))}
          {selectedPlan.type === "Free"
            && (
              <>
                <Select
                  vertical
                  name="option"
                  control={control}
                  label="Reason for canceling?"
                  labelSize="lg"
                  includeBlank
                  options={REASONS.map((r) => ({ label: r, value: r }))}
                />
                <TextArea
                  name="text"
                  control={control}
                  placeholder="Other comments (optional)…"
                />
              </>
            )}
        </>
      )
    }

    return (
      <>
        {planSummary({
          type: currentType, amount: currentAmount / 100, current: true,
        })}
        <Box>
          <RadioButtons
            name="type"
            control={control}
            spacing={8}
            options={filteredOptions()
              .map(((o) => ({ label: label(o), value: o.type })))}
          />
        </Box>
      </>
    )
  }

  return (
    <>
      <Link
        fontSize="xs"
        onClick={() => setShowModal(true)}
      >
        Downgrade
      </Link>
      {showModal && (
        <ModalForm
          title="Downgrade"
          onClose={() => {
            setSelectedPlan(null)
            setShowModal(false)
          }}
          initialValues={{ type: "" }}
          validationSchema={selectedPlan ? ConfirmSchema : SelectSchema}
          onSubmit={onSubmit}
          submitText={selectedPlan?.type === "Free" ? "Cancel Subscription" : "Downgrade"}
          submitColorScheme="button.danger"
        >
          {({ control }) => formContents(control)}
        </ModalForm>
      )}
    </>
  )
}

export default SubscriptionDowngrader
