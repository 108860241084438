import React from "react"
import { Box } from "@chakra-ui/react"
import { TextBlock } from "sharedTypes/builder"

const Text = ({ block: { data } }: { block: TextBlock }) => (
  <Box
    style={{ lineHeight: data.lineHeight }}
    dangerouslySetInnerHTML={{ __html: data.text }}
    bgColor={data.blockColor}
    color={data.textColor}
    fontSize={`${data.size}px`}
    fontFamily={data.font}
    px={5}
    pt={`${data.paddingTop * 4}px`}
    pb={`${data.paddingBottom * 4}px`}
    sx={{
      "& ul": {
        padding: "0 0 0 2.5em",
      },
    }}
  />
)

export default Text
