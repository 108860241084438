import React from "react"
import {
  Box, Flex, Stack, Text,
} from "@chakra-ui/react"
import UpgradeAlert from "components/elements/UpgradeAlert"
import { DioboxEvent, AccountUser } from "sharedTypes"
import TextField from "components/ReactHookForm/TextField"
import RadioButtons from "components/ReactHookForm/RadioButtons"
import { hasValidCustomDomain } from "utilities/strings"
import { Control } from "react-hook-form/dist/types/form"
import { replyToEmailOptions } from "utilities/objects"
import SimpleBar from "simplebar-react"
import { FieldValues } from "react-hook-form"

type Props<T extends FieldValues> = {
  event: DioboxEvent,
  control: Control<T, object>
  accountUsers?: AccountUser[],

}
const Setup = <T extends FieldValues, >({ event, accountUsers, control }: Props<T>) => {
  const replyToOptions = replyToEmailOptions(event.users, accountUsers)

  return (
    <Box as={SimpleBar} width="100%" height="full">
      <Stack w={800} spacing={6} p={8} m="auto">
        <TextField
          name="title"
          control={control}
          label="Email Name"
          labelSize="lg"
          helperText="This title is for internal use and won't be visible to your guests."
        />
        <TextField
          name="subject"
          control={control}
          label="Subject Line"
          labelSize="lg"
        />
        <TextField
          name="from"
          control={control}
          label="Sender Name"
          labelSize="lg"
        />
        {hasValidCustomDomain(event) ? (
          <TextField
            name="fromMailbox"
            control={control}
            label="Sender Email"
            labelSize="lg"
            rightElement={`@${event.customDomain?.domain}`}
          />
        ) : (
          <>
            <TextField
              disabled
              name="senderEmail"
              control={control}
              label="Sender Email"
              labelSize="lg"
            />
            {replyToOptions.length > 0 && (
            <RadioButtons
              name="replyTo"
              control={control}
              label='"Reply to" Address'
              labelSize="lg"
              options={replyToOptions}
            />
            )}
            <Flex>
              <Text w="100%">
                All emails will be sent from events@diobox.com, but you can select a “reply to”
                address, so that if anyone replies, their messages will still get delivered to
                you. To add a new “reply-to” address, invite a new team member and have them
                verify their email address.
              </Text>
            </Flex>
          </>
        )}
        {!event.whiteLabel
          && (
            <UpgradeAlert
              text="Upgrade to the Corporate Subscription to send emails from your company domain"
            />
          )}
      </Stack>
    </Box>
  )
}

export default Setup
