import React from "react"
import { Control, useController } from "react-hook-form"
import { RadioOption } from "sharedTypes"
import RSVPRadioButtons from "./RSVPRadioButtons"

type Props = {
  name: string
  control: Control<any>
  options: RadioOption[]
  primaryColor?: string
  required?: boolean
}

const RSVPRadioButtonsField = ({
  name,
  control,
  options,
  primaryColor,
  required,
}: Props) => {
  const {
    field: { onChange, value },
  } = useController({ name, control, rules: { required } })

  return (
    <RSVPRadioButtons
      name={name}
      options={options}
      onChange={onChange}
      defaultValue={value}
      color={primaryColor}
    />
  )
}

export default RSVPRadioButtonsField
