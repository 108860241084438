import React, { useState } from "react"
import { Speaker as DioboxSpeaker } from "sharedTypes"
import EditableSection from "components/layout/EditableSection"
import { Text } from "@chakra-ui/react"
import Avatar from "components/elements/Avatar"
import SpeakerForm from "./Form"

type Props = {
  speaker: DioboxSpeaker
  isSorting: boolean
}

const Speaker = ({ speaker, isSorting }: Props) => {
  const [edit, setEdit] = useState(false)

  const {
    name,
    company,
    title,
    description,
    photoUrl,
  } = speaker

  let titleAndCompanySection
  if (title || company) {
    titleAndCompanySection = (
      <Text mb={4}>
        {[title, company].filter(Boolean).join(", ")}
      </Text>
    )
  }

  const descriptionSection = (description && <Text fontSize="sm">{description}</Text>)

  return (
    <EditableSection
      title={<Avatar name={name} src={photoUrl} />}
      onEdit={isSorting ? null : () => setEdit(true)}
      columns
      showBorder
      titleWidth="auto"
    >
      <>
        <Text fontSize="md">{name}</Text>
        {titleAndCompanySection}
        <Text whiteSpace="pre-wrap">{descriptionSection}</Text>
      </>
      {edit && (
        <SpeakerForm
          speaker={speaker}
          onHide={() => {
            setEdit(false)
          }}
        />
      )}
    </EditableSection>
  )
}

export default Speaker
