import { GuestSummary } from "sharedTypes"
import React, { ReactNode } from "react"
import { firstName, lastName } from "utilities/strings"
import GuestAvatar from "components/elements/GuestAvatar"
import { Text } from "@chakra-ui/react"
import { MenuItem } from "components/dialogs/Menu"
import { Link, useParams } from "react-router-dom"
import { useEventGuestPaths } from "utilities/routes"
import SeatItem from "./SeatItem"

type Props = {
  children: ReactNode;
  guest: GuestSummary;
  canEditSeating: boolean;
  seatNumber?: number;
  onRemoveGuestFromSeat?: (number: number) => void;
  onMoveGuestToWaitlist?: (number: number) => void;
  onRemoveGuestFromWaitlist?: (guestId: string) => void;
  onMoveGuestToOpenSeat?: (guest: GuestSummary) => void;
}

const GuestItem = (
  {
    guest,
    seatNumber,
    children,
    onRemoveGuestFromSeat,
    onMoveGuestToWaitlist,
    onRemoveGuestFromWaitlist,
    onMoveGuestToOpenSeat,
    canEditSeating,
  }: Props,
) => {
  const { eventId } = useParams<{ eventId: string }>()
  const { eventGuestPath } = useEventGuestPaths()
  const {
    id, checkedIn, status, photoUrl, name,
  } = guest as GuestSummary
  const [guestFirstName, guestLastName] = [firstName(name), lastName(name)]

  return (
    <SeatItem
      seatNumber={seatNumber}
      title={`${guestFirstName} ${guestLastName}`}
      label={children}
      description={(
        <>
          <GuestAvatar
            firstName={guestFirstName}
            lastName={guestLastName}
            photoUrl={photoUrl}
            checkedIn={!!checkedIn}
            status={status}
          />
          <Text ml={2}>
            <Text as="strong" fontWeight="semibold">{guestFirstName}</Text> <Text as="span">{guestLastName}</Text>
          </Text>
        </>
      )}
      menuItems={[
        <MenuItem as={Link} to={eventGuestPath(eventId, id)} isCentered>
          View Guest Profile
        </MenuItem>,
        onMoveGuestToWaitlist && seatNumber ? (
          <MenuItem onClick={() => { onMoveGuestToWaitlist(seatNumber) }} isCentered>
            Move to Waitlist
          </MenuItem>
        ) : null,
        onRemoveGuestFromSeat && seatNumber ? (
          <MenuItem color="red.500" onClick={() => { onRemoveGuestFromSeat(seatNumber) }} isCentered>
            Remove Guest from Seat
          </MenuItem>
        ) : null,
        onMoveGuestToOpenSeat ? (
          <MenuItem onClick={() => { onMoveGuestToOpenSeat(guest) }} isCentered>
            Move to Open Seat
          </MenuItem>
        ) : null,
        onRemoveGuestFromWaitlist ? (
          <MenuItem color="red.500" onClick={() => { onRemoveGuestFromWaitlist(guest.id) }} isCentered>
            Remove Guest from Waitlist
          </MenuItem>
        ) : null,
      ]}
      canEditSeating={canEditSeating}
    />
  )
}

export default GuestItem
