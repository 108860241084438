import React from "react"
import { Box, Stack, Text } from "@chakra-ui/react"
import UpgradeAlert from "components/elements/UpgradeAlert"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import { Account, DioboxEvent } from "sharedTypes"
import ImageUpload from "components/ReactHookForm/ImageUpload"
import ImageUploadComponent from "components/ImageUploadComponent"
import TextField from "components/ReactHookForm/TextField"
import extendFormValuesWithImages from "utilities/extendFormValuesWithImages"
import TitleWithSubtitle from "../TitleWithSubtitle"
import ConfigBlock from "./ConfigBlock"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  account: Account
  onChangeEventPreview: (values: Partial<DioboxEvent>) => void
}

const PageBranding = ({
  event: {
    portalShowLogo,
    portalShowPageHeader,
    portalLogoUrl,
    portalPageHeader,
    whiteLabel,
  },
  updateEvent,
  account,
  onChangeEventPreview,
}: Props) => {
  const { subscription } = account
  const isCorporate = subscription?.type === "Corporate"

  const values = {
    portalShowLogo,
    portalShowPageHeader,
    portalLogoUrl,
    portalPageHeader,
    notWhiteLabel: !whiteLabel,
  }

  const updateEventWithNoWhiteLabel = (data) => {
    const { notWhiteLabel, ...eventToUpdate } = data
    eventToUpdate.whiteLabel = !notWhiteLabel

    return updateEvent(eventToUpdate)
  }

  return (
    <>
      <TitleWithSubtitle
        title="Page Branding"
        subtitle="Page branding includes multiple elements such as logos and headers at the top and bottom of the Guest Portal."
      />
      <ContainedForm
        values={extendFormValuesWithImages(values, ["portalLogo"])}
        onSubmit={updateEventWithNoWhiteLabel}
        noPadding
        name="Page Logo"
        onChange={onChangeEventPreview}
      >
        {({ control, watch }) => {
          const enabled = watch("portalShowLogo")
          const pageHeaderEnabled = watch("portalShowPageHeader")

          return (
            <Stack py={4} spacing={4}>
              <ConfigBlock title="Page Logo" switchName="portalShowLogo" control={control} showSeparator disabled={!isCorporate}>
                <Box mb={7}>
                  <Text>
                    Add a custom logo to display on all Guest Portal and confirmations pages.
                  </Text>
                </Box>
                <Text>
                  For best results, upload a transparent PNG logo in 320 x 132 pixels.
                  Supports PNG & JPG formats up to 1 MB.
                </Text>
                <Box mt={4} mb={8}>
                  <ImageUpload
                    name="portalLogo"
                    control={control}
                    imageWidth="auto"
                    imageHeight={12}
                    imageUrl={portalLogoUrl ? `${portalLogoUrl}?w=320` : ""}
                    Component={UploadComponent}
                    disabled={!enabled || !isCorporate}
                  />
                </Box>
                {!isCorporate && <UpgradeAlert noBorder text="Requires Corporate Subscription" />}
              </ConfigBlock>
              <ConfigBlock title="Page Header" switchName="portalShowPageHeader" control={control} showSeparator>
                <Text>
                  Set a custom header, such as your organization&apos;s name, for consistent
                  branding across Guest Portal pages.
                </Text>
                <Box mt={4}>
                  <TextField disabled={!pageHeaderEnabled} variant="outline" control={control} name="portalPageHeader" />
                </Box>
                <Text>
                  This section features a subtle dropdown menu offering guests
                  shortcuts to edit their profiles and email subscription
                  preferences.
                </Text>
              </ConfigBlock>
              <ConfigBlock title="Diobox Branding" switchName="notWhiteLabel" control={control} disabled>
                <Text>
                  Include a “Powered by Diobox” logo and link in the footer.
                </Text>
              </ConfigBlock>
            </Stack>
          )
        }}
      </ContainedForm>
    </>
  )
}

const UploadComponent = (props) => <ImageUploadComponent {...props} uploadButtonText="Upload Logo" removeButtonText="Remove" />

export default PageBranding
