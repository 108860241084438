import { QueryClient } from "react-query"
import { getEventCounts } from "api/events"
import _, { findIndex } from "lodash"
import { eventCacheKey } from "queries/events"
import { guestCacheKey, guestsCacheKey } from "queries/guests"
import { emailCacheKey, emailsCacheKey } from "queries/emails"
import { userCacheKey } from "queries/users"
import { produce } from "immer"
import { accountUsersCacheKey } from "queries/accountUsers"
import {
  Account as DioboxAccount,
  AgendaRow,
  CheckInLocation,
  CustomDomain,
  DioboxEvent,
  EventGroup,
  Form,
  Guest,
  GuestSummary,
  Email,
  Segment,
  Speaker,
  Table,
  Transaction,
  User,
  Website,
  FormQuestion,
  Translations,
  TextMessage,
} from "sharedTypes"
import { currentAccountId } from "services/authentication"
import updateGuests from "utilities/queries"
import { guestSummary } from "utilities/objects"
import { formCacheKey, formsCacheKey } from "queries/forms"
import { eventQuestionCacheKey, eventQuestionsCacheKey } from "queries/questions"
import { eventFormSubmissionCacheKey } from "queries/formSubmissions"
import { textMessageCacheKey, textMessagesCacheKey } from "queries/texts"

export const setCurrentUser = (queryClient: QueryClient, data: User) => {
  queryClient.setQueryData(userCacheKey(), data)
}

export const updateCurrentAccount = (
  queryClient: QueryClient, data: DioboxAccount,
) => {
  if (!userCached(queryClient)) { return }

  queryClient.setQueryData(userCacheKey(), ((oldData: any) => ({
    ...oldData,
    accounts: oldData.accounts.map(
      (a) => (a.id === data.id ? data : a),
    ),
  })))
}

export const addCustomDomain = (queryClient: QueryClient, customDomain: CustomDomain) => {
  if (!userCached(queryClient)) { return }

  queryClient.setQueryData(userCacheKey(), ((oldData: any) => produce(
    oldData,
    (draftData) => {
      const accountIndex = findIndex(draftData.accounts, { id: currentAccountId() || "" })
      draftData.accounts[accountIndex].customDomains.push(customDomain)
    },
  )))
}

export const updateCustomDomain = (queryClient: QueryClient, customDomain: CustomDomain) => {
  if (!userCached(queryClient)) { return }

  queryClient.setQueryData(userCacheKey(), ((oldData: any) => produce(
    oldData,
    (draftData) => {
      const accountIndex = findIndex(
        draftData.accounts, { id: currentAccountId() || "" },
      )
      const customDomainIndex = findIndex(
        draftData.accounts[accountIndex].customDomains, { id: customDomain.id },
      )
      draftData.accounts[accountIndex].customDomains.splice(customDomainIndex, 1, customDomain)
    },
  )))
}

export const removeCustomDomain = (queryClient: QueryClient, customDomain: CustomDomain) => {
  if (!userCached(queryClient)) { return }

  queryClient.setQueryData(userCacheKey(), ((oldData: any) => produce(
    oldData,
    (draftData) => {
      const accountIndex = findIndex(
        draftData.accounts, { id: currentAccountId() || "" },
      )
      const customDomainIndex = findIndex(
        draftData.accounts[accountIndex].customDomains, { id: customDomain.id },
      )
      draftData.accounts[accountIndex].customDomains.splice(customDomainIndex, 1)
    },
  )))
}

export const setCurrentEvent = (
  queryClient: QueryClient, event: DioboxEvent, user: User,
) => {
  queryClient.setQueryData(eventCacheKey(event.id),
    {
      ...event,
      currentUserRole: event.users.find(
        (eventUser) => eventUser.user?.id === user.id,
      )?.role,
    })
}

const eventCached = (
  queryClient: QueryClient, eventId: string,
):boolean => queryClient.getQueryState(eventCacheKey(eventId))?.status === "success"
const emailsCached = (
  queryClient: QueryClient, eventId: string,
):boolean => queryClient.getQueryState(emailsCacheKey(eventId))?.status === "success"
const guestCached = (
  queryClient: QueryClient, guestId: string,
):boolean => queryClient.getQueryState(guestCacheKey(guestId))?.status === "success"
const userCached = (
  queryClient: QueryClient,
):boolean => queryClient.getQueryState(userCacheKey())?.status === "success"
const accountUsersCached = (
  queryClient: QueryClient,
):boolean => queryClient.getQueryState(accountUsersCacheKey(currentAccountId()))?.status === "success"
const formCached = (
  queryClient: QueryClient, formId: string,
):boolean => queryClient.getQueryState(formCacheKey(formId))?.status === "success"
const formSubmissionCached = (
  queryClient: QueryClient, externalId: string,
):boolean => queryClient.getQueryState(eventFormSubmissionCacheKey(externalId))?.status === "success"
const eventQuestionCached = (
  queryClient: QueryClient, id: string,
):boolean => queryClient.getQueryState(eventQuestionCacheKey(id))?.status === "success"
const textMessageCached = (
  queryClient: QueryClient, textMessageId: string,
):boolean => queryClient.getQueryState(textMessageCacheKey(textMessageId))?.status === "success"

const updateEventCounts = (queryClient, eventId) => {
  if (!eventCached(queryClient, eventId)) { return }

  getEventCounts(eventId).then(({ data }) => {
    queryClient.setQueryData(
      eventCacheKey(eventId), (oldData: any) => ({ ...oldData, counts: data }),
    )
  })
}
const debouncedUpdateEventCounts = _.debounce(updateEventCounts, 250)

export const refreshEvents = (queryClient: QueryClient) => queryClient.invalidateQueries("event")

export const updateWebsite = (queryClient: QueryClient, eventId: string, data: Website) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(
    eventCacheKey(eventId), (oldData: any) => ({ ...oldData, website: data }),
  )
}

export const updateSpeakers = (queryClient: QueryClient, eventId: string, speakers: Speaker[]) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    website: {
      ...oldData.website,
      speakers,
    },
  }))
}

export const addSpeaker = (queryClient: QueryClient, eventId: string, speaker: Speaker) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    website: { ...oldData.website, speakers: [...oldData.website.speakers, speaker] },
  }))
}

export const removeSpeaker = (queryClient: QueryClient, eventId: string, speakerId: number) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    website: {
      ...oldData.website,
      speakers: oldData.website.speakers.filter((s) => s.id.toString() !== speakerId.toString()),
    },
  }))
}

export const updateSpeaker = (queryClient: QueryClient, eventId: string, speaker: Speaker) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    website: {
      ...oldData.website,
      speakers: oldData.website.speakers.map(
        (s) => (s.id.toString() === speaker.id.toString() ? speaker : s),
      ),
    },
  }))
}

export const addAgendaRow = (queryClient: QueryClient, eventId: string, agendaRow: AgendaRow) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    website: { ...oldData.website, agendaRows: [...oldData.website.agendaRows, agendaRow] },
  }))
}

export const removeAgendaRow = (queryClient: QueryClient, eventId: string, agendaRowId: number) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    website: {
      ...oldData.website,
      agendaRows: oldData.website.agendaRows.filter(
        (a) => a.id.toString() !== agendaRowId.toString(),
      ),
    },
  }))
}

export const updateAgendaRow = (
  queryClient: QueryClient, eventId: string, agendaRow: AgendaRow,
) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    website: {
      ...oldData.website,
      agendaRows: oldData.website.agendaRows.map(
        (a) => (a.id.toString() === agendaRow.id.toString() ? agendaRow : a),
      ),
    },
  }))
}

export const addEventGroup = (
  queryClient: QueryClient, eventId: string, eventGroup: EventGroup,
) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    eventGroups: [...oldData.eventGroups, eventGroup],
  }))
  debouncedUpdateEventCounts(queryClient, eventId)
}

export const updateEventGroup = (
  queryClient: QueryClient, eventId: string, eventGroup: EventGroup,
) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    eventGroups: oldData.eventGroups.map(
      (eg) => (eg.id.toString() === eventGroup.id.toString() ? eventGroup : eg),
    ),
  }))
}

export const removeEventGroup = (
  queryClient: QueryClient, eventId: string, eventGroupId: string,
) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    eventGroups: oldData.eventGroups.filter(
      (eg) => (eg.id.toString() !== eventGroupId.toString()),
    ),
  }))
}

export const addEmail = (queryClient: QueryClient, eventId: string, email: Email) => {
  if (!emailsCached(queryClient, eventId)) { return }

  queryClient.setQueryData(emailsCacheKey(eventId), (emails: any) => [email, ...emails])
}

export const updateEmail = (queryClient: QueryClient, eventId: string, email: Email) => {
  if (!emailsCached(queryClient, eventId)) { return }

  queryClient.setQueryData(
    emailsCacheKey(eventId),
    (emails: any) => emails.map((e) => (e.id.toString() === email.id.toString() ? email : e)),
  )
  queryClient.setQueryData(emailCacheKey(email.id), email)
}

export const removeEmail = (queryClient: QueryClient, eventId: string, emailId) => {
  if (!emailsCached(queryClient, eventId)) { return }

  queryClient.setQueryData(
    emailsCacheKey(eventId),
    (emails: any) => emails.filter((e) => e.id.toString() !== emailId.toString()),
  )
}

export const addCheckInLocation = (
  queryClient: QueryClient, eventId: string, checkInLocation: CheckInLocation,
) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    checkInLocations: [...oldData.checkInLocations, checkInLocation],
  }))
}

export const updateCheckInLocation = (
  queryClient: QueryClient, eventId: string, checkInLocation: CheckInLocation,
) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    checkInLocations: oldData.checkInLocations.map(
      (l) => (l.id.toString() === checkInLocation.id.toString() ? checkInLocation : l),
    ),
  }))
}

export const removeCheckInLocation = (
  queryClient: QueryClient, eventId: string, checkInLocationId: string,
) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    checkInLocations: oldData.checkInLocations.filter(
      (l) => l.id.toString() !== checkInLocationId.toString(),
    ),
  }))
}

export const refetchGuests = (
  queryClient: QueryClient,
  eventId: string,
  searchString?: string,
  groupId?: string | number,
  order?: string,
  segmentId?: string,
) => {
  queryClient.removeQueries(guestCacheKey())
  queryClient.invalidateQueries(guestsCacheKey(eventId, searchString, groupId, order, segmentId))
  debouncedUpdateEventCounts(queryClient, eventId)
}

export const updateGuest = (queryClient: QueryClient, guest: Guest) => {
  queryClient.setQueriesData(
    guestsCacheKey(guest.eventId),
    (oldData: any) => {
      if (!oldData?.pages) {
        return oldData
      }

      return ({
        ...oldData,
        pages: oldData.pages.map((page) => {
          if (!page.data) {
            return page
          }

          return {
            ...page,
            data: updateGuests(page.data, guestSummary(guest)),
          }
        }),
      })
    },
  )

  queryClient.setQueryData(
    guestCacheKey(guest.id),
    {
      ...guest,
      group: updateGuests(guest.group, guestSummary(guest)),
    },
  )
  debouncedUpdateEventCounts(queryClient, guest.eventId)
}

export const selectGuest = (addSelectedGuestId: (guestId: string) => void, guest: GuestSummary) => {
  addSelectedGuestId(guest.id)
}

export const deselectGuest = (removeSelectedGuestId, guest: GuestSummary) => {
  removeSelectedGuestId(guest.id)
}

const updateGuestsStatuses = (
  queryClient: QueryClient,
  eventId: string,
  guestIds,
  data,
) => {
  const updateStatus = (g) => (guestIds.includes(g.id)
    ? { ...g, status: parseInt(data, 10) }
    : g)

  queryClient.setQueriesData(
    guestsCacheKey(eventId),
    (oldData: any) => ({
      ...oldData,
      pages: oldData.pages.map((page) => {
        if (!page.data) {
          return page
        }

        return ({
          ...page,
          data: page.data.map(
            (guest) => {
              const guestWithUpdatedPlusOnes = {
                ...guest,
                plusOnes: guest.plusOnes.map(
                  (plusOne) => updateStatus(plusOne),
                ),
              }

              return updateStatus(guestWithUpdatedPlusOnes)
            },
          ),
        })
      }),
    }),
  )
}

export const updateSelectedGuestsStatuses = (
  queryClient: QueryClient,
  eventId: string,
  selectedGuestIds: string[],
  allSelected: boolean,
  deselectAll: () => void,
  data,
) => {
  if (allSelected) {
    queryClient.invalidateQueries(guestsCacheKey(eventId))
  } else {
    updateGuestsStatuses(queryClient, eventId, selectedGuestIds, data)
  }
  deselectAll()
  debouncedUpdateEventCounts(queryClient, eventId)
}

export const removeSelectedGuests = (
  queryClient: QueryClient, eventId: string,
) => {
  queryClient.invalidateQueries(guestsCacheKey(eventId))
  debouncedUpdateEventCounts(queryClient, eventId)
}

export const checkInGuests = (queryClient, eventId, guestIds, currentGuestId) => {
  guestIds.forEach((guestId) => {
    adjustGuestCheckIns(queryClient, eventId, guestId, 1)
  })

  queryClient.invalidateQueries(guestCacheKey(currentGuestId))
}

export const adjustGuestCheckIns = (
  queryClient: QueryClient, eventId: string, guestId: string, adjustBy: number,
) => {
  queryClient.setQueriesData(
    guestsCacheKey(eventId),
    (oldData: any) => {
      if (!oldData?.pages) {
        return oldData
      }

      return ({
        ...oldData,
        pages: oldData.pages.map((page) => {
          if (!page.data) {
            return page
          }

          return {
            ...page,
            data: updateGuests(
              page.data,
              (guest) => (
                guest.id === guestId
                  ? ({ ...guest, checkedIn: guest.checkedIn + adjustBy })
                  : guest
              ),
            ),
          }
        }),
      })
    },
  )
  queryClient.setQueriesData(
    "guest",
    (oldData: any) => ({
      ...oldData,
      group: oldData.group
        ? updateGuests(
          oldData.group,
          (guest) => (
            guest.id === guestId ? ({ ...guest, checkedIn: guest.checkedIn + adjustBy }) : guest
          ),
        )
        : undefined,
    }),
  )
  debouncedUpdateEventCounts(queryClient, eventId)
}

export const addGuestTransaction = (queryClient: QueryClient, transaction: Transaction) => {
  if (guestCached(queryClient, transaction.guestId)) {
    queryClient.setQueryData(
      guestCacheKey(transaction.guestId),
      (oldData: any) => ({
        ...oldData,
        checkedIn: oldData.checkedIn + (transaction.type === "AttendanceTransaction" ? 1 : 0),
        personInfo: {
          ...oldData.personInfo,
          transactions: [
            ...oldData.personInfo.transactions,
            transaction,
          ],
        },
      }),
    )
  }

  if (transaction.type === "AttendanceTransaction") {
    adjustGuestCheckIns(queryClient, transaction.eventId, transaction.guestId, 1)
  }
}

export const updateGuestTransaction = (queryClient: QueryClient, transaction: Transaction) => {
  if (guestCached(queryClient, transaction.guestId)) {
    queryClient.setQueryData(
      guestCacheKey(transaction.guestId),
      (oldData: any) => ({
        ...oldData,
        personInfo: {
          ...oldData.personInfo,
          transactions: oldData.personInfo.transactions.map(
            (t) => (t.id.toString() === transaction.id.toString() ? transaction : t),
          ),
        },
      }),
    )
  }
}

export const removeGuestTransaction = (queryClient: QueryClient, transaction: Transaction) => {
  if (guestCached(queryClient, transaction.guestId)) {
    queryClient.setQueryData(
      guestCacheKey(transaction.guestId),
      (oldData: any) => ({
        ...oldData,
        checkedIn: oldData.checkedIn - (transaction.type === "AttendanceTransaction" ? 1 : 0),
        personInfo: {
          ...oldData.personInfo,
          transactions: oldData.personInfo.transactions.filter(
            (t) => t.id.toString() !== transaction.id.toString(),
          ),
        },
      }),
    )
  }

  if (transaction.type === "AttendanceTransaction") {
    adjustGuestCheckIns(queryClient, transaction.eventId, transaction.guestId, -1)
  }
}

export const removeGuestFormSubmissionLineItem = (
  queryClient: QueryClient,
  guestId: string,
  externalId: string,
) => {
  if (guestCached(queryClient, guestId)) {
    queryClient.setQueryData(
      guestCacheKey(guestId),
      (oldData: any) => ({
        ...oldData,
        personInfo: {
          ...oldData.personInfo,
          formSubmissionLineItems: oldData.personInfo.formSubmissionLineItems.filter(
            (item) => item.externalId !== externalId,
          ),
        },
      }),
    )
  }
}

export const removeFormSubmissionLineItem = (
  queryClient: QueryClient,
  formSubmissionExternalId: string,
  externalId: string,
  guestId: string,
) => {
  removeGuestFormSubmissionLineItem(queryClient, guestId, externalId)

  if (formSubmissionCached(queryClient, formSubmissionExternalId)) {
    return queryClient.setQueryData(
      eventFormSubmissionCacheKey(formSubmissionExternalId),
      (oldData: any) => ({
        ...oldData,
        lineItems: oldData.lineItems.filter(
          (item) => item.externalId !== externalId,
        ),
      }),
    )
  }

  return {}
}

export const refetchSubmissions = (queryClient: QueryClient, eventId: string) => queryClient.invalidateQueries(["eventFormSubmissions", eventId])

export const addTable = (
  queryClient: QueryClient, eventId: string, table: Table,
) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    tables: [...oldData.tables, table],
  }))
  debouncedUpdateEventCounts(queryClient, eventId)
}

export const updateTable = (
  queryClient: QueryClient, eventId: string, table?: Table,
) => {
  if (!table?.id) { return }

  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    tables: oldData.tables.map((t) => (t.id.toString() === table.id.toString() ? table : t)),
  }))
  debouncedUpdateEventCounts(queryClient, eventId)
}

export const removeTable = (
  queryClient: QueryClient, eventId: string, tableId: string,
) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    tables: oldData.tables.filter((t) => (t.id.toString() !== tableId.toString())),
  }))
  queryClient.setQueriesData(
    guestsCacheKey(eventId),
    (oldData: any) => ({
      ...oldData,
      pages: oldData.pages.map((page) => {
        if (!page.data) {
          return page
        }

        return ({
          ...page,
          data: page.data.map(
            (g) => (
              g.seatingAssignment?.seatingTableId.toString() === tableId
                ? { ...g, seatingAssignment: null }
                : g
            ),
          ),
        })
      }),
    }),
  )
  debouncedUpdateEventCounts(queryClient, eventId)
}

export const updateTables = (queryClient: QueryClient, eventId: string, tables: Table[]) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({ ...oldData, tables }))
  debouncedUpdateEventCounts(queryClient, eventId)
}

export const updateWebsiteViewsCount = (
  queryClient: QueryClient, eventId: string, viewsCount: number,
) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(
    eventCacheKey(eventId),
    (oldData: any) => ({ ...oldData, website: { ...oldData.website, viewsCount } }),
  )
}

export const addSegment = (queryClient: QueryClient, eventId: string, segment: Segment) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    segments: [...oldData.segments, segment],
  }))
  debouncedUpdateEventCounts(queryClient, eventId)
}

export const updateSegment = (queryClient: QueryClient, eventId: string, segment: Segment) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    segments: oldData.segments.map(
      (s) => (s.id.toString() === segment.id.toString() ? segment : s),
    ),
  }))
  debouncedUpdateEventCounts(queryClient, eventId)
}

export const removeSegment = (queryClient: QueryClient, eventId: string, segmentId: string) => {
  if (!eventCached(queryClient, eventId)) { return }

  queryClient.setQueryData(eventCacheKey(eventId), (oldData: any) => ({
    ...oldData,
    segments: oldData.segments.filter((s) => s.id.toString() !== segmentId.toString()),
  }))
}

export const removeAccountUser = (queryClient: QueryClient, accountUser) => {
  if (!accountUsersCached(queryClient)) { return }

  queryClient.setQueryData(
    accountUsersCacheKey(currentAccountId()),
    (accountUsers: any) => accountUsers.filter((m) => m.id !== accountUser.id),
  )
}

export const updateAccountUser = (queryClient: QueryClient, accountUser) => {
  if (!accountUsersCached(queryClient)) { return }

  queryClient.setQueryData(
    accountUsersCacheKey(currentAccountId()),
    (accountUsers: any) => accountUsers.map((m) => (m.id === accountUser.id ? accountUser : m)),
  )
}

export const addAccountUser = (queryClient: QueryClient, accountUser) => {
  if (!accountUsersCached(queryClient)) { return }

  queryClient.setQueryData(
    accountUsersCacheKey(currentAccountId()),
    (accountUsers: any) => ([...accountUsers, accountUser]),
  )
}

export const refetchForms = (
  queryClient: QueryClient,
  eventId: string,
) => {
  debouncedUpdateEventCounts(queryClient, eventId)

  return queryClient.invalidateQueries(formsCacheKey(eventId))
}

export const updateForm = (
  queryClient: QueryClient, formId: string, form: Form,
) => {
  if (!formCached(queryClient, formId)) { return }

  queryClient.setQueryData(formCacheKey(formId), form)
}

export const refetchEventQuestions = (
  queryClient: QueryClient,
  eventId: string,
) => {
  updateEventCounts(queryClient, eventId)

  return queryClient.invalidateQueries(eventQuestionsCacheKey(eventId))
}

export const updateEventQuestion = (
  queryClient: QueryClient,
  id: string,
  eventQuestion: FormQuestion,
) => {
  if (!eventQuestionCached(queryClient, id)) { return }

  queryClient.setQueryData(
    eventQuestionCacheKey(id),
    () => (eventQuestion),
  )
}

export const updateFormTranslations = (
  queryClient: QueryClient,
  form: Form,
  translations: Translations,
) => {
  const { externalId } = form

  if (!formCached(queryClient, externalId)) { return }

  queryClient.setQueryData(
    formCacheKey(externalId),
    () => ({ ...form, translations }),
  )
}

export const refetchTextMessages = (
  queryClient: QueryClient,
  eventId: string,
) => {
  debouncedUpdateEventCounts(queryClient, eventId)

  return queryClient.invalidateQueries(textMessagesCacheKey(eventId))
}

export const updateTextMessages = (
  queryClient: QueryClient, textMessageId: string, text: TextMessage,
) => {
  if (!textMessageCached(queryClient, textMessageId)) { return }

  queryClient.setQueryData(textMessageCacheKey(textMessageId), text)
}
