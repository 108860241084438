import React, { useState } from "react"
import { uniq } from "lodash"
import { Account as DioboxAccount, SubscriptionStatuses, User } from "sharedTypes"
import { supportLogin } from "services/authentication"
import NoAccount from "pages/Account/NoAccount"
import PastDueSubscription from "pages/Account/PastDueSubscription"
import ExpiredSubscription from "pages/Account/ExpiredSubscription"
import AccountDisabled from "pages/Account/AccountDisabled"

type Props = {
  currentAccount: DioboxAccount,
  currentUser: User,
  setClosedPastDueNotificationAccountIDs: (ids: (prevIDs) => any) => void
  closedPastDueNotificationAccountIDs: string[]
}

const AccountErrors = ({
  currentAccount,
  currentUser,
  setClosedPastDueNotificationAccountIDs,
  closedPastDueNotificationAccountIDs,
}: Props) => {
  const [
    expiredSubscriptionClosed,
    setExpiredSubscriptionClosed,
  ] = useState<boolean>(false)

  const handleClosePastDueNotification = () => {
    setClosedPastDueNotificationAccountIDs((prevIDs) => uniq([...prevIDs, currentAccount.id]))
  }

  const handleCloseExpiredSubscription = supportLogin()
    ? () => {
      setExpiredSubscriptionClosed(true)
    }
    : undefined

  if (!currentAccount) {
    return <NoAccount currentUser={currentUser} />
  }

  if (
    currentAccount?.subscription?.status === SubscriptionStatuses.PastDue
    && !closedPastDueNotificationAccountIDs.includes(currentAccount.id)
  ) {
    return (
      <PastDueSubscription
        currentAccount={currentAccount}
        onClose={handleClosePastDueNotification}
      />
    )
  }

  if (currentAccount?.subscription?.expired && !expiredSubscriptionClosed) {
    return (
      <ExpiredSubscription
        currentAccount={currentAccount}
        onClickSupportLogin={handleCloseExpiredSubscription}
      />
    )
  }

  if (currentAccount?.disabled) {
    return <AccountDisabled currentAccount={currentAccount} />
  }

  return null
}

export default AccountErrors
