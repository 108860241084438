import { Factory } from "fishery"
import { EMAIL_FONTS, EMAIL_DIVIDER_STYLES } from "utilities/enums"

export const HeadingBlockFactory = Factory.define<{}>(() => ({
  text: "Heading Placeholder",
  blockColor: "#FFFFFF",
  textColor: "#000000",
  align: "center",
  font: EMAIL_FONTS[2],
  size: 1,
  paddingTop: 1,
  paddingBottom: 1,
}))

export const TextBlockFactory = Factory.define<{}>(() => ({
  text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  blockColor: "#FFFFFF",
  textColor: "#000000",
  font: EMAIL_FONTS[2],
  size: 16,
  lineHeight: 1.5,
  paddingTop: 1,
  paddingBottom: 1,
}))

export const ImageBlockFactory = Factory.define<{}>(() => ({
  source: "",
  alt: "",
  align: "center",
  paddingTop: 1,
  paddingBottom: 1,
  scale: 100,
  action: "",
  otherLink: "",
  blockColor: "#FFFFFF",
}))

export const QRCodeBlockFactory = Factory.define<{}>(() => ({
  paddingTop: 4,
  paddingBottom: 4,
  blockColor: "#FFFFFF",
}))

export const ButtonBlockFactory = Factory.define<{}>(() => ({
  text: "RSVP",
  action: "website",
  otherLink: "",
  buttonColor: "#4A90E2",
  blockColor: "#FFFFFF",
  textColor: "#FFFFFF",
  font: "Arial",
  paddingTop: 1,
  paddingBottom: 1,
}))

export const HtmlBlockFactory = Factory.define<{}>(() => ({
  html:
`<style>
  h1 { 
    font-size: 2em; 
    font-weight: bold;
  }
</style>
<div style='text-align: center;'>
  <h1>Header</h1>
  <p>Replace this with your own code</p>
</div>`,
  paddingTop: 1,
  paddingBottom: 1,
}))

export const DividerBlockFactory = Factory.define<{}>(() => ({
  style: EMAIL_DIVIDER_STYLES[0],
  size: 1,
  lineColor: "#CCCCCC",
  blockColor: "#FFFFFF",
  paddingTop: 1,
  paddingBottom: 1,
}))
