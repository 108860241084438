import React from "react"
import {
  FormControl, Checkbox as ChakraCheckbox, Box, FormLabel, TypographyProps, LayoutProps,
} from "@chakra-ui/react"
import { Control, Controller } from "react-hook-form"

type Props = {
  name: string;
  control: Control<any>;
  label?: React.ReactNode
  leftLabel?: string;
  leftLabelSize?: TypographyProps["fontSize"];
  submitOnChange?: boolean
  disabled?: boolean
  mb?: string|number
  onChange?: (e) => void
  vertical?: boolean
  w?: LayoutProps["width"]
  readonly?: boolean
}

const Checkbox = ({
  name,
  control,
  label,
  leftLabel,
  leftLabelSize,
  submitOnChange,
  disabled,
  mb = 4,
  onChange = () => {},
  vertical,
  w,
  readonly,
}: Props) => (
  <FormControl
    mb={mb}
    display={vertical ? "block" : "flex"}
    alignItems="center"
    isDisabled={disabled}
    w={w}
  >
    {leftLabel && (
    <FormLabel
      fontSize={leftLabelSize}
      fontWeight="bold"
      w={vertical ? "auto" : "25%"}
      mb={0}
    >{leftLabel}
    </FormLabel>
    )}
    <Box w="100%">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <ChakraCheckbox
            {...field}
            alignItems="flex-start"
            spacing={3}
            isDisabled={disabled}
            onChange={(event) => {
              onChange(event)
              field.onChange(event)
              if (submitOnChange) {
                event.target.form?.submit()
              }
            }}
            isChecked={field.value}
            isReadOnly={readonly}
          >
            {label && <Box mt="-4px">{label}</Box>}
          </ChakraCheckbox>
        )}
      />
    </Box>
  </FormControl>
)

export default Checkbox
