import React from "react"
import { Center, Image } from "@chakra-ui/react"
import Avatar from "./Avatar"

type Props = {
  logoUrl: string
  accountName: string
  onClick?: () => void
  showBorder?: boolean
}

const AccountLogo = ({
  logoUrl, accountName, onClick, showBorder,
}: Props) => (
  <Center
    w="40px"
    h="40px"
    borderRadius="base"
    bgColor="white"
    flexShrink={0}
    borderWidth={showBorder ? 1 : 0}
    {...onClick ? { cursor: "pointer", onClick } : {}}
  >
    {logoUrl && <Image src={`${logoUrl}?fit=crop&w=40&h=40`} />}
    {!logoUrl && <Avatar w="40px" h="40px" name={accountName} borderRadius="base" />}
  </Center>
)

export default AccountLogo
