import React, { useState } from "react"
import * as guestsApi from "api/guests"
import { SimpleGrid, Text } from "@chakra-ui/react"
import Option from "modals/ExportGuests/Option"
import { selectedFilter } from "pages/EventShow/Guests/ScrollView/Filters"
import {
  EventGroup, GuestFilterType, Segment, User,
} from "sharedTypes"
import { shallow } from "zustand/shallow"
import useGuestsStore from "services/useGuestsStore"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import { successToast } from "utilities/toasts"

enum ExportOption {
  GuestData,
  SelectedList,
  FormSubmissions,
  CheckIns,
}

type Props = {
  eventId: string
  eventGroups: EventGroup[]
  segments: Segment[]
  counts: {}
  onHide: () => void
  currentUser: User
}

const Export = ({
  eventId, eventGroups, segments, counts, onHide, currentUser,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState(ExportOption.GuestData)
  const [groupId, segmentId] = useGuestsStore((s) => [s.groupId, s.segmentId], shallow)

  const {
    label: selectedFilterLabel,
    type: selectedFilterType,
  } = selectedFilter(eventGroups, counts, segments, segmentId, groupId)

  const selectedListEnabled = selectedFilterType !== GuestFilterType.All

  const clickExportHandler = () => {
    switch (selectedOption) {
      case ExportOption.GuestData:
        return guestsApi.postExportGuests(eventId).then(() => {
          successToast({ title: "Guest data has been exported successfully and will be emailed to you shortly." })
          onHide()
        })
      case ExportOption.SelectedList:
        return guestsApi.postExportGuests(eventId, groupId, segmentId).then(() => {
          successToast({ title: "Selected List has been exported successfully. Please check your email." })
          onHide()
        })
      case ExportOption.FormSubmissions:
        return guestsApi.postExportFormSubmissions(eventId).then(() => {
          successToast({ title: "Form Submissions have been exported successfully. Please check your email." })
          onHide()
        })
      case ExportOption.CheckIns:
        return guestsApi.postExportCheckIns(eventId).then(() => {
          successToast({ title: "Check-Ins have been exported successfully. Please check your email." })
          onHide()
        })
      default:
        throw new Error()
    }
  }

  return (
    <ModalForm
      size="6xl"
      onClose={onHide}
      onSubmit={clickExportHandler}
      submitText="Export"
      title="Export Guests"
    >
      <>
        <SimpleGrid columns={4} spacing={5}>
          <Option
            icon="guests-stroke"
            title="Guest Data"
            descriptionItems={[
              "All Guest/Check-In Fields",
              "Confirmations and Seats",
              "Total Ticket Spend",
              "Email Bounces, Opens and Clicks",
            ]}
            onSelect={() => setSelectedOption(ExportOption.GuestData)}
            selected={selectedOption === ExportOption.GuestData}
          />
          <Option
            icon="segment"
            title="Selected List"
            descriptionItems={selectedListEnabled ? [
              selectedFilterType,
              `“${selectedFilterLabel}”`,
            ] : ["Select a guest list or a segment first to export it"]}
            onSelect={() => setSelectedOption(ExportOption.SelectedList)}
            selected={selectedOption === ExportOption.SelectedList}
            enabled={selectedListEnabled}
            centeredDescription
          />
          <Option
            icon="database-outline"
            title="Form Submissions"
            descriptionItems={[
              "All form submissions with primary guest info and corresponding receipt links.",
              "Ideal for generating ticket order reports with payment details.",
            ]}
            onSelect={() => setSelectedOption(ExportOption.FormSubmissions)}
            selected={selectedOption === ExportOption.FormSubmissions}
          />
          <Option
            icon="check-in"
            title="Activities"
            descriptionItems={[
              "Guest check-ins and their corresponding sessions",
              "Guest notes and team member info associated with adding the notes",
              "Primary guest profile fields",
            ]}
            onSelect={() => setSelectedOption(ExportOption.CheckIns)}
            selected={selectedOption === ExportOption.CheckIns}
          />
        </SimpleGrid>
        <Text mt={8} textAlign="center">
          Your CSV export file will be generated and emailed to:
          <Text as="span" display="block" fontSize="xl" fontWeight="bold">{currentUser.email}</Text>
        </Text>
      </>
    </ModalForm>
  )
}

export default Export
