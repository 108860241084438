import { Textarea, Box } from "@chakra-ui/react"
import CopyToClipboard from "components/elements/CopyToClipboard"
import React from "react"

type Props = {
  text: string
}

const TextareaWithCopyButton = ({ text }: Props) => (
  <Box>
    <Textarea readOnly>
      {text}
    </Textarea>
    <Box mt={2}>
      <CopyToClipboard text={text} size="xs" variant="outline">
        Copy
      </CopyToClipboard>
    </Box>
  </Box>
)

export default TextareaWithCopyButton
