import { getChargeConfiguration, getPlans, postPreviewInvoice } from "api/accounts"
import { useQuery } from "react-query"
import { ChargeConfiguration, Plan, PreviewInvoice } from "sharedTypes"

export const usePlans = () => {
  const { isLoading, data: plans = [] } = useQuery(
    "plans",
    () => getPlans().then(({ data }) => data as Plan[]),
    { staleTime: Infinity },
  )

  return { isLoading, plans }
}

export const usePreviewInvoice = (priceId, subscription) => {
  const { isFetching, data: previewInvoice = {} as PreviewInvoice } = useQuery(
    priceId,
    () => postPreviewInvoice(priceId).then(({ data }) => data),
    { enabled: !!priceId && !!subscription, cacheTime: 0, refetchOnWindowFocus: false },
  )

  return { isFetching, previewInvoice }
}

export const useChargeConfiguration = (id) => {
  const { isFetching, data: chargeConfiguration = {} as ChargeConfiguration } = useQuery(
    id,
    () => getChargeConfiguration(id).then(({ data }) => data),
    { refetchOnWindowFocus: false },
  )

  return { isFetching, chargeConfiguration }
}
