import Button from "components/Buttons/Button"
import React from "react"
import { AccountUser, ModalName } from "sharedTypes"
import {
  Box,
  Flex,
  GridItem,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react"
import Link from "components/elements/Link"
import useCurrentAccount from "services/useCurrentAccount"
import { useCurrentEvent } from "queries/events"
import useModal from "services/useModal"
import { usePlans } from "queries/plans"
import { isTerminalPlan } from "utilities/objects"
import {
  eventEmailsPath,
  eventSeatingPath,
  eventSettingsPath,
  eventWebsitePath, iOSAppURL,
} from "utilities/routes"
import download from "assets/images/download-from-app-store.png"
import usePermissions, { EventUserRole } from "services/permissions"
import Spinner from "components/elements/Spinner"
import useWebsiteUrl from "utilities/useWebsiteUrl"
import useFeatures from "services/useFeatures"
import useFeatureFlag from "services/featureFlags"
import { CheckList, CheckListItem, CheckListStatus } from "./components/CheckList"
import StatsSection from "./components/StatsSection"
import Section from "./components/Section"
import AboutSection from "./components/AboutSection"

type Props = {
  accountUsers: AccountUser[];
}

const Dashboard = ({ accountUsers }: Props) => {
  const currentAccount = useCurrentAccount()
  const { data: currentEvent, isLoading: currentEventIsLoading } = useCurrentEvent()
  const showModal = useModal()
  const { plans, isLoading: plansAreLoading } = usePlans()
  const {
    canViewEmails,
    canViewGuests,
    canViewSeating,
    canViewSettings,
    canViewWebsite,
    canViewForm,
  } = usePermissions()
  const websiteUrl = useWebsiteUrl(currentEvent.website)
  const {
    guestCheckIns,
    emailsAndInvitations,
    qrCodeDistribution,
    eventSessionsAndActivities,
    customFilters,
    registrationForms,
    ticketingAndPayments,
    rsvpForms,
    waitlistForms,
    customEmailDomains,
    tablesAndSeating,
    websiteAndAgenda,
    badgePrinting,
    whiteLabel,
  } = useFeatures()
  const { texting: textingEnabled } = useFeatureFlag()

  if (currentEventIsLoading || plansAreLoading) {
    return <Spinner />
  }

  if (!currentEvent.id) {
    return null
  }

  const {
    id: eventId,
    title,
    startDate,
    nonTicketedGuestLimit,
    maxTeamSize,
    currentUserRole,
    currency,
    stripeAccountId,
    counts: {
      guests,
      confirmedGuests,
      declinedGuests,
      unconfirmedGuests,
      arrivedGuests,
      waitlistedGuests,
      emails,
      dailyEmails,
      dailyTexts,
      openedEmails,
      clickedEmails,
      hardBouncedEmails,
      softBouncedEmails,
      bouncedEmails,
      tables,
      seats,
      waitlistedSeats,
      unassignedSeats,
      assignedSeats,
      grossSales,
      netSales,
      formSubmissionLineItems: {
        registration = 0,
        rsvp = 0,
        ticket = 0,
        waitlist = 0,
      } = {},
    },
    website: {
      coverImageUrl,
      urlPath,
      published,
      viewsCount,
    },
  } = currentEvent

  const { dailyEmailLimit, dailyTextLimit, subscription } = currentAccount

  const showQRCheckin = currentUserRole === EventUserRole.DoorStaff

  const teamSize = accountUsers
    ?.filter(({ admin, events }) => admin || events.find(({ event: { id } }) => id === eventId))
    .length

  return (
    <Box>
      <Box minH="100vh" bg="gray.100">
        <Stack p={12} w="full" maxW={1050} spacing={12} m="auto">
          <AboutSection
            title={title}
            coverImageUrl={coverImageUrl}
            canViewWebsite={canViewWebsite}
            startDate={startDate}
            urlPath={urlPath}
            websiteUrl={websiteUrl}
            viewsCount={viewsCount}
          />
          {(showQRCheckin) && (
          <Section title="QR Check-In" icon="email-block-qr-code">
            <Flex mt={6} justify="space-between" fontWeight="semibold">
              <Box fontSize="lg">
                <Text>Download the Diobox iOS app to scan guest QR codes.</Text>
                <Text>
                  To learn more about the check-in features, visit: <Link isExternal href={iOSAppURL}>https://home.d.io/check-in-app/</Link>
                </Text>
              </Box>
              <Box>
                <Link href={iOSAppURL} isExternal>
                  <Image src={download} w="130px" display="inline-block" />
                </Link>
              </Box>
            </Flex>
          </Section>
          )}
          {canViewSettings && (
          <Section title="Event Usage">
            {!isTerminalPlan(plans, subscription) && (
            <Box position="absolute" right={0} top={0} p={6}>
              <Button
                size="sm"
                onClick={() => showModal(ModalName.Upgrade)}
              >
                Upgrade
              </Button>
            </Box>
            )}
            <SimpleGrid columns={3} spacing={24} mt={4}>
              <CheckList>
                <CheckListItem text="Guest Check-Ins" checked={guestCheckIns} />
                <CheckListItem text="Email &amp; Invitations" checked={emailsAndInvitations} />
                <CheckListItem text="QR Code Distribution" checked={qrCodeDistribution} />
                <CheckListItem text="Event Session &amp; Activities" checked={eventSessionsAndActivities} />
                <CheckListItem text="Custom Filters" checked={customFilters} />
                {nonTicketedGuestLimit && (
                <CheckListStatus
                  text="Guest Limit"
                  status={guests}
                  total={nonTicketedGuestLimit}
                />
                )}
              </CheckList>
              <CheckList>
                <CheckListItem text="Registration Forms" checked={registrationForms} />
                <CheckListItem text="Ticketing & Payments" checked={ticketingAndPayments} />
                <CheckListItem text="RSVP Forms" checked={rsvpForms} />
                <CheckListItem text="Waitlist Forms" checked={waitlistForms} />
                <CheckListItem text="Custom Email Domains" checked={customEmailDomains} />
                <CheckListStatus
                  text="Daily Email Limit"
                  status={dailyEmails}
                  total={dailyEmailLimit}
                />
              </CheckList>
              <CheckList>
                <CheckListItem text="Tables & Seating" checked={tablesAndSeating} />
                <CheckListItem text="Website & Agenda" checked={websiteAndAgenda} />
                <CheckListItem text="Badge Printing" checked={badgePrinting} />
                <CheckListItem text="White-Label" checked={whiteLabel} />
                {teamSize && <CheckListStatus text="Team Size" status={teamSize} total={maxTeamSize} showStatusBar={false} />}
                {textingEnabled && (
                  <CheckListStatus
                    text="Daily SMS Limit"
                    status={dailyTexts}
                    total={dailyTextLimit}
                  />
                )}
              </CheckList>
            </SimpleGrid>
          </Section>
          )}
          <SimpleGrid columns={2} spacing={12}>
            {canViewGuests && (
            <GridItem>
              <StatsSection
                icon="guests"
                title="Guests"
                showChecklist={canViewSettings}
                statistics={[
                  { title: "All Guests", number: guests },
                  {
                    title: "Confirmed",
                    number: confirmedGuests,
                    ratio: confirmedGuests / guests,
                    color: "blue.400",
                  },
                  {
                    title: "Waitlisted",
                    number: waitlistedGuests,
                    ratio: waitlistedGuests / guests,
                    color: "yellow.300",
                  },
                  {
                    title: "Declined",
                    number: declinedGuests,
                    ratio: declinedGuests / guests,
                    color: "red.400",
                  },
                  {
                    title: "Unconfirmed",
                    number: unconfirmedGuests,
                    ratio: unconfirmedGuests / guests,
                    color: "gray.600",
                  },
                  {
                    title: "Checked In",
                    number: arrivedGuests,
                    ratio: arrivedGuests / guests,
                    color: "green.500",
                  },
                  {
                    title: "Not Checked In",
                    number: guests - arrivedGuests,
                    ratio: (guests - arrivedGuests) / guests,
                    color: "orange.300",
                  },
                ]}
                steps={[
                  {
                    description: "Add Guest",
                    onClick: () => showModal(ModalName.NewGuest),
                    done: !!guests,
                  },
                  { description: "Import Guests", onClick: () => showModal(ModalName.ImportGuests) },
                ]}
                externalLinks={[
                  {
                    url: "https://help.d.io/en/articles/3225045-importing-guests-from-a-csv-file",
                    children: "How to Import Guests",
                  },
                ]}
              />
            </GridItem>
            )}
            {canViewEmails && (
            <GridItem>
              <StatsSection
                icon="emails"
                title="Emails"
                showChecklist={canViewSettings}
                statistics={[
                  { title: "Total Emails Sent", number: emails },
                  {
                    title: "Opened",
                    number: openedEmails,
                    ratio: openedEmails / emails,
                    color: "green.200",
                  },
                  {
                    title: "Clicked",
                    number: clickedEmails,
                    ratio: clickedEmails / emails,
                    color: "green.400",
                  },
                  {
                    title: "Unopened",
                    number: emails - openedEmails,
                    ratio: (emails - openedEmails) / emails,
                    color: "gray.400",
                  },
                  {
                    title: "Soft Bounced",
                    number: softBouncedEmails,
                    ratio: softBouncedEmails / emails,
                    color: "emailStatus.softBounced",
                  },
                  {
                    title: "Hard Bounced",
                    number: hardBouncedEmails,
                    ratio: hardBouncedEmails / emails,
                    color: "emailStatus.hardBounced",
                  },
                  ...bouncedEmails ? [{
                    title: "Bounced",
                    number: bouncedEmails,
                    ratio: bouncedEmails / emails,
                    color: "emailStatus.bounced",
                  }] : [],
                ]}
                steps={[
                  {
                    description: "Create Email",
                    onClick: () => showModal(ModalName.NewEmail),
                    done: !!emails,
                  },
                  { description: "Send Email", path: eventEmailsPath(eventId), done: !!emails },
                ]}
                externalLinks={[]}
              />
            </GridItem>
            )}
            {canViewForm && (
            <GridItem>
              <StatsSection
                icon="forms"
                title="Forms"
                showChecklist={canViewSettings}
                statistics={[
                  ...[{ title: "Registrations", number: registration, color: "orange.500" },
                    { title: "RSVPs", number: rsvp, color: "orange.500" },
                    { title: "Waitlists", number: waitlist, color: "orange.500" },
                    { title: "Tickets Sold", number: ticket, color: "orange.500" }],
                  ...(ticket > 0) ? [{
                    title: "Net Sales", number: netSales, color: "green.500", currency, subBlock: true,
                  },
                  {
                    title: "Gross Sales", number: grossSales, color: "green.500", currency, subBlock: true,
                  }] : [],
                ]}
                steps={[
                  {
                    description: "Select Vanity URL",
                    onClick: () => showModal(ModalName.WebPageURL),
                    done: !!urlPath,
                  },
                  {
                    description: "Publish Website",
                    path: eventWebsitePath(eventId),
                    done: published,
                  },
                  {
                    description: "Connect Payment Gateway",
                    path: eventSettingsPath(eventId, "ticketing-payments"),
                    done: !!stripeAccountId,
                  },
                ]}
                externalLinks={[
                  {
                    url: "https://help.d.io/en/articles/8405358-overview-of-diobox-forms",
                    children: "Learn about Diobox Forms",
                  },
                ]}
              />
            </GridItem>
            )}
            {canViewSeating && (
            <GridItem>
              <StatsSection
                icon="seating"
                title="Seating"
                showChecklist={canViewSettings}
                statistics={[
                  { title: "Tables", number: tables },
                  { title: "All Seats", number: seats },
                  { title: "Vacant Seats", number: unassignedSeats, color: "blue.500" },
                  { title: "Waitlisted Guests", number: waitlistedSeats, color: "yellow.300" },
                ]}
                steps={[
                  {
                    description: "Add Table",
                    onClick: () => showModal(ModalName.TableForm),
                    done: !!tables,
                  },
                  {
                    description: "Assign Guest to a Seat",
                    path: eventSeatingPath(eventId),
                    done: !!assignedSeats,
                  },
                ]}
                externalLinks={[]}
              />
            </GridItem>
            )}
          </SimpleGrid>
        </Stack>
      </Box>
    </Box>
  )
}

export default Dashboard
