import React from "react"
import { Flex } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { Link } from "react-router-dom"

type Props = {
  to: string
  children: string
}

const BackLink = ({ to, children }: Props) => (
  <Link to={to}>
    <Flex align="center" color="gray.400" fontWeight="semibold"><Icon icon="prev-arrow" mr={2} /> {children}</Flex>
  </Link>
)

export default BackLink
