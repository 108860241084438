import React from "react"
import {
  Box, Flex, ListItem, Text,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { CheckInLocation } from "sharedTypes"

type Props = {
  handleClickLocation: (locationId: number|null) => void
  id: number|null
  checkInLocations: CheckInLocation[]
  name: string
  checkInLocationId: number|null
}

const ActivityListItem = ({
  handleClickLocation, id, checkInLocations, name, checkInLocationId,
}: Props) => {
  const selected = id === checkInLocationId

  return (
    <ListItem
      as={Flex}
      align="center"
      justify="space-between"
      role="button"
      onClick={() => handleClickLocation(id)}
      px={4}
      py={2}
      cursor="pointer"
      _first={checkInLocations.length ? { borderBottomWidth: 1, pb: 4, mb: 2 } : {}}
    >
      <Flex align="center" fontSize="md">
        <Box w={8} flexShrink={0}>{selected && <Icon icon="checkmark" size={5} color="blue.300" alt="Selected" />}</Box>
        <Text fontWeight={selected ? "semibold" : "normal"}>{name}</Text>
      </Flex>
    </ListItem>
  )
}

export default ActivityListItem
