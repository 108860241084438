import {
  CardElement, useElements, useStripe,
} from "@stripe/react-stripe-js"
import React from "react"
import { FormControl } from "@chakra-ui/react"
import { errorToast } from "utilities/toasts"
import { Control, FieldValues, useController } from "react-hook-form"

type Props<T extends FieldValues> = {
  name: string;
  control: Control<T, object>
}

const CreditCardField = <T extends FieldValues, >({ name, control }: Props<T>) => {
  const stripe = useStripe()
  const elements = useElements()
  const { field } = useController<any>({ name, control })

  const createToken = async () => {
    if (!stripe || !elements) {
      return
    }

    const cardElement = elements.getElement(CardElement)
    if (!cardElement) {
      return
    }

    const { token, error } = await stripe.createToken(cardElement)

    if (error) {
      errorToast({ title: error.message })
    } else {
      field.onChange(token?.id)
    }
  }

  const handleChange = ({ complete }) => {
    if (complete) {
      createToken()
    } else {
      field.onChange(null)
    }
  }

  const CARD_OPTIONS = {
    style: {
      base: {
        fontSize: "14px",
        fontWeight: "500",
      },
    },
  }

  return (
    <FormControl>
      <CardElement
        options={CARD_OPTIONS}
        onChange={(event) => handleChange(event)}
      />
    </FormControl>
  )
}

export default CreditCardField
