import React from "react"
import { createBillingPortalSession } from "api/accounts"
import { Subscription, Plan as DioboxPlan, SubscriptionStatuses } from "sharedTypes"
import {
  Box, Text, Heading, UnorderedList, ListItem,
} from "@chakra-ui/react"
import { asDateWithTZ, asMoney } from "utilities/strings"
import ChangeBillingInterval from "pages/Account/Plan/ChangeBillingInterval"
import SubscriptionDowngrader from "pages/Account/Plan/Downgrader"
import UpgradeEnticer from "pages/Account/Plan/UpgradeEnticer"
import { usePlans } from "queries/plans"
import Spinner from "components/elements/Spinner"
import Link from "components/elements/Link"
import { useIntercom } from "react-use-intercom"
import PastDueAlert from "components/elements/PastDueAlert"

type Props = {
  subscription: Subscription | null
  showBilling: boolean
  onClickResolve: () => void
}

const Plan = ({ subscription, showBilling, onClickResolve }: Props) => {
  const { show: showIntercom } = useIntercom()
  const accountIsPastDue = subscription?.status === SubscriptionStatuses.PastDue

  const { isLoading, plans } = usePlans()
  if (isLoading) { return <Spinner /> }

  const currentPlan = (): DioboxPlan => {
    const index = Math.max(plans.findIndex((plan) => plan.type === subscription?.type || (plan.type === "Basic" && subscription?.type === "Standard")), 0)

    return plans[index]
  }

  const {
    type, licenses, amount, interval, cancelAtPeriodEnd, periodEndsAt, legacy,
  } = Object(subscription)

  const planInfo = () => {
    if (subscription) {
      return (
        <Box mb={4}>
          <Text mb={4} fontSize="2xl">
            {type}
            {" "}
            Subscription Plan
            { legacy && " (Legacy)" }
          </Text>
          <Text>
            Team Size:{" "}
            {licenses}
          </Text>
          <Text>
            {asMoney(amount / 100)}
            {interval ? `/${interval}` : " custom subscription"}
          </Text>
          {interval === "year" && <Text>Includes {legacy ? "10" : "40"}% discount</Text>}
          {!subscription.stripeId && (
          <Box mt={8} mb={12}>
            <Text mb={3}>Expires: {asDateWithTZ(subscription.periodEndsAt, "UTC", "PPP")}</Text>
            <Text>
              To extend your subscription, <Link onClick={showIntercom}>send us a message</Link>.
            </Text>
          </Box>
          )}
        </Box>
      )
    }

    return <Text fontSize="2xl">Free Plan</Text>
  }

  const openBillingPortal = () => createBillingPortalSession()
    .then((response) => {
      window.open(response.data.url, "_blank")
    })

  return (
    <Box px={32} py={8}>
      <Heading fontSize="4xl" mb={8} fontWeight="semibold">Plan</Heading>
      <Box>
        {planInfo()}
        {accountIsPastDue && <PastDueAlert onClickResolve={onClickResolve} />}
        {!accountIsPastDue && subscription?.stripeId && !cancelAtPeriodEnd && (
        <SubscriptionDowngrader subscription={subscription} />
        )}
        {subscription?.stripeId
        && subscription?.interval === "year"
        && !subscription?.legacy
        && !cancelAtPeriodEnd && <ChangeBillingInterval currentPlan={currentPlan()} toInterval="month" />}
        {subscription?.cancelAtPeriodEnd && (
        <Box mb={4}>
          <Text mb={1} color="red.600" fontWeight="bold">YOUR SUBSCRIPTION IS PENDING CANCELATION.</Text>
          <Text mb={1} color="red.600" fontWeight="bold">
            You will lose your account and your data on {asDateWithTZ(periodEndsAt, "UTC", "PPP")}. To keep your subscription, <Link onClick={showIntercom}>send us a message</Link>.
          </Text>
        </Box>
        )}
      </Box>
      {(!subscription || subscription?.stripeId) && !subscription?.cancelAtPeriodEnd
      && (
        <Box py={8}>
          <UpgradeEnticer subscription={subscription} currentPlan={currentPlan()} />
        </Box>
      )}
      {showBilling && (
      <>
        <Heading fontSize="4xl" mb={8} fontWeight="semibold">Billing</Heading>
        <Text mb={3} fontWeight="semibold">Visit the <Link onClick={openBillingPortal}>Stripe Billing Portal</Link> to:</Text>
        <UnorderedList>
          <ListItem>Change your credit card number</ListItem>
          <ListItem>View your past invoices</ListItem>
        </UnorderedList>
      </>
      )}
    </Box>
  )
}

export default Plan
