import React from "react"
import { ActivityItem, DioboxEvent, Guest } from "sharedTypes"
import _ from "lodash"
import { Timeline, TimelineItem } from "components/layout/Timeline"
import { useParams } from "react-router"
import Item from "./Item"

type Props = {
  guest: Guest;
  event: DioboxEvent;
}

const ActivityList = ({ guest, event }: Props) => {
  const { eventId } = useParams<{eventId: string}>()
  const { timezone } = event
  const {
    personInfo: {
      transactions,
      formSubmissionLineItems,
    },
    id,
  } = guest

  const showTime = (type) => !["AccommodationTransaction", "NoteTransaction", "PackageTransaction"].includes(type)

  const items = [
    ...transactions
      .map((transaction) => (
        transaction.guestId === guest.id
          ? {
            ...transaction,
            date: transaction.transactionDate,
          }
          : null)),
    ...formSubmissionLineItems
      .filter((
        { formSubmission: { eventId: formSubmissionEventId } },
      ) => formSubmissionEventId === eventId)
      .map((formSubmissionLineItem) => (
        {
          ...formSubmissionLineItem,
          type: "FormSubmission",
          guestId: id,
          date: formSubmissionLineItem.createdAt,
          id: formSubmissionLineItem.externalId,
        }
      )),
  ].filter(Boolean) as ActivityItem[]

  if (!items.length) {
    return null
  }

  return (
    <Timeline>
      {
        _.reverse(_.sortBy(items, "date")).map((item, index) => (
          <TimelineItem
            key={`${item.type}-${item.id}`}
            date={item.date}
            timezone={timezone}
            showTime={showTime(item.type)}
            showConnector={index !== items.length - 1}
          >
            <Item item={item} event={event} />
          </TimelineItem>
        ))
      }
    </Timeline>
  )
}

export default ActivityList
