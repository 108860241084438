import React from "react"
import {
  Box, Flex, FormLabel, LayoutProps, Text, TypographyProps,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import TextField from "components/ReactHookForm/TextField"
import { Control, useController } from "react-hook-form"

type Props = {
  defaultTranslation: string
  name: string
  control: Control
  label?: string
  labelWeight?: TypographyProps["fontWeight"]
  labelWidth?: LayoutProps["width"]
  disabled?: boolean
}

const LabelRedefinition = ({
  defaultTranslation, name, label, control, labelWeight = "bold", labelWidth = "25%", disabled,
}: Props) => {
  const { field } = useController({ name, control })

  return (
    <Flex direction="column">
      {label && <FormLabel fontWeight={labelWeight} w={labelWidth}>{label}</FormLabel>}
      <Flex>
        <Box
          border="1px"
          borderColor="gray.300"
          borderRadius={4}
          w="50%"
          h={10}
          px={4}
          py={1.5}
          whiteSpace="nowrap"
          overflow="hidden"
        ><Text color="gray.600">{defaultTranslation}</Text>
        </Box>
        <Icon onClick={() => field.onChange(defaultTranslation)} icon="arrow-right" role="button" size={4} w="10%" mt={3} />
        <Box w="50%">
          <TextField
            name={name}
            control={control}
            variant="outline"
            disabled={disabled}
          />
        </Box>
      </Flex>
    </Flex>
  )
}

export default LabelRedefinition
