import React from "react"
import {
  DioboxEvent, ModalName, AccountUser, User,
} from "sharedTypes"
import {
  Box, Flex, Heading, Spacer, Text,
} from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import { titleize } from "utilities/strings"
import UpgradeAlert from "components/elements/UpgradeAlert"
import RoundedList from "pages/EventShow/Settings/RoundedList"
import useModal from "services/useModal"
import TeamMemberWithAvatar from "pages/Account/TeamMembers/components/TeamMemberWithAvatar"
import { EventUserRole } from "services/permissions"
import Link from "components/elements/Link"
import { accountTeamPath } from "utilities/routes"
import SectionHeader from "./SectionHeader"

interface Props {
  currentEvent: DioboxEvent
  maxTeamSize: number
  currentUser: User
  accountUsers: AccountUser[]
}

const TeamMembers = ({
  currentEvent, maxTeamSize, currentUser, accountUsers,
}: Props) => {
  const showModal = useModal()

  const { accountAdmins, limitedAccess, noAccess } = accountUsers
    .sort((tm) => (tm.user?.id === currentUser.id ? -1 : 1))
    .reduce(
      (sorted, tm) => {
        const currentEventRole = tm.events.find(
          ({ event }) => event.id === currentEvent.id,
        )?.role

        if (tm.admin || currentEventRole === EventUserRole.EventAdmin) {
          return { ...sorted, accountAdmins: [...sorted.accountAdmins, tm] }
        }
        if (currentEventRole) {
          return { ...sorted, limitedAccess: [...sorted.limitedAccess, tm] }
        }

        return { ...sorted, noAccess: [...sorted.noAccess, tm] }
      }, {
        accountAdmins: [] as AccountUser[],
        limitedAccess: [] as AccountUser[],
        noAccess: [] as AccountUser[],
      },
    )

  const canInvite = maxTeamSize - accountUsers.length > 0

  return (
    <Box>
      <SectionHeader
        actionButtons={
          [
            canInvite && (
            <Button
              leftIcon="plus"
              onClick={() => showModal(ModalName.AccountUserForm, { currentEvent })}
            >Invite Member
            </Button>
            )]
        }
      >Event Team
      </SectionHeader>
      <Spacer my={1} />
      <TeamMembersTable
        title="Admins"
        currentUser={currentUser}
        accountUsers={accountAdmins}
        showModal={showModal}
        currentEvent={currentEvent}
      />
      <TeamMembersTable
        title="Limited Access"
        currentUser={currentUser}
        accountUsers={limitedAccess}
        showModal={showModal}
        currentEvent={currentEvent}
      />
      <TeamMembersTable
        title="No Access"
        currentUser={currentUser}
        accountUsers={noAccess}
        showModal={showModal}
        currentEvent={currentEvent}
      />
      {accountUsers.length >= maxTeamSize
      && (
      <Box my={10}>
        <UpgradeAlert text="All team member licenses have been used." />
      </Box>
      )}
      <Text>
        To delete team members or change their account access,
        see <Link to={accountTeamPath()}>Account Settings</Link>.
      </Text>
    </Box>
  )
}

type TeamMembersTableProps = {
  title: string
  currentUser: User
  accountUsers: AccountUser[]
  showModal: (
    modalName: ModalName,
    params: { accountUser: AccountUser, currentEvent: DioboxEvent }
  ) => void
  currentEvent: DioboxEvent
}

const TeamMembersTable = ({
  title, currentUser, accountUsers, showModal, currentEvent,
}: TeamMembersTableProps) => {
  if (!accountUsers.length) {
    return null
  }

  return (
    <Box mt={12} mb={6}>
      <Heading mb={6} fontSize="md">{title}</Heading>
      <RoundedList>
        {accountUsers.map((accountUser) => {
          const {
            id, user, admin, owner,
          } = accountUser

          const role = accountUser.events?.find(({ event }) => event.id === currentEvent.id)?.role || ""
          const isYou = user?.id === currentUser.id

          return (
            <Flex justify="space-between" align="center" px={6} py={5} key={id}>
              <TeamMemberWithAvatar teamMember={accountUser} isCurrent={isYou} />
              <Text w={1 / 5}>{access(admin, owner, role)}</Text>
              <Box w={20} flexShrink={0} textAlign="right">
                {!isYou && !owner && (
                <Button
                  variant="outline"
                  size="sm"
                  onClick={
                    () => showModal(
                      ModalName.AccountUserForm,
                      { accountUser, currentEvent },
                    )
                  }
                >Edit
                </Button>
                )}
              </Box>
            </Flex>
          )
        })}
      </RoundedList>
    </Box>
  )
}

const access = (admin, owner, role) => {
  if (owner) {
    return "Account Owner"
  }

  if (admin) {
    return "Account Admin"
  }

  return titleize(role)
}

export default TeamMembers
