import React, { ReactNode } from "react"
import { addGuestTransaction, removeGuestTransaction, updateGuestTransaction } from "context/actions"
import { useHistory, useParams } from "react-router-dom"
import * as api from "api/guests"
import { Transaction } from "sharedTypes"
import { asDate } from "utilities/strings"
import { Box, Text } from "@chakra-ui/react"
import { useCurrentEvent } from "queries/events"
import { useQueryClient } from "react-query"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import { Control } from "react-hook-form"
import { useEventGuestPaths } from "utilities/routes"

type Props = {
  title: ReactNode;
  fields: (control: Control<any>) => ReactNode;
  initialValues: object;
  apiPayload: (values) => object;
  activity?: Transaction;
}

const ActivityForm = ({
  title, fields, initialValues, apiPayload, activity,
}: Props) => {
  const { guestId } = useParams<{ guestId: string }>()
  const history = useHistory()
  const { data: currentEvent } = useCurrentEvent()
  const queryClient = useQueryClient()
  const { eventGuestPath } = useEventGuestPaths()

  const handleClose = () => {
    history.push(eventGuestPath(currentEvent.id, guestId))
  }

  const handleDelete = () => {
    if (!activity) {
      return
    }

    api.deleteTransaction(currentEvent.id, guestId, activity.id).then(({ data }) => {
      handleClose()
      removeGuestTransaction(queryClient, data)
    })
  }

  let lastModified: ReactNode | null = null
  if (activity) {
    const owner = currentEvent.users.find((eventUser) => eventUser.user?.id === activity?.userId)
    lastModified = (
      <Box color="gray.500">
        Activity last modified
        {` ${asDate(activity.updatedAt)} `}
        by <Text as="strong" fontWeight="semibold" color="gray.700">{owner?.user.firstName} {owner?.user.lastName}</Text>
      </Box>
    )
  }

  const onSubmit = (values) => {
    if (activity) {
      return api
        .putTransaction(currentEvent.id, guestId, activity.id, apiPayload(values))
        .then(({ data }) => {
          updateGuestTransaction(queryClient, data)
          handleClose()
        })
    }

    return api
      .postTransaction(currentEvent.id, guestId, apiPayload(values))
      .then(({ data }) => {
        addGuestTransaction(queryClient, data)
        handleClose()
      })
  }

  return (
    <ModalForm
      title={title}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onDanger={activity ? handleDelete : null}
      dangerProps={{
        type: activity?.displayType,
      }}
      onClose={handleClose}
      size="sm"
    >
      {({ control }) => (
        <>
          {fields(control)}
          {lastModified}
        </>
      )}
    </ModalForm>
  )
}

export default ActivityForm
