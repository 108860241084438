import React from "react"
import {
  Input, Text, Stack, Flex,
} from "@chakra-ui/react"

type Props = {
  label: string
  placeholder?: string
  required?: boolean
}

const InputWithLabel = ({ label, placeholder, required }: Props) => (
  <Stack>
    <Flex gap={1}>
      <Text fontSize="sm" fontWeight="semibold" color="gray.700">{label}</Text>
      {required && <Text color="red" fontSize="sm">*</Text>}
    </Flex>
    <Input placeholder={placeholder} />
  </Stack>
)

export default InputWithLabel
