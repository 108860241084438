import React from "react"
import Icon from "components/elements/Icon"
import {
  DioboxEvent, FormSubmissionLineItem, FormType, Guest,
} from "sharedTypes"
import _ from "lodash"
import pluralize from "pluralize"
import Popover from "components/dialogs/Popover"
import { asMoney } from "utilities/strings"
import { Box, Flex, Text } from "@chakra-ui/react"
import DropdownToggle from "../DropdownToggle"

type Props = {
  guest: Guest
  currency: string
  disabled: boolean
  event: DioboxEvent
}

const TicketSpend = ({
  guest, currency, disabled, event,
}: Props) => {
  const { formSubmissionLineItems } = guest.personInfo
  const { id } = event

  const ticketsForCurrentEvent: FormSubmissionLineItem[] = formSubmissionLineItems.filter(
    ({ formSubmission: { eventId }, formType }) => (
      eventId === id && formType === FormType.Ticket),
  )

  const numberOfTickets = ticketsForCurrentEvent.length
  const eventSpend = _.sumBy(ticketsForCurrentEvent, (ticket) => parseInt(ticket.price, 10))

  let title = "No Tickets"
  if (numberOfTickets) {
    title = `${numberOfTickets} ${pluralize("Ticket", numberOfTickets)}${eventSpend ? `: ${asMoney(eventSpend, currency)}` : ""}`
  }

  return (
    <Popover
      isDisabled={disabled}
      arrow
      trigger={(
        <DropdownToggle
          icon={<Icon icon="ticket-horiz-outline" size={6} mr={2} />}
          title={title}
          disabled={disabled}
        />
      )}
      body={(
        <Flex py={6} textAlign="center" color="gray.700">
          <Box flex={1} w={40} borderRightWidth={1}>
            <Text fontSize="2xl">{asMoney(eventSpend, currency)}</Text>
            <Text fontSize="sm">Event Spend</Text>
          </Box>
          <Box>
            <Text flex={1} w={40} fontSize="2xl">{numberOfTickets}</Text>
            <Text fontSize="sm">{pluralize("Ticket", numberOfTickets)}</Text>
          </Box>
        </Flex>
      )}
    />
  )
}

export default TicketSpend
