import React from "react"
import { CheckInLocation, ModalName } from "sharedTypes"
import { Spacer, Stack, Text } from "@chakra-ui/react"
import RoundedList, { RoundedListItem } from "pages/EventShow/Settings/RoundedList"
import Button from "components/Buttons/Button"
import Icon from "components/elements/Icon"
import SectionHeader from "pages/EventShow/Settings/SectionHeader"
import UpgradeAlert from "components/elements/UpgradeAlert"
import _ from "lodash"
import useModal from "services/useModal"

type Props = {
  checkInLocations: CheckInLocation[]
  enabled: boolean
}

const EventActivities = ({ checkInLocations, enabled }: Props) => {
  const showModal = useModal()

  return (
    <Stack direction="column" spacing={4}>
      <SectionHeader
        actionButtons={[
          <Button
            isDisabled={!enabled}
            leftIcon="plus"
            onClick={() => showModal(ModalName.EventActivityForm)}
          >
            New Activity
          </Button>]}
      >Event Activities
      </SectionHeader>
      <Text>
        Event Activities (formerly Check-In Sessions) enable tracking of guest activities in
        multi-day or multi-session events. This feature allows you to check in guests for
        various sessions within the same event, such as receptions, dinners, after-parties,
        or workshops. You can also use it to track guest check-outs, ensuring a comprehensive
        overview of attendance and engagement.
      </Text>
      {!enabled && (
        <>
          <Spacer />
          <UpgradeAlert text="This feature requires a Professional Subscription." />
        </>
      )}
      <Spacer />
      {enabled && checkInLocations.length > 0 && (
        <RoundedList>
          {_.sortBy(checkInLocations, "name").map((location) => (
            <RoundedListItem
              key={location.id}
              text1={<><Icon icon="session" mr={2} size={6} />{location.name}</>}
              action={(
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => showModal(ModalName.EventActivityForm, { editLocation: location })}
                >Edit
                </Button>
              )}
            />
          ))}
        </RoundedList>
      )}
    </Stack>
  )
}

export default EventActivities
