import React, { Dispatch, SetStateAction } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import GuestAvatar from "components/elements/GuestAvatar"
import Icon from "components/elements/Icon"
import { GuestSummary } from "sharedTypes"

type Props = {
  selectedGuest: GuestSummary
  selectedGuestIndex: number
  setSelectedGuestIndex: Dispatch<SetStateAction<number>>
  guestCount: number
}

const GuestSelector = ({
  selectedGuest: {
    personInfo: {
      firstName,
      lastName,
      photoUrl,
    },
    status,
    checkedIn,
  },
  selectedGuestIndex,
  setSelectedGuestIndex,
  guestCount,
}: Props) => (
  <Box borderBottomWidth={1} px={6} pb={10}>
    <Text fontWeight="bold">Preview as Guest:</Text>
    <Flex pt={4} justifyContent="space-between">
      <Flex gap={2}>
        <GuestAvatar
          firstName={firstName}
          lastName={lastName}
          photoUrl={photoUrl}
          status={status}
          checkedIn={!!checkedIn}
        />
        <Flex alignItems="center" fontSize="sm">
          <Text fontWeight="semibold">{firstName}</Text>&nbsp;<Text>{lastName}</Text>
        </Flex>
      </Flex>
      <Flex alignItems="center" gap={2}>
        <Button
          size="xs"
          variant="ghost"
          isDisabled={selectedGuestIndex <= 0}
          onClick={() => {
            setSelectedGuestIndex((oldSelectedGuestIndex) => oldSelectedGuestIndex - 1)
          }}
        >
          <Icon icon="prev-arrow" size={3} />
        </Button>
        <Button
          size="xs"
          variant="ghost"
          isDisabled={selectedGuestIndex >= guestCount - 1}
          onClick={() => {
            setSelectedGuestIndex((oldSelectedGuestIndex) => oldSelectedGuestIndex + 1)
          }}
        >
          <Icon icon="next-arrow" size={3} />
        </Button>
      </Flex>
    </Flex>
  </Box>
)

export default GuestSelector
