import React, { ReactNode } from "react"
import {
  Box, CardBodyProps, Flex, Text,
} from "@chakra-ui/react"
import Card from "./layout/Card"

type Props = {
  title: string
  children: ReactNode
  accessoryRight?: ReactNode
} & CardBodyProps
const CardWithTitle = ({
  title, children, accessoryRight, ...props
}: Props) => (
  <Box>
    <Flex justifyContent="space-between"><Text mb={5} fontSize="xl">{title}</Text>{accessoryRight}</Flex>
    <Card {...props}>{children}</Card>
  </Box>
)

export default CardWithTitle
