import React, { ReactElement } from "react"
import { Box, Text } from "@chakra-ui/react"
import Confirm from "./Confirm"

export type Props = {
  children: ReactElement;
  onClickConfirm?: () => void;
  stopChildClickPropagation?: boolean;
  type?: string;
  name?: string;
  what?: string;
  description?: string|ReactElement;
  from?: string;
}

const ConfirmDelete = ({
  children,
  type,
  name,
  from,
  what,
  onClickConfirm = () => {},
  stopChildClickPropagation,
  description,
}: Props) => {
  const confirmationText = <>Are you sure you want to delete {whatToDelete(type, name, what)}{from ? ` from ${from}` : ""}?</>

  return (
    <Confirm
      confirmationTitle={`Delete${type ? ` ${type}` : ""}`}
      confirmationText={(
        <>
          <Text>{confirmationText}</Text>
          {!!description && (
          <Box mt={4}>{typeof description === "string"
            ? <Text>{description}</Text> : description}
          </Box>
          )}
        </>
      )}
      confirmButtonLabel="Delete"
      onClickConfirm={onClickConfirm}
      stopChildClickPropagation={stopChildClickPropagation}
    >
      {children}
    </Confirm>
  )
}

export const whatToDelete = (type?: string, name?: string, what?: string) => {
  if (what) {
    return <>{what}</>
  }

  if (type && !name) {
    return <>this {type}</>
  }

  const quotedName = <Text as="strong" fontWeight="semibold">“{name}”</Text>

  if (!type && name) {
    return quotedName
  }

  if (type && name) {
    return <>the {type} {quotedName}</>
  }

  return <>it</>
}

export default ConfirmDelete
