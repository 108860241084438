import React, { MouseEventHandler, ReactNode } from "react"
import { Flex, Text } from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import Icon from "components/elements/Icon"

type Props = {
  label: string
  value: ReactNode
  buttonText: string
  onClick: MouseEventHandler<HTMLButtonElement>
  emptyValueErrorMessage: string
}

const EditableTextProperty = ({
  label, value, buttonText, onClick, emptyValueErrorMessage,
}: Props) => (
  <Flex alignItems="center" justifyContent="space-between">
    <Text fontWeight="bold" w="25%">{label}</Text>
    <Flex flex={1}>{!value ? (
      <Flex alignItems="center" gap={1}>
        <Icon icon="alert" color="red.500" size={5} /><Text color="red.500">{emptyValueErrorMessage}</Text>
      </Flex>
    ) : <Text>{value}</Text>}
    </Flex>
    <Button variant="outline" size="sm" onClick={onClick}>{buttonText}</Button>
  </Flex>
)

export default EditableTextProperty
