import React from "react"
import {
  Box, Flex, List, Text, ListItem,
} from "@chakra-ui/react"
import Panel from "components/elements/Panel"
import _ from "lodash"
import { FormQuestion } from "sharedTypes"
import {
  Chart as ChartJS, ArcElement, PieController,
} from "chart.js"
import { Chart } from "react-chartjs-2"
import { CHART_COLORS } from "utilities/constants"

ChartJS.register(ArcElement, PieController)

type Props = {
    question: FormQuestion
}

const responseSummary = ({ responses }: FormQuestion) => Object.entries(responses)
  .map(([name, value]) => ({ name, value }))

const PieChart = ({ question, question: { responses, id } }: Props) => {
  if (!responses) {
    return null
  }

  const hasResponses = Object.keys(responses).length
  const data = hasResponses
    ? responseSummary(question)
    : question.choices.map((choice) => ({ name: choice, value: 0 }))

  const chartJsData = {
    labels: Object.keys(data).map((response) => (_.startCase(response))),
    datasets: [
      {
        label: "# of Answers",
        data: Object.values(data),
        backgroundColor: CHART_COLORS,
        borderColor: "#ffffff",
        borderWidth: 1,
      },
    ],
  }

  const options = {
    responsive: true,
    aspectRatio: 1.5,
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <Box mb={8}>
      {data.length > 0 && (
        <Panel>
          <Flex>
            <Flex py={5} flex={1} justify="center">
              {hasResponses ? (
                <Chart type="pie" data={chartJsData} options={options} />
              ) : (
                <Flex bg="gray.300" w={52} h={52} borderRadius="full" align="center" justify="center">No Responses</Flex>
              )}
            </Flex>
            <Flex flex={1} align="center">
              <List>
                {data.map(({ name, value }, index) => (
                  <ListItem display="flex" key={id + name + index}>
                    <Box
                      my={1}
                      mr={2}
                      borderWidth={1}
                      flexShrink={0}
                      w={10}
                      h={3}
                      bg={CHART_COLORS[index % CHART_COLORS.length]}
                    /> <Text fontSize="sm" color="gray.700" minW={32} maxW={60}>{name}: {value}</Text>
                  </ListItem>
                ))}
              </List>
            </Flex>
          </Flex>
        </Panel>
      )}
    </Box>
  )
}

export default PieChart
