import React from "react"
import { Box } from "@chakra-ui/react"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import { DioboxEvent } from "sharedTypes"
import Select from "components/ReactHookForm/Select"
import TitleWithSubtitle from "../TitleWithSubtitle"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
}

const languages = [
  { label: "English", value: "en" },
  { label: "French", value: "fr" },
  { label: "German", value: "de" },
  { label: "Spanish", value: "es" },
  { label: "Portuguese", value: "pt" },
  { label: "Dutch", value: "nl" },
]

const BadgesSection = ({ event, updateEvent }: Props) => {
  const onSubmit = (values) => updateEvent(values)

  return (
    <Box>
      <TitleWithSubtitle
        title="Badges"
        subtitle="Select the language for the “Add to Calendar” and “Add to Apple Wallet” badges."
        mb={5}
      />
      <ContainedForm values={event} onSubmit={onSubmit} name="Badges">
        {({ control }) => (
          <Box maxWidth={400}>
            <Select
              name="language"
              control={control}
              label="Language"
              options={languages}
            />
          </Box>
        )}
      </ContainedForm>
    </Box>
  )
}

export default BadgesSection
