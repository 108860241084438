import React from "react"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import { Form, FormType, Translations } from "sharedTypes"
import MessagingSection from "../EditFormModalContent/EditFormModalSettings/Messaging"

type Props = {
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
  type: FormType
  form: Form
}

const SubmissionSettings = ({
  updateEvent, defaultTranslations, type, form,
}: Props) => (
  <>
    <SidePanelHeader>Button</SidePanelHeader>
    <MessagingSection
      updateEvent={updateEvent}
      defaultTranslations={defaultTranslations}
      formType={type}
      form={form}
    />
  </>
)

export default SubmissionSettings
