import React from "react"
import {
  ChakraProps, Flex, Text,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"

type Props = {
  label: string;
  color?: string;
  textColor?: string;
  icon?: string;
} & ChakraProps

const Pill = ({
  label, color = "gray.200", textColor = "white", width = "16", icon, ...props
}: Props) => (
  <Flex bgColor={color} textAlign="center" borderRadius="base" h="24px" align="center" justify="center" shrink={0} width={width} {...props}>
    {icon && <Icon icon={icon} color={textColor} size={2} mr={1} />}
    <Text fontSize="xs" fontWeight="semibold" color={textColor} casing="uppercase">{label}</Text>
  </Flex>
)

export default Pill
