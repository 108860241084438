import React from "react"
import NoContent from "components/elements/NoContent"

const NoWebsite = ({ enableWebsite }) => {
  const descriptionItems = [
    "Publish a custom website to promote your event, create a registration form, and sell tickets.",
    "Build your event schedule with speaker profiles and an event agenda.",
  ]

  const callsToActions = [
    {
      icon: "globe",
      text: "Enable Website",
      action: enableWebsite,
    },
  ]

  return (
    <>
      <NoContent
        title="Event Website"
        icon="globe"
        descriptionItems={descriptionItems}
        callsToActions={callsToActions}
      />
    </>
  )
}

export default NoWebsite
