import axios, { AxiosPromise } from "axios"
import { decamelize, decamelizeKeys } from "humps"
import { handleError } from "utilities/toasts"
import { QueryClient } from "react-query"
import { camelizeObject, decamelizeObject } from "utilities/objects"
import {
  authToken, checkVersionUpdate, clearSession, signOut, socketId,
} from "./authentication"
import useMaintenanceStatus from "./useMaintenanceStatus"

const getAuthorizationHeaders = () => ({
  Authorization: `Token token="${authToken()}"`,
})

const errorResponseHandler = (error) => {
  const { response, config: { params } } = error
  if (response) {
    const { status } = response

    if (status === 503) {
      useMaintenanceStatus.setState({ maintenanceStatus: true })

      return Promise.reject(response)
    }

    switch (status) {
      case 401:
        window.location.assign("/login")
        signOut(new QueryClient(), () => {})

        return Promise.reject(response)
      case 403:
        if (response.config.url.endsWith("/sign_in")) {
          handleError(error)

          return Promise.reject(error)
        }
        clearSession()
        window.location.assign("/")
        break
      case 404:
        if (params.bypass404redirection !== true) {
          window.location.assign("/")
        }
        break
      case 400:
      default:
        handleError(error)
    }
  }

  return Promise.reject(response)
}

const responseHandler = (response) => {
  if (response.headers && !response.config.url.endsWith("api/v3/version")) {
    checkVersionUpdate(response.headers["webapp-version"])
  }

  return response
}

axios.interceptors.response.use(responseHandler, errorResponseHandler)

const request = <T>(method, url, data = {}, params = {}, additionalOptions = {}) => {
  const options = {
    method,
    url,
    data,
    params: decamelizeKeys({ ...params, socket_id: socketId() }),
    headers: getAuthorizationHeaders(),
    transformResponse: [].concat(
      axios.defaults.transformResponse as any,
      (camelizeObject) as any,
    ),
    transformRequest: [],
    ...additionalOptions,
  }

  if (!(data instanceof FormData)) {
    options.transformRequest = [].concat(
      (decamelizeObject) as any,
      axios.defaults.transformRequest as any,
    )
  }

  return axios(options) as AxiosPromise<T>
}

const handleFiles = (data) => {
  const fileKeys = Object.keys(data).filter((key) => data[key] instanceof File)

  if (fileKeys.length) {
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      formData.append(decamelize(key), data[key])
    })

    return formData
  }

  return data
}

export const get = <T>(url, params = {}, additionalOptions = {}) => request<T>("get", url, {}, params, additionalOptions)

export const post = <T>(url, data = {}) => request<T>("post", url, handleFiles(data), {})

export const put = <T>(url, data = {}) => request<T>("put", url, handleFiles(data), {})

export const patch = <T>(url, data = {}) => request<T>("patch", url, handleFiles(data), {})

export const del = <T>(url, data = {}) => request<T>("delete", url, data, {})
