import React from "react"
import { Button, Flex, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { asDateWithTZ, generateFormatStringWithYesterdayAndToday } from "utilities/strings"
import { CheckInLocation, CheckInTransaction } from "sharedTypes"
import ConfirmDelete from "components/dialogs/Confirm/ConfirmDelete"
import { useParams } from "react-router"
import { useQueryClient } from "react-query"
import { deleteTransaction } from "api/guests"
import { removeGuestTransaction } from "context/actions"

type Props = {
  checkInItem: CheckInTransaction
  timezone: string
  checkInLocationsWithCheckInOption: (CheckInLocation | { id: null; name: string})[]
  onDelete: () => void
}

const CheckInListItem = ({
  checkInItem, timezone, checkInLocationsWithCheckInOption, onDelete,
}: Props) => {
  const { eventId } = useParams<{ eventId: string }>()
  const queryClient = useQueryClient()

  const {
    guestOfGuest, checkInTime, checkInLocationId, guestId, id,
  } = checkInItem

  const handleDelete = () => {
    deleteTransaction(eventId, guestId, id)
      .then(() => {
        removeGuestTransaction(queryClient, checkInItem)
        onDelete()
      })
  }

  const locationName = checkInLocationsWithCheckInOption.find(
    ({ id: locationId }) => locationId === checkInLocationId,
  )?.name

  return (
    <Flex justifyContent="space-between" px={5} py={3} fontSize="md" gap={4} w="full">
      <Flex alignItems="center" gap={2} flex={1} w="30%">
        <Icon icon={guestOfGuest ? "guest-2" : "check-in"} size={6} color="green.500" />
        <Text isTruncated>{locationName}</Text>
      </Flex>
      <Flex alignItems="center" gap={2} flexShrink={0}>
        {asDateWithTZ(checkInTime,
          timezone, generateFormatStringWithYesterdayAndToday(checkInTime))}
        <ConfirmDelete type="Guest Activity" onClickConfirm={handleDelete}>
          <Button minW="min-content" h="min-content" p={0} variant="ghost">
            <Icon icon="trash" size={5} color="red.500" />
          </Button>
        </ConfirmDelete>
      </Flex>
    </Flex>
  )
}

export default CheckInListItem
