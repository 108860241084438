import React from "react"
import {
  Box, Text, Image, Flex,
} from "@chakra-ui/react"
import { successToast } from "utilities/toasts"
import Confirm from "components/dialogs/Confirm/Confirm"
import Link from "components/elements/Link"
import SectionV2 from "components/layout/SectionV2"

type Props = {
  templateId: number|null;
  websiteUrl: string;
  updateWebsite: (params: {}) => Promise<void>;
}

const Template = ({ templateId, websiteUrl, updateWebsite }: Props) => {
  const thumbUrl = (id) => `https://data.diobox.com/app/web/assets/events/website/template/template-thumb-${id}.jpg`

  const templates = [
    {
      name: "Modern",
      url: thumbUrl(3),
      id: 3,
    },
    {
      name: "Pictorial",
      url: thumbUrl(2),
      id: 2,
    },
    {
      name: "Classic",
      url: thumbUrl(1),
      id: 1,
    },
  ]

  const onClick = (template) => {
    updateWebsite({ templateId: template.id })
      .then(() => successToast({ title: `Updated website template to ${template.name}` }))
  }

  const templateList = templates.map((template) => {
    const selected = templateId === template.id

    return (
      <Box textAlign="center" key={template.id}>
        <Text fontSize="lg" textAlign="left" fontWeight="semibold">{template.name}</Text>
        <Box
          borderWidth={selected ? 3 : 1}
          borderRadius="base"
          borderColor={selected ? "blue.400" : "gray.400"}
          overflow="hidden"
          my={2}
        >
          <Confirm
            confirmationTitle="Website Template"
            confirmationText="Are you sure you want to change your website template?"
            confirmButtonLabel="Update"
            confirmButtonColorScheme="button.primary"
            skipIf={selected}
          >
            <Image
              w={52}
              src={template.url}
              alt={template.name}
              cursor="pointer"
              onClick={() => onClick(template)}
              ignoreFallback
            />
          </Confirm>
        </Box>
        <Link
          fontSize="sm"
          href={`${websiteUrl}?previewTemplateId=${template.id}`}
          isExternal
        >
          Preview
        </Link>
      </Box>
    )
  })

  return (
    <SectionV2
      title="Template"
      subtitle="This template controls the placement of the large image,
    event details, and the action button that appear on top of your webpage."
    >
      <Flex
        justify="space-evenly"
        gap={6}
        align="center"
        borderWidth={1}
        borderRadius="xl"
        p={4}
        backgroundColor="white"
      >
        {templateList}
      </Flex>
    </SectionV2>
  )
}

export default Template
