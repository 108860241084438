import { useLocation } from "react-router"

const useSearchParams = <T extends Record<string, string>>() => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const params: T = {} as T

  Array.from(searchParams.entries()).forEach(([key, value]) => {
    params[key as keyof T] = value as T[keyof T]
  })

  return params
}

export default useSearchParams
