import React from "react"
import NoContent from "components/elements/NoContent"
import { ModalName } from "sharedTypes"
import useModal from "services/useModal"

const NoGuests = () => {
  const showModal = useModal()

  const callsToActions = [
    {
      icon: "plus-big",
      text: "Add Guest",
      action: () => showModal(ModalName.NewGuest),
    },
    {
      icon: "tag-outline",
      text: "Create Guest List",
      action: () => showModal(ModalName.GuestListForm),
    },
    {
      icon: "import",
      text: "Import Guests",
      action: () => showModal(ModalName.ImportGuests),
    },
  ]

  return (
    <NoContent
      title="Guest Management"
      icon="guests-stroke"
      descriptionItems={["Organize, categorize, and tag your guests to interact with them in a way that works best for your event."]}
      callsToActions={callsToActions}
    />
  )
}

export default NoGuests
