import React from "react"
import Icon from "components/elements/Icon"
import { Box, Flex } from "@chakra-ui/react"

type Props = {
  tableName: string;
  onClickBack: () => void;
}

const TableDropdownHeader = ({ tableName, onClickBack }: Props) => (
  <Flex mx={-2}>
    <Flex
      align="center"
      as="button"
      w="25%"
      textAlign="left"
      fontSize="sm"
      color="blue.500"
      type="button"
      onClick={onClickBack}
    >
      <Icon icon="prev-arrow" mr={2} /> Tables
    </Flex>
    <Box w="50%">{tableName}</Box>
  </Flex>
)

export default TableDropdownHeader
