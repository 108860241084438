import React from "react"
import { Flex, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { DioboxEvent, Form, FormType } from "sharedTypes"
import { asMoney } from "utilities/strings"
import { formIconAndType, formStatusAndColor } from "utilities/forms"

type Props = {
  form: Form
  event: DioboxEvent
}

const FormListItem = ({
  form, event, event: { currency },
}: Props) => {
  const { color } = formStatusAndColor(form, event)
  const { type } = form
  const { icon } = formIconAndType(type)

  return (
    <Flex alignItems="center">
      <Icon color={color} size={3} icon="dot" mr={3} />
      <Icon icon={icon} size={6} mr={3} />
      <Flex alignItems="center">
        <Text noOfLines={1}>{form.name}</Text>
        {type === FormType.Ticket && <Text ml={1}>{asMoney(form.price, currency)}</Text>}
      </Flex>
    </Flex>
  )
}

export default FormListItem
