import React, { ElementType, ReactNode, useRef } from "react"
import Icon from "components/elements/Icon"
import { Box } from "@chakra-ui/react"
import { useInViewport } from "ahooks"
import { ChakraProps } from "@chakra-ui/system"

type Props = {
  children: ReactNode;
  selected?: boolean;
  checked?: boolean;
  type?: "button";
  onClick?: () => void;
  component?: ElementType;
} & ChakraProps

const DropdownItem = ({
  children,
  selected,
  checked,
  type,
  onClick,
  component: Component = "button",
  ...rest
}: Props) => {
  const containerRef = useRef(null)
  const inViewport = useInViewport(containerRef)

  return (
    <Box
      as={Component}
      ref={containerRef}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      w="full"
      p={5}
      borderTopWidth={1}
      textAlign="left"
      fontSize="lg"
      bg={selected ? "blue.50" : "none"}
      type={type}
      onClick={onClick}
      {...rest}
    >
      <Box w="full" h={8}>{inViewport ? children : null}</Box>
      {checked && <Icon icon="checkmark" size={5} color="blue.500" />}
    </Box>
  )
}

export default DropdownItem
