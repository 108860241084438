import React from "react"
import { Box, Text } from "@chakra-ui/react"

type Props = {
  name: string
  email: string
}

const GuestDetails = ({ name, email }: Props) => (
  <Box textAlign="center">
    <Text fontSize="lg" fontWeight="semibold">{name}</Text>
    <Text>{email}</Text>
  </Box>
)

export default GuestDetails
