import { GuestSummary } from "sharedTypes"

type Updater = GuestSummary | ((GuestSummary) => GuestSummary)

const withoutGroupSize = (g: GuestSummary[]) => g.map(
  ({ groupSize, ...guestSummary }) => guestSummary,
)

const updateGuests = (guests: GuestSummary[], updatedGuest: Updater) => {
  if (!guests) {
    return guests
  }

  const updater = typeof updatedGuest === "function"
    ? (g) => ({
      ...updatedGuest(g),
      plusOnes: g.plusOnes ? withoutGroupSize(g.plusOnes.map(updatedGuest)) : undefined,
    })
    : (g) => ({
      ...g.id === updatedGuest.id ? updatedGuest : g,
      plusOnes: g.plusOnes ? withoutGroupSize(updateGuests(g.plusOnes, updatedGuest)) : undefined,
    })

  return guests?.map(updater)
}

export default updateGuests
