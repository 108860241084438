import React from "react"
import * as Yup from "yup"
import Link from "components/elements/Link"
import { Box, Text } from "@chakra-ui/react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import Checkbox from "components/ReactHookForm/Checkbox"
import FileUpload from "components/ReactHookForm/FileUpload"
import { HELP_ARTICLE_HREF, IMPORTING_GUEST_CSV_TEMPLATE_URL } from "utilities/externalLinks"

type Props = {
  onUpload: (File, boolean) => Promise<{success: boolean, errorMessage: string}>
  onClose: () => void
}

const UploadSchema = Yup.object().shape({
  importFile: Yup.mixed()
    .defined()
    .test("fileType", (value: any) => /.+(\.csv)$/.test(value?.name)),
  containsHeaders: Yup.boolean(),
})

const Upload = ({ onUpload, onClose }: Props) => {
  const onSubmit = ({
    importFile, containsHeaders,
  }) => onUpload(importFile, containsHeaders)

  const howtoLink = (
    <Link
      isExternal
      href={HELP_ARTICLE_HREF}
    >
      Importing Guests from a CSV
    </Link>
  )

  const templateLink = (
    <Link
      isExternal
      href={IMPORTING_GUEST_CSV_TEMPLATE_URL}
    >
      Download the Template
    </Link>
  )

  return (
    <ModalForm
      icon="import"
      size="3xl"
      title="Import Guests"
      closeOnEsc={false}
      onClose={onClose}
      onSubmit={onSubmit}
      submitText="Next"
      initialValues={{
        importFile: "",
        containsHeaders: true,
      }}
      validationSchema={UploadSchema}
    >
      {({
        control,
        isValid,
        errors,
      }) => (
        <>
          <Text mb={8} align="center">
            For a tutorial, see {howtoLink} in the Help Center or {templateLink}.
          </Text>
          <FileUpload
            name="importFile"
            control={control}
            accept=".csv"
          />
          {errors.importFile
            && (
            <Box textAlign="center" mt={10} mb={6}>
              <Text color="red.600">Please select a document in CSV format.</Text>
              <Text color="gray.700">
                To learn more about importing or to download a template, see this
                <> </>
                <Link isExternal href={HELP_ARTICLE_HREF}>help article</Link>.
              </Text>
            </Box>
            )}
          {isValid && (
          <Box textAlign="center" mt={6}>
            <Checkbox name="containsHeaders" control={control} label="First row contains headers" />
          </Box>
          )}
        </>
      )}
    </ModalForm>
  )
}

export default Upload
