import {
  put, post, del, get,
} from "services/api"
import { currentAccountId } from "services/authentication"
import {
  Account,
  PaginationMeta,
  ChargeConfiguration,
  PreviewInvoice,
  PromoCode,
  AccountUser,
  User,
  AccountConfiguration,
} from "../sharedTypes"
import {
  PostSubscription,
  PostAccount,
  PutAccount,
  PostPreviewInvoice,
  PostApplyPromoCode,
  CheckPassword,
  PutCurrentUser,
  PutUpdatePassword,
  PostCharge,
  DeleteSubscription,
} from "../sharedTypes/api"

export const postAccount = (data: PostAccount) => post<Account>(`${import.meta.env.VITE_API_BASE}/api/v2/accounts.json`, data)

export const putAccount = (data: PutAccount) => put<Account>(`${import.meta.env.VITE_API_BASE}/api/v2/accounts/${currentAccountId()}.json`, data)

export const deleteAccount = () => del<Account>(`${import.meta.env.VITE_API_BASE}/api/v2/accounts/${currentAccountId()}.json`)

export const postSubscription = (data: PostSubscription) => post<Account>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/subscription.json`, data)

export const postPreviewInvoice = (priceId: PostPreviewInvoice) => post<PreviewInvoice>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/subscription/preview_invoice.json`, { stripePriceId: priceId })

export const postApplyPromoCode = (data: PostApplyPromoCode) => post<PromoCode>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/subscription/apply_promo_code.json`, data)

export const deleteSubscription = (data: DeleteSubscription) => del<Account>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/subscription.json`, data)

export const checkPassword = (data: CheckPassword) => post<{passed: boolean}>(`${import.meta.env.VITE_API_BASE}/api/v2/users/check_password.json`, data)

export const putCurrentUser = (data: PutCurrentUser) => put<User>(`${import.meta.env.VITE_API_BASE}/api/v2/users.json`, data)

export const putUpdatePassword = (data: PutUpdatePassword) => put<User>(`${import.meta.env.VITE_API_BASE}/api/v2/user/update_password.json`, data)

export const createBillingPortalSession = () => post<{url: string}>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/billing_portal_sessions.json`)

export const getPlans = () => get(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/plans.json`)

export const getChargeConfiguration = (id: string) => get<ChargeConfiguration>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/charge_configurations/${id}.json`)

export const postCharge = (chargeConfigurationId: string, data: PostCharge) => post<Account>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/charge_configurations/${chargeConfigurationId}/charges.json`, data)

export const getAccountUsers = () => get<{ data: AccountUser[], meta: PaginationMeta }>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/team_members.json`)

export const postAccountUser = (accountUser: Partial<AccountUser>) => post<{ data: AccountUser }>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/team_members.json`, { teamMember: accountUser })

export const putAccountUser = (data: AccountUser) => put<{ data: AccountUser }>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/team_members/${data.id}.json`, data)

export const deleteAccountUser = (data: AccountUser) => del(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/team_members/${data.id}.json`)

export const getConfiguration = () => get<AccountConfiguration>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/configuration.json`)

export const deleteOrphanContacts = () => del<{ count: number }>(`${import.meta.env.VITE_API_BASE}/api/v2/accounts/${currentAccountId()}/orphan_contacts.json`)
export const postExportAccount = () => post(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/export.json`)
