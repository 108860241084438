import { formIconAndType } from "utilities/forms"
import { useForms } from "queries/forms"
import { FormType } from "sharedTypes"

const useGenerateName = (eventId: string) => {
  const { data: formsData } = useForms(eventId)

  const generateFormName = (type?: FormType) => {
    if (!formsData || !type) {
      return ""
    }

    const count = formsData?.data.filter((form) => form.type === type).length

    return `${formIconAndType(type).type} ${count + 1}`
  }

  return { generateFormName }
}

export default useGenerateName
