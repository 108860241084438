import React, { ReactNode } from "react"
import { Box } from "@chakra-ui/react"

type Props = {
  children: ReactNode
}

const DesktopView = ({ children }: Props) => (
  <Box minW="1240px">
    {children}
  </Box>
)

export default DesktopView
