import React from "react"
import Spinner from "components/elements/Spinner"
import { eventFormPath } from "utilities/routes"
import NoContent from "components/elements/NoContent"
import EventResourcesView from "components/layout/EventResourcesView"
import EventResourceListItem from "components/layout/EventResourceListItem"
import { Box, Flex, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import SelectPrompt from "components/SelectPrompt"
import useModal from "services/useModal"
import { useForms } from "queries/forms"
import {
  Form, ModalName, EventCounts, FormType, DioboxEvent, Translations, FormSortOption,
} from "sharedTypes"
import { asMoney } from "utilities/strings"
import _ from "lodash"
import Link from "components/elements/Link"
import { DIOBOX_FORMS_ARTICLE_LINK } from "utilities/externalLinks"
import { formIconAndType, formStatusAndColor } from "utilities/forms"
import SelectedForm from "./SelectedForm/SelectedForm"
import { getFormVisibility } from "./SelectedForm/StatusBox/Visibility"
import FormListItemTypeHeader from "./FormListItemTypeHeader"

interface Props {
  counts: EventCounts
  formId: string
  path: string
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
  sort: FormSortOption
}

const EventForms = ({
  event, counts, formId, path, updateEvent, defaultTranslations, sort,
}: Props) => {
  const showModal = useModal()
  const { id: eventId, currency } = event
  const { isLoading, data } = useForms(eventId)
  const { forms: formsCount } = counts
  const forms = data?.data

  if (isLoading) {
    return <Spinner />
  }

  if (!formsCount) {
    return <NoForms onClick={() => showModal(ModalName.FormForm)} />
  }

  if (!forms) {
    return null
  }

  const sortForms = () => {
    const typeOrder = [FormType.Registration, FormType.Ticket, FormType.RSVP, FormType.Waitlist]

    switch (sort) {
      case FormSortOption.CreatedAtDesc:
        return _.orderBy(forms, "createdAt", "desc")
      case FormSortOption.Name:
        return _.orderBy(forms, (form) => form.name.toLowerCase())
      case FormSortOption.Type:
        return typeOrder.map((type) => ({
          type,
          items: _.groupBy(forms, "type")[type] || [],
        })).filter((group) => group.items.length > 0)
      case FormSortOption.Visibility:
        return _.orderBy(forms, (form) => {
          const { status } = formStatusAndColor(form, event)

          return getFormVisibility(form.private, status, form.type)[0]
        }, "desc")
      default:
        return forms
    }
  }

  return (
    <EventResourcesView
      listItems={sortForms().map((element) => {
        if (sort === FormSortOption.Type) {
          const { type, items } = element

          return (
            <Box key={type}>
              <FormListItemTypeHeader text={type} />
              {items.map((item) => (
                <ListItem
                  key={item.externalId}
                  form={item}
                  selected={item.externalId === formId}
                  event={event}
                  currency={currency}
                />
              ))}
            </Box>
          )
        }

        return (
          <ListItem
            key={element.externalId}
            form={element}
            selected={element.externalId === formId}
            event={event}
            currency={currency}
          />
        )
      })}
      showBorder={false}
      bgColor={formId ? "gray.50" : "white"}
      selectedItem={formId ? (
        <SelectedForm
          formId={formId}
          path={path}
          event={event}
          updateEvent={updateEvent}
          defaultTranslations={defaultTranslations}
          formsCount={formsCount}
        />
      ) : <SelectPrompt text="Select a Form" />}
    />
  )
}

type ListItemProps = {
  form: Form
  selected: boolean
  event: DioboxEvent
  currency: string
}

const ListItem = ({
  form, selected, event, currency,
}: ListItemProps) => {
  const { color } = formStatusAndColor(form, event)
  const {
    type,
    variablePrice,
    price,
    externalId,
    private: isPrivate,
  } = form
  const { icon } = formIconAndType(type)

  const generateSubtitle = () => {
    if (type !== FormType.Ticket) {
      return null
    }
    if (variablePrice) {
      return "Variable Price"
    }

    return asMoney(price, currency)
  }

  const subtitle = generateSubtitle()

  return (
    <EventResourceListItem
      selected={selected}
      path={eventFormPath(event.id, externalId)}
      key={externalId}
    >
      <>
        <Flex align="center" mr={5}>
          <Icon icon={icon} size={8} mr={5} />
          <Flex minH={12} flexDirection="column" justifyContent="center">
            <Text fontSize="xl" noOfLines={1}>{form.name}</Text>
            {subtitle && <Text fontSize="sm">{subtitle}</Text>}
          </Flex>
        </Flex>
        <Flex alignItems="center">
          {isPrivate && <Icon icon="closed-eye" size={5} mr={3} color="gray.500" />}
          <Icon color={color} size={3.5} icon="dot" />
        </Flex>
      </>
    </EventResourceListItem>
  )
}

const NoForms = ({ onClick }) => {
  const descriptionItems = [
    "You can create forms or tickets that allow guests to register for your event. Diobox provides various form types that offer different functionalities.",
  ]

  const footer = (
    <Flex alignItems="center" mt={4}>
      <Link href={DIOBOX_FORMS_ARTICLE_LINK} target="_blank"><Icon icon="info" mr={2} />Learn more about Diobox Forms</Link>
    </Flex>
  )

  return (
    <NoContent
      title="Forms"
      icon="form-registration"
      descriptionItems={descriptionItems}
      callsToActions={[{
        icon: "plus-big", text: "Create First Form", action: onClick, isButton: true,
      }]}
      footer={footer}
    />
  )
}

export default EventForms
