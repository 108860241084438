/* Extend the given object with additional properties initialized to undefined
   This is necessary because these propertys are added by the image loader component
   during image upload, and if they are not initialized to undefined then form validation
   won't work properly.
*/

const extendFormValuesWithIamges = (object: object, properties: string[]) => {
  const extendedObject = { ...object }

  properties.forEach((prop) => {
    extendedObject[prop] = undefined
  })

  return extendedObject
}

export default extendFormValuesWithIamges
