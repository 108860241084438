import React, { useState } from "react"
import {
  Box, Divider, Flex, Heading, HStack, Stack, Text,
} from "@chakra-ui/react"
import { Account, Plan, PromoCode } from "sharedTypes"
import { asMoney } from "utilities/strings"
import Button from "components/Buttons/Button"
import Link from "components/elements/Link"
import { usePreviewInvoice } from "queries/plans"
import { postApplyPromoCode, postSubscription } from "api/accounts"
import { updateCurrentAccount } from "context/actions"
import * as Yup from "yup"
import { useQueryClient } from "react-query"
import PaymentForm, { LineItem } from "components/elements/PaymentForm"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import TextField from "components/ReactHookForm/TextField"
import SwitchField from "components/ReactHookForm/SwitchField"
import CreditCardField from "components/ReactHookForm/CreditCardField"

type Props = {
  selectedPlan: Plan
  interval: "month" | "year"
  currentAccount: Account
  onSuccess: () => void
}

const Payment = ({
  selectedPlan,
  interval,
  currentAccount,
  onSuccess,
}: Props) => {
  const { subscription, cardBrand, cardLast4 } = currentAccount
  const hasStripeSubscription = !!subscription?.stripeId

  const [appliedPromoCode, setAppliedPromoCode] = useState<PromoCode | null>(null)
  const queryClient = useQueryClient()

  const {
    isFetching,
    previewInvoice: {
      prorationAmount,
      unusedTimeAmount,
      startingBalance,
      discountAmount,
      amountDue,
      prorationDate,
    },
  } = usePreviewInvoice(selectedPlan.stripePriceIds[interval], subscription)

  const applyPromoCode = (promoCode) => {
    postApplyPromoCode({ promoCode }).then(({ data }) => setAppliedPromoCode(data))
  }

  const fullPrice = () => (interval === "year" ? selectedPlan.monthlyPrice * 12 : selectedPlan.monthlyPrice)
  const annualDiscount = () => (interval === "year" ? selectedPlan.monthlyPrice * 12 - selectedPlan.annualPrice : 0)
  const grandTotal = () => (
    typeof amountDue === "number" ? amountDue / 100 : fullPrice() - annualDiscount() - promoCodeAmount()
  )

  const promoCodeAmount = () => {
    if (appliedPromoCode?.amountOff) {
      return (appliedPromoCode.amountOff / 100)
    }
    if (appliedPromoCode?.percentOff) {
      return (fullPrice() - annualDiscount()) * (appliedPromoCode.percentOff / 100)
    }

    return 0
  }

  const submitText = () => {
    if (grandTotal() <= 0) {
      return "Free Upgrade"
    }

    return subscription ? "Pay & Upgrade" : "Pay & Start Subscription"
  }

  const onSubmit = async ({
    tokenId, promoCodeEnabled,
  }: {tokenId?: string | null, promoCodeEnabled?: boolean}) => {
    const payload = {
      subscription: {
        type: selectedPlan.type,
        tokenId,
        promoCode: promoCodeEnabled ? appliedPromoCode?.id : null,
        stripePriceId: selectedPlan.stripePriceIds[interval],
        prorationDate,
      },
    }

    try {
      const { data, status } = await postSubscription(payload)

      if (status === 200) {
        await updateCurrentAccount(queryClient, data)
        onSuccess()
      }
    } catch {
      // API service automatically alerts the user, show must go on without closing the modal
    }
  }

  const SubscriptionSchema = Yup.object().shape({
    tokenId: Yup.string().nullable().when("creditCardRequired", {
      is: true,
      then: (schema) => schema.required(),
    }),
    promoCodeEnabled: Yup.boolean(),
    promoCode: Yup.string().nullable(),
    creditCardRequired: Yup.boolean(),
  })

  const {
    control, handleSubmit, formState: { isValid, isSubmitting }, watch, setValue,
  } = useForm({
    defaultValues: {
      tokenId: null,
      promoCodeEnabled: false,
      promoCode: "",
      creditCardRequired: !hasStripeSubscription,
    },
    resolver: yupResolver(SubscriptionSchema),
    mode: "onChange",
  })

  const [
    promoCodeEnabled,
    promoCode,
    creditCardRequired,
  ] = watch([
    "promoCodeEnabled",
    "promoCode",
    "creditCardRequired",
  ])

  return (
    <>
      <PaymentForm
        isFetching={isFetching}
        isValid={isValid}
        submitText={submitText()}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box py={6}>
          <LineItem title={`${selectedPlan.type} (${interval}ly)`} value={fullPrice()} />
          {annualDiscount() > 0 && (
          <LineItem
            credit
            title="Annual Billing Discount"
            value={annualDiscount()}
          />
          )}
        </Box>
        <Divider />
        {(!!prorationAmount
                    || !!unusedTimeAmount
                    || !!discountAmount
                    || !!startingBalance
                    || (promoCodeEnabled && appliedPromoCode))
                  && (
                    <>
                      <Box py={6}>
                        {!!prorationAmount
                        && <LineItem title="Prorated for this period" value={prorationAmount / 100} />}
                        {!!discountAmount
                        && <LineItem title="Promo Code Discount" value={discountAmount / 100} credit />}
                        {!!unusedTimeAmount
                        && <LineItem title="Unused Time Credit" value={-unusedTimeAmount / 100} credit />}
                        {!!startingBalance
                        && <LineItem title="Starting Balance" value={-startingBalance / 100} credit />}
                        {promoCodeEnabled && appliedPromoCode && (
                          <LineItem
                            credit
                            title={`Promo Code: ${appliedPromoCode?.id}`}
                            value={promoCodeAmount()}
                          />
                        )}
                      </Box>
                      <Divider />
                    </>
                  )}
        <Flex py={8} justify="space-between" fontSize="xl" fontWeight="semibold">
          <Text>Total</Text>
          <Text>{asMoney(grandTotal(), "USD")}</Text>
        </Flex>
        <Divider />
        {grandTotal() > 0 && (
          <>
            <Heading pt={6} fontSize="sm" fontWeight="normal">Billing information</Heading>
            <Stack pt={6} pb={8}>
              <Box mb={2}>
                {creditCardRequired && (
                <Box borderWidth={1} borderRadius="md" p={3}>
                  <CreditCardField name="tokenId" control={control} />
                </Box>
                )}
                {hasStripeSubscription && (
                <>
                  {creditCardRequired && <Heading mt={6} fontSize="sm">Existing card</Heading>}
                  <Text fontWeight="semibold">
                    {`${cardBrand} ending in ${cardLast4} `}
                    {creditCardRequired && (
                    <Link
                      fontWeight="normal"
                      onClick={() => {
                        setValue("tokenId", null)
                        setValue("creditCardRequired", false)
                      }}
                    >
                      use this card
                    </Link>
                    )}
                  </Text>
                </>
                )}
                {!creditCardRequired && (
                <Link onClick={() => setValue("creditCardRequired", true)}>
                  Enter new card information
                </Link>
                )}
              </Box>
              {!hasStripeSubscription && (
              <Box>
                <SwitchField
                  label="Promo code"
                  name="promoCodeEnabled"
                  control={control}
                  size="sm"
                  fontSize="sm"
                  fontWeight="normal"
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setValue("promoCode", "")
                      setAppliedPromoCode(null)
                    }
                  }}
                  mb={2}
                />
                {promoCodeEnabled && (
                <HStack>
                  <TextField
                    name="promoCode"
                    control={control}
                    placeholder="Enter promo Code"
                    variant="outline"
                    size="sm"
                    mb={0}
                    h={8}
                  />
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => applyPromoCode(promoCode)}
                  >Apply
                  </Button>
                </HStack>
                )}
              </Box>
              )}
            </Stack>
            <Divider />
          </>
        )}
      </PaymentForm>
    </>
  )
}

export default Payment
