import React, { ReactNode } from "react"
import {
  Flex, Heading, SpaceProps, Text,
} from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import { ModalName } from "sharedTypes"
import { useIntercom } from "react-use-intercom"
import useModal from "services/useModal"
import useCurrentAccount from "services/useCurrentAccount"
import Icon from "components/elements/Icon"
import AlertBox, { AlertBoxType } from "./AlertBox/AlertBox"

interface Props {
  text: string|ReactNode
  title?: string
  noBorder?: boolean;
  mb?: SpaceProps["marginBottom"];
}

const UpgradeAlert = ({
  title, text, noBorder = false, mb,
}: Props) => {
  const { subscription } = useCurrentAccount()
  const { show: showIntercom } = useIntercom()
  const isCorporate = subscription?.type === "Corporate"
  const showModal = useModal()

  const handleClick = () => (isCorporate ? showIntercom() : showModal(ModalName.Upgrade))
  const buttonText = isCorporate ? "Contact Us" : "Upgrade"
  const color = "blue.500"

  if (noBorder) {
    return (
      <Flex justify="flex-start" align="center" mb={mb}>
        <Icon icon="info" color={color} mr={1} />
        {title && <Heading>{title}</Heading>}
        <Text textColor={color} fontWeight="bold" fontSize="md" mr={3}>{text}</Text>
        <Button
          size="xs"
          variant={noBorder ? "outline" : "solid"}
          onClick={handleClick}
        >{buttonText}
        </Button>
      </Flex>
    )
  }

  return (
    <AlertBox
      type={AlertBoxType.Upgrade}
      header={title}
      text={text}
      buttonText={buttonText}
      onClick={handleClick}
      mb={mb}
    />
  )
}

export default UpgradeAlert
