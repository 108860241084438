import React, { ReactNode } from "react"
import {
  Card as ChakraCard, CardBody, CardBodyProps, CardHeader,
} from "@chakra-ui/react"

type Props = { header?: ReactNode } & CardBodyProps

const Card = ({ header, ...props }: Props) => (
  <ChakraCard>
    {header && <CardHeader>{header}</CardHeader>}
    <CardBody {...props} />
  </ChakraCard>
)

export default Card
