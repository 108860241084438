import React, { useState } from "react"
import {
  Box, Flex, HStack, Text,
} from "@chakra-ui/react"
import Popover from "components/dialogs/Popover"
import CopyToClipboard from "components/elements/CopyToClipboard"
import Link from "components/elements/Link"
import Icon from "components/elements/Icon"
import Button from "components/Buttons/Button"
import useWebsiteUrl from "utilities/useWebsiteUrl"
import { Website } from "sharedTypes"
import PreviewMode from "./PreviewMode"
import EditMode from "./EditMode"
import StatusIndicator from "./StatusIndicator"

type Props = {
  website: Website
  updateWebsite: (params: {}) => Promise<void>
}

const PublishedUpdater = ({
  website, website: { published, urlPath }, updateWebsite,
}: Props) => {
  const [editMode, setEditMode] = useState(false)
  const url = useWebsiteUrl(website)
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenEditModal = () => setEditMode(true)
  const handleClosePopover = () => setIsOpen(false)
  const handleOpenPopover = () => setIsOpen(true)
  const handleCloseEditModal = () => { setEditMode(false); handleClosePopover() }

  return (
    <Popover
      placement="bottom-start"
      arrow={false}
      isOpen={isOpen}
      onClose={handleClosePopover}
      trigger={(
        <Flex alignItems="center" onClick={handleOpenPopover}>
          <StatusIndicator
            published={published}
            urlPath={urlPath}
          />
          <Icon icon="down-arrow" size={3} ml={2} />
        </Flex>
      )}
      body={(
        <>
          <Box p={6} pb={4} borderBottomWidth={1}>
            {editMode
              ? (
                <EditMode
                  published={published}
                  onClose={handleCloseEditModal}
                  updateWebsite={updateWebsite}
                />
              )
              : (
                <PreviewMode
                  published={published}
                  urlPath={urlPath}
                  onOpen={handleOpenEditModal}
                />
              )}
          </Box>
          <Box p={6}>
            <Text fontWeight="semibold">Web page URL</Text>
            <Link isExternal href={url} fontSize="lg">{url}</Link>
            <HStack mt={2}>
              <CopyToClipboard size="sm" text={url} variant="outline" leftIcon="copy">Copy</CopyToClipboard>
              <Button as="a" href={url} target="-_blank" size="sm" leftIcon="link-external" variant="outline">View</Button>
            </HStack>
          </Box>
        </>
      )}
    />
  )
}

export default PublishedUpdater
