import React from "react"
import {
  Box,
  Input,
  Text,
  PopoverTrigger,
  InputGroup,
  Popover,
  InputLeftAddon,
  InputRightElement,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  InputLeftElement,
} from "@chakra-ui/react"
import { HexColorPicker } from "react-colorful"

type Props = {
  isOpen: boolean
  onOpen: () => void
  color: string
  onTextInputChange: (newColor: string) => void
  onColorPickerChange: (newColor: string) => void
  compact?: boolean
}

const ColorSelectPopover = ({
  isOpen, onOpen, color, onColorPickerChange, onTextInputChange, compact,
}: Props) => (
  <Popover variant="responsive" autoFocus={false} isOpen={isOpen}>
    <PopoverTrigger>
      <InputGroup mt={compact ? 0 : 4}>
        {compact && (
          <InputLeftAddon>
            <Text>Hex</Text>
          </InputLeftAddon>
        )}
        <InputLeftElement pl={compact ? 20 : 0} pointerEvents="none">
          <Text>#</Text>
        </InputLeftElement>
        <Input
          variant="outline"
          w={compact ? "full" : 28}
          bg="white"
          onFocus={onOpen}
          onChange={(e) => onTextInputChange(e.target.value)}
          value={color}
          sx={{ paddingLeft: 6 }}
        />
        {compact && (
          <InputRightElement>
            <Box
              backgroundColor={`#${color}`}
              borderWidth={1}
              w={6}
              h={6}
              borderRadius="md"
            />
          </InputRightElement>
        )}
      </InputGroup>
    </PopoverTrigger>
    <PopoverContent>
      <PopoverArrow />
      <PopoverBody>
        <HexColorPicker color={color} onChange={onColorPickerChange} />
      </PopoverBody>
    </PopoverContent>
  </Popover>
)

export default ColorSelectPopover
