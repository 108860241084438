import { produce } from "immer"

// todo: use this in guestsStore

// eslint-disable-next-line import/prefer-default-export
export const immer = (config) => (set, get, api) => config((partial, replace) => {
  const nextState = typeof partial === "function"
    ? produce(partial)
    : partial

  return set(nextState, replace)
}, get, api)
