import React from "react"
import { Control } from "react-hook-form"
import { Center, Stack, Text } from "@chakra-ui/react"
import RSVPRadioButtonsField from "components/ReactHookForm/RSVPRadioButtonsField/RSVPRadioButtonsField"
import { SubmissionGuest, Translations } from "sharedTypes"
import GuestDetails from "components/elements/GuestDetails"

type Props = {
  control: Control<{
    guest: {
        status: string;
    };
  }, any>
  guest: SubmissionGuest
  namePrefix: string
  translations: Translations
  primaryColor: string
  disabled?: boolean
}

export const RSVPFields = ({
  control,
  guest,
  namePrefix,
  translations,
  primaryColor,
  disabled = false,
}: Props) => (
  <Stack py={5} spacing={5}>
    <GuestDetails name={`${guest.firstName} ${guest.lastName}`} email={guest.email} />
    <Center pointerEvents={disabled ? "none" : "auto"}>
      <RSVPRadioButtonsField
        control={control}
        name={`${namePrefix}.status`}
        options={[
          {
            label: <Text>{translations.rsvpConfirm}</Text>,
            value: "1",
          },
          {
            label: <Text>{translations.rsvpDecline}</Text>,
            value: "2",
          },
        ]}
        primaryColor={primaryColor}
      />
    </Center>
  </Stack>
)

export default RSVPFields
