import React from "react"
import { Website } from "sharedTypes"
import SectionV2 from "components/layout/SectionV2"
import Card from "components/layout/Card"
import Date from "./Date"
import Location from "./Location"
import Links from "./Links"

type Props = {
  website: Website
  updateWebsiteWithToastFeedback: (params: {}) => Promise<void>
}

const Sidebar = ({
  website: {
    dateEnabled,
    dateHeader,
    dateLine1,
    dateLine2,
    locationEnabled,
    locationVenue,
    locationLine1,
    locationLine2,
    locationLine3,
    links,
    linksEnabled,
  },
  updateWebsiteWithToastFeedback,
}: Props) => (
  <SectionV2
    title="Sidebar"
    subtitle="Customize your webpage by displaying additional details about your event."
  >
    <Card>
      <Date
        dateEnabled={dateEnabled}
        dateHeader={dateHeader}
        dateLine1={dateLine1}
        dateLine2={dateLine2}
        updateWebsite={updateWebsiteWithToastFeedback}
      />
      <Location
        locationEnabled={locationEnabled}
        locationVenue={locationVenue}
        locationLine1={locationLine1}
        locationLine2={locationLine2}
        locationLine3={locationLine3}
        updateWebsite={updateWebsiteWithToastFeedback}
      />
      <Links
        links={links}
        linksEnabled={linksEnabled}
        updateWebsite={updateWebsiteWithToastFeedback}
      />
    </Card>
  </SectionV2>
)

export default Sidebar
