import React from "react"
import {
  Box, Flex, Grid, GridItem, Heading, Image, Text,
} from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import _ from "lodash"
import { ModalName } from "sharedTypes"
import {
  asDate, asDateDistance, asNumber, removeProtocolFromUrl,
} from "utilities/strings"
import Link from "components/elements/Link"
import useModal from "services/useModal"
import Card from "components/layout/Card"
import { DateFormat } from "utilities/constants"

type Props = {
  title: string
  coverImageUrl: string
  canViewWebsite: boolean
  startDate: string
  urlPath: string
  websiteUrl: string
  viewsCount: number
}

const AboutSection = ({
  title, coverImageUrl, canViewWebsite, startDate, urlPath, websiteUrl, viewsCount,
}: Props) => {
  const showModal = useModal()

  return (
    <Card>
      <Grid
        templateRows="minmax(50px, auto) 1fr"
        templateColumns="repeat(3, 1fr)"
        gap={4}
        minH={56}
      >
        <GridItem colSpan={2}>
          <Heading display="flex" alignItems="center" fontSize="2xl" fontWeight="semibold">{title}</Heading>
        </GridItem>
        <GridItem colSpan={1} rowSpan={2}>
          <Flex
            position="absolute"
            right={6}
            top={6}
            mt={1}
            direction="column"
            align="center"
            justify="center"
            w={310}
            h={210}
            borderWidth={1}
            borderRadius={4}
            overflow="hidden"
          >
            {coverImageUrl
              ? (
                <Image
                  src={`${coverImageUrl}?fit=crop&w=310&h=210`}
                  {...canViewWebsite
                    ? {
                      role: "button",
                      onClick: () => showModal(ModalName.EventImage),
                    }
                    : {}
                }
                />
              ) : (
                <>
                  <Text size="sm" color="gray.400">No Event Image</Text>
                  {canViewWebsite && (
                  <Button
                    size="xs"
                    variant="outline"
                    onClick={() => showModal(ModalName.EventImage)}
                  >Upload
                  </Button>
                  )}
                </>
              )}
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Grid templateColumns="repeat(2, 1fr)" fontWeight="semibold">
            <GridItem rowSpan={2}>
              <Heading fontSize="lg">{asDate(startDate, DateFormat.MONTH_DAY_YEAR)}</Heading>
              <Text color="gray.500" fontWeight="semibold" fontStyle="italic">{_.startCase(asDateDistance(startDate, true))}</Text>
            </GridItem>
            <GridItem px={8}>
              <Box>
                <Heading fontSize="md" fontWeight="sebmibold" color="gray.500">Website URL</Heading>
                { urlPath
                  ? (
                    <Link
                      color="black"
                      href={websiteUrl}
                      target="_blank"
                    >
                      {removeProtocolFromUrl(websiteUrl)}
                    </Link>
                  ) : (
                    canViewWebsite
                      ? (
                        <Button size="xs" variant="outline" onClick={() => showModal(ModalName.WebPageURL)}>
                          Enable
                        </Button>
                      )
                      : <Text>Not enabled</Text>
                  )}
              </Box>
              {canViewWebsite && (
                <Box>
                  <Heading fontSize="md" mt={4} fontWeight="sebmibold" color="gray.500">Page
                    Views
                  </Heading>
                  <Text>{asNumber(viewsCount)}</Text>
                </Box>
              )}
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Card>
  )
}

export default AboutSection
