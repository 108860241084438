import React, { ReactNode } from "react"
import { Box } from "@chakra-ui/react"

type Props = {
  children: ReactNode;
  color?: string | null;
  size?: "auto" | "xs" | "md" | "full" | number;
  textColor?: string;
  borderColor?: string;
  borderWidth?: number;
}

export const DEFAULT_BUTTON_COLOR = "#4E92DF"

const ButtonPreview = ({
  children, color, size = "full", textColor, borderColor, borderWidth,
}: Props) => (
  <Box
    w={size}
    borderRadius="md"
    borderColor={borderColor}
    borderWidth={borderWidth}
    textAlign="center"
    color={textColor || "white"}
    lineHeight="10"
    backgroundColor={color || DEFAULT_BUTTON_COLOR}
    fontWeight="semibold"
    px={10}
  >
    {children}
  </Box>
)

export default ButtonPreview
