import React from "react"
import {
  Box, Flex, Heading, Text,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import EditableFilter from "./EditableFilter"
import Filter from "./Filter"

type Props = {
  label: string;
  filters: any[];
  editable?: boolean;
  onAdd?: () => void;
}

const FilterGroup = ({
  label, filters, editable, onAdd,
}: Props) => {
  let filtersSection
  if (editable) {
    if (!filters.length) {
      filtersSection = (
        <Flex align="center" role="button" onClick={onAdd} px={8} py={3}>
          <Icon icon="circle" size={3} mr={2} />
          <Text fontStyle="italic" color="gray.600" fontSize="xl">Create a filter defined by custom rules</Text>
        </Flex>
      )
    } else {
      filtersSection = filters.map((filter) => <EditableFilter {...filter} />)
    }
  } else {
    filtersSection = filters.map((filter) => <Filter {...filter} />)
  }

  return (
    <Box>
      <Flex justify="space-between" align="center" w="full" px={4} borderBottomWidth={2}>
        <Heading fontSize="xl" py={3}>{label}</Heading>
        {onAdd && <Icon role="button" color="blue.500" icon="plus" onClick={onAdd} mr={4} />}
      </Flex>
      {filtersSection}
    </Box>
  )
}

export default FilterGroup
