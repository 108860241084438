import React, { useEffect, useState } from "react"
import {
  Box, Flex, ListItem, Text, UnorderedList,
} from "@chakra-ui/react"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import useModal from "services/useModal"
import { FormQuestion, ModalName, QuestionTypes } from "sharedTypes"
import Button from "components/Buttons/Button"
import ConfirmDelete from "components/dialogs/Confirm/ConfirmDelete"
import FormattedQuestionTitleWithRequiredMark from "components/Builder/Blocks/Question/FormattedQuestionTitleWithRequiredMark"

type Props = {
  data: FormQuestion
  onDeleteBlock?: () => void
  onChange: (updatedQuestion: FormQuestion) => void
}

const QuestionFields = ({
  data, onDeleteBlock, onChange,
}: Props) => {
  const showModal = useModal()

  const [question, setQuestion] = useState(data)

  useEffect(() => {
    setQuestion(data)
  }, [data])

  const {
    type, title, choices, required, id,
  } = question

  const handleSuccess = (updatedQuestion: FormQuestion) => {
    // Update the question on the editor sidebar
    setQuestion(updatedQuestion)
    // And on the form preview too
    onChange(updatedQuestion)
  }

  return (
    <>
      <SidePanelHeader>Question</SidePanelHeader>
      <Box backgroundColor="white" m={6} borderWidth={1}>
        <Flex justifyContent="space-between" borderBottomWidth={1} p={4}>
          <Text fontWeight="bold">Question Type</Text>
          <Text>{type}</Text>
        </Flex>
        <Flex flexDirection="column" borderBottomWidth={1} p={4}>
          <Text fontWeight="bold">Question Text</Text>
          <FormattedQuestionTitleWithRequiredMark title={title} required={required} />
        </Flex>
        {choices.length > 0 && (
        <Flex flexDirection="column" borderBottomWidth={1} p={4}>
          <Text fontWeight="bold">{type === QuestionTypes.Terms ? "User Consent" : "Choices"}</Text>
          <Box ml={4}>
            <UnorderedList>
              {choices.map(
                (choice, index) => (
                  <ListItem key={index}>{choice}</ListItem>
                ),
              )}
            </UnorderedList>
          </Box>
        </Flex>
        )}
      </Box>
      <Flex justifyContent="space-between" mx={6}>
        <Button
          colorScheme="button.primary"
          variant="outline"
          onClick={() => showModal(
            ModalName.QuestionForm,
            {
              questionId: id,
              onSuccess: handleSuccess,
            },
          )}
        >
          Edit Question
        </Button>
        {onDeleteBlock && (
        <ConfirmDelete type="Question" onClickConfirm={onDeleteBlock}>
          <Button colorScheme="button.danger" variant="outline">Remove Question</Button>
        </ConfirmDelete>
        )}
      </Flex>
    </>
  )
}

export default QuestionFields
