import { useQuery } from "react-query"
import { getForm, getForms } from "api/forms"

export const formsCacheKey = (eventId: string) => ["forms", eventId]
export const formCacheKey = (formId: string) => ["form", formId]

export const useForms = (eventId: string) => useQuery(
  formsCacheKey(eventId),
  () => getForms(eventId, 1, 200).then(({ data }) => data),
)

export const useForm = (
  eventId: string, formId?: string,
) => useQuery(
  formId ? formCacheKey(formId) : [],
  () => getForm(eventId, formId ?? "").then(({ data }) => data),
  { enabled: Boolean(formId) },
)
