import { get, post, put } from "services/api"
import { User } from "../sharedTypes"

export const signIn = (email: string, password: string) => post<User>(`${import.meta.env.VITE_API_BASE}/api/v2/users/sign_in`, { email, password })

export const createUser = (user: User) => post<User>(`${import.meta.env.VITE_API_BASE}/api/v2/users`, user)

export const resetPassword = (email: string) => post<void>(`${import.meta.env.VITE_API_BASE}/api/v2/user/reset_password`, { email })

export const changePassword = (password: string, resetPasswordToken: string) => put<void>(`${import.meta.env.VITE_API_BASE}/api/v2/users/password`,
  { user: { resetPasswordToken, password, passwordConfirmation: password } })

export const confirmEmail = (confirmationToken: string) => get<void>(`${import.meta.env.VITE_API_BASE}/api/v2/users/confirmation`, { confirmationToken })

export const resendConfirmationInstructions = (email: string) => post<void>(`${import.meta.env.VITE_API_BASE}/api/v2/users/confirmation`, { user: { email } })

export const checkAuth = () => get<User>(`${import.meta.env.VITE_API_BASE}/api/v2/check_session`)

export const checkVersion = () => get<{ webapp: string }>(`${import.meta.env.VITE_API_BASE}/api/v3/version`)
