import React, { Fragment, ReactNode } from "react"
import {
  Menu as ChakraMenu,
  MenuButton,
  MenuItem as ChakraMenuItem,
  MenuList,
  MenuDivider as ChakraMenuDivider,
  Heading,
  MenuGroup as ChakraMenuGroup,
  As,
  StyleProps,
  Portal,
  LayoutProps,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import Button from "components/Buttons/Button"

type MenuProps = {
  button?: ReactNode
  buttonText?: string
  buttonVariant?: "solid" | "outline"
  buttonWidth?: "full"
  menuItems: ReactNode[]
  title?: string;
  gutter?: number
  placement?: "top" | "right" | "bottom" | "left" | "auto" | "auto-start" | "auto-end" | "top-start" | "top-end" | "bottom-start" | "bottom-end" | "right-start" | "right-end" | "left-start" | "left-end"
  noPadding?: boolean
  disabled?: boolean
  isOpen?: boolean
  onOpen?: () => void
  onClose?: () => void
  isLazy?: boolean
  autoSelect?: boolean
  maxH?: LayoutProps["maxH"]
}

const Menu = ({
  button,
  buttonText,
  buttonVariant = "outline",
  buttonWidth,
  menuItems,
  title,
  gutter = 8,
  placement = "bottom-end",
  noPadding = false,
  disabled = false,
  isOpen,
  onOpen,
  onClose,
  isLazy,
  autoSelect = true,
  maxH,
}: MenuProps) => {
  const renderButton = () => (buttonText ? (
    <Button
      variant={buttonVariant}
      size="sm"
      rightIcon="down-arrow"
      onClick={onOpen}
    >{buttonText}
    </Button>
  ) : <>{button}</>)

  if (disabled) {
    return renderButton()
  }

  return (
    <ChakraMenu
      gutter={gutter}
      placement={placement}
      isLazy={isLazy}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      autoSelect={autoSelect}
    >
      <MenuButton w={buttonWidth} title={title} aria-label={title} type="button">
        {renderButton()}
      </MenuButton>
      <Portal>
        <MenuList p={noPadding ? 0 : undefined} maxH={maxH} overflow={maxH ? "scroll" : "hidden"}>
          {menuItems.map((item, index) => <Fragment key={index}>{item}</Fragment>)}
        </MenuList>
      </Portal>
    </ChakraMenu>
  )
}

type MenuHeaderProps = {
  children: ReactNode;
  noPadding?: boolean;
}

export const MenuHeader = ({ children, noPadding }: MenuHeaderProps) => (
  <Heading textAlign="center" fontSize="md" bg="gray.100" px={0} py={noPadding ? 0 : 3}>{children}</Heading>
)

type MenuGroupProps = {
  title: string;
  children: ReactNode;
  first?: boolean;
}

export const MenuGroup = ({ title, children, first }: MenuGroupProps) => (
  <ChakraMenuGroup
    title={title}
    mx={0}
    p={3}
    borderTopWidth={1}
    borderBottomWidth={1}
    textAlign="center"
    fontSize="md"
    bg="gray.100"
    {...first ? { mt: "-1px" } : {}}
  >
    {children}
  </ChakraMenuGroup>
)

type MenuItemProps = {
  children: ReactNode
  color?: string;
  icon?: string;
  selected?: boolean;
  isDisabled?: boolean;
  isFocusable?: boolean;
  isCentered?: boolean;
  onClick?: () => void;
  to?: string;
  iconSize?: number;
  as?: As;
} & StyleProps

export const MenuItem = ({
  icon, children, isCentered, selected, iconSize, to = "", ...rest
}: MenuItemProps) => (
  <ChakraMenuItem
    icon={icon ? <Icon icon={icon} size={iconSize} /> : undefined}
    fontSize="md"
    justifyContent={isCentered ? "center" : undefined}
    px={8}
    py={3}
    to={to}
    {...rest}
  >
    {selected && <Icon icon="checkmark" pos="absolute" left={0} ml={4} color="gray.600" />}
    {children}
  </ChakraMenuItem>
)

export const MenuDivider = ({ ...props }) => (<ChakraMenuDivider {...props} />)

export default Menu
