import React from "react"
import { Text } from "@chakra-ui/react"

type Props = {
  name: string
  description: string
}

const FormDetailHeader = ({ name, description }: Props) => (
  <>
    <Text fontSize="2xl" mb={2}>{name}</Text>
    <Text mb={4}>{description}</Text>
  </>
)

export default FormDetailHeader
