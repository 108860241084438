import { Box } from "@chakra-ui/react"
import React, { ReactNode } from "react"
import { ChakraProps } from "@chakra-ui/system"

type Props = {
  children: ReactNode;
} & ChakraProps

const Section = ({ children, ...rest }: Props) => (
  <Box
    borderTopWidth={2}
    _first={{ borderTopWidth: 0, pt: 0 }}
    w="100%"
    py={6}
    {...rest}
  >
    { children }
  </Box>
)

export default Section
