import React from "react"
import * as Yup from "yup"
import {
  Box, Flex, Heading, Stack, Text,
} from "@chakra-ui/react"
import _ from "lodash"
import useCurrentAccount from "services/useCurrentAccount"
import { useCurrentEvent, useEvents } from "queries/events"
import { EventUserRole } from "services/permissions"
import { asDate, titleize } from "utilities/strings"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import UserWithAvatar from "components/elements/UserWithAvatar"
import EmailField from "components/ReactHookForm/EmailField"
import EventWithAvatar from "components/elements/EventWithAvatar"
import Checkbox from "components/ReactHookForm/Checkbox"
import Select from "components/ReactHookForm/Select"
import RadioButtons from "components/ReactHookForm/RadioButtons"
import { User } from "sharedTypes"

export type InitialValuesProps = {
  admin: boolean,
  canCreateEvents: boolean,
  eventId?: string,
  role?: EventUserRole,
  email?: string,
}

type Props = {
  initialValues: InitialValuesProps,
  onSubmit: (values) => Promise<any>
  onClose: () => void
  currentUser: User
  user?: User
}

const PermissionsForm = ({
  initialValues, onSubmit, onClose, currentUser, user,
}: Props) => {
  const currentAccount = useCurrentAccount()
  const { data: currentEvent } = useCurrentEvent()
  const { data: events = [] } = useEvents(currentAccount.id)
  const event = events.find(({ id }) => id === initialValues.eventId)
  const existing = !!initialValues.email

  const EventUserSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    canCreateEvents: Yup.boolean(),
    eventId: Yup.string().when(
      "canCreateEvents", {
        is: false,
        then: (schema) => schema.required(),
      },
    ),
    role: Yup.string().when(
      "eventId", {
        is: (val) => !!val,
        then: (schema) => schema.required(),
      },
    ),
  })

  const roles = [
    { value: EventUserRole.EventAdmin, description: "Access to complete event settings, features, and manage team members." },
    { value: EventUserRole.GuestManager, description: "Create and edit guests, tables, invitations, process payments (iOS), but no access to ticket sales." },
    { value: EventUserRole.DoorOperator, description: "Check-in guests, process payments (iOS), and view seating charts." },
    { value: EventUserRole.DoorStaff, description: "Check-in only without access to guest emails and phone numbers." },
    { value: EventUserRole.WebsiteAdmin, name: "Website Administrator", description: "Full access to website content and settings. No access to guest data." },
  ]
  if (existing) {
    roles.push({ value: EventUserRole.NoAccess, name: "No Access", description: "Team member has no access to this event." })
  }

  const optionLabel = (role) => (
    <Box ml={2} cursor="pointer">
      <Text fontWeight="bold">{role.name || titleize(role.value)}</Text>
      <Text>{role.description}</Text>
    </Box>
  )

  return (
    <ModalForm
      size="xl"
      title={existing ? "Team Member Access" : "Invite Team Member"}
      initialValues={initialValues}
      submitText={existing ? "Save" : "Send Invite"}
      onSubmit={onSubmit}
      onClose={onClose}
      validationSchema={EventUserSchema}
    >
      {({ control, watch, setValue }) => {
        const [admin, eventId] = watch(["admin", "eventId"])

        const handleChangeAdmin = async (e) => {
          const value = e.target.checked

          if (value) {
            await setValue("canCreateEvents", true)
          }
          setValue("admin", value)
        }

        return (
          <Stack spacing={4}>
            {initialValues.email
              ? (
                <Flex align="center">
                  <Text w={1 / 5} flexShrink={0} fontWeight="bold">User</Text>
                  <UserWithAvatar
                    name={user ? `${user.firstName} ${user.lastName}` : undefined}
                    email={initialValues.email}
                    current={user?.id === currentUser.id}
                    unconfirmed={user && !user.confirmed}
                    invited={!user}
                    w="100%"
                  />
                </Flex>
              )
              : (
                <EmailField
                  label="Email"
                  name="email"
                  control={control}
                  placeholder="Enter email address"
                />
              )}
            {event && !currentEvent
              ? (
                <Flex align="center">
                  <Text w={1 / 5} flexShrink={0} fontWeight="bold">Event</Text>
                  <EventWithAvatar event={event} />
                </Flex>
              )
              : (
                <>
                  <Heading fontSize="md" fontWeight="semibold">Account Access</Heading>
                  <Box>
                    <Checkbox
                      name="admin"
                      control={control}
                      label={(
                        <>
                          <Text mb={1} fontSize="lg" fontWeight="semibold">Account Admin</Text>
                          <Text fontSize="sm">Provides access to all events, account settings, and
                            team
                            management
                          </Text>
                        </>
                      )}
                      onChange={handleChangeAdmin}
                    />
                    <Checkbox
                      name="canCreateEvents"
                      control={control}
                      label={(
                        <>
                          <Text mb={1} fontSize="lg" fontWeight="semibold">Create New Events</Text>
                          <Text fontSize="sm">Allows team member to create new events on their
                            own
                          </Text>
                        </>
                      )}
                      disabled={admin}
                    />
                  </Box>
                </>
              )}
            <Heading fontSize="md" fontWeight="semibold">Event Access</Heading>
            {
              admin
                ? <Text>Team member will have access to all events</Text>
                : (
                  <>
                    {!event && (
                      <Select
                        name="eventId"
                        control={control}
                        options={
                          _.orderBy(events, "startDate", "desc")
                            .map(({ id, title, startDate }) => (
                              {
                                label: `${asDate(startDate, "yyyy-MM-dd")} ${_.truncate(title, { length: 54 })}`,
                                value: id,
                              }
                            ))
                        }
                        includeBlank
                        blankLabel="Select event..."
                        fullWidth
                      />
                    )}
                    {eventId && (
                      <Box borderWidth={1} py={4}>
                        <RadioButtons
                          divider
                          mb={0}
                          name="role"
                          control={control}
                          options={roles.map((role) => (
                            { label: optionLabel(role), value: role.value }
                          ))}
                          px={4}
                          spacing={3}
                        />
                      </Box>
                    )}
                  </>
                )
            }
          </Stack>
        )
      }}
    </ModalForm>
  )
}

export default PermissionsForm
