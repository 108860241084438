import React, { useState } from "react"
import { List, VStack } from "@chakra-ui/react"
import _ from "lodash"
import ListHeader from "../pages/EventShow/Guests/ScrollView/GuestListHeader"
import GuestList from "../pages/EventShow/Guests/ScrollView/GuestList"
import Modal from "../components/dialogs/Modal"
import { useGuests } from "../queries/guests"

type Props = {
  eventId: string
  onSelect: (guest) => void
  onClose: () => void
  filtered: boolean
  showMenu?: boolean
  disabledGuestIds?: string[]
  disableMainGuests?: boolean
  showTables?: boolean
}

const SelectGuest = ({
  eventId,
  onSelect,
  onClose,
  filtered,
  showMenu = true,
  disabledGuestIds = [],
  disableMainGuests,
  showTables = true,
}: Props) => {
  const [searchString, setSearchString] = useState("")
  const [guestsOrder, setGuestsOrder] = useState("first_name_asc")

  const { guests, fetchNextPage, isLoading } = useGuests(eventId, searchString, guestsOrder)

  const handleScroll = (e) => {
    if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight) {
      fetchNextPage()
    }
  }
  const debouncedHandleScroll = _.debounce(handleScroll, 100)

  return (
    <Modal
      title="Select Guest"
      show
      size="md"
      h="80%"
      noPadding
      hide={onClose}
    >
      <VStack h="full">
        <ListHeader
          loading={isLoading}
          searchString={searchString}
          onSearch={(query) => setSearchString(query)}
          order={guestsOrder}
          onSetOrder={(value) => setGuestsOrder(value)}
          showMenu={showMenu}
          clearSelectedGuestOnSearch={false}
        />
        <List
          w="full"
          h={72}
          flexGrow={1}
          overflowY="scroll"
          onScroll={(e) => {
            e.persist()
            debouncedHandleScroll(e)
          }}
        >
          <GuestList
            guests={guests}
            showTables={showTables}
            onClickGuest={(guest) => {
              onSelect(guest)
              onClose()
            }}
            order={guestsOrder}
            disabledGuestIds={disabledGuestIds}
            disableMainGuests={disableMainGuests}
            filtered={filtered}
          />
        </List>
      </VStack>
    </Modal>
  )
}

export default SelectGuest
