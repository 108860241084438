import React, { Dispatch, ReactNode } from "react"
import { BuilderBlockWithBlockId } from "sharedTypes"
import { Box } from "@chakra-ui/react"
import BuildingBlock, { BlockInsertPosition } from "components/Builder/BuildingBlock"
import { BuilderBlockType, DraggableItem } from "sharedTypes/builder"
import BlockContent from "components/Builder/BlockContent"
import { handleMove } from "components/Builder/helpers"

type Props = {
  blocks: BuilderBlockWithBlockId[];
  onClick?: () => void;
  editable?: boolean
  selectedBlock?: BuilderBlockWithBlockId;
  setSelectedBlockId?: (blockId: number) => void
  setBlocks: Dispatch<React.SetStateAction<BuilderBlockWithBlockId[]>>
  onDeleteBlock: (blockId: number) => void
  onDuplicateBlock: (blockId: number) => void
  onDropInto?: (item: DraggableItem, blockId: number, position: BlockInsertPosition) => void
}

const Blocks = ({
  blocks,
  onClick,
  editable = false,
  setSelectedBlockId,
  selectedBlock,
  setBlocks,
  onDeleteBlock,
  onDuplicateBlock,
  onDropInto,
}: Props) => {
  const handleSelectBlock = (blockId: number) => {
    setSelectedBlockId?.(blockId)
    onClick?.()
  }

  const hasDynamicBlocks = blocks.length > 0

  if (editable) {
    return (
      <BlocksWrapper hasDynamicBlocks={hasDynamicBlocks}>
        {blocks.map(({ block, blockId }) => (
          <BuildingBlock
            key={blockId}
            dragId={blockId}
            onMove={(
              fromDragId: number,
              toDragId: number,
              position: BlockInsertPosition,
            ) => handleMove(blocks, setBlocks, fromDragId, toDragId, position)}
            onSelect={() => handleSelectBlock(blockId)}
            selected={selectedBlock?.blockId === blockId}
            onDuplicate={
              block.type === BuilderBlockType.QUESTION
                ? undefined
                : () => onDuplicateBlock(blockId)
            }
            onDelete={() => onDeleteBlock(blockId)}
            type={block.type}
            onDropInto={onDropInto}
          >
            <BlockContent block={block} hasPadding />
          </BuildingBlock>
        ))}
      </BlocksWrapper>
    )
  }

  return (
    <BlocksWrapper hasDynamicBlocks={hasDynamicBlocks}>
      {blocks.map(({ block, blockId }) => {
        if (block.type === BuilderBlockType.QUESTION) {
          return (
            <Box key={blockId} onClick={onClick}>
              <BlockContent block={block} hasPadding />
            </Box>
          )
        }

        return <BlockContent key={blockId} block={block} hasPadding />
      })}
    </BlocksWrapper>
  )
}

type BlocksWrapperProps = {
  children: ReactNode
  hasDynamicBlocks: boolean
}

const BlocksWrapper = ({ children, hasDynamicBlocks }: BlocksWrapperProps) => (
  <Box
    borderLeftWidth={1}
    borderRightWidth={1}
    borderBottomWidth={hasDynamicBlocks ? 1 : 0}
  >{children}
  </Box>
)

export default Blocks
