import React from "react"
import { Image, Text } from "@chakra-ui/react"
import Link from "components/elements/Link"
import { SubmissionResponseType } from "sharedTypes"

type Props = {
  response: SubmissionResponseType
};

const Response = ({ response }: Props) => {
  if (!response) {
    return <Text fontWeight="bold" color="gray.400">No response</Text>
  }

  if (typeof response === "object") {
    if (response instanceof Array) {
      return <>{response.map((item) => (<Text key={item} fontWeight="bold" color="black">{item}</Text>))}</>
    }

    if (response.type.includes("image")) {
      return (
        <Link href={response.url} target="_blank" display="block">
          <Image maxH={16} h="full" alt={response.file} src={response.url} borderRadius="md" />
        </Link>
      )
    }

    return (
      <Link href={response.url} target="_blank" display="block">
        <Text fontWeight="bold" color="black">{response.file}</Text>
      </Link>
    )
  }

  if (typeof response === "string") {
    return <Text fontWeight="bold" color="black">{response}</Text>
  }

  return null
}

export default Response
