import { HStack, Stack, Text } from "@chakra-ui/react"
import React from "react"
import { DioboxEvent, FormType, SubmissionLineItem } from "sharedTypes"
import { asDate } from "utilities/strings"
import LineItemDetailCard from "./LineItemDetailCard"
import GuestCard from "./GuestCard"
import PlusOnes from "./PlusOnes"

type Props = {
  lineItem: SubmissionLineItem
  event: DioboxEvent
}
const LineItemView = ({ lineItem, event }: Props) => {
  const { translations } = event
  const { form: { type } } = lineItem

  return (
    <Stack spacing={8} w="full">
      {type !== FormType.Survey && (
      <GuestCard
        name={`${lineItem.guest.firstName} ${lineItem.guest.lastName}`}
        email={lineItem.guest.email}
        status={lineItem.guest.status}
        statuses={[
          translations.unconfirmed,
          translations.confirmed,
          translations.declined,
          translations.waitlisted,
        ]}
        qrCodeUrl={lineItem.guest.qrCodeUrl}
        qrCodeText={translations.qrCodeForCheckIn}
        event={event}
      />
      )}
      <Stack spacing={3}>
        <LineItemDetailCard
          lineItem={lineItem}
          event={event}
        />
        <HStack fontSize="sm" justify="flex-end" spacing={5} color="gray.400">
          <Text>{translations.submitted}: {asDate(lineItem.createdAt)}</Text>
          {lineItem.updatedAt !== lineItem.createdAt && (
          <Text>{translations.edited}: {asDate(lineItem.updatedAt)}</Text>
          )}
        </HStack>
      </Stack>
      {lineItem.guest.status === 1 && lineItem.plusOnes.length > 0 && (
      <PlusOnes
        translations={event.translations}
        primaryColor={event.portalPrimaryColor}
        plusOnes={lineItem.plusOnes}
      />
      )}
    </Stack>
  )
}

export default LineItemView
