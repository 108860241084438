import React from "react"
import { Box, Flex } from "@chakra-ui/react"

type Props = {
  children: any
  onEdit?: () => void
  first?: boolean
  last?: boolean
}

const FormSection = ({
  children, onEdit, first, last,
}: Props) => (
  <Flex justifyContent="center">
    <Flex flexShrink={0} flexGrow={0} w="full" justify="space-between" align="center">
      <Box flex={1} m="auto">
        <Box
          position="relative"
          py={6}
          {...first && {
            py: 4, borderWidth: 1, borderBottomWidth: 0,
          }}
          {...last ? { borderBottomRadius: "md", borderWidth: 1, borderTopWidth: 0 } : {}}
        >
          {children}
          <Box
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            onClick={onEdit}
          />
        </Box>
      </Box>
    </Flex>
  </Flex>
)

export default FormSection
