import React, { ReactNode } from "react"
import { Flex, Text } from "@chakra-ui/react"

type Props = {
  name: string
  value: string|number|ReactNode
  color?: string
}

const ScorecardItem = ({ name, value, color }: Props) => (
  <Flex direction="column" align="center" mb={3}>
    <Text as="strong" fontSize="2xl" fontWeight="normal" lineHeight="short" color={color || "gray.800"}>{value}</Text>
    <Text as="span" fontSize="sm" color="gray.500">{name}</Text>
  </Flex>
)

export default ScorecardItem
