import React from "react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import RecipientCheckboxes from "components/RecipientCheckboxes"
import { DioboxEvent, TextMessage } from "sharedTypes"
import { textRecipientArrayToObject } from "utilities/arrays"
import { textRecipientObjectToArray } from "utilities/objects"

type Props = {
  onHide: () => void
  event: DioboxEvent
  onSave: (textMessage: TextMessage) => void
  textMessage: TextMessage
}

const EditTextRecipients = ({
  onHide, event, onSave, textMessage,
}: Props) => {
  const handleSubmit = ({ sendTo }) => {
    onSave({ ...textMessage, recipients: textRecipientObjectToArray(sendTo) })
    onHide()
  }

  return (
    <ModalForm
      title="Select Recipients"
      onSubmit={handleSubmit}
      onClose={onHide}
      initialValues={textRecipientArrayToObject(textMessage.recipients)}
    >
      {({ control }) => (
        <RecipientCheckboxes event={event} control={control} submitOnChange={false} />
      )}
    </ModalForm>
  )
}

export default EditTextRecipients
