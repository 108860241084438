import React from "react"
import { Flex, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"

type Props = {
    icon: string
    label: string
    hasRightLinkIcon?: boolean
  }

const MenuLink = ({ icon, label, hasRightLinkIcon }: Props) => (
  <Flex
    px={5}
    py={1.5}
    minH={14}
    alignItems="center"
    justifyContent="space-between"
    _hover={{ backgroundColor: "gray.50" }}
  >
    <Flex>
      <Icon size={6} icon={icon} mr={2.5} />
      <Text>{label}</Text>
    </Flex>
    {hasRightLinkIcon && <Icon size={4.5} icon="link" />}
  </Flex>
)

export default MenuLink
