import React, { ReactNode } from "react"
import { Redirect, Route } from "react-router-dom"

interface Props {
  exact?: boolean
  path: string | string[]
  authorized: boolean|((params: {location: {pathname: string}}) => boolean)
  redirectTo: string
  children: ReactNode
}

const ProtectedRoute = ({
  exact, path, authorized, redirectTo, children, ...rest
}: Props) => {
  const isAuthorized = typeof authorized === "function" ? authorized(rest as any) : authorized

  return (
    <Route
      exact={exact}
      path={path}
      render={() => (isAuthorized ? children : <Redirect to={redirectTo} />)}
      {...rest}
    />
  )
}

export default ProtectedRoute
