import Icon from "components/elements/Icon"
import React from "react"
import { Transaction } from "sharedTypes"
import RatingField from "components/ReactHookForm/RatingField"
import ActivityForm from "../Form"

type Props = {
  activity?: Transaction;
}

const Rating = ({ activity }: Props) => {
  let initialValues
  if (activity) {
    initialValues = {
      number: activity.data.number,
    }
  } else {
    initialValues = {
      number: 5,
    }
  }

  return (
    <ActivityForm
      title={<><Icon icon="rating" size={6} mr={2} /> Rating</>}
      fields={(control) => (
        <RatingField
          name="number"
          control={control}
          label="Rating"
        />
      )}
      initialValues={initialValues}
      apiPayload={({ number }) => ({
        type: "RatingTransaction",
        data: { number },
      })}
      activity={activity}
    />
  )
}

export default Rating
