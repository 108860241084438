import React from "react"
import {
  Tr as ChakraTr,
  Th as ChakraTh,
  Td as ChakraTd,
} from "@chakra-ui/react"

export const TheadTr = (props) => <Tr borderWidth={2} background="gray.100" {...props} />
export const Tr = (props) => <ChakraTr borderWidth={2} {...props} />
export const Th = (props) => <ChakraTh textTransform="none" color="gray.800" fontSize="sm" {...props} />
export const Td = (props) => <ChakraTd px={6} py={8} fontSize="sm" {...props} />
