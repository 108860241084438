import { DioboxEvent, Email } from "sharedTypes"
import React, { ReactNode } from "react"
import { Box } from "@chakra-ui/react"
import UpgradeAlert from "components/elements/UpgradeAlert"
import EmailHeader from "../EmailHeader"
import EmailProperty from "../EmailProperty"

type EmailDetailsProps = {
  currentEvent: DioboxEvent
  sendTo: ReactNode
  selectedEmail?: Email
  requiresUpgrade: boolean
}

const EmailDetails = ({
  currentEvent, sendTo, selectedEmail, requiresUpgrade,
}: EmailDetailsProps) => {
  if (!selectedEmail) {
    return null
  }

  return (
    <Box p={8}>
      {requiresUpgrade
        ? <UpgradeAlert text="Your subscription does not support sending this type of email." />
        : (
          <>
            <EmailHeader
              sendTo={sendTo}
              event={currentEvent}
              from={selectedEmail.from}
              fromMailbox={selectedEmail.fromMailbox}
              replyTo={selectedEmail.replyTo}
            />
            <EmailProperty name="Subject">{selectedEmail.subject}</EmailProperty>
          </>
        )}
    </Box>
  )
}

export default EmailDetails
