import React from "react"
import ActionSelect from "components/Builder/components/ActionSelect"
import EditorSection from "components/Builder/components/EditorSection"
import FontSelect from "components/Builder/components/FontSelect"
import PaddingInputs from "components/Builder/components/PaddingInputs"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import ColorSelect from "components/ReactHookForm/ColorSelect/ColorSelect"
import TextField from "components/ReactHookForm/TextField"
import { Control } from "react-hook-form"

type Props = {
  control: Control
}

const ButtonFields = ({ control }: Props) => (
  <>
    <SidePanelHeader>Button Block</SidePanelHeader>
    <EditorSection>
      <ActionSelect
        type="button"
        control={control}
      />
    </EditorSection>
    <EditorSection>
      <TextField label="Button Text" name="text" control={control} variant="outline" />
    </EditorSection>
    <EditorSection>
      <FontSelect control={control} />
    </EditorSection>
    <EditorSection>
      <PaddingInputs control={control} />
    </EditorSection>
    <EditorSection>
      <ColorSelect label="Button Color" name="buttonColor" control={control} />
    </EditorSection>
    <EditorSection>
      <ColorSelect label="Text Color" name="textColor" control={control} />
    </EditorSection>
    <EditorSection>
      <ColorSelect label="Block Color" name="blockColor" control={control} colorScheme="light" />
    </EditorSection>
  </>
)

export default ButtonFields
