import React from "react"
import { Box } from "@chakra-ui/react"
import LabelRedefinition from "modals/FormForm/LabelRedefinition"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import { DioboxEvent, Translations } from "sharedTypes"
import TitleWithSubtitle from "../../TitleWithSubtitle"
import DeferredTicketAssignmentEnabledCheckbox from "./DeferredTicketAssignmentEnabledCheckbox"
import { DEFERRED_GUEST_REGISTRATION_INFO } from "../../content"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
}

const DeferredGuestRegistration = ({ event, updateEvent, defaultTranslations }: Props) => {
  const submitHandler = (values: DioboxEvent) => updateEvent(values)

  return (
    <Box>
      <TitleWithSubtitle
        title="Deferred Guest Registrations"
        subtitle={DEFERRED_GUEST_REGISTRATION_INFO}
        mb={5}
      />
      <DeferredTicketAssignmentEnabledCheckbox event={event} updateEvent={updateEvent} />
      <ContainedForm values={event} onSubmit={submitHandler} name="Deferred Guest Registrations">
        {({ control }) => (
          <Box>
            <Box mb={12}>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.registerGuestsNow}
                name="translations.registerGuestsNow"
                control={control}
              />
            </Box>
            <Box mb={12}>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.yourInformation}
                name="translations.yourInformation"
                control={control}
              />
            </Box>
            <Box mb={12}>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.yourInformationDescription}
                name="translations.yourInformationDescription"
                control={control}
              />
            </Box>
          </Box>
        )}
      </ContainedForm>
    </Box>
  )
}

export default DeferredGuestRegistration
