import React from "react"
import { Heading, Text, VStack } from "@chakra-ui/react"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import { deleteOrphanContacts } from "api/accounts"
import { successToast } from "utilities/toasts"
import pluralize from "pluralize"
import { useMutation } from "react-query"

const ContactSettings = () => {
  const { mutateAsync } = useMutation(
    deleteOrphanContacts,
    {
      onSuccess: (({ data: { count } }) => {
        const title = count
          ? `${count} orphan ${pluralize("contact", count)} deleted.`
          : "No orphan contacts found."
        successToast({ title })
      }),
    },
  )

  return (
    <ContainedForm
      values={{}}
      onSubmit={mutateAsync}
      confirmDeleteProps={{ type: "Orphan Contacts", what: "all Orphan Contacts", from: "your account" }}
      showCancel={false}
      disableSubmitWhenNotDirty={false}
    >
      <VStack spacing={4} py={4} align="baseline">
        <Heading fontSize="md">Orphan Contacts</Heading>
        <Text>
          Diobox automatically stores your guests in a unified contacts database so that they
          can be added in future events, giving you the ability to track their activity across
          events.
        </Text>
        <Text>
          This means if you use the &ldquo;Remove&rdquo; feature, they are removed from that event
          but will still remain in the contacts database. However, if you have contacts that are
          not associated with any events (for example if there were added by mistake), you can
          use this feature to permanently delete them. Proceeding with this process
          will <Text as="strong" fontWeight="bold">not</Text> delete any guest present at an event.
        </Text>
      </VStack>
    </ContainedForm>
  )
}

export default ContactSettings
