import React, { useState } from "react"
import { Flex, Heading } from "@chakra-ui/react"
import { postForm } from "api/forms"
import Button from "components/Buttons/Button"
import Select from "components/ReactHookForm/Select"
import Icon from "components/elements/Icon"
import { refetchForms } from "context/actions"
import { formFormInitialValues } from "modals/FormForm/FormForm"
import { formIconAndType, getFormsByTypeWithVisibilityAndStatus } from "utilities/forms"
import { Control, useController } from "react-hook-form"
import { DioboxEvent, Form, FormType } from "sharedTypes"
import { MAX_FORMS_PER_EVENT } from "utilities/constants"
import { errorToast, successToast } from "utilities/toasts"
import { useQueryClient } from "react-query"
import useGenerateName from "services/useGenerateName"

type Props = {
  control: Control<any>
  event: DioboxEvent
  forms: Form[]
  formType?: FormType
}

const ConnectToForm = ({
  control, event: { id: eventId }, event, forms, formType,
}: Props) => {
  const [createFormIsLoading, setCreateFormIsLoading] = useState(false)
  const queryClient = useQueryClient()
  const { field } = useController({ name: "formId", control })
  const { generateFormName } = useGenerateName(eventId)
  const formName = generateFormName(formType)

  if (!formType) {
    return null
  }

  const formsByType = getFormsByTypeWithVisibilityAndStatus(forms, formType, event)

  const { type: formTypeName } = formIconAndType(formType)

  const handleClick = () => {
    if (forms && forms.length >= MAX_FORMS_PER_EVENT) {
      errorToast({ title: `You can create up to ${MAX_FORMS_PER_EVENT} Forms per event` })

      return
    }

    setCreateFormIsLoading(true)
    const formData = {
      ...formFormInitialValues,
      name: formName,
      type: formType,
    }

    postForm(eventId, formData)
      .then(async ({ data: { externalId } }) => {
        await refetchForms(queryClient, eventId)
        field.onChange(externalId)
        successToast({ title: "New form has been created" })
      })
  }

  return (
    <Flex
      borderTopWidth={1}
      h={14}
      px={6}
      alignItems="center"
      justifyContent="space-between"
      onClick={(e) => e.stopPropagation()}
    >
      <Flex alignItems="center">
        <Icon icon="link-chain" size={5} mr={8} />
        <Heading fontSize="lg" fontWeight="semibold">Connects to Form: </Heading>
      </Flex>
      <Flex alignItems="center">
        {formsByType.length > 0 ? (
          <Select
            name="formId"
            control={control}
            options={formsByType}
            mb={0}
          />
        ) : (
          <Button
            variant="solid"
            isLoading={createFormIsLoading}
            onClick={handleClick}
            size="sm"
          >Create New {formTypeName}
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

export default ConnectToForm
