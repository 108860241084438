import React from "react"
import { Code as DioboxCode } from "@chakra-ui/react"

type Props = {
  children: string;
}

const Code = ({ children }: Props) => (
  <DioboxCode colorScheme="red">{children}</DioboxCode>
)

export default Code
