import React, { ReactElement, ReactNode } from "react"
import { Box } from "@chakra-ui/react"
import { DraggableItem } from "sharedTypes/builder"
import HoverArea from "components/Builder/HoverArea"
import { BlockInsertPosition } from "components/Builder/BuildingBlock"

type FixedBlockProps = {
  editable?: boolean
  selected: boolean
  onSelect: () => void
  children: ReactNode
  blockInsertPosition: BlockInsertPosition
  onDropInto?: (item: DraggableItem, blockId: number, position: BlockInsertPosition) => void
  allowDropInto?: boolean
}

const FixedBlock = ({
  editable, selected, onSelect, children, blockInsertPosition, onDropInto, allowDropInto = true,
}: FixedBlockProps): ReactElement => {
  if (!editable) {
    return <>{children}</>
  }

  return (
    <Box
      _hover={{ outline: `2px solid ${selected ? "#3182CE" : "#C4C4C4"}`, zIndex: 3 }}
      outline={selected ? "2px solid #3182CE" : undefined}
      zIndex={selected ? 2 : undefined}
      position="relative"
      onClick={onSelect}
      cursor="pointer"
    >
      {allowDropInto && (
        <HoverArea
          position={blockInsertPosition}
          onDropInto={onDropInto}
          dragId={blockInsertPosition === BlockInsertPosition.Bottom ? 0 : -1}
          forFixedBlock
        />
      )}
      {children}
    </Box>
  )
}

export default FixedBlock
