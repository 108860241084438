import React from "react"
import { DioboxEvent, Form, FormType } from "sharedTypes"
import SidePanelForm from "components/Builder/components/SidePanelForm"
import { GUEST_PORTAL_HELP_ARTICLE_LINK } from "utilities/externalLinks"
import { BuilderType } from "sharedTypes/builder"
import AddContentSection from "components/Builder/AddContentSection"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import HelpCenterLink from "components/Builder/EditorPanel/HelpCenterLink"
import FormAttributesSection from "./FormAttributesSection"
import ManagePlusOnes from "./ManagePlusOnes"

type Props = {
  form: Form
  onAttributeChange: (formData: Form) => void
  event: DioboxEvent
}

const EditFormModalSettingsPanel = ({
  form,
  onAttributeChange,
  event,
}: Props) => {
  const { currency } = event

  return (
    <SidePanelForm onChange={onAttributeChange} initialValues={form}>
      {({ control }) => (
        <>
          <SidePanelHeader>Form Builder</SidePanelHeader>
          <AddContentSection builderType={BuilderType.Form} />
          <FormAttributesSection form={form} onChange={onAttributeChange} currency={currency} />
          {form.type === FormType.RSVP && (<ManagePlusOnes control={control} />)}
          <HelpCenterLink url={GUEST_PORTAL_HELP_ARTICLE_LINK} title="Help Center: Registration Forms" />
        </>
      )}
    </SidePanelForm>
  )
}

export default EditFormModalSettingsPanel
