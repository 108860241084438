import React from "react"
import { Flex, Text, Box } from "@chakra-ui/react"
import StatusBar from "components/elements/StatusBar"
import { FormType } from "sharedTypes"

type Props = {
  type: FormType
  submissionLineItemsCount: number
  quantity?: number
}

const SubmissionsLabel = ({
  type, quantity, submissionLineItemsCount,
}: Props) => {
  const ratio = (!quantity) ? 0 : submissionLineItemsCount / quantity
  const textColor = (submissionLineItemsCount && submissionLineItemsCount > 0) ? "blue.400" : "gray.400"
  const underlineProps = submissionLineItemsCount > 0 ? { borderBottomColor: textColor } : {}

  return (
    <>
      {type === FormType.Registration || type === FormType.Ticket
        ? (
          <Flex alignItems="flex-end">
            <Flex
              mr={4}
              alignItems="flex-end"
              borderBottomColor="transparent"
              borderBottomWidth={2}
              _hover={underlineProps}
            >
              <Text as="span" color={textColor} fontSize="2xl" lineHeight={4}>{submissionLineItemsCount}</Text>
              {quantity && (
              <>
                <Text as="span" color={textColor} fontSize="2xl" lineHeight={4}>/</Text>
                <Text as="span" color={textColor} fontSize="md" lineHeight={3}>{quantity}</Text>
              </>
              )}
            </Flex>
            {(Boolean(submissionLineItemsCount) && Boolean(quantity)) && <Box w={36}><StatusBar sections={[{ color: "blue.400", size: ratio * 100 }, { color: "gray.100", size: (1 - ratio) * 100 }]} /></Box>}
          </Flex>
        )
        : (
          <Flex alignItems="flex-end">
            <Text as="span" color={textColor} fontSize="2xl" lineHeight={4}>{submissionLineItemsCount}</Text>
          </Flex>
        )}

    </>
  )
}

export default SubmissionsLabel
