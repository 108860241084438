import React from "react"
import { Guest, Table } from "sharedTypes"
import DropdownItem from "../DropdownItem"

type Props = {
  table: Table;
  guest: Guest;
  onSelectSeat: (number) => void;
  onSelectWaitlist: () => void;
}

const SeatSelect = ({
  table, guest, onSelectSeat, onSelectWaitlist,
}: Props) => {
  const isWaitlistSelected = table.id === guest.waitlistTable?.id

  const seatOptions = table.seats.map(({ number, guest: seatGuest }, index) => {
    const isSelected = seatGuest?.id === guest.id

    return (
      seatGuest
        ? (
          <DropdownItem
            key={index}
            component="div"
            selected={isSelected}
            checked={isSelected}
            color={!isSelected ? "gray.300" : ""}
          >
            {`Seat ${number}`} ({seatGuest.name})
          </DropdownItem>
        )
        : (
          <DropdownItem
            key={index}
            type="button"
            onClick={() => onSelectSeat(number)}
          >
            {`Seat ${number}`} (Vacant)
          </DropdownItem>
        )
    )
  })

  const waitlistOption = (
    <DropdownItem
      type="button"
      onClick={onSelectWaitlist}
      selected={isWaitlistSelected}
      checked={isWaitlistSelected}
    >
      Waitlist
    </DropdownItem>
  )

  return (
    <>
      {seatOptions}
      {waitlistOption}
    </>
  )
}

export default SeatSelect
