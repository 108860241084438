import React from "react"
import { Stack } from "@chakra-ui/react"
import { DioboxEvent, Translations } from "sharedTypes"
import PageContent from "components/layout/PageContent"
import GuestConfirmationStatusesSection from "./GuestConfirmationStatusesSection"
import FormButtonsSection from "./FormButtonsSection"
import RegistrationFieldsSection from "./RegistrationFieldsSection"
import FormSpecificMessagingSection from "./FormSpecificMessagingSection/FormSpecificMessagingSection"
import TitleWithSubtitle from "../TitleWithSubtitle"
import OtherPhrasesSection from "./FormSpecificMessagingSection/OtherPhrasesSection"
import DeferredGuestRegistration from "./FormSpecificMessagingSection/DeferredGuestRegistration"
import BadgesSection from "./BadgesSection"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
}

const Terminology = ({ event, updateEvent, defaultTranslations }: Props) => (
  <PageContent>
    <Stack spacing={10}>
      <TitleWithSubtitle
        title="Event Terminology"
        subtitle="Customize or translate any Diobox terms that appear throughout
      the Guest Portal or are sent in automated email notifications."
      />
      <GuestConfirmationStatusesSection
        event={event}
        updateEvent={updateEvent}
        defaultTranslations={defaultTranslations}
      />
      <FormButtonsSection
        event={event}
        updateEvent={updateEvent}
        defaultTranslations={defaultTranslations}
      />
      <BadgesSection
        event={event}
        updateEvent={updateEvent}
      />
      <RegistrationFieldsSection
        event={event}
        updateEvent={updateEvent}
        defaultTranslations={defaultTranslations}
      />
      <OtherPhrasesSection
        event={event}
        updateEvent={updateEvent}
        defaultTranslations={defaultTranslations}
      />
      <DeferredGuestRegistration
        event={event}
        updateEvent={updateEvent}
        defaultTranslations={defaultTranslations}
      />
      <FormSpecificMessagingSection
        event={event}
        updateEvent={updateEvent}
        defaultTranslations={defaultTranslations}
      />
    </Stack>
  </PageContent>
)

export default Terminology
