import React from "react"
import PaginationButtons from "components/PaginationButtons"

interface Props {
  currentPage: number
  pageCount: number
  onChangePage: (currentPage: number) => void
}

const Paginate = ({ currentPage, pageCount, onChangePage }: Props) => (
  <PaginationButtons
    currentPage={currentPage}
    pageCount={pageCount}
    onChangePage={onChangePage}
  />
)

export default Paginate
