import React from "react"
import * as api from "api/guests"
import { updateSelectedGuestsStatuses } from "context/actions"
import pluralize from "pluralize"
import Icon from "components/elements/Icon"
import { Guest, GuestStatus } from "sharedTypes"
import { successToast } from "utilities/toasts"
import { Box, Flex, Text } from "@chakra-ui/react"
import * as Yup from "yup"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import useGuestsStore from "services/useGuestsStore"
import { shallow } from "zustand/shallow"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import RadioButtons from "components/ReactHookForm/RadioButtons"
import useFeatureFlag from "services/featureFlags"
import Checkbox from "components/ReactHookForm/Checkbox"
import UpgradeAlert from "components/elements/UpgradeAlert"
import useCurrentAccount from "services/useCurrentAccount"

type Props = {
  onClose: () => void;
  guest?: Guest;
  updateGuest?: (params: {}) => Promise<void>;
  selectedCount?: number;
  selectedStatusValue?: number;
}

const ChangeStatus = ({
  onClose, guest, updateGuest, selectedCount, selectedStatusValue,
}: Props) => {
  const { waitlistedStatus: waitlistedStatusEnabled } = useFeatureFlag()
  const queryClient = useQueryClient()
  const { eventId } = useParams<{ eventId: string }>()
  const { subscription } = useCurrentAccount()
  const isCorporate = subscription?.type === "Corporate"

  const [selectedGuestIds, deselectAll, allSelected, groupId, segmentId] = useGuestsStore(
    (s) => [s.selectedGuestIds, s.deselectAll, s.allSelected, s.groupId, s.segmentId],
    shallow,
  )

  const statuses = [
    { label: "Confirmed", value: GuestStatus.Confirmed.toString(), color: "blue.400" },
    { label: "Unconfirmed", value: GuestStatus.Unconfirmed.toString(), color: "gray.400" },
    waitlistedStatusEnabled ? { label: "Waitlisted", value: GuestStatus.Waitlisted.toString(), color: "yellow.300" } : undefined,
    { label: "Declined", value: GuestStatus.Declined.toString(), color: "red.400" },
  ].filter(Boolean) as {label: string; value: string; color: string}[]
  const selectedStatus = statuses.find(({ value }) => value === selectedStatusValue?.toString())

  let onSubmit
  let subject

  if (guest && updateGuest) {
    subject = `${guest.personInfo.firstName} ${guest.personInfo.lastName}`
    onSubmit = ({ status, sendConfirmationEmail }) => {
      updateGuest({
        status,
        sendConfirmationEmail: sendConfirmationEmail || undefined,
      }).then(() => {
        onClose()
      })
    }
  } else {
    subject = `${selectedCount} ${pluralize("guest", selectedCount)}`
    onSubmit = ({ status, sendConfirmationEmail }) => api.patchGuestsStatuses(
      eventId,
      status,
      selectedGuestIds,
      allSelected ? segmentId : undefined,
      allSelected ? groupId : undefined,
      sendConfirmationEmail,
    ).then(() => {
      updateSelectedGuestsStatuses(
        queryClient,
        eventId,
        selectedGuestIds,
        allSelected,
        deselectAll,
        status,
      )

      const statusLabel = statuses.find(({ value }) => value === status)?.label
      successToast({ title: `Successfully set the status of ${selectedCount} ${pluralize("guest", selectedCount)} to ${statusLabel}` })
      onClose()
    }).catch(() => {
      // API service automatically alerts the user, show must go on without closing the modal
    })
  }

  return (
    <ModalForm
      initialValues={{
        status: selectedStatusValue?.toString(),
        sendConfirmationEmail: false,
      }}
      validationSchema={Yup.object().shape({ status: Yup.string().required() })}
      onSubmit={onSubmit}
      onClose={onClose}
      title="Change Guest Status"
      submitText="Apply"
    >
      {({ control }) => (
        <>
          <Text fontSize="xl" mb={6}>Update the status of <Text as="strong" fontWeight="semibold">{subject}</Text> to:</Text>
          {selectedStatus ? (
            <Box
              borderRadius="full"
              borderWidth={1}
              borderColor={selectedStatus.color}
              fontSize="sm"
              backgroundColor="gray.100"
              display="inline-block"
              pr={4}
              mb={6}
            >
              <Label color={selectedStatus.color} text={selectedStatus.label} />
            </Box>
          )
            : (
              <RadioButtons
                name="status"
                control={control}
                options={statuses.map(({ label, value, color }) => ({
                  label: <Label color={color} text={label} />,
                  value,
                }))}
              />
            )}
          <Text fontSize="xl" mb={6}>Notify guests:</Text>
          <Checkbox label="Send guest confirmation email about their updated status" name="sendConfirmationEmail" control={control} disabled={!isCorporate} />
          {!isCorporate && (
            <Box textColor="blue.400" ml={7} mt={-4}>
              <UpgradeAlert text="This feature requires a Corporate Subscription." noBorder />
            </Box>
          )}
        </>
      )}
    </ModalForm>
  )
}

const Label = ({ color, text }) => (
  <Flex align="center" fontSize="lg">
    <Icon icon="dot" ml={2} mr={3} color={color} /> {text}
  </Flex>
)

export default ChangeStatus
