import Popover from "components/dialogs/Popover"
import React, { ReactNode } from "react"
import {
  Flex, List, Link as ChakraLink, Stack, Divider,
} from "@chakra-ui/react"
import { eventSettingsPath } from "utilities/routes"
import { CheckInLocation, ModalName } from "sharedTypes"
import { Link } from "react-router-dom"
import Icon from "components/elements/Icon"
import useModal from "services/useModal"
import SideToggle from "../SideToggle/SideToggle"
import ActivityListItem from "./ActivityListItem/ActivityListItem"

type Props = {
  checkInLocationsWithCheckInOption: (CheckInLocation | {id: null; name: string})[]
  checkInLocationId: number|null
  checkInLocations: CheckInLocation[]
  handleClickLocation: (locationId: number|null) => void
  eventId: string
}

const SelectActivityButton = ({
  checkInLocationsWithCheckInOption,
  checkInLocationId,
  checkInLocations,
  handleClickLocation,
  eventId,
}: Props) => {
  const showModal = useModal()

  const onSave = ({ id }) => handleClickLocation(id)

  return (
    <Popover
      trigger={(<SideToggle icon="session" title="Select Event Activity" />)}
      body={(
        <Flex direction="column">
          <List py={2} borderBottomWidth={1}>
            {checkInLocationsWithCheckInOption.map(({ id, name }) => (
              <ActivityListItem
                key={id}
                checkInLocationId={checkInLocationId}
                checkInLocations={checkInLocations}
                handleClickLocation={handleClickLocation}
                id={id}
                name={name}
              />
            ))}
          </List>
          <Stack divider={<Divider />} spacing={0} color="gray.400" textAlign="center">
            <ActionButton icon="plus-big" onClick={() => showModal(ModalName.EventActivityForm, { onSave })}>New Event
              Activity
            </ActionButton>
            <ActionButton icon="pencil" to={eventSettingsPath(eventId, "event-activities")}>Edit
              Event Activities
            </ActionButton>
          </Stack>
        </Flex>
      )}
      zIndex={8}
      gutter={16}
      placement="auto"
      closeOnClick
    />
  )
}

type ActionButtonProps = {
  icon: string
  children: ReactNode
  to?: string
  onClick?: () => void
}

const ActionButton = ({
  to, onClick, icon, children,
}: ActionButtonProps) => (
  <ChakraLink
    as={Link}
    p={4}
    display="flex"
    alignItems="center"
    _hover={{ textDecoration: "none", bgColor: "gray.50" }}
    color="blue.400"
    to={to}
    onClick={onClick}
  >
    <Icon icon={icon} size={5} mr={3} color="blue.400" />
    {children}
  </ChakraLink>
)

export default SelectActivityButton
