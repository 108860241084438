import React, { useState } from "react"
import AuthenticationWrapper from "pages/Unauthenticated/AuthenticationWrapper"
import { Text, VStack } from "@chakra-ui/react"
import EmailField from "components/ReactHookForm/EmailField"
import SubmitButton from "components/Buttons/SubmitButton"
import { resetPassword } from "api/sessions"
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

const ForgotPassword = () => {
  const [sentInstructions, setSentInstructions] = useState(false)

  const onSubmit = ({ email }) => resetPassword(email)
    .then(() => {
      setSentInstructions(true)
    })
    .catch(() => {})

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  })

  const { control, handleSubmit, formState: { isValid, isSubmitting } } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(ResetPasswordSchema),
    mode: "onChange",
  })

  const form = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack w="full" spacing={6}>
        <Text>
          Enter the email address you used to sign up for Diobox and we&apos;ll
          send you an email with a link to reset your password.
        </Text>
        <EmailField name="email" control={control} placeholder="Email" />
        <SubmitButton
          text="Reset my password"
          disabled={!isValid}
          submitting={isSubmitting}
        />
      </VStack>
    </form>
  )

  const success = (
    <Text>An email with a password reset link has been sent.</Text>
  )

  return (
    <AuthenticationWrapper
      pageTitle="Reset Password"
      body={sentInstructions ? success : form}
      header={null}
    />
  )
}

export default ForgotPassword
