import React, { useEffect, useState } from "react"
import { Email, ModalName } from "sharedTypes"
import {
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react"
import Link from "components/elements/Link"
import { eventEmailPath } from "utilities/routes"
import { useHistory, useParams } from "react-router-dom"
import FullScreenModalNav from "components/dialogs/FullScreenModalNav"
import { getInvitationRecipients } from "api/emails"
import Select from "components/ReactHookForm/Select"
import Button from "components/Buttons/Button"
import Modal from "components/dialogs/Modal"
import Paginate from "components/elements/Paginate"
import { asDate } from "utilities/strings"
import Icon from "components/elements/Icon"
import { useQuery } from "react-query"
import useModal from "services/useModal"
import { useForm } from "react-hook-form"
import EmailReportRow from "./EmailReportRow"

type Props = {
  email: Email
}

const EmailReport = ({
  email: {
    id, sentSize, title, updatedAt,
  },
}: Props) => {
  const showModal = useModal()
  const history = useHistory()
  const { eventId } = useParams<{ eventId: string }>()
  const [filter, setFilter] = useState("all")
  const [page, setPage] = useState(1)
  const FILTER_OPTIONS = [
    { label: "All Emails", value: "all" },
    { label: "Opened", value: "opened" },
    { label: "Clicked", value: "clicked" },
    { label: "Unopened", value: "unopened" },
    { label: "Bounced", value: "bounced" },
    { label: "Hard Bounced", value: "hard_bounced" },
    { label: "Soft Bounced", value: "soft_bounced" },
    { label: "Complained", value: "complained" },
  ]

  const close = () => {
    history.push(eventEmailPath(eventId!, id))
  }

  const onSubmit = (values) => {
    setFilter(values.filter)
  }

  const {
    data: recipients,
  } = useQuery(
    ["emailReport", id, filter, page],
    () => getInvitationRecipients(eventId!, id, filter, page).then(({ data }) => data),
  )
  const totalPages = Math.ceil(sentSize / 50)

  const headers = () => (
    <Tr>
      <Th>Name</Th>
      <Th>Email Address</Th>
      <Th>Sent</Th>
      <Th>Opens</Th>
      <Th>Clicks</Th>
      <Th>Soft Bounced</Th>
      <Th>Hard Bounced</Th>
      <Th>Bounced</Th>
    </Tr>
  )

  const { control, handleSubmit, watch } = useForm({
    defaultValues: { filter },
  })

  const filterValue = watch("filter")

  useEffect(() => {
    handleSubmit(onSubmit)()
  }, [filterValue])

  const handleChangePage = (selectedPage: number) => {
    setPage(selectedPage)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal
        show
        noPadding
        size="full"
        hide={close}
        header={(
          <FullScreenModalNav type="header">
            <Text>Email Report</Text>
            <Text>{title}: {asDate(updatedAt)}</Text>
            <Link onClick={close} color="blue.300" fontWeight="bold">
              Close
              <Icon icon="cancel-circle" ml={2} />
            </Link>
          </FullScreenModalNav>
          )}
      >
        <Box overflowY="scroll" p={5} my={12}>
          <Flex justify="space-between">
            <Box>
              <Select
                name="filter"
                control={control}
                options={FILTER_OPTIONS}
              />
            </Box>
            <Flex align="flex-start">
              {totalPages > 1
                && (
                  <Box mr={5}>
                    <Paginate
                      pageCount={totalPages}
                      currentPage={page}
                      onChangePage={handleChangePage}
                    />
                  </Box>
                )}
              <Button
                variant="outline"
                size="sm"
                onClick={() => showModal(ModalName.ExportInvitationList, { id })}
              >Export to CSV
              </Button>
            </Flex>
          </Flex>
          <Table size="sm">
            <Thead>
              {headers()}
            </Thead>
            <Tbody>
              { (recipients || []).map((recipient) => (
                <EmailReportRow recipient={recipient} />
              ))}
            </Tbody>
          </Table>
        </Box>
      </Modal>
    </form>
  )
}

export default EmailReport
