import React from "react"
import { resendConfirmationInstructions } from "api/sessions"
import { successToast } from "utilities/toasts"
import AlertBox, { AlertBoxType } from "./elements/AlertBox/AlertBox"

const ConfirmEmailAlert = ({ email }) => {
  const onClick = () => resendConfirmationInstructions(email)
    .then(() => successToast({ title: `Instructions sent to ${email}` }))

  return (
    <AlertBox
      type={AlertBoxType.Verification}
      text={<>Please confirm your email address (<b>{email}</b>)</>}
      buttonText="Resend Confirmation Instructions"
      onClick={onClick}
    />
  )
}

export default ConfirmEmailAlert
