import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import EventResourceListItem from "./layout/EventResourceListItem"
import Icon from "./elements/Icon"
import Pill from "./elements/Pill"
import useFeatureFlag from "../services/featureFlags"

type Props = {
  id: string
  selectedListItemId: string
  path: string
  icon: string
  title: string
  sentOn: string|null
  subtitle?: string|null
  sendScheduledAt?: string|null
}

const EventResourcesViewListItem = ({
  id, selectedListItemId, path, icon, title, subtitle, sentOn, sendScheduledAt,
}: Props) => (
  <EventResourceListItem key={id} selected={id === selectedListItemId} path={path}>
    <Flex align="center" mr={5}>
      <Icon icon={icon} size={8} mr={5} />
      <Box>
        <Text fontSize="lg" noOfLines={1}>{title}</Text>
        {subtitle && <Text fontSize="sm">{subtitle}</Text>}
      </Box>
    </Flex>
    <StatusPill sentOn={sentOn} sendScheduledAt={sendScheduledAt} />
  </EventResourceListItem>
)

type StatusPillProps = {
  sentOn: string|null
  sendScheduledAt?: string|null
}
const StatusPill = ({ sentOn, sendScheduledAt }: StatusPillProps) => {
  const { scheduledEmails: scheduledEmailsEnabled } = useFeatureFlag()

  if (sentOn) {
    return <Pill label="Sent" color="blue.400" px={2} />
  }

  if (scheduledEmailsEnabled && sendScheduledAt) {
    return <Pill label="Scheduled" color="purple.400" width="auto" px={4} />
  }

  return <Pill label="Draft" color="gray.400" px={2} />
}

export default EventResourcesViewListItem
