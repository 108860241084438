import React from "react"
import WebsiteLink from "components/elements/WebsiteLink"
import EditableSection from "components/layout/EditableSection"
import { ModalName, Website } from "sharedTypes"
import useModal from "services/useModal"

type Props = {
  website: Website
}

const URL = ({ website }: Props) => {
  const showModal = useModal()

  return (
    <EditableSection
      title={website.published ? "Page URL" : "Preview URL"}
      onEdit={() => { showModal(ModalName.WebPageURL) }}
    >
      <WebsiteLink website={website} />
    </EditableSection>
  )
}

export default URL
