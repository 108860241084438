import React from "react"
import { Box } from "@chakra-ui/react"
import { DividerBlock } from "sharedTypes/builder"

const Divider = ({ block: { data } }: { block: DividerBlock }) => (
  <Box
    bgColor={data.blockColor}
    pt={`${data.paddingTop * 4}px`}
    pb={`${data.paddingBottom * 4}px`}
  >
    <hr
      style={{ borderStyle: data.style, borderColor: data.lineColor, borderTopWidth: `${data.size}px` }}
    />
  </Box>
)

export default Divider
