import React, { ReactNode } from "react"
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react"

type Props = {
  name: string
  required?: boolean
  placeholder?: string
  inputRightElement?: ReactNode
}

const FixedBlockTextInput = ({
  name,
  required,
  placeholder,
  inputRightElement,
}: Props) => (
  <Box mb={1}>
    <Flex mb={1.5}>
      <Text fontSize="sm" fontWeight="semibold">{name}</Text>
      {required && (
        <Text fontSize="sm" fontWeight="semibold" color="red.600">
          &nbsp;*
        </Text>
      )}
    </Flex>
    <InputGroup>
      <Input
        variant="outline"
        type="text"
        isRequired={required}
        isReadOnly
        placeholder={placeholder}
      />
      <InputRightElement>
        {inputRightElement}
      </InputRightElement>
    </InputGroup>
  </Box>
)

export default FixedBlockTextInput
