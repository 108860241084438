import { ButtonBlockAction, FormQuestion } from "sharedTypes"

export enum BuilderBlockType {
  BUTTON = "button",
  DIVIDER = "divider",
  HEADING = "heading",
  HTML = "html",
  IMAGE = "image",
  QR_CODE = "qr_code",
  SPACER = "spacer",
  TEXT = "text",
  QUESTION = "question",
}

export type BuilderBlock =
  ButtonBlock |
  DividerBlock |
  HeadingBlock |
  HTMLBlock |
  ImageBlock |
  SpacerBlock |
  TextBlock |
  QRCodeBlock |
  QuestionBlock

export type ButtonBlock = {
  type: BuilderBlockType.BUTTON,
  data: {
    action: ButtonBlockAction
    blockColor: string
    buttonColor: string
    font: string
    text: string
    textColor: string
    paddingTop: number
    paddingBottom: number
    formId?: string
  }
}

export type QRCodeBlock = {
  type: BuilderBlockType.QR_CODE,
  data: {
    blockColor: string
    paddingTop: number
    paddingBottom: number
  }
}

export type DividerBlock = {
  type: BuilderBlockType.DIVIDER,
  data: {
    blockColor: string
    lineColor: string
    size: number
    style: "solid" | "dashed" | "dotted"
    paddingTop: number
    paddingBottom: number
  }
}

export type HeadingBlock = {
  type: BuilderBlockType.HEADING,
  data: {
    align: Align
    blockColor: string
    font: string
    size: number
    text: string
    textColor: string
    paddingTop: number
    paddingBottom: number
  }
}

export type HTMLBlock = {
  type: BuilderBlockType.HTML,
  data: {
    html: string
    paddingTop: number
    paddingBottom: number
  }
}

export type ImageBlock = {
  type: BuilderBlockType.IMAGE,
  data: {
    action: "" | "form" | "website" | "URL"
    align: Align
    alt: string
    blockColor: string
    paddingTop: number
    paddingBottom: number
    scale: number
    size: number
    source: string
  }
}

export type SpacerBlock = {
  type: BuilderBlockType.SPACER,
  data: {
    size: number
    blockColor: string
  }
}

export type TextBlock = {
  type: BuilderBlockType.TEXT,
  data: {
    blockColor: string
    font: string
    lineHeight: number
    size: number
    text: string
    textColor: string
    paddingTop: number
    paddingBottom: number
  }
}

export type QuestionBlock = {
  type: BuilderBlockType.QUESTION,
  data: FormQuestion | Partial<FormQuestion>

}

type Align = "left" | "center" | "right"

export enum DraggableItemTypes {
  REORDER = "reorder",
  ADD = "add"
}

export enum BuilderType {
  Email,
  Form
}

export type DraggableItem = {
  dragId: number
  blockTypeToAdd?: BuilderBlockType
}
