import { useQuery } from "react-query"
import { getAccountUsers } from "api/accounts"
import { AccountUser } from "../sharedTypes"
import { currentAccountId } from "../services/authentication"

export const accountUsersCacheKey = (accountId) => ["accountUsers", accountId]

export const useAccountUsers = (disabled?) => useQuery(
  accountUsersCacheKey(currentAccountId()),
  () => (disabled ? [] : getAccountUsers().then(({ data }) => data.data)),
) as { data?: AccountUser[], isLoading: boolean }
