import Select from "components/ReactHookForm/Select"
import { EMAIL_DIVIDER_STYLES } from "utilities/enums"
import _ from "lodash"
import React from "react"

const DividerStyleSelect = ({ control }) => (
  <Select
    label="Style"
    name="style"
    control={control}
    options={EMAIL_DIVIDER_STYLES.map((option) => ({
      label: _.capitalize(option),
      value: option,
    }))}
  />
)

export default DividerStyleSelect
