import React from "react"
import { RegistrationFields } from "sharedTypes"
import { Control } from "react-hook-form"
import Field from "./Field"
import Fieldset from "./Fieldset"

type Props = {
  fields: RegistrationFields
  control: Control<any>
}

const RegistrationSettingsSwitches = ({ fields, control }: Props) => {
  const generateFields = (fieldType: string) => fields[fieldType].map(
    ({
      name, fieldName, readOnly, requiredLocked,
    }) => (
      <Field
        key={name}
        label={name}
        name={fieldName}
        control={control}
        disabled={readOnly}
        requiredLocked={requiredLocked}
      />
    ),
  )

  return (
    <>
      <Fieldset title="Profile Fields">
        {generateFields("personal")}
      </Fieldset>
      <Fieldset title="Address Fields">
        {generateFields("address")}
      </Fieldset>
    </>
  )
}

export default RegistrationSettingsSwitches
