import Select from "components/ReactHookForm/Select"
import { EMAIL_ALIGNMENT_OPTIONS } from "utilities/enums"
import _ from "lodash"
import React from "react"
import { Control, FieldValues } from "react-hook-form"

type Props<T extends FieldValues> = {
  control: Control<T, object>
  name?: string
}

const AlignmentSelect = <T extends FieldValues, >({
  control,
  name = "align",
}: Props<T>) => (
  <Select
    label="Alignment"
    name={name}
    control={control}
    options={EMAIL_ALIGNMENT_OPTIONS.map((option) => ({
      label: _.capitalize(option),
      value: option,
    }))}
  />
  )

export default AlignmentSelect
