import { useQuery } from "react-query"
import { AccountConfiguration, User } from "sharedTypes"
import { checkAuth } from "api/sessions"
import { authToken } from "services/authentication"
import { getConfiguration } from "api/accounts"

export const userCacheKey = () => "user"

// eslint-disable-next-line import/prefer-default-export
export const useCurrentUser = (): User => {
  const { data: user } = useQuery(
    userCacheKey(),
    () => checkAuth().then(({ data }) => data),
    {
      enabled: !!authToken(),
    },
  )

  return user || {} as User
}

export const useAccountConfiguration = () => {
  const { isLoading, data: accountConfiguration = {} as AccountConfiguration } = useQuery(
    "accountConfiguration",
    () => getConfiguration().then(({ data }) => data as AccountConfiguration),
    { staleTime: Infinity },
  )

  return { isLoading, accountConfiguration }
}
