import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import SyntaxHighlighter from "react-syntax-highlighter"
import CopyToClipboard from "components/elements/CopyToClipboard"
import "assets/styles/highlight.css"

type Props = {
  children: string;
  copiable?: boolean;
}

const CodeBlock = ({ children, copiable }: Props) => (
  <Flex align="center" fontSize="sm" my={2}>
    <Box flex={1}>
      <SyntaxHighlighter
        language="xml"
        wrapLongLines
      >
        {children}
      </SyntaxHighlighter>
    </Box>
    {copiable && (
      <CopyToClipboard
        text={children}
        variant="outline"
        colorScheme="button.light"
        size="xs"
        minWidth={20}
        ml={4}
      >
        Copy
      </CopyToClipboard>
    )}
  </Flex>
)

export default CodeBlock
