import React from "react"
import { Flex, Text } from "@chakra-ui/react"
import { asMoney, titleize } from "utilities/strings"
import Spinner from "components/elements/Spinner"

interface Props {
  loading: boolean
  planType: string
  interval: string
  upgradeAmount: number
  creditAmount: number
  amountDue: number
}

const Summary = ({
  loading,
  planType,
  interval,
  upgradeAmount,
  amountDue,
  creditAmount,
}: Props) => {
  if (loading) {
    return <Spinner heightFull />
  }

  return (
    <>
      <Text fontSize="2xl" color="blue.500" fontWeight="semibold" mb={3}>Summary</Text>
      <Flex justify="space-between" align="center" mb={1} fontSize="lg">
        <Text>Upgrade to {planType} - {titleize(interval)}ly Billing</Text>
        <Text>{asMoney(upgradeAmount)}</Text>
      </Flex>
      {creditAmount !== 0 && (
        <Flex justify="space-between" align="center" color="green.500" mb={1} fontSize="lg">
          <Text>Credit Applied</Text>
          <Text>-{asMoney(creditAmount)}</Text>
        </Flex>
      )}
      <Flex
        justify="space-between"
        align="center"
        mt={2}
        pt={2}
        fontSize="xl"
        borderTopWidth={3}
        color={amountDue <= 0 ? "green.500" : ""}
      >
        <Text>{amountDue > 0 ? "Total Charges Today" : "Free Upgrade"}</Text>
        <Text>{asMoney(amountDue)}
        </Text>
      </Flex>
    </>
  )
}

export default Summary
