import React from "react"
import _ from "lodash"
import { Control } from "react-hook-form"
import Select from "components/ReactHookForm/Select"

type Props = {
    name: string;
    control: Control<object>;
    label?: string;
}

const DropdownSelect = ({ name, control, label }: Props) => (
  <Select
    name={name}
    control={control}
    label={label}
    options={options}
    fullWidth
  />
)

type SelectOptionType = {
  label: string;
  value: string|number
}
const options: SelectOptionType[] = []
_.times(10, (index) => {
  const number = (index + 1)
  options.push({ label: number.toString(), value: number })
})

export default DropdownSelect
