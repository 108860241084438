import React from "react"
import { IconProps, Icon as ChakraIcon } from "@chakra-ui/react"

type Props = {
  icon: string
  size?: number
  alt?: string
} & IconProps

const Icon = ({ icon, size = 4, ...props }: Props) => (
  <ChakraIcon as={DioboxIcon} icon={icon} fontSize={`${size * 0.25}rem`} {...props} />
)

const DioboxIcon = ({ icon, ...props }) => (
  <svg
    style={{ fill: "currentColor" }}
    className={`icon icon-${icon}`}
    {...props}
  ><use xlinkHref={`#icon-${icon}`} />
  </svg>
)

export default Icon
