import { Seat } from "sharedTypes"
import { MenuItem } from "components/dialogs/Menu"
import React, { ReactNode } from "react"
import SeatItem from "./SeatItem"

type Props = {
  seat: Seat;
  onEditPlaceholder: (number: number) => void;
  onRemovePlaceholder: (number: number) => void;
  children: ReactNode;
  canEditSeating: boolean;
}

const BlockedSeatItem = (
  {
    seat: { number, description },
    onEditPlaceholder,
    onRemovePlaceholder,
    children,
    canEditSeating,
  }: Props,
) => (
  <SeatItem
    seatNumber={number}
    title={description || "Blocked"}
    label={children}
    description={description}
    labelColor="gray.300"
    menuItems={[
      <MenuItem onClick={() => { onEditPlaceholder(number) }} isCentered>
        Edit Placeholder
      </MenuItem>,
      <MenuItem color="red.500" onClick={() => { onRemovePlaceholder(number) }} isCentered>
        Remove Placeholder
      </MenuItem>,
    ]}
    canEditSeating={canEditSeating}
  />
)

export default BlockedSeatItem
