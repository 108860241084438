import React from "react"
import { useHistory } from "react-router-dom"
import SectionV2 from "components/layout/SectionV2"
import { Text } from "@chakra-ui/layout"
import Card from "components/layout/Card"

type Props = {
  title: string;
}

const Header = ({ title }: Props) => {
  const history = useHistory()

  return (
    <SectionV2
      title="Header"
      subtitle="Enter the event title or a header to display within the hero image."
      onEdit={() => { history.push("hero-section/edit-title-and-content") }}
    >
      <Card>
        <Text fontSize="2xl" fontWeight="semibold">{title}</Text>
      </Card>
    </SectionV2>
  )
}

export default Header
