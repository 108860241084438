import React, { useState } from "react"
import EventResourcesView from "components/layout/EventResourcesView"
import { useParams } from "react-router-dom"
import usePermissions from "services/permissions"
import { EventCounts, ModalName, Table as DioboxTable } from "sharedTypes"
import EventPage, { EventPageHeader } from "components/layout/EventPage"
import TablesHeader from "pages/EventShow/Seating/TablesHeader"
import _ from "lodash"
import Link from "components/elements/Link"
import { Box, Text } from "@chakra-ui/react"
import { useGuests } from "queries/guests"
import useModal from "services/useModal"
import Tables from "./Tables"
import ListHeader from "../Guests/ScrollView/GuestListHeader"
import GuestList from "../Guests/ScrollView/GuestList"
import Table from "./Table"
import NoTables from "./NoTables"

interface Props {
  eventId: string
  counts: EventCounts
  tables: DioboxTable[]
  maxTables: number|null
}

const Seating = ({
  eventId, counts, tables, maxTables,
}: Props) => {
  const { tableId } = useParams<{ tableId: string }>()
  const showModal = useModal()
  const [order, setOrder] = useState<{ field: string; order: "asc" | "desc" }>({ field: "name", order: "asc" })
  const [searchString, setSearchString] = useState("")
  const [guestsOrder, setGuestsOrder] = useState("first_name_asc")
  const overTablesLimit = maxTables && tables.length >= maxTables
  const { canEditSeating } = usePermissions()

  const {
    guests,
    fetchNextPage,
    isLoading,
  } = useGuests(eventId, searchString, guestsOrder)

  if (!tables.length) {
    return (
      <NoTables
        canEditSeating={canEditSeating}
        onClick={() => showModal(ModalName.TableForm)}
      />
    )
  }

  let table: DioboxTable | undefined
  if (tableId) {
    table = tables.find(({ id }) => id.toString() === tableId)
  }

  const extendedTables = tables.map((t) => {
    const seatCount = t.seats.length
    const waitlistedGuestCount = t.waitlistedGuests.length
    const vacantSeatCount = t.seats.filter(({ guest, reserved }) => !guest && !reserved).length

    return {
      ...t,
      seatCount,
      vacantSeatCount,
      waitlistedGuestCount,
    }
  })

  return (
    <Box h="full" overflow="hidden" position="relative">
      {table && (
        <Box position="absolute" top={0} right={0} bottom={0} left={0} zIndex={10} bg="white" overflowY="scroll">
          <Table table={table} />
        </Box>
      )}
      <EventPage
        header={(
          <EventPageHeader
            icon="seating"
            title="Seating"
            helpText={(
              <Text p={1}>
                After creating guests and tables, you can drag guests to seats.{" "}
                <Link isExternal href="https://home.d.io/tables-seating">Learn more about
                  seating.
                </Link>
              </Text>
              )}
            items={(
              <TablesHeader
                tableCount={counts.tables}
                seatCount={counts.seats}
                vacantSeatCount={counts.unassignedSeats}
                order={order}
                onChangeOrder={setOrder}
                canEditSeating={canEditSeating && !overTablesLimit}
              />
              )}
          />
          )}
        body={(
          <EventResourcesView
            showBorder={false}
            bgColor="gray.50"
            listHeader={(
              <ListHeader
                loading={isLoading}
                searchString={searchString}
                onSearch={(query) => setSearchString(query)}
                order={guestsOrder}
                onSetOrder={(value) => setGuestsOrder(value)}
              />
            )}
            listItems={(
              <GuestList
                guests={guests}
                showTables
                draggingEnabled={canEditSeating}
                onClickGuest={!canEditSeating}
                order={guestsOrder}
                filtered={!!searchString}
              />
              )}
            selectedItem={(
              <Tables
                tables={_.orderBy(extendedTables, order.field === "name" ? ({ name }) => name.toLowerCase() : order.field, order.order)}
                overTablesLimit={overTablesLimit}
              />
              )}
            onScrollEnd={fetchNextPage}
            listWidth="md"
          />
          )}
      />
    </Box>
  )
}

export default Seating
