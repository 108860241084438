import Modal from "components/dialogs/Modal"
import { Text } from "@chakra-ui/react"
import React from "react"
import UpgradeAlert from "components/elements/UpgradeAlert"

const EnrichDisabled = ({ onHide }) => (
  <Modal icon="enhance" title="Enrich Guest" show hide={onHide}>
    <Text mb={8}>
      Turn plain email addresses into rich contacts that are up-to-date
      with photos, names, companies, titles, and social links.
    </Text>
    <UpgradeAlert text="This feature requires a Corporate Subscription." />
  </Modal>
)

export default EnrichDisabled
