import React from "react"
import Menu, { MenuItem } from "components/dialogs/Menu"
import _ from "lodash"
import Button from "components/Buttons/Button"
import { asNumber } from "utilities/strings"
import pluralize from "pluralize"
import { ModalName } from "sharedTypes"
import HorizontalStat from "components/elements/HorizontalStat"
import { Box, Flex } from "@chakra-ui/react"
import useModal from "services/useModal"

type Props = {
  tableCount: number;
  seatCount: number;
  vacantSeatCount: number;
  order: {field: string, order: "asc"|"desc"};
  onChangeOrder: ({ field, order }) => void;
  canEditSeating: boolean;
}

const TablesHeader = ({
  tableCount, seatCount, vacantSeatCount, order, onChangeOrder, canEditSeating,
}: Props) => {
  const showModal = useModal()

  return (
    <>
      <HorizontalStat label={pluralize("Table", tableCount)} value={asNumber(tableCount)} />
      <HorizontalStat label={pluralize("Seat", seatCount)} value={asNumber(seatCount)} />
      <HorizontalStat label="Vacant" value={asNumber(vacantSeatCount)} />
      <Flex>
        <SortingDropdownMenu selected={order} onChange={onChangeOrder} />
        {canEditSeating && (
          <Box ml={4}>
            <Button leftIcon="plus" onClick={() => showModal(ModalName.TableForm)} size="sm">
              Table
            </Button>
          </Box>
        )}
      </Flex>
    </>
  )
}

type FilterDropdownMenuProps = {
  selected: { field, order };
  onChange: ({ field, order }) => void;
}

const SortingDropdownMenu = ({ selected, onChange }: FilterDropdownMenuProps) => {
  const sortingOptions = [
    { text: "Table Name", field: "name", order: "asc" },
    { text: "Table Size", field: "seatCount", order: "desc" },
    { text: "Number of Vacant Seats", field: "vacantSeatCount", order: "desc" },
    { text: "Number of Waitlisted Guests", field: "waitlistedGuestCount", order: "desc" },
  ]

  return (
    <Menu
      buttonText="View"
      menuItems={sortingOptions.map(({ text, field, order }) => (
        <MenuItem
          key={field}
          onClick={() => onChange({ field, order })}
          px={10}
          selected={_.isEqual(selected, { field, order })}
        >
          {text}
        </MenuItem>
      ))}
    />
  )
}

export default TablesHeader
