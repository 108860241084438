import React from "react"
import { ButtonProps } from "@chakra-ui/react"
import Button from "./Button"

type Props = {
  onClick: () => void
  size?: ButtonProps["size"]
}

const CancelButton = ({ onClick, size = "md" }: Props) => (
  <Button
    variant="outline"
    colorScheme="button.light"
    onClick={onClick}
    size={size}
  >
    Cancel
  </Button>
)

export default CancelButton
