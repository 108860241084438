import Icon from "components/elements/Icon"
import React from "react"
import { Transaction } from "sharedTypes"
import { asDate } from "utilities/strings"
import TextField from "components/ReactHookForm/TextField"
import Select from "components/ReactHookForm/Select"
import DatePickerField from "components/ReactHookForm/DatePickerField"
import TextArea from "components/ReactHookForm/TextArea"
import ActivityForm from "../Form"

type Props = {
  activity?: Transaction;
}

const Package = ({ activity }: Props) => {
  let initialValues
  if (activity) {
    initialValues = {
      transactionDate: asDate(activity.transactionDate, "yyyy-MM-dd"),
      type: activity.data.type,
      trackingNo: activity.data.trackingNo,
      details: activity.details,
    }
  } else {
    initialValues = {
      transactionDate: asDate(new Date(), "yyyy-MM-dd"),
      type: "Postal Invitation",
      trackingNo: "",
      details: "",
    }
  }

  return (
    <ActivityForm
      title={<><Icon icon="package" size={6} mr={2} /> Package</>}
      fields={(control) => (
        <>
          <DatePickerField
            name="transactionDate"
            control={control}
            label="Date"
            placeholder="Select Date"
          />
          <Select
            name="type"
            control={control}
            label="Type"
            options={[
              { label: "Postal Invitation", value: "Postal Invitation" },
              { label: "DHL", value: "DHL" },
              { label: "FedEx", value: "FedEx" },
              { label: "UPS", value: "UPS" },
              { label: "USPS", value: "USPS" },
              { label: "Pick Up", value: "Pick Up" },
              { label: "Messenger", value: "Messenger" },
              { label: "Other", value: "Other" },
            ]}
          />
          <TextField
            name="trackingNo"
            control={control}
            label="Tracking Number"
            placeholder="Enter tracking number"
          />
          <TextArea
            name="details"
            control={control}
            label="Details"
            placeholder="Additional details (optional)"
          />
        </>
      )}
      initialValues={initialValues}
      apiPayload={({
        transactionDate, details, type, trackingNo,
      }) => ({
        type: "PackageTransaction",
        transactionDate,
        details,
        data: { type, trackingNo },
      })}
      activity={activity}
    />
  )
}

export default Package
