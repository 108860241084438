import React from "react"
import { Link } from "react-router-dom"
import pluralize from "pluralize"
import { eventPath } from "utilities/routes"
import { Heading, Text } from "@chakra-ui/react"
import { asNumber, asDate } from "utilities/strings"
import CoverImage from "pages/EventsIndex/CoverImage"

type Props = {
  eventId: string
  title: string
  imageUrl: string
  startDate: string
  guestCount: number
}

const EventListItem = ({
  eventId, title, imageUrl, startDate, guestCount,
}: Props) => (
  <Link to={eventPath(eventId)}>
    <CoverImage
      url={imageUrl}
      startDate={startDate}
      mb={3}
    />
    <Heading fontSize="md" noOfLines={3} mb={1}>{ title }</Heading>
    <Text fontSize="sm">{ asDate(startDate, "PPP") }</Text>
    <Text fontSize="sm">
      { asNumber(guestCount) }
      {" "}
      { pluralize("guest", guestCount) }
    </Text>
  </Link>
)

export default EventListItem
