import React, { useEffect, useRef, useState } from "react"
import {
  Flex,
  FormControl,
  FormLabel,
  Box,
  HStack,
  useDisclosure,
  useOutsideClick,
  Text,
} from "@chakra-ui/react"
import { COLOR_SCHEMES } from "utilities/enums"
import { Control, useController } from "react-hook-form"
import ColorSelectPopover from "./ColorSelectPopover"
import ColorCircle from "./ColorCircle"

type Props = {
  name: string;
  control: Control<any>;
  label?: string;
  disabled?: boolean;
  error?: boolean;
  colorScheme?: "dark" | "light";
  compact?: boolean;
}

const ColorSelect = ({
  name, control, label, disabled, error, colorScheme = "dark", compact,
}: Props) => {
  const { field } = useController({ name, control })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const popoverRef = useRef(null)
  const [color, setColor] = useState(colorWithoutHash(field.value))

  useOutsideClick({
    ref: popoverRef,
    handler: onClose,
  })

  useEffect(() => {
    if (field.value !== colorWithHash(color) && colorIsValid(color)) {
      setColor(colorWithoutHash(field.value))
    }
  }, [field.value])

  const handleColorPickerChange = (newColor: string) => {
    if (colorIsValid(newColor)) {
      setColor(colorWithoutHash(newColor))
      field.onChange(colorWithHash(newColor))
    }
  }

  const handleTextInputChange = (newColor: string) => {
    setColor(colorWithoutHash(newColor))
    if (colorIsValid(newColor)) {
      field.onChange(colorWithHash(newColor))
    }
  }

  if (compact) {
    return (
      <FormControl isInvalid={error}>
        <Box>
          {label && <Text fontWeight="bold">{label}</Text>}
          <Flex ref={popoverRef} direction="column" align="start" w="100%" mt={4}>
            <ColorSelectPopover
              isOpen={isOpen}
              onOpen={onOpen}
              onColorPickerChange={handleColorPickerChange}
              onTextInputChange={handleTextInputChange}
              color={color}
              compact={compact}
            />
          </Flex>
        </Box>
      </FormControl>
    )
  }

  return (
    <FormControl isDisabled={disabled} mb={4} isInvalid={error}>
      <Flex>
        {label && <FormLabel pointerEvents="none" fontWeight="bold" w={1 / 5} flexShrink={0}>{label}</FormLabel>}
        <Flex direction="column" align="start" w="100%">
          <HStack>{COLOR_SCHEMES[colorScheme!].map((c) => (
            <ColorCircle
              key={c}
              color={c}
              selected={color === c}
              onSelect={handleColorPickerChange}
            />
          ))}
          </HStack>
          <Flex ref={popoverRef}>
            <ColorSelectPopover
              isOpen={isOpen}
              onOpen={onOpen}
              onColorPickerChange={handleColorPickerChange}
              onTextInputChange={handleTextInputChange}
              color={color}
              compact={compact}
            />
          </Flex>
        </Flex>
      </Flex>
    </FormControl>
  )
}

const colorIsValid = (color: string) => /^#?[0-9A-F]{6}$/i.test(color)

const colorWithHash = (color: string) => (color.startsWith("#") ? color : `#${color}`)

const colorWithoutHash = (color: string) => color.replace("#", "")

export default ColorSelect
