import React from "react"
import {
  ButtonBlock,
  DividerBlock,
  BuilderBlock,
  BuilderBlockType,
  HTMLBlock,
  HeadingBlock,
  ImageBlock,
  SpacerBlock,
  TextBlock, QRCodeBlock,
} from "sharedTypes/builder"
import Button from "components/Builder/Blocks/Button"
import Divider from "components/Builder/Blocks/Divider"
import Heading from "components/Builder/Blocks/Heading"
import HTML from "components/Builder/Blocks/HTML"
import Image from "components/Builder/Blocks/Image"
import Spacer from "components/Builder/Blocks/Spacer"
import QRCode from "components/Builder/Blocks/QRCode"
import Text from "components/Builder/Blocks/Text"
import { FormQuestion } from "sharedTypes"
import Question from "components/Builder/Blocks/Question/Question"

type BlockContentProps = {
  block: BuilderBlock
  hasPadding?: boolean
}

const BlockContent = ({ block, block: { type }, hasPadding = false }: BlockContentProps) => {
  switch (type) {
    case BuilderBlockType.BUTTON:
      return <Button block={block as ButtonBlock} />
    case BuilderBlockType.DIVIDER:
      return <Divider block={block as DividerBlock} />
    case BuilderBlockType.HEADING:
      return <Heading block={block as HeadingBlock} />
    case BuilderBlockType.HTML:
      return <HTML block={block as HTMLBlock} />
    case BuilderBlockType.IMAGE:
      return <Image block={block as ImageBlock} hasPadding={hasPadding} />
    case BuilderBlockType.SPACER:
      return <Spacer block={block as SpacerBlock} />
    case BuilderBlockType.QR_CODE:
      return <QRCode block={block as QRCodeBlock} />
    case BuilderBlockType.TEXT:
      return <Text block={block as TextBlock} />
    case BuilderBlockType.QUESTION:
      return <Question question={block.data as FormQuestion} />
    default:
      return <></>
  }
}

export default BlockContent
