import React, { ReactNode } from "react"
import {
  Heading, Image, Stack, Text,
} from "@chakra-ui/react"
import logo from "assets/images/diobox-icon.svg"

type Props = {
  heading: ReactNode
  description: ReactNode
  footer?: ReactNode
}

const FullScreenInfoView = ({ heading, description, footer }: Props) => (
  <Stack flexDirection="column" justify="center" align="center" h="100dvh" spacing={6}>
    <Image src={logo} mx="auto" />
    <Heading fontWeight="semibold" textAlign="center">{heading}</Heading>
    <Text fontSize="xl">{description}</Text>
    {footer}
  </Stack>
)

export default FullScreenInfoView
