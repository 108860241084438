import SubmitButton from "components/Buttons/SubmitButton"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { storeAuthToken, storeSupportLogin } from "services/authentication"
import { forgotPasswordPath, registrationPath } from "utilities/routes"
import { signIn } from "api/sessions"
import { Flex, Text, VStack } from "@chakra-ui/react"
import AuthenticationWrapper from "pages/Unauthenticated/AuthenticationWrapper"
import * as Yup from "yup"
import { handleAuthResponse } from "components/SessionHydrater"
import { useIntercom } from "react-use-intercom"
import Button from "components/Buttons/Button"
import { useQuery, useQueryClient } from "react-query"
import { userCacheKey } from "queries/users"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import PasswordField from "components/ReactHookForm/PasswordField"
import EmailField from "components/ReactHookForm/EmailField"

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
})

const Login = () => {
  const { boot: bootIntercom } = useIntercom()
  const [credentials, setCredentials] = useState<{email, password}>()
  const queryClient = useQueryClient()

  const { control, handleSubmit, formState: { isValid } } = useForm({
    resolver: yupResolver(loginSchema),
    mode: "onChange",
  })

  const { isFetching } = useQuery(
    userCacheKey(),
    credentials
      ? () => signIn(credentials.email, credentials.password).then(({ data }) => {
        storeAuthToken(data.authenticationToken)
        storeSupportLogin(data.supportLogin)
        handleAuthResponse(data, bootIntercom, queryClient)

        return data
      })
        .catch(() => {
          setCredentials(undefined)
        })
      : () => {},
    { enabled: !!credentials },
  )

  const onSubmit = (values) => {
    setCredentials(values)
  }

  return (
    <AuthenticationWrapper
      pageTitle="Sign In"
      body={(
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack w="full" mt={12} spacing={12}>
            <VStack w="full" spacing={3}>
              <EmailField placeholder="Email" name="email" control={control} />
              <PasswordField placeholder="Password" name="password" control={control} />
            </VStack>
            <VStack w="full" spacing={3}>
              <SubmitButton
                text="Login"
                submitting={isFetching}
                disabled={!isValid}
              />
              <Text
                textAlign="center"
                fontSize="sm"
                color="gray.600"
              ><Link to={forgotPasswordPath()}>Forgot password?</Link>
              </Text>
            </VStack>
          </VStack>
        </form>
      )}
      header={(
        <Flex
          h={8}
          color="gray.600"
          fontSize="sm"
          align="center"
        >
          <Text mr={6} display={["none", "none", "none", "block"]}>Don&apos;t have an account?</Text>
          <Button
            as={Link}
            size="sm"
            variant="outline"
            colorScheme="button.light"
            to={registrationPath()}
          >
            Get Started
          </Button>
        </Flex>
      )}
    />
  )
}

export default Login
