import {
  FormControl, FormLabel, Radio, RadioGroup, Stack, Box, Divider, Flex, Text, TypographyProps,
} from "@chakra-ui/react"
import React, { ReactNode } from "react"
import { Control, useController } from "react-hook-form"

export type RadioButtonsOption = {
  label: string|ReactNode;
  value: string;
  disabled?: boolean;
  additionalElement?: ReactNode
 }

type Props = {
  divider?: boolean;
  horizontal?: boolean;
  label?: string;
  labelSize?: TypographyProps["fontSize"];
  mb?: number;
  name: string;
  control: Control<any>;
  onChange?: (e) => void;
  options: RadioButtonsOption[];
  px?: number;
  spacing?: number;
  submitOnChange?: boolean
}

const RadioButtons = ({
  divider = false,
  horizontal = false,
  label,
  labelSize = "md",
  mb = 4,
  name,
  control,
  onChange = () => {},
  options,
  px = 0,
  spacing = 3,
  submitOnChange = false,
}: Props) => {
  const { field } = useController({ name, control })

  return (
    <FormControl mb={mb}>
      <Flex align="flex-start">
        {label
              && <FormLabel fontSize={labelSize} fontWeight="bold" w={1 / 4}>{label}</FormLabel>}
        <RadioGroup
          w="100%"
          value={field.value}
          onChange={(value) => {
            onChange(value)
            field.onChange(value)
          }}
        >
          <Stack
            align="center"
            direction={horizontal ? "row" : "column"}
            spacing={spacing}
            divider={divider ? <Divider /> : undefined}
          >
            {options.map((option) => (
              <>
                <Radio
                  variant={option.disabled ? "readonly" : undefined}
                  isDisabled={option.disabled}
                  key={option.value}
                  value={option.value}
                  w="100%"
                  px={px}
                  alignItems="flex-start"
                  onChange={(e) => {
                    if (submitOnChange && e.target.checked) {
                      e.target.form?.submit()
                    }
                  }}
                >
                  <Box ml={1} mt={horizontal ? "-3px" : "-6px"}>{option.label}</Box>
                </Radio>
                {option.additionalElement}
              </>
            ))}
          </Stack>
        </RadioGroup>
      </Flex>
    </FormControl>
  )
}

export const RadioOptionLabel = ({ title, description, disabled = false }) => (
  <Box mt={0.5}>
    <Text as="strong" opacity={disabled ? 0.4 : 1}>{title}</Text><br />
    {description}
  </Box>
)

export default RadioButtons
