import React from "react"
import {
  Box, Flex, Heading, List, ListItem,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { Control } from "react-hook-form"
import { DioboxEvent, Form, FormType } from "sharedTypes"
import ConnectToForm from "./ConnectToForm"

type Props = {
  onClick: () => void
  selected: boolean
  description: string
  features: string[]
  icon: string
  control: Control<any>
  event: DioboxEvent
  forms: Form[]
  formType? : FormType
}

const TemplateOption = ({
  onClick, selected, description, features, icon, control, event, forms, formType,
}: Props) => (
  <Box onClick={onClick} cursor="pointer">
    <Box
      borderWidth={1}
      mb={5}
      borderColor={selected ? "blue.400" : "inherit"}
      bg={selected ? "blue.50" : "gray.50"}
      borderRadius={5}
    >
      <Box p={3}>
        <Flex alignItems="center">
          <Icon icon={icon} size={8} ml={3} mr={5} />
          <Heading fontSize="lg" fontWeight="semibold">{description}</Heading>
        </Flex>
        <Box pl={16}>
          <List textAlign="left" my={4}>
            {features.map((feature) => <ListItem mb={2} key={feature}>{feature}</ListItem>)}
          </List>
        </Box>
      </Box>
      {selected && (
        <ConnectToForm
          control={control}
          formType={formType}
          forms={forms}
          event={event}
        />
      )}
    </Box>
  </Box>
)

export default TemplateOption
