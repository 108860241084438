import { GuestSummary } from "sharedTypes"
import { camelizeObject } from "./objects"

export const naturalSortBy = <T>(array: T[], sortBy: string): T[] => [...array].sort(
  (a: T, b: T) => a[sortBy].localeCompare(b[sortBy], undefined, {
    numeric: true,
    sensitivity: "base",
  }),
)

export const sortPlusOnes = (plusOnes: GuestSummary[]) => {
  const plusOnesWithDisplayName = plusOnes.map(
    (plusOne) => ({
      ...plusOne,
      displayName: `${plusOne.personInfo.firstName} ${plusOne.personInfo.lastName}`,
    }),
  )

  // Remove the added display name after the sorting to give back GuestSummary-type array
  return naturalSortBy(plusOnesWithDisplayName, "displayName").map(({ displayName, ...plusOne }) => plusOne)
}

export const textRecipientArrayToObject = (keysArray: string[]) => {
  const obj = {}

  keysArray.forEach((key) => {
    obj[key] = true
  })

  return { sendTo: camelizeObject(obj) }
}
