import React, { ReactNode } from "react"
import { useForm } from "react-hook-form"
import { useDeepCompareEffect } from "ahooks"

type Props = {
  defaultValues: any
  onSubmit: (values?: any) => (Promise<any>|void)
  children: ReactNode | ((formProps) => ReactNode)
}

const AutoSubmitForm = ({
  defaultValues,
  onSubmit,
  children,
}: Props) => {
  const form = useForm({
    defaultValues,
  })

  const { handleSubmit, watch } = form

  let resolvedChildren = children
  if (typeof children === "function") {
    resolvedChildren = children(form)
  }

  const values = watch()

  useDeepCompareEffect(() => {
    handleSubmit(onSubmit)()
  }, values)

  return (
    <>{resolvedChildren as ReactNode}</>
  )
}

export default AutoSubmitForm
