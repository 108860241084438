import React from "react"
import { Box, Text } from "@chakra-ui/react"

type Props = {
  text: string
}

const TextBubble = ({ text }: Props) => (
  <Box
    position="relative"
    borderRadius="2xl"
    bg="gray.200"
    p={4}
    maxW="85%"
    _after={{
      content: "\"\"",
      position: "absolute",
      bottom: 3,
      left: 0,
      border: "10px solid",
      borderColor: "transparent transparent transparent #E9E9E9",
      transform: "translateY(100%)",
    }}
  >
    <Text whiteSpace="pre-line">
      {text}
    </Text>
  </Box>
)

export default TextBubble
