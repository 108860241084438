import React, { useState } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import Popover from "components/dialogs/Popover"
import Icon from "components/elements/Icon"
import { CheckInLocation, CheckInTransaction, Transaction } from "sharedTypes"
import CheckInListItem from "./CheckInListItem/CheckInListItem"
import { defaultStyles, inverseStyles } from ".."

type Props = {
  transactions: Transaction[]
  timezone: string
  checkIn: (guestId: string) => void
  guestId: string
  checkInLocationsWithCheckInOption: (CheckInLocation | { id: null; name: string})[]
  checkInLocationId: number|null
  eventId: string
}
const CheckInButton = ({
  transactions,
  timezone,
  checkIn,
  guestId,
  checkInLocationsWithCheckInOption,
  checkInLocationId,
  eventId,
}:Props) => {
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)
  const checkIns = transactions.filter(
    ({ eventId: eId, type }) => type === "AttendanceTransaction" && eId === eventId,
  ) as CheckInTransaction[]
  const selfCheckIns = checkIns.filter(({ guestOfGuest }) => !guestOfGuest)
  const selfCheckInsCount = selfCheckIns.length

  const autoCheckIn = () => {
    if (popoverIsOpen) {
      setPopoverIsOpen(false)

      return
    }

    if (!selfCheckInsCount) {
      checkIn(guestId)

      return
    }

    setPopoverIsOpen(true)
  }

  const checkInLocationName = checkInLocationsWithCheckInOption.find(
    ({ id }) => id === checkInLocationId,
  )?.name

  return (
    <Popover
      isOpen={popoverIsOpen}
      onClose={() => setPopoverIsOpen(false)}
      header="Guest Activity"
      contentWidth={400}
      trigger={(
        <Flex
          rel="button"
          align="center"
          justify="center"
          w={44}
          h={16}
          borderColor={selfCheckInsCount ? "white" : "green.500"}
          borderWidth={selfCheckInsCount ? "0px" : "2px"}
          borderRadius="full"
          overflow="hidden"
          {...selfCheckInsCount ? defaultStyles : inverseStyles}
          title="Check-In"
          fontSize="lg"
          fontWeight="semibold"
          onClick={autoCheckIn}
          px={4}
        >
          <Text color={selfCheckInsCount ? "white" : "green.500"} noOfLines={2}>{checkInLocationName}</Text>
          {!!selfCheckInsCount && <Icon icon="dropdown" ml={2} />}
        </Flex>
    )}
      body={(
        <>
          <Box py={4}>
            {checkIns.map((checkInItem) => (
              <CheckInListItem
                checkInItem={checkInItem}
                timezone={timezone}
                checkInLocationsWithCheckInOption={checkInLocationsWithCheckInOption}
                onDelete={() => setPopoverIsOpen(false)}
              />
            ))}
          </Box>
          {!!selfCheckInsCount && (
            <Flex
              role="button"
              align="center"
              w="full"
              px={3}
              py={4}
              borderTopWidth="1px"
              fontSize="2xl"
              color="green.500"
              justify="center"
              onClick={() => checkIn(guestId)}
            >
              <Icon icon="add-solid" size={10} mr={2} /> {checkInLocationName}
            </Flex>
          )}
        </>
    )}
      zIndex={9}
      gutter={32}
    />
  )
}

export default CheckInButton
