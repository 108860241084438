import React, { forwardRef } from "react"
import ReactPhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import { Control, FieldValues, useController } from "react-hook-form"
import { Box, Input } from "@chakra-ui/react"

type Props<T extends FieldValues> = {
  control: Control<T, object>;
  name: string;
  placeholder?: string;
};

const PhoneNumberField = <T extends FieldValues, >({
  control,
  name,
  placeholder,
}: Props<T>) => {
  const { field } = useController<any>({ name, control })

  return (
    <Box mb={4}>
      <ReactPhoneInput
        {...field}
        placeholder={placeholder}
        onChange={(value) => field.onChange(value)}
        inputComponent={CustomInput}
        international
      />
    </Box>
  )
}

const CustomInput = forwardRef<HTMLInputElement>((props, ref) => (
  <Input ref={ref} {...props} />
))

export default PhoneNumberField
