import { Flex, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import Link from "components/elements/Link"
import React from "react"

export type Props = {
  description: string
  done?: boolean
  path?: string
  onClick?: () => void
}

const Step = ({
  description, done, path, onClick,
} : Props) => {
  const styleProps = { ml: 3, color: "black" }

  return (
    <Flex align="center" fontWeight="semibold">
      {
        done
          ? <Icon icon={done ? "checkin-filled" : "circle"} color="green.400" size={4} />
          : <Icon icon="circle" color="gray.400" size={4} />
      }
      {
        path || onClick
          ? (
            <Link
              {...path ? { to: path } : {}}
              {...onClick ? { onClick } : {}}
              {...styleProps}
            >
              {description}
            </Link>
          )
          : <Text {...styleProps}>{description}</Text>
      }
    </Flex>
  )
}

export default Step
