import React, { ReactNode } from "react"
import { Box, SpaceProps } from "@chakra-ui/react"

type Props = {
  children: ReactNode | ReactNode[]
  grow?: boolean
  pt?: SpaceProps["paddingTop"]
}

const EditorSection = ({ children, grow, pt = 6 }: Props) => (
  <Box
    pt={pt}
    pb={2}
    px={6}
    borderBottomWidth={2}
    flexGrow={grow ? 1 : 0}
    textAlign="left"
  >{children}
  </Box>
)

export default EditorSection
