import React, { useEffect, useState } from "react"
import EventPage, { EventPageHeader } from "components/layout/EventPage"
import {
  Route, Switch, useLocation, useParams,
} from "react-router-dom"
import { eventGuestPortalPath } from "utilities/routes"
import NavigationTabButton from "components/Navigation/HeaderTabs/NavigationTabButton"
import {
  Account, AccountConfiguration, AccountUser, DioboxEvent, TabButton, GuestPortalSubpath,
} from "sharedTypes"
import HeaderTabs from "components/elements/HeaderTabs"
import General from "./General/General"
import Branding from "./Branding/Branding"
import Terminology from "./Terminology/Terminology"
import EmailResponders from "./EmailResponders/EmailResponders"
import SharePopover from "./SharePopover"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  accountConfiguration: AccountConfiguration
  account: Account
  accountUsers: AccountUser[]
}

const GuestPortal = ({
  event: { id: eventId },
  event,
  updateEvent,
  accountConfiguration: { translations: defaultTranslations },
  account,
  accountUsers,
}: Props) => {
  const location = useLocation()
  const { subpath } = useParams<{ subpath: string }>()
  const [eventPreview, setEventPreview] = useState(event)

  useEffect(() => {
    setEventPreview(event)
  }, [event.portalEventImageUrl, event.portalLogoUrl])

  const tabs = [
    { id: 0, title: "General", iconName: "guest-search-settings" },
    {
      id: 1, title: "Branding", subpath: GuestPortalSubpath.Branding, iconName: "branding-outline",
    },
    {
      id: 2, title: "Terminology", subpath: GuestPortalSubpath.Terminology, iconName: "terminology-outline",
    },
    {
      id: 3, title: "Email Responders", subpath: GuestPortalSubpath.EmailResponders, iconName: "email-responders-outline",
    },
  ]

  // If there's no active tab, default to the first tab
  const activeTab = tabs.find((tab) => tab.subpath === subpath) ?? tabs[0]

  const handleChangeEventPreview = (values: Partial<DioboxEvent>) => setEventPreview(
    (oldEventPreview) => ({ ...oldEventPreview, ...values }),
  )

  return (
    <EventPage
      header={(
        <EventPageHeader
          icon="guest-portal"
          title="Guest Portal"
          subtitle={<HeaderNav eventId={eventId} tabs={tabs} activeTab={activeTab} />}
          items={<SharePopover event={event} />}
        />
      )}
      body={(
        <Switch location={location}>
          <Route path={`${eventGuestPortalPath(eventId, GuestPortalSubpath.Branding)}`}>
            <Branding
              event={event}
              account={account}
              onChangeEventPreview={handleChangeEventPreview}
              updateEvent={updateEvent}
              eventPreview={eventPreview}
              defaultTranslations={defaultTranslations}
            />
          </Route>
          <Route path={`${eventGuestPortalPath(eventId, GuestPortalSubpath.Terminology)}`}>
            <Terminology
              event={event}
              updateEvent={updateEvent}
              defaultTranslations={defaultTranslations}
            />
          </Route>
          <Route path={`${eventGuestPortalPath(eventId, GuestPortalSubpath.EmailResponders)}`}>
            <EmailResponders accountUsers={accountUsers} updateEvent={updateEvent} />
          </Route>
          <Route path={`${eventGuestPortalPath(eventId)}`}>
            <General
              event={event}
              updateEvent={updateEvent}
              eventPreview={eventPreview}
              account={account}
            />
          </Route>
        </Switch>
      )}
    />
  )
}

type HeaderNavProps = {
  eventId: string
  tabs: TabButton<GuestPortalSubpath>[]
  activeTab?: TabButton<GuestPortalSubpath>
}

const HeaderNav = ({ eventId, tabs, activeTab }: HeaderNavProps) => (
  <HeaderTabs>
    {tabs.map(({
      id, title, subpath, iconName,
    }) => (
      <NavigationTabButton
        key={subpath}
        title={title}
        iconName={iconName}
        active={activeTab?.id === id}
        path={eventGuestPortalPath(eventId, subpath)}
      />
    ))}
  </HeaderTabs>
)

export default GuestPortal
