import React from "react"
import { Website } from "sharedTypes"
import { Route, useHistory, useRouteMatch } from "react-router-dom"
import { successToast } from "utilities/toasts"
import { Stack } from "@chakra-ui/react"
import useWebsiteUrl from "utilities/useWebsiteUrl"
import EventImage from "./EventImage"
import EditHeader from "./Header/EditHeader"
import Template from "./Template"
import Sidebar from "./Sidebar/Sidebar"
import Header from "./Header/Header"

type Props = {
  website: Website;
  updateWebsite: (params: {}) => Promise<void>;
}

const HeroSection = ({ website, updateWebsite }: Props) => {
  const { path } = useRouteMatch()
  const history = useHistory()

  const updateWebsiteWithToastFeedback = (params) => updateWebsite(params)
    .then(() => successToast({ title: "Hero Section updated" }))

  const {
    coverImageUrl,
    title,
    templateId,
  } = website

  return (
    <Stack spacing={12}>
      <Template
        websiteUrl={useWebsiteUrl(website)}
        updateWebsite={updateWebsite}
        templateId={templateId || 1}
      />
      <EventImage
        coverImageUrl={coverImageUrl}
      />
      <Header title={title} />
      <Sidebar website={website} updateWebsiteWithToastFeedback={updateWebsiteWithToastFeedback} />
      <Route path={`${path}/edit-title-and-content`}>
        <EditHeader
          title={title}
          updateWebsite={updateWebsiteWithToastFeedback}
          onClose={() => { history.go(-1) }}
        />
      </Route>
    </Stack>
  )
}

export default HeroSection
