import React, { ReactNode } from "react"
import Link from "components/elements/Link"
import { eventTextMessagePath } from "utilities/routes"
import { DioboxEvent, TextActivityItem } from "sharedTypes"
import Status from "pages/EventShow/Guests/SelectedGuest/Show/ActivityList/Item/Invitation/Status"
import _ from "lodash"
import { asDateWithTZ, generateFormatStringWithYesterdayAndToday } from "utilities/strings"
import Popover from "./Popover"
import {
  ActivityPopoverContent, ActivityPopoverDeleteTransaction,
  ActivityPopoverRow, ActivityPopoverSection,
} from "./ActivityPopover"

type Props = {
  trigger: ReactNode
  header: string
  item: TextActivityItem
  event: DioboxEvent
}

const TextActivityPopup = ({
  trigger,
  header,
  item,
  item: {
    textId,
    eventId,
    userId,
    title,
    date,
    data: {
      to,
      from,
      status,
    },
  },
  event: { timezone, users },
}: Props) => {
  const { user } = users.find(({ user: { id } }) => id === userId) || {}
  const sentBy = user ? `${user.firstName} ${user.lastName}` : "Unknown"

  return (
    <Popover
      placement="top-end"
      trigger={trigger}
      header={header}
      body={(
        <ActivityPopoverContent>
          <ActivityPopoverSection>
            <ActivityPopoverRow
              label="Name"
              value={<Link to={eventTextMessagePath(eventId, textId)}>{title}</Link>}
            />
          </ActivityPopoverSection>
          <ActivityPopoverSection>
            <ActivityPopoverRow
              label="Date/Time"
              value={asDateWithTZ(date, timezone, generateFormatStringWithYesterdayAndToday(date))}
            />
            <ActivityPopoverRow label="Sent by" value={sentBy} />
          </ActivityPopoverSection>
          <ActivityPopoverSection>
            <ActivityPopoverRow label="To" value={to} />
            <ActivityPopoverRow label="From" value={from} />
            <ActivityPopoverRow
              label="Status"
              value={(
                <Status
                  text={_.capitalize(status)}
                  color={`textStatus.${status}`}
                />
              )}
            />
          </ActivityPopoverSection>
          <ActivityPopoverDeleteTransaction item={item} />
        </ActivityPopoverContent>
      )}
    />
  )
}

export default TextActivityPopup
