import React from "react"
import Rating from "react-star-ratings"
import { Box, FormLabel } from "@chakra-ui/react"
import { Control, useController } from "react-hook-form"

type Props = {
  name: string;
  control: Control<any>;
  label?: string;
}

const RatingField = ({ name, control, label }: Props) => {
  const { field } = useController({ name, control })

  return (
    <Box mb={3}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Rating
        rating={Number(field.value)}
        changeRating={(rating) => {
          field.onChange(rating)
        }}
        numberOfStars={5}
        starRatedColor="gold"
        starHoverColor="gold"
        starDimension="2em"
        starSpacing="0.25em"
      />
    </Box>
  )
}

export default RatingField
