import DividerStyleSelect from "components/Builder/components/DividerStyleSelect"
import EditorSection from "components/Builder/components/EditorSection"
import PaddingInputs from "components/Builder/components/PaddingInputs"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import ColorSelect from "components/ReactHookForm/ColorSelect/ColorSelect"
import NumberField from "components/ReactHookForm/NumberField"
import React from "react"
import { Control } from "react-hook-form"

type Props = {
  control: Control
}

const DividerFields = ({ control }: Props) => (
  <>
    <SidePanelHeader>Divider Block</SidePanelHeader>
    <EditorSection>
      <DividerStyleSelect control={control} />
    </EditorSection>
    <EditorSection>
      <NumberField label="Thickness" name="size" control={control} min={1} />
    </EditorSection>
    <EditorSection>
      <PaddingInputs control={control} />
    </EditorSection>
    <EditorSection>
      <ColorSelect label="Line Color" name="lineColor" control={control} />
    </EditorSection>
    <EditorSection>
      <ColorSelect label="Block Color" name="blockColor" control={control} colorScheme="light" />
    </EditorSection>
  </>
)

export default DividerFields
