import React from "react"
import Icon from "components/elements/Icon"
import { FormSubmissionLineItem, FormSubmissionLineItemResponse, FormType } from "sharedTypes"
import { Box, Text } from "@chakra-ui/react"
import { formIconAndType } from "utilities/forms"
import { asMoney } from "utilities/strings"
import FormSubmissionLineItemActivityPopup from "components/dialogs/FormSubmissionLineItemActivityPopup"
import { useParams } from "react-router-dom"
import FormSubmissionResponses from "./FormSubmissionResponses"

type Props = {
  item: FormSubmissionLineItem
  timeZone: string
  eventId?: string
}

const FormSubmission = ({
  item: {
    externalId: formSubmissionLineItemExternalId,
    formType,
    name,
    price,
    currency,
    formSubmission: { externalId: formSubmissionExternalId, paymentMethod, paymentDetails },
    responses,
    createdAt,
  },
  timeZone,
  eventId,
}: Props) => {
  const { icon } = formIconAndType(formType)
  const { guestId } = useParams<{ guestId: string }>()

  const isTicket = formType === FormType.Ticket
  const priceString = (price && isTicket ? `${asMoney(price, currency)}` : "")
  const formSubmissionName = `${name} ${priceString}`
  const header = `${formType} ${priceString}`
  const questions = formatResponsesArray(responses)

  return (
    <>
      <FormSubmissionLineItemActivityPopup
        trigger={(
          <>
            <Icon
              size={6}
              icon={icon}
              mr={2}
              color="black"
            />
            <Text as="span" color="gray.900">{formSubmissionName}</Text>
          </>
        )}
        header={header}
        guestId={guestId}
        formType={formType}
        formSubmissionName={formSubmissionName}
        isTicket={isTicket}
        externalId={formSubmissionLineItemExternalId}
        formSubmissionExternalId={formSubmissionExternalId}
        eventId={eventId}
        payment={paymentDetails ?? paymentMethod}
        createdAt={createdAt}
        timeZone={timeZone}
      />
      <Box pl={7}>
        {questions.map(({ question, response }) => (
          <Box mt={3}>
            <Box dangerouslySetInnerHTML={{ __html: question }} />
            {response && <FormSubmissionResponses response={response} />}
          </Box>
        ))}
      </Box>
    </>
  )
}

type QuestionWithArrayResponse = {
  question: string;
  response: string[];
}

const formatResponsesArray = (
  responses: FormSubmissionLineItemResponse[],
) => responses.map((questionObj) => {
  if (typeof questionObj.response === "string") {
    // Convert string response to a single-element array
    return {
      question: questionObj.question,
      response: [questionObj.response],
    }
  }

  return questionObj as QuestionWithArrayResponse
})

export default FormSubmission
