import { Box } from "@chakra-ui/react"
import {
  TableCircle, TableHalfRound, TableRectangle, TableRow, TableSquare,
} from "dio-tables"
import React, { memo, useEffect, useState } from "react"
import { Seat, TableShapes } from "sharedTypes"
import { decamelizeKeys } from "humps"
import SimpleBar from "simplebar-react"

type GeneralTable = {
  id: string;
  shape: TableShapes;
  topSeats: number;
  bottomSeats?: number;
  leftSeats?: number;
  rightSeats?: number;
  seatsData?: Seat[];
  editing?: boolean;
  onResetSeat?: (number) => void;
  onAssignGuest?: (number) => void;
  onMoveToWaitlist?: (number) => void;
  onAddPlaceholder?: (number) => void;
}

type Props = GeneralTable

const SeatingTable = (props: Props) => {
  const [containerWidth, setContainerWidth] = useState(0)

  const {
    id,
    onResetSeat = () => {},
    onAssignGuest = () => {},
    onMoveToWaitlist = () => {},
    onAddPlaceholder = () => {},
    shape,
    topSeats,
    rightSeats = 0,
    bottomSeats = 0,
    leftSeats = 0,
    editing = false,
    seatsData,
  } = props

  const containerId = `table-container-${id}`

  const onClickMenuItem = ({ data: { number: seatNumber } }, { name: action }) => {
    if (action === "ItemRemove" && onResetSeat) {
      onResetSeat(seatNumber)
    } else if (action === "ItemAddGuest" && onAssignGuest) {
      onAssignGuest(seatNumber)
    } else if (action === "ItemMoveGuest" && onMoveToWaitlist) {
      onMoveToWaitlist(seatNumber)
    } else if (action === "ItemAddPlaceholder" && onAddPlaceholder) {
      onAddPlaceholder(seatNumber)
    }
  }

  useEffect(() => {
    let table

    switch (shape) {
      case TableShapes.Circle:
        table = new TableCircle(containerId)
        break
      case TableShapes.Row:
        table = new TableRow(containerId)
        break
      case TableShapes.HalfRound:
        table = new TableHalfRound(containerId)
        table.bottomSeatsNumber = bottomSeats
        break
      case TableShapes.Square:
        table = new TableSquare(containerId)
        table.rightSeatsNumber = rightSeats
        table.bottomSeatsNumber = bottomSeats
        table.leftSeatsNumber = leftSeats
        break
      case TableShapes.Rectangle:
        table = new TableRectangle(containerId)
        table.rightSeatsNumber = rightSeats
        table.bottomSeatsNumber = bottomSeats
        table.leftSeatsNumber = leftSeats
        break
      default:
        throw Error("Table shape doesn't exist.")
    }

    table.topSeatsNumber = topSeats
    table.editMode = editing
    table.onMenuItemClick = onClickMenuItem

    if (seatsData) {
      table.seatsData = decamelizeKeys(seatsData)
    }

    table.render()
    setContainerWidth(table.stage.getAttr("width"))
  })

  return (
    <Box as={SimpleBar} maxW="100%">
      <Box id={containerId} w={containerWidth} mx="auto" />
    </Box>
  )
}

export default memo(SeatingTable)
