import React, { ReactNode } from "react"
import {
  Flex, Box, Text, Heading,
} from "@chakra-ui/react"
import Button from "../Buttons/Button"

type Props = {
  title: string | ReactNode
  subtitle?: string | ReactNode
  children?: any
  onEdit?: () => void
}

const SectionV2 = ({
  title, subtitle, children, onEdit,
}: Props) => (
  <Box>
    <Flex justifyContent="space-between" mb={2}>
      <Heading mb={2} fontSize="lg">{title}</Heading>
      {onEdit && <Button variant="outline" size="sm" onClick={onEdit}>Edit</Button>}
    </Flex>
    <Box>
      <Text mb={6}>{subtitle}</Text>
      <Box w="full" fontSize="xl">{children}</Box>
    </Box>
  </Box>
)

export default SectionV2
