import React, { ReactNode } from "react"
import {
  Box, Flex, List, ListItem, Text,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { asDateWithTZ } from "utilities/strings"

type TimelineProps = {
  children: ReactNode
}

export const Timeline = ({ children }: TimelineProps) => (
  <List pt={6}>{children}</List>
)

export default Timeline

type TimelineItemProps = {
  children: ReactNode
  date: string;
  timezone: string;
  showTime: boolean;
  showConnector: boolean;
}

export const TimelineItem = ({
  children, date, timezone, showTime, showConnector,
}: TimelineItemProps) => (
  <ListItem>
    <Flex textColor="gray.500">
      <Box minW={20} textAlign="right" fontSize="sm" pt={0.5} flex="0 0 25%">
        <Text>{asDateWithTZ(date, timezone, "PP")}</Text>
        {showTime && <Text>{asDateWithTZ(date, timezone, "h:mm a")}</Text>}
      </Box>
      <Flex direction="column" align="center" w={10} flex="0 0">
        <Icon icon="dot" size={2.5} m={2} color="gray.400" />
        {showConnector && <Box flexGrow={1} borderLeftWidth={1} borderColor="gray.400" my={-1.5} />}
      </Flex>
      <Box pb={12}>{children}</Box>
    </Flex>
  </ListItem>
)
