import React, { ReactNode } from "react"
import Menu, { MenuDivider, MenuItem } from "components/dialogs/Menu"

type Props = {
  button: string | ReactNode
  order: string
  onSetOrder: (order) => void
  allSelected?: boolean
  toggleSelectAll?: () => void
}

const GuestsMenu = ({
  button, order, onSetOrder, allSelected, toggleSelectAll,
}: Props) => {
  const sortingItems = [
    {
      label: "First Name",
      id: "first_name_asc",
    }, {
      label: "Last Name",
      id: "last_name_asc",
    },
  ].map(({ label, id }) => (
    <MenuItem
      key={id}
      onClick={() => onSetOrder(id)}
      selected={order === id}
      px={10}
    >
      Sort by {label}
    </MenuItem>
  ))

  const selectAll = toggleSelectAll ? [
    <MenuItem
      key="select-all"
      onClick={toggleSelectAll}
      px={10}
    >
      {allSelected ? "Clear Selection" : "Select All"}
    </MenuItem>,
    <MenuDivider />,
  ] : []

  return (
    <Menu
      button={button}
      title="Options"
      placement="bottom-end"
      gutter={16}
      menuItems={[
        ...selectAll,
        ...sortingItems,
      ]}
    />
  )
}

export default GuestsMenu
