import React from "react"
import { Text, Box, Flex } from "@chakra-ui/react"
import { asDateWithTZ } from "utilities/strings"
import { useCurrentEvent } from "queries/events"
import { DioboxEvent, FormStatus, FormType } from "sharedTypes"
import Button from "components/Buttons/Button"
import { useHistory } from "react-router"
import { eventSettingsPath } from "utilities/routes"
import { formStatusLabel } from "utilities/forms"

type Props = {
    submissionsEndAt: string
    status: FormStatus
    formType: FormType
    color: string
    event: DioboxEvent
}

const Status = ({
  submissionsEndAt, status, formType, color, event,
}: Props) => {
  const { data: { timezone } } = useCurrentEvent()
  const history = useHistory()

  const formattedSubmissionsEndAt = submissionsEndAt
    ? asDateWithTZ(submissionsEndAt, timezone)
    : null

  if (status === FormStatus.GatewayNotConnected) {
    return (
      <Box pt={5}>
        <Text fontSize="2xl" color="status.inactive" lineHeight={10}>Connect to Payment Gateway</Text>
        <Text mb={2}>
          To process credit cards, connect your Stripe account or create a free account
        </Text>
        <Button
          size="sm"
          onClick={() => history.push(eventSettingsPath(event.id, "ticketing-payments"))}
        >Connect
        </Button>
      </Box>
    )
  }

  let text = ""

  if (status === FormStatus.Published) {
    text = "Until"
  }

  if (status === FormStatus.Ended) {
    text = "on"
  }

  return (
    <Flex justifyContent="flex-end" alignItems="flex-end">
      <Box mr={4}><Text fontSize="2xl" color={color} lineHeight={4}>{formStatusLabel(status, formType)}</Text></Box>
      {status !== FormStatus.Disabled && submissionsEndAt && <Text lineHeight={3} fontSize="md">{text} {formattedSubmissionsEndAt}</Text>}
    </Flex>
  )
}

export default Status
