import { useLocation } from "react-router-dom"
import {
  GuestPortalHash, GuestPortalSubpath, SubmissionsSubpath,
} from "sharedTypes"
import useGuestsStore from "services/useGuestsStore"
import { shallow } from "zustand/shallow"

export const useQuery = () => new URLSearchParams(useLocation().search)

export const accountPath = () => "/account"
export const accountPlanPath = () => "/account/plan"
export const accountTeamPath = () => "/account/team"
export const accountTeamMemberPath = (teamMemberId: string) => `/account/team/members/${teamMemberId}`
export const upgradePath = (plan: string | null = null) => `/upgrade${plan ? `?plan=${plan}` : ""}`
export const eventsPath = () => "/events"
export const eventsFuturePath = () => "/events/future"
export const eventsPastPath = () => "/events/past"
export const eventPath = (eventId: string) => `/events/${eventId}`
export const eventEmailsPath = (eventId: string) => `${eventPath(eventId)}/emails`
export const eventEmailPath = (eventId: string, emailId: number) => `${eventEmailsPath(eventId)}/${emailId}`
export const eventEmailSetupPath = (eventId: string, emailId: number) => `${eventEmailsPath(eventId)}/${emailId}/setup`
export const eventEmailRecipientsPath = (eventId: string, emailId: number) => `${eventEmailsPath(eventId)}/${emailId}/recipients`
export const eventEmailEmailPath = (eventId: string, emailId: number) => `${eventEmailsPath(eventId)}/${emailId}/email`
export const eventEmailReviewPath = (eventId: string, emailId: number) => `${eventEmailsPath(eventId)}/${emailId}/review`
export const eventEmailReportPath = (eventId: string, emailId: number) => `${eventEmailsPath(eventId)}/${emailId}/report`
export const eventTablePath = (eventId: string, tableId: number) => `/events/${eventId}/seating/tables/${tableId}`
export const eventEditTablePath = (eventId: string, tableId: number) => `/events/${eventId}/seating/tables/${tableId}/edit`
export const eventSeatingPath = (eventId: string) => `${eventPath(eventId)}/seating`
export const eventFormsPath = (eventId: string) => `${eventPath(eventId)}/forms`
export const eventFormPath = (eventId: string, formId: string) => `${eventFormsPath(eventId)}/${formId}`
export const eventEditFormPath = (eventId: string, formId: string) => `${eventFormPath(eventId, formId)}/edit`
export const eventWebsitePath = (eventId: string, subPath?: "hero-section"|"button") => `${eventPath(eventId)}/website${subPath ? `/${subPath}` : ""}`
export const eventSettingsPath = (eventId: string, subPath?: "email-notifications"|"ticketing-payments"|"event-activities") => `${eventPath(eventId)}/settings${subPath ? `/${subPath}` : ""}`
export const eventSubmissionsPath = (eventId: string, subPath?: SubmissionsSubpath) => `${eventPath(eventId)}/submissions${subPath ? `/${subPath}` : ""}`
export const eventSubmissionPath = (eventId: string) => `${eventPath(eventId)}/submissions`
export const eventQuestionsPath = (eventId: string) => `${eventPath(eventId)}/submissions/questions`
export const eventDataCollectionFormSubmissionPath = (eventId: string, submissionId: string) => `${eventSubmissionsPath(eventId)}/${submissionId}`
export const eventDataCollectionQuestionPath = (eventId: string, questionId: string) => `${eventQuestionsPath(eventId)}/${questionId}`
export const eventGuestPortalPath = (eventId: string, subPath?: GuestPortalSubpath, hash?: GuestPortalHash) => `${eventPath(eventId)}/guest-portal${subPath ? `/${subPath}` : ""}${hash ? `#${hash}` : ""}`
export const eventTextingPath = (eventId: string) => `${eventPath(eventId)}/texts`
export const eventTextMessagePath = (eventId: string, textMessageId: string) => `${eventTextingPath(eventId)}/${textMessageId}`
export const eventTextMessageReportPath = (eventId: string, textMessageId: string) => `${eventTextingPath(eventId)}/${textMessageId}/report`
export const loginPath = () => "/login"
export const registrationPath = () => "/register"
export const completeRegistrationPath = (plan: string | null = null) => `/register/complete${plan ? `?plan=${plan}` : ""}`
export const forgotPasswordPath = () => "/passwords/new"
export const resetPasswordPath = () => "/users/password/edit"
export const confirmEmailPath = () => "/users/confirmation"
export const iOSAppURL = "https://itunes.apple.com/us/app/diobox/id889676302"
export const AndroidAppURL = "https://play.google.com/store/apps/details?id=com.diobox.rn.android"

export const useEventGuestPaths = () => {
  const {
    searchString: q, groupId, segmentId,
  } = useGuestsStore(
    (s) => s, shallow,
  )

  const basePath = (eventId: string) => `${eventPath(eventId)}/guests`
  const filter = () => {
    if (groupId) {
      return `groupId=${encodeURIComponent(groupId)}`
    }
    if (segmentId) {
      return `segmentId=${encodeURIComponent(segmentId)}`
    }
    if (q) {
      return `q=${encodeURIComponent(q)}`
    }

    return null
  }

  const guestsPath = (eventId: string, queryParams?: string) => {
    const filterString = queryParams ?? filter()
    if (filterString) {
      return `${basePath(eventId)}?${filterString}`
    }

    return basePath(eventId)
  }

  const guestPath = (eventId: string, guestId: string) => {
    const filterString = filter()
    if (filterString) {
      return `${basePath(eventId)}/${guestId}?${filterString}`
    }

    return `${basePath(eventId)}/${guestId}`
  }

  return {
    eventGuestsPath: guestsPath,
    eventGuestPath: guestPath,
  }
}
