import {
  ListItem, Text, Image, UnorderedList, Box,
} from "@chakra-ui/react"
import Link from "components/elements/Link"
import React from "react"

type Props = {
  response: string[] | {file: string, type: string, url: string}
}

const FormSubmissionResponses = ({ response }: Props) => {
  if (Array.isArray(response)) {
    return (
      <UnorderedList>
        {response.map((answer) => (
          <ListItem color="blue.400">
            <Text>{typeof answer === "string" && answer}</Text>
          </ListItem>
        ))}
      </UnorderedList>
    )
  }

  if (typeof response === "object" && response.url) {
    return (
      <Box>
        <Link href={response.url} target="_blank" display="block">
          <Image maxH="250px" h="full" alt={response.file} src={response.url} borderRadius="md" mt={2} />
        </Link>
      </Box>
    )
  }

  return null
}

export default FormSubmissionResponses
