import React, { useRef } from "react"
import { Box, Flex } from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import Icon from "components/elements/Icon"
import { Control, useController } from "react-hook-form"

type Props = {
  name: string
  control: Control<any>
  fileName?: string|null
  accept?: string
}

const FileUpload = ({
  name, control, fileName, accept,
}: Props) => {
  const uploadInput = useRef<HTMLInputElement>(null)
  const { field } = useController({ name, control })

  const inputProps = {
    id: name,
    type: "file",
    accept,
    field,
    onClick: (e) => {
      e.target.value = null
    },
  }

  return (
    <Flex direction="column" align="center">
      <Icon icon="file-icon" size={20} mb={6} />
      <Button
        variant="outline"
        size="sm"
        onClick={() => uploadInput.current?.click()}
      >
        Select File
      </Button>
      <input
        {...inputProps}
        ref={uploadInput}
        style={{ display: "none" }}
        onChange={(e: any) => {
          field.onChange(e.target.files[0])
        }}
        data-testid="file-upload"
      />
      <Box mt={2} fontSize="sm" color="gray.500">{field.value?.name || fileName || "(no file selected)"}</Box>
    </Flex>
  )
}

export default FileUpload
