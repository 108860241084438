import React, { ReactNode } from "react"
import {
  Box, Flex, Heading, ListItem, Text, UnorderedList,
} from "@chakra-ui/react"
import { asMoney } from "utilities/strings"
import Spinner from "./Spinner"
import Link from "./Link"
import SubmitButton from "../Buttons/SubmitButton"

interface Props {
  isFetching: boolean
  isValid: boolean
  isSubmitting: boolean
  submitText: string
  children: ReactNode
  onSubmit: () => void
}

const PaymentForm = ({
  isFetching, isValid, isSubmitting, submitText, children, onSubmit,
}: Props) => (
  <form onSubmit={onSubmit}>
    <Box
      w={320}
      flexShrink={0}
      boxShadow="0px 0px 16px 5px rgba(0,0,0,0.07);"
      borderWidth={1}
      borderRadius="md"
      ml={6}
      mt={10}
      pb={8}
    >
      {isFetching && (
      <Box pt={8}>
        <Spinner heightFull />
      </Box>
      )}
      <Box display={isFetching ? "none" : ""}>
        <Heading fontSize="lg" fontWeight="semibold" px={6} pt={5} pb={3} borderBottomWidth={1}>
          Payment
        </Heading>
        <Box mx={6}>
          {children}
          <UnorderedList py={6} fontSize="sm" color="gray.700" spacing={3}>
            <ListItem>All amounts shown are in US dollars.</ListItem>
            <ListItem>By completing this transaction, you agree to the{" "}
              <Link
                href="http://data.diobox.com/legal/terms.html"
                isExternal
              >
                Diobox Terms
              </Link>.
            </ListItem>
          </UnorderedList>
          <SubmitButton
            disabled={!isValid || isFetching}
            submitting={isSubmitting}
            text={submitText}
            size="md"
          />
        </Box>
      </Box>
    </Box>
  </form>
)

export const LineItem = ({
  title, value, credit = false,
}) => (
  <Flex
    justify="space-between"
    color={credit ? "green.400" : undefined}
    mb={0.5}
  >
    <Text>{title}</Text>
    <Text fontWeight="semibold">{credit && "-"}{asMoney(value, "USD")}</Text>
  </Flex>
)

export default PaymentForm
