import { Box, Stack, StackDivider } from "@chakra-ui/react"
import React from "react"
import {
  FormType, QuestionTypes, SubmissionForm, SubmissionGuest, Translations,
} from "sharedTypes"
import { BuilderBlock } from "sharedTypes/builder"
import { useForm } from "react-hook-form"
import GuestDetails from "components/elements/GuestDetails"
import LabelValuePair from "./LabelValuePair"
import Question from "./Question/Question"
import FormBlocks from "./FormBlocks"
import RSVPFields from "./RSVPFields"

const LineItemShow = ({
  guest: {
    name, email, companyName, companyTitle, phone, address,
  },
  guest,
  fields,
  formType,
  responses,
  translations,
  primaryColor,
  blocks,
}: {
    guest: SubmissionGuest
    fields: Partial<SubmissionForm>
    formType: FormType
    primaryColor: string
    translations: Translations
    responses: { [key: string]: string | [] }
    blocks: BuilderBlock[]
  }) => {
  const iconMap = {
    [QuestionTypes.MultipleAnswer]: "checkbox",
    [QuestionTypes.DropdownMenu]: "dropdown",
    [QuestionTypes.SingleAnswer]: "radiobutton",
    [QuestionTypes.SingleLineText]: "textfield",
    [QuestionTypes.ParagraphText]: "paragraph",
    [QuestionTypes.FileUpload]: "attachment",
    [QuestionTypes.Terms]: "terms",
    [QuestionTypes.TextBlock]: "textfield",
  }

  const { control } = useForm({
    defaultValues: { guest: { status: guest.status!.toString() } },
  })

  const profileFields = [
    { enabled: fields.profileNameEnabled, label: translations.fullName, value: name },
    { enabled: fields.profileEmailEnabled, label: translations.emailAddress, value: email },
    { enabled: fields.profileCompanyEnabled, label: translations.company, value: companyName },
    { enabled: fields.profileTitleEnabled, label: translations.title, value: companyTitle },
    { enabled: fields.profilePhoneEnabled, label: translations.phone, value: phone },
    {
      enabled: fields.profileAddressEnabled,
      label: translations.addressLine1,
      value: address?.line1,
    },
    { enabled: fields.profileCityEnabled, label: translations.city, value: address?.city },
    { enabled: fields.profileStateEnabled, label: translations.state, value: address?.state },
    { enabled: fields.profileZipEnabled, label: translations.postalCode, value: address?.zip },
    { enabled: fields.profileCountryEnabled, label: translations.country, value: address?.country },
  ].filter(({ enabled }) => enabled)

  return (
    <Stack spacing={5} divider={<StackDivider />}>
      {formType === FormType.Survey && (<GuestDetails name={name!} email={email!} />)}
      {formType === FormType.RSVP && (
      <Box>
        <RSVPFields
          namePrefix="guest"
          guest={guest}
          translations={translations}
          control={control}
          disabled
          primaryColor={primaryColor}
        />
      </Box>
      )}
      {profileFields.length > 0 && (
      <Stack spacing={5} fontWeight="semibold">
        {profileFields.map(({ label, value }, index) => (
          <LabelValuePair
            key={index}
            label={label}
            primaryColor={primaryColor}
            value={value}
          />
        ))}
      </Stack>
      )}
      {blocks.length > 0 && (
      <FormBlocks
        blocks={blocks}
        questionRenderFunction={(block: BuilderBlock) => (
          <Question
            block={block}
            primaryColor={primaryColor}
            responses={responses}
            iconMap={iconMap}
          />
        )}
      />
      )}
    </Stack>
  )
}

export default LineItemShow
