import React from "react"
import { FormQuestion, QuestionTypes } from "sharedTypes"
import BarChart from "pages/EventShow/Submissions/ResponseChart/BarChart"
import PieChart from "./PieChart"

type Props = {
  question: FormQuestion
}

const Chart = ({ question }: Props) => {
  switch (question.type) {
    case QuestionTypes.SingleLineText:
      return null
    case QuestionTypes.MultipleAnswer:
      return <BarChart question={question} />
    default:
      return <PieChart question={question} />
  }
}

export default Chart
