import React from "react"
import {
  Flex, Text, Image, Box,
} from "@chakra-ui/react"
import battery from "assets/images/phone/battery.png"
import signal from "assets/images/phone/signal.png"
import wifi from "assets/images/phone/wifi.png"
import arrowBack from "assets/images/phone/arrow-back.png"
import contact from "assets/images/phone/contact.png"
import { format } from "date-fns"

type Props = {
  phoneNumber?: string
}

const Header = ({ phoneNumber }: Props) => (
  <Box h={125}>
    <Flex justifyContent="space-between" px={14}>
      <Text fontSize="lg">{format(new Date(), "HH:mm")}</Text>
      <Flex gap={1}>
        <Image src={signal} h={5} w="auto" />
        <Image src={wifi} h={5} w="auto" />
        <Image src={battery} h={6} w="auto" />
      </Flex>
    </Flex>
    <Flex pt={6} pl={3.5}>
      <Image src={arrowBack} h={7} w="auto" color="blue" />
    </Flex>
    <Flex flexDirection="column" mt={-6} alignItems="center" gap={1}>
      <Image src={contact} w={12} color="blue" />
      <Text fontSize="xs">{phoneNumber}</Text>
    </Flex>
  </Box>
)

export default Header
