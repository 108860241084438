import React from "react"
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  VStack,
} from "@chakra-ui/react"
import { Control, FieldValues, useController } from "react-hook-form"

type ItemProps = {
  id: number|string
  [x: string]: any
}

type Props<T extends FieldValues> = {
  name: string
  control: Control<T, object>
  items: ItemProps[]
  itemComponent: React.FC<any>
  label?: string
  vertical?: boolean
}

const Checkboxes = <T extends FieldValues, >({
  name, control, label, items, itemComponent: ItemComponent, vertical,
}: Props<T>) => {
  const { field } = useController<any>({ name, control })

  if (!items.length) {
    return null
  }

  return (
    <FormControl mb={4} display="flex" flexDirection={vertical ? "column" : "row"}>
      {label && <FormLabel as="legend" fontWeight="bold" w={vertical ? "full" : "25%"}>{label}</FormLabel>}
      <Box w="full">
        <VStack align="flex-start" spacing={3}>
          {items.map((item) => (
            <Checkbox
              key={item.id}
              spacing="1rem"
              isChecked={field.value.includes(item.id)}
              onChange={(event) => {
                if (event.target.checked) {
                  field.onChange([...field.value, item.id])
                } else {
                  field.onChange(field.value.filter((itemId) => itemId !== item.id))
                }
              }}
            >
              <ItemComponent {...item} />
            </Checkbox>
          ))}
        </VStack>
      </Box>
    </FormControl>
  )
}

export default Checkboxes
