import React from "react"
import { Box, VStack } from "@chakra-ui/react"
import { Account, DioboxEvent, Translations } from "sharedTypes"
import EventBranding from "./EventBranding"
import PageBranding from "./PageBranding"
import ColorTheme from "./ColorTheme/ColorTheme"
import PageWithPreview from "../PageWithPreview"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
  account: Account
  eventPreview: DioboxEvent
  onChangeEventPreview: (values: Partial<DioboxEvent>) => void
}

export const GUEST_PORTAL_PADDING_PROPS = {
  px: 6,
  py: 4,
}

const Branding = ({
  event,
  updateEvent,
  defaultTranslations,
  account,
  eventPreview,
  onChangeEventPreview,
}: Props) => (
  <PageWithPreview eventPreview={eventPreview}>
    <VStack align="flex-start" spacing={16}>
      <Box w="100%">
        <ColorTheme
          event={event}
          updateEvent={updateEvent}
          onChangeEventPreview={onChangeEventPreview}
        />
      </Box>
      <Box>
        <EventBranding
          event={event}
          updateEvent={updateEvent}
          defaultTranslations={defaultTranslations}
          onChangeEventPreview={onChangeEventPreview}
        />
      </Box>
      <Box>
        <PageBranding
          event={event}
          updateEvent={updateEvent}
          account={account}
          onChangeEventPreview={onChangeEventPreview}
        />
      </Box>
    </VStack>
  </PageWithPreview>
)

export default Branding
