import React from "react"
import {
  Box, Center, Flex, Image, Text,
} from "@chakra-ui/react"
import SubmissionCard from "pages/EventShow/Submissions/FormSubmissions/FormSubmissionsDetailView/SubmissionCard"
import { DioboxEvent, GuestStatus } from "sharedTypes"
import Icon from "components/elements/Icon"

const GuestCard = ({
  name,
  email,
  status,
  statuses,
  qrCodeUrl,
  qrCodeText,
  event: { includeQrCode },
}: {
  name: string
  email: string
  status: GuestStatus
  statuses: string[]
  qrCodeUrl: string
  qrCodeText: string
  event: DioboxEvent
}) => {
  const statusColors = ["status.unconfirmed", "status.portal.confirmed", "status.portal.declined", "status.waitlisted"]
  const statusNames = ["unconfirmed", "confirmed", "declined", "waitlisted"]

  return (
    <SubmissionCard>
      <Flex justify="space-between">
        <Box>
          <Flex mb={1} color={statusColors[status]} alignItems="center">
            <Icon icon={`portal-${statusNames[status]}`} mr={1} />
            <Text textTransform="uppercase" fontSize="md" fontWeight="bold">
              {statuses[status]}
            </Text>
          </Flex>
          <Box mb={4}>
            <Text fontSize="2xl" fontWeight="semibold" mb={-1}>
              {name}
            </Text>
            {email && <Text fontSize="lg">{email}</Text>}
          </Box>
        </Box>
        {status === 1 && includeQrCode && (
        <Box w={140} textAlign="center">
          <Center>
            <Image
              src={qrCodeUrl}
              width={100}
              height={100}
              alt="QR Code"
              mb={3}
            />
          </Center>
          <Text fontSize="xs">{qrCodeText}</Text>
        </Box>
        )}
      </Flex>
    </SubmissionCard>
  )
}

export default GuestCard
