import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"

type Props = {
  label: string
  primaryColor: string
  value?: string
}

const LabelValuePair = ({ label, primaryColor, value }: Props) => (
  <Flex>
    <Icon icon="form-textfield" mr={3} size={6} mt={0.25} />
    <Box>
      <Text>{label}</Text>
      <Text color={primaryColor}>{value}</Text>
    </Box>
  </Flex>
)

export default LabelValuePair
