import React, { useState } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { FormQuestion, ModalName } from "sharedTypes"
import Button from "components/Buttons/Button"
import useModal from "services/useModal"
import PaginatedView from "components/PaginatedView"
import { useEventQuestionResponsesPaginated } from "queries/questions"
import { questionTypeIcons } from "utilities/enums"
import Icon from "components/elements/Icon"
import ResponseListItem from "./ResponseListItem/ResponseListItem"
import Chart from "../ResponseChart/Chart"

type Props = {
    question: FormQuestion
    eventId: string
}

const GuestResponses = ({ question, eventId }: Props) => {
  const showModal = useModal()
  const [page, setPage] = useState(1)

  const { data, isPreviousData, isLoading } = useEventQuestionResponsesPaginated(
    eventId,
    question.id,
    page,
  )

  const questionHasResponses = Object.keys(question.responses).length > 0
  const items = data?.data || []
  const totalCount = data?.meta.totalCount || 0

  return (
    <Box paddingBottom={2}>
      <Flex direction="column" gap={2}>
        <Flex align="center" gap={1} color="gray.700">
          <Icon icon={questionTypeIcons[question.type]} size={7} />
          <Text size="sm">{question.type}</Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontSize="xl" mb={5} dangerouslySetInnerHTML={{ __html: question.title }} />
          <Button onClick={() => showModal(ModalName.QuestionForm, { questionId: question.id })} size="sm" variant="outline" px={4}>Edit</Button>
        </Flex>
      </Flex>
      <Chart question={question} />
      {questionHasResponses && (
        <>
          <Flex justifyContent="space-between" alignItems="center" mb={5}>
            <Text fontSize="2xl">Guest Responses</Text>
          </Flex>
          <PaginatedView
            Component={ResponseListItem}
            entityName="Response"
            isPreviousData={isPreviousData}
            items={items}
            onChangePage={setPage}
            page={page}
            totalCount={totalCount}
            isLoading={isLoading}
          />
        </>
      )}
      {!questionHasResponses && question.choices.length === 0 && (
        <Flex
          backgroundColor="white"
          justifyContent="center"
          alignItems="center"
          borderWidth={1}
          borderRadius="md"
          py={32}
        >
          <Text>No Responses</Text>
        </Flex>
      )}
    </Box>
  )
}

export default GuestResponses
