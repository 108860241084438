import React from "react"
import { FormQuestion, QuestionTypes } from "sharedTypes"
import QuestionContent from "components/elements/QuestionContent"
import {
  Box, Checkbox, Stack, Text,
} from "@chakra-ui/react"
import FormattedQuestionTitleWithRequiredMark from "./FormattedQuestionTitleWithRequiredMark"

type Props = {
  question: FormQuestion;
}

const Question = ({
  question: {
    type, title, choices, required,
  },
}: Props) => (
  <Stack px={5} py={2.5}>
    <FormattedQuestionTitleWithRequiredMark title={title} required={required} />
    {type === QuestionTypes.Terms && (
      <Box>
        <Checkbox defaultChecked><Text fontWeight="semibold">{choices[0]}</Text></Checkbox>
      </Box>
    )}
    <Box>
      <QuestionContent type={type} choices={choices} />
    </Box>
  </Stack>
)

export default Question
