import React from "react"
import {
  DioboxEvent, Form, Email, ModalName,
} from "sharedTypes"
import { Flex, Stack, Text } from "@chakra-ui/react"
import { asDate, hasValidCustomDomain } from "utilities/strings"
import { DEFAULT_SENDER_EMAIL } from "utilities/enums"
import useModal from "services/useModal"
import CardWithTitle from "components/CardWithTitle"
import Button from "components/Buttons/Button"
import ViewEmailContent from "components/ViewEmailContent"
import { useQueryClient } from "react-query"
import { updateEmail } from "context/actions"
import { putInvitation } from "api/emails"
import FormIntegrations from "../SentEmail/FormIntegrations/FormIntegrations"
import EmailHeader from "../EmailHeader"

type Props = {
  event: DioboxEvent
  email: Email
  duplicateEmail: (email: Email) => void
  deleteEmail: (email: Email) => void
  linkedForms: Form[]
  deletedFormsCount: number
}

const DraftEmail = ({
  event,
  event: {
    id: eventId, customDomain, emailNotificationsSenderMailbox, timezone,
  },
  email,
  email: {
    id: emailId, from, fromMailbox, updatedAt,
  }, duplicateEmail, deleteEmail,
  linkedForms,
  deletedFormsCount,
}: Props) => {
  const showModal = useModal()
  const queryClient = useQueryClient()

  const onSubmit = (values) => {
    const { title: emailTitle } = values

    return putInvitation(eventId, email.id, {
      title: emailTitle,
    })
      .then(({ data }) => {
        updateEmail(queryClient, eventId, data)
      })
  }

  const senderEmailAddress = fromMailbox || emailNotificationsSenderMailbox
  const fromEmail = hasValidCustomDomain(event) ? `${from} <${senderEmailAddress}@${customDomain?.domain}>` : DEFAULT_SENDER_EMAIL

  return (
    <Stack spacing={9}>
      <EmailHeader
        email={email}
        fromEmail={fromEmail}
        onEditName={() => showModal(ModalName.EditEmailName, { onSubmit, initialValues: email })}
        onDuplicate={() => duplicateEmail(email)}
        onDelete={() => deleteEmail(email)}
        timezone={timezone}
      />
      {(linkedForms.length > 0 || deletedFormsCount > 0) && (
      <CardWithTitle title="Form Integration">
        <FormIntegrations
          event={event}
          forms={linkedForms}
          deletedFormsCount={deletedFormsCount}
        />
      </CardWithTitle>
      )}
      <CardWithTitle
        title="Email Preview"
        accessoryRight={(
          <Button
            onClick={() => showModal(ModalName.SendTestEmail, { id: emailId })}
            size="sm"
            variant="outline"
          >Send Test
          </Button>
        )}
        p={0}
      >
        <ViewEmailContent email={email} />
      </CardWithTitle>
      <Flex direction="column" align="center">
        <Text mb={5}>Last saved: {asDate(updatedAt)}</Text>
      </Flex>
    </Stack>
  )
}

export default DraftEmail
