import React, { useState } from "react"
import { DraggableItem, DraggableItemTypes } from "sharedTypes/builder"
import { useDrop } from "react-dnd"
import { Flex } from "@chakra-ui/react"
import { BlockInsertPosition } from "./BuildingBlock"

type Props = {
  position: BlockInsertPosition
  dragId: number
  onMove?: (fromDragId: number, toDragId: number, position: BlockInsertPosition) => void
  onDropInto?: (item: DraggableItem, blockId: number, position: BlockInsertPosition) => void
  forFixedBlock?: boolean
}

const HoverArea = ({
  position, dragId, onMove, onDropInto, forFixedBlock,
}: Props) => {
  const [showInsertIndicator, setShowInsertIndicator] = useState(false)

  // If we drop on a FixedBlock we always want to insert top to the top (before the 1st element).
  // If we want to insert to the bottom it doesn't matter because a new element will be inserted
  // since we provided -1 as the blockId, and the insert logic puts the block to the end anyway.
  const insertPosition = forFixedBlock ? BlockInsertPosition.Top : position

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [DraggableItemTypes.REORDER, DraggableItemTypes.ADD],
    hover: (item: DraggableItem, monitor) => {
      if (monitor.getItemType() === DraggableItemTypes.ADD) {
        setShowInsertIndicator(true)
      } else {
        setShowInsertIndicator(false)
      }

      if (monitor.getItemType() === DraggableItemTypes.REORDER) {
        if (item.dragId === dragId) {
          return
        }

        onMove?.(item.dragId, dragId, insertPosition)
      }
    },
    drop: (item: DraggableItem) => onDropInto?.(item, dragId, insertPosition),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }), [dragId, onMove])

  return (
    <Flex
      ref={drop}
      justify="center"
      position="absolute"
      w="100%"
      h={forFixedBlock ? "100%" : "50%"}
      {...{ [position]: 0 }}
      data-select-block-on-click
      zIndex={1}
      borderTopWidth={position === BlockInsertPosition.Top && isOver && showInsertIndicator ? 3 : 0}
      borderBottomWidth={
        position === BlockInsertPosition.Bottom && isOver && showInsertIndicator ? 3 : 0
      }
      borderColor="green.500"
      mb={position === BlockInsertPosition.Bottom ? "-1.5px" : 0}
      mt={position === BlockInsertPosition.Top ? "-1.5px" : 0}
    />
  )
}

export default HoverArea
