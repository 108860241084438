import React from "react"
import {
  HStack, Box, Flex, FormLabel,
} from "@chakra-ui/react"
import { Control } from "react-hook-form"
import TextField from "../../pages/EventShow/Guests/SelectedGuest/Edit/Attribute/Field"

type Props = {
  control: Control<any>
  path?: string
  label?: string
  eventAddress?: boolean
}

const AddressFields = ({
  path, control, label, eventAddress,
}: Props) => {
  const namePrefix = path ? `${path}.` : ""

  const fieldNames = {
    line1: eventAddress ? "addressLine1" : "line1",
    line2: eventAddress ? "addressLine2" : "line2",
    city: "city",
    state: "state",
    zip: eventAddress ? "zipcode" : "zip",
    country: "country",
  }

  return (
    <Flex>
      {label && <FormLabel fontWeight="bold" w="25%">{label}</FormLabel>}
      <Box mt={-1} mb={2} w="100%">
        <TextField name={`${namePrefix}${fieldNames.line1}`} control={control} placeholder="Line 1" />
        <TextField name={`${namePrefix}${fieldNames.line2}`} control={control} placeholder="Line 2" />
        <HStack>
          <Box flexBasis="55%"><TextField name={`${namePrefix}${fieldNames.city}`} control={control} placeholder="City" /></Box>
          <Box flexBasis="30%"><TextField name={`${namePrefix}${fieldNames.state}`} control={control} placeholder="State" /></Box>
          <Box flexBasis="15%"><TextField name={`${namePrefix}${fieldNames.zip}`} control={control} placeholder="Zip" /></Box>
        </HStack>
        <TextField name={`${namePrefix}country`} control={control} placeholder="Country" />
      </Box>
    </Flex>
  )
}

export default AddressFields
