import React, { useEffect } from "react"
import EventResourcesView from "components/layout/EventResourcesView"
import { useHistory, useParams } from "react-router-dom"
import {
  EventCounts, EventGroup, ModalName, Segment, Table, AccountUser, DioboxEvent,
} from "sharedTypes"
import EventPage, { EventPageHeader } from "components/layout/EventPage"
import Filters from "pages/EventShow/Guests/ScrollView/Filters"
import usePermissions from "services/permissions"
import HorizontalStat from "components/elements/HorizontalStat"
import { asNumber, asPercent } from "utilities/strings"
import pluralize from "pluralize"
import Menu, { MenuDivider, MenuItem } from "components/dialogs/Menu"
import SelectPrompt from "components/SelectPrompt"
import { useGuests } from "queries/guests"
import { shallow } from "zustand/shallow"
import useGuestsStore from "services/useGuestsStore"
import useModal from "services/useModal"
import useSearchParams from "services/useSearchParams"
import { useEventGuestPaths } from "utilities/routes"
import SelectedGuest from "./SelectedGuest"
import MultipleSelectActions from "./MultipleSelectActions"
import ListHeader from "./ScrollView/GuestListHeader"
import GuestList from "./ScrollView/GuestList"
import NoGuests from "./NoGuests"
import LimitReached from "./LimitReached"

interface Props {
  counts: EventCounts
  currency: string
  segmentsEnabled: boolean
  enhanceEnabled: boolean
  groupsEnabled: boolean
  eventGroups: EventGroup[]
  importLimit: number|null
  segments: Segment[]
  tables: Table[]
  timezone: string
  event: DioboxEvent
  accountUsers?: AccountUser[]
}

const Guests = ({
  counts,
  counts: { guests: guestsCount },
  currency,
  segmentsEnabled,
  enhanceEnabled,
  groupsEnabled,
  eventGroups,
  importLimit,
  segments,
  tables,
  timezone,
  event,
  accountUsers,
}: Props) => {
  const { eventId, guestId } = useParams<{ eventId: string, guestId: string }>()
  const history = useHistory()
  const showModal = useModal()
  const { canEditGuests } = usePermissions()
  const { groupId: groupIdSearchParam, segmentId: segmentIdSearchParam, q } = useSearchParams()

  const {
    searchString, groupId, segmentId, order, selectAll,
    deselectAll, allSelected, selectedGuestIds, reset, filter, search, sort,
  } = useGuestsStore(
    (s) => s, shallow,
  )

  const toggleSelectAll = () => (allSelected ? deselectAll : selectAll)()

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    filter({ groupId: groupIdSearchParam, segmentId: segmentIdSearchParam })
  }, [groupIdSearchParam, segmentIdSearchParam])

  useEffect(() => {
    search(q)
  }, [q])

  // When currently selected Segment is deleted filters are reset
  const selectedSegment = segments.find(({ id }) => id === segmentId)
  useEffect(() => {
    if (segmentId && !selectedSegment) {
      reset()
    }
  }, [selectedSegment])

  const {
    guests,
    fetchNextPage,
    isLoading,
  } = useGuests(eventId, searchString, order, groupId, segmentId)
  const { eventGuestsPath } = useEventGuestPaths()

  const multipleSelectEnabled = !!selectedGuestIds.length

  useEffect(() => {
    if (guestId) {
      deselectAll()
    }
  }, [guestId])

  useEffect(() => {
    if (multipleSelectEnabled || allSelected) {
      history.push(eventGuestsPath(eventId))
    }
  }, [multipleSelectEnabled, allSelected])

  useEffect(() => {
    deselectAll()
  }, [groupId, segmentId])

  useEffect(() => {
    if (searchString !== "") {
      deselectAll()
    }
  }, [searchString])

  if (!accountUsers) {
    return null
  }

  if (!guestsCount) {
    return <NoGuests />
  }

  if (importLimit && remainingGuestSpots(guestsCount, importLimit) < 0) {
    return <LimitReached guestLimit={importLimit} guestsCount={guestsCount} />
  }

  const onFilter = (f) => {
    if (f.groupId !== groupId || f.segmentId !== segmentId) {
      let searchParam = ""
      if (f.groupId) {
        searchParam = `groupId=${f.groupId}`
      }
      if (f.segmentId) {
        searchParam = `segmentId=${f.segmentId}`
      }

      history.push(eventGuestsPath(eventId, searchParam))
    }
  }

  const handleSearch = (query) => {
    history.push(eventGuestsPath(eventId, query ? `q=${query}` : ""))
  }

  const filtered = !!groupId || !!segmentId || !!searchString

  return (
    <EventPage
      header={(
        <EventPageHeader
          title={(
            <Filters
              counts={counts}
              eventGroups={eventGroups}
              segmentsEnabled={segmentsEnabled}
              segments={segments}
              onChange={onFilter}
              selected={{ segmentId, groupId }}
              canEditGuests={canEditGuests}
              teamMembers={accountUsers}
            />
          )}
          items={(
            <>
              <HorizontalStat
                label={pluralize("Guest", counts.guests)}
                value={asNumber(counts.guests)}
              />
              <HorizontalStat
                label="Checked In"
                value={asPercent((
                  counts.arrivedGuests) / (
                  counts.guests))}
              />
              {canEditGuests
              && (
              <Menu
                buttonText="Guests"
                buttonVariant="solid"
                menuItems={[
                  <MenuItem onClick={() => showModal(ModalName.NewGuest)}>
                    New Guest
                  </MenuItem>,
                  <MenuDivider />,
                  <MenuItem onClick={() => showModal(ModalName.GuestListForm)}>
                    New Guest List
                  </MenuItem>,
                  <MenuItem onClick={() => showModal(ModalName.SegmentForm)}>
                    New Segment
                  </MenuItem>,
                  <MenuDivider />,
                  <MenuItem onClick={() => showModal(ModalName.ImportGuests)}>
                    Import Guests
                  </MenuItem>,
                  <MenuItem onClick={() => showModal(ModalName.ExportGuests)}>
                    Export Guests
                  </MenuItem>,
                ].filter(Boolean)}
              />
              )}
            </>
          )}
        />
      )}
      body={(
        <EventResourcesView
          listWidth="md"
          onScrollEnd={fetchNextPage}
          listHeader={(
            <ListHeader
              loading={isLoading}
              onSearch={handleSearch}
              searchString={searchString}
              order={order}
              onSetOrder={(value) => sort(value)}
              allSelected={allSelected}
              toggleSelectAll={toggleSelectAll}
            />
          )}
          listItems={(
            <GuestList
              guests={guests}
              multipleSelect
              multipleSelectEnabled={multipleSelectEnabled}
              order={order}
              allSelected={allSelected}
              filtered={filtered}
            />
          )}
          showBorder={!!guestId}
          selectedItem={
            multipleSelectEnabled || allSelected
              ? (
                <MultipleSelectActions
                  eventGroups={eventGroups}
                  segments={segments}
                  counts={counts}
                />
              )
              : (
                guestId
                  ? (
                    <SelectedGuest
                      enhanceEnabled={enhanceEnabled}
                      groupsEnabled={groupsEnabled}
                      currency={currency}
                      timezone={timezone}
                      tables={tables}
                      teamMembers={accountUsers}
                      event={event}
                    />
                  )
                  : <SelectPrompt text="Select a Guest" />
              )
          }
        />
      )}
    />
  )
}

export const remainingGuestSpots = (
  guestCount: number, guestLimit: number|null,
) => {
  if (!guestLimit) {
    return Infinity
  }

  return guestLimit - guestCount
}

export default Guests
