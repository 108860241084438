import { createStandaloneToast } from "@chakra-ui/react"
import React from "react"
import { infoToast } from "utilities/toasts"
import { setCurrentUser } from "context/actions"
import { User } from "sharedTypes"
import { QueryClient } from "react-query"

const AUTH_TOKEN = "authToken"
const CURRENT_ACCOUNT_ID = "currentAccountId"
const CURRENT_USER_DATA = "currentUserData"
const CURRENT_VERSION = "currentVersion"
const SOCKET_ID = "socketId"
const SUPPORT_LOGIN = "supportLogin"

export const authToken = () => localStorage.getItem(AUTH_TOKEN)
export const currentAccountId = () => localStorage.getItem(CURRENT_ACCOUNT_ID)
export const currentUserData = () => localStorage.getItem(CURRENT_USER_DATA)
export const currentUserId = () => currentUserData()?.split(":")[0]
export const currentVersion = () => sessionStorage.getItem(CURRENT_VERSION)
export const supportLogin = ():boolean => localStorage.getItem(SUPPORT_LOGIN) === "true"
export const socketId = () => sessionStorage.getItem(SOCKET_ID)

export const storeAuthToken = (data) => {
  localStorage.setItem(AUTH_TOKEN, data)
}

export const storeSupportLogin = (data) => {
  localStorage.setItem(SUPPORT_LOGIN, data)
}

export const storeCurrentAccountId = (data) => {
  localStorage.setItem(CURRENT_ACCOUNT_ID, data)
}

export const storeCurrentUserData = (id: string, email: string, name: string) => {
  localStorage.setItem(CURRENT_USER_DATA, [id, email, name].join(":"))
}

export const storeSocketId = (data) => {
  sessionStorage.setItem(SOCKET_ID, data)
}

export const storeCurrentVersion = (data) => {
  sessionStorage.setItem(CURRENT_VERSION, data)
}

export const checkVersionUpdate = (data) => {
  if (import.meta.env.DEV) { return }

  if (currentVersion() && currentVersion() !== data) {
    const { toast } = createStandaloneToast()
    if (!toast.isActive("newVersion")) {
      infoToast({
        id: "newVersion",
        title: "Diobox was updated with improvements.",
        description: React.createElement(
          "div", null, "Please ",
          React.createElement("a", { onClick: () => { window.location.reload() }, style: { textDecoration: "underline" } }, "reload"),
          " this page to start using them.",
        ),
        duration: null,
        isClosable: false,
      })
    }
  }
}

export const clearSession = () => {
  localStorage.clear()
  sessionStorage.clear()
}

export const signOut = (queryClient: QueryClient, shutdownIntercom) => {
  clearSession()
  setCurrentUser(queryClient, {} as User)
  shutdownIntercom()
}
