import React from "react"
import NoContent from "components/elements/NoContent"

type Props = {
  onClickAdd: () => void
}

const NoSpeakers = ({ onClickAdd }: Props) => {
  const descriptionItems = [
    "Create profiles with detailed speaker bios",
    "Include speaker contact info and photo",
    "Include optional links to external speaker websites",
  ]

  const callsToActions = [
    {
      icon: "plus-big",
      text: "Add First Speaker",
      action: onClickAdd,
    },
  ]

  return (
    <NoContent
      title="Event Speakers"
      descriptionItems={descriptionItems}
      icon="speakers"
      callsToActions={callsToActions}
    />
  )
}

export default NoSpeakers
