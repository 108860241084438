import React from "react"
import PillButton from "components/Buttons/PillButton"
import { useHistory } from "react-router-dom"

type Props = {
  title: string
  iconName: string
  active: boolean
  path: string
}

const NavigationTabButton = ({
  title, iconName, active, path,
}: Props) => {
  const history = useHistory()

  return (
    <PillButton
      active={active}
      icon={iconName}
      onClick={() => history.push(path)}
      title={title}
    />
  )
}

export default NavigationTabButton
