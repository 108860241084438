import React from "react"
import { Route, Switch } from "react-router-dom"
import { User, Account, AccountUser } from "sharedTypes"
import { accountTeamMemberPath } from "utilities/routes"
import TeamMembersList from "./TeamMembersList"
import TeamMemberShow from "./TeamMemberShow"

type Props = {
  currentUser: User;
  account: Account;
  accountUsers?: AccountUser[];
}

const TeamMembers = ({ currentUser, account, accountUsers }: Props) => {
  if (!accountUsers) {
    return null
  }

  return (
    <Switch>
      <Route path={accountTeamMemberPath(":teamMemberId")}>
        {(route) => {
          const teamMemberId = route.match?.params.teamMemberId
          const accountUser = accountUsers?.find(({ id }) => id.toString() === teamMemberId)

          if (!accountUser) {
            return null
          }

          return (
            <TeamMemberShow
              currentUser={currentUser}
              account={account}
              accountUser={accountUser}
            />
          )
        }}
      </Route>
      <Route>
        <TeamMembersList
          currentUser={currentUser}
          account={account}
          teamMembers={accountUsers}
        />
      </Route>
    </Switch>
  )
}

export default TeamMembers
