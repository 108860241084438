import React from "react"
import { Form } from "sharedTypes"
import {
  Box, Flex, Stack, Divider,
} from "@chakra-ui/react"
import formatRegistrationFields from "./RegistrationFields/formatRegistrationFields"
import FixedBlockTextInput from "./RegistrationFields/FixedBlockTextInput"

type Props = {
  form: Form
  enableNameAndEmailToggle?: boolean
  showTopDivider?: boolean
}

const DynamicInputFields = ({ form, enableNameAndEmailToggle, showTopDivider }: Props) => {
  const fields = formatRegistrationFields(form, enableNameAndEmailToggle)

  const enabledFields = (fieldsToEnable) => fieldsToEnable
    .filter(({ enabled }) => enabled)

  const enabledPersonalFields = enabledFields(fields.personal)
  const enabledAddressFields = enabledFields(fields.address)

  if (enabledPersonalFields.length + enabledAddressFields.length === 0) {
    return null
  }

  const nameInputFields = (
    <Flex gap={4}>
      <Box flex={1}>
        <FixedBlockTextInput name="First Name" required />
      </Box>
      <Box flex={1}>
        <FixedBlockTextInput name="Last Name" required />
      </Box>
    </Flex>
  )

  return (
    <>
      {showTopDivider && <Divider my={4} />}
      <Stack px={6}>
        {enabledPersonalFields.map(({ fieldName, name, required }) => (
          fieldName === "profileName"
            ? nameInputFields
            : <FixedBlockTextInput key={name + fieldName} name={name} required={required} />
        ))}
        {enabledAddressFields.map(({ name, required }) => (
          <FixedBlockTextInput key={name} name={name} required={required} />
        ))}
      </Stack>
    </>

  )
}

export default DynamicInputFields
