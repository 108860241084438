import React from "react"
import { currentAccountId } from "services/authentication"
import SectionHeader from "pages/EventShow/Settings/SectionHeader"
import {
  Box,
  Flex, Stack, Text, Image,
} from "@chakra-ui/react"
import { CURRENCY_OPTIONS } from "utilities/enums"
import StripeLogo from "assets/images/stripe-logo.png"
import { StripeAccount } from "sharedTypes"
import Menu, { MenuDivider, MenuItem } from "components/dialogs/Menu"
import Icon from "components/elements/Icon"
import Button from "components/Buttons/Button"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import Select from "components/ReactHookForm/Select"
import { successToast } from "utilities/toasts"

type Props = {
  eventId: string
  currency: string
  currencySelectDisabled: boolean
  stripeAccounts: StripeAccount[]
  stripeAccountId: string
  updateEvent: (params: {}) => Promise<void>
}

const TicketingPayments = ({
  eventId,
  currency,
  currencySelectDisabled,
  stripeAccounts,
  stripeAccountId,
  updateEvent,
}: Props) => {
  const onSubmit = (values) => updateEvent(values)

  const stripeConnect = () => {
    window.open(`${import.meta.env.VITE_STRIPE_CONNECT_URL}?account_id=${currentAccountId()}&event_id=${eventId}&client_redirect_uri=${(window.location.href)}`)
  }

  const handleChangeStripeAccount = async (id) => {
    await updateEvent({ stripeAccountId: id })

    const { businessName, stripeId } = stripeAccounts.find(
      ({ id: accountId }) => accountId === id,
    ) || {}

    successToast({ title: `Connected to Stripe Account "${businessName || stripeId}"` })
  }

  return (
    <Stack direction="column" spacing={3}>
      <PaymentProcessing
        currency={currency}
        currencySelectDisabled={currencySelectDisabled}
        onSubmit={onSubmit}
        stripeAccounts={stripeAccounts}
        onStripeConnect={stripeConnect}
        stripeAccountId={stripeAccountId}
        onStripeAccountChange={handleChangeStripeAccount}
      />
    </Stack>
  )
}

const PaymentProcessing = ({
  currency,
  currencySelectDisabled,
  onSubmit,
  onStripeConnect,
  onStripeAccountChange: onChangeStripeAccount,
  stripeAccounts,
  stripeAccountId,
}) => (
  <>
    <SectionHeader>Payment Processing</SectionHeader>
    <ContainedForm
      values={{ currency }}
      onSubmit={onSubmit}
      name="Payment Processing"
    >
      {({ control }) => (
        <>
          <Select
            labelSize="lg"
            label="Currency"
            name="currency"
            control={control}
            options={CURRENCY_OPTIONS}
            helperText="Currency cannot be changed after the first ticket is purchased"
            disabled={currencySelectDisabled}
          />
          <Flex mt={8}>
            <Text w={1 / 4} fontSize="lg" fontWeight="bold" mr={3}>Payment Gateway</Text>
            <Box w="100%">
              <Image src={StripeLogo} mb={3} w={100} />
              <Text mb={3}>
                All proceeds will be posted to your Stripe account instantly
                and transferred to your bank account on a 2-day rolling basis.
              </Text>
              <Text mb={6}>
                Stripe is the new standard in online payments. If you don’t have a Stripe account,
                you can create a free account by clicking the button below.
              </Text>
              <Text mb={5}>
                <Menu
                  noPadding
                  button={(
                    <StripeConnectButton
                      connectedStripeAccount={stripeAccounts.find(
                        ({ id }) => id === stripeAccountId,
                      )}
                    />
                  )}
                  menuItems={[
                    ...stripeAccounts.map(
                      ({ id, stripeId, businessName }) => (
                        <MenuItem
                          px={10}
                          onClick={() => onChangeStripeAccount(id)}
                          selected={id === stripeAccountId}
                        >
                          {businessName ?? `Stripe ID: ${stripeId}`}
                        </MenuItem>
                      ),
                    ),
                    stripeAccounts.length ? <MenuDivider m={0} /> : undefined,
                    <MenuItem px={10} fontWeight="semibold" color="blue.600" onClick={onStripeConnect}>Connect to new Stripe account</MenuItem>,
                  ]}
                />
              </Text>
            </Box>
          </Flex>
        </>
      )}
    </ContainedForm>
  </>
)

const StripeConnectButton = (
  { connectedStripeAccount }: {connectedStripeAccount?: StripeAccount},
) => {
  if (!connectedStripeAccount) {
    return <Button>Connect to Stripe</Button>
  }

  const { businessName } = connectedStripeAccount

  return (
    <Button variant="outline" rightIcon="down-arrow">
      <Icon icon="checkmark" mr={2} />
      Connected to Stripe{businessName ? ` (${businessName})` : ""}
    </Button>
  )
}

export default TicketingPayments
