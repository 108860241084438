import Popover from "components/dialogs/Popover"
import React from "react"
import { GuestSummary } from "sharedTypes"
import { postCheckIns } from "api/guests"
import { checkInGuests } from "context/actions"
import { useQueryClient } from "react-query"
import SideToggle from "../SideToggle/SideToggle"
import GroupCheckInPopover from "./GroupCheckInPopover/GroupCheckInPopover"

type Props = {
  group: GuestSummary[]
  checkIn: (guestId: string) => void
  eventId: string
  checkInLocationId: number|null
  guestId: string
}

const GroupCheckInButton = ({
  group, checkIn, eventId, checkInLocationId, guestId,
}:Props) => {
  const queryClient = useQueryClient()

  const checkInAll = () => {
    const guestIds = group
      .filter(({ checkedIn }) => checkedIn === 0)
      .map(({ id }) => id)

    postCheckIns(eventId, guestIds, checkInLocationId || null)
      .then(() => {
        checkInGuests(queryClient, eventId, guestIds, guestId)
      })
  }

  const remainingFromGroup = group.filter(({ checkedIn }) => !checkedIn).length

  return (
    <Popover
      header="Group Activity"
      placement="top"
      trigger={(
        <SideToggle
          icon="guest-group"
          title="Add Group Activity"
          text={group.length > 1 ? `${group.length - remainingFromGroup}/${group.length}` : ""}
        />
)}
      body={(
        <GroupCheckInPopover
          group={group}
          checkIn={checkIn}
          checkInAll={checkInAll}
          remainingFromGroup={remainingFromGroup}
        />
)}
      zIndex={8}
      gutter={16}
    />
  )
}

export default GroupCheckInButton
