import { Box } from "@chakra-ui/react"
import React, {
  ReactNode, useEffect, useRef, useState,
} from "react"

type Props = {
  children: ReactNode
}
const ShrinkWrap = ({ children }: Props) => {
  const labelRef = useRef<HTMLDivElement>(null)
  const [textWidth, setTextWidth] = useState<number>()
  useEffect(() => {
    const range = document.createRange()
    const text = labelRef.current?.firstChild
    if (text) {
      range.selectNode(text)
    }
    const boxW = labelRef.current?.getBoundingClientRect().width
    const textW = range.getBoundingClientRect().width
    if (boxW && textW < boxW) {
      setTextWidth(range.getBoundingClientRect().width)
    }
  }, [labelRef.current?.firstChild, children])

  return (
    <Box ref={labelRef} w={textWidth ? `${textWidth}px` : undefined}>
      {children}
    </Box>
  )
}

export default ShrinkWrap
