import React from "react"
import Button from "components/Buttons/Button"
import { getFormSubmissionReceiptExternalUrl } from "utilities/forms"
import { Box, Flex } from "@chakra-ui/react"

type Props = {
  formSubmissionExternalId: string
}

const ReceiptButton = ({ formSubmissionExternalId }: Props) => (
  <Flex justifyContent="flex-end">
    <Box>
      <Button
        as="a"
        href={getFormSubmissionReceiptExternalUrl(formSubmissionExternalId)}
        target="_blank"
        variant="outline"
        size="sm"
      >Receipt
      </Button>
    </Box>
  </Flex>
)

export default ReceiptButton
