import React, { ReactNode } from "react"
import Icon from "components/elements/Icon"
import { Box, Flex } from "@chakra-ui/react"

type Props = {
  children: ReactNode;
  icon?: string;
  color?: string;
  selected?: boolean;
}

const SecondaryNavItem = ({
  icon, color = "gray.800", children, selected,
}: Props) => (
  <Flex
    justify="space-between"
    align="center"
    borderBottomWidth={1}
    px={6}
    py={8}
    bgColor={selected ? "blue.50" : ""}
  >
    <Flex
      flexGrow={1}
      align="center"
      fontSize="xl"
    >
      {icon && <Icon icon={icon} size={8} mr={5} color={color} />}
      <Box
        flexGrow={1}
        color="gray.700"
        fontSize="xl"
      >{children}
      </Box>
    </Flex>
    <Icon icon="next-arrow" size={3} ml={5} color="gray.500" />
  </Flex>
)

export default SecondaryNavItem
