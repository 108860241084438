import Icon from "components/elements/Icon"
import React from "react"
import { Transaction } from "sharedTypes"
import Select from "components/ReactHookForm/Select"
import DatePickerField from "components/ReactHookForm/DatePickerField"
import TextArea from "components/ReactHookForm/TextArea"
import ActivityForm from "../Form"

type Props = {
  activity?: Transaction;
}

const TravelPlan = ({ activity }: Props) => {
  let initialValues
  if (activity) {
    initialValues = {
      transactionDate: activity.transactionDate,
      route: activity.data.route,
      travelType: activity.data.travelType,
      expensesPaidBy: activity.data.expensesPaidBy,
      details: activity.details,
    }
  } else {
    initialValues = {
      transactionDate: new Date(),
      route: "Arrival",
      travelType: "Flight",
      expensesPaidBy: "Guest pays",
      details: "",
    }
  }

  return (
    <ActivityForm
      title={<><Icon icon="travel" size={6} mr={2} /> Travel Plan</>}
      fields={(control) => (
        <>
          <DatePickerField
            showTimeSelect
            name="transactionDate"
            control={control}
            label="Date &amp; Time"
            placeholder="Select Date and Time"
          />
          <Select
            name="route"
            control={control}
            label="Route"
            options={[
              { label: "Arrival", value: "Arrival" },
              { label: "Departure", value: "Departure" },
            ]}
          />
          <Select
            name="travelType"
            control={control}
            label="Type"
            options={[
              { label: "Flight", value: "Flight" },
              { label: "Train", value: "Train" },
              { label: "Shuttle", value: "Shuttle" },
              { label: "Yacht", value: "Yacht" },
              { label: "Taxi", value: "Taxi" },
              { label: "Town Car", value: "Town Car" },
              { label: "Other", value: "Other" },
            ]}
          />
          <Select
            name="expensesPaidBy"
            control={control}
            label="Expenses"
            options={[
              { label: "Guest pays", value: "Guest pays" },
              { label: "Event pays", value: "Event pays" },
            ]}
          />
          <TextArea
            name="details"
            control={control}
            label="Details"
            placeholder="Additional details (optional)"
          />
        </>
      )}
      initialValues={initialValues}
      apiPayload={({
        transactionDate, details, expensesPaidBy, route, travelType,
      }) => ({
        type: "TravelPlansTransaction",
        transactionDate,
        details,
        data: {
          expensesPaidBy,
          route,
          travelType,
        },
      })}
      activity={activity}
    />
  )
}

export default TravelPlan
