import {
  createStandaloneToast,
} from "@chakra-ui/react"
import { ReactNode } from "react"
import chakraTheme from "theme/chakraTheme"

export interface ToastProps {
  id?: string
  title?: ReactNode
  description?: ReactNode
  duration?: number | null
  status?: "success" | "error" | "warning" | "info"
  isClosable?: boolean
}

export const successToast = ({
  title, description, duration = 5000, isClosable = true,
}: ToastProps) => {
  popToast({
    title, description, duration, isClosable, status: "success",
  })
}

export const infoToast = ({
  id, title, description, duration = 5000, isClosable = true,
}: ToastProps) => {
  popToast({
    id, title, description, duration, isClosable, status: "info",
  })
}

export const warningToast = ({
  title, description, duration = 5000, isClosable = true,
}: ToastProps) => {
  popToast({
    title, description, duration, isClosable, status: "warning",
  })
}

export const errorToast = ({
  title, description, duration = null, isClosable = true,
}: ToastProps = {
  title: "Something went wrong", description: "Please try again later.", duration: null, isClosable: true,
}) => {
  popToast({
    title, description, duration, isClosable, status: "error",
  })
}

export const handleError = (error) => {
  const title = error?.response?.data?.error?.userMessage
    || error?.response?.data?.error?.message
    || "There was a problem processing your request."

  errorToast({ title, description: error.response.status >= 500 ? "If the issue persists, contact support@diobox.com." : null })
}

export const popToast = ({
  id, title, description, duration, status, isClosable,
} : ToastProps) => {
  const { toast } = createStandaloneToast({ theme: chakraTheme })

  toast({
    id,
    title,
    description,
    status,
    position: "top-right",
    duration,
    isClosable,
  })
}
