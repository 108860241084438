import React from "react"
import Checkbox from "components/ReactHookForm/Checkbox"
import { useForm } from "react-hook-form"
import { DioboxEvent } from "sharedTypes"
import { successToast } from "utilities/toasts"
import { useDeepCompareEffect } from "ahooks"

type Props = {
  event: DioboxEvent
  updateEvent: (formData: DioboxEvent) => void
}

const DeferredTicketAssignmentEnabledCheckbox = ({ event, updateEvent }: Props) => {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: { deferredTicketAssignmentEnabled: event.deferredTicketAssignmentEnabled },
  })

  useDeepCompareEffect(() => {
    const subscription = watch(() => {
      handleSubmit(onSubmitDeferredTicketAssignmentEnabled)()
    })

    return () => subscription.unsubscribe()
  }, [watch])

  const onSubmitDeferredTicketAssignmentEnabled = async (formData) => {
    await updateEvent(formData)
    successToast({ title: "Deferred Guest Registrations Saved" })
  }

  return (
    <Checkbox
      name="deferredTicketAssignmentEnabled"
      control={control}
      label="Enable Deferred Guest Registrations"
    />
  )
}

export default DeferredTicketAssignmentEnabledCheckbox
