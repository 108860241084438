import React from "react"
import {
  Link, matchPath, useLocation, useRouteMatch,
} from "react-router-dom"
import { slugify } from "utilities/strings"
import SecondaryNavItem from "components/layout/SecondaryNavItem"
import { Box, Flex } from "@chakra-ui/react"
import SimpleBar from "simplebar-react"
import { SIDEBAR_WIDTHS } from "utilities/enums"

type Props = {
  routeMatch: string;
  items: ({ text: string; icon?: string; color?: string })[];
  w?: "sm" | "md" | "lg" | "xl";
}

const SecondaryNav = ({ routeMatch, items, w = "md" }: Props) => {
  const { url: rootPath } = useRouteMatch(routeMatch) || { url: "" }
  const { pathname } = useLocation()

  let selectedItemIndex = 0
  let defaultItemIndex

  items.forEach((item, index) => {
    if (defaultItemIndex === undefined) {
      defaultItemIndex = index
    }

    const itemPath = defaultItemIndex === index ? `${routeMatch}` : `${routeMatch}/${slugify(item.text)}`
    if (matchPath(pathname, itemPath)) {
      selectedItemIndex = index
    }
  })

  return (
    <Box
      w={SIDEBAR_WIDTHS[w!]}
      flexShrink={0}
      borderRightWidth={1}
      h="100%"
      as={SimpleBar}
      minWidth={360}
      maxWidth={500}
    >
      <Flex
        direction="column"
      >
        {items.map((item, index) => {
          const { text, icon, color } = item
          const path = defaultItemIndex === index ? `${rootPath}` : `${rootPath}/${slugify(item.text)}`

          return (
            <Link to={path} key={index}>
              <SecondaryNavItem
                icon={icon}
                color={color}
                selected={index === selectedItemIndex}
              >
                {text}
              </SecondaryNavItem>
            </Link>
          )
        })}
      </Flex>
    </Box>
  )
}

export default SecondaryNav
