import React from "react"
import { Box, Text } from "@chakra-ui/react"
import FormSection from "pages/EventShow/Forms/FormSection"
import FixedBlockTextInput from "./RegistrationFields/FixedBlockTextInput"
import FormHeader from "./FormHeader"
import FormSubmitButton from "./FormSubmitButton"

type Props = {
  submitButtonColor: string
  submitButtonText: string
  headerText: string
}

const FormPaymentFields = ({ submitButtonColor, submitButtonText, headerText }: Props) => (
  <>
    <FormHeader isSeparate={false}>
      <Text fontSize="2xl" fontWeight="semibold">{headerText}</Text>
    </FormHeader>
    <Box borderTopWidth={1} borderLeftWidth={1} borderRightWidth={1}>
      <FormSection>
        <Box px={6}>
          <FixedBlockTextInput
            name="Card Number"
            placeholder="1234 1234 1234 1234"
          />
        </Box>
      </FormSection>
    </Box>
    <Box borderTopWidth={1}>
      <FormSection last>
        <FormSubmitButton color={submitButtonColor}>{submitButtonText}
        </FormSubmitButton>
      </FormSection>
    </Box>
  </>
)

export default FormPaymentFields
