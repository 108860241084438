import React from "react"
import Menu, { MenuItem } from "components/dialogs/Menu"
import _ from "lodash"
import Icon from "components/elements/Icon"
import { Flex } from "@chakra-ui/react"
import attributeTypes from "../attributeTypes"

type Props = {
  onSelect: (field) => void;
  social: boolean;
}

const AddAttribute = ({ onSelect, social }: Props) => {
  const handleSelect = (field: {type: string, title?: string}) => {
    onSelect(field)
  }

  if (social) {
    return (
      <>
        <Menu
          button={<AddButton text="Add URL" />}
          menuItems={attributeTypes.SocialField.titles.map(
            (title) => (
              <MenuItem
                key={title}
                onClick={() => handleSelect({ type: "SocialField", title: _.lowerCase(title) })}
              >
                {title}
              </MenuItem>
            ),
          )}
        />
      </>
    )
  }

  return (
    <>
      <Menu
        button={<AddButton text="Add Field" />}
        menuItems={Object.entries(attributeTypes)
          .filter(([type]) => type !== "SocialField")
          .map(([type, { titles: [title] }]) => (
            <MenuItem
              key={type}
              onClick={() => handleSelect({ type, title: _.lowerCase(title) })}
            >
              {title}
            </MenuItem>
          ))}
      />
    </>
  )
}

const AddButton = ({ text }) => (
  <Flex align="center" color="blue.500" fontWeight="semibold">
    <Icon icon="add-solid" mr={2} /> {text}
  </Flex>
)

export default AddAttribute
