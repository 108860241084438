import ColorSelect from "components/ReactHookForm/ColorSelect/ColorSelect"
import SwitchField from "components/ReactHookForm/SwitchField"
import FileUpload from "components/ReactHookForm/FileUpload"
import React from "react"
import { Email } from "sharedTypes"
import { useController } from "react-hook-form"
import SidePanelHeader from "./components/SidePanelHeader"
import EditorSection from "./components/EditorSection"
import SidePanelForm from "./components/SidePanelForm"

type Props = {
  email: Email
  onChange: (email: Email) => void
  onCancel: () => void
}

const SettingsPanel = ({
  email: {
    backgroundColor, borderColor, emailAttachment: { fileName }, showUnsubscribe,
  }, onChange, onCancel,
}: Props) => {
  const initialValues = {
    backgroundColor: backgroundColor || "#FFFFFF",
    borderColor: borderColor || "#CCCCCC",
    showEmailAttachment: !!fileName,
    showUnsubscribe,
  }

  return (
    <SidePanelForm
      onChange={onChange}
      onCancel={onCancel}
      initialValues={initialValues}
    >
      {({ control }) => (
        <>
          <SidePanelHeader>Email Settings</SidePanelHeader>
          <EditorSection>
            <ColorSelect label="Bg Color" name="backgroundColor" control={control} colorScheme="light" />
          </EditorSection>
          <EditorSection>
            <ColorSelect label="Border Color" name="borderColor" control={control} />
          </EditorSection>
          <AttachmentSection fileName={fileName} control={control} />
          <EditorSection>
            <SwitchField label="Unsubscribe Link" name="showUnsubscribe" control={control} />
          </EditorSection>
        </>
      )}
    </SidePanelForm>
  )
}

const AttachmentSection = ({ fileName, control }) => {
  const { field: { value: showEmailAttachment } } = useController({ name: "showEmailAttachment", control })

  return (
    <EditorSection>
      <SwitchField label="Email Attachment" name="showEmailAttachment" control={control} />
      {showEmailAttachment && <FileUpload name="emailAttachment" fileName={fileName} control={control} />}
    </EditorSection>
  )
}

export default SettingsPanel
