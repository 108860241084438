import React, { ReactNode } from "react"
import { ListItem, Link as ChakraLink } from "@chakra-ui/react"
import { Link } from "react-router-dom"

interface EventResourcesListItemProps {
  children: ReactNode
  selected?: boolean
  path: string | { pathname: any; state: { background: any; }; }
}

const EventResourceListItem = ({
  selected, path, children,
}: EventResourcesListItemProps) => {
  const selectedProps = {
    bg: "ui.selectedItemBg",
  }

  return (
    <ListItem borderBottomWidth={1}>
      <ChakraLink
        {...selected && selectedProps}
        p={6}
        as={Link}
        to={path}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        _hover={{ textDecoration: "none" }}
      >
        {children}
      </ChakraLink>
    </ListItem>
  )
}

export default EventResourceListItem
