import React, { useState } from "react"
import { Flex, VStack } from "@chakra-ui/react"
import { useHistory, useLocation } from "react-router-dom"
import { loginPath } from "utilities/routes"
import SubmitButton from "components/Buttons/SubmitButton"
import { changePassword } from "api/sessions"
import Button from "components/Buttons/Button"
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import PasswordField from "components/ReactHookForm/PasswordField"
import AuthenticationWrapper from "./AuthenticationWrapper"

const ResetPassword = () => {
  const [changedPassword, setChangedPassword] = useState(false)
  const history = useHistory()
  const resetPasswordToken = new URLSearchParams(useLocation().search).get("reset_password_token") || ""

  const onSubmit = ({ password }) => changePassword(password, resetPasswordToken)
    .then(() => {
      setChangedPassword(true)
    })

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string().required().min(6),
  })

  const { control, handleSubmit, formState: { isValid, isSubmitting } } = useForm({
    defaultValues: { password: "" },
    resolver: yupResolver(ResetPasswordSchema),
    mode: "onChange",
  })

  const form = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack w="full" spacing={6}>
        <PasswordField
          name="password"
          control={control}
          placeholder="New password"
          helperText="Must contain at least 6 characters"
        />
        <SubmitButton
          text="Reset my password"
          disabled={!isValid}
          submitting={isSubmitting}
        />
      </VStack>
    </form>
  )

  const success = <Flex justify="center"><Button onClick={() => history.push(loginPath())}>Login</Button></Flex>

  return (
    <AuthenticationWrapper
      pageTitle={changedPassword ? "Your password has been reset!" : "Reset Password"}
      body={changedPassword ? success : form}
      header={null}
    />
  )
}

export default ResetPassword
