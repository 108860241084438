import Icon from "components/elements/Icon"
import React from "react"
import ImageUpload from "components/ReactHookForm/ImageUpload"
import { Box, Image } from "@chakra-ui/react"
import { Control, FieldValues } from "react-hook-form"
import Menu from "components/dialogs/Menu"
import GuestMenuItem from "../../Show/Menu/Item"

type Props<T extends FieldValues> = {
  name: string;
  control: Control<T, object>;
  alt: string;
  imageUrl: string;
}

const imagePlaceholderUrl = "https://data.diobox.com/app/web/assets/person/profile-unspecified.jpg"

const PhotoUpload = <T extends FieldValues, >({
  name, control, alt, imageUrl,
}: Props<T>) => (
  <ImageUpload
    name={name}
    control={control}
    alt={alt}
    imageUrl={imageUrl}
    hideImageBorder
    Component={({
      imagePreview, fileInputRef, onRemove, removed,
    }) => {
      const placeholder = imagePreview?.props.src === imagePlaceholderUrl

      return (
        <Box position="relative">
          <Box w={28} h={28} borderWidth={1} color="gray.500" borderRadius="full" overflow="hidden">
            {removed ? <Image w="full" h="full" src={imagePlaceholderUrl} /> : imagePreview}
          </Box>
          <Box
            as="button"
            position="absolute"
            w={7}
            h={7}
            right={0}
            bottom={0}
            borderWidth={1}
            borderColor="gray.500"
            bg="white"
            mr={4}
            borderRadius="full"
            type="button"
            title="Edit"
          >
            <Menu
              button={<Icon icon="pencil" color="blue.400" />}
              menuItems={[
                <GuestMenuItem
                  key="choose"
                  icon="speakers"
                  title="Choose Photo"
                  onClick={() => {
                    fileInputRef.current!.click()
                  }}
                />,
                (imagePreview && !removed && !placeholder) ? (
                  <Box key="delete" borderTopWidth={1}>
                    <GuestMenuItem
                      color="red.500"
                      icon="times"
                      title="Delete Photo"
                      onClick={onRemove}
                    />
                  </Box>
                ) : undefined,
              ]}
              noPadding
            />
          </Box>
        </Box>
      )
    }}
  />
  )

export default PhotoUpload
