import React, { MouseEvent, Fragment } from "react"
import { GuestSummary } from "sharedTypes"
import { deselectGuest, selectGuest } from "context/actions"
import { useCurrentEvent } from "queries/events"
import useGuestsStore from "services/useGuestsStore"
import { shallow } from "zustand/shallow"
import { sortPlusOnes } from "utilities/arrays"
import Item from "../Item"

type Props = {
  guests: GuestSummary[];
  order: string;
  filtered: boolean;
  allSelected?: boolean;
  multipleSelect?: boolean;
  multipleSelectEnabled?: boolean;
  showTables?: boolean;
  draggingEnabled?: boolean;
  onClickGuest?: ((guest: GuestSummary) => void) | boolean;
  disabledGuestIds?: string[];
  disableMainGuests?: boolean;
}

const GuestList = ({
  guests,
  order,
  allSelected,
  multipleSelect,
  multipleSelectEnabled,
  showTables,
  draggingEnabled,
  onClickGuest = false,
  disabledGuestIds,
  disableMainGuests,
  filtered,
}: Props) => {
  const { data: { tables } } = useCurrentEvent()
  const [
    selectedGuestIds,
    addSelectedGuestId,
    removeSelectedGuestId,
    deselectAll,
  ] = useGuestsStore(
    (state) => [
      state.selectedGuestIds,
      state.addSelectedGuestId,
      state.removeSelectedGuestId,
      state.deselectAll,
    ],
    shallow,
  )

  const handleSelectionChange = (event: MouseEvent<HTMLInputElement>, guest: GuestSummary) => {
    changeSelection((event.target as HTMLInputElement).checked, event.nativeEvent.shiftKey, guest)
  }
  const handleClick = (event: MouseEvent<HTMLInputElement>, guest: GuestSummary) => {
    if (!multipleSelectEnabled) {
      return
    }

    event.preventDefault()
    changeSelection(!selectedGuestIds.includes(guest.id), event.nativeEvent.shiftKey, guest)
  }

  const changeSelection = (selected: boolean, selectMultiple: boolean, guest: GuestSummary) => {
    if (allSelected) {
      deselectAll()

      return
    }

    if (selected) {
      if (selectMultiple && selectedGuestIds.length > 0) {
        guestsToSelect(guest).forEach((g) => selectGuest(addSelectedGuestId, g))
      } else {
        selectGuest(addSelectedGuestId, guest)
      }
    } else {
      deselectGuest(removeSelectedGuestId, guest)
    }
  }

  const guestsToSelect = (guest) => {
    const guestIndex = guests.findIndex((g) => g.id === guest.id)
    const firstSelectedGuestIndex = guests.findIndex((g) => g.id === selectedGuestIds[0])

    if (guestIndex < firstSelectedGuestIndex) {
      return guests.slice(guestIndex, firstSelectedGuestIndex)
    }

    const lastSelectedGuestIndex = guests.findIndex(
      (g) => g.id === selectedGuestIds[selectedGuestIds.length - 1],
    )

    return guests.slice(lastSelectedGuestIndex + 1, guestIndex + 1)
  }

  const guestProps = (guest) => ({
    guest,
    onSelectionChange: (event) => handleSelectionChange(event, guest),
    onClick: (event) => handleClick(event, guest),
    multipleSelect,
    multipleSelectEnabled,
    tables,
    showTables,
    draggingEnabled,
    onClickGuest,
    selected: allSelected || selectedGuestIds.includes(guest.id),
    order,
    filtered,
  })

  return (
    <>
      {guests.map((guest) => {
        const { plusOnes } = guest

        return (
          <Fragment key={guest.id}>
            <Item
              {...guestProps(guest)}
              disabled={
                (disableMainGuests && !!plusOnes?.length)
                || disabledGuestIds?.includes(guest.id)
              }
            />
            {((!filtered) && plusOnes) && sortPlusOnes(plusOnes).map((plusOne) => (
              <Item
                plusOne
                key={plusOne.id}
                {...guestProps(plusOne)}
                disabled={disabledGuestIds?.includes(plusOne.id)}
              />
            ))}
          </Fragment>
        )
      })}
    </>
  )
}

export default GuestList
