import Link from "components/elements/Link"
import TextField from "components/ReactHookForm/TextField"
import SubmitButton from "components/Buttons/SubmitButton"
import React, { useEffect, useState } from "react"
import { Guest, ModalName } from "sharedTypes"
import { Redirect, useHistory } from "react-router-dom"
import _ from "lodash"
import * as api from "api/guests"
import * as Yup from "yup"
import { Box, Flex, HStack } from "@chakra-ui/react"
import { warningToast } from "utilities/toasts"
import useModal from "services/useModal"
import { yupResolver } from "@hookform/resolvers/yup"
import { useFieldArray, useForm } from "react-hook-form"
import SimpleBar from "simplebar-react"
import { EMAIL_VALIDATION_REGEX } from "utilities/constants"
import { useEventGuestPaths } from "utilities/routes"
import PhotoUpload from "./PhotoUpload"
import Attribute from "./Attribute"
import AddAttribute from "./AddAttribute"
import attributeTypes from "./attributeTypes"
import { sortedValidAttributeTypes } from "../Show/AttributeList"

type Props = {
  eventId: string;
  guest: Guest;
  updateGuest: (params: {}) => Promise<void>;
  enrich: boolean;
  enrichEnabled: boolean;
}

const extendWithDefaultFields = (guest: Guest) => {
  const guestCopy = _.cloneDeep(guest)

  const defaultFields = [
    {
      type: "AddressField",
      title: "address",
      value: {
        city: undefined,
        country: undefined,
        line1: undefined,
        line2: undefined,
        state: undefined,
        zip: undefined,
      },
      default: true,
    },
    {
      type: "EmailField",
      title: "email",
      value: "",
      default: true,
    },
    {
      type: "PhoneField",
      title: "phone",
      value: "",
      default: true,
    },
  ].filter(({ type }) => !guestCopy.personInfo.customFields.some((field) => field.type === type))

  const defaultSocialFields = attributeTypes.SocialField.titles.map((title) => ({
    type: "SocialField",
    title: title.toLowerCase(),
    value: "",
    default: true,
  })).filter(({ title }) => !guestCopy.personInfo.customFields.some((field) => field.type === "SocialField" && field.title === title))

  guestCopy.personInfo.customFields = [
    ...guestCopy.personInfo.customFields,
    ...defaultFields,
    ...defaultSocialFields,
  ]

  return guestCopy
}

const baseFields = [
  "photoUrl",
  "firstName",
  "lastName",
  "companyName",
  "companyTitle",
  "birthDate",
  "gender",
  "notes",
]

const personInfosAreEqual = (a, b) => {
  if (
    baseFields.some((fieldName) => a[fieldName] !== b[fieldName])
    || a.customFields.some(({ id }, index) => b.customFields[index].id !== id)
  ) {
    return false
  }

  return true
}

const Edit = ({
  eventId, guest: initialGuest, updateGuest, enrich, enrichEnabled,
}: Props) => {
  const showModal = useModal()
  const history = useHistory()
  const [guest, setGuest] = useState(extendWithDefaultFields(initialGuest))
  const [customFields, setCustomFields] = useState(
    extendWithDefaultFields(initialGuest).personInfo.customFields,
  )

  useEffect(() => {
    if (enrich && enrichEnabled) {
      api.putGuestEnrich(eventId, guest.personInfo.id)
        .then((response) => {
          if (personInfosAreEqual(initialGuest.personInfo, response.data)) {
            warningToast({ title: "No additional information was found online." })

            return
          }

          setGuest((previousGuest) => ({
            ...previousGuest,
            personInfo: response.data,
          }))
        })
    }
  }, [])

  const addAttribute = (field, append) => {
    append({ ...field })

    setCustomFields((prevFields) => [...prevFields, field])
  }

  const deleteAttribute = (attributeId, remove, index, update, name, field) => {
    if (!attributeId) {
      remove(index)
      setCustomFields((prevFields) => {
        const updatedFields = [...prevFields]
        updatedFields.splice(index, 1)

        return updatedFields
      })
    } else {
      update(index, { ...field, _destroy: true })
      setCustomFields((prevFields) => {
        const updatedFields = [...prevFields]
        const indexOfFieldToDelete = updatedFields.findIndex(
          (item) => item.id === attributeId,
        )

        updatedFields[indexOfFieldToDelete] = {
          ...updatedFields[indexOfFieldToDelete],
          _destroy: true,
        }

        return updatedFields
      })
    }
  }

  const withoutEmptyFields = (values: Guest) => ({
    ...values,
    personInfo: {
      ...values.personInfo,
      customFields: values.personInfo.customFields?.filter(
        ({ value, _destroy }) => _destroy || (!!value && !_.isEmpty(value)),
      ),
    },
  })

  const withoutEnrichFlags = (guestWithEnrichFlags): Guest => ({
    ...guestWithEnrichFlags,
    personInfo: {
      ...guestWithEnrichFlags.personInfo,
      customFields: guestWithEnrichFlags.personInfo.customFields?.map(
        ({ enriched, ...customField }) => customField,
      ),
    },
  })

  const saveGuest = async (values, photoUrl?: string) => {
    const guestData = withoutEmptyFields(withoutEnrichFlags(values))
    if (photoUrl) {
      guestData.personInfo.photoUrl = photoUrl
    }

    await updateGuest(guestData)
    history.go(-1)
  }

  const onSubmit = async (personInfo) => {
    const updatedGuest = {
      ...guest,
      personInfo: {
        ...guest.personInfo,
        ..._.omit(personInfo, ["socialFields", "otherFields"]),
        customFields: [...personInfo.otherFields, ...personInfo.socialFields],
      },
    }

    if (typeof personInfo.photoUrl === "object") {
      const { data } = await api.putGuestProfilePicture(
        eventId,
        updatedGuest.personInfo.id,
        personInfo.photoUrl,
      )

      return saveGuest(updatedGuest, data.photoUrl)
    }

    return saveGuest(updatedGuest)
  }

  const isEnriched = (path: string) => {
    if (!enrich) {
      return false
    }

    const initial = _.get(initialGuest.personInfo, path)
    const current = _.get(guest.personInfo, path)

    if (!path.includes("customFields.")) {
      return (
        current
        && initial !== current
      )
    }

    if (initialGuest.personInfo.customFields.some(({ id }) => id === current?.id)) {
      return false
    }

    return true
  }

  const {
    photoUrl,
    email: primaryEmail,
  } = guest.personInfo

  const customFieldsWithEnrich = customFields.map(
    (field, index) => ({
      ...field,
      enriched: isEnriched(`customFields.${index}`),
    }),
  )

  const socialFields = customFieldsWithEnrich.filter(({ type }) => type === "SocialField")

  const defaultValues = {
    ..._.pick(guest.personInfo, baseFields),
    socialFields,
    otherFields: customFields.filter(({ type }) => sortedValidAttributeTypes.includes(type)),
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    otherFields: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().nullable(),
        title: Yup.string().nullable(),
        id: Yup.string().nullable(),
        default: Yup.boolean().nullable(),
        _destroy: Yup.boolean().nullable(),
        value: Yup.lazy((value) => {
          if (typeof value === "string") {
            return Yup.string().when(["type", "_destroy"], {
              is: (type, _destroy) => (type === "EmailField" && !_destroy),
              then: (schema) => schema.matches(EMAIL_VALIDATION_REGEX, "Invalid email address"),
              otherwise: (schema) => schema,
            })
          }

          return Yup.mixed().nullable()
        }),
      }),
    ),
  })

  const {
    control, handleSubmit, formState: { isSubmitting, isValid, errors }, reset, trigger,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema) as any,
    mode: "onChange",
  })

  useEffect(() => {
    reset(defaultValues)
    trigger()
  }, [guest])

  const { eventGuestPath } = useEventGuestPaths()

  if (enrich && !enrichEnabled) {
    showModal(ModalName.EnrichDisabled)

    return <Redirect to={eventGuestPath(eventId, guest.id)} />
  }

  return (
    <Box as="form" h="full" onSubmit={handleSubmit(onSubmit)} autoComplete="disabled">
      <Flex direction="column" align="stretch" px={5} h="full">
        <Flex justify="space-between" align="center" bg="white" borderBottomWidth={1}>
          <Flex mr={8}>
            <Box position="relative">
              <PhotoUpload
                name="photoUrl"
                control={control}
                alt="Profile Picture"
                imageUrl={photoUrl}
              />
            </Box>
            <HStack spacing={8} ml={8} maxW={80}>
              <TextField
                name="firstName"
                control={control}
                placeholder="First name"
                variant="unstyled"
                size="xl"
                mb={0}
                color={isEnriched("firstName") ? "orange.500" : ""}
              />
              <TextField
                name="lastName"
                control={control}
                placeholder="Last name"
                variant="unstyled"
                size="xl"
                mb={0}
                color={isEnriched("lastName") ? "orange.500" : ""}
              />
            </HStack>
          </Flex>
          <HStack spacing={6}>
            <Link onClick={() => history.go(-1)}>Cancel</Link>
            <SubmitButton submitting={isSubmitting} disabled={!isValid} text="Save" />
          </HStack>
        </Flex>
        <Box height={100} flex={1} as={SimpleBar}>
          <Attribute
            label="Company"
            name="companyName"
            control={control}
            placeholder="Organization"
            enriched={isEnriched("companyName")}
          />
          <Attribute
            label="Title"
            name="companyTitle"
            control={control}
            placeholder="Job Title"
            enriched={isEnriched("companyTitle")}
          />
          <Attribute
            label="Birthdate"
            name="birthDate"
            control={control}
            type="date"
            placeholder="Enter Birth Date"
            enriched={isEnriched("birthDate")}
          />
          <Attribute
            label="Gender"
            name="gender"
            control={control}
            type="checkboxes"
            options={[{ label: "Unspecified", value: "" }, {
              label: "Male",
              value: "male",
            }, { label: "Female", value: "female" }]}
            enriched={isEnriched("gender")}
          />
          <Attribute
            label="Notes"
            name="notes"
            control={control}
            type="textarea"
          />
          <CustomAttributes name="otherFields" control={control} addAttribute={addAttribute} deleteAttribute={deleteAttribute} primaryEmail={primaryEmail} errors={errors} />
          <CustomAttributes name="socialFields" control={control} addAttribute={addAttribute} deleteAttribute={deleteAttribute} primaryEmail={primaryEmail} errors={errors} />
        </Box>
      </Flex>
    </Box>
  )
}

const CustomAttributes = ({
  name, control, addAttribute, deleteAttribute, primaryEmail, errors,
}) => {
  const {
    fields, append, remove, update,
  } = useFieldArray<any, any, any>({ name, control, keyName: "key" })

  const emailCount = fields.filter(({ type, _destroy }) => type === "EmailField" && !_destroy).length

  return (
    <>
      {(fields as unknown as {
        key: string, id: number, type: string, title: string, value: string, enriched: boolean,
        _destroy: boolean,
      }[]).map((field, index) => {
        const {
          key, id, type, title, value, enriched, _destroy,
        } = field

        return (
          <>
            <Attribute
              key={key}
              id={id}
              path={`${name}[${index}]`}
              name="value"
              title={title}
              type={type}
              onDelete={() => deleteAttribute(id, remove, index, update, name, field)}
              deleted={_destroy}
              enriched={enriched}
              control={control}
              primary={emailCount > 1 && type === "EmailField" && value === primaryEmail}
              errorMessage={errors.otherFields && errors[name]?.[index]?.value.message}
            />
          </>
        )
      })}
      <Box py={5}>
        <AddAttribute
          key="add"
          onSelect={(field) => addAttribute(field, append)}
          social={name === "socialFields"}
        />
      </Box>
    </>
  )
}

export default Edit
