import {
  get, post, put, del,
} from "services/api"
import { currentAccountId } from "services/authentication"
import {
  Form, FormQuestion, FormSubmissionSortOption, PaginationMeta, Submission, EventQuestionResponse,
} from "sharedTypes"

const formsBaseURL = (eventId: string) => `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/forms`

export const getForms = (eventId: string, page: number, perPage?: number) => get<{data: Form[], meta: PaginationMeta}>(`${formsBaseURL(eventId)}.json?page=${page}&per=${perPage}`)

export const getForm = (eventId: string, formId: string) => get<Form>(`${formsBaseURL(eventId)}/${formId}.json`)

export const postForm = (eventId: string, form: Partial<Form>) => post<Form>(`${formsBaseURL(eventId)}.json`, form)

export const patchForm = (eventId: string, formId: string, form: Form | Partial<Form>) => put<Form>(`${formsBaseURL(eventId)}/${formId}.json`, { form })

export const deleteForm = (eventId: string, formId: string) => del<void>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/forms/${formId}.json`)

export const duplicateForm = (eventId: string, formId: string) => post<void>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/forms/${formId}/duplicate.json`)

export const deleteFormSubmissionLineItem = (
  eventId: string,
  id: string,
) => del<void>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/form_submission_line_items/${id}.json`, {})

export const eventFormUploadsUrl = (eventId: string, formId: string) => `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/forms/${formId}/uploads.json`

export const getEventQuestions = (eventId: string, page: number) => get<{data: FormQuestion[], meta: PaginationMeta}>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/questions.json?page=${page}`)

export const getEventQuestion = (eventId: string, questionId: string) => get<FormQuestion>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/questions/${questionId}.json`)

export const postEventQuestion = (eventId: string, question: FormQuestion) => post<FormQuestion>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/questions.json`, question)

export const putEventQuestion = (eventId: string, questionId: string, question: FormQuestion) => put<FormQuestion>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/questions/${questionId}.json`, question)

export const getEventQuestionResponses = (eventId: string, questionId: string, page: number) => get<{ data: EventQuestionResponse[], meta: PaginationMeta }>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/questions/${questionId}/responses.json?page=${page}`)

export const deleteEventQuestion = (eventId: string, questionId: string) => del<FormQuestion>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/questions/${questionId}.json`)

export const getEventFormSubmissions = (
  eventId: string,
  page: number,
  sort?: FormSubmissionSortOption,
  formIds?: string[],
  types?: string[],
) => {
  const url = new URL(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/form_submissions.json`)

  const params = new URLSearchParams()
  params.append("page", page.toString())

  if (sort) {
    params.append("sort", sort)
  }

  if (formIds) {
    formIds.forEach((formId) => { params.append("form_ids[]", formId) })
  }

  if (types) {
    types.forEach((type) => { params.append("types[]", type) })
  }

  url.search = params.toString()

  return get<{data: Submission[], meta: PaginationMeta}>(
    decodeURIComponent(url.toString()),
  )
}

export const getEventFormSubmission = (eventId: string, submissionId: string) => get<Submission>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/form_submissions/${submissionId}.json`, { bypass404redirection: true })
