import React from "react"
import SectionV2 from "components/layout/SectionV2"
import { Website } from "sharedTypes"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import RichTextField, { RichTextFieldType } from "components/ReactHookForm/RichTextField"
import { Box } from "@chakra-ui/react"

type Props = {
  website: Website;
  updateWebsite: (params: {}) => Promise<void>;
}

const PageContent = ({ website, updateWebsite }: Props) => {
  const { content } = website
  const handleSubmit = (values) => updateWebsite(values)

  return (
    <>
      <SectionV2
        title="Page Content"
        subtitle="Edit the content of your event webpage."
      >
        <ContainedForm
          values={{ content }}
          onSubmit={handleSubmit}
          noPadding
          successMessage="Page content updated"
        >
          {({ control }) => (
            <Box borderRadius="xl" overflow="hidden">
              <RichTextField name="content" control={control} type={RichTextFieldType.WEBSITE} className="website-editor" />
            </Box>
          )}
        </ContainedForm>
      </SectionV2>
    </>
  )
}

export default PageContent
