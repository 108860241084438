import React from "react"
import { Box, Heading, Stack } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { useToggle } from "ahooks"
import DocsLink, { Props as DocsLinkProps } from "components/DocsLink"
import StatisticsBlock, { Props as StatisticsProps } from "./StatisticsBlock"
import Step, { Props as StepProps } from "./Step"
import Section from "./Section"

type Props = {
  title: string
  icon: string
  statistics: StatisticsProps[]
  steps: StepProps[]
  externalLinks: DocsLinkProps[]
  showChecklist: boolean
}

const StatsSection = ({
  title,
  icon,
  statistics,
  steps,
  externalLinks,
  showChecklist,
}: Props) => (
  <Section
    title={title}
    icon={icon}
  >
    <Stack spacing={4} mt={10}>
      {statistics.map((stat) => (
        <StatisticsBlock {...stat} />
      ))}
      {showChecklist && <Checklist items={steps} externalLinks={externalLinks} />}
    </Stack>
  </Section>
)

const Checklist = ({ items, externalLinks }) => {
  const [expanded, { toggle }] = useToggle(true)

  return (
    <Box pt={5}>
      <Heading fontSize="md" onClick={() => toggle()} cursor="pointer"><Icon icon={`${expanded ? "down" : "up"}-arrow`} m={1} /> Checklist</Heading>
      {expanded && (
        <>
          <Stack pl={6} pt={6} spacing={3}>
            {items.map((step) => (
              <Step {...step} />
            ))}
          </Stack>
          <Stack mt={6} px={6} spacing={5}>
            {externalLinks.map((link) => <DocsLink {...link} fontWeight="semibold" />)}
          </Stack>
        </>
      )}
    </Box>
  )
}

export default StatsSection
