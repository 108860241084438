import React from "react"
import _ from "lodash"
import SeatingTable from "components/elements/SeatingTable"
import * as api from "api/tables"
import { addTable, updateTable } from "context/actions"
import { Box } from "@chakra-ui/react"
import * as Yup from "yup"
import { Table, TableShapes } from "sharedTypes"
import { useQueryClient } from "react-query"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextField from "components/ReactHookForm/TextField"
import Slider from "components/ReactHookForm/Slider"
import ToggleButtons from "components/ReactHookForm/ToggleButtons"
import ToggleButton from "components/ReactHookForm/ToggleButton"

interface Props {
  eventId: string
  tableId: string|undefined
  tables: Table[]
  onHide: () => void
}

const TableForm = ({
  eventId, tableId, tables, onHide,
}: Props) => {
  const queryClient = useQueryClient()

  let title
  let initialValues
  let onSubmit

  if (tableId) {
    const table = tables.find(({ id }) => id.toString() === tableId.toString()) as Table

    initialValues = _.pick(table, ["name", "notes", "shape", "topSize", "bottomSize", "rightSize", "leftSize"])
    title = "Update Attributes"
    onSubmit = (values) => api.putTable(eventId, {
      externalId: table.externalId,
      ...tableAttributes(values),
      seats: table.seats,
    }).then(({ data }) => {
      updateTable(queryClient, eventId, data)
      onHide()
    })
  } else {
    title = "New Table"
    initialValues = {
      name: "",
      notes: "",
      shape: TableShapes.Circle,
      topSize: 6,
      bottomSize: 0,
      rightSize: 0,
      leftSize: 0,
    }
    onSubmit = (values) => api.postTable(eventId, tableAttributes(values)).then(({ data }) => {
      addTable(queryClient, eventId, data)
      onHide()
    })
  }

  return (
    <ModalForm
      title={title}
      onSubmit={onSubmit}
      onClose={onHide}
      validationSchema={validationSchema}
      validate={(values) => {
        const {
          topSize, bottomSize, leftSize, rightSize,
        } = tableAttributes(values)
        const totalSeats = topSize + bottomSize + leftSize + rightSize

        if (!totalSeats) {
          return { topSize: "No seats." }
        }

        return {}
      }}
      initialValues={initialValues}
      size="6xl"
    >
      {({ control, watch }) => {
        const [shape, topSize, bottomSize, rightSize, leftSize] = watch(
          ["shape", "topSize", "bottomSize", "rightSize", "leftSize"],
        )

        const max = 20
        let topLabel: string
        let maxTop: number
        switch (shape) {
          case TableShapes.Circle:
            topLabel = "Total"
            maxTop = 40
            break
          case TableShapes.Row:
            topLabel = "Total"
            maxTop = 100
            break
          default:
            topLabel = "Top"
            maxTop = 20
        }

        return (
          <>
            <Box maxW="4xl" mx="auto">
              <TextField name="name" control={control} label="Table Name" />
              <TextField name="notes" control={control} label="Notes (optional)" />
              <ShapeSelect control={control} />
              <Box maxW="xl" mx="auto">
                <Slider name="topSize" control={control} label={topLabel} max={maxTop} />
                {
                  [TableShapes.HalfRound, TableShapes.Square, TableShapes.Rectangle].includes(shape)
                  && <Slider name="bottomSize" control={control} label="Bottom" max={max} />
                }
                {
                  [TableShapes.Square, TableShapes.Rectangle].includes(shape)
                  && (
                    <>
                      <Slider name="rightSize" control={control} label="Right" max={max} />
                      <Slider name="leftSize" control={control} label="Left" max={max} />
                    </>
                  )
                }
              </Box>
            </Box>
            <SeatingTable
              id={`form${tableId}`}
              shape={shape}
              topSeats={topSize}
              bottomSeats={bottomSize}
              rightSeats={rightSize}
              leftSeats={leftSize}
            />
          </>
        )
      }}
    </ModalForm>
  )
}

const ShapeSelect = ({ control }) => (
  <Box my={8}>
    <ToggleButtons name="shape" control={control} columns={5}>
      {Object.values(TableShapes).map((shape) => ({
        button: (selected) => (
          <ToggleButton
            title={_.startCase(shape).replace(" ", "-")}
            icon={`table-${shape}`}
            selected={selected}
          />
        ),
        value: shape,
      }))}
    </ToggleButtons>
  </Box>
)

const tableAttributes = ({
  name, notes, shape, topSize, bottomSize, rightSize, leftSize, seats = [],
}) => {
  const zeroSizes = {
    name, notes, shape, topSize: 0, bottomSize: 0, rightSize: 0, leftSize: 0, seats,
  }
  switch (shape) {
    case TableShapes.Circle:
    case TableShapes.Row:
      return { ...zeroSizes, topSize }
    case TableShapes.HalfRound:
      return { ...zeroSizes, topSize, bottomSize }
    case TableShapes.Square:
    case TableShapes.Rectangle:
      return {
        ...zeroSizes, topSize, bottomSize, rightSize, leftSize,
      }
    default:
      return zeroSizes
  }
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  notes: Yup.string().nullable(),
  shape: Yup.string(),
  topSize: Yup.number(),
  bottomSize: Yup.number(),
  rightSize: Yup.number(),
  leftSize: Yup.number(),
})

export default TableForm
