import React from "react"
import DocsLink from "components/DocsLink"
import { FormType } from "sharedTypes"
import { DIOBOX_FORMS_ARTICLE_LINK } from "utilities/externalLinks"
import UpgradeAlert from "components/elements/UpgradeAlert"

type Props = {
  formType: FormType
}

const FormUpgradeBox = ({ formType }: Props) => {
  const docsLink = (
    <DocsLink url={DIOBOX_FORMS_ARTICLE_LINK} display="block" mt={2}>
      Learn more about Diobox Forms
    </DocsLink>
  )

  switch (formType) {
    case FormType.Waitlist:
      return (
        <UpgradeAlert
          title="Publish this form with a Corporate Subscription"
          text={(
            <>
              A Waitlist registration form allows you to have prospective guests apply and join a
              waiting list for an event, enabling you to later qualify and either confirm or decline
              their attendance.
              {docsLink}
            </>
          )}
        />
      )
    case FormType.RSVP:
      return (
        <UpgradeAlert
          title="Publish this form with a Professional Subscription"
          text={(
            <>
              An RSVP form facilitates exclusive event registration by creating unique,
              non-transferable RSVP links for each invitee, ensuring that only the intended
              recipients can register, preventing the sharing of email invites.<br />
              {docsLink}
            </>
        )}
        />
      )
    case FormType.Survey:
      return (
        <UpgradeAlert
          title="This form requires a Corporate Subscription"
          text={(
            <>
              A survey form allows you to collect responses from your guests before or
              after the event using a unique link generated for each guest. This ensures
              that responses are tracked, and each guest can submit answers only once.<br />
              {docsLink}
            </>
        )}
        />
      )
    default:
      return null
  }
}

export default FormUpgradeBox
