import React from "react"
import { Flex, Text } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import SubmitButton from "components/Buttons/SubmitButton"
import Select from "components/ReactHookForm/Select"
import { useForm } from "react-hook-form"
import { Boolean } from "utilities/enums"
import { successToast } from "utilities/toasts"
import * as Yup from "yup"

type Props = {
  published: boolean
  onClose: () => void
  updateWebsite: (params: {}) => Promise<void>;
}

const EditMode = ({ published, onClose, updateWebsite }: Props) => {
  const Schema = Yup.object().shape({
    published: Yup.boolean(),
  })

  const { control, handleSubmit, formState: { isSubmitting, isValid } } = useForm({
    defaultValues: { published },
    resolver: yupResolver(Schema),
    mode: "onChange",
  })

  const onSubmit = async (values) => {
    await updateWebsite(values)
    onClose()
    successToast({ title: `Website ${values.published ? "published" : "unpublished"}` })
  }

  const options = [{ label: "Published", value: Boolean.True }, { label: "Draft", value: Boolean.False }]

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text fontWeight="semibold">Status</Text>
      <Flex justifyContent="space-between" align="baseline">
        <Flex align="baseline">
          <Select
            vertical
            name="published"
            control={control}
            options={options}
          />
        </Flex>
        <SubmitButton
          text="Save"
          size="sm"
          disabled={!isValid}
          submitting={isSubmitting}
        />
      </Flex>
    </form>
  )
}

export default EditMode
