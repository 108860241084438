import React from "react"
import { Heading, SimpleGrid, Stack } from "@chakra-ui/react"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import EditorSection from "components/Builder/components/EditorSection"
import { MERGE_TAGS } from "utilities/enums"
import Button from "components/Buttons/Button"
import HTMLEditor from "components/ReactHookForm/HTMLEditor"
import { Control } from "react-hook-form"
import withMergeTags from "../withMergeTags"

type Props = {
  name: string
  control: Control
  insertTag: (id: string) => void
  handleSelectionChange: () => void
}
const HtmlFields = withMergeTags(({
  name, control, insertTag, handleSelectionChange,
}: Props) => (
  <Stack height="full">
    <SidePanelHeader>HTML Block</SidePanelHeader>
    <EditorSection grow>
      <Heading size="xs" mb={2} fontStyle="normal">Merge Fields</Heading>
      <SimpleGrid columns={2} spacing={4} my={2}>
        {MERGE_TAGS.map(({ id, label }) => (
          <Button key={id} size="xs" variant="outline" onClick={() => insertTag(id)}>{label}</Button>
        ))}
      </SimpleGrid>
      <HTMLEditor name={name} control={control} fullHeight onBlur={handleSelectionChange} />
    </EditorSection>
  </Stack>
), "html")

export default HtmlFields
