export default [
  {
    timeZoneId: "Pacific/Pago_Pago",
    label: "UTC -11:00 Pago Pago, American Samoa",
    numericOffset: -39600000,
  },
  {
    timeZoneId: "Pacific/Niue",
    label: "UTC -11:00 Niue, Niue",
    numericOffset: -39600000,
  },
  {
    timeZoneId: "Pacific/Midway",
    label: "UTC -11:00 Midway, United States Minor Outlying Islands",
    numericOffset: -39600000,
  },
  {
    timeZoneId: "Pacific/Rarotonga",
    label: "UTC -10:00 Rarotonga, Cook Islands",
    numericOffset: -36000000,
  },
  {
    timeZoneId: "Pacific/Tahiti",
    label: "UTC -10:00 Tahiti, French Polynesia",
    numericOffset: -36000000,
  },
  {
    timeZoneId: "America/Adak",
    label: "UTC -10:00 Adak, United States",
    numericOffset: -36000000,
  },
  {
    timeZoneId: "Pacific/Honolulu",
    label: "UTC -10:00 Honolulu, United States",
    numericOffset: -36000000,
  },
  {
    timeZoneId: "Pacific/Marquesas",
    label: "UTC -09:30 Marquesas, French Polynesia",
    numericOffset: -34200000,
  },
  {
    timeZoneId: "Pacific/Gambier",
    label: "UTC -09:00 Gambier, French Polynesia",
    numericOffset: -32400000,
  },
  {
    timeZoneId: "America/Anchorage",
    label: "UTC -09:00 Anchorage, United States",
    numericOffset: -32400000,
  },
  {
    timeZoneId: "America/Juneau",
    label: "UTC -09:00 Juneau, United States",
    numericOffset: -32400000,
  },
  {
    timeZoneId: "America/Metlakatla",
    label: "UTC -09:00 Metlakatla, United States",
    numericOffset: -32400000,
  },
  {
    timeZoneId: "America/Nome",
    label: "UTC -09:00 Nome, United States",
    numericOffset: -32400000,
  },
  {
    timeZoneId: "America/Sitka",
    label: "UTC -09:00 Sitka, United States",
    numericOffset: -32400000,
  },
  {
    timeZoneId: "America/Yakutat",
    label: "UTC -09:00 Yakutat, United States",
    numericOffset: -32400000,
  },
  {
    timeZoneId: "America/Vancouver",
    label: "UTC -08:00 Vancouver, Canada",
    numericOffset: -28800000,
  },
  {
    timeZoneId: "America/Tijuana",
    label: "UTC -08:00 Tijuana, Mexico",
    numericOffset: -28800000,
  },
  {
    timeZoneId: "Pacific/Pitcairn",
    label: "UTC -08:00 Pitcairn, Pitcairn",
    numericOffset: -28800000,
  },
  {
    timeZoneId: "America/Los_Angeles",
    label: "UTC -08:00 Los Angeles, United States",
    numericOffset: -28800000,
  },
  {
    timeZoneId: "America/Cambridge_Bay",
    label: "UTC -07:00 Cambridge Bay, Canada",
    numericOffset: -25200000,
  },
  {
    timeZoneId: "America/Creston",
    label: "UTC -07:00 Creston, Canada",
    numericOffset: -25200000,
  },
  {
    timeZoneId: "America/Dawson",
    label: "UTC -07:00 Dawson, Canada",
    numericOffset: -25200000,
  },
  {
    timeZoneId: "America/Dawson_Creek",
    label: "UTC -07:00 Dawson Creek, Canada",
    numericOffset: -25200000,
  },
  {
    timeZoneId: "America/Edmonton",
    label: "UTC -07:00 Edmonton, Canada",
    numericOffset: -25200000,
  },
  {
    timeZoneId: "America/Fort_Nelson",
    label: "UTC -07:00 Fort Nelson, Canada",
    numericOffset: -25200000,
  },
  {
    timeZoneId: "America/Inuvik",
    label: "UTC -07:00 Inuvik, Canada",
    numericOffset: -25200000,
  },
  {
    timeZoneId: "America/Whitehorse",
    label: "UTC -07:00 Whitehorse, Canada",
    numericOffset: -25200000,
  },
  {
    timeZoneId: "America/Ciudad_Juarez",
    label: "UTC -07:00 Ciudad Juarez, Mexico",
    numericOffset: -25200000,
  },
  {
    timeZoneId: "America/Hermosillo",
    label: "UTC -07:00 Hermosillo, Mexico",
    numericOffset: -25200000,
  },
  {
    timeZoneId: "America/Mazatlan",
    label: "UTC -07:00 Mazatlan, Mexico",
    numericOffset: -25200000,
  },
  {
    timeZoneId: "America/Boise",
    label: "UTC -07:00 Boise, United States",
    numericOffset: -25200000,
  },
  {
    timeZoneId: "America/Denver",
    label: "UTC -07:00 Denver, United States",
    numericOffset: -25200000,
  },
  {
    timeZoneId: "America/Phoenix",
    label: "UTC -07:00 Phoenix, United States",
    numericOffset: -25200000,
  },
  {
    timeZoneId: "America/Belize",
    label: "UTC -06:00 Belize, Belize",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Rankin_Inlet",
    label: "UTC -06:00 Rankin Inlet, Canada",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Regina",
    label: "UTC -06:00 Regina, Canada",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Resolute",
    label: "UTC -06:00 Resolute, Canada",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Swift_Current",
    label: "UTC -06:00 Swift Current, Canada",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Winnipeg",
    label: "UTC -06:00 Winnipeg, Canada",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Costa_Rica",
    label: "UTC -06:00 Costa Rica, Costa Rica",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "Pacific/Galapagos",
    label: "UTC -06:00 Galapagos, Ecuador",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/El_Salvador",
    label: "UTC -06:00 El Salvador, El Salvador",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Guatemala",
    label: "UTC -06:00 Guatemala, Guatemala",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Tegucigalpa",
    label: "UTC -06:00 Tegucigalpa, Honduras",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Bahia_Banderas",
    label: "UTC -06:00 Bahia Banderas, Mexico",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Chihuahua",
    label: "UTC -06:00 Chihuahua, Mexico",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Matamoros",
    label: "UTC -06:00 Matamoros, Mexico",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Merida",
    label: "UTC -06:00 Merida, Mexico",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Mexico_City",
    label: "UTC -06:00 Mexico City, Mexico",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Monterrey",
    label: "UTC -06:00 Monterrey, Mexico",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Ojinaga",
    label: "UTC -06:00 Ojinaga, Mexico",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Managua",
    label: "UTC -06:00 Managua, Nicaragua",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Chicago",
    label: "UTC -06:00 Chicago, United States",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Indiana/Knox",
    label: "UTC -06:00 Knox, United States",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Indiana/Tell_City",
    label: "UTC -06:00 Tell City, United States",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Menominee",
    label: "UTC -06:00 Menominee, United States",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/North_Dakota/Beulah",
    label: "UTC -06:00 Beulah, United States",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/North_Dakota/Center",
    label: "UTC -06:00 Center, United States",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/North_Dakota/New_Salem",
    label: "UTC -06:00 New Salem, United States",
    numericOffset: -21600000,
  },
  {
    timeZoneId: "America/Nassau",
    label: "UTC -05:00 Nassau, Bahamas",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Eirunepe",
    label: "UTC -05:00 Eirunepe, Brazil",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Rio_Branco",
    label: "UTC -05:00 Rio Branco, Brazil",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Atikokan",
    label: "UTC -05:00 Atikokan, Canada",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Iqaluit",
    label: "UTC -05:00 Iqaluit, Canada",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Toronto",
    label: "UTC -05:00 Toronto, Canada",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Cayman",
    label: "UTC -05:00 Cayman, Cayman Islands",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "Pacific/Easter",
    label: "UTC -05:00 Easter, Chile",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Bogota",
    label: "UTC -05:00 Bogota, Colombia",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Havana",
    label: "UTC -05:00 Havana, Cuba",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Guayaquil",
    label: "UTC -05:00 Guayaquil, Ecuador",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Port-au-Prince",
    label: "UTC -05:00 Port-au-Prince, Haiti",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Jamaica",
    label: "UTC -05:00 Jamaica, Jamaica",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Cancun",
    label: "UTC -05:00 Cancun, Mexico",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Panama",
    label: "UTC -05:00 Panama, Panama",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Lima",
    label: "UTC -05:00 Lima, Peru",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Grand_Turk",
    label: "UTC -05:00 Grand Turk, Turks and Caicos Islands",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Detroit",
    label: "UTC -05:00 Detroit, United States",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Indiana/Indianapolis",
    label: "UTC -05:00 Indianapolis, United States",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Indiana/Marengo",
    label: "UTC -05:00 Marengo, United States",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Indiana/Petersburg",
    label: "UTC -05:00 Petersburg, United States",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Indiana/Vevay",
    label: "UTC -05:00 Vevay, United States",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Indiana/Vincennes",
    label: "UTC -05:00 Vincennes, United States",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Indiana/Winamac",
    label: "UTC -05:00 Winamac, United States",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Kentucky/Louisville",
    label: "UTC -05:00 Louisville, United States",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Kentucky/Monticello",
    label: "UTC -05:00 Monticello, United States",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/New_York",
    label: "UTC -05:00 New York, United States",
    numericOffset: -18000000,
  },
  {
    timeZoneId: "America/Anguilla",
    label: "UTC -04:00 Anguilla, Anguilla",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Antigua",
    label: "UTC -04:00 Antigua, Antigua and Barbuda",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Aruba",
    label: "UTC -04:00 Aruba, Aruba",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Barbados",
    label: "UTC -04:00 Barbados, Barbados",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "Atlantic/Bermuda",
    label: "UTC -04:00 Bermuda, Bermuda",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/La_Paz",
    label: "UTC -04:00 La Paz, Bolivia, Plurinational State of",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Kralendijk",
    label: "UTC -04:00 Kralendijk, Bonaire, Sint Eustatius and Saba",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Boa_Vista",
    label: "UTC -04:00 Boa Vista, Brazil",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Campo_Grande",
    label: "UTC -04:00 Campo Grande, Brazil",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Cuiaba",
    label: "UTC -04:00 Cuiaba, Brazil",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Manaus",
    label: "UTC -04:00 Manaus, Brazil",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Porto_Velho",
    label: "UTC -04:00 Porto Velho, Brazil",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Blanc-Sablon",
    label: "UTC -04:00 Blanc-Sablon, Canada",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Glace_Bay",
    label: "UTC -04:00 Glace Bay, Canada",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Goose_Bay",
    label: "UTC -04:00 Goose Bay, Canada",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Halifax",
    label: "UTC -04:00 Halifax, Canada",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Moncton",
    label: "UTC -04:00 Moncton, Canada",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Curacao",
    label: "UTC -04:00 Curacao, Curaçao",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Dominica",
    label: "UTC -04:00 Dominica, Dominica",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Santo_Domingo",
    label: "UTC -04:00 Santo Domingo, Dominican Republic",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Thule",
    label: "UTC -04:00 Thule, Greenland",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Grenada",
    label: "UTC -04:00 Grenada, Grenada",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Guadeloupe",
    label: "UTC -04:00 Guadeloupe, Guadeloupe",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Guyana",
    label: "UTC -04:00 Guyana, Guyana",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Martinique",
    label: "UTC -04:00 Martinique, Martinique",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Montserrat",
    label: "UTC -04:00 Montserrat, Montserrat",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Puerto_Rico",
    label: "UTC -04:00 Puerto Rico, Puerto Rico",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/St_Barthelemy",
    label: "UTC -04:00 St Barthelemy, Saint Barthélemy",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/St_Kitts",
    label: "UTC -04:00 St Kitts, Saint Kitts and Nevis",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/St_Lucia",
    label: "UTC -04:00 St Lucia, Saint Lucia",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Marigot",
    label: "UTC -04:00 Marigot, Saint Martin (French part)",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/St_Vincent",
    label: "UTC -04:00 St Vincent, Saint Vincent and the Grenadines",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Lower_Princes",
    label: "UTC -04:00 Lower Princes, Sint Maarten (Dutch part)",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Port_of_Spain",
    label: "UTC -04:00 Port of_Spain, Trinidad and Tobago",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Caracas",
    label: "UTC -04:00 Caracas, Venezuela, Bolivarian Republic of",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/Tortola",
    label: "UTC -04:00 Tortola, Virgin Islands, British",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/St_Thomas",
    label: "UTC -04:00 St Thomas, Virgin Islands, U.S.",
    numericOffset: -14400000,
  },
  {
    timeZoneId: "America/St_Johns",
    label: "UTC -03:30 St Johns, Canada",
    numericOffset: -12600000,
  },
  {
    timeZoneId: "Antarctica/Palmer",
    label: "UTC -03:00 Palmer, Antarctica",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "Antarctica/Rothera",
    label: "UTC -03:00 Rothera, Antarctica",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Argentina/Buenos_Aires",
    label: "UTC -03:00 Buenos Aires, Argentina",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Argentina/Catamarca",
    label: "UTC -03:00 Catamarca, Argentina",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Argentina/Cordoba",
    label: "UTC -03:00 Cordoba, Argentina",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Argentina/Jujuy",
    label: "UTC -03:00 Jujuy, Argentina",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Argentina/La_Rioja",
    label: "UTC -03:00 La Rioja, Argentina",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Argentina/Mendoza",
    label: "UTC -03:00 Mendoza, Argentina",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Argentina/Rio_Gallegos",
    label: "UTC -03:00 Rio Gallegos, Argentina",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Argentina/Salta",
    label: "UTC -03:00 Salta, Argentina",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Argentina/San_Juan",
    label: "UTC -03:00 San Juan, Argentina",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Argentina/San_Luis",
    label: "UTC -03:00 San Luis, Argentina",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Argentina/Tucuman",
    label: "UTC -03:00 Tucuman, Argentina",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Argentina/Ushuaia",
    label: "UTC -03:00 Ushuaia, Argentina",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Araguaina",
    label: "UTC -03:00 Araguaina, Brazil",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Bahia",
    label: "UTC -03:00 Bahia, Brazil",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Belem",
    label: "UTC -03:00 Belem, Brazil",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Fortaleza",
    label: "UTC -03:00 Fortaleza, Brazil",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Maceio",
    label: "UTC -03:00 Maceio, Brazil",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Recife",
    label: "UTC -03:00 Recife, Brazil",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Santarem",
    label: "UTC -03:00 Santarem, Brazil",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Sao_Paulo",
    label: "UTC -03:00 Sao Paulo, Brazil",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Punta_Arenas",
    label: "UTC -03:00 Punta Arenas, Chile",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Santiago",
    label: "UTC -03:00 Santiago, Chile",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "Atlantic/Stanley",
    label: "UTC -03:00 Stanley, Falkland Islands (Malvinas)",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Cayenne",
    label: "UTC -03:00 Cayenne, French Guiana",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Asuncion",
    label: "UTC -03:00 Asuncion, Paraguay",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Miquelon",
    label: "UTC -03:00 Miquelon, Saint Pierre and Miquelon",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Paramaribo",
    label: "UTC -03:00 Paramaribo, Suriname",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Montevideo",
    label: "UTC -03:00 Montevideo, Uruguay",
    numericOffset: -10800000,
  },
  {
    timeZoneId: "America/Noronha",
    label: "UTC -02:00 Noronha, Brazil",
    numericOffset: -7200000,
  },
  {
    timeZoneId: "America/Nuuk",
    label: "UTC -02:00 Nuuk, Greenland",
    numericOffset: -7200000,
  },
  {
    timeZoneId: "Atlantic/South_Georgia",
    label: "UTC -02:00 South Georgia, South Georgia and the South Sandwich Islands",
    numericOffset: -7200000,
  },
  {
    timeZoneId: "Atlantic/Cape_Verde",
    label: "UTC -01:00 Cape Verde, Cape Verde",
    numericOffset: -3600000,
  },
  {
    timeZoneId: "America/Scoresbysund",
    label: "UTC -01:00 Scoresbysund, Greenland",
    numericOffset: -3600000,
  },
  {
    timeZoneId: "Atlantic/Azores",
    label: "UTC -01:00 Azores, Portugal",
    numericOffset: -3600000,
  },
  {
    timeZoneId: "Pacific/Kiritimati",
    label: "UTC +14:00 Kiritimati, Kiribati",
    numericOffset: 50400000,
  },
  {
    timeZoneId: "Pacific/Chatham",
    label: "UTC +13:45 Chatham, New Zealand",
    numericOffset: 49500000,
  },
  {
    timeZoneId: "Antarctica/McMurdo",
    label: "UTC +13:00 McMurdo, Antarctica",
    numericOffset: 46800000,
  },
  {
    timeZoneId: "Pacific/Kanton",
    label: "UTC +13:00 Kanton, Kiribati",
    numericOffset: 46800000,
  },
  {
    timeZoneId: "Pacific/Auckland",
    label: "UTC +13:00 Auckland, New Zealand",
    numericOffset: 46800000,
  },
  {
    timeZoneId: "Pacific/Apia",
    label: "UTC +13:00 Apia, Samoa",
    numericOffset: 46800000,
  },
  {
    timeZoneId: "Pacific/Fakaofo",
    label: "UTC +13:00 Fakaofo, Tokelau",
    numericOffset: 46800000,
  },
  {
    timeZoneId: "Pacific/Tongatapu",
    label: "UTC +13:00 Tongatapu, Tonga",
    numericOffset: 46800000,
  },
  {
    timeZoneId: "Pacific/Fiji",
    label: "UTC +12:00 Fiji, Fiji",
    numericOffset: 43200000,
  },
  {
    timeZoneId: "Pacific/Tarawa",
    label: "UTC +12:00 Tarawa, Kiribati",
    numericOffset: 43200000,
  },
  {
    timeZoneId: "Pacific/Kwajalein",
    label: "UTC +12:00 Kwajalein, Marshall Islands",
    numericOffset: 43200000,
  },
  {
    timeZoneId: "Pacific/Majuro",
    label: "UTC +12:00 Majuro, Marshall Islands",
    numericOffset: 43200000,
  },
  {
    timeZoneId: "Pacific/Nauru",
    label: "UTC +12:00 Nauru, Nauru",
    numericOffset: 43200000,
  },
  {
    timeZoneId: "Pacific/Norfolk",
    label: "UTC +12:00 Norfolk, Norfolk Island",
    numericOffset: 43200000,
  },
  {
    timeZoneId: "Asia/Anadyr",
    label: "UTC +12:00 Anadyr, Russian Federation",
    numericOffset: 43200000,
  },
  {
    timeZoneId: "Asia/Kamchatka",
    label: "UTC +12:00 Kamchatka, Russian Federation",
    numericOffset: 43200000,
  },
  {
    timeZoneId: "Pacific/Funafuti",
    label: "UTC +12:00 Funafuti, Tuvalu",
    numericOffset: 43200000,
  },
  {
    timeZoneId: "Pacific/Wake",
    label: "UTC +12:00 Wake, United States Minor Outlying Islands",
    numericOffset: 43200000,
  },
  {
    timeZoneId: "Pacific/Wallis",
    label: "UTC +12:00 Wallis, Wallis and Futuna",
    numericOffset: 43200000,
  },
  {
    timeZoneId: "Antarctica/Macquarie",
    label: "UTC +11:00 Macquarie, Australia",
    numericOffset: 39600000,
  },
  {
    timeZoneId: "Australia/Hobart",
    label: "UTC +11:00 Hobart, Australia",
    numericOffset: 39600000,
  },
  {
    timeZoneId: "Australia/Lord_Howe",
    label: "UTC +11:00 Lord Howe, Australia",
    numericOffset: 39600000,
  },
  {
    timeZoneId: "Australia/Melbourne",
    label: "UTC +11:00 Melbourne, Australia",
    numericOffset: 39600000,
  },
  {
    timeZoneId: "Australia/Sydney",
    label: "UTC +11:00 Sydney, Australia",
    numericOffset: 39600000,
  },
  {
    timeZoneId: "Pacific/Kosrae",
    label: "UTC +11:00 Kosrae, Micronesia, Federated States of",
    numericOffset: 39600000,
  },
  {
    timeZoneId: "Pacific/Pohnpei",
    label: "UTC +11:00 Pohnpei, Micronesia, Federated States of",
    numericOffset: 39600000,
  },
  {
    timeZoneId: "Pacific/Noumea",
    label: "UTC +11:00 Noumea, New Caledonia",
    numericOffset: 39600000,
  },
  {
    timeZoneId: "Pacific/Bougainville",
    label: "UTC +11:00 Bougainville, Papua New Guinea",
    numericOffset: 39600000,
  },
  {
    timeZoneId: "Asia/Magadan",
    label: "UTC +11:00 Magadan, Russian Federation",
    numericOffset: 39600000,
  },
  {
    timeZoneId: "Asia/Sakhalin",
    label: "UTC +11:00 Sakhalin, Russian Federation",
    numericOffset: 39600000,
  },
  {
    timeZoneId: "Asia/Srednekolymsk",
    label: "UTC +11:00 Srednekolymsk, Russian Federation",
    numericOffset: 39600000,
  },
  {
    timeZoneId: "Pacific/Guadalcanal",
    label: "UTC +11:00 Guadalcanal, Solomon Islands",
    numericOffset: 39600000,
  },
  {
    timeZoneId: "Pacific/Efate",
    label: "UTC +11:00 Efate, Vanuatu",
    numericOffset: 39600000,
  },
  {
    timeZoneId: "Australia/Adelaide",
    label: "UTC +10:30 Adelaide, Australia",
    numericOffset: 37800000,
  },
  {
    timeZoneId: "Australia/Broken_Hill",
    label: "UTC +10:30 Broken Hill, Australia",
    numericOffset: 37800000,
  },
  {
    timeZoneId: "Antarctica/DumontDUrville",
    label: "UTC +10:00 DumontDUrville, Antarctica",
    numericOffset: 36000000,
  },
  {
    timeZoneId: "Australia/Brisbane",
    label: "UTC +10:00 Brisbane, Australia",
    numericOffset: 36000000,
  },
  {
    timeZoneId: "Australia/Lindeman",
    label: "UTC +10:00 Lindeman, Australia",
    numericOffset: 36000000,
  },
  {
    timeZoneId: "Pacific/Guam",
    label: "UTC +10:00 Guam, Guam",
    numericOffset: 36000000,
  },
  {
    timeZoneId: "Pacific/Chuuk",
    label: "UTC +10:00 Chuuk, Micronesia, Federated States of",
    numericOffset: 36000000,
  },
  {
    timeZoneId: "Pacific/Saipan",
    label: "UTC +10:00 Saipan, Northern Mariana Islands",
    numericOffset: 36000000,
  },
  {
    timeZoneId: "Pacific/Port_Moresby",
    label: "UTC +10:00 Port Moresby, Papua New Guinea",
    numericOffset: 36000000,
  },
  {
    timeZoneId: "Asia/Ust-Nera",
    label: "UTC +10:00 Ust-Nera, Russian Federation",
    numericOffset: 36000000,
  },
  {
    timeZoneId: "Asia/Vladivostok",
    label: "UTC +10:00 Vladivostok, Russian Federation",
    numericOffset: 36000000,
  },
  {
    timeZoneId: "Australia/Darwin",
    label: "UTC +09:30 Darwin, Australia",
    numericOffset: 34200000,
  },
  {
    timeZoneId: "Asia/Jayapura",
    label: "UTC +09:00 Jayapura, Indonesia",
    numericOffset: 32400000,
  },
  {
    timeZoneId: "Asia/Tokyo",
    label: "UTC +09:00 Tokyo, Japan",
    numericOffset: 32400000,
  },
  {
    timeZoneId: "Asia/Pyongyang",
    label: "UTC +09:00 Pyongyang, Korea, Democratic People's Republic of",
    numericOffset: 32400000,
  },
  {
    timeZoneId: "Asia/Seoul",
    label: "UTC +09:00 Seoul, Korea, Republic of",
    numericOffset: 32400000,
  },
  {
    timeZoneId: "Pacific/Palau",
    label: "UTC +09:00 Palau, Palau",
    numericOffset: 32400000,
  },
  {
    timeZoneId: "Asia/Chita",
    label: "UTC +09:00 Chita, Russian Federation",
    numericOffset: 32400000,
  },
  {
    timeZoneId: "Asia/Khandyga",
    label: "UTC +09:00 Khandyga, Russian Federation",
    numericOffset: 32400000,
  },
  {
    timeZoneId: "Asia/Yakutsk",
    label: "UTC +09:00 Yakutsk, Russian Federation",
    numericOffset: 32400000,
  },
  {
    timeZoneId: "Asia/Dili",
    label: "UTC +09:00 Dili, Timor-Leste",
    numericOffset: 32400000,
  },
  {
    timeZoneId: "Australia/Eucla",
    label: "UTC +08:45 Eucla, Australia",
    numericOffset: 31500000,
  },
  {
    timeZoneId: "Antarctica/Casey",
    label: "UTC +08:00 Casey, Antarctica",
    numericOffset: 39600000,
  },
  {
    timeZoneId: "Australia/Perth",
    label: "UTC +08:00 Perth, Australia",
    numericOffset: 28800000,
  },
  {
    timeZoneId: "Asia/Brunei",
    label: "UTC +08:00 Brunei, Brunei Darussalam",
    numericOffset: 28800000,
  },
  {
    timeZoneId: "Asia/Shanghai",
    label: "UTC +08:00 Shanghai, China",
    numericOffset: 28800000,
  },
  {
    timeZoneId: "Asia/Hong_Kong",
    label: "UTC +08:00 Hong Kong, Hong Kong",
    numericOffset: 28800000,
  },
  {
    timeZoneId: "Asia/Makassar",
    label: "UTC +08:00 Makassar, Indonesia",
    numericOffset: 28800000,
  },
  {
    timeZoneId: "Asia/Macau",
    label: "UTC +08:00 Macau, Macao",
    numericOffset: 28800000,
  },
  {
    timeZoneId: "Asia/Kuala_Lumpur",
    label: "UTC +08:00 Kuala Lumpur, Malaysia",
    numericOffset: 28800000,
  },
  {
    timeZoneId: "Asia/Kuching",
    label: "UTC +08:00 Kuching, Malaysia",
    numericOffset: 28800000,
  },
  {
    timeZoneId: "Asia/Choibalsan",
    label: "UTC +08:00 Choibalsan, Mongolia",
    numericOffset: 28800000,
  },
  {
    timeZoneId: "Asia/Ulaanbaatar",
    label: "UTC +08:00 Ulaanbaatar, Mongolia",
    numericOffset: 28800000,
  },
  {
    timeZoneId: "Asia/Manila",
    label: "UTC +08:00 Manila, Philippines",
    numericOffset: 28800000,
  },
  {
    timeZoneId: "Asia/Irkutsk",
    label: "UTC +08:00 Irkutsk, Russian Federation",
    numericOffset: 28800000,
  },
  {
    timeZoneId: "Asia/Singapore",
    label: "UTC +08:00 Singapore, Singapore",
    numericOffset: 28800000,
  },
  {
    timeZoneId: "Asia/Taipei",
    label: "UTC +08:00 Taipei, Taiwan, Province of China",
    numericOffset: 28800000,
  },
  {
    timeZoneId: "Antarctica/Davis",
    label: "UTC +07:00 Davis, Antarctica",
    numericOffset: 25200000,
  },
  {
    timeZoneId: "Asia/Phnom_Penh",
    label: "UTC +07:00 Phnom Penh, Cambodia",
    numericOffset: 25200000,
  },
  {
    timeZoneId: "Indian/Christmas",
    label: "UTC +07:00 Christmas, Christmas Island",
    numericOffset: 25200000,
  },
  {
    timeZoneId: "Asia/Jakarta",
    label: "UTC +07:00 Jakarta, Indonesia",
    numericOffset: 25200000,
  },
  {
    timeZoneId: "Asia/Pontianak",
    label: "UTC +07:00 Pontianak, Indonesia",
    numericOffset: 25200000,
  },
  {
    timeZoneId: "Asia/Vientiane",
    label: "UTC +07:00 Vientiane, Lao People's Democratic Republic",
    numericOffset: 25200000,
  },
  {
    timeZoneId: "Asia/Hovd",
    label: "UTC +07:00 Hovd, Mongolia",
    numericOffset: 25200000,
  },
  {
    timeZoneId: "Asia/Barnaul",
    label: "UTC +07:00 Barnaul, Russian Federation",
    numericOffset: 25200000,
  },
  {
    timeZoneId: "Asia/Krasnoyarsk",
    label: "UTC +07:00 Krasnoyarsk, Russian Federation",
    numericOffset: 25200000,
  },
  {
    timeZoneId: "Asia/Novokuznetsk",
    label: "UTC +07:00 Novokuznetsk, Russian Federation",
    numericOffset: 25200000,
  },
  {
    timeZoneId: "Asia/Novosibirsk",
    label: "UTC +07:00 Novosibirsk, Russian Federation",
    numericOffset: 25200000,
  },
  {
    timeZoneId: "Asia/Tomsk",
    label: "UTC +07:00 Tomsk, Russian Federation",
    numericOffset: 25200000,
  },
  {
    timeZoneId: "Asia/Bangkok",
    label: "UTC +07:00 Bangkok, Thailand",
    numericOffset: 25200000,
  },
  {
    timeZoneId: "Asia/Ho_Chi_Minh",
    label: "UTC +07:00 Ho Chi_Minh, Viet Nam",
    numericOffset: 25200000,
  },
  {
    timeZoneId: "Indian/Cocos",
    label: "UTC +06:30 Cocos, Cocos (Keeling) Islands",
    numericOffset: 23400000,
  },
  {
    timeZoneId: "Asia/Yangon",
    label: "UTC +06:30 Yangon, Myanmar",
    numericOffset: 23400000,
  },
  {
    timeZoneId: "Asia/Dhaka",
    label: "UTC +06:00 Dhaka, Bangladesh",
    numericOffset: 21600000,
  },
  {
    timeZoneId: "Asia/Thimphu",
    label: "UTC +06:00 Thimphu, Bhutan",
    numericOffset: 21600000,
  },
  {
    timeZoneId: "Indian/Chagos",
    label: "UTC +06:00 Chagos, British Indian Ocean Territory",
    numericOffset: 21600000,
  },
  {
    timeZoneId: "Asia/Urumqi",
    label: "UTC +06:00 Urumqi, China",
    numericOffset: 21600000,
  },
  {
    timeZoneId: "Asia/Almaty",
    label: "UTC +06:00 Almaty, Kazakhstan",
    numericOffset: 21600000,
  },
  {
    timeZoneId: "Asia/Qostanay",
    label: "UTC +06:00 Qostanay, Kazakhstan",
    numericOffset: 21600000,
  },
  {
    timeZoneId: "Asia/Bishkek",
    label: "UTC +06:00 Bishkek, Kyrgyzstan",
    numericOffset: 21600000,
  },
  {
    timeZoneId: "Asia/Omsk",
    label: "UTC +06:00 Omsk, Russian Federation",
    numericOffset: 21600000,
  },
  {
    timeZoneId: "Asia/Kathmandu",
    label: "UTC +05:45 Kathmandu, Nepal",
    numericOffset: 20700000,
  },
  {
    timeZoneId: "Asia/Kolkata",
    label: "UTC +05:30 Kolkata, India",
    numericOffset: 19800000,
  },
  {
    timeZoneId: "Asia/Colombo",
    label: "UTC +05:30 Colombo, Sri Lanka",
    numericOffset: 19800000,
  },
  {
    timeZoneId: "Antarctica/Mawson",
    label: "UTC +05:00 Mawson, Antarctica",
    numericOffset: 18000000,
  },
  {
    timeZoneId: "Antarctica/Vostok",
    label: "UTC +05:00 Vostok, Antarctica",
    numericOffset: 21600000,
  },
  {
    timeZoneId: "Indian/Kerguelen",
    label: "UTC +05:00 Kerguelen, French Southern Territories",
    numericOffset: 18000000,
  },
  {
    timeZoneId: "Asia/Aqtau",
    label: "UTC +05:00 Aqtau, Kazakhstan",
    numericOffset: 18000000,
  },
  {
    timeZoneId: "Asia/Aqtobe",
    label: "UTC +05:00 Aqtobe, Kazakhstan",
    numericOffset: 18000000,
  },
  {
    timeZoneId: "Asia/Atyrau",
    label: "UTC +05:00 Atyrau, Kazakhstan",
    numericOffset: 18000000,
  },
  {
    timeZoneId: "Asia/Oral",
    label: "UTC +05:00 Oral, Kazakhstan",
    numericOffset: 18000000,
  },
  {
    timeZoneId: "Asia/Qyzylorda",
    label: "UTC +05:00 Qyzylorda, Kazakhstan",
    numericOffset: 18000000,
  },
  {
    timeZoneId: "Indian/Maldives",
    label: "UTC +05:00 Maldives, Maldives",
    numericOffset: 18000000,
  },
  {
    timeZoneId: "Asia/Karachi",
    label: "UTC +05:00 Karachi, Pakistan",
    numericOffset: 18000000,
  },
  {
    timeZoneId: "Asia/Yekaterinburg",
    label: "UTC +05:00 Yekaterinburg, Russian Federation",
    numericOffset: 18000000,
  },
  {
    timeZoneId: "Asia/Dushanbe",
    label: "UTC +05:00 Dushanbe, Tajikistan",
    numericOffset: 18000000,
  },
  {
    timeZoneId: "Asia/Ashgabat",
    label: "UTC +05:00 Ashgabat, Turkmenistan",
    numericOffset: 18000000,
  },
  {
    timeZoneId: "Asia/Samarkand",
    label: "UTC +05:00 Samarkand, Uzbekistan",
    numericOffset: 18000000,
  },
  {
    timeZoneId: "Asia/Tashkent",
    label: "UTC +05:00 Tashkent, Uzbekistan",
    numericOffset: 18000000,
  },
  {
    timeZoneId: "Asia/Kabul",
    label: "UTC +04:30 Kabul, Afghanistan",
    numericOffset: 16200000,
  },
  {
    timeZoneId: "Asia/Yerevan",
    label: "UTC +04:00 Yerevan, Armenia",
    numericOffset: 14400000,
  },
  {
    timeZoneId: "Asia/Baku",
    label: "UTC +04:00 Baku, Azerbaijan",
    numericOffset: 14400000,
  },
  {
    timeZoneId: "Asia/Tbilisi",
    label: "UTC +04:00 Tbilisi, Georgia",
    numericOffset: 14400000,
  },
  {
    timeZoneId: "Indian/Mauritius",
    label: "UTC +04:00 Mauritius, Mauritius",
    numericOffset: 14400000,
  },
  {
    timeZoneId: "Asia/Muscat",
    label: "UTC +04:00 Muscat, Oman",
    numericOffset: 14400000,
  },
  {
    timeZoneId: "Europe/Astrakhan",
    label: "UTC +04:00 Astrakhan, Russian Federation",
    numericOffset: 14400000,
  },
  {
    timeZoneId: "Europe/Samara",
    label: "UTC +04:00 Samara, Russian Federation",
    numericOffset: 14400000,
  },
  {
    timeZoneId: "Europe/Saratov",
    label: "UTC +04:00 Saratov, Russian Federation",
    numericOffset: 14400000,
  },
  {
    timeZoneId: "Europe/Ulyanovsk",
    label: "UTC +04:00 Ulyanovsk, Russian Federation",
    numericOffset: 14400000,
  },
  {
    timeZoneId: "Indian/Reunion",
    label: "UTC +04:00 Reunion, Réunion",
    numericOffset: 14400000,
  },
  {
    timeZoneId: "Indian/Mahe",
    label: "UTC +04:00 Mahe, Seychelles",
    numericOffset: 14400000,
  },
  {
    timeZoneId: "Asia/Dubai",
    label: "UTC +04:00 Dubai, United Arab Emirates",
    numericOffset: 14400000,
  },
  {
    timeZoneId: "Asia/Tehran",
    label: "UTC +03:30 Tehran, Iran, Islamic Republic of",
    numericOffset: 12600000,
  },
  {
    timeZoneId: "Antarctica/Syowa",
    label: "UTC +03:00 Syowa, Antarctica",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Asia/Bahrain",
    label: "UTC +03:00 Bahrain, Bahrain",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Europe/Minsk",
    label: "UTC +03:00 Minsk, Belarus",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Indian/Comoro",
    label: "UTC +03:00 Comoro, Comoros",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Africa/Djibouti",
    label: "UTC +03:00 Djibouti, Djibouti",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Africa/Asmara",
    label: "UTC +03:00 Asmara, Eritrea",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Africa/Addis_Ababa",
    label: "UTC +03:00 Addis Ababa, Ethiopia",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Asia/Baghdad",
    label: "UTC +03:00 Baghdad, Iraq",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Asia/Amman",
    label: "UTC +03:00 Amman, Jordan",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Africa/Nairobi",
    label: "UTC +03:00 Nairobi, Kenya",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Asia/Kuwait",
    label: "UTC +03:00 Kuwait, Kuwait",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Indian/Antananarivo",
    label: "UTC +03:00 Antananarivo, Madagascar",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Indian/Mayotte",
    label: "UTC +03:00 Mayotte, Mayotte",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Asia/Qatar",
    label: "UTC +03:00 Qatar, Qatar",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Europe/Kirov",
    label: "UTC +03:00 Kirov, Russian Federation",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Europe/Moscow",
    label: "UTC +03:00 Moscow, Russian Federation",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Europe/Volgograd",
    label: "UTC +03:00 Volgograd, Russian Federation",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Asia/Riyadh",
    label: "UTC +03:00 Riyadh, Saudi Arabia",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Africa/Mogadishu",
    label: "UTC +03:00 Mogadishu, Somalia",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Asia/Damascus",
    label: "UTC +03:00 Damascus, Syrian Arab Republic",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Africa/Dar_es_Salaam",
    label: "UTC +03:00 Dar es_Salaam, Tanzania, United Republic of",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Europe/Istanbul",
    label: "UTC +03:00 Istanbul, Turkey",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Africa/Kampala",
    label: "UTC +03:00 Kampala, Uganda",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Europe/Simferopol",
    label: "UTC +03:00 Simferopol, Ukraine",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Asia/Aden",
    label: "UTC +03:00 Aden, Yemen",
    numericOffset: 10800000,
  },
  {
    timeZoneId: "Africa/Gaborone",
    label: "UTC +02:00 Gaborone, Botswana",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Europe/Sofia",
    label: "UTC +02:00 Sofia, Bulgaria",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Africa/Bujumbura",
    label: "UTC +02:00 Bujumbura, Burundi",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Africa/Lubumbashi",
    label: "UTC +02:00 Lubumbashi, Congo, the Democratic Republic of the",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Asia/Famagusta",
    label: "UTC +02:00 Famagusta, Cyprus",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Asia/Nicosia",
    label: "UTC +02:00 Nicosia, Cyprus",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Africa/Cairo",
    label: "UTC +02:00 Cairo, Egypt",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Europe/Tallinn",
    label: "UTC +02:00 Tallinn, Estonia",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Europe/Helsinki",
    label: "UTC +02:00 Helsinki, Finland",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Europe/Athens",
    label: "UTC +02:00 Athens, Greece",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Asia/Jerusalem",
    label: "UTC +02:00 Jerusalem, Israel",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Europe/Riga",
    label: "UTC +02:00 Riga, Latvia",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Asia/Beirut",
    label: "UTC +02:00 Beirut, Lebanon",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Africa/Maseru",
    label: "UTC +02:00 Maseru, Lesotho",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Africa/Tripoli",
    label: "UTC +02:00 Tripoli, Libya",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Europe/Vilnius",
    label: "UTC +02:00 Vilnius, Lithuania",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Africa/Blantyre",
    label: "UTC +02:00 Blantyre, Malawi",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Europe/Chisinau",
    label: "UTC +02:00 Chisinau, Moldova, Republic of",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Africa/Maputo",
    label: "UTC +02:00 Maputo, Mozambique",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Africa/Windhoek",
    label: "UTC +02:00 Windhoek, Namibia",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Asia/Gaza",
    label: "UTC +02:00 Gaza, Palestine, State of",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Asia/Hebron",
    label: "UTC +02:00 Hebron, Palestine, State of",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Europe/Bucharest",
    label: "UTC +02:00 Bucharest, Romania",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Europe/Kaliningrad",
    label: "UTC +02:00 Kaliningrad, Russian Federation",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Africa/Kigali",
    label: "UTC +02:00 Kigali, Rwanda",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Africa/Johannesburg",
    label: "UTC +02:00 Johannesburg, South Africa",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Africa/Juba",
    label: "UTC +02:00 Juba, South Sudan",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Africa/Khartoum",
    label: "UTC +02:00 Khartoum, Sudan",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Africa/Mbabane",
    label: "UTC +02:00 Mbabane, Swaziland",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Europe/Kyiv",
    label: "UTC +02:00 Kyiv, Ukraine",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Africa/Lusaka",
    label: "UTC +02:00 Lusaka, Zambia",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Africa/Harare",
    label: "UTC +02:00 Harare, Zimbabwe",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Europe/Mariehamn",
    label: "UTC +02:00 Mariehamn, Åland Islands",
    numericOffset: 7200000,
  },
  {
    timeZoneId: "Europe/Tirane",
    label: "UTC +01:00 Tirane, Albania",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/Algiers",
    label: "UTC +01:00 Algiers, Algeria",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Andorra",
    label: "UTC +01:00 Andorra, Andorra",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/Luanda",
    label: "UTC +01:00 Luanda, Angola",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Vienna",
    label: "UTC +01:00 Vienna, Austria",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Brussels",
    label: "UTC +01:00 Brussels, Belgium",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/Porto-Novo",
    label: "UTC +01:00 Porto-Novo, Benin",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Sarajevo",
    label: "UTC +01:00 Sarajevo, Bosnia and Herzegovina",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/Douala",
    label: "UTC +01:00 Douala, Cameroon",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/Bangui",
    label: "UTC +01:00 Bangui, Central African Republic",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/Ndjamena",
    label: "UTC +01:00 Ndjamena, Chad",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/Brazzaville",
    label: "UTC +01:00 Brazzaville, Congo",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/Kinshasa",
    label: "UTC +01:00 Kinshasa, Congo, the Democratic Republic of the",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Zagreb",
    label: "UTC +01:00 Zagreb, Croatia",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Prague",
    label: "UTC +01:00 Prague, Czech Republic",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Copenhagen",
    label: "UTC +01:00 Copenhagen, Denmark",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/Malabo",
    label: "UTC +01:00 Malabo, Equatorial Guinea",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Paris",
    label: "UTC +01:00 Paris, France",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/Libreville",
    label: "UTC +01:00 Libreville, Gabon",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Berlin",
    label: "UTC +01:00 Berlin, Germany",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Busingen",
    label: "UTC +01:00 Busingen, Germany",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Gibraltar",
    label: "UTC +01:00 Gibraltar, Gibraltar",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Vatican",
    label: "UTC +01:00 Vatican, Holy See (Vatican City State)",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Budapest",
    label: "UTC +01:00 Budapest, Hungary",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Rome",
    label: "UTC +01:00 Rome, Italy",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Vaduz",
    label: "UTC +01:00 Vaduz, Liechtenstein",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Luxembourg",
    label: "UTC +01:00 Luxembourg, Luxembourg",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Skopje",
    label: "UTC +01:00 Skopje, Macedonia, the Former Yugoslav Republic of",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Malta",
    label: "UTC +01:00 Malta, Malta",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Monaco",
    label: "UTC +01:00 Monaco, Monaco",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Podgorica",
    label: "UTC +01:00 Podgorica, Montenegro",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/Casablanca",
    label: "UTC +01:00 Casablanca, Morocco",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/El_Aaiun",
    label: "UTC +01:00 El Aaiun, Morocco",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Amsterdam",
    label: "UTC +01:00 Amsterdam, Netherlands",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/Niamey",
    label: "UTC +01:00 Niamey, Niger",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/Lagos",
    label: "UTC +01:00 Lagos, Nigeria",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Oslo",
    label: "UTC +01:00 Oslo, Norway",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Warsaw",
    label: "UTC +01:00 Warsaw, Poland",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/San_Marino",
    label: "UTC +01:00 San Marino, San Marino",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Belgrade",
    label: "UTC +01:00 Belgrade, Serbia",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Bratislava",
    label: "UTC +01:00 Bratislava, Slovakia",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Ljubljana",
    label: "UTC +01:00 Ljubljana, Slovenia",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/Ceuta",
    label: "UTC +01:00 Ceuta, Spain",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Madrid",
    label: "UTC +01:00 Madrid, Spain",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Arctic/Longyearbyen",
    label: "UTC +01:00 Longyearbyen, Svalbard and Jan Mayen",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Stockholm",
    label: "UTC +01:00 Stockholm, Sweden",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Europe/Zurich",
    label: "UTC +01:00 Zurich, Switzerland",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Africa/Tunis",
    label: "UTC +01:00 Tunis, Tunisia",
    numericOffset: 3600000,
  },
  {
    timeZoneId: "Antarctica/Troll",
    label: "UTC Troll, Antarctica",
    numericOffset: 0,
  },
  {
    timeZoneId: "Africa/Ouagadougou",
    label: "UTC Ouagadougou, Burkina Faso",
    numericOffset: 0,
  },
  {
    timeZoneId: "Africa/Abidjan",
    label: "UTC Abidjan, Côte d'Ivoire",
    numericOffset: 0,
  },
  {
    timeZoneId: "Atlantic/Faroe",
    label: "UTC Faroe, Faroe Islands",
    numericOffset: 0,
  },
  {
    timeZoneId: "Africa/Banjul",
    label: "UTC Banjul, Gambia",
    numericOffset: 0,
  },
  {
    timeZoneId: "Africa/Accra",
    label: "UTC Accra, Ghana",
    numericOffset: 0,
  },
  {
    timeZoneId: "America/Danmarkshavn",
    label: "UTC Danmarkshavn, Greenland",
    numericOffset: 0,
  },
  {
    timeZoneId: "Europe/Guernsey",
    label: "UTC Guernsey, Guernsey",
    numericOffset: 0,
  },
  {
    timeZoneId: "Africa/Conakry",
    label: "UTC Conakry, Guinea",
    numericOffset: 0,
  },
  {
    timeZoneId: "Africa/Bissau",
    label: "UTC Bissau, Guinea-Bissau",
    numericOffset: 0,
  },
  {
    timeZoneId: "Atlantic/Reykjavik",
    label: "UTC Reykjavik, Iceland",
    numericOffset: 0,
  },
  {
    timeZoneId: "Europe/Dublin",
    label: "UTC Dublin, Ireland",
    numericOffset: 0,
  },
  {
    timeZoneId: "Europe/Isle_of_Man",
    label: "UTC Isle of_Man, Isle of Man",
    numericOffset: 0,
  },
  {
    timeZoneId: "Europe/Jersey",
    label: "UTC Jersey, Jersey",
    numericOffset: 0,
  },
  {
    timeZoneId: "Africa/Monrovia",
    label: "UTC Monrovia, Liberia",
    numericOffset: 0,
  },
  {
    timeZoneId: "Africa/Bamako",
    label: "UTC Bamako, Mali",
    numericOffset: 0,
  },
  {
    timeZoneId: "Africa/Nouakchott",
    label: "UTC Nouakchott, Mauritania",
    numericOffset: 0,
  },
  {
    timeZoneId: "Atlantic/Madeira",
    label: "UTC Madeira, Portugal",
    numericOffset: 0,
  },
  {
    timeZoneId: "Europe/Lisbon",
    label: "UTC Lisbon, Portugal",
    numericOffset: 0,
  },
  {
    timeZoneId: "Atlantic/St_Helena",
    label: "UTC St Helena, Saint Helena, Ascension and Tristan da Cunha",
    numericOffset: 0,
  },
  {
    timeZoneId: "Africa/Sao_Tome",
    label: "UTC Sao Tome, Sao Tome and Principe",
    numericOffset: 0,
  },
  {
    timeZoneId: "Africa/Dakar",
    label: "UTC Dakar, Senegal",
    numericOffset: 0,
  },
  {
    timeZoneId: "Africa/Freetown",
    label: "UTC Freetown, Sierra Leone",
    numericOffset: 0,
  },
  {
    timeZoneId: "Atlantic/Canary",
    label: "UTC Canary, Spain",
    numericOffset: 0,
  },
  {
    timeZoneId: "Africa/Lome",
    label: "UTC Lome, Togo",
    numericOffset: 0,
  },
  {
    timeZoneId: "Europe/London",
    label: "UTC London, United Kingdom",
    numericOffset: 0,
  },
]
