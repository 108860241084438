import { Heading } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import Card from "components/layout/Card"
import React, { ReactNode } from "react"

type Props = {
  title: string
  children: ReactNode
  icon?: string
}

const Section = ({
  title, children, icon,
}: Props) => (
  <Card>
    <Heading display="flex" alignItems="center" fontSize="2xl" fontWeight="semibold">
      {icon && <Icon icon={icon} color="blue.500" size={5} mr={3} />}
      {title}
    </Heading>
    {children}
  </Card>
)

export default Section
