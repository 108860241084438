import React from "react"
import Pill from "components/elements/Pill"
import { Flex } from "@chakra-ui/react"

type Props = {
  value: string;
  primary: boolean;
  hardBounced: boolean;
  softBounced: boolean;
}

const EmailField = ({
  value, primary, hardBounced, softBounced,
}: Props) => (
  <Flex>
    {value}
    {primary && <PrimaryPill />}
    {hardBounced && <BouncedPill type="hard" />}
    {softBounced && <BouncedPill type="soft" />}
  </Flex>
)

type BouncedPillProps = {
  type: "hard" | "soft";
}

export const BouncedPill = ({ type }: BouncedPillProps) => {
  const color = type === "hard" ? "emailStatus.hardBounced" : "emailStatus.softBounced"
  const label = type === "hard" ? "Hard Bounced" : "Soft Bounced"

  return (
    <Pill
      ml={2}
      icon="dot"
      label={label}
      borderWidth={1}
      borderColor={color}
      bgColor="white"
      textColor={color}
      width="auto"
      px={2}
    />
  )
}

export const PrimaryPill = () => (
  <Pill
    ml={2}
    label="Primary"
    borderWidth={1}
    borderColor="green.500"
    bgColor="white"
    textColor="green.500"
  />
)

export default EmailField
