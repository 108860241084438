import { Box, Flex, Text } from "@chakra-ui/react"
import React from "react"

type Props = {
  title: string
  required: boolean
}

const FormattedQuestionTitleWithRequiredMark = ({ title, required }: Props) => (
  <Flex>
    <Box
      sx={{
        "& a": {
          color: "blue.500",
        },
      }}
      dangerouslySetInnerHTML={{ __html: title }}
    />
    {required && <Text ml={1} color="red">*</Text>}
  </Flex>
)

export default FormattedQuestionTitleWithRequiredMark
