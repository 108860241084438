import React from "react"
import { Flex, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { useDrag } from "react-dnd"
import { BuilderBlockType, DraggableItemTypes } from "sharedTypes/builder"

type Props = {
  text: string
  icon: string
  type: BuilderBlockType
}

const AddContentButton = ({
  text, icon, type,
}: Props) => {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: DraggableItemTypes.ADD,
      item: { blockTypeToAdd: type },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
  )

  return (
    <Flex
      ref={dragRef}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      borderWidth={1}
      w={32}
      h={20}
      borderRadius="lg"
      backgroundColor="white"
      color="blue.400"
      cursor="move"
      boxShadow="md"
      style={{ opacity }}
    >
      <Icon icon={icon} size={6} mb={1.5} />
      <Text fontSize="smaller" fontWeight="semibold" lineHeight="normal">{text}</Text>
      <Text color="gray.500" fontWeight="semibold" lineHeight="normal">:::</Text>
    </Flex>
  )
}

export default AddContentButton
