import React, { ReactNode } from "react"
import {
  Box, Flex, Heading, HStack, Image, Spacer, Text,
} from "@chakra-ui/react"
import backgroundImage from "assets/images/login-bg.jpg"
import logo from "assets/images/diobox-logo.svg"
import { loginPath } from "utilities/routes"
import Icon from "components/elements/Icon"
import { Link } from "react-router-dom"
import Button from "components/Buttons/Button"
import SimpleBar from "simplebar-react"

interface Props {
  pageTitle: ReactNode
  body: ReactNode
  header?: ReactNode
  description?: ReactNode
}

const AuthenticationWrapper = ({
  pageTitle,
  body,
  header = (
    <Flex
      h={8}
      color="gray.600"
      fontSize="sm"
      align="center"
    >
      <Text mr={6} display={["none", "none", "none", "block"]}>Already have an account?</Text>
      <Button
        as={Link}
        size="sm"
        variant="outline"
        colorScheme="button.light"
        to={loginPath()}
      >
        Sign In
      </Button>
    </Flex>
  ),
  description = (
    <>
      <Text fontSize="2xl" align="center">The most advanced platform for managing events and guests.</Text>
      <HStack spacing={7} justify="center" mt={6}>
        <Icon icon="guests-stroke" size={8} />
        <Icon icon="payment-lists" size={7} />
        <Icon icon="globe" size={7} />
        <Icon icon="ticketing-stroke" size={8} />
        <Icon icon="newdash-invites" size={8} />
        <Icon icon="table-square" size={8} />
      </HStack>
    </>
  ),
}: Props) => (
  <Flex
    h="100vh"
    overflowY="auto"
    direction={["column", "column", "column", "row"]}
  >
    <Flex
      bgImage={`url(${backgroundImage})`}
      bgRepeat="no-repeat"
      bgSize="cover"
      bgPosition="right"
      direction={["row", "row", "row", "column"]}
      justify="center"
      minW={[null, null, null, "440"]}
      w={["full", "full", "full", "25%"]}
      px={12}
      py={10}
      fontSize="2xl"
      color="white"
    >
      <Box flex={1}>
        <Image w={36} src={logo} />
      </Box>
      <Box display={["none", "none", "none", "block"]}>{description}</Box>
      <Spacer flex={1} />
      <Box display={["block", "block", "block", "none"]}>{header}</Box>
    </Flex>
    <Box
      as={SimpleBar}
      flex={1}
      h={["auto", "auto", "auto", "100vh"]}
    >
      <Flex
        direction="column"
        align="center"
        justify="start"
        bg="white"
        p={10}
        minH="100vh"
      >
        <Flex display={["none", "none", "none", "flex"]} justify="flex-end" w="full" flex={1}>{header}</Flex>
        <Box w={["full", 400, 400]}>
          <Heading
            fontSize="3xl"
            color="gray.900"
            fontWeight="normal"
            mb={6}
          >{pageTitle}
          </Heading>
          {body}
        </Box>
        <Spacer flex={1} />

      </Flex>
    </Box>
  </Flex>
)

export default AuthenticationWrapper
