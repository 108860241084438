import NumberField from "components/ReactHookForm/NumberField"
import React from "react"

const PaddingInputs = ({ control }) => (
  <>
    <NumberField label="Top Padding" name="paddingTop" control={control} min={0} />
    <NumberField label="Bottom Padding" name="paddingBottom" control={control} min={0} />
  </>
)

export default PaddingInputs
