import React from "react"
import { Box, Stack, Text } from "@chakra-ui/react"
import SectionHeader from "pages/EventShow/Settings/SectionHeader"
import { DioboxEvent } from "sharedTypes"
import EmbedInstructions, { EmbedInstructionsType } from "components/EmbedInstructions"

type Props = {
  event: DioboxEvent;
}

const EmbedCode = ({ event: { externalId } }: Props) => (
  <Stack direction="column" spacing={3}>
    <SectionHeader>Embed Code</SectionHeader>
    <Text>
      You can integrate the Diobox Forms process into your own website. This means your
      guests will have a streamlined experience of registering for your event without leaving
      your site, which is mobile-friendly and is processed on Diobox’s secure backend
      platform.
    </Text>
    <Text>
      Adding the two code snippets below will display a registration button on your external
      website. When clicked, this button will open a modal showing all your public forms,
      allowing your guests to register or purchase tickets seamlessly.
    </Text>
    <Box borderRadius="xl" bgColor="white" borderWidth={1} px={6} py={4}>
      <EmbedInstructions type={EmbedInstructionsType.Event} externalId={externalId} />
    </Box>
    <Text>
      To embed a single form on your website, select the desired form and find its specific
      embed code.
    </Text>
  </Stack>
)

export default EmbedCode
