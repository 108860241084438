import React from "react"
import { Image as ChakraImage, Box } from "@chakra-ui/react"
import { QRCodeBlock } from "sharedTypes/builder"

const QRCode = ({ block: { data } }: { block: QRCodeBlock }) => (
  <Box
    bgColor={data.blockColor}
    pt={`${data.paddingTop * 4}px`}
    pb={`${data.paddingBottom * 4}px`}
  >
    <ChakraImage
      src="https://data.diobox.com/app/web/assets/email/qr-code-sample.png"
      w={100}
      h={100}
      mx="auto"
    />
  </Box>
)

export default QRCode
