import timeZones from "utilities/timeZones"
import React from "react"
import { Control, useController } from "react-hook-form"
import Select, { SingleValue } from "react-select"
import _ from "lodash"
import { Box, Flex, FormLabel } from "@chakra-ui/react"

type Props = {
  control: Control<any>
  label?: string
}

const TimeZoneSelect = ({ control, label }: Props) => {
  const timeZoneOptions = _.sortBy(timeZones, "numericOffset")
    .map(({ label: timeZoneLabel, timeZoneId }) => ({
      label: timeZoneLabel,
      value: timeZoneId,
    }))

  const { field } = useController({ name: "timezone", control })
  const selectedItem = timeZoneOptions.find((option) => option.value === field.value)

  return (
    <Flex mb={3} w="full" align="flex-end">
      {label && <FormLabel fontWeight="semibold" w="25%" htmlFor="timezone">{label}</FormLabel>}
      <Box w={label ? "100%" : "100%"}>
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={selectedItem}
          name="timezone"
          options={timeZoneOptions}
          onChange={({ value }: SingleValue<any>) => field.onChange(value)}
        />
      </Box>
    </Flex>
  )
}

export default TimeZoneSelect
