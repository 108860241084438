import React from "react"
import { Box, Text } from "@chakra-ui/react"
import LabelRedefinition from "modals/FormForm/LabelRedefinition"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import { DioboxEvent, Translations } from "sharedTypes"
import TitleWithSubtitle from "../TitleWithSubtitle"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
}

const RegistrationFieldsSection = ({ event, updateEvent, defaultTranslations }: Props) => {
  const onSubmit = (values) => updateEvent(values)

  return (
    <Box>
      <TitleWithSubtitle
        title="Registration Fields"
        subtitle="Customize the field names that guests see when they register for an event or edit their existing records."
        mb={5}
      />
      <ContainedForm values={event} onSubmit={onSubmit} name="Registration Fields">
        {({ control }) => (
          <Box maxWidth={500}>
            <Box mb={7}>
              <Box mb={8}>
                <Text fontSize="2xl">Basic Fields</Text>
              </Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.firstName}
                name="translations.firstName"
                control={control}
              />
              <LabelRedefinition
                defaultTranslation={defaultTranslations.lastName}
                name="translations.lastName"
                control={control}
              />
              <LabelRedefinition
                defaultTranslation={defaultTranslations.fullName}
                name="translations.fullName"
                control={control}
              />
              <LabelRedefinition
                defaultTranslation={defaultTranslations.emailAddress}
                name="translations.emailAddress"
                control={control}
              />
              <LabelRedefinition
                defaultTranslation={defaultTranslations.company}
                name="translations.company"
                control={control}
              />
              <LabelRedefinition
                defaultTranslation={defaultTranslations.title}
                name="translations.title"
                control={control}
              />
              <LabelRedefinition
                defaultTranslation={defaultTranslations.phone}
                name="translations.phone"
                control={control}
              />
            </Box>
            <Box mb={7}>
              <Box mb={8}>
                <Text fontSize="2xl">Address Fields</Text>
              </Box>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.addressLine1}
                name="translations.addressLine1"
                control={control}
              />
              <LabelRedefinition
                defaultTranslation={defaultTranslations.addressLine2}
                name="translations.addressLine2"
                control={control}
              />
              <LabelRedefinition
                defaultTranslation={defaultTranslations.city}
                name="translations.city"
                control={control}
              />
              <LabelRedefinition
                defaultTranslation={defaultTranslations.state}
                name="translations.state"
                control={control}
              />
              <LabelRedefinition
                defaultTranslation={defaultTranslations.postalCode}
                name="translations.postalCode"
                control={control}
              />
              <LabelRedefinition
                defaultTranslation={defaultTranslations.country}
                name="translations.country"
                control={control}
              />
            </Box>
          </Box>
        )}
      </ContainedForm>
    </Box>
  )
}

export default RegistrationFieldsSection
