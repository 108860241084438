import { Seat } from "sharedTypes"
import { MenuItem } from "components/dialogs/Menu"
import React, { ReactNode } from "react"
import SeatItem from "./SeatItem"

type VacantSeatItemProps = {
  seat: Seat;
  children: ReactNode;
  onAssignGuest: (number: number) => void;
  onAddPlaceholder: (number: number) => void;
  canEditSeating: boolean;
}

const VacantSeatItem = (
  {
    seat: { number },
    children,
    onAssignGuest,
    onAddPlaceholder,
    canEditSeating,
  }: VacantSeatItemProps,
) => (
  <SeatItem
    seatNumber={number}
    label={children}
    title="Vacant"
    description="Vacant"
    labelColor="gray.300"
    canEditSeating={canEditSeating}
    menuItems={[
      <MenuItem
        onClick={() => { onAssignGuest(number) }}
        isCentered
      >Assign Guest
      </MenuItem>,
      <MenuItem
        onClick={() => { onAddPlaceholder(number) }}
        isCentered
      >
        Add Placeholder
      </MenuItem>,
    ]}
  />
)

export default VacantSeatItem
