import React from "react"
import { Box } from "@chakra-ui/react"
import { HTMLBlock } from "sharedTypes/builder"
import ReactShadowRoot from "react-shadow-root"

const HTML = ({ block: { data } }: { block: HTMLBlock }) => {
  const style = "h1, h2, h3, h4, h5, h6, p { font-size: unset; font-weight: unset; margin: unset; padding: unset; }"

  return (
    <Box
      bgColor="#FFFFFF"
    >
      <ReactShadowRoot>
        <style>{style}</style>
        <Box dangerouslySetInnerHTML={{ __html: data.html }} />
      </ReactShadowRoot>
    </Box>
  )
}

export default HTML
