import React from "react"
import { SimpleGrid } from "@chakra-ui/react"
import AddContentButton from "components/Builder/AddContentButton"
import EditorSection from "components/Builder/components/EditorSection"
import { BuilderBlockType, BuilderType } from "sharedTypes/builder"

type Props = {
  builderType: BuilderType
}

const AddContentSection = ({ builderType }: Props) => {
  const configuration = {
    [BuilderBlockType.HEADING]: {
      text: "Heading", icon: "email-block-headline", type: BuilderBlockType.HEADING,
    },
    [BuilderBlockType.TEXT]: {
      text: "Paragraph", icon: "email-block-text", type: BuilderBlockType.TEXT,
    },
    [BuilderBlockType.IMAGE]: {
      text: "Image", icon: "email-block-image", type: BuilderBlockType.IMAGE,
    },
    [BuilderBlockType.DIVIDER]: {
      text: "Divider", icon: "email-block-divider", type: BuilderBlockType.DIVIDER,
    },
    [BuilderBlockType.SPACER]: {
      text: "Spacer", icon: "email-block-spacer", type: BuilderBlockType.SPACER,
    },
    [BuilderBlockType.BUTTON]: {
      text: "Button", icon: "email-block-button", type: BuilderBlockType.BUTTON,
    },
    [BuilderBlockType.QR_CODE]: {
      text: "QR Code", icon: "email-block-qr-code", type: BuilderBlockType.QR_CODE,
    },
    [BuilderBlockType.HTML]: {
      text: "HTML", icon: "email-block-html", type: BuilderBlockType.HTML,
    },
    [BuilderBlockType.QUESTION]: {
      text: "Question", icon: "custom-question", type: BuilderBlockType.QUESTION,
    },
  }

  const optionsToRender = builderType === BuilderType.Email
    ? [
      configuration[BuilderBlockType.HEADING],
      configuration[BuilderBlockType.TEXT],
      configuration[BuilderBlockType.IMAGE],
      configuration[BuilderBlockType.DIVIDER],
      configuration[BuilderBlockType.SPACER],
      configuration[BuilderBlockType.BUTTON],
      configuration[BuilderBlockType.QR_CODE],
      configuration[BuilderBlockType.HTML],
    ]
    : [
      configuration[BuilderBlockType.TEXT],
      configuration[BuilderBlockType.QUESTION],
      configuration[BuilderBlockType.IMAGE],
      configuration[BuilderBlockType.DIVIDER],
      configuration[BuilderBlockType.SPACER],
    ]

  return (
    <EditorSection>
      <SimpleGrid spacing={3.5} minChildWidth={32} mt={7} mb={7} justifyItems="center">
        {optionsToRender.map(({ text, icon, type }) => (
          <AddContentButton
            key={type}
            text={text}
            icon={icon}
            type={type}
          />
        ))}
      </SimpleGrid>
    </EditorSection>
  )
}

export default AddContentSection
