import React from "react"
import { Control, FieldValues } from "react-hook-form"
import TextField from "./TextField"

type Props<T extends FieldValues> = {
  name: string;
  control: Control<T, object>;
  label?: string;
  placeholder?: string;
  onChange?: (event) => void;
  vertical?: boolean;
  readonly?: boolean;
}

const EmailField = <T extends FieldValues, >({
  name, control, label, placeholder, onChange, vertical, readonly,
}: Props<T>) => (
  <TextField
    name={name}
    control={control}
    label={label}
    placeholder={placeholder}
    onChange={onChange}
    type="email"
    autoComplete="email"
    vertical={vertical}
    readonly={readonly}
  />
  )

export default EmailField
