import React, { useRef } from "react"
import pluralize from "pluralize"
import { Table, Seat as DioboxSeat } from "sharedTypes"
import {
  Box, Flex, Heading, HStack,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { useInViewport } from "ahooks"
import Seat from "./Seat"
import TableMenu from "../TableMenu"
import withTableState from "../withTableState"

type Props = {
  table: Table;
  seats: DioboxSeat[];
  resetSeat: (number) => void;
  assignGuest: (number) => void;
  moveToWaitlist: (number) => void;
  addPlaceholder: (number) => void;
  editPlaceholder: (number) => void;
  canEditSeating: boolean;
}

const TableListItem = ({
  table,
  seats,
  resetSeat,
  assignGuest,
  moveToWaitlist,
  addPlaceholder,
  editPlaceholder,
  canEditSeating,
}: Props) => {
  const seatCount = table.seats.length
  const waitlistedGuestCount = table.waitlistedGuests.length
  const vacantSeatCount = table.seats.filter(({ guest, reserved }) => !guest && !reserved).length
  const containerRef = useRef(null)
  const inViewport = useInViewport(containerRef)

  return (
    <Box ref={containerRef} borderWidth={1} borderRadius="md" p={5} w="100%" bg="white">
      <Flex
        borderBottomWidth={1}
        justify="space-between"
        alignItems="center"
        pb={2}
        mb={5}
      >
        <Heading size="md" fontWeight="normal">
          <TableMenu
            table={table}
            button={(
              <Flex align="center" textAlign="left">
                <Icon icon={`table-${table.shape}`} size={8} mr={2} />
                {table.name}
                <Icon icon="down-arrow" ml={2} />
              </Flex>
            )}
            showTableLinks
            isVisible={!!inViewport}
          />
        </Heading>
        <Box
          color={!vacantSeatCount ? "red.500" : ""}
        >{seatCount} {pluralize("Seat", seatCount)}
        </Box>
      </Flex>
      <HStack wrap="wrap" mb={-2} spacing={0} shouldWrapChildren>
        {seats.map((seat) => {
          const { number } = seat

          return (
            <Box key={number} h={10} mr={2} mb={2}>
              <Seat
                seat={seat}
                table={table}
                onResetSeat={() => resetSeat(number)}
                onAssignGuest={() => assignGuest(number)}
                onMoveToWaitlist={() => moveToWaitlist(number)}
                onAddPlaceholder={() => addPlaceholder(number)}
                onEditPlaceholder={() => editPlaceholder(number)}
                canEditSeating={canEditSeating}
                isVisible={!!inViewport}
              />
            </Box>
          )
        })}
        {waitlistedGuestCount && <Box color="orange.400">+ {waitlistedGuestCount} Waitlisted</Box>}
      </HStack>
    </Box>
  )
}

export default withTableState(TableListItem)
