import React from "react"
import {
  Box, Flex, Text,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { UseFormReset } from "react-hook-form"
import { FormFormType, FormFormValues } from "sharedTypes"
import FormTypeSelectListItem from "components/layout/FormTypeSelectListItem"
import useGenerateName from "services/useGenerateName"

type Props = {
    form: FormFormType;
    initialValues: FormFormValues
    values: FormFormValues;
    reset: UseFormReset<object>
    disabled?: boolean
    eventId: string
}

const ListItem = ({
  form, values, initialValues, reset, disabled, eventId,
}: Props) => {
  const { generateFormName } = useGenerateName(eventId)
  const formName = generateFormName(form.type)

  return (
    <FormTypeSelectListItem
      selected={form.type === values.type}
      onClick={() => { reset({ ...initialValues, type: form.type, name: formName }) }}
      disabled={disabled}
    >
      <Flex align="center">
        <Icon icon={form.icon} size={7} mr={3} />
        <Box>
          <Text fontSize="sm" fontWeight="semibold" noOfLines={1}>{form.name}</Text>
        </Box>
      </Flex>
    </FormTypeSelectListItem>
  )
}

export default ListItem
