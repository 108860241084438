import React, { ReactNode } from "react"
import { Flex, SpaceProps, Text } from "@chakra-ui/react"
import Button from "components/Buttons/Button"

export enum AlertBoxType {
  Upgrade = "upgrade",
  Verification = "verification",
}

type Props = {
  type: AlertBoxType
  text: string|ReactNode
  mb?: SpaceProps["marginBottom"]
  header?: string
  buttonText?: string|ReactNode
  onClick?: () => void
}

const backgroundColors = {
  [AlertBoxType.Upgrade]: "blue.50",
  [AlertBoxType.Verification]: "yellow.100",
}

const AlertBox = ({
  text, mb, header, type, buttonText, onClick,
}: Props) => (
  <Flex
    flexDirection="column"
    borderRadius="md"
    borderWidth={1}
    p={4}
    bgColor={backgroundColors[type]}
    mb={mb}
    w="full"
    maxW="750px"
  >
    {header && <Text fontSize="xl" mb={2}>{header}</Text>}
    <Flex flexWrap="wrap" alignItems="center" justifyContent="space-between" w="full" gap={4}>
      <Text>{text}</Text>
      {(buttonText && onClick) && (
        <Button size="sm" onClick={onClick}>{buttonText}</Button>
      )}
    </Flex>
  </Flex>
)

export default AlertBox
