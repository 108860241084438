import React from "react"
import * as Yup from "yup"
import {
  Box, FormLabel, HStack, Flex, Text,
} from "@chakra-ui/react"
import { FormQuestion, QuestionTypes } from "sharedTypes"
import Icon from "components/elements/Icon"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextField from "components/ReactHookForm/TextField"
import Checkbox from "components/ReactHookForm/Checkbox"
import ToggleButtons from "components/ReactHookForm/ToggleButtons"
import ToggleButton from "components/ReactHookForm/ToggleButton"
import { Control, useFieldArray } from "react-hook-form"
import _ from "lodash"
import RichTextField, { RichTextFieldType } from "components/ReactHookForm/RichTextField"
import { setValueIfNotEqual } from "utilities/forms"
import { stripHtmlTags } from "utilities/strings"
import { questionTypeIcons } from "utilities/enums"

const DEFAULT_TERMS = "By registering for this event, you agree to our Terms of Use and Privacy Policy."
const DEFAULT_USER_CONSENT = "I agree to these terms and conditions."

type Props = {
  title: string
  initialValues: {type: QuestionTypes; title: string; choices: string[]; required: boolean;}
  onSave: (question: FormQuestion) => void
  onCancel: () => void
  onDelete?: () => void
  uploadEnabled?: boolean
  termsEnabled?: boolean
  richTextEditor?: boolean
  textBlockEnabled?: boolean
}

const QuestionSchema = Yup.object().shape({
  title: Yup.string().test("empty-html-tags", (s) => (
    !s
      ? false
      : s.replace(/(<([^>]+)>)/gi, "").length > 0)).required(),
  type: Yup.string(),
  choices: Yup.mixed()
    .when("type", {
      is: (val) => questionTypesWithChoices.includes(val) || QuestionTypes.Terms === val,
      then: () => Yup.array().of(Yup.object().shape({
        text: Yup.string().required(),
      })).min(1),
      otherwise: () => Yup.array().of(Yup.mixed()).min(0),
    }),
})

const QuestionModalForm = ({
  title,
  initialValues,
  onSave,
  onCancel,
  onDelete,
  uploadEnabled = false,
  termsEnabled = false,
  richTextEditor = false,
  textBlockEnabled = true,
}: Props) => {
  const handleSubmit = ({ choices, ...rest }: FormQuestion) => {
    let choicesToSave: string[] = []

    /* We should collect the choices when the question type requires them, and also when the
       type is terms since we use the 1st element of the choices array to store the consent */
    if (questionTypesWithChoices.includes(rest.type) || QuestionTypes.Terms === rest.type) {
      choicesToSave = _.map(choices, "text")
    }

    return onSave({ choices: choicesToSave, ...rest })
  }

  return (
    <ModalForm
      title={title}
      initialValues={{ ...initialValues, choices: initialValues.choices.map((text) => ({ text })) }}
      validationSchema={QuestionSchema}
      onSubmit={handleSubmit}
      onDanger={onDelete}
      dangerProps={{ type: "Question", name: richTextEditor ? undefined : initialValues.title }}
      onClose={onCancel}
    >
      {({ control, watch, setValue }) => {
        const values = watch()

        let label
        switch (values.type) {
          case QuestionTypes.TextBlock:
            label = "Text Block (Without Answer)"
            break
          case QuestionTypes.Terms:
            label = "Terms & Conditions Statement"
            break
          default:
            label = "Question"
            break
        }

        watch((data, { name, type }) => {
          if (name === "type" && type === "change") {
            const stripedTitle = stripHtmlTags(data.title)
            const titleIsDefault = stripedTitle === "" || stripedTitle === DEFAULT_TERMS
            if (data.type === QuestionTypes.Terms && titleIsDefault) {
              setValueIfNotEqual(setValue, "title", data.title, DEFAULT_TERMS)
              setValueIfNotEqual(setValue, "choices[0].text", data.title, DEFAULT_USER_CONSENT)
            } else if (titleIsDefault) {
              setValueIfNotEqual(setValue, "title", data.title, "")
              setValueIfNotEqual(setValue, "choices[0].text", data.title, "")
            }

            if (data.type === QuestionTypes.Terms) {
              setValueIfNotEqual(setValue, "required", data.required, true)
            } else {
              setValueIfNotEqual(setValue, "required", data.required, false)
            }
          }
        })

        return (
          <>
            <TypeOptions
              uploadEnabled={uploadEnabled}
              control={control}
              termsEnabled={termsEnabled}
              textBlockEnabled={textBlockEnabled}
            />
            <Box mb={8}>
              {
              richTextEditor
                ? (
                  <>
                    <Flex justifyContent="space-between">
                      <Text mb={2} fontSize="lg">{label}</Text>
                      {values.type !== QuestionTypes.TextBlock && <Checkbox disabled={values.type === QuestionTypes.Terms} name="required" control={control} label="Required" w="auto" mb={0} />}
                    </Flex>
                    <RichTextField
                      name="title"
                      control={control}
                      type={RichTextFieldType.QUESTION}
                      placeholder="Enter Question"
                      className="question-editor"
                    />
                  </>
                )
                : (
                  <Flex direction="column" alignItems="flex-end">
                    <TextField label={label} name="title" control={control} />
                    {values.type !== QuestionTypes.TextBlock && <Checkbox name="required" control={control} label="Required" w="auto" />}
                  </Flex>
                )
            }
            </Box>
            {questionTypesWithChoices.includes(values.type) && <ChoiceFields name="choices" control={control} />}
            {values.type === QuestionTypes.Terms && (
            <Box>
              <Text fontSize="lg" mb={2}>User Consent</Text>
              <TextField name="choices[0].text" control={control} variant="outline" />
            </Box>
            )}
          </>
        )
      }}
    </ModalForm>
  )
}

const questionTypesWithChoices = [
  QuestionTypes.MultipleAnswer,
  QuestionTypes.DropdownMenu,
  QuestionTypes.SingleAnswer,
]

const ChoiceFields = ({ name, control }) => {
  const {
    fields, append, remove, update, insert,
  } = useFieldArray<any, any, any>({ name, control })

  const handlePaste = (event: React.ClipboardEvent<Element>, index: number) => {
    if (!event || !event.clipboardData || !event.target) {
      return
    }

    // If the input we paste into has got already a value, we dont't do our multiline-paste trick
    const target = event.target as HTMLInputElement
    if (target.value) {
      return
    }

    event.preventDefault()

    const pastedText = event.clipboardData.getData("Text")
    const lines = pastedText.split("\n")

    lines.forEach((line, lineIndex) => {
      if (lineIndex === 0) {
        update(index, { text: line })
      } else {
        insert(index + lineIndex, { text: line })
      }
    })
  }

  const handleKeyDown = (event: React.KeyboardEvent, index: number) => {
    if (event.key === "Enter") {
      event.preventDefault()
      insert(index + 1, { text: "" })
    }
  }

  return (
    <fieldset>
      <FormLabel as="legend" fontSize="lg" color="gray.800">Choices</FormLabel>
      {fields.map((choice, index) => (
        <HStack key={choice.id} align="center" spacing={4} mb={4}>
          <button type="button" onClick={() => remove(index)}>
            <Icon icon="delete-solid" color="red.600" />
          </button>
          <Box flexGrow={1} h={10}>
            <TextField
              name={`choices.${index}.text`}
              control={control}
              onPaste={(event) => handlePaste(event, index)}
              placeholder="Enter choice or paste multiple lines"
              onKeyDown={(event) => handleKeyDown(event, index)}
            />
          </Box>
        </HStack>
      ))}
      <button type="button" onClick={() => append("")}>
        <Flex align="center" color="blue.500">
          <Icon icon="add-solid" mr={4} /> <span>Add Choice</span>
        </Flex>
      </button>
    </fieldset>
  )
}

const TypeOptions = (
  {
    uploadEnabled, termsEnabled, textBlockEnabled, control,
  }:
  {uploadEnabled: boolean, termsEnabled: boolean, textBlockEnabled: boolean, control: Control<any>},
) => (
  <ToggleButtons name="type" control={control} columns={4}>
    {Object.values(QuestionTypes)
      .filter((questionType) => uploadEnabled || questionType !== QuestionTypes.FileUpload)
      .filter((questionType) => termsEnabled || questionType !== QuestionTypes.Terms)
      .filter((questionType) => textBlockEnabled || questionType !== QuestionTypes.TextBlock)
      .map((questionType) => ({
        button: (selected) => (
          <ToggleButton
            title={questionType}
            icon={questionTypeIcons[questionType]}
            selected={selected}
          />
        ),
        value: questionType,
      }))}
  </ToggleButtons>
)

export default QuestionModalForm
