import React, { Dispatch, useState } from "react"
import {
  BuilderBlockWithBlockId, DioboxEvent, FixedBlockType, Form, Translations,
} from "sharedTypes"
import { Box } from "@chakra-ui/react"
import EditorPanel from "components/Builder/EditorPanel/EditorPanel"
import BuilderLayout from "components/Builder/BuilderLayout"
import { useParams } from "react-router"
import { RichTextFieldType } from "components/ReactHookForm/RichTextField"
import { eventFormUploadsUrl } from "api/forms"
import { DraggableItem } from "sharedTypes/builder"
import { BlockInsertPosition } from "components/Builder/BuildingBlock"
import EditFormModalSettingsPanel from "./EditFormModalSettings/EditFormModalSettingsPanel"
import FormContainer from "../../FormContainer/FormContainer"
import FixedBlockEditorPanel from "../FixedBlockEditorPanel/FixedBlockEditorPanel"

type Props = {
  form: Form
  setForm: Dispatch<React.SetStateAction<Form | undefined>>
  blocks: BuilderBlockWithBlockId[]
  setSelectedBlockId: React.Dispatch<React.SetStateAction<number | undefined>>
  onBlockContentChange: (data: {}, selectedBlockId?: number) => void
  setBlocks: Dispatch<React.SetStateAction<BuilderBlockWithBlockId[]>>
  onDeleteBlock: () => void
  onDuplicateBlock: (blockId: number) => void
  selectedBlockId?: number,
  onAttributeChange: (formData: Form) => void
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
  onDropBlock: (item: DraggableItem, blockId: number, position: BlockInsertPosition) => void
}

const EditFormModalContent = ({
  form,
  setForm,
  blocks,
  onBlockContentChange,
  setSelectedBlockId,
  selectedBlockId,
  setBlocks,
  onDeleteBlock,
  onDuplicateBlock,
  onAttributeChange,
  event,
  updateEvent,
  defaultTranslations,
  onDropBlock,
}: Props) => {
  const selectedBlock = blocks.find(({ blockId }) => blockId === selectedBlockId)
  const [selectedFixedBlockType, setSelectedFixedBlockType] = useState<FixedBlockType>()

  const { currency } = event
  const onSelectFixedBlockType = (blockType: FixedBlockType|undefined) => {
    setSelectedFixedBlockType(blockType)
    if (blockType !== undefined) {
      setSelectedBlockId(undefined)
    }
  }

  const deselectBlocks = () => {
    setSelectedBlockId(undefined)
    setSelectedFixedBlockType(undefined)
  }

  const handleDropIntoDropHereBox = (item: DraggableItem) => {
    if (item.blockTypeToAdd) {
      onDropBlock(item, 0, BlockInsertPosition.Bottom)
    }
  }

  return (
    <BuilderLayout
      sidePanel={(
        <SidePanel
          form={form}
          setForm={setForm}
          selectedBlock={selectedBlock}
          selectedBlockId={selectedBlockId}
          setSelectedBlockId={setSelectedBlockId}
          onBlockContentChange={(data) => onBlockContentChange(data, selectedBlockId)}
          selectedFixedBlokType={selectedFixedBlockType}
          onSelectFixedBlockType={onSelectFixedBlockType}
          onDeleteBlock={onDeleteBlock}
          onAttributeChange={onAttributeChange}
          event={event}
          updateEvent={updateEvent}
          defaultTranslations={defaultTranslations}
        />
    )}
      handleDropIntoDropHereBox={handleDropIntoDropHereBox}
    >
      <Box position="relative" pb={40} overflow="hidden" minHeight="30rem">
        <Box
          position="absolute"
          height="full"
          top={0}
          right={0}
          bottom={0}
          left={0}
          onClick={deselectBlocks}
        />
        <Box w={700} margin="auto">
          <FormContainer
            form={form}
            blocks={blocks}
            editable
            setSelectedBlockId={setSelectedBlockId}
            selectedBlock={selectedBlock}
            setBlocks={setBlocks}
            onDeleteBlock={onDeleteBlock}
            onSelectFixedBlockType={onSelectFixedBlockType}
            selectedFixedBlockType={selectedFixedBlockType}
            onDuplicateBlock={onDuplicateBlock}
            currency={currency}
            onDropBlock={onDropBlock}
          />
        </Box>
      </Box>
    </BuilderLayout>
  )
}

const SidePanel = ({
  form,
  setForm,
  selectedBlock,
  selectedBlockId,
  setSelectedBlockId,
  onBlockContentChange,
  selectedFixedBlokType,
  onSelectFixedBlockType,
  onDeleteBlock,
  onAttributeChange,
  event,
  updateEvent,
  defaultTranslations,
}) => {
  const { eventId } = useParams<{ eventId: string }>()

  if (selectedBlock) {
    const uploadUrl = eventFormUploadsUrl(eventId, form.id)

    return (
      <EditorPanel
        selectedBlock={selectedBlock.block}
        onChange={onBlockContentChange}
        onCancel={() => setSelectedBlockId(undefined)}
        selectedBlockId={selectedBlockId}
        imageUploadUrl={uploadUrl}
        onDeleteBlock={onDeleteBlock}
        richTextFieldType={RichTextFieldType.FORM_BLOCK_TEXT}
      />
    )
  }

  if (selectedFixedBlokType !== undefined) {
    return (
      <FixedBlockEditorPanel
        form={form}
        setForm={setForm}
        selectedFixedBlockType={selectedFixedBlokType}
        onCancel={() => onSelectFixedBlockType(undefined)}
        updateEvent={updateEvent}
        defaultTranslations={defaultTranslations}
      />
    )
  }

  return (
    <Box bgColor="gray.100">
      <EditFormModalSettingsPanel
        form={form}
        onAttributeChange={onAttributeChange}
        event={event}
      />
    </Box>
  )
}

export default EditFormModalContent
