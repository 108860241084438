import React, { ReactNode } from "react"
import { DEFAULT_SENDER_EMAIL } from "utilities/enums"
import { customDomainSender } from "utilities/strings"
import { DioboxEvent } from "sharedTypes"
import EmailProperty from "./EmailProperty"

type Props = {
  sendTo: ReactNode
  event: DioboxEvent
  from: string
  replyTo: string
  fromMailbox?: string | null
}

const EmailHeader = ({
  sendTo, event, from, replyTo, fromMailbox,
}: Props) => {
  const emailSender = customDomainSender(event, fromMailbox)

  return (
    <>
      {sendTo}
      <EmailProperty name="From">{from}<br /> ({emailSender || DEFAULT_SENDER_EMAIL})</EmailProperty>
      {!emailSender && (
      <EmailProperty name="Reply-To">
        {replyTo}
      </EmailProperty>
      )}
    </>
  )
}

export default EmailHeader
