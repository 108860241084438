import React, { useState } from "react"
import Icon from "components/elements/Icon"
import { Table, Guest } from "sharedTypes"
import * as guestsApi from "api/guests"
import { updateGuest, updateTables } from "context/actions"
import { guestSummary } from "utilities/objects"
import Popover from "components/dialogs/Popover"
import { Box, useDisclosure } from "@chakra-ui/react"
import { FocusLock } from "@chakra-ui/focus-lock"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import SimpleBar from "simplebar-react"
import DropdownToggle from "../DropdownToggle"
import TableDropdownHeader from "./TableDropdownHeader"
import SeatSelect from "./SeatSelect"
import TableSelect from "./TableSelect"

export const updateTablesFromGuest = (guest: Guest, tables: Table[]) => {
  const {
    seatingAssignment: nextSeatingAssignment,
    waitlistTable: nextWaitlistTable,
  } = guest
  const updatedTables = tables.map(
    (table) => {
      const waitlistedGuests = table.waitlistedGuests.filter(({ id }) => id !== guest.id)
      const waitlistedGuestIds = table.waitlistedGuestIds.filter((id) => id !== guest.id)

      if (nextWaitlistTable?.id === table.id) {
        waitlistedGuests.push(guestSummary(guest))
        waitlistedGuestIds.push(guest.id)
      }

      const seats = table.seats.map(
        (seat) => {
          if (seat.guest?.id && seat.guest?.id !== guest.id) {
            return seat
          }

          if (
            table.id === nextSeatingAssignment?.seatingTableId
            && seat.number === nextSeatingAssignment.seat
          ) {
            return {
              ...seat,
              guestId: guest.id,
              guest: guestSummary(guest),
            }
          }

          return { ...seat, guestId: null, guest: null }
        },
      )

      const updatedTable = {
        ...table,
        seats,
        waitlistedGuests,
        waitlistedGuestIds,
      }

      return updatedTable
    },
  )

  return updatedTables
}

const Seating = (
  { guest, tables, disabled }: { guest: Guest, tables: Table[], disabled: boolean },
) => {
  const disclosure = useDisclosure()
  const queryClient = useQueryClient()
  const { seatingAssignment: assignment, waitlistTable } = guest
  const [selectedTable, setSelectedTable] = useState<Table|null>(null)
  const { eventId } = useParams<{ eventId: string }>()

  const updateStateAndReset = (data) => {
    updateGuest(queryClient, data)
    const updatedTables = updateTablesFromGuest(data, tables)
    updateTables(queryClient, eventId, updatedTables)
    setSelectedTable(null)
    disclosure.onClose()
  }

  const removeSeat = () => {
    guestsApi.deleteGuestSeat(eventId, guest.id)
      .then(({ data }) => {
        updateStateAndReset(data)
      })
  }

  const setStanding = () => {
    guestsApi.putGuest(eventId, guest.id, { standing: true })
      .then(({ data }) => {
        updateStateAndReset(data)
      })
  }

  const setWaitlist = (): void => {
    if (!selectedTable) {
      return
    }

    guestsApi.postGuestTableWaitlist(eventId, guest.id, selectedTable)
      .then(({ data }) => {
        updateStateAndReset(data)
      })
  }

  const setSeat = (number) => {
    if (!selectedTable) {
      return
    }

    guestsApi.postGuestSeat(eventId, guestSummary(guest), selectedTable, number)
      .then(({ data }) => {
        updateStateAndReset(data)
      })
  }

  let title = "No Seat"
  if (assignment) {
    const { seatingTableTitle, seat } = assignment
    title = `${seatingTableTitle}: Seat ${seat}`
  } else if (waitlistTable) {
    title = `${waitlistTable.name}: Waitlisted`
  } else if (guest.standing) {
    title = "Standing"
  }

  let dropdownHeader

  if (selectedTable) {
    dropdownHeader = (
      <TableDropdownHeader
        tableName={selectedTable.name}
        onClickBack={() => setSelectedTable(null)}
      />
    )
  } else {
    dropdownHeader = "Seating"
  }

  return (
    <Popover
      isDisabled={disabled}
      placement="auto"
      arrow
      header={dropdownHeader}
      trigger={(
        <DropdownToggle
          icon={<Icon icon="invite-seat" size={6} mr={2} />}
          title={title}
          disabled={disabled}
        />
      )}
      contentHeight={400}
      body={(
        <FocusLock persistentFocus>
          <Box tabIndex={0} maxHeight={400} as={SimpleBar}>
            <DropdownContent {...{
              selectedTable,
              guest,
              setSeat,
              setWaitlist,
              tables,
              removeSeat,
              setStanding,
              setSelectedTable,
            }}
            />
          </Box>
        </FocusLock>

      )}
      {...disclosure}
    />
  )
}

const DropdownContent = ({
  selectedTable,
  guest,
  setSeat,
  setWaitlist,
  tables,
  removeSeat,
  setStanding,
  setSelectedTable,
}) => {
  if (selectedTable) {
    return (
      <SeatSelect
        table={selectedTable}
        guest={guest}
        onSelectSeat={(number) => setSeat(number)}
        onSelectWaitlist={setWaitlist}
      />
    )
  }

  return (
    <TableSelect
      tables={tables}
      guest={guest}
      onRemoveSeat={removeSeat}
      onSetStanding={setStanding}
      onSelectTable={(table) => setSelectedTable(table as Table)}
    />
  )
}

export default Seating
