import React from "react"
import { ButtonProps } from "@chakra-ui/react"
import Button from "./Button"

type Props = {
  disabled?: boolean;
  submitting?: boolean;
  colorScheme?: ButtonProps["colorScheme"]
  size?: ButtonProps["size"]
  text?: string;
}

const SubmitButton = ({
  disabled, submitting, colorScheme = "button.primary", size = "md", text = "Submit",
}: Props) => (
  <Button
    type="submit"
    isLoading={submitting}
    isDisabled={disabled}
    colorScheme={colorScheme}
    size={size}
  >
    { text }
  </Button>
)

export default SubmitButton
