import {
  Text, Link as ChakraLink,
} from "@chakra-ui/react"
import React from "react"
import Button from "components/Buttons/Button"
import { useIntercom } from "react-use-intercom"
import { Account, ModalName } from "sharedTypes"
import Link from "components/elements/Link"
import AccountError from "components/layout/AccountError"
import useModal from "services/useModal"
import { createBillingPortalSession } from "api/accounts"

type Props = {
  currentAccount: Account
  onClose?: () => void
}

const PastDueSubscription = ({ currentAccount, onClose }: Props) => {
  const { show: showIntercom } = useIntercom()
  const showModal = useModal()
  const { name, subscription } = currentAccount
  const hostedInvoiceUrl = subscription?.hostedInvoiceUrl
  const openBillingPortal = () => {
    createBillingPortalSession()
      .then((response) => {
        window.open(response.data.url, "_blank")
      })
  }

  const openHostedInvoiceUrl = () => {
    if (hostedInvoiceUrl) {
      window.open(hostedInvoiceUrl, "_blank")
    } else {
      openBillingPortal()
    }
  }

  return (
    <AccountError
      title="Your account is past due"
      footer={(
        <ChakraLink onClick={() => showModal(ModalName.SwitchAccount)}>
          Switch Account
        </ChakraLink>
      )}
      onClose={onClose}
    >
      <Text fontSize="lg" fontWeight="semibold" mb={6}>{name}</Text>
      <Text mb={6} fontSize="lg">
        Our system attempted to bill your account, but the pavment failed.
        Please update vour credit card information.
      </Text>
      <Button onClick={openHostedInvoiceUrl} size="md">
        View Outstanding Invoice
      </Button>
      <Text fontSize="lg" fontWeight="bold" my={8} color="red.500">
        Your account will be suspended and marked for deletion if payment is not processed promptly.
      </Text>
      <Text fontSize="sm" mt={6}>
        Access your <Link onClick={openBillingPortal}>Billing Portal</Link>, a Stripe dashboard,
        which manages Diobox subscriptions, providing you access to your invoices and credit cards.
        <br />
        If you have any questions about your subscription,
        {" "}<Link onClick={showIntercom}>message us</Link>.
      </Text>
    </AccountError>
  )
}

export default PastDueSubscription
