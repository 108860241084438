import React, { Dispatch } from "react"
import _ from "lodash"
import update from "immutability-helper"
import { BuilderBlockWithBlockId, Form } from "sharedTypes"
import { BuilderBlock, BuilderBlockType } from "sharedTypes/builder"
import {
  ButtonBlockFactory,
  DividerBlockFactory,
  HeadingBlockFactory,
  HtmlBlockFactory,
  ImageBlockFactory,
  QRCodeBlockFactory,
  TextBlockFactory,
} from "specHelpers/factories/invitationBlockData"
import { BlockInsertPosition } from "./BuildingBlock"

export const handleBlockContentChange = (
  data,
  setBlocks: Dispatch<React.SetStateAction<BuilderBlockWithBlockId[]>>,
  selectedBlockId?: number,
  debouncedSave?: _.DebouncedFunc<(newBlocks: any) => void>,
) => {
  setBlocks((prevBlocks) => {
    const index = prevBlocks.findIndex(({ blockId }) => blockId === selectedBlockId)

    const updatedBlocks = update(prevBlocks, {
      [index]: {
        $apply: (block) => update(block, { block: { data: { $merge: data } } }),
      },
    })

    debouncedSave?.(updatedBlocks)

    return updatedBlocks
  })
}

type HandleAddBlockType = {
  type: BuilderBlockType,
  setBlocks: Dispatch<React.SetStateAction<BuilderBlockWithBlockId[]>>,
  setSelectedBlockId: Dispatch<React.SetStateAction<number | undefined>>,
  position: BlockInsertPosition,
  blockId?: number,
  block?: BuilderBlock,
  debouncedSave?: _.DebouncedFunc<(newBlocks: any) => void>,
}

export const handleAddBlock = ({
  type,
  setBlocks,
  setSelectedBlockId,
  position,
  blockId,
  block,
  debouncedSave,
}: HandleAddBlockType) => {
  let data
  if (block) {
    ({ data } = block)
  } else {
    switch (type) {
      case BuilderBlockType.HEADING:
        data = HeadingBlockFactory.build()
        break
      case BuilderBlockType.SPACER:
        data = { size: 5, blockColor: "#FFFFFF" }
        break
      case BuilderBlockType.HTML:
        data = HtmlBlockFactory.build()
        break
      case BuilderBlockType.DIVIDER:
        data = DividerBlockFactory.build()
        break
      case BuilderBlockType.IMAGE:
        data = ImageBlockFactory.build()
        break
      case BuilderBlockType.QR_CODE:
        data = QRCodeBlockFactory.build()
        break
      case BuilderBlockType.BUTTON:
        data = ButtonBlockFactory.build({ action: "website" })
        break
      case BuilderBlockType.TEXT:
      default:
        data = TextBlockFactory.build()
    }
  }

  setBlocks((prevState: BuilderBlockWithBlockId[]) => {
    const prevBlocks = [...prevState]
    let positionIndex = prevState.findIndex(
      (b) => b.blockId === blockId,
    )

    if (positionIndex === -1) {
      positionIndex = prevBlocks.length
    } else if (position === BlockInsertPosition.Bottom) {
      positionIndex += 1
    }

    prevBlocks.splice(
      positionIndex,
      0,
      { block: { type, data }, blockId: -1 },
    )

    const updatedBlocks = prevBlocks.map((b, index) => {
      if (b.blockId === -1) {
        setSelectedBlockId(index)
      }

      return { block: b.block, blockId: index }
    })
    debouncedSave?.(updatedBlocks)

    return updatedBlocks
  })
}

export const handleDeleteBlock = (
  setBlocks: Dispatch<React.SetStateAction<BuilderBlockWithBlockId[]>>,
  selectedBlockId?: number,
  debouncedSave?: _.DebouncedFunc<(newBlocks: any) => void>,
) => {
  setBlocks((prevBlocks) => {
    const updatedBlocks = prevBlocks.filter(({ blockId }) => blockId !== selectedBlockId)
    debouncedSave?.(updatedBlocks)

    return updatedBlocks
  })
}

export const handleMove = (
  blocks: BuilderBlockWithBlockId[],
  setBlocks: Dispatch<React.SetStateAction<BuilderBlockWithBlockId[]>>,
  fromDragId: number,
  toDragId: number,
  position: BlockInsertPosition,
) => {
  const fromIndex = blocks.findIndex(({ blockId }) => fromDragId === blockId)
  let toIndex = blocks.findIndex(({ blockId }) => toDragId === blockId)

  if (fromIndex < toIndex && position === BlockInsertPosition.Top) {
    toIndex -= 1
  } else if (fromIndex > toIndex && position === BlockInsertPosition.Bottom) {
    toIndex += 1
  }

  if (fromIndex === toIndex) {
    return
  }

  if (typeof fromIndex === "undefined") {
    return
  }
  const updatedBlocks = [...blocks]

  const itemToMove = updatedBlocks.splice(fromIndex, 1)
  updatedBlocks.splice(toIndex, 0, itemToMove[0])

  setBlocks(updatedBlocks)
}

export const stripOutQuestionsFormBlockData = (form: Form) => ({
  ...form,
  formData:
      {
        ...form.formData,
        blocks: form.formData.blocks.map(
          (block) => (block.type === BuilderBlockType.QUESTION
            ? { data: { id: block.data.id }, type: block.type }
            : block),
        ),
      },
})
