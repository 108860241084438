import React, { useState } from "react"
import { AgendaRows } from "sharedTypes"
import Button from "components/Buttons/Button"
import { AGENDA_ROW_TYPES } from "utilities/enums"
import PageHeader from "components/layout/PageHeader"
import { Box, Stack } from "@chakra-ui/react"
import AgendaHeading from "./AgendaHeading"
import AgendaItem from "./AgendaItem"
import NoAgenda from "./NoAgenda"
import AgendaRowForm from "./Form"

type Props = {
  rows: AgendaRows
}

const Agenda = ({ rows }: Props) => {
  const [add, setAdd] = useState("")

  let content

  if (rows.length) {
    const agendaList = rows.map((row) => {
      const {
        id,
        type,
        title,
        description,
        speakerIds,
        startsAt,
        endsAt,
      } = row

      let listItem

      switch (type) {
        case AGENDA_ROW_TYPES.heading:
          listItem = (
            <AgendaHeading
              key={id}
              id={id}
              title={title}
              description={description}
              startsAt={startsAt}
            />
          )
          break
        case AGENDA_ROW_TYPES.item:
          listItem = (
            <AgendaItem
              key={id}
              id={id}
              title={title}
              description={description}
              speakerIds={speakerIds}
              startsAt={startsAt}
              endsAt={endsAt}
            />
          )
          break
        default:
          listItem = null
      }

      return (
        <Box p={6}>
          {listItem}
        </Box>
      )
    })

    content = (
      <>
        <PageHeader actionButtons={[
          <Button leftIcon="plus" size="sm" onClick={() => { setAdd(AGENDA_ROW_TYPES.heading) }}>Heading</Button>,
          <Button leftIcon="plus" size="sm" onClick={() => { setAdd(AGENDA_ROW_TYPES.item) }}>Item</Button>,
        ]}
        />
        <Box backgroundColor="white" borderRadius="lg" borderWidth={1} mt={2}>
          <Stack pt={2} spacing={1} divider={<Box borderBottomWidth={1} />}>
            {agendaList}
          </Stack>
        </Box>
      </>
    )
  } else {
    // This is an ugly hack for centering the NoSpeakers component vertically with SimpleBar
    content = <Box h="calc(100vh - 200px)"><NoAgenda onClickAdd={() => { setAdd(AGENDA_ROW_TYPES.item) }} /></Box>
  }

  return (
    <>
      {content}
      {add && <AgendaRowForm onHide={() => setAdd("")} agendaRowType={add} />}
    </>
  )
}

export default Agenda
