import React, { ReactNode } from "react"
import {
  Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup as ChakraMenuOptionGroup, Portal,
} from "@chakra-ui/react"
import Button from "./Buttons/Button"
import Icon from "./elements/Icon"

type Props = {
  title: string
  optionGroups: {
    groupTitle: string,
    type: MenuWithOptionGroupType,
    defaultValue: string,
    onChange: (value: string) => void,
    options: { title: string, value: string }[],
  }[]
}

const MenuWithOptionGroups = ({ title, optionGroups }: Props) => (
  <Menu autoSelect={false}>
    <MenuButton>
      <Button
        variant="outline"
        size="sm"
        rightIcon="down-arrow"
      >{title}
      </Button>
    </MenuButton>
    <Portal>
      <MenuList p={0} overflow="hidden">
        {optionGroups.map(({
          groupTitle, type, defaultValue, onChange, options,
        }) => (
          <MenuOptionGroup
            title={groupTitle}
            defaultValue={defaultValue}
            type={type}
            onChange={onChange}
          >
            {options.map(({ title: optionTitle, value }) => (
              <MenuItemOption
                value={value}
                borderBottomWidth={1}
                height={12}
                fontSize="sm"
                icon={<Icon icon="checkmark" />}
                pr={8}
              >{optionTitle}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        ))}
      </MenuList>
    </Portal>
  </Menu>
)

type MenuOptionGroupProps = {
  children: ReactNode
  onChange: (value: any) => void
  title: string
  type: MenuWithOptionGroupType
  defaultValue?: string
}

const MenuOptionGroup = ({
  children, onChange, defaultValue, title, type,
}: MenuOptionGroupProps) => (
  <ChakraMenuOptionGroup
    defaultValue={defaultValue}
    title={title}
    type={type}
    textAlign="center"
    backgroundColor="gray.100"
    margin={0}
    paddingX={4}
    paddingY={2}
    borderBottomWidth={1}
    onChange={onChange}
    fontSize="lg"
  >
    {children}
  </ChakraMenuOptionGroup>
)

export enum MenuWithOptionGroupType {
  Radio = "radio",
  Checkbox = "checkbox",
}

export default MenuWithOptionGroups
