import React from "react"
import { eventSettingsPath } from "utilities/routes"
import { Box, Text } from "@chakra-ui/react"
import SwitchField from "components/ReactHookForm/SwitchField"
import { useForm } from "react-hook-form"
import { Website } from "sharedTypes"
import Link from "components/elements/Link"
import { successToast } from "utilities/toasts"

type Props = {
  website: Website
  updateWebsite: (params: {}) => Promise<void>
  eventId: string
}

const Map = ({
  website: {
    mapEnabled, locationVenue, locationLine1, locationLine2, locationLine3,
  },
  updateWebsite,
  eventId,
}: Props) => {
  const { control, watch } = useForm({ defaultValues: { mapEnabled }, mode: "onChange" })

  const enabled = watch("mapEnabled")

  const handleChange = async (event) => {
    await updateWebsite({ mapEnabled: event.target.checked })
    successToast({ title: "Map updated" })
  }

  return (
    <>
      <SwitchField
        name="mapEnabled"
        control={control}
        label="Event Map"
        fontSize="xl"
        onChange={handleChange}
      />
      <Text mb={8} mt={2}>
        Display a map of the event location at the bottom of your event webpage.
        The map will overlay the venue details as well a button for directions.
      </Text>
      <MapFrame
        mapEnabled={enabled}
        locationVenue={locationVenue}
        locationLine1={locationLine1}
        locationLine2={locationLine2}
        locationLine3={locationLine3}
      />
      <Box mt={6}>
        <Text mb={3} textAlign="center">
          Your event venue address can be changed in the
          {" "}
          <Link to={eventSettingsPath(eventId)}>Event Settings</Link>
          .
        </Text>
      </Box>
    </>
  )
}

const MapFrame = ({
  mapEnabled, locationVenue, locationLine1, locationLine2, locationLine3,
}) => {
  const addressQuery = [locationVenue, locationLine1, locationLine2, locationLine3].filter(Boolean).join("+")

  if (addressQuery) {
    return (
      <Box
        as="iframe"
        title={addressQuery}
        w="full"
        h={96}
        opacity={!mapEnabled ? 0.25 : 1}
        src={`https://www.google.com/maps/embed/v1/search?key=AIzaSyCT6QyzAp3i41yJePnvB-xd3BuqZqmJ78o&q=${addressQuery}`}
      />
    )
  }

  return null
}

export default Map
