import React from "react"
import Modal from "components/dialogs/Modal"
import { Text } from "@chakra-ui/react"
import EmbedInstructions, { EmbedInstructionsType } from "components/EmbedInstructions"

const EmbedModal = ({ hide, externalId }) => (
  <Modal
    show
    title="Embed Form"
    hide={hide}
  >
    <Text mb={5}>
      You can embed this form on your website, allowing your guests to register
      without leaving your site. Follow these steps:
    </Text>
    <EmbedInstructions type={EmbedInstructionsType.Form} externalId={externalId} />
  </Modal>
)

export default EmbedModal
