import React from "react"
import { Flex, Box, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { BuilderBlock } from "sharedTypes/builder"
import { FormQuestion, QuestionTypes } from "sharedTypes"
import FormattedAnswer from "./FormattedAnswer"

type Props = {
  block: BuilderBlock
  primaryColor: string
  responses: { [key: string]: string | [] }
  iconMap: { [key in QuestionTypes]: string }
}

const Question = ({
  block, primaryColor, responses, iconMap,
}: Props) => {
  const question = block.data as FormQuestion
  const response = responses[question.id]

  return (
    <Flex fontWeight="semibold">
      <Icon icon={`form-${iconMap[question.type]}`} mr={3} size={6} mt={0.25} />
      <Box>
        <Box dangerouslySetInnerHTML={{ __html: question.title }} />
        <Text color={primaryColor}>
          <FormattedAnswer response={response} />
        </Text>
      </Box>
    </Flex>
  )
}

export default Question
