import React, { ChangeEvent, ReactNode } from "react"
import { Flex, useRadio, useRadioGroup } from "@chakra-ui/react"
import { RadioOption } from "sharedTypes"
import RSVPCheckbox from "./RSVPCheckbox"

type Props = {
  name: string
  options: RadioOption[]
  onChange?: (value: string) => void
  color?: string
  defaultValue?: string
}

const RSVPRadioButtons = ({
  name,
  options,
  onChange,
  color,
  defaultValue,
}: Props) => {
  const {
    getRootProps,
    getRadioProps,
    value: radioGroupValue,
  } = useRadioGroup({
    name,
    defaultValue,
    onChange,
  })

  const group = getRootProps()

  return (
    <Flex justifyContent="center" mx={4} gap={6} {...group}>
      {options.map(({ label, value }) => {
        const radio = getRadioProps({ value })

        return (
          // @ts-ignore
          <RadioButton
            key={value}
            {...radio}
            isChecked={radioGroupValue === value}
            color={color}
          >
            {label}
          </RadioButton>
        )
      })}
    </Flex>
  )
}

type RadioButtonProps = {
  children: ReactNode
  isChecked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  value: string
  color?: string
}

const RadioButton = ({
  children,
  isChecked,
  onChange,
  value,
  color,
}: RadioButtonProps) => {
  const { getInputProps } = useRadio({ isChecked, onChange, value })
  const input = getInputProps()

  return (
    <Flex as="label" cursor="pointer" zIndex={0}>
      <input {...input} />
      <RSVPCheckbox isChecked={isChecked} color={color}>
        {children}
      </RSVPCheckbox>
    </Flex>
  )
}

export default RSVPRadioButtons
