import React from "react"
import useMaintenanceStatus from "services/useMaintenanceStatus"
import FullScreenInfoView from "./FullScreenInfoView"
import Link from "./elements/Link"

const MaintenanceView = () => {
  const setStatus = useMaintenanceStatus(({ setMaintenanceStatus }) => setMaintenanceStatus)

  return (
    <FullScreenInfoView
      heading={<>Diobox is down for<br /> maintenance and updates</>}
      description="We&apos;ll be back online shortly."
      footer={<Link onClick={() => setStatus(false)}>Try again</Link>}
    />
  )
}

export default MaintenanceView
