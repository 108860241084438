import React, { useEffect } from "react"
import _ from "lodash"
import { IMPORT_GUEST_HEADERS } from "utilities/enums"
import { ImportGuestsHeader } from "sharedTypes"
import { Box } from "@chakra-ui/react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import ImportGuestsTable from "modals/ImportGuests/ManageFields/Table"

type Props = {
  headerConfiguration: (ImportGuestsHeader | undefined)[];
  onChangeHeaderConfiguration: (headerConfiguration: (ImportGuestsHeader | undefined)[]) => void;
  examples: (string | undefined)[][];
  containsHeader: boolean;
  onClickBack: () => void;
  onClickNext: () => void;
  onClose: () => void;
}

const matchColumn = (a: string, b: string) => (
  _.camelCase(a) === _.camelCase(b)
  || (
    a === "First Name" && b.toLowerCase() === "name"
  ))

const ManageFields = ({
  headerConfiguration,
  onChangeHeaderConfiguration,
  examples,
  containsHeader,
  onClickBack,
  onClickNext,
  onClose,
}: Props) => {
  const rows = _.zip(...examples)
  let headers
  let customFields = []
  if (containsHeader) {
    headers = rows.shift()
    customFields = headers.filter(
      (header) => !IMPORT_GUEST_HEADERS.some(({ value }) => matchColumn(value, header)),
    )
  }

  useEffect(() => {
    let initialHeaderConfiguration = IMPORT_GUEST_HEADERS.slice(0, examples.length)
    if (headers) {
      initialHeaderConfiguration = headers.map((header) => (
        IMPORT_GUEST_HEADERS.find((h) => matchColumn(h.value, header))
          || undefined
      )) as ImportGuestsHeader[]
    }

    onChangeHeaderConfiguration(initialHeaderConfiguration)
  }, [examples])

  const handleHeaderChange = (
    columnIndex,
    { key: headerKey, type: headerType }: ImportGuestsHeader,
  ) => {
    const newConfiguration = [...headerConfiguration]

    // Disable the column that currently holds the same header
    const replacedColumnIndex = headerConfiguration.findIndex((header) => header?.key === headerKey)
    newConfiguration[replacedColumnIndex] = undefined

    if (!headerKey) {
      newConfiguration[columnIndex] = undefined
    } else {
      // Set the selected header for the current column
      newConfiguration[columnIndex] = IMPORT_GUEST_HEADERS.find(({ key }) => key === headerKey)
          || {
            key: headerKey, value: headerKey, custom: true, type: headerType,
          }
    }

    onChangeHeaderConfiguration(newConfiguration)
  }

  const examplesTable = (
    <Box overflowX="auto">
      <ImportGuestsTable
        headerConfiguration={headerConfiguration}
        headers={headers}
        rows={rows}
        onChangeHeaderConfiguration={handleHeaderChange}
        customFields={customFields}
      />
    </Box>
  )

  return (
    <ModalForm
      icon="import"
      size="6xl"
      onSubmit={onClickNext}
      submitText="Next"
      onBack={onClickBack}
      onClose={onClose}
      closeOnEsc={false}
      title="Import Guests"
    >
      {examplesTable}
    </ModalForm>
  )
}

export default ManageFields
